import React, { useContext, useEffect, useState } from 'react'
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import ProjectHeader from './project-header';
import InputSelect from '../InputGroup/InputSelect';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { addUserToProjectRouting, getProjectLead, getUsersForProjectLead, getUsersForProjectRouting, removeUserFromProjectRouting, setProjectLead } from '../../services/public/public.service';
import { toast } from 'react-toastify';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchicon.svg';
import { ReactComponent as ArrowClockwiseIcon } from '../../assets/icons/arrows_clockwise.svg';

import { heightMeasurment } from '../../utils/project-constant';
import Consumer from '../../helpers/context';
import { Link } from 'react-router-dom';
import TooltipText from '../Tooltip';
import ActionModalOfprojectRouting from '../../modules/Settings/User&Teams/Roles/Modal/ActionModalOfprojectRouting';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        zIndex: '99',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const TEAM_STRUCTURE = [
    {
        label: 'Sales Only',
        value: 'sales_only'
    },
    {
        label: 'None',
        value: 'null'
    }
]
const TeamInfo = () => {

    const [projectSalesLead, setProjectSalesLead] = useState([]);
    const [routing, setRouting] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [selectedSales, setSelectedSales] = useState('');
    const [showActionModal, setShowActionModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [rolesDetails, setRolesDetail] = useState({});

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            team_structure: '',
            sales: '',
        },
        // resolver: yupResolver(validationSchema)
    });

    let { allowedPermissions } = useContext(Consumer);

    const team = watch('team_structure');
    const sales = watch('sales');

    const history = useHistory();
    const { uuid } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const propertyType = searchParams.get('pt');
    const transactionType = searchParams.get('tt');

    const getProjectSalesLead = async () => {
        const res = await getUsersForProjectLead();
        if (res.data.status === 200) {
            const mappedData = res.data.data.map(item => ({ ...item, label: `${item.name} (${item.role_name})`, value: item.uuid }))
            setProjectSalesLead(mappedData)
            if (selectedSales) {
                const projectLeads = mappedData.find(item => item.value === selectedSales)
                setValue('sales', projectLeads)
            }
        }
    }

    const getProjectLeads = async () => {
        const res = await getProjectLead(uuid);
        if (res.data.status === 200) {
            setRouting(res.data.data.lead_routing || false);
            if (res.data.data.team_structure) {
                const teamStructure = TEAM_STRUCTURE.find(item => item.value === res.data.data.team_structure)
                setValue('team_structure', teamStructure)
            }
            if (res.data.data.project_lead) {
                setSelectedSales(res.data.data.project_lead)
            }
        }
    }

    useEffect(() => {
        getProjectLeads()
    }, [])

    const getTeamMembers = async (salesUUID) => {
        /**
         * salesUUID ===> selected project lead uuid
         * uuid===> project uuid from params
         */
        try {
            const res = await getUsersForProjectRouting(salesUUID, uuid);
            if (res.data.status === 200) {
                setTeamMembers(res.data.data);
            } else {
                toast.error(res.data.message);
            }
        } catch (err) {
            if (err.response.data.data.length === 0) {
                setTeamMembers([]);
            }
        }
    }

    useEffect(() => {
        if (team.value === 'sales_only') {
            getProjectSalesLead();
        }
        if (team.value === 'null') {
            getTeamMembers()
        }
    }, [team]);

    useEffect(() => {
        if (sales.value) {
            getTeamMembers(sales.value);
        }
    }, [sales]);

    const handleAddUserToProjectRouting = async (userId, index) => {
        const payload = {
            user_uuid: userId,
            project_uuid: uuid
        }
        try {
            const res = await addUserToProjectRouting(payload);
            if (res.data.status === 200) {
                toast.success(res.data.message);
                let teams = [...teamMembers];
                teams[index].is_routing = !teams[index].is_routing
                setTeamMembers(teams)
            }
        } catch (err) {
            console.log(err.response)
            if (err.response.data.status === 400) {
                setRolesDetail(err.response.data.data)
                setModalText(err.response.data.message);
                setShowActionModal(true);
                let teams = [...teamMembers];
                teams[index].is_routing = false
                setTeamMembers(teams)
            }
        }
    }

    const handleRemoveUserToProjectRouting = async (userId, index) => {
        const payload = {
            user_uuid: userId,
            project_uuid: uuid
        }
        const res = await removeUserFromProjectRouting(payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            let teams = [...teamMembers];
            teams[index].is_routing = !teams[index].is_routing
            setTeamMembers(teams)
        }
    }

    const handleProjectLead = async () => {
        const payload = {
            user_id: sales.value || null,
            lead_routing: routing,
            team_structure: team.value || null
        }
        try {
            const res = await setProjectLead(uuid, payload)
            if (res.data.status === 200) {
                toast.success(res.data.message);
            }
        } catch (err) {
            console.log(err.response.data.message);
        }
    }

    useEffect(() => {
        if (team?.value === 'sale_only' && sales.value) {
            handleProjectLead()
        } else if (team?.value === 'null') {
            handleProjectLead()
        } else {
            handleProjectLead()
        }
    }, [sales, team, routing]);

    const handleRouting = (e, item, index) => {
        e.stopPropagation();
        if (!item.is_routing) {
            handleAddUserToProjectRouting(item.uuid, index);
        }
        if (item.is_routing) {
            handleRemoveUserToProjectRouting(item.uuid, index)
        }
    }
    const onFormSubmit = (values) => {
        console.log({ values })
    }

    const handleSubmitModal = () => {
        // history.push({
        //     pathname: `/settings/user-roles/roles/new/permissions/${rolesDetails?.uuid}`,
        //     search: `?role=edit&from=detail&name=${rolesDetails?.name}&description=${rolesDetails?.description}`,
        // })
        window.open(`/settings/user-roles/roles/new/permissions/${rolesDetails?.uuid}?role=edit&name=${rolesDetails?.name}&description=${rolesDetails?.description}`, '_blank')
        setShowActionModal(false);
    }

    return (
        <div className={`${style.creatproject}`}>
            <ProjectHeader style={style} />
            <form onSubmit={handleSubmit(onFormSubmit)} className={`${style.creatproject} overflow-auto border-top border-color`} id='additioninfo'>
                <div className={`${style.Location} d-flex flex-fill flex-col mb-1 pl-24 pt-20`}>
                    <div className='w-100'>
                        <div className={`grid grid-cols-3 gap-6 max-w-[1100px]`}>

                            <div className='relative form-group'>
                                <Controller
                                    control={control}
                                    name='team_structure'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <div className='position-relative'>
                                            <label className=''>Team Structure</label>
                                            <InputSelect
                                                placeholderText='Select Team Structure'
                                                options={TEAM_STRUCTURE}
                                                styles={customStyles}
                                                onChange={onChange}
                                                value={value}
                                                disable={!allowedPermissions.includes('select_project_lead')}
                                            />
                                        </div>
                                    )}
                                />
                            </div>

                            <div>
                                {/* <div className='relative form-group mb-4'>
                                    <Controller
                                        control={control}
                                        name='pre_sale'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <div className='position-relative'>
                                                <label className=''>Project Lead (Pre-Sales)</label>
                                                <InputSelect
                                                    placeholderText='Select Team Structure'
                                                    options={[]}
                                                />
                                            </div>
                                        )}
                                    />
                                </div> */}
                                {team?.value === 'sales_only' && <div className='relative form-group'>
                                    <Controller
                                        control={control}
                                        name='sales'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <div className='position-relative'>
                                                <label className=''>Project Lead (Sales)</label>
                                                <InputSelect
                                                    placeholderText='Select Project Lead'
                                                    options={projectSalesLead}
                                                    styles={customStyles}
                                                    onChange={onChange}
                                                    value={value}
                                                    disable={!allowedPermissions.includes('select_project_lead')}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>}
                            </div>

                            {team && <div>
                                <label>  Lead routing</label>
                                <div className='border rounded-md w-40 '>
                                    <button
                                        type='button'
                                        className={`text-center w-1/2 py-2 rounded-l-md ${!routing ? 'text-primary font-semibold bg-blueHighlight' : 'text-black700'}`}
                                        onClick={() => setRouting(false)}
                                        disable={!allowedPermissions.includes('select_project_lead')}
                                    >Manual</button>
                                    <button
                                        type='button'
                                        className={`text-center w-1/2 py-2 rounded-r-md ${routing ? 'text-primary font-semibold bg-blueHighlight' : 'text-black700'}`}
                                        onClick={() => setRouting(true)}
                                        disable={!allowedPermissions.includes('select_project_lead')}
                                    >Auto</button>
                                </div>
                            </div>}
                        </div>

                        {(routing && (team.value === 'null' || sales.value)) && <div className={`${style.bankDetailsmain} d-flex flex-fill flex-col mb-1 mt-[40px]`} style={{ height: '330px' }}>
                            <div className='flex items-center justify-between pr-1 mb-2.5 max-w-[915px]'>
                                <div className='flex items-center'>
                                    <label htmlFor="" className='text-base m-0'>Team Members</label>
                                    {team.value === 'sales_only' && <TooltipText title={'Refresh'}>
                                        <button className='flex border rounded p-1 ml-2' onClick={() => getTeamMembers(sales.value)}><ArrowClockwiseIcon /></button>
                                    </TooltipText>}
                                </div>

                                {team.value === 'sales_only' && <a
                                    className='border border-primary text-primary text-xs font-semibold rounded px-2.5 p-1 flex items-center'
                                    href={`/settings/user-roles/roles/detail/${sales.role_uuid}?user=${sales.uuid}&name=${sales.role_name}&description=${''}`} target='_blank'
                                >
                                    <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="path-1-inside-1_1117_451" fill="#0062FF">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                        </mask>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="#0062FF" />
                                        <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="#0062FF" mask="url(#path-1-inside-1_1117_451)" />
                                    </svg>
                                    Team member
                                </a>}
                            </div>
                            <div className='w-100 flex flex-col'>
                                <div className={` def-table !pr-0 pb-0 w-[915px] !h-auto max-h-[333px]`}>
                                    <table className='table mb-0'>
                                        <thead style={{ position: 'static' }}>
                                            <tr>
                                                <th style={{ position: 'static' }} >S.No</th>
                                                <th>User Name</th>
                                                <th>Assign Leads</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamMembers.length > 0 && teamMembers.map((item, index) => (
                                                <tr key={`${uuid}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-lg btn-toggle ${item.is_routing ? 'active' : ''}  mr-2`}
                                                            data-toggle="button"
                                                            aria-pressed='true'
                                                            autoComplete="off"
                                                            value={item.is_routing}
                                                            onClick={(e) => handleRouting(e, item, index)}
                                                            disabled={!allowedPermissions.includes('edit_project_routing')}
                                                        >
                                                            <div className="handle"></div>
                                                            <span className={`${item.is_routing ? 'on' : 'off'}`}>{item.is_routing ? 'ON' : 'OFF'}</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                            }


                                        </tbody>
                                    </table>
                                </div>
                                {teamMembers.length === 0 &&
                                    <div className='flex justify-center flex-column items-center max-w-[915px] !mt-8'>
                                        <figure><SearchIcon className='h-10 w-10' /></figure>
                                        <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Users Added</h2>
                                        <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add users to see them here</p>
                                    </div>
                                }
                                {/* <div className={`${style.bankDetailtable} def-table pr-24 pb-0 w-1/2`}>
                                    <table className='table mb-0'>
                                        <thead style={{ position: 'static' }}>
                                            <tr>
                                                <th style={{ position: 'static' }}>S.No</th>
                                                <th>User Name</th>
                                                <th>Assign Leads</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                <tr >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div> */}
                            </div>
                        </div>}

                    </div>
                </div>

            </form >
            <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                <button
                    type='button'
                    className={`${style.cancelbtn}`}
                    onClick={() => {
                        history.push(`/projects/create-projects/construction-updates/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                    }}
                >
                    <LeftIcon />Previous
                </button>
                <button type='button' onClick={() => {
                    history.push(`/projects`);
                }} className={`${style.savebtn}`}>
                    Save
                </button>
            </div>


            {showActionModal && <ActionModalOfprojectRouting
                show={showActionModal}
                closeModal={() => setShowActionModal(false)}
                subHeadingText={modalText}
                btnText={'Edit Permission'}
                handleSubmit={handleSubmitModal}
            />}

        </div >
    )
}

export default TeamInfo