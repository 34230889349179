import React, { useCallback, useState } from 'react'
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';

import { deleteConfigurationByUUID, getPreferredTenant, getProjectConfigurations, getUnitType } from '../../services/public/public.service';
import { useEffect } from 'react';
import ProjectHeader from './project-header';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { ReactComponent as EditICon } from '../../assets/icons/NameEdit.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { convertToTitleCase, numDifferentiation, removeDecimalPlaces } from '../../helpers/helpers';
import ConfigurationModal from './Modal/ConfigurationModal';
import { toast } from 'react-toastify';
import moment from 'moment';

const Configuration = ({ handleChangeConfiguration, configurations, getConfig, inventory, allowedPermissions }) => {
    const [showConfigModal, setShowConfigModal] = useState(false);
    const [configurationsData, setConfigurations] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState({});
    const [preferredTenent, setPreferredTenant] = useState([])
    const [unitType, setUnitType] = useState([]);
    const [selectedConfigIndex, setSelectedConfigIndex] = useState();

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const property = searchParams.get('pt');
    const transaction = searchParams.get('tt');


    const getConfigUnitType = async () => {
        const res = await getUnitType(property);
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item, index) => ({ label: item.name, value: item.name }))
            setUnitType(mapped);
        }
    }

    const getTenant = async () => {
        const res = await getPreferredTenant(property);
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item, index) => ({ label: item.name, value: item.name }))
            setPreferredTenant(mapped);
        }
    }


    const handleChangeUnitType = useCallback((newType) => {
        setUnitType([...unitType, newType])
    }, [unitType])

    const handleDeleteConfig = async (configuuid) => {
        const res = await deleteConfigurationByUUID(configuuid);
        if (res.data.status) {
            getConfig();
            toast.success(res.data.message);
        }
    }
    useEffect(() => {
        if (configurationsData.length > 0)
            handleChangeConfiguration(configurationsData)
    }, [configurationsData])

    useEffect(() => {
        setConfigurations(configurations)
    }, [configurations])

    useEffect(() => {
        if (property) {
            getConfigUnitType();
            getTenant();
        }
    }, [property]);

    const handleSubmit = () => {
        history.push(`/projects/create-projects/location/${uuid}?pt=${property}&tt=${transaction}`);
    }

    return (

        <>
            {configurationsData.length === 0 && <div className={`${style.basic_form} basic_form mt-20`}>
                <div className={`${style.basic_form} basic_form mt-16`}>
                    <div className={`${style.col_span_2} border-color d-flex align-items-center justify-content-between border-bottom `}>
                        <h1 className="fz18 black fw-po-semi inter mb-2">Configuration</h1>
                    </div>
                </div>

                {allowedPermissions.includes('create_project_configuration') && <div className={`${style.col_span_2}`}>
                    <button type='button' onClick={() => setShowConfigModal(true)} className='pr-outline-btn d-block w-100'>+ Add Configuration</button>
                </div>}
            </div>}
            {configurationsData.length > 0 && <div className={`${style.creatproject}`}>
                <div className={`${style.projecttitle} border-color pt-20 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                    <h1 className='fz18 black fw-po-semi inter mb-0'>Configuration</h1>
                    <div className='d-flex flex-row align-items-center  gap-12 gap-sm-8'>
                        {configurationsData.length === 1 && <button
                            className={`${style.configeditbtn}`}
                            type='button' onClick={() => {
                                setSelectedConfig(configurationsData[0]);
                                setSelectedConfigIndex(0)
                                setShowConfigModal(true);
                            }}>
                            <EditICon /><span className='d-md-inline d-none'>Edit</span>
                        </button>}
                        {allowedPermissions.includes('delete_project_configuration') && configurationsData.length === 1 && <button
                            className={`${style.configdelbtn} strokered hover:!text-red`} type='button' onClick={() => {
                                if (Object.keys(configurationsData[0]).includes('uuid')) {
                                    handleDeleteConfig(configurationsData[0].uuid)
                                } else {
                                    handleChangeConfiguration([])
                                }
                            }}>
                            <TrashIcon />
                            <span className='d-md-inline d-none'> Delete</span>
                        </button>}
                        {allowedPermissions.includes('create_project_configuration') && <button className='pr-btn inter' type='button' onClick={() => setShowConfigModal(true)}><PlushIcon />Add</button>}
                    </div>
                </div>
                <div className={`${style.overflowcontent} d-flex flex-fill flex-col border-top border-color pt-20`}>
                    <div className='w-100'>
                        {/* {configurationsData.length === 0 &&
                            <div className={`${style.col_span_2}`}>
                                <button type='button' onClick={() => setShowConfigModal(true)} className='pr-outline-btn d-block w-100'>+ Add Configuration</button>
                            </div>
                        } */}
                        {configurationsData.length === 1 &&
                            <>
                                <div className={`${style.overflowcontentrow} d-flex flex-md-wrap flex-nowrap align-items-start gap-y-14 gap-x-20`}>
                                    {configurationsData[0]?.unit_type && <div className={`${style.confiboxview}`}>
                                        <h6>{convertToTitleCase(configurationsData[0].unit_type || '')}</h6>
                                        <p>Unit Type</p>
                                    </div>}
                                    {configurationsData[0]?.unit_configuration && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].unit_configuration}</h6>
                                        <p>Unit Configuration</p>
                                    </div>}
                                    {configurationsData[0]?.price && <div className={`${style.confiboxview}`}>
                                        <h6>{numDifferentiation(configurationsData[0].price)}</h6>
                                        <p>Price</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.rent_price && <div className={`${style.confiboxview}`}>
                                        <h6>{numDifferentiation(configurationsData[0].rent_price)}</h6>
                                        <p>Unit rent Price (Monthly)</p>
                                    </div>}
                                    {configurationsData[0]?.carpet_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].carpet_area)} {configurationsData[0].carpet_area_unit}</h6>
                                        <p>Carpet Area</p>
                                    </div>}
                                    {configurationsData[0]?.super_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].super_area)} {configurationsData[0].super_area_unit}</h6>
                                        <p>Super Area</p>
                                    </div>}
                                    {configurationsData[0]?.built_up_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].built_up_area)} {configurationsData[0].built_up_area_unit}</h6>
                                        <p>Built up area</p>
                                    </div>}
                                    {configurationsData[0]?.plot_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].plot_area)} {configurationsData[0].plot_area_unit}</h6>
                                        <p>Plot Area</p>
                                    </div>}
                                    {configurationsData[0]?.furnishing && <div className={`${style.confiboxview}`}>
                                        <h6>{convertToTitleCase(configurationsData[0].furnishing || '')}</h6>
                                        <p>Unit Furnishing</p>
                                    </div>}
                                    {['rent', 'pre_lease', 'retail_lease'].includes(transaction) && inventory === 'resale' && configurationsData[0]?.usage_status && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].usage_status || ''}</h6>
                                        <p>Usage Status</p>
                                    </div>}
                                    {configurationsData[0]?.facing && <div className={`${style.confiboxview}`}>
                                        <h6 className='text-capi'>{convertToTitleCase(configurationsData[0].facing || '')}</h6>
                                        <p>Facing</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.security_deposit && <div className={`${style.confiboxview}`}>
                                        <h6>{numDifferentiation(configurationsData[0].security_deposit)}</h6>
                                        <p>Security Deposit</p>
                                    </div>}
                                    {configurationsData[0]?.maintenance_cost && <div className={`${style.confiboxview}`}>
                                        <h6>{numDifferentiation(configurationsData[0].maintenance_cost)}</h6>
                                        <p>Maintenance Cost</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.maintenance_cost_payee && <div className={`${style.confiboxview}`}>
                                        <h6>{convertToTitleCase(configurationsData[0].maintenance_cost_payee)}</h6>
                                        <p>Maintenance Cost Payee</p>
                                    </div>}
                                    {transaction === 'sale' &&
                                        ['new_launch', 'sustenance'].includes(inventory) && configurationsData[0]?.price_per_unit && <div className={`${style.confiboxview}`}>
                                            <h6>{numDifferentiation(configurationsData[0].price_per_unit)}</h6>
                                            <p>Price ({configurationsData[0]?.price_per_unit_type}) </p>
                                        </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.rent_escalation && <div className={`${style.confiboxview}`}>
                                        <h6 className='text-capi'>{configurationsData[0].rent_escalation} {configurationsData[0].rent_escalation_period}</h6>
                                        <p>Rent Escalation</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.minimum_rent_tenure && <div className={`${style.confiboxview}`}>
                                        <h6 className='text-capi'>{configurationsData[0].minimum_rent_tenure} {configurationsData[0].minimum_rent_tenure_period}</h6>
                                        <p>Minimum Rent Tenure</p>
                                    </div>}
                                    {transaction === 'pre_lease' && (['commercial'].includes(property)) && configurationsData[0]?.lockin_period && <div className={`${style.confiboxview}`}>
                                        <h6 className='text-capi'>{configurationsData[0].lockin_period} {configurationsData[0].lockin_period_unit}</h6>
                                        <p>Lockin Period</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.unit_sharing && <div className={`${style.confiboxview}`}>
                                        <h6 className='text-capitalize'>{configurationsData[0].unit_sharing}</h6>
                                        <p>Sharing</p>
                                    </div>}
                                    {configurationsData[0]?.unit_no && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].unit_no}</h6>
                                        <p>Unit No.</p>
                                    </div>}
                                    {configurationsData[0]?.parking_slots && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].parking_slot}</h6>
                                        <p>Parking Slots</p>
                                    </div>}
                                    {configurationsData[0]?.floor && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].floor}</h6>
                                        <p>Floor</p>
                                    </div>}
                                    {configurationsData[0]?.tower && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].tower}</h6>
                                        <p>Tower Number</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.preferred_tenant && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].preferred_tenant}</h6>
                                        <p>Preferred Tenant</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.rent_start_date && <div className={`${style.confiboxview}`}>
                                        <h6>{moment(configurationsData[0].rent_start_date).format('MMM YYYY')}</h6>
                                        <p>Rent Start Date</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.rent_end_date && <div className={`${style.confiboxview}`}>
                                        <h6>{moment(configurationsData[0].rent_end_date).format('MMM YYYY')}</h6>
                                        <p>Rent End Date</p>
                                    </div>}
                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.available_for_rent && <div className={`${style.confiboxview}`}>
                                        <h6>{moment(configurationsData[0].available_for_rent).format('MMM YYYY')}</h6>
                                        <p>Available For Rent</p>
                                    </div>}
                                    {configurationsData[0]?.similar_unit_count && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].similar_unit_count}</h6>
                                        <p>Similar Unit Count</p>
                                    </div>}
                                    {property === 'commercial' && transaction === "sale" && configurationsData[0]?.assured_return && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].assured_return}</h6>
                                        <p>Assured Return</p>
                                    </div>}
                                    {property === 'commercial' && transaction === "sale" && configurationsData[0]?.lease_guarantee && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].lease_guarantee}</h6>
                                        <p>Lease Guarantee</p>
                                    </div>}
                                    {property === 'commercial' && transaction === "sale" && configurationsData[0]?.seat_count && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].seat_count}</h6>
                                        <p>seat Count</p>
                                    </div>}
                                    {property === 'commercial' && transaction === "sale" && configurationsData[0]?.cabin_count && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].cabin_count}</h6>
                                        <p>Cabin Count</p>
                                    </div>}
                                    {property === 'commercial' && transaction === "sale" && configurationsData[0]?.office_cabin_count && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].office_cabin_count}</h6>
                                        <p>Office Cabin Count</p>
                                    </div>}
                                    {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.front_dimension_width && <div className={`${style.confiboxview}`}>
                                        <h6 className='text-capi'>{configurationsData[0].front_dimension_width} {configurationsData[0].front_dimension_width_unit}</h6>
                                        <p>Front Dimension Width</p>
                                    </div>}
                                    {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && configurationsData[0]?.front_dimension_height && <div className={`${style.confiboxview}`}>
                                        <h6 className='text-capi'>{configurationsData[0].front_dimension_height} {configurationsData[0].front_dimension_height_unit}</h6>
                                        <p>Front Dimension Height</p>
                                    </div>}
                                </div>
                                {/* <div className={`${style.col_span_2} mt-12`}>
                                    <button type='button' onClick={() => setShowConfigModal(true)} className='pr-outline-btn d-block w-100'>+ Add More</button>
                                </div> */}
                            </>
                        }

                        {configurationsData.length > 1 &&
                            <div className={`${style.createprojecttable} def-table pr-0 !h-[332px] pb-0`}>
                                <table className='table mb-0'>
                                    <thead className='!z-10'>
                                        <tr>
                                            <th >S.No.</th>
                                            <th >Unit Type</th>
                                            <th>Unit Configuration</th>
                                            {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <th> Price</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Unit rent Price (Monthly)</th>}
                                            {['rent', 'pre_lease', 'retail_lease'].includes(transaction) && inventory === 'resale' && <th>Usage Status</th>}
                                            <th>Carpet Area</th>
                                            <th>Super Area</th>
                                            <th>Built Up Area</th>
                                            <th>Facing</th>
                                            <th>Floor</th>
                                            <th>Plot Area</th>
                                            {['Retail-lease', 'Rent'].includes(transaction) && (['Residential', 'Commercial'].includes(property)) && <th>Unit Configuration</th>}
                                            <th>Unit Furnishing</th>
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Security Deposit</th>}
                                            <th>Maintenance Cost</th>
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Maintenance Cost Payee</th>}
                                            {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <th>Price Per Unit</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Rent Escalation</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Minimum Rent Tenure</th>}
                                            {transaction === 'pre_lease' && (['commercial'].includes(property)) && <th>Lockin Period</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Sharing</th>}
                                            <th>Unit No.</th>
                                            <th>Tower Number</th>
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Preferred Tenant</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Rent Start Date</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Rent End Date</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Available For Rent</th>}
                                            <th>Similar Unit Count</th>
                                            {property === 'commercial' && transaction === "sale" && <th>Assured Return</th>}
                                            {property === 'commercial' && transaction === "sale" && <th>seat Count</th>}
                                            {property === 'commercial' && transaction === "sale" && <th>Lease Guarantee</th>}
                                            {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Front Dimension Width</th>}
                                            {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Front Dimension Height</th>}
                                            <th>Parking Slots</th>
                                            <th className='sticky right-0'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            configurationsData?.map((data, index) => (
                                                <tr className='text-left' key={`${data.uuid}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.unit_type ? convertToTitleCase(data.unit_type) : '-'}</td>
                                                    <td>{data?.unit_configuration ? convertToTitleCase(data?.unit_configuration) : '-'}</td>
                                                    {/* <td>2BHK, Furnished</td> */}
                                                    {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <td>
                                                        {data?.price ? '₹ ' + numDifferentiation(Number(data?.price)) : '-'}
                                                    </td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>
                                                        {data?.rent_price ? '₹ ' + numDifferentiation(Number(data?.rent_price)) : '-'}
                                                    </td>}
                                                    {['rent', 'pre_lease', 'retail_lease'].includes(transaction) && inventory === 'resale' && <td>{convertToTitleCase(data.usage_status || '-')}</td>}
                                                    <td>{data.carpet_area ? `${removeDecimalPlaces(data.carpet_area)} ${data.carpet_area_unit}` : '-'}</td>
                                                    <td>{data.super_area ? `${removeDecimalPlaces(data.super_area)} ${data.super_area_unit}` : '-'}</td>
                                                    <td>{data.built_up_area ? `${removeDecimalPlaces(data.built_up_area)} ${data.built_up_area_unit} ` : '-'}</td>
                                                    <td>{convertToTitleCase(data.facing || '-')}</td>
                                                    <td>{data.floor || '-'}</td>
                                                    <td>{data.plot_area ? `${removeDecimalPlaces(data.plot_area)} ${data.plot_area_unit}` : '-'}</td>
                                                    {['Retail-lease', 'Rent'].includes(transaction) && (['Residential', 'Commercial'].includes(property)) &&
                                                        <td>{data.unit_configuration || '-'}</td>
                                                    }
                                                    <td>{convertToTitleCase(data.furnishing || '-')}</td>
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.security_deposit ? numDifferentiation(data.security_deposit) : '-'}</td>}
                                                    <td>{data.maintenance_cost ? numDifferentiation(data.maintenance_cost) : '-'}</td>
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.maintenance_cost_payee ? data.maintenance_cost_payee : '-'}</td>}
                                                    {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <td>{data.price_per_unit ? `${data.price_per_unit} ${data.price_per_unit_type}` : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.rent_escalation ? `${data.rent_escalation} ${data.rent_escalation_period}` : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.minimum_rent_tenure ? `${data.minimum_rent_tenure} ${data.minimum_rent_tenure_period}` : '-'}</td>}
                                                    {transaction === 'pre_lease' && (['commercial'].includes(property)) && <td>{data.lockin_period ? `${data.lockin_period} ${data.lockin_period_unit}}` : ''}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.unit_sharing ? data.unit_sharing : '-'}</td>}
                                                    <td>{data.unit_no ? data.unit_no : '-'}</td>
                                                    <td>{data.tower ? data.tower : '-'}</td>
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.preferred_tenant ? data.preferred_tenant : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.rent_start_date ? moment(data.rent_start_date).format('MMM YYYY') : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.rent_end_date ? moment(data.rent_end_date).format('MMM YYYY') : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.available_for_rent ? moment(data.available_for_rent).format('MMM YYYY') : '-'}</td>}
                                                    <td>{data.similar_unit_count ? data.similar_unit_count : '-'}</td>
                                                    {property === 'commercial' && transaction === "sale" && <td>{data.assured_return ? data.assured_return : '-'}</td>}
                                                    {property === 'commercial' && transaction === "sale" && <td>{data.seat_count ? data.seat_count : '-'}</td>}
                                                    {property === 'commercial' && transaction === "sale" && <td>{data.lease_guarantee ? data.seat_count : '-'}</td>}
                                                    {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.front_dimension_width ? `${data.front_dimension_width} ${data.front_dimension_width_unit}` : '-'}</td>}
                                                    {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.front_dimension_height ? `${data.front_dimension_height} ${data.front_dimension_height_unit}` : '-'}</td>}
                                                    <td className='table-data text-textblack  border-black400 relative' >{data.parking_slot || '-'}</td>
                                                    <td className='dropdown view-more !sticky right-0'>
                                                        <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <ThreedotsIcon />
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                            {allowedPermissions.includes('create_project_configuration') && <a className="dropdown-item text-capitalize"
                                                                onClick={() => {
                                                                    setSelectedConfig(data);
                                                                    setSelectedConfigIndex(index)
                                                                    setShowConfigModal(true);
                                                                }
                                                                }>Edit Configuration</a>}

                                                            {allowedPermissions.includes('delete_project_configuration') && <a className="dropdown-item del-menu-itme text-capitalize"
                                                                onClick={() => {
                                                                    if (data?.uuid) {
                                                                        handleDeleteConfig(data.uuid)
                                                                    } else {
                                                                        const filteredList = configurationsData.filter((item, itemIndex) => itemIndex !== index)
                                                                        handleChangeConfiguration(filteredList)
                                                                    }
                                                                }
                                                                }>Delete Configuration</a>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                </div>
            </div>}
            {
                showConfigModal &&
                < ConfigurationModal
                    show={showConfigModal} closeModal={() => {
                        setSelectedConfig({})
                        setSelectedConfigIndex();
                        setShowConfigModal(false)
                    }} style={style}
                    getConfig={getConfig}
                    uuid={uuid}
                    selectedConfig={selectedConfig}
                    setConfigurations={setConfigurations}
                    configurationsData={configurationsData}
                    unitTypes={unitType}
                    handleChangeUnitType={handleChangeUnitType}
                    inventory={inventory}
                    preferredTenent={preferredTenent}
                    selectedConfigIndex={selectedConfigIndex}
                />
            }
        </>
    )
}

export default Configuration
