import React, { useState } from 'react';
import { ReactComponent as HeaderMenu } from '../../../assets/icons/HeaderMenu.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/NoteIcon.svg';
import { ReactComponent as InfoRedIcon } from '../../../assets/icons/InfoRed.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/PrimaryRight.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import VerifyDetials from '../Modal/VerifyDetials';
import { toINRFormat } from '../../../helpers/helpers';
import moment from 'moment';
import ViewCollection from '../Modal/ViewCollection';
import { Link } from 'react-router-dom';

const CollectionsCard = ({
    item,
    setShowViewNotes,
    setSelectedCollection,
    setShowVerifyModal,
    setShowReassignModal,
    allowedPermission,
    setShowAddCollectionModal,
    currentTab,
    selectedProject,
    setShowDeleteModal
}) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <div className='border border-grayLight !p-4 flex flex-col shadow-boxshadow rounded-lg bg-white'>
            <div className='flex flex-row !border-b !border-grayLight !pb-3'>
                <div className='w-5/12'>
                    <Link to={`/collection-manager/detail/${item?.booking_uuid}?project=${selectedProject?.label}&tab=${currentTab}`}
                        className='text-xl inter text-textblack mb-0 font-semibold !leading-7'>
                        <span className='text-black700 mr-1'>
                            #{item?.collection_unique_id}
                        </span> {item?.customer_name}
                    </Link>
                    <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium !mt-2'>
                        <div className='flex items-center'>
                            {item?.collection_approval_status === 'pending' && <p
                                className='m-0 text-[#171725] rounded-lg p-1 bg-[#FFCE3E] flex items-center'

                            >
                                Pending
                            </p>}
                            {/* {item?.approval_status === 'verified' && <p className='m-0'>
                            Verified
                        </p>} */}
                            {item?.collection_approval_status === 'verified' && <p className='m-0 text-white bg-green p-1 rounded-lg'>
                                Verified
                            </p>}
                            {item?.collection_approval_status === 'approved' && <p className='m-0 text-white bg-green p-1 rounded-lg'>
                                Approved
                            </p>}
                            {item?.collection_approval_status === 'rejected' && <p className='m-0 text-white p-1 bg-red rounded-lg'>
                                Rejected
                            </p>}
                            <div className='ml-2 flex items-center'>
                                <span className='mr-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.62125 0.19236C4.75425 -0.519097 6.90971 0.817014 7.50842 2.98023C7.91639 4.45429 7.53002 5.99994 6.58787 7.05833C6.78083 7.30824 6.99168 7.54852 7.22057 7.77759C7.44836 8.00554 7.6872 8.2156 7.93554 8.40791C8.99355 7.45831 10.5439 7.0676 12.0222 7.47736C14.185 8.07685 15.5186 10.234 14.8082 12.3669C14.3422 13.7663 13.4731 14.6166 12.314 14.8965C11.237 15.1566 10.0758 14.8801 9.05163 14.4544C6.98887 13.5969 4.95202 11.9078 4.02179 10.9769C3.0696 10.024 1.38785 7.98421 0.538138 5.92533C0.116188 4.90293 -0.155499 3.74602 0.105165 2.67396C0.385601 1.52059 1.23155 0.655861 2.62125 0.19236ZM5.80821 3.45079C5.44419 2.13556 4.23468 1.51388 3.17944 1.86585C2.25186 2.17522 1.93014 2.63512 1.81935 3.09076C1.68879 3.62772 1.80232 4.36423 2.16885 5.25232C2.89608 7.01443 4.40431 8.86392 5.26968 9.72993C6.11411 10.575 7.96164 12.0908 9.7288 12.8254C10.6196 13.1957 11.3597 13.3121 11.8999 13.1817C12.3581 13.071 12.8221 12.7474 13.1345 11.8095C13.4868 10.7517 12.8641 9.54135 11.551 9.17739C10.5737 8.90652 9.56904 9.22035 8.97109 9.86073C8.58497 10.2743 7.84895 10.51 7.22273 10.0729C6.78212 9.76533 6.36367 9.41583 5.97268 9.02455C5.58107 8.63265 5.23143 8.21319 4.92382 7.7716C4.48947 7.14805 4.72124 6.41489 5.13177 6.02786C5.76741 5.42861 6.07814 4.42608 5.80821 3.45079Z" fill="#92929D" />
                                    </svg>
                                </span>
                                {item?.customer_mobile}
                            </div>
                        </div>
                        <div className='w-2 h-2 rounded-full bg-black700'></div>
                        <div>{item?.unit_name} {item?.plot_area && `, ${item.plot_area} ${item.plot_area_unit}`} {item?.block && `, B-${item?.block}`}, {item?.floor_no && `, F-${item?.floor_no}`}</div>
                        {/* <div className='w-2 h-2 rounded-full bg-black700'></div> */}
                        {/* <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
                            <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                                B
                            </span>
                            Sham Associates
                        </div> */}
                    </div>
                    <div className='!mt-2 flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium '>
                        {/* <div>#{item?.collection_unique_id}</div> */}
                        {/* <div className='w-2 h-2 rounded-full bg-black700'></div> */}
                        {item?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
                            <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                                B
                            </span>{item?.channel_partner_name}
                        </div>}
                        {!item?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
                            <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                                S
                            </span>{item?.assigned_user_name}
                        </div>}
                        <div className='w-2 h-2 rounded-full bg-black700'></div>
                        <div>{item?.payment_plan || '-'}</div>
                    </div>
                </div>
                <div className='w-7/12'>
                    <div className='flex gap-x-6 items-center justify-end'>
                        {/* {item?.collection_approval_status === 'pending' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-[#FBBC05] font-semibold m-0 leading-6 text-right'>Pending</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>
                                Status
                            </div>
                        </div>}
                        {item?.collection_approval_status === 'approved' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-green font-semibold m-0 leading-6 text-right'>Approved</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                Status
                            </div>
                        </div>}
                        {item?.collection_approval_status === 'verified' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-green font-semibold m-0 leading-6 text-right'>Verified</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                Status
                            </div>
                        </div>}
                        {item?.collection_approval_status === 'rejected' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-red font-semibold m-0 leading-6 text-right inline-flex items-center gap-x-1'><InfoRedIcon />Rejected</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                Status
                            </div>
                        </div>} */}
                        <div className='flex flex-col gap-y-2'>
                            <div>
                                <OverlayTrigger
                                    placement="top"

                                    overlay={
                                        <Tooltip id="button-tooltip " className='autowidht' >
                                            <div className='info-msg'>
                                                <p className='m-0'>Sender Bank : {item?.sender_bank_name}, Acc no. {item?.sender_bank_account}</p>
                                                <p className='m-0'>Receiver Bank : {item?.receiver_bank_name}, Acc no. {item?.receiver_bank_account}</p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <h3 className='inter text-base !text-black font-semibold m-0 leading-6 text-right hover:!text-primary cursor-pointer float-right'>
                                        View
                                    </h3>
                                </OverlayTrigger>
                            </div>


                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div>Bank Details</div>

                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2 text-right'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 uppercase'>{item?.transaction_mode || '-'}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div>Mode</div>

                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2 text-right'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{(item?.final_price || item?.agreement_price || 0) ? '₹' + `${toINRFormat((item?.final_price || item?.agreement_price || 0))}` : '-'}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div> Net Purchase Price </div>

                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2 text-right'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.amount_received_date ? moment(item?.amount_received_date).format('Do MMM YYYY') : '-'}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div>Received Date</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2 text-right'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.collection_amount ? `₹ ${toINRFormat(item?.collection_amount)}` : '-'}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div>Amt. Received</div>
                            </div>
                        </div>
                        <div>
                            <div class="dropdown view-more ">
                                <button class="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <HeaderMenu />
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                    <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                    {allowedPermission.includes('add_collection') && <a className='dropdown-item' onClick={() => {
                                        setSelectedCollection(item)
                                        setShowAddCollectionModal(true)
                                    }} >Add New Collection</a>}
                                    {/* {allowedPermission.includes('view_collection') && <a className='dropdown-item'>View all Collection</a>} */}
                                    {/* {allowedPermission.includes('add_collection') && <div className="head-itme pb-2 !mt-2 mb-0">Edit <span></span></div>} */}
                                    {allowedPermission.includes('re_assign_booking_single') && <a
                                        className='dropdown-item'
                                        onClick={() => {
                                            setSelectedCollection(item)
                                            setShowReassignModal(true)
                                        }}
                                    >Re- assign Booking</a>}
                                    {allowedPermission.includes('delete_booking') && <a
                                        class="dropdown-item !text-red hover:!bg-red hover:!text-white"
                                        onClick={() => {
                                            setSelectedCollection(item);
                                            setShowDeleteModal(true);
                                        }}
                                    >Delete Booking</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-row gap-x-3 !mt-3 inter'>
                <div className='w-[140px] h-[104px] grow-0 shrink-0 basis-[140px] !border !border-grayLight shadow-boxshadow rounded-lg !py-2 !px-3 flex flex-col'>
                    <h2 className='flex items-center text-sm text-black700 font-medium leading-5 border-b border-grayLight !pb-2 m-0'><span className='w-4'><NoteIcon /></span> Note</h2>
                    <div className='flex w-100 h-100 justify-center items-center'>
                        <button
                            className='text-textblack font-semibold text-base inter hover:!text-primary cursor-pointer'
                            onClick={() => {
                                setShowViewNotes(true);
                                setSelectedCollection(item)
                            }}
                        >
                            View Note</button>
                    </div>
                </div>
                <div className='!border !border-grayLight shadow-boxshadow rounded-lg !py-2 !px-3 flex flex-col inter w-100'>
                    <div className='flex flex-row justify-between items-center !pb-2 border-b border-grayLight'>
                        <h6 className='text-sm font-medium leading-5 inter text-black700 m-0'>Collection Details</h6>
                        <button className='text-sm font-medium leading-5 inter !text-primary m-0 flex flex-row items-center gap-x-1' onClick={() => {
                            setSelectedCollection(item);
                            setShowVerifyModal(true);
                        }}>
                            {item?.collection_approval_status === 'approved' ? 'View' : 'Verify'} Details <RightArrow /></button>

                    </div>
                    <div className='flex gap-x-14 !pt-2'>
                        <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_unique_id ? `#${item?.demand_unique_id}` : '-'}</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Demand ID
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_raised_date ? moment(item?.demand_raised_date).format('Do MMM YYYY') : '-'}</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Raise Date
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_amount ? `₹ ${toINRFormat(item?.demand_amount)}` : '-'}</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Demand Raised
                            </div>
                        </div>
                        {/* <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>23 Jun 23</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Raise Date
                            </div>
                        </div> */}
                        <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_milestone_no ? item?.demand_milestone_no?.join(', ') : '-'}</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Milestone
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.transaction_id || '-'}</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Transaction No.
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.collection_date ? moment(item?.collection_date).format('Do MMM YYYY') : '-'}</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Added Date
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_raised_by || '-'}</h3>
                            <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                Added By
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* {showModal && <VerifyDetials show={showModal} closeModal={() => setShowModal(false)} />} */}
            {showModal && <ViewCollection show={showModal} closeModal={() => setShowModal(false)} />}
        </div>
    )
}

export default CollectionsCard
