import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ViewlistIcon } from '../../assets/icons/Viewlist.svg';
import { ReactComponent as ViewgridIcon } from '../../assets/icons/Viewgrid.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as HeaderMenu } from '../../assets/icons/HeaderMenu.svg';
import Booking from '../../components/Collection-Manager/Booking';
import Collections from '../../components/Collection-Manager/Collections';
import Demand from '../../components/Collection-Manager/Demand';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import Searchbox from '../../components/InputGroup/Searchbox';
import InputSelect from '../../components/InputGroup/InputSelect';
import { getProjects } from '../../services/private/company.service';
import { getBookingsForCollectionManager, getCollectionManagerStats, getCollectionsByProjectId, getDemandsByProjectId } from '../../services/private/inventory.service';
import Consumer from '../../helpers/context';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '34px',
        height: '34px',
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',

        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const getStatsCount = (value, stats) => {
    const findStat = stats.find(item => item.approval_status === value);
    return findStat?.count || 0
}

const totalStats = (stats) => {
    let sum = 0;
    stats.forEach(item => {
        if (item.count) {
            sum += parseInt(item.count)
        }
    });
    return sum;
}

const CollectionManager = () => {
    const [isGridViewActive, setGridViewActive] = useState(true);
    const [isListViewActive, setListViewActive] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [showingResults, setShowingResults] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [allBookings, setAllBookings] = useState([]);
    const [allCollections, setAllCollections] = useState([]);
    const [allDemand, setAllDemand] = useState([]);
    const [overAllStats, setOverAllStats] = useState({});
    const [bookingStats, setBookingStats] = useState([]);
    const [collectionsStats, setCollectionsStats] = useState([]);
    const [demandsStats, setDemandsStats] = useState([]);
    const [currentTab, setCurrentTab] = useState('booking');
    const [collectionStatus, setCollectionStatus] = useState(null);
    const [demandStatus, setDemandStatus] = useState(null);
    const [leadsCount, setLeadsCount] = useState()
    const [maxPage, setMaxPage] = useState(1);

    const { userProfile } = useContext(Consumer);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const getAllProjects = async () => {
        const res = await getProjects();
        if (res.data.status === 200) {
            const mapped = res.data.data.projects.length > 0 && res.data.data.projects.map(item => ({ ...item, label: item.name, value: item.uuid }))
            setProjects(mapped);

            if (localStorage.getItem('collectionProject')) {
                setSelectedProject(JSON.parse(localStorage.getItem('collectionProject')));
            } else if (!searchParams.get('project') && !localStorage.getItem('collectionProject')) {
                setSelectedProject(mapped[0]);
                localStorage.setItem('collectionProject', JSON.stringify(mapped[0]))
            }
            // getProjectUnitLists(mapped[0].value);
        }
    }

    const bookingCollections = async () => {
        const payload = {
            query: {
                status: selectedStatus,
                searchText
            }
        }

        const res = await getBookingsForCollectionManager(selectedProject.value, showingResults, currentPage, payload);
        if (res.data.status === 200) {
            setAllBookings(res.data.data.bookings);
            setBookingStats(res.data.data.stats);
            let stats = 0
            if (selectedStatus) {
                stats = getStatsCount(selectedStatus, res.data.data.stats);
            } else {
                stats = totalStats(res.data.data.stats)
            }
            const totalPages = Math.ceil(
                Number(stats) / showingResults
            );
            setMaxPage(totalPages);
            setLeadsCount(stats);
        }
    }

    const getCollectionsDetails = async () => {
        const payload = {
            query: {
                status: collectionStatus,
                searchText
            }
        }
        const res = await getCollectionsByProjectId(selectedProject.value, showingResults, currentPage, payload);
        if (res.data.status === 200) {
            setAllCollections(res.data.data.collections);
            setCollectionsStats(res.data.data.stats)
            let stats = 0;
            if (collectionStatus) {
                stats = getStatsCount(collectionStatus, res.data.data.stats);
            } else {
                stats = totalStats(res.data.data.stats)
            }

            const totalPages = Math.ceil(
                Number(stats) / showingResults
            );
            setMaxPage(totalPages);
            setLeadsCount(stats);
        }
    }

    const getDemandDetails = async () => {
        const payload = {
            query: {
                status: demandStatus,
                searchText
            }
        }
        const res = await getDemandsByProjectId(selectedProject.value, showingResults, currentPage, payload);
        if (res.data.status === 200) {
            setAllDemand(res.data.data.demands);
            setDemandsStats(res.data.data.stats);
            let stats = 0
            if (demandStatus) {
                stats = getStatsCount(demandStatus, res.data.data.stats);
            } else {
                stats = totalStats(res.data.data.stats)
            }

            const totalPages = Math.ceil(
                Number(stats) / showingResults
            );
            setMaxPage(totalPages);
            setLeadsCount(stats);
        }
    }

    const getStats = async (uuid) => {
        const res = await getCollectionManagerStats(uuid);
        if (res.data.status === 200) {
            setOverAllStats(res.data.data);
        }
    }

    useEffect(() => {
        if (selectedProject?.uuid) {
            getStats(selectedProject.uuid)
        }
    }, [selectedProject]);

    useEffect(() => {
        getAllProjects();
        if (localStorage.getItem('collectionProject')) {
            setSelectedProject(JSON.parse(localStorage.getItem('collectionProject')));
        }
    }, [])

    useEffect(() => {
        if (searchParams.get('project') && searchParams.get('tab') && projects.length) {
            const pj = projects.find(p => p?.label === searchParams.get('project'));
            setCurrentTab(searchParams.get('tab'));
            setSelectedProject(pj);
        }
    }, [searchParams.get('project'), searchParams.get('tab'), projects]);

    useEffect(() => {
        if (selectedProject?.value) {
            if (currentTab === 'booking') {
                bookingCollections(selectedProject.value);
            }
            if (currentTab === 'collection') {
                getCollectionsDetails(selectedProject.value);
            }
            if (currentTab === 'demand') {
                getDemandDetails(selectedProject.value);
            }
        }

    }, [selectedStatus, searchText, showingResults, currentPage, selectedProject, collectionStatus, demandStatus])

    return (
        <div className='flex h-100 flex-col w-100'>
            <div className='bg-white !border-b !border-grayLight !pt-5 sm:!px-5 !px-4'>
                <div className='flex flex-row items-center'>
                    <div className='w-6/12'>
                        <h1 className='inter text-xl font-semibold text-textblack mb-0'>Collection Manager</h1>
                    </div>
                    <div className='w-6/12 '>
                        <div className='flex flex-row gap-x-4 items-center justify-end '>
                            <div className='w-[218px] shrink-0 grow-0 basis-[218px]'>
                                <InputSelect
                                    styles={customStyles}
                                    value={selectedProject}
                                    onChange={(val) => {
                                        setSelectedProject(val);
                                        localStorage.setItem('collectionProject', JSON.stringify(val))
                                    }}
                                    options={projects}
                                />
                            </div>
                            <div className='w-[218px] shrink-0 grow-0 basis-[218px]'>
                                <Searchbox
                                    inputClass='!pl-8 !py-1.5 !pr-8 text-sm leading-5 rounded'
                                    searchIconClass='w-5 h-5'
                                    placeholder='Search'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    handelClear={() => setSearchText('')}

                                />

                            </div>
                            {/* Grid view / list view */}
                            {/* <div>
                                <div className='flex flex-row !border border-grayLight whitespace-nowrap h-8 rounded'>
                                    <button className='flex items-center !py-1 !px-2 gap-x-1 border-r border-grayLight text-sm text-black700 tracking-[0.5px] inter bg-black200'><ViewgridIcon />Grid</button>
                                    <button className='flex items-center !py-1 !px-2 gap-x-1 border-l border-grayLight text-sm text-black700 tracking-[0.5px] inter'><ViewlistIcon />List</button>
                                </div>
                            </div> */}
                            {/* Filter */}
                            {/* <div className='filter-btn'>
                                <button className='flex flex-row items-center gap-x-1 text-sm font-medium leading-6 text-black700 px-3 py-1 hover:bg-black200 rounded inter'><FilterIcon />Filter</button>
                            </div> */}
                            {/* Triple dot */}
                            {/* <div className=''>
                                <div className="dropdown view-more ">
                                    <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <HeaderMenu />
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                        <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                        <a className="dropdown-item" type="button">Add New Collection</a>
                                        <a className="dropdown-item" type="button">Add New Booking</a>
                                        <a className="dropdown-item" type="button">Raise New Demand</a>
                                        <div className="head-itme  !mt-2 whitespace-nowrap">Bulk Action <span></span></div>
                                        <a className="dropdown-item" type="button">Re- assign Lead</a>
                                        <a className="dropdown-item !text-red hover:!bg-red hover:!text-white" type="button">Delete Lead</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='!mt-4'>
                    <nav className='tabs-bottom-active-border'>
                        <div className="nav nav-tabs border-0 !mb-2 gap-x-6" id="nav-tab" role="tablist">
                            <a
                                className="nav-item inter !text-base font-medium !text-textblack !p-0 border-0 m-0 nav-link hover:!text-primary active"
                                id="nav-booking-tab"
                                data-toggle="tab"
                                href="#nav-booking"
                                role="tab"
                                aria-controls="nav-booking"
                                aria-selected="true"
                                onClick={() => {
                                    setCurrentTab('booking')
                                    bookingCollections(selectedProject.value)
                                }}
                            >Booking</a>
                            {userProfile?.role?.allowed_permissions?.includes('view_collection') && <a
                                className="nav-item inter !text-base font-medium !text-textblack !p-0 border-0 m-0 nav-link hover:!text-primary"
                                id="nav-collections-tab"
                                data-toggle="tab"
                                href="#nav-collections"
                                role="tab"
                                aria-controls="nav-collections"
                                aria-selected="false"
                                onClick={() => {
                                    setCurrentTab('collection')
                                    getCollectionsDetails(selectedProject.value)
                                }}
                            >Collections</a>}
                            {userProfile?.role?.allowed_permissions?.includes('view_raised_demand') && <a
                                className="nav-item inter !text-base font-medium !text-textblack !p-0 border-0 m-0 nav-link hover:!text-primary"
                                id="nav-demand-tab"
                                data-toggle="tab"
                                href="#nav-demand"
                                role="tab"
                                aria-controls="nav-demand"
                                aria-selected="false"
                                onClick={() => {
                                    setCurrentTab('demand')
                                    getDemandDetails(selectedProject.value)
                                }}
                            >Demand</a>}
                        </div>
                    </nav>

                </div>
            </div>
            <div className='flex h-100 overflow-x-hidden overflow-y-auto bg-black100 sm:!p-5 !p-4'>
                <div className='w-100'>
                    <div className="tab-content w-100" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-booking" role="tabpanel" aria-labelledby="nav-booking-tab">
                            <Booking
                                allBookings={allBookings}
                                overAllStats={overAllStats}
                                bookingStats={bookingStats}
                                setSelectedStatus={setSelectedStatus}
                                bookingCollections={bookingCollections}
                                selectedProject={selectedProject}
                                allowedPermission={userProfile?.role?.allowed_permissions || []}
                                currentTab={currentTab}
                            />
                        </div>
                        <div className="tab-pane fade" id="nav-collections" role="tabpanel" aria-labelledby="nav-collections-tab">
                            <Collections
                                allCollections={allCollections}
                                overAllStats={overAllStats}
                                collectionsStats={collectionsStats}
                                setCollectionStatus={setCollectionStatus}
                                getCollectionsDetails={getCollectionsDetails}
                                bookingCollections={bookingCollections}
                                selectedProject={selectedProject}
                                allBookings={allBookings}
                                allowedPermission={userProfile?.role?.allowed_permissions || []}
                                currentTab={currentTab}
                            />
                        </div>
                        <div className="tab-pane fade" id="nav-demand" role="tabpanel" aria-labelledby="nav-demand-tab">
                            <Demand
                                allDemand={allDemand}
                                overAllStats={overAllStats}
                                demandsStats={demandsStats}
                                setDemandStatus={setDemandStatus}
                                selectedProject={selectedProject}
                                getDemandDetails={getDemandDetails}
                                allowedPermission={userProfile?.role?.allowed_permissions || []}
                                currentTab={currentTab}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <LeadsFooterBar
                    classname='!relative w-100 '
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    showingResults={showingResults}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                />
            </div>
        </div >
    )
}

export default CollectionManager
