import React from 'react'
import CenterModal from './center-modal'
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as AddyourlistImage } from '../../assets/addyourlist.svg';
import contactImg from '../../assets/contactmodal.png'


const ContactModal = ({ show, closeModal, style, item, handleContact }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='AddYourListModal projectCotactModal'>

            <div className={`${style.AddYourListHeader} d-flex align-items-center justify-content-between`}>
                <h2 className='fz18 black fw-po-medium mb-0 inter'>Contact: {item.name}</h2>
                <button onClick={closeModal} className='border-0 bg-tr'><CloseIcon /></button>
            </div>
            <div className={`${style.AddYourListBody}`}>
                <figure className='mb-0'>
                    <img src={contactImg} alt="" className='d-table mx-auto' />
                </figure>
                <h1 className='mb-12 fz20 black inter'>Thank you for submitting your query.</h1>
                <p className='fz16 inter fw-po-medium'>Our team is currently reviewing your request, and we will do our best to respond as soon as possible.</p>
            </div>
            <div className={`${style.AddYourListfooter}`}>

                <button className='pr-btn w-100 mt-0' onClick={() => handleContact(item.uuid)}>Got It</button>
            </div>
        </CenterModal>
    )
}

export default ContactModal
