import React, { useState } from 'react'
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import AddCollection from '../Modal/AddCollection';
import AddDemand from '../Modal/AddDemand';
import { getBookingPaymentMilestonesByBookingId } from '../../../services/private/inventory.service';
import { toINRFormat } from '../../../helpers/helpers';
import moment from 'moment';
import TooltipText from '../../Tooltip';

const DemandRaise = ({ item, getPaymentMilestones, setSelectedItem, allowedPermission }) => {

    return (
        // bg-red/10 !border-red  add these color after date pass
        <div className={` !py-2 !px-3 !border max-w-[300px] w-[300px] shadow-boxshadow rounded-lg`}>
            <div className='flex justify-between inter border-b border-grayLight !pb-2'>
                <div className=' text-sm font-medium text-black700 leading-5'>Demand Raised</div>
                {allowedPermission.includes('raise_demand') && <button
                    className='inline-flex items-center gap-x-1 text-xs !text-primary font-medium leading-4 justify-end cursor-pointer'
                    onClick={() => {
                        setSelectedItem(item);
                        getPaymentMilestones(item?.booking_uuid)
                    }}
                >
                    <PlusPrimary className='w-3 h-3' />  Raise Demand
                </button>}
            </div>
            <div className='flex flex-col justify-between inter !pt-2'>
                <div className='flex flex-row justify-between'>
                    <h3 className='text-textblack text-base font-semibold !mb-2 leading-6'>{item.demand_amount ? `₹ ${toINRFormat(item.demand_amount)}` : '-'}</h3>
                    {item?.demand_paid_status !== 'full' && <h3
                        className={`!text-red text-sm font-medium  leading-5 capitalize text-right'`}
                    >
                        Collection Pending
                    </h3>}
                    {/* <h3 className={`${(item?.demand_paid_status && item?.demand_paid_status !== 'pending') ? '!text-green' : '!text-red '} text-sm font-medium  leading-5 capitalize text-right'`}>{(item?.demand_paid_status && item?.demand_paid_status !== 'pending') ? item?.demand_paid_status : 'Collection Pending'}</h3> */}

                </div>
                <div className='flex flex-row justify-between'>
                    <p className='text-xs text-black700 font-medium leading-4 mb-0'>{item?.demand_date ? moment(item?.demand_date).format('Do MMM YYYY') : ''}</p>

                    {item?.demand_milestone_no && <TooltipText title={item?.payment_plan}>
                        <p className='text-xs text-black700 font-medium leading-4 text-right mb-0'>Milestones: {item?.demand_milestone_no?.join(', ')}</p>
                    </TooltipText>}
                </div>
            </div>

        </div>
    )
}

export default DemandRaise;
