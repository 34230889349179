import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MainContainer from "./components/Layouts/Container/MainContainer";
import App from "./modules/App/routes";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./config/store";
import "./media.css"
import "./global.css";
import "./calander.css";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-phone-input-2/lib/style.css";
import { subscribeUser } from "./subscription";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <MainContainer>
      <App />
    </MainContainer>
    <ToastContainer
      position="bottom-right"
      autoClose={4000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </Provider>
);

// serviceWorker.unregister();

// subscribeUser();
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}
