import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as PropacityLogo } from '../../assets/propacityplush.svg';
import { ReactComponent as RightIcon } from '../../assets/chevron-right.svg';
import { ReactComponent as Offericon } from '../../assets/offericon.svg';
import { ReactComponent as Propacityfav } from '../../assets/propacityfav.svg';
import { ReactComponent as BankIcon } from '../../assets/bank.svg';
import { ReactComponent as SucessIcon } from '../../assets/ic_sucess.svg';
import { applyCouponSubscription, createOrder, getOrderStatus } from '../../services/public/public.service';
import useRazorpay from "react-razorpay";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { API_URLS } from '../../config/apiUrl';
import axios from 'axios';
import CouponAppliedModal from '../Settings/Modal/CouponAppliedModal';
import PaymentSuccessModal from './Modal/PaymentSuccessModal';
import InputSelect from '../../components/InputGroup/InputSelect';

const calculateVal = (amount, percente) => {
    return (amount * (percente / 100))
}

const PAYMENT_METHOD = [
    {
        label: 'UPI',
        value: 'upi'
    },
    {
        label: 'Card',
        value: 'card'
    }
]

const Offers = ({ setComponentName,
    page,
    selectedPlan,
    setSelectedOffer,
    code,
    setCode,
    selectedOffer,
    uuid,
    paymentFrom,
    redirectTo,
    closeModal,
    referralCode,
}) => {

    const [paymentStatus, setPaymentStatus] = useState('');
    const [showCouponAppliedModal, setShowCouponAppliedModa] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD[0].value);

    const [Razorpay] = useRazorpay();

    const history = useHistory();

    useEffect(() => {
        if (code) {
            setShowCouponAppliedModa(true)
        }
    }, [code]);

    const discount = useMemo(() => {
        if (Object.keys(selectedPlan).length > 0 && (Object.keys(selectedOffer).length > 0 || code)) {
            let amount = selectedPlan?.base_amount ? selectedPlan?.base_amount : selectedPlan?.amount
            let percentage = selectedOffer?.discount_percentage || selectedPlan?.name === 'Annual' ? 20 : 10
            return calculateVal(parseFloat(amount), parseFloat(percentage)).toFixed(1);
        }
    }, [selectedPlan, selectedOffer, code]);

    const gstAmount = useMemo(() => {
        let planAmount = selectedPlan?.base_amount ? parseFloat(selectedPlan?.base_amount) : parseFloat(selectedPlan?.amount)
        return calculateVal((parseFloat(planAmount) - parseFloat(discount || 0)), 18).toFixed(1);
    }, [selectedPlan, discount]);

    const totalAmount = useMemo(() => {
        let totalAmount = selectedPlan?.base_amount ? parseFloat(selectedPlan?.base_amount) : selectedPlan?.amount
        totalAmount -= (parseFloat(discount) || 0);
        totalAmount += (parseFloat(gstAmount) || 0);
        return parseFloat(totalAmount).toFixed(1);

    }, [gstAmount, discount, selectedPlan])

    const getPaymentStatus = async (orderId) => {
        const res = await getOrderStatus(orderId);
        if (res.data.status === 200) {
            setPaymentStatus(res.data.data.stauts)
            // if (res.data.data.stauts === 'paid') {

            //     if (paymentFrom === 'modal') {
            //         closeModal();
            //     }
            // }
        }
    }

    const handleApplyCoupon = async (code) => {
        const payload = {
            subscription_id: selectedPlan.uuid,
            code: code
        }
        try {
            const res = await applyCouponSubscription(uuid, payload);
            if (res.data.status === 200) {
                setCode(code);
            }
        } catch (err) {
            setCode('');
            toast.error(err.response.data.message);
        }
    }

    useEffect(() => {
        if (selectedPlan.uuid && referralCode) {
            handleApplyCoupon(referralCode)
        }
    }, [referralCode, selectedPlan]);

    const handleSubmit = async () => {
        const payload = {
            subscription_id: selectedPlan.uuid,
            code: code,
            payment_method: paymentMethod
        }
        let res = {};
        setLoading(true);
        try {
            if (!uuid) {
                res = await createOrder(payload);
            } else {
                res = await axios.post(`${API_URLS.baseurl}${API_URLS.company.createOrder}/${uuid}`, payload);
            }
            setLoading(false);
            if (res.data.status === 200) {
                window.open(res.data.data.short_url, '_self');
            }
            // if (res.data.status === 200 && res.data.data?.link) {
            //     let name = localStorage.getItem('name');
            //     let email = localStorage.getItem('email');
            //     let contact = localStorage.getItem('mobile');
            //     console.log(`${res.data.data?.link}?name=${name}&phone=${contact}&email=${email}`, 'link')
            //     const link = document.createElement("a");
            //     console.log(link, 'tag')
            //     link.href = `${res.data.data?.link}?name=${name}&phone=${contact}&email=${email}`;
            //     // link.href = `${res.data.data?.link}/view?name=${name}&phone=${contact}&email=${email}`;
            //     // link.href = `https://pages.razorpay.com/pl_Nrm3vrS42qskco/view?name=${name}&phone=${contact}&email=${email}`;
            //     // link.download = `${item.name}`;
            //     link.click();
            // }
            // if (res.data.status === 200 && res.data.data.order_id) {
            //     const options = {
            //         // key: 'rzp_test_y32zmeuiS8ZLml ', // development
            //         key: 'rzp_live_xDM3yUq8d0oJs1',
            //         // amount: totalAmount, // Amount in paise
            //         currency: 'INR',
            //         name: 'Propacity',
            //         description: 'Test Transaction',
            //         // image: 'https://your-company-logo.png',
            //         order_id: res.data.data.order_id,
            //         handler: (response) => {
            //             console.log(response)
            //             if (response?.razorpay_payment_id) {
            //                 toast.success('Payment success.')
            //                 setShowPaymentSuccessModal(true)
            //                 let count = 1
            //                 let timer = setInterval(() => {
            //                     if (count > 3 || paymentStatus === 'paid') {
            //                         clearInterval(timer);
            //                         // setDisableResend(false);

            //                     } else {
            //                         count = count + 1;
            //                         if (!uuid) {
            //                             getPaymentStatus(res.data.data.order_id)
            //                         }
            //                     }
            //                 }, 2000)
            //             }
            //         },
            //         prefill: {
            //             name: localStorage.getItem('name'),
            //             email: localStorage.getItem('email'),
            //             contact: localStorage.getItem('mobile')
            //         }
            //     };

            //     const razorpay = new Razorpay(options);
            //     razorpay.open();
            // }

        } catch (err) {
            setLoading(false);
            toast.error(err.response.data.message);
        }
    }
    return (
        <div className={`bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-14 pb-8 !px-6 grow-0 shrink-0 ${page === 'inner' ? 'w-full' : 'w-11/12 shadow-shadowcard'} mt-10 `}>
            <div className=''>
                <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>

                <div>
                    <div className='!mb-4 font-semibold text-textblack'>Offers & Coupon</div>
                    {!code && <div className='flex justify-between items-center rounded-xl !border-black400 !border !py-3 !px-4 cursor-pointer' onClick={() => setComponentName('coupons')}>
                        <div className='inline-flex items-center !gap-2 text-textblack'>
                            <span><Offericon /></span>
                            Apply Coupon/Referral
                        </div>
                        <div className='cursor-pointer '><RightIcon /></div>
                    </div>}
                    {code && <div className='flex md:flex-row flex-col md:justify-between md:items-center items-start md:gap-y-0 gap-y-2 rounded-xl !border-black400 !border !py-3 !px-4'>
                        <div className='inline-flex items-center !gap-2 text-black700 text-sm'>
                            <span><SucessIcon /></span>
                            <span className='text-textblack font-semibold'>₹{discount}</span> saved with ‘{code}’
                        </div>
                        <button className='text-blue-900 font-semibold !px-2 md:ml-0 ml-auto' onClick={() => {
                            setCode('');
                            setSelectedOffer({});
                        }}>Remove</button>
                    </div>}
                </div>

                <div className='!mt-6'>
                    <div className='!mb-4 font-semibold text-textblack'>Bill Summary</div>
                    <div className='rounded-xl !p-4 shadow-boxshadow !border border-black400 space-y-4'>
                        <div className='flex justify-between'>
                            <div className='flex items-center !gap-2 text-sm font-medium text-black800'><Propacityfav />Premium Subscription</div>
                            <div className='text-sm text-black800 font-medium'>₹{parseFloat(selectedPlan?.base_amount)}</div>
                        </div>
                        {discount && <div className='flex justify-between text-blue-900'>
                            <div className='flex items-center !gap-2 text-sm font-medium '
                            >
                                {/* <BankIcon /> */}
                                Coupon - <span>({code})</span>
                            </div>
                            <div className='text-sm font-medium'>- ₹{discount}</div>
                        </div>}
                        <div className='flex justify-between'>
                            <div className='flex items-center !gap-2 text-sm font-medium text-black800'><BankIcon />18% GST</div>
                            <div className='text-sm text-black800 font-medium'>₹{gstAmount}</div>
                        </div>
                        <div className='!border !border-t-black400 !my-3 h-px '></div>
                        <div className='flex justify-between'>
                            <div className='flex items-center !gap-2 text-sm font-semibold text-black800'>Grand Total</div>
                            <div className='text-sm text-black800 font-semibold'>₹{totalAmount}</div>
                        </div>
                    </div>
                </div>
                <div className='w-full !mt-4'>
                    <InputSelect
                        label={'Payment Method'}
                        placeholder={'Select Payment Method'}
                        value={paymentMethod ? PAYMENT_METHOD.find(i => i.value === paymentMethod) : ''}
                        onChange={(value) => setPaymentMethod(value.value)}
                    />
                </div>

                <div className='!mt-6 flex !gap-4'>
                    <button
                        className='w-1/2 shadow-white-btn-shadow focus:!shadow-white-btn-shadow !border !border-black400 text-base font-medium !rounded-lg !py-2 text-black'
                        onClick={() => {
                            setCode('');
                            setSelectedOffer({});
                            setComponentName('paymentplan')
                        }}
                    >Cancel</button>
                    <button
                        className={`w-1/2 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2`}
                        onClick={handleSubmit}
                        disabled={loading}
                    >Pay ₹{totalAmount}</button>
                </div>

            </div>
            {showCouponAppliedModal && <CouponAppliedModal show={showCouponAppliedModal} closeModal={() => setShowCouponAppliedModa(false)} code={code} discount={discount} />}
            {showPaymentSuccessModal && <PaymentSuccessModal show={showPaymentSuccessModal} closeModal={() => setShowPaymentSuccessModal(false)} paymentFrom={paymentFrom} redirectTo={redirectTo} />}
        </div>
    )
}

export default Offers
