import React, { useEffect, useState } from 'react';
import { createNoteCall, getAllNotesForLead } from '../../../services/private/leads.service';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DisplayNote from './DiplayNote';

export default function CreateNote({
    showCreateNote,
    toggleCreateNote,
    notesCount,
    reloadSideBarCount,
    leadAccess,
    userProfile,
    isRolesModule,
    allowedPermissions
}) {

    const params = useParams();

    const [notes, setNotes] = useState([]);
    const [createNote, setCreateNote] = useState('');
    const [noteInputInvalid, setNoteInputInvalid] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);

    const getAllNotes = () => {
        getAllNotesForLead(params.id).then(res => {
            if (res.status === 200) {
                setNotes(res.data.data);
            }
        })
    }

    const reloadAllNotes = () => {
        getAllNotes();
        reloadSideBarCount();
    }

    const saveNote = () => {
        if (!createNote) {
            setNoteInputInvalid(true)
            return false;
        }
        setBtnDisable(true);
        createNoteCall(params.id, createNote).then(res => {
            if (res.status === 200) {
                toast.success("Note Created")
                setCreateNote('')
                setBtnDisable(false)
                getAllNotes()
                reloadSideBarCount()
            }
        })
    }

    useEffect(() => {
        getAllNotes()
    }, [])

    return (
        <>
            <div className={`slide-sidebar note position-absolute ${showCreateNote ? "active" : "d-active"}`}>
                <div className="border-0 sidebar-title">
                    <div className="row align-itmes-center">
                        <div className="col-12">
                            <h2 className="mb-0 fz16 po black po pt-10 flex items-center">
                                <button className="border-0 bg-tr p-0 bck-btn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={toggleCreateNote}>
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#44444F" />
                                </svg></button>
                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 1H15.6482C16.2514 1 16.8224 1.27227 17.2022 1.74098L21.054 6.49524C21.3425 6.8514 21.5 7.29587 21.5 7.75425V19C21.5 21.2091 19.7091 23 17.5 23H7.5C5.29086 23 3.5 21.2091 3.5 19V5C3.5 2.79086 5.29086 1 7.5 1ZM16.125 3.58854V6.4H18.4028L16.125 3.58854ZM7.5 3C6.39543 3 5.5 3.89543 5.5 5V19C5.5 20.1046 6.39543 21 7.5 21H17.5C18.6046 21 19.5 20.1046 19.5 19V8.4H16.125C15.0204 8.4 14.125 7.50457 14.125 6.4V3H7.5Z" fill="#FF974A" />
                                </svg>
                                Notes {notesCount ? <span>{notesCount}</span> : ''}
                            </h2>
                        </div>

                    </div>
                </div>
                <div className="form-group create-new-notes">

                    <textarea col="5"
                        className="form-control mb-16"
                        placeholder="Enter your note..."
                        value={createNote}
                        onChange={(e) => {
                            setCreateNote(e.target.value)
                            setNoteInputInvalid(false)
                        }}>
                    </textarea>

                    {noteInputInvalid && <p className="text-danger">Note is required.</p>}
                    {/* Save note button */}
                    <button className="pr-btn text-center w-100 py-8" onClick={saveNote} disabled={(btnDisable || (userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view'))}>
                        Create New Note
                    </button>
                </div>

                {/* Recent Notes */}
                <div className="resent-notes mt-30 ">
                    <h1 className="black fz16 po mb-24">Recent Notes</h1>
                    {
                        notes.length > 0 ? notes.map((data, index) => {
                            return (
                                <DisplayNote
                                    note={data}
                                    key={index}
                                    index={index}
                                    notes={notes}
                                    reloadAllNotes={reloadAllNotes}
                                    leadAccess={leadAccess}
                                    userProfile={userProfile}
                                    isRolesModule={isRolesModule}
                                    allowedPermissions={allowedPermissions}
                                />
                            )
                        }) :
                            <div className="no-result-find d-flex flex-column align-items-center justify-content-center">
                                <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none">
                                    <circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF" />
                                    <path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF" />
                                    <circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974" />
                                    <circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE" />
                                    <circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974" />
                                    <path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE" />
                                </svg></figure>
                                <h2 className='po black fz16 fw-po-medium mb-8'>No Notes</h2>
                                <p className='text-center black-dark-600 fz14 mb-0'>Please add notes to see here.</p>
                            </div>
                    }

                </div>
            </div>



        </>
    )
}