export const LEAD_SOURCE = [ { value : "Facebook" , label: "Facebook" } ,  { value : "Google" ,  label: "Google" } , {  value : "Offline" ,  label: "Offline" } , { value : "Refrence" , label: "Refrence"  }]

export const LEAD_STATUS = [{ value : "Assigned" , label :"Assigned" }, { value : "In Progress" , label :"In Progress" } ,  { value : "Site Visit Scheduled" , label :"Site Visit Scheduled" } ,  { value : "Site Visit Completed" , label :"Site Visit Completed" } , { value : "EOI Completed" , label :"EOI Completed" } ,{ value : "Booking Completed" , label :"Booking Completed" }    ]

export const ALL_REGEX = {

    PHONE_NUMBER : /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    EMAIL :  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD :  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,

}