import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom';
import style from './style.module.css';

import './style.css'
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as ReraIcon } from '../../assets/icons/rera.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downloadprimry.svg';
import { ReactComponent as Downloadimg } from '../../assets/icons/download.svg';
import { ReactComponent as DownloadBlackIcon } from '../../assets/icons/downloadBlack.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as PdfICon } from '../../assets/icons/pdf.svg';
import { ReactComponent as PptIcon } from '../../assets/icons/ppt.svg';
import { ReactComponent as ExlIcon } from '../../assets/icons/exl.svg';
import { ReactComponent as TradingIcon } from '../../assets/icons/trading.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as PrimumIcon } from '../../assets/icons/primum.svg';
import { ReactComponent as DownoadkitICon } from '../../assets/icons/downoadkit.svg';
import { ReactComponent as CallIcon } from '../../assets/icons/call.svg';
import { ReactComponent as AddtomylistIcon } from '../../assets/icons/addtomylist.svg';
import { ReactComponent as BacktoTopIcon } from '../../assets/icons/backtotop.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/Pageback.svg';
import { ReactComponent as DocIcon } from '../../assets/icons/docicon.svg';
import { ReactComponent as XlIcon } from '../../assets/icons/xlIcon.svg';
import { ReactComponent as PptICon } from '../../assets/icons/pptICon.svg';
import { ReactComponent as EditICon } from '../../assets/icons/NameEdit.svg';

import Galaryimg from '../../assets/icons/ProjectcardImg.png';
import execlimg from '../../assets/icons/execl.jpg';
import videoimg from '../../assets/icons/Video.jpg'
import pptimg from '../../assets/icons/Dppt.jpg'
import docImg from '../../assets/icons/Ddoc.jpg'
import sheetImg from '../../assets/icons/Dxcel.jpg'

import pdfimg from '../../assets/icons/Dpdf.jpg';
import documnetimg from '../../assets/icons/documnet-bg.jpg';
import develoerLogo from '../../assets/icons/lunchpadLogo.svg';
import cardbg from '../../assets/DetailThumbnail.jpg';
import { addToProjectList, downloadAllMedia, getCRMProjectsDetail, getProjectSpecifications, insertProjectRegisteration } from '../../services/public/public.service';
import moment from 'moment';
import LightGalleryModal from './LightGalleryModal';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { convertToTitleCase, numDifferentiation, removeDecimalPlaces } from '../../helpers/helpers';
// import { createWorker } from 'zip-js';
// import download from 'downloadjs';
// import { saveAs } from 'file-saver';
// import AdmZip from 'adm-zip';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import ContactModal from '../../components/Modals/ContactModal';
import AddYourListModal from '../../components/Modals/AddYourListModal';
import { useHistory } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
// import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Link } from 'react-router-dom';
import { getAuthRolePermissions } from '../../services/private/company.service';
import Consumer from '../../helpers/context';
import TooltipText from '../../components/Tooltip';

const ProjectDetail = () => {

    const [details, setDetails] = useState({});
    const [showLightModal, setShowLightModal] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [media, setMedia] = useState([]);
    // For export csv
    const [tempConfig, setTempConfig] = useState([]);
    const [tempPaymentPlan, setTempPaymentPlan] = useState([]);
    const [tempBankDetails, setTempBankDetails] = useState([]);
    const [tempAdditionalCost, setTempAdditionalCost] = useState([]);
    const [showAddToListModal, setShowAddToListModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [sectionId, setSectionId] = useState();
    const [selectdImg, setSelectdImg] = useState();
    const [thumbnail, setThumbnail] = useState('');
    const [videos, setVideos] = useState([])
    const [projectPermission, setProjectPermission] = useState();
    const [transaction, setTransaction] = useState();
    const [property, setProperty] = useState();
    const [inventory, setInventory] = useState();
    const [specifications, setSpecifications] = useState([]);

    let { allowedPermissions, isRolesModule } = useContext(Consumer);

    const { uuid } = useParams();
    const divRef = useRef(null);
    const history = useHistory();

    const handleScroll = () => {
        const scrollPosition = divRef.current.scrollTop;
        setShowButton(scrollPosition > 500);
    };

    const handleClick = () => {
        divRef.current.scrollTop = 0;
    };

    const styles = {
        backgroundImage: `url(${thumbnail || cardbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepet: 'no-repet'
    };

    const getProjectDetails = async () => {
        const res = await getCRMProjectsDetail(uuid);
        if (res.data.status === 200) {
            let media = [];
            let doc = [];
            let video = []
            res.data.data.media.forEach(item => {
                if (['jpg', 'png', 'gif', 'svg', 'jpeg'].includes(item.file_name.split('.').pop())) {
                    media.push(item)
                } else if (['mp4', 'mp3', 'mkv'].includes(item.file_name.split('.').pop()) || item.type === 'youtube_video') {
                    video.push(item)
                } else {
                    doc.push(item)
                }
            })
            const cover = media.find(item => item.is_cover);
            setInventory(res.data.data.inventory_type)
            setProperty(res.data.data.property_type)
            setTransaction(res.data.data.transaction_type)
            setThumbnail(cover?.url || '');
            setMedia(media)
            setVideos(video);
            setDocuments(doc)
            // const docs = res.data.data.media.filter(item => item.type === 'application/pdf');
            setDetails(res.data.data);
            // setDocuments(docs)
        }
    }

    const getUsersAuthRolePermissions = () => {
        getAuthRolePermissions().then((res) => {
            res.data.data.map((role) => {
                if (role.module === "projects") {
                    setProjectPermission(role.permissions)
                }
            })
        })
    }

    const getSpecifications = async () => {
        const res = await getProjectSpecifications(uuid);
        if (res.data.status === 200) {
            setSpecifications(res.data.data);
        }
    }

    useEffect(() => {
        getProjectDetails();
        getUsersAuthRolePermissions();
        getSpecifications()
    }, []);

    const handleAddToList = useCallback(async () => {
        const res = await addToProjectList(uuid)
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getProjectDetails();
        }
    }, []);

    const handleShowAddToList = useCallback(() => {
        if (localStorage.getItem('dontShow') === 'true') {
            handleAddToList()
        } else {
            setShowAddToListModal(true);
        }
    }, []);

    const handleShowContactModal = useCallback(() => {
        setShowContactModal(true)
    }, []);

    const handleContact = useCallback(async () => {
        const res = await insertProjectRegisteration(uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            // setShowContactModal(false);
            getProjectDetails()
        }
    }, []);

    const scrollToHome = () => {
        const homeElement = document.getElementById('home');
        if (homeElement) {
            homeElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const homeElement = document.getElementById('home');
        if (homeElement) {
            homeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    // export csv
    const exportConfig = () => {
        let temp = details?.configurations.map((item, index) => {
            return {
                "Unit Type": item.unit_type ? convertToTitleCase(item.unit_type) : '',
                "Price": `${item.price || ''}`,
                "Floor": `${item.floor || ''}`,
                "Carpet Area": item.carpet_area ? `${item.carpet_area} ${item.carpet_area_unit}` : '',
                "Super Area": item.super_area ? `${item.super_area} ${item.super_area_unit}` : '',
                "Built Area": item.built_up_area ? `${item.built_up_area} ${item.built_up_area_unit}` : '',
                "Furnishing": item.furnishing ? convertToTitleCase(item.furnishing || '-') : '',
                "Usage Status": item.usage_status || '',
                "Facing": item.facing || '',
            }
        })
        setTempConfig(temp);

    };

    const exportPaymentPlan = () => {
        let temp = details?.paymentPlans.map((item, index) => {
            return {
                "Payment Plan Type": item.name ? item.name : '',
                "Description": item.description ? item.description : '',
                "Offers": item.offers ? item.offers : '',
                "Offer Validity": item.validity ? moment(item.validity).format('D MMMM YYYY') : '',
            }
        })
        setTempPaymentPlan(temp);
    };


    const exportBankDetails = () => {
        let temp = details?.bankDetails.map((item, index) => {
            return {
                "Bank Name": item.bank_name ? item.bank_name : '',
                "Bank Branch": item.bank_branch ? item.bank_branch : '',
                "Account No.": item.account_no ? item.account_no : '',
                "IFSC Code": item.ifsc ? item.ifsc : '',
            }
        })
        setTempBankDetails(temp);

    };

    const exportAdditionalCost = () => {
        let temp = details?.additionalCost.map((item, index) => {
            return {
                "Cost Name": item.name ? item.name : '',
                "Amount": item.amount ? item.amount : '',
                "Comments": item.comment ? item.comment : ''
            }
        })
        setTempAdditionalCost(temp);
    };
    // export csv end

    // Download media in zip file
    const downloadFiles = async () => {
        try {
            // let fileUrls = [];
            // for (let index = 0; index < details?.media.length; index++) {
            //     const detail = details?.media[index];
            //     if (detail.type !== 'youtube_video') {
            //         fileUrls.push(detail.url);
            //     }
            // }
            // fileUrls.push(...(documents.map(document => document.url)))


            const zip = new JSZip();

            // await Promise.all(
            //     fileUrls.map(async (fileUrl) => {
            //         const response = await fetch(fileUrl);
            //         const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            //         const fileData = await response.arrayBuffer();
            //         zip.file(fileName, fileData);
            //     })
            // );

            for (let index = 0; index < details?.media?.length; index++) {
                const detail = details?.media[index];
                if (detail.type !== 'youtube_video') {
                    const response = await fetch(detail.url);
                    let filename = '';
                    filename = detail.url.substring(detail.url.lastIndexOf('/') + 1);
                    if (detail.media_name) {
                        let temp = filename.split('.')
                        let extension = temp[temp.length - 1]
                        filename = `${detail.media_name}.${extension}`;
                    }
                    const fileData = await response.arrayBuffer();
                    zip.file(filename, fileData);

                }
            }

            for (let index = 0; index < documents?.length; index++) {
                const document = documents[index];
                if (document.type !== 'youtube_video') {
                    const response = await fetch(document.url);
                    let filename = '';
                    filename = document.url.substring(document.url.lastIndexOf('/') + 1);
                    if (document.media_name) {
                        let temp = filename.split('.')
                        let extension = temp[temp?.length - 1]
                        filename = `${document.media_name}.${extension}`;
                    }
                    const fileData = await response.arrayBuffer();
                    zip.file(filename, fileData);

                }
            }


            const zipContent = await zip.generateAsync({ type: 'blob' });

            // Save the zip file using file-saver
            saveAs(zipContent, `${details?.project.name}.zip`);
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };

    // const downloadFiles = async () => {
    //     try {
    //         let fileUrls = details?.media?.map(detail => detail.url)
    //         fileUrls.push(...(documents.map(document => document.url)))

    //         const folderName = 'Media'; // Name of the folder to store the downloaded files

    //         await Promise.all(
    //             fileUrls.map(async (fileUrl) => {
    //                 const response = await fetch(fileUrl);
    //                 const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    //                 const fileBlob = await response.blob();
    //                 download(fileBlob, `${folderName}/${fileName}`);
    //             })
    //         );
    //     } catch (error) {
    //         console.error('Error downloading files:', error);
    //     }
    // };

    // let sectionRef = useRef(null);

    const scrollToSection = (sectionId) => {

        // let sectionRef = useRef(null);
        let sectionElement = document.getElementById(sectionId);

        if (sectionElement) {
            // sectionRef.current = sectionElement;
            sectionElement.scrollIntoView({ behavior: 'smooth' });

        }

    }

    const scrollTop = () => {
        // document.body.scrollTop = 0;
        window.scrollTo(0, 0)
        // document.documentElement.scrollTop = 0

    }

    return (

        <div className={`${style.projectdetailPage} projectdetailPage d-flex flex-column`}>
            <div className={`${style.sticky_top} d-flex flex-col gap-y-1 `}>
                <div>
                    <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                        <ul className="d-flex align-items-center justify-content-start flex-row inter m-0">
                            <li>
                                <Link to='/projects'>Projects </Link>
                            </li>
                            <li className='flex items-center'><RightArrow />
                                <span className={`${style.msingleline} w-[360px] truncate`}>{details.project?.name}</span>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className='d-flex w-full align-items-center justify-content-between'>
                    <h1 className='fz24 lh-40 black fw-po-bold mb-0 inter d-flex align-items-center'>
                        <Link to='/projects' className={`${style.backbtn} d-md-inline-flex mr-12`}><BackIcon /></Link>
                        <TooltipText title={details.project?.name}>
                            <span className={`${style.msingleline} w-[360px] truncate`}>{details.project?.name}</span>
                        </TooltipText>

                    </h1>

                    {/* <nav id="navbar-example2" className={`${style.scrollspynavbar} navbar`} style={{ opacity: '0' }}>

                    <ul className="nav nav-pills gap-x-32 flex-nowrap">
                        <li className="nav-item">
                            <a className="nav-link scrollsyp_active" onClick={() => scrollToSection('about')}>About Project</a>
                        </li>
                        {details?.configurations?.length > 0 && <li className="nav-item">
                            <a className="nav-link" onClick={() => scrollToSection('configuration')}>Configuration / Pricing</a>
                        </li>}
                        {(details?.project?.site_address || details?.project?.google_address) && <li className="nav-item">
                            <a className="nav-link" onClick={() => scrollToSection('location')}>Location</a>
                        </li>}
                        {details?.media?.length > 0 && <li className="nav-item">
                            <a className="nav-link" onClick={() => scrollToSection('media')}>Media</a>
                        </li>}
                        {details.project?.amenities?.length > 0 && <li className="nav-item">
                            <a className="nav-link" onClick={() => scrollToSection('amenities')}>Amenities / Nearby Brands</a>
                        </li>}
                        {details?.paymentPlans?.length > 0 && <li className="nav-item">
                            <a className="nav-link" onClick={() => scrollToSection('payment')}>Payment Details</a>
                        </li>}

                        {(details.project?.developer_logo || details.project?.developer_description) && <li className="nav-item">
                            <a className="nav-link" onClick={() => scrollToSection('developerdetail')}>Developer Details</a>
                        </li>}

                    </ul>

                </nav> */}

                    {(!isRolesModule || allowedPermissions?.includes('edit_project')) && <button className={`${style.detailedit} d-md-inline-flex d-none`}
                        onClick={() => {
                            localStorage.setItem('projectStatus', 'edit');
                            localStorage.setItem('projectName', details.project?.name);
                            history.push(`/projects/create-projects/basic-details/${uuid}?pt=${details.project?.property_type}&tt=${details.project?.transaction_type}`)
                        }}
                    >
                        <EditICon /> Edit Project
                    </button>}
                </div>
            </div>

            <div className='d-flex flex-fill flex-column w-100 h-100 overflow-auto position-relative' ref={divRef} onScroll={handleScroll} data-spy="scroll" data-target="#navbar-example">
                <div className={`${style.Detailbanner}  d-flex align-items-start`} style={styles} id='home'>
                    <div className='mt-24 d-md-none d-none align-items-start gap-14 justify-content-between w-100 px-24'>
                        <a href='/projects' className={`${style.backbtn}`}><BackIcon /></a>
                        {/* <div className={`${style.bannerTags}`}><TradingIcon />Trending</div>
                    <div className={`${style.bannerTags} text-white`}><ClockIcon />13 People Contacted Today</div> */}
                        {(!isRolesModule || allowedPermissions?.includes('edit_project')) && <button className={`${style.detailedit} d-md-inline-flex d-none`}
                            onClick={() => {
                                localStorage.setItem('projectStatus', 'edit');
                                localStorage.setItem('projectName', details.project?.name);
                                history.push(`/projects/create-projects/basic-details/${uuid}?pt=${details.project?.property_type}&tt=${details.project?.transaction_type}`)
                            }}
                        >
                            <EditICon /> Edit Project
                        </button>}
                    </div>
                </div>
                <div className={`${style.mainprojectdetailblock} !pl-5 !pr-5 `} id='about'>
                    <div className='row'>
                        <div className="col-lg-8 pr-md-0 order-lg-1 order-2">
                            <div className={`${style.detailcontent} !mb-8`}>
                                <h1 className='fz32 lh-40 black fw-po-bold mb-16 inter'>{details.project?.name}</h1>
                                <p className='fz18 neutral-700 mb-0'>{details.project?.description}</p>
                            </div>
                            <div className={`${style.projectcardgrp} gap-y-20 gap-x-32`}>

                                {details?.project?.possession_date && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{moment(details?.project?.possession_date).format('MMMM YYYY')}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Possession</p>
                                    </div>
                                </div>}
                                {details?.project?.size && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.size} {details?.project?.size_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Project Size</p>
                                    </div>
                                </div>}
                                {details?.project?.age && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.age} {details?.project?.age_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Property Age</p>
                                    </div>
                                </div>}
                                {details?.project?.unit_count && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.unit_count}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>No. of units</p>
                                    </div>
                                </div>}
                                {details?.project?.tower_height && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20 text-capitalize'>{details?.project?.tower_height} {details?.project?.tower_height_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Tower Height</p>
                                    </div>
                                </div>}
                                {details?.project?.towers && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.towers}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>No. of Towers</p>
                                    </div>
                                </div>}

                                {details?.project?.allotment_date && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{moment(details?.project?.allotment_date).format('MMMM YYYY')}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Allotment Date</p>
                                    </div>
                                </div>}
                                {details?.project?.available_date && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{moment(details?.project?.available_date).format('MMMM YYYY')}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Available Date</p>
                                    </div>
                                </div>}
                                {details?.project?.lease_start_date && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{moment(details?.project?.lease_start_date).format('MMMM YYYY')}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Lease Start Date</p>
                                    </div>
                                </div>}
                                {details?.project?.lease_end_date && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{moment(details?.project?.lease_end_date).format('MMMM YYYY')}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Lease End Date</p>
                                    </div>
                                </div>}
                                {details?.project?.monthly_rent && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.monthly_rent}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Monthly Date</p>
                                    </div>
                                </div>}
                                {details?.project?.assured_rental && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.assured_rental}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Assured Rental</p>
                                    </div>
                                </div>}
                                {details?.project?.lease_tenure && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.lease_tenure} {details?.project?.lease_tenure_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Lease Tenure</p>
                                    </div>
                                </div>}
                                {details?.project?.rent_escalation && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.rent_escalation} {details?.project?.lease_tenure_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Rent Escalation</p>
                                    </div>
                                </div>}
                                {details?.project?.rent_escalation_period && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.rent_escalation_period} {details?.project?.rent_escalation_period_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Rent Escalation Period</p>
                                    </div>
                                </div>}
                                {details?.project?.lockin_period && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.lockin_period} {details?.project?.lockin_period_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Lockin Period</p>
                                    </div>
                                </div>}
                                {details?.project?.security_deposite && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.security_deposite} {details?.project?.security_deposit_period_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Security Deposit</p>
                                    </div>
                                </div>}
                                {details?.project?.security_deposit_period && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.security_deposit_period} {details?.project?.security_deposit_period_unit}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Security Deposit Period</p>
                                    </div>
                                </div>}
                                {details?.project?.tenant_background && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.tenant_background}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Tenant Background</p>
                                    </div>
                                </div>}
                                {details?.project?.preferred_tenant && <div className={`${style.project_card}`}>
                                    <div ><StarIcon /></div>
                                    <div>
                                        <h3 className='inter black fz16 mb4 fw-po-semi lh-20'>{details?.project?.preferred_tenant}</h3>
                                        <p className='inter mb-0 fz14 lh-20'>Preferred Tenant</p>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        {/* stcky card */}
                        <div className="col-lg-4 pr-lg-0 order-lg-2 order-1">
                            <div className={`${style.developercontact} ml-auto`}>
                                <div className={`${style.developercontactHeader}`}>
                                    <div className={`${style.develoerDetail}`}>
                                        <h1 className='inter black fz20 fw-po-semi mb4 lh-24'>{details.project?.developer || details.project?.owner_name}</h1>
                                        <p className='fz14 inter black-dark-700 lh-16 mb-8'>{details?.project?.city}</p>
                                        {/* <div className={`${style.bannerTags}`}><PrimumIcon />Premium Partner</div> */}
                                    </div>
                                    {details?.project?.developer_logo && <div className={`${style.developerlogo} position-relative`}>
                                        <img src={details?.project?.developer_logo} alt="" />
                                        {/* <div className={`${style.prtnericon}`}><PrimumIcon /></div> */}
                                    </div>}
                                </div>
                                <div className='inter fz14 black-dark-700 lh-24 d-flex justify-content-between mt-12'>
                                    <div>Posted, {moment(details?.project?.created_on).format('D MMMM YYYY')}</div>
                                    <div>{details?.project?.possession_type === 'in_future' ? 'Under Construction' : convertToTitleCase(details?.project?.possession_type || '')}</div>
                                </div>
                                <div className='d-flex flex-column gap-12 mt-20'>
                                    {details?.project?.developer_created && <button onClick={handleShowContactModal} className={`${style.developerContactbtn} pr-btn`}><CallIcon />Contact</button>}
                                    {details?.project?.developer_created && <>
                                        {!details?.project?.added_by && <button onClick={handleShowAddToList} className={`${style.developerConOtBtn}`}><AddtomylistIcon />Add to my list</button>}
                                        {details?.project?.added_by && <button onClick={handleShowAddToList} className={`${style.developerConOtBtn}`}>Added</button>}

                                    </>}
                                    <button onClick={downloadFiles} className={`${style.developerConOtBtn}`}><DownoadkitICon />Download Media Kit</button>
                                    {(!isRolesModule || allowedPermissions?.includes('edit_project')) && <button className={`${style.detailedit} d-md-none w-100 text-center`}
                                        onClick={() => {
                                            localStorage.setItem('projectStatus', 'edit');
                                            localStorage.setItem('projectName', details.project?.name);
                                            history.push(`/projects/create-projects/basic-details/${uuid}?pt=${details.project?.property_type}&tt=${details.project?.transaction_type}`)
                                        }}
                                    ><EditICon /> Edit Project</button>}

                                    {/* <button onClick={handleShowContactModal} className={`${style.developerContactbtn} pr-btn`}><CallIcon />Contact</button>
                                {!details?.project?.added_by && <button onClick={handleShowAddToList} className={`${style.developerConOtBtn}`}><AddtomylistIcon />Add to my list</button>}
                                {details?.project?.added_by && <button onClick={handleShowAddToList} className={`${style.developerConOtBtn}`}>Added</button>}
                                <button onClick={downloadFiles} className={`${style.developerConOtBtn}`}><DownoadkitICon />Download Media Kit</button> */}
                                </div>
                            </div>
                            {/* Event Card */}
                            {/* <div className={`${style.noteotes} ${style.projectlaunchblcok} ml-auto mt-24 `}>
                                <div className={`${style.projectlaunchHead} d-flex align-items-center justify-content-between`}>
                                    <div className='fz14 black-dark-700 fw-po-semi inter'>Notes</div>

                                </div>
                                <div className='mt-1'>
                                    <textarea className={`${style.simpleinput} w-100 border-0 p-1`} row='4' placeholder='Take notes in this area to quickly jot down important details about the project...' />
                                </div>
                            </div> */}
                            <div className={`${style.projectlaunchblcok} ml-auto mt-24 d-none`}>
                                <div className={`${style.projectlaunchHead} d-flex align-items-center justify-content-between`}>
                                    <div className='fz14 black-dark-700 fw-po-semi inter'>PROJECT LAUNCH</div>
                                    <div className={`${style.eventtags}`}>Event</div>
                                </div>
                                <div className={`${style.projectlaunchbody} pt-12 pb-12 border-bottom border-color pr-1`}>
                                    <h1 className='inter fz20 black fw-po-medium mb-0 '>VTP Blue Waters - Channel Partner Launch Party 2023</h1>
                                </div>
                                <div className='pt-12 fz14 black-dark-700 lh-16 inter d-flex justify-content-between'>
                                    <div className='pr-color'>May 7, 6PM</div>
                                    <div>Online (Zoom)</div>
                                </div>
                                <div className='d-block w-100'>
                                    <button className={`${style.reservseatbtn} pr-btn w-100 inter`}>Reserve Seat</button>
                                </div>
                            </div>

                            {/* New updates */}
                            {/* <div className={`${style.newupdateblock} ml-auto mt-24`}>
                            <div className={`${style.newupdatehHead} pb-12 border-bottom border-color`}>
                                <div className='fz14 black-dark-700 fw-po-semi inter'>NEW UPDATES</div>
                            </div>
                            <div className='d-flex  gap-4 flex-column inter mt-12'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className={`${style.updatestatus}`}><span className={`${style.circle}`}></span>Changes in Pricing</div>
                                    <div className={`${style.updatedate}`}>12 JAN 2023</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className={`${style.updatestatus}`}><span className={`${style.circle}`}></span>Changes in Pricing</div>
                                    <div className={`${style.updatedate}`}>12 JAN 2023</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className={`${style.updatestatus}`}><span className={`${style.circle}`}></span>Changes in Pricing</div>
                                    <div className={`${style.updatedate}`}>12 JAN 2023</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className={`${style.updatestatus}`}><span className={`${style.circle}`}></span>Changes in Pricing</div>
                                    <div className={`${style.updatedate}`}>12 JAN 2023</div>
                                </div>

                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>


                {details.project?.rera?.length > 0 && <div className={`${style.project2ndblock} mt-50 pl-64 pr-32`}>
                    <h1 className='fz32 lh-40 black fw-po-bold mb-22 inter'>RERA Details</h1>
                    <div className='d-flex flex-sm-row flex-column gap-x-32 gap-y-20 flex-wrap'>
                        {details.project?.rera?.map((item, index) => (
                            <div className={`${style.rrabox}`} key={`${item}_${index}`}>
                                <div><ReraIcon /></div>
                                <div>
                                    <h5 className='inter black fz16 mb4 fw-po-semi lh-20'>{item}</h5>
                                    <p className='inter mb-0 fz14 lh-20'>Project RERA Number ({index + 1}) </p>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>}
                <div className={`${style.multipledivgroup} d-flex flex-column mt-50 !px-5`} data-spy="scroll" data-target="#navbar-example2" data-offset="0">
                    {/* Location and Google Map */}
                    {((details?.project?.latitude && details?.project?.longitude) || details?.project?.google_address) && <div className={`${style.propartyLocation}`} id='location'>
                        <div className='d-flex align-items-sm-center justify-content-between flex-sm-row flex-column'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Property Location</h1>
                            <div className='d-flex flex-row align-items-center gap-12'>
                                <button
                                    onClick={() => {
                                        window.open(`https://api.whatsapp.com/send?text=Here's address for project - ${details.project?.name},  ${details?.project?.google_address}`)
                                    }}
                                    className='border border-color rounded hover:bg-gray-100  px-6 py-6 fz12 lh-16 black fw-po-medium inter gap-12 d-inline-flex align-items-center'
                                >
                                    Share via Whatsapp
                                    <WhatsappIcon />
                                </button>
                                <button onClick={() => {
                                    const text = `https://www.google.com/maps/place/${details?.project?.google_address}`
                                    navigator.clipboard.writeText(text).then(function () {
                                        toast.success('Link Copied');
                                    }, function (err) {
                                        toast.error('Could not copy Link');
                                    })
                                }
                                } className='border border-color rounded hover:bg-gray-100  px-6 py-6 fz12 lh-16 black fw-po-medium inter gap-12 d-inline-flex align-items-center'>Copy Link <CopyIcon /></button>
                            </div>
                        </div>
                        <div className='mt-24'>

                            <iframe src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDRuni-c1oJBFaF8ahTxlxPmYyCmRRtclw&q=${details?.project?.google_address}`} width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='border-0'></iframe>
                        </div>
                        {details?.project?.google_address && <div className='mt-24'>
                            <p className='fz18 fw-po-semi mb-0 neutral-700 inter'>{details?.project?.google_address}</p>
                        </div>}
                    </div>}
                    {/* configuration */}
                    {details?.configurations?.length > 0 && <div className={`${style.configrationblocl}`} id='configuration'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Pricing and Configuration</h1>
                            <CSVLink
                                className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}
                                data={tempConfig}
                                onClick={exportConfig}
                                filename={'configuration.csv'}>
                                Export to CSV <DownloadIcon />
                            </CSVLink>
                            {/* <button className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}>Export CSV <DownloadIcon /></button> */}
                        </div>
                        <div className='w-100 mt-24'>
                            <div className={`${style.pricisngtable} def-table p-0 w-100 overflow-auto h-auto !max-h-[333px]`}>
                                <table className='table mb-0'>
                                    <thead>
                                        <tr>
                                            <th >#</th>
                                            <th >Unit Type</th>
                                            {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <th> Price</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Unit rent Price (Monthly)</th>}
                                            {['rent', 'pre_lease', 'retail_lease'].includes(transaction) && inventory === 'resale' && <th>Usage Status</th>}
                                            <th>Carpet Area</th>
                                            <th>Super Area</th>
                                            <th>Built Up Area</th>
                                            <th>Facing</th>
                                            <th>Floor</th>
                                            <th>Plot Area</th>
                                            {['Retail-lease', 'Rent'].includes(transaction) && (['Residential', 'Commercial'].includes(property)) && <th>Unit Configuration</th>}
                                            <th>Unit Furnishing</th>
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Security Deposit</th>}
                                            <th>Maintenance Cost</th>
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Maintenance Cost Payee</th>}
                                            {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <th>Price Per Unit</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Rent Escalation</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Minimum Rent Tenure</th>}
                                            {transaction === 'pre_lease' && (['commercial'].includes(property)) && <th>Lockin Period</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Sharing</th>}
                                            <th>Unit No.</th>
                                            <th>Tower Number</th>
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Preferred Tenant</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Rent Start Date</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Rent End Date</th>}
                                            {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Available For Rent</th>}
                                            <th>Similar Unit Count</th>
                                            {property === 'commercial' && transaction === "sale" && <th>Assured Return</th>}
                                            {property === 'commercial' && transaction === "sale" && <th>seat Count</th>}
                                            {property === 'commercial' && transaction === "sale" && <th>Lease Guarantee</th>}
                                            {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Front Dimension Width</th>}
                                            {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <th>Front Dimension Height</th>}
                                            <th>Parking Slots</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            details?.configurations?.map((data, index) => (
                                                <tr className='text-left' key={`${data.uuid}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.unit_type ? convertToTitleCase(data.unit_type) : '-'}</td>
                                                    {/* <td>2BHK, Furnished</td> */}
                                                    {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <td>
                                                        {data?.price ? '₹ ' + numDifferentiation(Number(data?.price)) : '-'}
                                                    </td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>
                                                        {data?.rent_price ? '₹ ' + numDifferentiation(Number(data?.rent_price)) : '-'}
                                                    </td>}
                                                    {['rent', 'pre_lease', 'retail_lease'].includes(transaction) && inventory === 'resale' && <td>{convertToTitleCase(data.usage_status || '-')}</td>}
                                                    <td>{data.carpet_area ? `${removeDecimalPlaces(data.carpet_area)} ${data.carpet_area_unit}` : '-'}</td>
                                                    <td>{data.super_area ? `${removeDecimalPlaces(data.super_area)} ${data.super_area_unit}` : '-'}</td>
                                                    <td>{data.built_up_area ? `${removeDecimalPlaces(data.built_up_area)} ${data.built_up_area_unit} ` : '-'}</td>
                                                    <td>{convertToTitleCase(data.facing || '-')}</td>
                                                    <td>{data.floor || '-'}</td>
                                                    <td>{data.plot_area ? `${removeDecimalPlaces(data.plot_area)} ${data.plot_area_unit}` : '-'}</td>
                                                    {['Retail-lease', 'Rent'].includes(transaction) && (['Residential', 'Commercial'].includes(property)) &&
                                                        <td>{data.unit_configuration || '-'}</td>
                                                    }
                                                    <td>{convertToTitleCase(data.furnishing || '-')}</td>
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.security_deposit ? numDifferentiation(data.security_deposit) : '-'}</td>}
                                                    <td>{data.maintenance_cost ? numDifferentiation(data.maintenance_cost) : '-'}</td>
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.maintenance_cost_payee ? data.maintenance_cost_payee : '-'}</td>}
                                                    {transaction === 'sale' && ['new_launch', 'sustenance'].includes(inventory) && <td>{data.price_per_unit ? `${data.price_per_unit} ${data.price_per_unit_type}` : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.rent_escalation ? `${data.rent_escalation} ${data.rent_escalation_period}` : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.minimum_rent_tenure ? `${data.minimum_rent_tenure} ${data.minimum_rent_tenure_period}` : '-'}</td>}
                                                    {transaction === 'pre_lease' && (['commercial'].includes(property)) && <td>{data.lockin_period ? `${data.lockin_period} ${data.lockin_period_unit}}` : ''}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.unit_sharing ? data.unit_sharing : '-'}</td>}
                                                    <td>{data.unit_no ? data.unit_no : '-'}</td>
                                                    <td>{data.tower ? data.tower : '-'}</td>
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.preferred_tenant ? data.preferred_tenant : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.rent_start_date ? moment(data.rent_start_date).format('MMM YYYY') : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.rent_end_date ? moment(data.rent_end_date).format('MMM YYYY') : '-'}</td>}
                                                    {['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.available_for_rent ? moment(data.available_for_rent).format('MMM YYYY') : '-'}</td>}
                                                    <td>{data.similar_unit_count ? data.similar_unit_count : '-'}</td>
                                                    {property === 'commercial' && transaction === "sale" && <td>{data.assured_return ? data.assured_return : '-'}</td>}
                                                    {property === 'commercial' && transaction === "sale" && <td>{data.seat_count ? data.seat_count : '-'}</td>}
                                                    {property === 'commercial' && transaction === "sale" && <td>{data.lease_guarantee ? data.seat_count : '-'}</td>}
                                                    {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.front_dimension_width ? `${data.front_dimension_width} ${data.front_dimension_width_unit}` : '-'}</td>}
                                                    {property === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transaction) && <td>{data.front_dimension_height ? `${data.front_dimension_height} ${data.front_dimension_height_unit}` : '-'}</td>}
                                                    <td >{data.parking_slot || '-'}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}

                    {specifications.length > 0 && <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Specification</h1>
                            <CSVLink
                                className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}
                                data={tempConfig}
                                onClick={exportConfig}
                                filename={'configuration.csv'}>
                                Export to CSV <DownloadIcon />
                            </CSVLink>
                            {/* <button className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}>Export CSV <DownloadIcon /></button> */}
                        </div>
                        <div className='w-100 mt-24'>
                            <div className='def-table pr-0 h-100 pb-0'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Project Specification Name</th>
                                            <th>Description</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            specifications.map((specification, index) => (
                                                <tr key={`${specification.type}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{specification.type}</td>
                                                    <td>{specification.value}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>}
                    {/* Payment Plan */}
                    {details?.paymentPlans?.length > 0 && <div className={`${style.paymentblock}`} id='payment'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Payment Plans</h1>
                            <CSVLink
                                className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}
                                data={tempPaymentPlan} onClick={exportPaymentPlan} filename={'payment_plan.csv'}>
                                Export to CSV <DownloadIcon />
                            </CSVLink>
                            {/* <button className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}>Export CSV <DownloadIcon /></button> */}
                        </div>
                        <div className='w-100 mt-24'>
                            <div className={`${style.paymentetbale} def-table p-0 w-100 h-auto !max-h-[333px] overflow-auto`}>
                                <table className='table mb-0'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Payment Plan Type</th>
                                            <th>Description</th>
                                            <th>Offers</th>
                                            <th>Offer Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            details?.paymentPlans?.map((item, index) => (
                                                <tr key={`${item.uuid}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        {item.description?.length > 20 ?
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            {/* <h4>Total Leads</h4> */}
                                                                            <p className=''>{item.description}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='three-dots'>{item.description}</span>
                                                            </OverlayTrigger>
                                                            :
                                                            <div className=''> {item.description}</div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.offers?.length > 20 ?
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            {/* <h4>Total Leads</h4> */}
                                                                            <p className=''>{item.offers}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='three-dots'>{item.offers}</span>
                                                            </OverlayTrigger>
                                                            :
                                                            <div className=''>{item.offers || '-'}</div>
                                                        }


                                                    </td>
                                                    <td>{item.validity ? moment(item.validity).format('D MMMM YYYY') : '-'}</td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                    {/* Bank Details */}
                    {details?.bankDetails?.length > 0 && <div className={`${style.bakdetialblock}`}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Bank Details</h1>
                            <CSVLink
                                className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}
                                data={tempBankDetails} onClick={exportBankDetails} filename={'bank_details.csv'}>
                                Export to CSV <DownloadIcon />
                            </CSVLink>
                            {/* <button className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}>Export CSV <DownloadIcon /></button> */}
                        </div>
                        <div className='w-100 mt-24'>
                            <div className={`${style.paymentetbale} def-table p-0 w-100 h-auto !max-h-[333px] overflow-auto`}>
                                <table className='table mb-0'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Bank Name</th>
                                            <th>Bank Branch</th>
                                            <th>Account No.</th>
                                            <th>IFSC Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            details?.bankDetails?.map((item, index) => (
                                                <tr key={`${item.uuid}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.bank_name}</td>
                                                    <td>{item.bank_branch}</td>
                                                    <td>{item.account_no}</td>
                                                    <td>{item.ifsc}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                    {/* Payment Plan */}
                    {details?.additionalCost?.length > 0 && <div className={`${style.paymentblock}`} id='payment'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Additional Cost</h1>
                            <CSVLink
                                className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}
                                data={tempAdditionalCost} onClick={exportAdditionalCost} filename={'additional_cost.csv'}>
                                Export to CSV <DownloadIcon />
                            </CSVLink>
                            {/* <button className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}>Export CSV <DownloadIcon /></button> */}
                        </div>
                        <div className='w-100 mt-24'>
                            <div className={`${style.paymentetbale} def-table p-0 w-100 h-auto !max-h-[333px] overflow-auto`}>
                                <table className='table mb-0'>
                                    <thead>
                                        <tr>
                                            <th >#</th>
                                            <th>Cost Name</th>
                                            <th>Amount</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            details?.additionalCost.map((item, index) => (
                                                <tr key={`${item.uuid}_${index}`}>
                                                    <td >{index + 1}</td>
                                                    <td>{convertToTitleCase(item.name || '-')}</td>
                                                    <td>{'₹ ' + item?.amount}</td>
                                                    <td>
                                                        {item?.comment?.length > 30 ?
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg w-auto'>
                                                                            {/* <h4>Total Leads</h4> */}
                                                                            <p className=''>{item.comment}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className=' three-dots'>{item.comment}</span>
                                                            </OverlayTrigger>
                                                            :
                                                            <div className=''> {item.comment || '-'}</div>
                                                        }
                                                    </td>


                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                    {/* Amenities */}
                    {details.project?.amenities?.length > 0 && <div className={`${style.listblock}`} id='amenities'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Amenities</h1>
                        </div>
                        <div className='row gap-y-20 mt-16'>

                            {
                                details.project?.amenities?.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 gap-16" key={`${item}_${index}`}>
                                        <li className={`${style.offerlist}`} >{item} </li>
                                    </div>
                                ))
                            }

                        </div>
                    </div>}
                    {/* Near by Brand */}
                    {details.project?.nearby_brands?.length > 0 && <div className={`${style.listblock}`}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Nearby Brands</h1>
                        </div>
                        <div className='mt-16'>
                            <ul className={`${style.listitems} ${style.offerlistitems} `}>
                                {
                                    details.project?.nearby_brands?.map((item, index) => (
                                        <li key={`${item}_${index}`}>{item} </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>}
                    {/* Offers */}
                    {details.project?.offers?.length > 0 && <div className={`${style.listblock}`}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Offers</h1>
                        </div>
                        <div className='mt-16'>
                            <div className='row gap-y-20'>
                                {
                                    details.project?.offers?.map((item, index) => (
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 gap-16" key={`${item}_${index}`}>
                                            <li className={`${style.offerlist}`} >{item} </li>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>}
                    {/* Media */}
                    {media?.length > 0 && <div className={`${style.mediaBlock}`} id='media'>
                        <div className='d-flex align-items-center justify-content-between border-bottom border-color pb-12'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Media</h1>
                            <button
                                className={`${style.exprotcsv} outline-btn d-inline-flex align-items-center gap-4`}
                                onClick={downloadFiles}
                            >Download All <DownloadIcon /></button>

                        </div>
                        <div className='pt-12 pb-12 border-bottom border-color'>
                            <h2 className='fz24 lh-40 black fw-po-bold mb-0 inter'>Gallery</h2>
                        </div>
                        <div className={`${style.gallarygrid} mt-24`}>
                            {
                                media?.map((item, index) => (
                                    <div className='overflow-hidden' key={`${item.uuid}_${index}`}>
                                        <figure className=' position-relative m-0'>
                                            <button onClick={() => {
                                                setSelectdImg(index)
                                                setShowLightModal(true)

                                            }} className={`${style.galarybtn}`}></button>
                                            <img src={item.url} alt="" />
                                            <TooltipText title='Download Image'>

                                                <button onClick={() => {
                                                    const link = document.createElement("a");
                                                    link.href = item.url;
                                                    link.download = `${item.name}`;
                                                    link.click();
                                                }}
                                                    className={`${style.downloadbtn}`}
                                                >
                                                    <Downloadimg />
                                                </button>
                                            </TooltipText>
                                        </figure>

                                        {(item.media_name || item.file_name)?.length > 40 ? <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg'>
                                                        {/* <h4>Total Leads</h4> */}
                                                        <p className='text-capitalize'>{item.media_name || item.file_name}</p>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <p className={`${item.type === 'video/mp4' ? 'mt4' : 'mt-10'} black fz16 fw-po-semi mb-0 h-line`}>{item.media_name || item.file_name}</p>
                                        </OverlayTrigger>
                                            :
                                            <p className={`${item.type === 'video/mp4' ? 'mt4' : 'mt-10 black fz16 fw-po-semi mb-0'}`}>{item.media_name || item.file_name}</p>
                                        }
                                    </div>
                                ))
                            }
                            {
                                videos?.map((item, index) => (
                                    <div className='overflow-hidden' key={`${item.uuid}_${index}`}>

                                        {item.type !== 'youtube_video' && <video width="100%" height="280" controls muted style={{ objectFit: 'cover', borderRadius: '8px' }}>
                                            <source src={item.url} type="video/mp4" />
                                        </video>}

                                        {item.type === 'youtube_video' &&
                                            <iframe width="100%" height="280px" style={{ borderRadius: '8px' }} src={item.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        }
                                        {(item.media_name || item.file_name)?.length > 40 ? <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg'>
                                                        {/* <h4>Total Leads</h4> */}
                                                        <p className='text-capitalize'>{item.media_name || item.file_name}</p>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <p className={`${item.type === 'video/mp4' ? 'mt4' : 'mt-10'} black fz16 fw-po-semi mb-0 h-line`}>{item.media_name || item.file_name}</p>
                                        </OverlayTrigger>
                                            :
                                            <p className={`${item.type === 'video/mp4' ? 'mt4' : 'mt-10 '} black fz16 fw-po-semi mb-0`}>{item.media_name || item.file_name}</p>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        {/* <button className={`${style.viewmorebtn} outline-btn mx-auto d-table mt-40 mb-20 fz14 fw-po-medium `}>+ View More</button> */}
                    </div >}
                    {/* Documents */}
                    {documents?.length > 0 && < div className={`${style.documnetblock}`}>
                        <div className='d-flex align-items-center justify-content-between border-bottom border-color pb-12'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>Documents</h1>
                        </div>
                        <div className='mt-24 d-flex flex-row gap-24 flex-wrap'>
                            {
                                documents.map((doc, index) => (
                                    <div className={`${style.documntBox}`} key={`${doc.uuid}_${index}`}>
                                        <figure className='mb-0'>
                                            {['ppt', 'pptx'].includes(doc.file_name.split('.').pop()) && <img src={pptimg} alt="" className=' w-100 ' />}
                                            {['doc', 'docx'].includes(doc.file_name.split('.').pop()) && <img src={docImg} alt="" className=' w-100 ' />}
                                            {['xls', 'xlsx', 'csv'].includes(doc.file_name.split('.').pop()) && <img src={sheetImg} alt="" className=' w-100' />}
                                            {['pdf', 'pdfx'].includes(doc.file_name.split('.').pop()) && <img src={pdfimg} alt="" className=' w-100' />}
                                            {/* <img src={documnetimg} alt="" /> */}
                                        </figure>
                                        <div className={`${style.documntfooter}`}>
                                            <div className='d-flex align-items-center justify-content-between w-100 gap-12'>
                                                {['pdf', 'pdfx'].includes(doc.file_name.split('.').pop()) && <span className={`${style.docIcon}`}><PdfICon style={{ height: '20px' }} /></span>}
                                                {['doc', 'docx'].includes(doc.file_name.split('.').pop()) && <span className={`${style.docIcon}`}><DocIcon style={{ height: '20px' }} /></span>}
                                                {['xls', 'xlsx', 'csv'].includes(doc.file_name.split('.').pop()) && <span className={`${style.docIcon}`}><XlIcon style={{ height: '20px' }} /></span>}
                                                {['ppt', 'pptx'].includes(doc.file_name.split('.').pop()) && <span className={`${style.docIcon}`}><PptICon style={{ height: '20px' }} /></span>}

                                                {doc.media_name?.length || doc.file_name > 20 ? <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="button-tooltip" >
                                                            <div className='info-msg'>
                                                                {/* <h4>Total Leads</h4> */}
                                                                <p className='text-capitalize'>{doc.media_name || doc.file_name}</p>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <h2 className='fz16 black fw-po-medium mb-0 h-line'>{doc.media_name || doc.file_name}</h2>
                                                </OverlayTrigger>
                                                    :
                                                    <h2 className='fz16 black fw-po-medium mb-0 h-line'>{doc.media_name || doc.file_name}</h2>
                                                }
                                                <button onClick={() => {
                                                    const link = document.createElement("a");
                                                    link.href = doc.url;
                                                    link.download = `${doc.media_name || doc.file_name}`;
                                                    link.click();
                                                }}
                                                    className={`${style.docdownBtn}`}><DownloadBlackIcon />
                                                </button>



                                            </div>
                                        </div>
                                    </div>
                                ))
                            }


                            {/* <div className={`${style.documntBox}`}>
                                <figure className='mb-0'><img src={execlimg} alt="" /></figure>
                                <div className={`${style.documntfooter}`}>
                                    <div className='d-inline-flex align-items-center justify-content-between w-100'>
                                        <div className='d-inline-flex align-items-center gap-12 black fz16 inter fw-po-medium'>
                                            <span className={`${style.docIcon}`}><ExlIcon /></span>
                                            Brochure 2.xlss
                                        </div>
                                        <div><button className={`${style.docdownBtn}`}><DownloadBlackIcon /></button></div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>}
                    {(details.project?.developer_logo || details.project?.developer_description) && <div className={`${style.develoerdetail} develoerdetail`} id='developerdetail'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='fz32 lh-40 black fw-po-bold mb-0 inter'>About the Developer</h1>
                        </div>
                        <div className='w-100 mt-40 d-flex flex-lg-row flex-column align-items-start gap-x-32 pr-32'>
                            {details.project?.developer_logo && <div className={`${style.developerLogo} mb-lg-0 mb-3`}>
                                <img src={details?.project?.developer_logo} alt="" />
                            </div>}
                            {details.project?.developer_description && <div>
                                {details.project?.developer && <h2 className="inter black fz20 fw-po-semi mb-12 lh-24">{details.project?.developer}</h2>}
                                {details?.project?.developer_description && <p className='fz18 black inter mb-0 pr-32 pr-sm0'>{details?.project?.developer_description}</p>}
                            </div>}
                        </div>
                    </div>}
                </div>


                {/* {showLightModal && <LightGalleryModal show={showLightModal} closeModal={() => setShowLightModal(false)} style={style} media={details?.media} selectdImg={selectdImg} />} */}
                {
                    showButton && (
                        <a className={`${style.backtotop}`} onClick={() => scrollToSection('home')}><BacktoTopIcon />Scroll To Top</a>)
                }


                {showLightModal && <Lightbox
                    open={showLightModal}
                    close={() => setShowLightModal(false)}
                    slides={media?.filter(item => item?.type !== 'video/mp4').map(item => ({ src: item.url }))}
                    carousel={{
                        finite: true
                    }}
                    index={selectdImg}
                    plugins={[Thumbnails]}
                // video={{
                //     controls: true,
                //     playsInline: true,
                //     autoPlay: false,
                //     loop: false,
                //     muted: true,
                //     disablePictureInPicture: false,
                //     disableRemotePlayback: false,
                //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
                //     crossOrigin: '',
                //     preload: '',
                // }}
                />}

                <AddYourListModal
                    show={showAddToListModal}
                    closeModal={() => setShowAddToListModal(false)}
                    item={{ name: details.project?.name, uuid }}
                    style={style}
                    handleAddToList={handleAddToList}
                />
                <ContactModal
                    show={showContactModal}
                    closeModal={() => setShowContactModal(false)}
                    item={{ name: details.project?.name, uuid }}
                    style={style}
                    handleContact={handleContact}
                />
            </div >

        </div >
    )
}

export default ProjectDetail
