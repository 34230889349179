import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createFollowupCall } from '../../../services/private/leads.service';
import DateTimePicker from '../../InputGroup/DateTime';
import InputSelect from '../../InputGroup/InputSelect';




const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};



export default function CreateForm({
    reloadFollowupsData,
    intentOptions,
    leadInfoData,
    statusOptions,
    leadAccess,
    userProfile
}) {

    const params = useParams();
    const [selectedDate, setSelectedDate] = useState();
    const [comments, setComments] = useState("");
    const [error, setError] = useState(false);
    const [selectedIntent, setSelectedIntent] = useState(leadInfoData.intent
        && leadInfoData.intent?.display_name != 'New Lead' ?
        {
            label: leadInfoData.intent?.display_name,
            value: leadInfoData.intent?.id
        } : '')
    const [selectedStatus, setSelectedStatus] = useState({})
    const [loading, setLoading] = useState(false)

    const getReminderMessage = () => {
        // Remind 10 minutes before
        if (selectedDate) {
            return <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-16'>We will remind you <span className='black fw-po-medium'>15 mins</span>  prior to <span className='black fw-po-medium'>{moment(selectedDate).format('h:mm a, Do MMMM YYYY')}</span>.</p>
            // return <p className="text-center px-16 fz14 black-dark-700">
            //     We will remind you to followup with <span className='black fw-po-medium'>{ }</span> at <span className='black fw-po-medium'>{moment(selectedDate).subtract(15, 'minutes').format('LT')}</span> on <span className='black fw-po-medium'>{moment(selectedDate).format('LL')}</span>.
            // </p>
        }
    }

    const createFollowup = async () => {
        if (!selectedDate || !selectedIntent || Object.keys(selectedStatus).length === 0) {
            setError(true)
            return false
        } else {
            setError(false)
        }

        setLoading(true)
        await createFollowupCall(params.id, {
            scheduleDate: selectedDate,
            remarks: comments,
            intentId: selectedIntent.value,
            statusId: selectedStatus.value
        }).then(res => {
            if (res.status === 200) {
                toast.success("Follow Up Created")
                reloadFollowupsData();
                window.location.reload();
            }
        }).catch(err => {
            console.log(err, 'err')
        })
        setLoading(false);
    }

    return (
        <>
            <div className="date-time ml-0">
                <div className="mb-16 align-items-center">
                    <div className="row">
                        <div className="col">
                            <DateTimePicker
                                onValueChange={(e) => setSelectedDate(e)}
                                label="Select Followup Date & Time"
                                name="scheduleDate"
                                error={error && !selectedDate && ' Please Select date.'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-16'>
                <InputSelect
                    index={2}
                    label="Intent"
                    name="Intent"
                    styles={customStyles}
                    value={intentOptions.find(intent => intent.value === selectedIntent?.value)}
                    options={intentOptions}
                    placeholder="Select Intent"
                    onChange={(e) => { setSelectedIntent(e) }}
                    error={error && (Object.keys(selectedIntent)?.length === 0) && 'Intent is required'}
                />
            </div>
            <div className='mb-16'>
                <InputSelect
                    index={2}
                    label="Status"
                    name="Status"
                    styles={customStyles}
                    value={Object.keys(selectedStatus).length > 0 ? selectedStatus : ''}
                    options={statusOptions.filter((status) => status.label === 'Not Connected' || status.label === 'In Progress')}
                    placeholder={"Select Status"}
                    onChange={(e) => { setSelectedStatus(e) }}
                    error={error && (Object.keys(selectedStatus)?.length === 0) && 'Status is required'}
                />
            </div>
            <div className="form-group ml-0">
                <label>Comments (optional)</label>
                <textarea
                    className="form-control mb-16"
                    placeholder="Enter any followup comments..."
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}>
                </textarea>

                {/* Reminder message */}
                {getReminderMessage()}

                {/* Save button */}
                <button className="pr-btn text-center w-100 py-8" onClick={createFollowup} disabled={loading || (userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view')}>
                    Create Followup
                </button>
            </div>
            <h1 className="black fz16 po mb-24 mt-30">Recent Followups</h1>
        </>
    )
}