import React, { Component, useContext } from 'react';
import AppContext from '../../../helpers/context';
import Spinner from '../../Spinner/Spinner';
import { checkAuthorization, getCompanyConfig } from '../../../helpers/helpers';
import '../../../global.css';
import { getUserProfile } from '../../../services/private/company.service';
import { getNewBrokerDetails } from '../../../services/public/public.service';

class MainContainer extends Component {
  //  socket = socketio.connect(process.env.SOCKET_URL);
  state = {
    isMounted: false,
    authorized: false,
    requesting: true,
    setAuthorized: this.setAuthorized,
    companyConfig: {},
    allowedPermissions: [],
    isRolesModule: false,
    userProfile: {},
    isSubscribed: false,
    borkerDetails: {}
  }

  setAuthorized = authorized => {
    this.setState({ authorized });
  };

  constructor(props) {
    super(props);
    // this.componentInit();

  }

  async componentInit() {
    let response = await checkAuthorization();

    if (response) {
      let output = await getCompanyConfig();
      if (output && output.result && output.result.data) {
        let allData = output.result.data.data.result;
        this.setState({ authorized: response, requesting: false, companyConfig: allData });

      } else {
        this.setState({ authorized: response, requesting: false, companyConfig: {} });

      }


    } else {
      this.setState({ authorized: response, requesting: false, companyConfig: {} });
    }
  }

  getPersonalData = async () => {
    const res = await getNewBrokerDetails();
    if (res.data.status === 200) {

      this.setState({
        isSubscribed: res.data.data.is_subscribed,
        borkerDetails: res.data.data
      });
    }
  }
  async componentDidMount() {
    this.setState({ isMounted: true });

    let response = await checkAuthorization();

    if (response) {
      let output = await getCompanyConfig();
      let userProfileResponse = await getUserProfile();
      this.getPersonalData();
      if (output && output.result && output.result.data) {
        let allData = output.result.data.data.result;
        let intent = output.result.data.data.result.Intent.filter(item => item.label != 'New Lead')
        this.setState({ authorized: response, requesting: false, companyConfig: { ...allData, Intent: intent } });

      } else {
        this.setState({ authorized: response, requesting: false, companyConfig: {} });

      }
      if (userProfileResponse.data.status === 200) {

        this.setState({
          allowedPermissions: userProfileResponse.data.data.role?.allowed_permissions || [],
          isRolesModule: userProfileResponse.data.data.is_roles_module,
          userProfile: userProfileResponse.data.data
        })
      }


    } else {
      this.setState({ authorized: response, requesting: false, companyConfig: {} });
    }

  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }


  render() {
    return (
      <AppContext.Provider
        value={{
          authorized: this.state.authorized,
          setAuthorized: this.setAuthorized,
          companyConfig: this.state.companyConfig,
          allowedPermissions: this.state.allowedPermissions,
          isRolesModule: this.state.isRolesModule,
          userProfile: this.state.userProfile,
          isSubscribed: this.state.isSubscribed,
          borkerDetails: this.state.borkerDetails
        }}>

        {this.state.requesting ? <Spinner requesting={true} /> : this.props.children}

      </AppContext.Provider >

    );
  }

}



export default MainContainer;