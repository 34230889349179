import React from 'react'
import { Modal } from 'react-bootstrap'

const RightModal = ({ show, closeModal, children, backDropClassName, modalClass }) => {
    return (
        <Modal show={show} onHide={closeModal} dialogClassName={`rightmodal ${modalClass}`} backdrop="static" fullscreen backdropClassName={backDropClassName}>
            {children}
        </Modal>
    )
}

export default RightModal
