import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';


export async function insertFollowup(uuid, scheduleDate, remarks, intentId, info) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.followups.insert}/${uuid}`, null, {
        scheduleDate,
        remarks,
        intentId,
        info
    });
}


export async function updateFollowup(uuid, scheduleDate, remarks) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.followups.update}/${uuid}`, null, {
        scheduleDate,
        remarks
    });
}

export async function getActiveFollowupByLeadId(leadId) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.followups.getActiveFollowupByLeadId}/${leadId}`, null, null)
}