import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputSelect from '../../../components/InputGroup/InputSelect';
import InputText from '../../../components/InputGroup/InputText';
import CreatableSelect from 'react-select/creatable';
import { toINRFormat, getUnitTotalPrice } from '../../../helpers/helpers';

import { bhk_type, carpet_area, facing, furnished_unit, plot_area, project_area } from '../../../utils/project-constant';
import style from '../../../modules/Settings/Projects/style.module.css';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as RupayIcon } from '../../../assets/icons/rupay.svg';
import TooltipText from '../../../components/Tooltip';
import Dropdown from '../../../components/InputGroup/dropdown';
import { updateUnit } from '../../../services/private/inventory.service';
import { toast } from 'react-toastify';
import BasicModal from '../../../components/Modals/BasicModal';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const EditUnitDetails = ({ show, closeModal, selectedUnit, selectedProject, inventoryStats, getProjectUnitLists }) => {

    const [unitType, setUnitType] = useState([]);
    const [type, setType] = useState('sale')
    const [unit, setUnit] = useState({});

    const { bookingPricing } = selectedUnit;

    let error = '';

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            name: '',
            unit_type: '',
            unit_configuration: '',

            carpet_area: '',
            carpet_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.feet'
            },
            super_area: '',
            super_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.feet'
            },
            built_up_area: '',
            built_up_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.feet'
            },
            plot_area: '',
            plot_area_unit: {
                label: 'Acres',
                value: 'acres'
            },
            base_price: '',
            furnishing: '',
            facing: '',
            unit_sharing: '',
            usage_status: '',
            car_parking: '',
            floor_no: '',
            tower: '',
            unit_no: '',
            price: '',
            seats_count: '',
            office_cabins: '',
            bathroom_count: '',
            plc_charge1_type: '',
            plc_charge1: '',
            plc_charge2_type: '',
            plc_charge2: '',
            plc_charge3_type: '',
            plc_charge3: '',
            plc_charge4_type: '',
            plc_charge4: '',
            personal_washroom: '',
            block: ''
        },
        // resolver: yupResolver(basicDetailsvalidationSchema)
    });

    useEffect(() => {
        if (selectedUnit) {
            setUnit(selectedUnit?.bookingUnit)
        }
    }, [selectedUnit]);

    useEffect(() => {
        if (unit) {
            reset({
                name: unit?.name,
                unit_configuration: {
                    label: unit.configuration?.unit_configuration,
                    value: unit.configuration?.unit_configuration
                },
                unit_type: {
                    label: unit?.configuration?.unit_type,
                    value: unit?.configuration?.unit_type
                },
                floor_no: unit?.floor_no,
                facing: facing.find(item => item.label === unit?.facing),
                base_price: unit?.base_price || '',
                plc_charge1_type: unit?.plc_charge1_type,
                plc_charge1: unit?.plc_charge1 || '',
                plc_charge2_type: unit?.plc_charge2_type,
                plc_charge2: unit?.plc_charge2 || '',
                plc_charge3_type: unit?.plc_charge3_type,
                plc_charge3: unit?.plc_charge3 || '',
                plc_charge4_type: unit?.plc_charge4_type,
                plc_charge4: unit?.plc_charge4 || '',
                bathroom_count: unit?.bathroom_count || '',
                furnishing: furnished_unit?.find(item => item.label === unit?.furnishing),
                status: inventoryStats.find(item => item.value === unit?.status),
                car_parking: unit?.car_parking || '',
                plan_link: unit?.plan_link || '',
                personal_washroom: unit?.personal_washroom,
                seats_count: unit?.seats_count || '',
                office_cabins: unit?.office_cabins || '',
                block: unit?.block || ''
            },
                { keepDefaultValues: true }
            )
        }
    }, [unit])

    const handleChangeUnitType = (newType) => {
        setUnitType([...unitType, newType])
    }

    const onFormSubmit = async (values) => {
        console.log(values, 'values')
        const payload = {
            name: values.name,
            floor_no: values.floor_no,
            facing: values.facing.label,
            base_price: parseInt(values.base_price.replace(/,/g, '')) || null,
            plc_charge1_type: values.plc_charge1_type,
            plc_charge1: parseInt(values.plc_charge1.replace(/,/g, '')) || null,
            plc_charge2_type: values.plc_charge2_type,
            plc_charge2: parseInt(values.plc_charge2.replace(/,/g, '')) || null,
            plc_charge3_type: values.plc_charge3_type,
            plc_charge3: parseInt(values.plc_charge3.replace(/,/g, '')) || null,
            plc_charge4_type: values.plc_charge4_type,
            plc_charge4: parseInt(values.plc_charge4.replace(/,/g, '')) || null,
            bathroom_count: values.bathroom_count,
            furnishing: values.furnishing.label,
            status: values.status.value,
            car_parking: values.car_parking || null,
            plan_link: values.plan_link,
            personal_washroom: values.personal_washroom,
            seats_count: values.seats_count || null,
            office_cabins: values.office_cabins || null,
        }
        const res = await updateUnit(unit.uuid, payload);
        if (res.data.status === 200) {
            getProjectUnitLists(selectedProject.value);
            toast.success(res.data.message);
            closeModal();
        }
    }
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-4 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div>
                        <p className='m-0 text-xl font-semibold'>Unit {unit?.name || '-'} | {unit?.block || '-'}</p>
                        <p className='m-0 text-sm text-black700'>For {selectedUnit?.customer?.customer_name || '-'}</p>
                    </div>
                    <div className='flex items-center'>
                        {/* <button className='border border-grayLight py-2.5 px-3 mr-3 rounded-lg'>Edit unit</button> */}
                        <button onClick={closeModal}><CloseIcon /></button>
                    </div>
                </div>


                <div className='!px-6 !pt-5 pb-8 h-full overflow-y-auto overflow-x-hidden inter'>
                    {/* <>
                        <p className='m-0 text-xl font-semibold'>Booking Details</p>
                        <div className='grid grid-cols-3 gap-y-4 mt-3 '>
                            <div>
                                <p className='m-0 text-sm text-black700'>Name</p>
                                <p className='m-0 text-base font-semibold'>Aditya Pratap</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700'>Mobile</p>
                                <p className='m-0 text-base font-semibold'>+918797616790</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700'>Email</p>
                                <p className='m-0 text-base font-semibold'>adityapratap230@gmail.com</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700'>Agreement Amount</p>
                                <p className='m-0 text-base font-semibold'>₹ 6254000</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700'>Booking Date</p>
                                <p className='m-0 text-base font-semibold'>12:00 PM, 25 Mar 2023 </p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700'>Salutation</p>
                                <p className='m-0 text-base font-semibold'>₹ 6254000</p>
                            </div>
                        </div>
                    </> */}
                    <div className=''>
                        <p className='m-0 text-xl font-semibold'>Unit Details</p>
                        <form onSubmit={handleSubmit(onFormSubmit)} id='unit-form' className='grid grid-cols-2 gap-y-4 gap-x-6 !mt-3'>

                            <div>
                                <label htmlFor="">Project</label>
                                <InputSelect
                                    styles={customStyles}
                                    value={selectedProject}
                                    disable={true}
                                />
                            </div>
                            <div>
                                <label htmlFor="">Transaction type</label>
                                <InputSelect
                                    styles={customStyles}
                                    value={
                                        {
                                            label: selectedProject?.transaction_type,
                                            value: selectedProject?.transaction_type
                                        }}
                                    disable={true}
                                />

                            </div>
                            {/* Property Type */}
                            <div>
                                <Controller
                                    control={control}
                                    name='property_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label htmlFor="">Property Type</label>

                                            <InputSelect
                                                styles={customStyles}
                                                value={
                                                    {
                                                        label: selectedProject?.property_type,
                                                        value: selectedProject?.property_type
                                                    }}
                                                disable={true}
                                            />
                                        </>
                                    )}
                                />
                            </div>

                            {/* unit configuration */}
                            {type === 'sale' && <div>
                                <Controller
                                    control={control}
                                    name='unit_configuration'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label='Configuration Type'
                                                placeholder='Select Configuration Type'
                                                onChange={onChange}
                                                value={value}
                                                options={bhk_type}
                                                styles={customStyles}
                                                disable={true}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </>
                                    )}
                                />
                            </div>}
                            {/* unit type*/}
                            <div className={` position-relative m-0`}>
                                <Controller
                                    control={control}
                                    name='unit_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label>
                                                Unit Type
                                            </label>
                                            <CreatableSelect
                                                placeholder='Select Type'
                                                onCreateOption={(value) => {
                                                    handleChangeUnitType({ label: value, value: value })
                                                    onChange({ label: value, value: value })
                                                }}
                                                onChange={onChange}
                                                value={value}
                                                options={unitType}
                                                styles={customStyles}
                                                isDisabled={true}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {/* Block No. */}
                            <div className=''>
                                <Controller
                                    control={control}
                                    name='block'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label='Block No.'
                                                placeholder='Select Configuration Type'
                                                onChange={onChange}
                                                value={{ label: unit?.block, value: unit?.block }}
                                                styles={customStyles}
                                                disable={true}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </>
                                    )}
                                />

                            </div>
                            {type !== 'commercial' && <div>
                                <Controller
                                    control={control}
                                    name='phase'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> Phase</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control text-input error' : 'form-control text-input '}
                                                placeholder='e.g. Phase 1'
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                />
                            </div>}
                            {/* Base Price */}
                            <div className=' position-relative'>
                                <Controller
                                    control={control}
                                    name='base_price'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> Base Price</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                                placeholder='e.g. 120000'
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(
                                                        toINRFormat(
                                                            e.target.value
                                                                .replace(/^0+/g, '')
                                                                .replace(/[^0-9]/g, '')
                                                        ))
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                <RupayIcon className={`${style.absrupay}`} />
                            </div>
                            {/* PLC charge1 type */}
                            <div>
                                <Controller
                                    control={control}
                                    name='plc_charge1_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charge1 Type</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control  text-input error' : 'form-control text-input'}
                                                placeholder='e.g. PLC charges 1'
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {/* PLC charge 1 */}
                            <div className=' position-relative'>
                                <Controller
                                    control={control}
                                    name='plc_charge1'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charges1</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                                placeholder='e.g. 120000'
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(
                                                        toINRFormat(
                                                            e.target.value
                                                                .replace(/^0+/g, '')
                                                                .replace(/[^0-9]/g, '')
                                                        ))
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                <RupayIcon className={`${style.absrupay}`} />
                            </div>
                            {/* PLC charge2 type */}
                            <div>
                                <Controller
                                    control={control}
                                    name='plc_charge2_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charge2 Type</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                                placeholder='e.g. PLC charges 2'
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {/* PLC charge 2 */}
                            <div className=' position-relative'>

                                <Controller
                                    control={control}
                                    name='plc_charge2'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charge2</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                                placeholder='e.g. 120000'
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(
                                                        toINRFormat(
                                                            e.target.value
                                                                .replace(/^0+/g, '')
                                                                .replace(/[^0-9]/g, '')
                                                        ))
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                <RupayIcon className={`${style.absrupay}`} />
                            </div>
                            {/* PLC charge3 type */}
                            <div>
                                <Controller
                                    control={control}
                                    name='plc_charge3_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charge3 Type</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control  text-input error' : 'form-control text-input '}
                                                placeholder='e.g. PLC charges 3'
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {/* PLC charge 3 */}
                            <div className=' position-relative'>
                                <Controller
                                    control={control}
                                    name='plc_charge3'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charge3</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                                placeholder='e.g. 120000'
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(
                                                        toINRFormat(
                                                            e.target.value
                                                                .replace(/^0+/g, '')
                                                                .replace(/[^0-9]/g, '')
                                                        ))
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                <RupayIcon className={`${style.absrupay}`} />
                            </div>
                            {/* PLC charge4 type */}
                            <div>
                                <Controller
                                    control={control}
                                    name='plc_charge4_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charge4 Type</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                                placeholder='e.g. PLC charges 4'
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {/* PLC charge 4 */}
                            <div className=' position-relative'>

                                <Controller
                                    control={control}
                                    name='plc_charge4'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <label> PLC Charge4</label>
                                            <input
                                                type='text'
                                                className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                                placeholder='e.g. 120000'
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(
                                                        toINRFormat(
                                                            e.target.value
                                                                .replace(/^0+/g, '')
                                                                .replace(/[^0-9]/g, '')
                                                        ))
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                <RupayIcon className={`${style.absrupay}`} />
                            </div>

                            {/* Carpet area */}
                            {/* {type !== 'rent' && <div className={`  position-relative`}>
                                <label className='block text-black700 text-sm mb-2 font-medium'>Carpet Area </label>
                                <Controller
                                    control={control}
                                    name='carpet_area_unit'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <Dropdown
                                                list={carpet_area}
                                                onChange={onChange}
                                                value={value}
                                                style={style}
                                            />
                                        </>
                                    )}
                                />

                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"number"}
                                    placeholder='e.g 1200'
                                    {...register('carpet_area')}
                                />
                            </div>} */}
                            {/* Super Area */}
                            {/* {type !== 'rent' && <div className={`  position-relative`}>
                                <label className='block text-black700 text-sm mb-2 font-medium'>Super Area </label>
                                <Controller
                                    control={control}
                                    name='super_area_unit'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <Dropdown
                                                list={project_area}
                                                onChange={onChange}
                                                value={value}
                                                style={style}
                                            />
                                        </>
                                    )}
                                />

                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"number"}
                                    placeholder='e.g. 1400'
                                    {...register('super_area')}
                                />
                            </div>} */}
                            {/* built up Area */}
                            {/* {type !== 'rent' && <div className={`  position-relative`}>
                                <label className='block text-black700 text-sm mb-2 font-medium'>Built up Area </label>
                                <Controller
                                    control={control}
                                    name='built_up_area_unit'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <Dropdown
                                                list={project_area}
                                                onChange={onChange}
                                                value={value}
                                                style={style}
                                            />
                                        </>
                                    )}
                                />

                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"number"}
                                    placeholder='e.g. 1300'
                                    {...register('built_up_area')}
                                />
                            </div>} */}
                            {/* Balcony  Area */}
                            {/* {type === 'sale' && <div className={`position-relative`}>
                                <label className='block text-black700 text-sm mb-2 font-medium'>Built up Area </label>
                                <Controller
                                    control={control}
                                    name='balcony_area_unit'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <Dropdown
                                                list={carpet_area}
                                                onChange={onChange}
                                                value={value}
                                                style={style}
                                            />
                                        </>
                                    )}
                                />
                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"number"}
                                    placeholder='e.g. 1300'
                                    {...register('balcony_area')}
                                />
                            </div>} */}
                            {/* Plot area */}
                            {/* <div className={`  position-relative`}>
                                <label className='block text-black700 text-sm mb-2 font-medium'>Plot Area </label>
                                <Controller
                                    control={control}
                                    name='plot_area_unit'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <Dropdown
                                                list={plot_area}
                                                onChange={onChange}
                                                value={value}
                                                style={style}
                                            />
                                        </>
                                    )}
                                />
                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"number"}
                                    placeholder='e.g. 33'
                                    {...register('plot_area')}
                                />

                            </div> */}
                            {/* Furnishing */}
                            {type !== 'rent' && <div>
                                <Controller
                                    control={control}
                                    name='furnishing'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label='Unit Furnishing'
                                                placeholder='Select Furnishing'
                                                onChange={onChange}
                                                value={value}
                                                options={furnished_unit}
                                                styles={customStyles}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </>
                                    )}
                                />
                            </div>}
                            {/* facing */}
                            <div>
                                <Controller
                                    control={control}
                                    name='facing'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label='Unit Facing'
                                                placeholder='Select Facing'
                                                onChange={onChange}
                                                value={value}
                                                options={facing}
                                                styles={customStyles}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {/* Inventory status */}
                            <div>
                                <Controller
                                    control={control}
                                    name='status'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label='Inventory Status'
                                                placeholder='Select Inventory Status'
                                                onChange={onChange}
                                                value={value}
                                                options={[]}
                                                styles={customStyles}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {/* Personal Washroom */}
                            {type === 'commercial' && <div>
                                <Controller
                                    control={control}
                                    name='washroom'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label='Personal Washroom'
                                                placeholder='Select'
                                                onChange={onChange}
                                                value={value}
                                                options={[
                                                    {
                                                        label: 'Yes',
                                                        value: 'yes'
                                                    },
                                                    {
                                                        label: 'No',
                                                        value: 'no'
                                                    }
                                                ]}
                                                styles={customStyles}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </>
                                    )}
                                />
                            </div>}
                            {/* Parking Slot */}
                            {type !== 'rent' && <div className=''>
                                <label >Parking Slots</label>
                                <input
                                    type='text'
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    placeholder='e.g. 1'
                                    {...register('car_parking')}
                                />
                            </div>}
                            {/* Floor */}
                            <div className=''>
                                <label className='flex items-center gap-1'>Floor
                                    <TooltipText title={'On which floor is it situated'}>
                                        <InfoIcon className='cursor-pointer' />
                                    </TooltipText></label>
                                <input
                                    type='number'
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    placeholder='e.g. 3'
                                    {...register('floor_no')}
                                />

                            </div>
                            {/* Tower No. */}
                            {/* {type !== 'rent' && <div className=''>
                                <label >Tower No.</label>
                                <input
                                    type='text'
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    placeholder='e.g. 8'
                                    {...register('tower')}
                                />
                            </div>} */}

                            {/* No. of seats */}
                            {type === 'commercial' && <div className=''>
                                <label >No. of Seats</label>
                                <input
                                    type='number'
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    {...register('seats_count')}
                                    placeholder='e.g. 100'
                                />
                            </div>}

                            {/* No. of Bathroom */}
                            {type === 'rent' && <div className=''>
                                <label >No. of Bathroom</label>
                                <input
                                    type='number'
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    {...register('bathroom_count')}
                                    placeholder='e.g. 100'
                                />
                            </div>}
                            {/* No. of Office Cabins */}
                            {type === 'commercial' && <div className=''>
                                <label >No. of Office Cabins</label>
                                <input
                                    type='number'
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    {...register('office_cabins')}
                                    placeholder='e.g. 100'
                                />
                            </div>}
                            {/* Plan Link */}
                            <div className=''>
                                <label >Plan Link</label>
                                <input
                                    type='text'
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    {...register('plan_link')}
                                    placeholder='e.g. 100'
                                />
                            </div>
                        </form>
                    </div>
                </div>

                <div className='!px-6 !py-4 flex sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                    <div className='w-full'>
                        <p className='m-0 inter sm:text-xl text-lg text-textblack font-semibold inter'>Total Price : ₹ {toINRFormat(bookingPricing?.total_price || '-')}</p>
                        <p className='m-0 text-sm inter text-black700 !mt-1 inter'>For {unit?.name || '-'}</p>
                    </div>
                    <div className='flex w-full justify-end gap-x-3'>
                        <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:!bg-black100 !bg-white' onClick={closeModal}>Cancel</button>
                        <button form='unit-form' className='text-base font-medium inter leading-6 rounded-lg !border !border-primary !text-white sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:opacity-90 !bg-primary'>
                            Save Changes
                        </button>
                    </div>

                </div>
            </div>
        </BasicModal>
    )
}

export default EditUnitDetails;