import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import mapIMg from '../../assets/icons/map.png'
import InputSelect from '../InputGroup/InputSelect';
import { useParams } from 'react-router-dom';
import { getCitiesFromState, getProjectBasicDetailsByUUID, getStates, updateLocation } from '../../services/public/public.service';
import { Controller, useForm } from 'react-hook-form';
import ProjectHeader from './project-header';
import { yupResolver } from '@hookform/resolvers/yup';
import { locationSchema } from './validations/locationValidation';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { useCallback } from 'react';

// Set your Google Maps API key here
const apiKey = 'AIzaSyDRuni-c1oJBFaF8ahTxlxPmYyCmRRtclw';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const textareastyle = {
    minHeight: 'unset!important',
    important: true

};

const Location = ({ google,
    handleUpdateLocation,
    googleAddress,
    previousCity,
    latitude,
    longitude,
    previousState,
    register,
    errors
}) => {
    const error = '';
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const mapRef = useRef(null);
    const searchInputRef = useRef(null);
    const autocompleteService = useRef(null);
    const autocomplete = useRef(null);
    const markerRef = useRef(null);


    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const property = searchParams.get('pt');
    const transaction = searchParams.get('tt');


    const {
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { isSubmitting }
    } = useForm({
        defaultValues: {
            city: '',
            state: '',
            customAddress: '',
            projectLocation: '',
            nearbyLandmark: ''
        },
        resolver: yupResolver(locationSchema)
    });

    const state = watch('state');
    const getBasicDetails = async () => {
        const res = await getProjectBasicDetailsByUUID(uuid)
        if (res.status === 200) {
            const data = res.data.data;
            reset({
                city: data?.city ? { label: data.city, value: data.city } : '',
                state: data?.state ? { label: data.state, value: data.state } : '',
                customAddress: data?.site_address || '',
                projectLocation: data?.google_address || '',
                nearbyLandmark: data?.landmark || ''
            },
                { keepDefaultValues: true }
            )
            getCity(data?.state)
        }
    }

    const initAutocomplete = useCallback(() => {
        autocompleteService.current = new google.maps.places.AutocompleteService();
        autocomplete.current = new google.maps.places.Autocomplete(searchInputRef.current);
        autocomplete.current.addListener('place_changed', handlePlaceChanged);
    }, []);

    useEffect(() => {
        initAutocomplete();
    }, []);

    useEffect(() => {
        if (uuid) getBasicDetails();
    }, [uuid]);

    useEffect(() => {
        let lat = latitude ? parseFloat(latitude) : null;
        let lng = longitude ? parseFloat(longitude) : null;

        setSelectedLocation({
            lat: lat,
            lng: lng,
            address: googleAddress,
            city: previousCity,
            state: previousState,
        })
        searchInputRef.current.value = googleAddress;
        if (mapRef.current && lat && lng) {
            mapRef.current.map.setCenter({ lat, lng });
            mapRef.current.map.panTo({ lat, lng });
        }

        if (markerRef.current && lat && lng) {
            markerRef.current.marker.setPosition({ lat, lng });
        }

    }, [googleAddress, previousCity, latitude, longitude, previousState])

    const handlePlaceChanged = useCallback(() => {
        const place = autocomplete.current.getPlace();
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const address = place.formatted_address;

            let city = '';
            let state = '';
            let country = '';

            place.address_components.forEach(component => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    state = component.long_name;
                } else if (component.types.includes('country')) {
                    country = component.long_name;
                }
            });

            setSelectedLocation({ lat, lng, address, city, state, country });
            handleUpdateLocation(city, address, state, lat, lng);

            if (mapRef.current) {
                mapRef.current.map.setCenter({ lat, lng });
                mapRef.current.map.panTo({ lat, lng });
            }

            if (markerRef.current) {
                markerRef.current.marker.setPosition({ lat, lng });
            }
        }
    }, []);
    // const handlePlaceChanged = () => {
    //     const place = autocomplete.current.getPlace();
    //     if (place.geometry) {
    //         const lat = place.geometry.location.lat();
    //         const lng = place.geometry.location.lng();
    //         const address = place.formatted_address;

    //         setSelectedLocation({ lat, lng, address });
    //         console.log(address, 'location', place)
    //         setValue('projectLocation', address)

    //         if (mapRef.current) {
    //             mapRef.current.map.setCenter({ lat, lng });
    //             mapRef.current.map.panTo({ lat, lng });
    //         }

    //         if (markerRef.current) {
    //             markerRef.current.marker.setPosition({ lat, lng });
    //         }
    //     }
    // };

    const handleMapClick = useCallback((mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].formatted_address;
                    setSelectedLocation({ lat, lng, address });
                }
            }
        });

        if (markerRef.current) {
            markerRef.current.marker.setPosition({ lat, lng });
        }
    }, []);

    const handleMarkerDrag = (mapProps, map, event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].formatted_address;

                    setSelectedLocation({ lat, lng, address });
                }
            }
        });
    };

    const handleSearchChange = useCallback(() => {
        const query = searchInputRef.current.value;

        if (query && autocompleteService.current) {
            const request = {
                input: query,
            };

            autocompleteService.current.getPlacePredictions(request, (predictions) => {
                if (predictions && predictions.length > 0) {
                    autocomplete.current.set('predictions', predictions);
                }
            });
        }
    }, []);

    const handleGetDirections = () => {
        const { lat, lng } = selectedLocation;
        const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        window.open(url, '_blank');
    };

    const getAllStates = async () => {
        const res = await getStates()
        if (res.data.status === 200) {
            const states = res.data.data.map((data) => {
                return {
                    label: data.name,
                    value: data.name,
                };
            });
            setStateList(states);
        }
    }

    const getCity = async (state) => {
        const res = await getCitiesFromState(state);
        if (res.data.status === 200) {
            const cities = res.data.data.map((data) => {
                return {
                    label: data.name,
                    value: data.name,
                };
            });
            setCityList(cities);
        }
    }
    useEffect(() => {
        getAllStates();
    }, []);

    const onFormSubmit = async (values) => {
        console.log(values, 'values')
        const payload = {
            state: values.state.value,
            city: values.city.value,
            site_address: values.customAddress,
            google_address: values.projectLocation,
            landmark: values.nearbyLandmark,
        }

        const res = await updateLocation(uuid, payload);
        if (res.status === 200) {
            history.push(`/projects/create-projects/mediaupload/${uuid}?pt=${property}&tt=${transaction}`)
        }
    }

    return (
        <div className={`${style.creatproject} `}>

            <div className={`${style.projecttitle} border-color d-flex align-items-center justify-content-between `}>
                <h1 className='fz18 black fw-po-semi inter mb-2'>Location</h1>

            </div>
            <div className={`${style.Location} d-flex flex-fill flex-column  border-top border-color pt-20`}>
                <div className={`${style.basic_form} basic_form`}>
                    <div className={`${style.groupofinput}`}>
                        <div className={`form-group ${errors?.google_address?.message ? 'invalid' : ''} position-relative`}>
                            <label>Full Address <sup>*</sup></label>
                            <input
                                className={errors?.google_address?.message ? 'form-control text-input error' : 'form-control text-input'}
                                type={"text"}
                                placeholder='e.g. Orchid Island'
                                ref={searchInputRef}
                                // value={googleAddress}
                                onChange={handleSearchChange}
                            // {...register('projectLocation')}
                            />

                            {errors?.google_address?.message && (
                                <div className="input-feedback mt-8">
                                    {errors?.google_address?.message}
                                </div>
                            )}
                        </div>
                        {/* <div>
                                    <Controller
                                        control={control}
                                        name='state'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='State'
                                                    placeholder='Choose State'
                                                    onChange={(value) => {
                                                        onChange(value);
                                                        setValue('city', '');
                                                        console.log(value.value)
                                                        getCity(value.value);
                                                    }}
                                                    value={value}
                                                    options={stateList}
                                                    error={errors?.state?.message ? 'Please select State' : ''}
                                                    styles={customStyles}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />

                                </div> */}
                        <div className={`form-group ${errors?.city?.message ? 'invalid' : ''} position-relative`}>
                            <label>City <sup>*</sup></label>
                            <input
                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                type={"text"}
                                placeholder='e.g. New Delhi'
                                value={selectedLocation?.city}
                                onChange={handleSearchChange}
                                disabled
                            // {...register('projectLocation')}
                            />

                            {errors?.city?.message && (
                                <div className="input-feedback mt-8">
                                    {errors?.city?.message}
                                </div>
                            )}
                        </div>
                        {/* <div>
                            <Controller
                                control={control}
                                name='city'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (

                                    <>
                                        <InputSelect
                                            label={'City'}
                                            value={value}
                                            options={cityList}
                                            placeholder='Choose City'
                                            onChange={onChange}
                                            error={errors?.city?.message ? 'Please select City' : ''}
                                            styles={customStyles}
                                            required={true}
                                        />
                                    </>
                                )}
                            />

                        </div> */}
                        <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                            <label>Nearby Landmark</label>
                            <input
                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                type={"text"}
                                placeholder='Enter Landmark'
                                {...register('landmark')}
                            />
                        </div>

                    </div>
                    <div className='form-group'>
                        <label htmlFor="">Google Maps</label>
                        <div className={`${style.gmap} border border-color radius-8`}>
                            {/* <img src={mapIMg} alt="" /> */}
                            <Map
                                google={google}
                                zoom={14}
                                onClick={handleMapClick}
                                initialCenter={{ lat: 37.7749, lng: -122.4194 }}
                                style={{ width: '100%', height: '100%', marginBottom: '0' }}
                                ref={mapRef}
                            >
                                {selectedLocation && (
                                    <Marker position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }} />
                                )}
                            </Map>
                        </div>

                    </div>
                </div>

                {/* <div className={`${style.col_span_2}`}>
                                <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                    <label>Custom Address</label>
                                    <textarea rows="4" style={textareastyle}
                                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                                        type={"text"}
                                        placeholder='Enter Complete Address'
                                        {...register('customerAddress')}
                                    />
                                </div>

                            </div> */}


            </div>



            {/* <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                    <button
                        type='button'
                        className={`${style.cancelbtn}`}
                        onClick={() => {
                            history.push(`/projects/create-projects/configuration/${uuid}?pt=${property}&tt=${transaction}`)
                        }
                        }
                    ><LeftIcon />Previous </button>
                    <button type='submit' className={`${style.savebtn}`}>Save & Next</button>
                </div> */}



        </div >
    )
}

export default GoogleApiWrapper({
    apiKey: apiKey,
    libraries: ['places'],
})(Location);

