import React from 'react'
import { Link } from 'react-router-dom'


function ShortsDetails(props) {
    return (
        <div className='main-section position-relative'>
            <div className="title-wraper">
                <ul className='d-flex flex-row align-items-center'>

                    <li><Link to='/learning-center' className='pr-hover'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className='mr-16' viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.01182 10L10.5892 13.5774C10.9147 13.9028 10.9147 14.4305 10.5892 14.7559C10.2638 15.0814 9.73616 15.0814 9.41072 14.7559L5.24406 10.5893C4.91862 10.2638 4.91862 9.73618 5.24406 9.41074L9.41072 5.24408C9.73616 4.91864 10.2638 4.91864 10.5892 5.24408C10.9147 5.56951 10.9147 6.09715 10.5892 6.42259L7.01182 10Z" fill="#171725" />
                        <line x1="7.33331" y1="10" x2="14.3333" y2="10" stroke="#171725" strokeWidth="2" strokeLinecap="round" />
                    </svg>Shorts</Link></li>
                    <li><img src={`${process.env.PUBLIC_URL}/right.png`} /></li>
                    <li>Shorts name</li>
                </ul>
            </div>

            <div className="overview-serction">
                <div className="row">
                    <div className="col-12">

                        <h5 className="h-font fz28 fw-po-medium mb-24">Get your leads at your desk without marketing</h5>
                        <div id="section">
                            <div className="row">
                                <div className="col-md-8">
                                    <figure>
                                        <img src={`${process.env.PUBLIC_URL}/rahul.png`} alt="" className='w-100' />
                                    </figure>
                                </div>
                                <div className="col-md-4">
                                    <h2 className='fz24 mb-8 black'>Description</h2>
                                    <p className='fz14 black-dark-700 pr-5 mr-5 mb-16'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra sit a sed ut. Natoque quis imperdiet sit id odio.</p>
                                    <a href="" className='pr-hover pr-color fz14 fw-po-medium'><svg className='mr4' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8 2C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H16C18.2091 22 20 20.2091 20 18V8.75425C20 8.29587 19.8425 7.8514 19.554 7.49524L15.7022 2.74098C15.3224 2.27227 14.7514 2 14.1482 2H8ZM15.625 5.82283V7.4H16.9028L15.625 5.82283ZM8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V9.4H15.625C14.5204 9.4 13.625 8.50457 13.625 7.4V4H8ZM13 14.5L13.4 14.2C13.8418 13.8686 14.4686 13.9582 14.8 14.4C15.1314 14.8418 15.0418 15.4686 14.6 15.8L12.6081 17.2939C12.4396 17.4232 12.2288 17.5 12 17.5C11.7712 17.5 11.5604 17.4232 11.3919 17.2939L9.4 15.8C8.95817 15.4686 8.86863 14.8418 9.2 14.4C9.53137 13.9582 10.1582 13.8686 10.6 14.2L11 14.5V10.6114C11 10.0591 11.4477 9.61139 12 9.61139C12.5523 9.61139 13 10.0591 13 10.6114V14.5Z" fill="#0062FF" stroke="white" strokeWidth="0.4" />
                                    </svg>Download course material</a>
                                </div>
                            </div>

                        </div>




                    </div>

                </div>
            </div>
        </div >
    )
}



export default ShortsDetails
