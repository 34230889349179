import React, { Component } from 'react';
import { eventEnum, statusEnum } from '../../config/enums';
import { ACTIVITY_VIEWS } from '../../modules/Lead Details/components/Detail Sidebar/ACTIVITY_VIEWS';



export default class StatusDropDown extends Component {
    constructor(props) {
        super(props);
    }

    clickHandler = (label, value) => {
        let event = '';
        switch (label) {
            case statusEnum.NOT_CONNECTED:
                event = eventEnum.CREATE_FOLLOWUP;
                break;
            case statusEnum.IN_PROGRESS:
                event = eventEnum.CREATE_FOLLOWUP;
                break;
            case statusEnum.SV_SCHEDULES:
                event = eventEnum.CREATE_SV;
                break;
            case statusEnum.SV_COMPLETED:
                event = eventEnum.CREATE_FOLLOWUP;
                break;
            case statusEnum.EOI_COMPLETED:
                event = eventEnum.CREATE_FOLLOWUP;
                break;
            case statusEnum.BOOKING_COMPLETED:
                // event = eventEnum.CREATE_FOLLOWUP;
                break;
        }

        this.props.onClickHandler(value, event);
    };


    render() {
        return <>
            {/* Status Drodown */}
            <div className="assigned-drop ml-20">
                {/* Selected */}
                <button className="outline" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.props.defaultLabel}
                    <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#0062FF
" />
                    </svg>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        this.props.options.map(option => {
                            return <a
                                key={option.value}
                                onClick={() => this.clickHandler(option.label, option.value)}
                                className={`dropdown-item ${this.props.defaultValue == option.value ? 'selected' : ''}`}
                            >
                                {option.label}
                            </a>
                        })
                    }
                    {/* <a className="dropdown-item" href="#">Cold Lead</a>
                    <a className="dropdown-item" href="#">Hot Lead</a>
                    <a className="dropdown-item" href="#">Warm Lead</a>
                    <a className="dropdown-item" href="#">Dead Lead</a> */}
                </div>
            </div>
        </>
    }
}