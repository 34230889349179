import React, { useEffect, useState } from 'react'
import CountCard from './CountCard'
import BookingCard from './Cards/BookingCard'
import CollectionsCard from './Cards/CollectionsCard'
import DemandCard from './Cards/DemandCard'
import BookingVerifyCard from './Cards/BookingVerifyCard'
import ViewNotes from './Modal/ViewNotes'
import ReassignLeadModal from '../Modals/ReassignLeadModal'
import { deleteLeads, reassignLeads } from '../../services/private/leads.service'
import { toast } from 'react-toastify'
import { getBookingDoneDetails, getBookingPaymentMilestonesByBookingId, getDemandDetail, updateBookingApprovalStatus } from '../../services/private/inventory.service'
import AddDemand from './Modal/AddDemand';
import AddDebitTransaction from './Modal/AddDebitTransaction';
import ResentCollection from './Modal/ResentCollection';
import AddOtherTransaction from './Modal/AddOtherTransaction'
import AddCollection from './Modal/AddCollection'
import DetailsModal from '../../modules/Inventory/Modal/details'
import ConfirmDeletion from '../Modals/ConfirmDeletion'
import AddPDC from './Modal/AddPDC'

const getStatsCount = (value, stats) => {
    const findStat = stats.find(item => item.approval_status === value);
    return findStat?.count || 0
}

const totalStats = (stats) => {
    let sum = 0;
    stats.forEach(item => {
        if (item.count) {
            sum += parseInt(item.count)
        }
    });
    return sum;
}

const Booking = ({
    allBookings,
    overAllStats,
    bookingStats,
    setSelectedStatus,
    bookingCollections,
    getCollectionsDetails,
    selectedProject,
    allowedPermission,
    currentTab
}) => {

    const [allApproveds, setAllApproved] = useState([])
    const [allPendings, setAllPendings] = useState([]);
    const [allRejected, setAllRejected] = useState([]);
    const [showViewNotes, setShowViewNotes] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState({});
    const [showReassignModal, setShowReassignModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [show, setShow] = useState(false);
    const [paymentMilestones, setPaymentMilestones] = useState([]);
    const [showDebitTransaction, setShowDebitTransaction] = useState(false);
    const [showVerifiyCollection, setShowVerifyCollection] = useState(false);
    const [showOtherTransaction, setShowOtherTransaction] = useState(false);
    const [showAddCollection, setShowAddCollection] = useState(false);
    const [bookingUUId, setBookingUUID] = useState('');
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [bookingStatus, setBookingStatus] = useState('');
    const [selectedBooking, setSelectedBooking] = useState({});
    const [isEditDemand, setIsEditDemand] = useState(false);
    const [editDemandData, setEditDemandData] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddPDCModal, setShowAddPDCModal] = useState(false);

    useEffect(() => {
        setAllApproved(allBookings?.filter(item => item.approval_status === 'approved'))
        setAllPendings(allBookings?.filter(item => item.approval_status === 'pending'));
        setAllRejected(allBookings?.filter(item => item.approval_status === 'rejected'))
    }, [allBookings]);

    const handleReassignModalSubmit = (userDetails, remarks) => {
        reassignLeads([selectedItem.lead_uuid], userDetails.value, remarks).then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned")
                // getLeadsData();
                setSelectedItem('');
                setShowReassignModal(false)
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
        // resetDataMods()
    }

    const getPaymentMilestones = async (booking_uuid, demand_uuid) => {
        const res = await getBookingPaymentMilestonesByBookingId(booking_uuid);
        if (res.data.status === 200) {
            const milestones = res.data.data.filter(item => !item.is_completed).map(milestone => ({ ...milestone, label: `${milestone.count}. ${milestone.description}`, value: milestone.id }));
            setPaymentMilestones(milestones);
            if (demand_uuid) {
                handleGetDemandDetails(demand_uuid);
            } else {
                setShow(true);
            }
        }
    }
    const getBookingDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getBookingDoneDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
            }
        }
    }

    const handleChangeBookingStatus = async (payload) => {
        const res = await updateBookingApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            bookingCollections();
            setShowDetailsModal(false)
        }
    }

    const handleUpdateBooking = async (payload) => {
        const res = await updateBookingApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            bookingCollections();
            // setShowCancelBooking(false);
            // setShowDetailsModal(false);
            setSelectedBooking({})
        }
    }

    const handleGetDemandDetails = async (uuid) => {
        const res = await getDemandDetail(uuid)
        if (res.data.status === 200) {
            setEditDemandData(res.data.data.demand);
            setShow(true);
        }
    }

    const handleUserDeletion = (selectedValue) => {
        deleteLeads(selectedValue).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message);
                bookingCollections();
                setShowDeleteModal(false);
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    return (
        <>
            <div className='w-100 grid  sm:grid-cols-4 grid-cols-2	 sm:!gap-x-8 gap-x-4 sm:gap-y-0 gap-y-4 '>
                <div>
                    <CountCard
                        title={overAllStats?.totalDemandRaised?.total}
                        subtext='Demand Raised'
                    // icon
                    />
                </div>
                <div>
                    <CountCard
                        title={overAllStats?.totalCollectedAmount?.total}
                        subtext='Collected'
                    // icon
                    />
                </div>
                <div>
                    <CountCard
                        title={overAllStats?.totalDueAmount?.total}
                        subtext='Amount Due'
                        icon
                    />
                </div>
                <div>
                    <CountCard
                        title={overAllStats?.totalOverDueAmount?.total}
                        subtext='Overdue'
                        icon
                    />
                </div>

            </div>
            <div className='mt-7'>
                <nav>
                    <div className="nav nav-tabs border-0 !mb-2 gap-x-6 tabsactive" id="nav-tab" role="tablist">
                        <a
                            className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary active"
                            id="nav-All-tab"
                            data-toggle="tab"
                            href="#nav-All"
                            role="tab"
                            aria-controls="nav-All"
                            aria-selected="true"
                            onClick={() => setSelectedStatus(null)}
                        >
                            All ({totalStats(bookingStats)})
                        </a>
                        <a
                            className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary"
                            id="nav-Approved-tab"
                            data-toggle="tab"
                            href="#nav-Approved"
                            role="tab"
                            aria-controls="nav-Approved"
                            aria-selected="false"
                            onClick={() => setSelectedStatus('approved')}
                        >
                            Approved ({getStatsCount('approved', bookingStats)})
                        </a>
                        <a
                            className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary"
                            id="nav-Pending-Approval-tab"
                            data-toggle="tab"
                            href="#nav-Pending-Approval"
                            role="tab"
                            aria-controls="nav-Pending-Approval"
                            aria-selected="false"
                            onClick={() => setSelectedStatus('pending')}
                        >
                            Pending Approval ({getStatsCount('pending', bookingStats)})
                        </a>
                        <a
                            className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary"
                            id="nav-Rejected-tab"
                            data-toggle="tab"
                            href="#nav-Rejected"
                            role="tab"
                            aria-controls="nav-Rejected"
                            aria-selected="false"
                            onClick={() => setSelectedStatus('rejected')}
                        >
                            Rejected ({getStatsCount('rejected', bookingStats)})
                        </a>

                    </div>
                </nav>
                <div className="tab-content !pb-6" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-All" role="tabpanel" aria-labelledby="nav-All-tab">
                        {allBookings.length > 0 ? allBookings.map(item => (
                            <div className='flex flex-col gap-y-5 !mt-5'>
                                {item?.approval_status === 'approved' &&
                                    <BookingCard
                                        item={item}
                                        setShow={setShow}
                                        setSelectedItem={setSelectedItem}
                                        setShowViewNotes={setShowViewNotes}
                                        setSelectedCollection={setSelectedCollection}
                                        setShowReassignModal={setShowReassignModal}
                                        getPaymentMilestones={getPaymentMilestones}
                                        setPaymentMilestones={setPaymentMilestones}
                                        bookingCollections={bookingCollections}
                                        setShowDebitTransaction={setShowDebitTransaction}
                                        selectedProject={selectedProject}
                                        setShowOtherTransaction={setShowOtherTransaction}
                                        allowedPermission={allowedPermission}
                                        currentTab={currentTab}
                                        setShowAddCollection={setShowAddCollection}
                                        setShowVerifyCollection={setShowVerifyCollection}
                                        key={item.uuid}
                                        handleGetDemandDetails={handleGetDemandDetails}
                                        setIsEditDemand={setIsEditDemand}
                                        setShowDeleteModal={setShowDeleteModal}
                                        setBookingStatus={setBookingStatus}
                                        getBookingDetails={getBookingDetails}
                                        setShowAddPDCModal={setShowAddPDCModal}
                                    />
                                }

                                {
                                    item.approval_status !== 'approved' &&
                                    <BookingCard
                                        item={item}
                                        setShowReassignModal={setShowReassignModal}
                                        setSelectedItem={setSelectedItem}
                                        getPaymentMilestones={getPaymentMilestones}
                                        setShow={setShow}
                                        setPaymentMilestones={setPaymentMilestones}
                                        bookingCollections={bookingCollections}
                                        setShowDebitTransaction={setShowDebitTransaction}
                                        setShowVerifyCollection={setShowVerifyCollection}
                                        selectedProject={selectedProject}
                                        setShowOtherTransaction={setShowOtherTransaction}
                                        allowedPermission={allowedPermission}
                                        currentTab={currentTab}
                                        setShowAddCollection={setShowAddCollection}
                                        getBookingDetails={getBookingDetails}
                                        setBookingStatus={setBookingStatus}
                                        key={item.uuid}
                                        handleGetDemandDetails={handleGetDemandDetails}
                                        setIsEditDemand={setIsEditDemand}
                                        setShowDeleteModal={setShowDeleteModal}
                                        setShowAddPDCModal={setShowAddPDCModal}
                                        setSelectedCollection={setSelectedCollection}
                                    />
                                }

                                {/* {item?.demand_uuid &&
                                    <DemandCard item={item} />
                                }

                                {item?.collection_uuid &&
                                    <CollectionsCard item={item} />
                                } */}

                            </div>
                        )) :

                            <div className='flex flex-col justify-center items-center h-[45vh]'>
                                <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Bookings Found</h2>
                                {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                {/* <button className="pr-btn px-32 py-8 fz16 mt-24 d-table mx-auto" onClick={showCreateLeadModal}>Create new lead</button> */}
                            </div>
                        }


                    </div>
                    <div className="tab-pane fade" id="nav-Approved" role="tabpanel" aria-labelledby="nav-Approved-tab">
                        {
                            allApproveds.length > 0 ? allApproveds?.map(item => (
                                <div className='flex flex-col gap-y-5 !mt-5'>
                                    <BookingCard
                                        item={item}
                                        setShow={setShow}
                                        setSelectedItem={setSelectedItem}
                                        setShowViewNotes={setShowViewNotes}
                                        setSelectedCollection={setSelectedCollection}
                                        setShowReassignModal={setShowReassignModal}
                                        getPaymentMilestones={getPaymentMilestones}
                                        setPaymentMilestones={setPaymentMilestones}
                                        bookingCollections={bookingCollections}
                                        setShowDebitTransaction={setShowDebitTransaction}
                                        setShowVerifyCollection={setShowVerifyCollection}
                                        selectedProject={selectedProject}
                                        setShowOtherTransaction={setShowOtherTransaction}
                                        allowedPermission={allowedPermission}
                                        currentTab={currentTab}
                                        setBookingStatus={setBookingStatus}
                                        setShowAddCollection={setShowAddCollection}
                                        key={item.uuid}
                                        handleGetDemandDetails={handleGetDemandDetails}
                                        setIsEditDemand={setIsEditDemand}
                                        setShowDeleteModal={setShowDeleteModal}
                                        getBookingDetails={getBookingDetails}
                                        setShowAddPDCModal={setShowAddPDCModal}
                                    />
                                </div>
                            )) :
                                <div className='flex flex-col justify-center items-center h-[45vh]'>
                                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8"> No Bookings Found</h2>
                                    {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                    {/* <button className="pr-btn px-32 py-8 fz16 mt-24 d-table mx-auto" onClick={showCreateLeadModal}>Create new lead</button> */}
                                </div>
                        }

                    </div>
                    <div className="tab-pane fade" id="nav-Pending-Approval" role="tabpanel" aria-labelledby="nav-Pending-Approval-tab">
                        {
                            allPendings.length ? allPendings?.map(item => (
                                <div className='flex flex-col gap-y-5 !mt-5'>
                                    <BookingCard
                                        item={item}
                                        setShow={setShow}
                                        setSelectedItem={setSelectedItem}
                                        setShowReassignModal={setShowReassignModal}
                                        getPaymentMilestones={getPaymentMilestones}
                                        setPaymentMilestones={setPaymentMilestones}
                                        bookingCollections={bookingCollections}
                                        setShowDebitTransaction={setShowDebitTransaction}
                                        setShowVerifyCollection={setShowVerifyCollection}
                                        selectedProject={selectedProject}
                                        setShowOtherTransaction={setShowOtherTransaction}
                                        allowedPermission={allowedPermission}
                                        currentTab={currentTab}
                                        getBookingDetails={getBookingDetails}
                                        setBookingStatus={setBookingStatus}
                                        setShowAddCollection={setShowAddCollection}
                                        key={item.uuid}
                                        handleGetDemandDetails={handleGetDemandDetails}
                                        setIsEditDemand={setIsEditDemand}
                                        setShowDeleteModal={setShowDeleteModal}
                                        setShowAddPDCModal={setShowAddPDCModal}
                                        setSelectedCollection={setSelectedCollection}
                                    />
                                </div>
                            )) :
                                <div className='flex flex-col justify-center items-center h-[45vh]'>
                                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8"> No Bookings Found</h2>
                                    {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                    {/* <button className="pr-btn px-32 py-8 fz16 mt-24 d-table mx-auto" onClick={showCreateLeadModal}>Create new lead</button> */}
                                </div>
                        }

                    </div>
                    <div className="tab-pane fade" id="nav-Rejected" role="tabpanel" aria-labelledby="nav-Rejected-tab">
                        {allRejected.length ? allRejected?.map(item => (
                            <div className='flex flex-col gap-y-5 !mt-5'>
                                <BookingCard
                                    item={item}
                                    setShow={setShow}
                                    setSelectedItem={setSelectedItem}
                                    setShowReassignModal={setShowReassignModal}
                                    getPaymentMilestones={getPaymentMilestones}
                                    setPaymentMilestones={setPaymentMilestones}
                                    bookingCollections={bookingCollections}
                                    setShowVerifyCollection={setShowVerifyCollection}
                                    setShowOtherTransaction={setShowOtherTransaction}
                                    allowedPermission={allowedPermission}
                                    currentTab={currentTab}
                                    getBookingDetails={getBookingDetails}
                                    setBookingStatus={setBookingStatus}
                                    setShowAddCollection={setShowAddCollection}
                                    key={item.uuid}
                                    setIsEditDemand={setIsEditDemand}
                                    setShowDeleteModal={setShowDeleteModal}
                                    setShowAddPDCModal={setShowAddPDCModal}
                                    setSelectedCollection={setSelectedCollection}
                                />
                            </div>
                        )) :
                            <div className='flex flex-col justify-center items-center h-[45vh]'>
                                <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Bookings Found</h2>
                                {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                {/* <button className="pr-btn px-32 py-8 fz16 mt-24 d-table mx-auto" onClick={showCreateLeadModal}>Create new lead</button> */}
                            </div>
                        }

                    </div>

                </div>
            </div>
            {showViewNotes && <ViewNotes show={showViewNotes} closeModal={() => setShowViewNotes(false)} item={selectedCollection} />}
            {
                showReassignModal && <ReassignLeadModal
                    setShow={setShowReassignModal}
                    show={showReassignModal}
                    handleSubmit={(userId, remarks) => handleReassignModalSubmit(userId, remarks)}
                    count={1}
                    selectedLeadName={selectedItem.customer_name}
                />
            }
            {show && <AddDemand
                show={show}
                closeModal={() => {
                    setSelectedItem({})
                    setShow(false)
                }}
                item={selectedItem}
                paymentMilestones={paymentMilestones}
                onSuccess={bookingCollections}
                editDemandData={editDemandData}
            />}
            {showDebitTransaction &&
                <AddDebitTransaction
                    show={showDebitTransaction}
                    item={selectedItem}
                    closeModal={() => {
                        setSelectedItem({})
                        setShowDebitTransaction(false)
                    }}
                />
            }
            {
                showAddPDCModal && <AddPDC
                    show={showAddPDCModal}
                    closeModal={() => {
                        setShowAddPDCModal(false);
                    }}
                    item={selectedItem}
                />
            }
            {showVerifiyCollection && <ResentCollection
                show={showVerifiyCollection}
                closeModal={() => setShowVerifyCollection(false)}
                item={selectedItem}
                onSuccess={bookingCollections}
            />}
            {
                showOtherTransaction && <AddOtherTransaction
                    show={showOtherTransaction}
                    closeModal={() => setShowOtherTransaction(false)}
                />
            }
            {showAddCollection && <AddCollection
                show={showAddCollection}
                closeModal={() => setShowAddCollection(false)}
                item={selectedItem}
                onSuccess={bookingCollections}
                selectedProject={selectedProject}
            />}

            {showDetailsModal && <DetailsModal
                show={showDetailsModal}
                unit={{}}
                selectedBooking={selectedBooking}
                closeModal={() => setShowDetailsModal(false)}
                modalOpenFrom={'table'}
                bookingStatus={bookingStatus}
                ModalType={'Booking'}
                handleChangeApprovalStatus={handleChangeBookingStatus}
                handleSubmit={handleUpdateBooking}
                updateData={bookingCollections}
            />}
            {showDeleteModal && selectedItem.lead_uuid && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedItem.customer_name}?`}
                deleteHandler={() => handleUserDeletion(selectedItem.lead_uuid)}
                subText={`After removal, unit ${selectedItem?.block}, unit ${selectedItem?.block} will be unblocked.`}
            />}
        </>

    )
}

export default Booking
