import React, { useContext, useState } from 'react';
import BookingDone from '../Booking Done/BookingDone';
import CustomActivity from '../Custom Activity/CustomActivity';
import EOIDone from '../EOI Done/EOIDone';
import NewActivityOptions from '../New Activity Options/NewActivityOptions';
import RecentActivities from '../Recent Activities/RecentActivities';
import SidebarOptions from '../Sidebar Options/SidebarOptions';
import SiteVisitDone from '../Site Visit Done/SiteVisitDone';
import { ACTIVITY_VIEWS } from './ACTIVITY_VIEWS';

import createNoteLogo from "../../../../assets/icons/note.leaddetail.svg";
import createFollowupLogo from "../../../../assets/icons/followup.leaddetail.svg";
import createSiteVisitLogo from "../../../../assets/icons/sitevisit.leaddetail.svg";
import CreateNote from '../../../../components/LeadDetail/CreateNote/CreateNote';
import CreateFolloups from '../../../../components/LeadDetail/CreateFollowups/CreateFolloups';
import CreateSiteVist from '../../../../components/LeadDetail/CreateSiteVisit/CreateSiteVisit';
import Consumer from '../../../../helpers/context';



export default function DetailSidebar({
    notesCount,
    followupsCount,
    siteVisitsCount,
    reloadSideBarCount,
    intentOptions,
    leadInfoData,
    statusOptions,
    leadAccess
}) {

    const [showMenu, setShowMenu] = useState({
        showCreateNote: false,
        showCreateFollowup: false,
        showCreateSiteVisit: false,
        activityView: false
    })
    let { userProfile, allowedPermissions, isRolesModule, } = useContext(Consumer);

    const [activityView, setActivityView] = useState(ACTIVITY_VIEWS.RECENT_ACTIVITY)

    const toggleShowMenu = (menuName) => {
        setShowMenu({ ...showMenu, [menuName]: !showMenu[menuName] })
    }

    const changeActivityView = (view) => {
        setActivityView(view);
    }

    // display create new acitivity view
    const displayActivityView = () => {
        switch (activityView) {
            case ACTIVITY_VIEWS.RECENT_ACTIVITY:
                return <RecentActivities changeActivityView={changeActivityView} />

            case ACTIVITY_VIEWS.NEW_ACTIVITY_OPTIONS:
                return <NewActivityOptions
                    changeActivityView={changeActivityView}
                />

            case ACTIVITY_VIEWS.SITE_VISIT_DONE:
                return <SiteVisitDone
                    changeActivityView={changeActivityView}
                />

            case ACTIVITY_VIEWS.BOOKING_DONE:
                return <BookingDone
                    changeActivityView={changeActivityView}
                />

            case ACTIVITY_VIEWS.EOI_DONE:
                return <EOIDone
                    changeActivityView={changeActivityView}
                />

            case ACTIVITY_VIEWS.CUSTOM_ACTIVITY:
                return <CustomActivity
                    changeActivityView={changeActivityView}
                />
        }
    }


    return <>
        <div className="board position-relative pt-0">
            <div className="sidebar-body position-sticky">
                {/* Create Note */}
                <div className="mt-20"></div>
                <SidebarOptions
                    title={notesCount === 0 ? "Create Note" : 'Notes'}
                    count={notesCount}
                    logo={createNoteLogo}
                    clickHandler={() => toggleShowMenu("showCreateNote")}
                />

                <div className="lines"></div>

                {/* Create Followup */}
                <SidebarOptions
                    title="Followups"
                    count={followupsCount}
                    logo={createFollowupLogo}
                    clickHandler={() => toggleShowMenu("showCreateFollowup")}
                />
                <div className="lines"></div>

                {/* Create Site Visit */}
                <SidebarOptions
                    title="Site Visit"
                    count={siteVisitsCount}
                    logo={createSiteVisitLogo}
                    clickHandler={() => toggleShowMenu("showCreateSiteVisit")}
                />
                <div className="lines"></div>
            </div>
            {/* New Activity Options / Recent Activity */}
            {
                showMenu.showCreateNote ||
                    showMenu.showCreateFollowup ||
                    showMenu.showCreateSiteVisit ?
                    " "
                    :
                    displayActivityView()
            }

            {/* add active class when you show the sidebar */}
            {/* Notes creation form */}

            {showMenu.showCreateNote && <CreateNote
                showCreateNote={showMenu.showCreateNote}
                toggleCreateNote={() => toggleShowMenu("showCreateNote")}
                notesCount={notesCount}
                reloadSideBarCount={reloadSideBarCount}
                leadAccess={leadAccess}
                userProfile={userProfile}
                allowedPermissions={allowedPermissions}
                isRolesModule={isRolesModule}
            />}


            {/* Followup creation form */}
            {showMenu.showCreateFollowup && <CreateFolloups
                showCreateFollowup={showMenu.showCreateFollowup}
                toggleCreateFollowup={() => toggleShowMenu("showCreateFollowup")}
                followupsCount={followupsCount}
                reloadSideBarCount={reloadSideBarCount}
                intentOptions={intentOptions}
                leadInfoData={leadInfoData}
                statusOptions={statusOptions}
                leadAccess={leadAccess}
                userProfile={userProfile}
            />}


            {/* Site visit creation form */}
            {showMenu.showCreateSiteVisit && <CreateSiteVist
                showCreateSiteVisit={showMenu.showCreateSiteVisit}
                toggleCreateSiteVist={() => toggleShowMenu("showCreateSiteVisit")}
                siteVisitCount={siteVisitsCount}
                reloadSideBarCount={reloadSideBarCount}
                intentOptions={intentOptions}
                leadInfoData={leadInfoData}
                leadAccess={leadAccess}
                userProfile={userProfile}
                allowedPermissions={allowedPermissions}
            />}
        </div>
    </>
}
