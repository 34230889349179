
import React from 'react'
import { ReactComponent as LinkICon } from '../../../../assets/link.svg';
import { ReactComponent as WhatsappIcon } from '../../../../assets/whatsapp.svg';
import { toast } from 'react-toastify';

const CopyRefer = ({ referLink, message }) => {

    console.log(message)
    return (
        <div className='!p-6 bg-white'>
            <div className='!border !border-black400 !rounded-lg !p-3'>
                <label htmlFor="" className='text-gray800 text-sm font-medium !mb-3'>Copy your referral link and share it anywhere.</label>
                <div className='flex items-center justify-between gap-3 sm:flex-row flex-col'>
                    {/* <input type="text" value={referLink} className='h-11 !border border-black400 !px-4 py-px !rounded-lg focus:outline-none focus:!border-primary w-full' placeholder='Invitelink_Propacity/JZLPS' /> */}
                    <button
                        className='text-gray700 text-sm font-medium inline-flex gap-x-1 !rounded-lg items-center whitespace-nowrap h-11 shadow-white-btn-shadow bg-white !border !border-black400 px-2.5 sm:w-[166px] w-full  sm:basis-[166px] sm:shrink-0 sm:grow-0 justify-center'
                        onClick={() => {
                            navigator.clipboard.writeText(referLink).then(function () {
                                toast.success('Link Copied');
                            }, function (err) {
                                toast.error('Could not copy');
                            });
                        }}
                    ><LinkICon />Copy Link</button>


                    <a
                        href={`https://api.whatsapp.com/send?text=${message}`}
                        className='!rounded-lg items-center whitespace-nowrap h-11 grident-green text-white inline-flex gap-x-2 text-sm font-medium w-100 justify-center'><WhatsappIcon />Share on Whatsapp
                    </a>

                </div>
            </div>

        </div >
    )
}

export default CopyRefer
