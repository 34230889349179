import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import EnrollmentCard from '../../components/workshops-details/enrollment-card';
import Faqs from '../../components/workshops-details/faqs';
import Overview from '../../components/workshops-details/overview';
import { getWorkshopById, getWorkshopMentorsByWorkshopId } from '../../services/public/public.service';
import './WorkshopsDetails.css';

import parseMilliseconds from 'parse-ms';
import moment from 'moment';


const WorkshopsDetails = () => {
    const uuid = useParams().id
    const [data, setData] = useState([])
    let [hours, setHours] = useState(0)
    let [durationString, setDurationString] = useState("");
    const [zoomLinkStatus, setZoomLinkStatus] = useState(false);
    const [mentors, setMentors] = useState([]);

    const calculateDuration = () => {
        if (data.start_time && data.end_time) {
            const startTime = moment(data.start_time).format('hh:mm a');
            const endTime = moment(data.end_time).format('hh:mm a');
            const new_startTime = moment(startTime, "hh:mm a");
            const new_endTime = moment(endTime, "hh:mm a");
            const diff = new_endTime.diff(new_startTime, "milliseconds");
            setHours(diff)

            const durationString = createDuration(diff);
            setDurationString(durationString);
        }
    }

    const zoomLinkShow = () => {
        if (data.start_time && data.start_date) {
            const startTime = moment(data.start_time).format('hh:mm a');
            const startDate = moment(data.start_date).format('DD-MM-YYYY');
            const newMomentDate = moment(startDate + " " + startTime, "DD-MM-YYYY hh:mm a");
            const diff = newMomentDate.diff(moment(), "minutes");
            if (diff < 180) {
                setZoomLinkStatus(true)
            }
            else {
                setZoomLinkStatus(false)
            }
        }
    }

    const getWorkshop = async () => {
        await getWorkshopById(uuid)
            .then((res) => {
                setData(res.data.data.workshop)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getWorkshopMentors = async () => {
        await getWorkshopMentorsByWorkshopId(uuid)
            .then((res) => {
                setMentors(res.data.data.mentor)
            })
            .catch((err) => {
                console.log(err);
            })
    }




    useEffect(() => {
        calculateDuration()
        zoomLinkShow()
    }, [data])


    const createDuration = (diff) => {
        const obj = parseMilliseconds(Math.abs(diff));
        // console.log("🚀 ~ file: WorkshopsDetails.jsx ~ line 75 ~ createDuration ~ obj", obj)
        let rtnString = "";
        if (obj.hours === 1) {
            rtnString += "1 hr"
        }

        if (obj.hours > 1) {
            rtnString += `${obj.hours} hrs`
        }

        if (obj.minutes > 0) {
            rtnString += ` ${obj.minutes} mins`
        }

        return rtnString
    }

    useEffect(() => {
        getWorkshop()
        getWorkshopMentors()
        const el = document.getElementById('main-container')
        el.classList.add('workshop-detail')

        return (() => el.classList.remove('workshop-detail'))
    }, [])




    return (
        <div className='main-section position-relative'>
            <div className="title-wraper">
                <ul className='d-flex flex-row align-items-center'>

                    <li><Link to='/learning-center' className='pr-hover'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className='mr-16' viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.01182 10L10.5892 13.5774C10.9147 13.9028 10.9147 14.4305 10.5892 14.7559C10.2638 15.0814 9.73616 15.0814 9.41072 14.7559L5.24406 10.5893C4.91862 10.2638 4.91862 9.73618 5.24406 9.41074L9.41072 5.24408C9.73616 4.91864 10.2638 4.91864 10.5892 5.24408C10.9147 5.56951 10.9147 6.09715 10.5892 6.42259L7.01182 10Z" fill="#171725" />
                        <line x1="7.33331" y1="10" x2="14.3333" y2="10" stroke="#171725" strokeWidth="2" strokeLinecap="round" />
                    </svg>Learning Centre</Link></li>
                    <li><img src={`${process.env.PUBLIC_URL}/right.png`} /></li>
                    <li>{data.name}</li>
                </ul>
            </div>

            <div className="overview-serction">
                <div className="row">
                    <div className="col">
                        <div className="real-stat-card">
                            <h5 className="h-font">{data.name}</h5>
                            <figure className="d-md-block d-none">
                                <img src={data.detail_thumbnail} alt="" className='w-100' />
                            </figure>
                            <figure className="d-md-none d-block">
                                <img src={data.detail_thumbnail} alt="" className='w-100' />
                            </figure>
                            <div id="section">
                                <div className="article">
                                    <p>
                                        {data.short_description}
                                    </p>

                                </div>

                            </div>
                        </div>


                        <Overview workshop={data} createDuration={durationString} mentors={mentors} zoomLinkStatus={zoomLinkStatus} uuid={uuid} />
                        {data?.faq_questions?.length > 0 && <Faqs workshop={data} />}
                    </div>
                    <div className="col">
                        <EnrollmentCard workshop={data} uuid={uuid} createDuration={durationString} zoomLinkStatus={zoomLinkStatus} mentors={mentors} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WorkshopsDetails;