import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import RightModal from '../../Modals/RightModal';
import { insertPaymentPlan, updatePaymentPlanByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { useEffect } from 'react';
import moment from 'moment';

const PaymentPlanModal = ({ show, closeModal, style, getPaymentPlans, uuid, selectedPaymentPlan }) => {

    const error = '';

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            paymentPlan: '',
            description: '',
            offers: '',
            offersValidity: ''
        },
        // resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (Object.keys(selectedPaymentPlan).length > 0) {
            reset({
                paymentPlan: selectedPaymentPlan.name,
                description: selectedPaymentPlan.description,
                offers: selectedPaymentPlan.offers,
                offersValidity: new Date(selectedPaymentPlan.validity)
            },
                { keepDefaultValues: true }
            );

        }
    }, [selectedPaymentPlan])

    const onFormSubmit = async (values) => {

        const payload = {
            name: values.paymentPlan,
            description: values.description,
            offers: values.offers,
            validity: values.offersValidity || null
        }

        let res = {}
        if (selectedPaymentPlan?.uuid) {
            res = await updatePaymentPlanByUUID(selectedPaymentPlan.uuid, payload)
        } else {
            res = await insertPaymentPlan(uuid, payload);
        }
        if (res.status === 200) {
            toast.success(res.message);
            getPaymentPlans();
            reset();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal=
            {() => {
                reset();
                closeModal();
            }}
            modalClass={`${style.configurationModal} ${style.addPaymentPlanModal}  configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {selectedPaymentPlan?.uuid ? 'Edit' : 'Add'} Payment Plan Details</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    reset();
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} id='payment-detail' className={`${style.modalForm} modalForm d-flex h-100 overflow-auto flex-column px-20`}>
                <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                    <label>Payment Plan Name</label>
                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='e.g. Payment Plan 1'
                        {...register('paymentPlan')}
                    />
                </div>
                <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                    <label>Description</label>
                    <textarea
                        rows={3}
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='Enter Payment Plan Description'
                        {...register('description')}
                    />
                </div>
                <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                    <label>Offers</label>
                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='Enter Offers for this Payment Plan'
                        {...register('offers')}
                    />
                </div>

                <Controller
                    control={control}
                    name='offersValidity'
                    render={({
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <div className='position-relative'>
                            <label className=''>Offer Validity</label>
                            <DatePicker
                                placeholderText='Select Date'
                                onChange={onChange}
                                selected={value}
                                dateFormat="dd MMM yyyy"
                                minDate={new Date()}
                                className={`form-control text-input ${error ? 'error' : ''}`}
                            // error={errors?.state?.message ? 'Please select State' : ''}
                            />
                            <CalendarIcon className={`${style.calicon}`} />
                        </div >
                    )}
                />
            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    reset();
                    closeModal();
                }}>Cancel</button>
                <button type='button' disabled={!isDirty || isSubmitting} onClick={handleSubmit(onFormSubmit)} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default PaymentPlanModal
