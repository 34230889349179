import React from 'react'
import { ReactComponent as ArrowSmallRightIcon } from '../../assets/icons/ArrowSmallRight.svg';
import { toINRFormat } from '../../helpers/helpers';


const CountCard = ({ title, subtext, icon }) => {
    return (
        <div className='bg-white !border !border-grayLight !p-4 rounded-[10px] hover:!border-primary hover:!bg-blue-light relative'>
            <h2 className='inter lg:text-xl text-lg font-bold !mb-3 !text-black leading-7'> {title ? `₹ ${toINRFormat(title)}` : '-'}</h2>
            <p className='inter text-xs text-black700 mb-0 leading-4 tracking-[1px] uppercase'>{subtext}</p>
            {/* {icon &&
                <div className='absolute right-4 bottom-4'><ArrowSmallRightIcon /></div>
            } */}
        </div>
    )
}

export default CountCard;
