import React from 'react'

import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';

import { ReactComponent as AlertIcon } from '../../../../assets/icons/alert.svg';
import CenterModal from '../../../../components/Modals/center-modal';

const DeleteModal = ({ show, closeModal, handleSubmit }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!max-w-[450px]  [&>*]:!border-0 [&>*]:!rounded-lg [&>*]:!overflow-hidden' backDropClassName={''}>
            <div className=' relative !p-5'>
                <div>
                    <span className='mb-4 h-12 w-12 bg-[#FEE4E2] rounded-full flex items-center justify-center'>
                        <AlertIcon />
                    </span>
                    <button onClick={closeModal} className='absolute right-5 top-5'><CloseIcon /></button>
                </div>

                <div className='!mt-4'>
                    <h4 className='text-xl font-semibold m-0'>Delete Account Request </h4>
                    <div className='text-black700 text-sm font-medium !mb-3'>Your Personal information and application data associated with Propacity will be deleted.</div>
                    <div className='!mt-5 flex items-center !gap-4'>
                        <button
                            className='w-1/2  text-black700 border rounded-lg flex items-center justify-center !py-3'
                            onClick={handleSubmit}
                        >
                            {/* <span className='mr-2.5'><CallRingIcon /></span> */}
                            Cancel
                        </button>
                        <button
                            className='w-1/2 bg-red text-white rounded-lg flex items-center justify-center !py-3'
                            onClick={handleSubmit}
                        >
                            {/* <span className='mr-2.5'><CallRingIcon /></span> */}
                            Submit Request
                        </button>
                    </div>
                </div>
            </div>

        </CenterModal>
    )
}

export default DeleteModal;