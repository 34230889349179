import React, { useEffect, useState, useRef } from 'react'
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadicon.svg';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as Bgprimarycheckicon } from '../../assets/icons/bgprimarycheck.svg';
import { ReactComponent as BgclosegrayIcon } from '../../assets/icons/bgclosegray.svg';
import { ReactComponent as NameEditIcon } from '../../assets/icons/NameEdit.svg';
import { ReactComponent as SecureIcon } from '../../assets/icons/secure.svg';
import { ReactComponent as YtbIcon } from '../../assets/icons/ytb.svg';

import pdfimg from '../../assets/icons/pdfview.jpg'
import videoimg from '../../assets/icons/Video.jpg'
import youtubeIcone from '../../assets/icons/youtube.jpg';
import pptimg from '../../assets/icons/ppt.jpg'
import docImg from '../../assets/icons/doc.jpg'
import sheetImg from '../../assets/icons/sheet.jpg'
import InputText from '../InputGroup/InputText';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { getDocumentNames, getMediaName, getProjectMedia, insertProjectMedia, updateProjectMedia, uploadFile } from '../../services/public/public.service';
import { Oval } from 'react-loader-spinner';
import ProjectHeader from './project-header';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InputSelect from '../InputGroup/InputSelect';
import CreatableSelect from 'react-select/creatable';
import YoutubeModal from './Modal/YoutubeModal';
import { getYoutubeVideoId } from '../../helpers/helpers';

const MediaUpload = () => {
    const [activeButton, setActiveButton] = useState('new');
    const [dragging, setDragging] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [previousFile, setPreviousFile] = useState([]);
    const [goToNext, setGoToNext] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [mediaNameList, setMediaNameList] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [documentNamesList, setDocumentNamesList] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [uploadFileCount, setUploadFileCount] = useState();

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const property = searchParams.get('pt');
    const transaction = searchParams.get('tt');

    const scrollableRef = useRef(null);
    const [isActive, setIsActive] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = scrollableRef.current.scrollTop;
            if (scrollTop >= 50) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        };
        scrollableRef?.current?.addEventListener('scroll', handleScroll);

        return () => {
            scrollableRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getAllMedia = async () => {
        const res = await getProjectMedia(uuid);
        if (res.data.status) {
            if (res.data.data.length > 0) {
                let media = [];
                let doc = [];
                res.data.data.forEach(item => {
                    if (['mp4', 'mp3', 'mkv', 'jpg', 'png', 'gif', 'svg', 'jpeg'].includes(item.file_name.split('.').pop()) || item.type === 'youtube_video') {
                        media.push(item)
                    }
                    else {
                        doc.push(item)
                    }
                })
                setPreviousFile(media)
                setDocuments(doc)

            }
        }
    }

    const getAllMediaName = async () => {
        const res = await getMediaName();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ label: item.name, value: item.name }));
            setMediaNameList(list);
        }
    }

    const getAllDocumentName = async () => {
        const res = await getDocumentNames();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ label: item.name, value: item.name }));
            setDocumentNamesList(list);
        }
    }

    useEffect(() => {
        if (uuid) {
            getAllMediaName();
            getAllDocumentName();
            getAllMedia();
        }
    }, [uuid]);

    const handleClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    const handleOnChangeUpload = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                file_name: file.name,
                media_name: '',
                type: file.type,
                url: response.data.data.s3_url,
            }
            if (!(selectedIndex >= 0)) {
                updateMediaFile(newFile);
            }
            if (['mp4', 'mp3', 'mkv', 'jpg', 'png', 'gif', 'svg', 'jpeg'].includes(file.name.split('.').pop())) {
                const newFiles = [...previousFile];
                newFiles[selectedIndex] = newFile;
                setPreviousFile([...newFiles]);
            } else {
                const docs = [...documents];
                docs[selectedIndex] = newFile;
                setDocuments([...docs]);
            }
        }
    }

    const updateMediaFile = async (file) => {
        const res = await updateProjectMedia(file.uuid, file);
        if (res.data.status === 200) {
            getAllMedia();
            setSelectedMedia({});
            setSelectedIndex('');
            toast.success(res.data.message);
        }
    }

    useEffect(() => {
        localStorage.setItem('project_state', 'create')
    }, [])

    useEffect(() => {
        if (uploadFileCount === 0) {
            setUploading(false);
        }
    }, [uploadFileCount])

    const uploadFileToS3 = async (file, upload) => {
        // if (file.size / 1048576 > 5) {
        //     setSizeError('Size exceeds 5mb');

        //     return;
        // }
        // if (file.type !== 'text/csv') {
        //     setFileTypeError('Wrong File format Uploaded');

        //     return;
        // }
        // setUploading(true)

        const formData = new FormData();
        formData.append("file", file)
        try {
            const response = await uploadFile(formData);
            setUploadFileCount(prev => prev - 1);
            if (response.data.status === 200) {
                if (['mp4', 'mp3', 'mkv', 'jpg', 'png', 'gif', 'svg', 'jpeg'].includes(file.name.split('.').pop())) {
                    setPreviousFile(prevState => {
                        return [...prevState, {
                            file_name: file.name,
                            media_name: '',
                            type: file.type,
                            url: response.data.data.s3_url,
                        }]
                    })
                } else {
                    setDocuments(prevState => {
                        return [...prevState, {
                            file_name: file.name,
                            media_name: '',
                            type: file.type,
                            url: response.data.data.s3_url,
                        }]
                    })
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            setUploadFileCount(prev => prev - 1);
            toast.error(error.message)
        }
    }

    const handleOnChange = async (e) => {

        const files = [...e.target.files];
        setUploading(true);
        if (uploadFileCount > 0) {
            setUploadFileCount(uploadFileCount + files.length);
        } else {
            setUploadFileCount(files.length);
        }
        files.forEach((file, index) => {
            uploadFileToS3(file);

        });
    }

    const handleDrag = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setDragging(event.type !== 'dragleave');
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer || e.target;
        setUploading(true);
        if (uploadFileCount > 0) {
            setUploadFileCount(uploadFileCount + Object.keys(files).length);
        } else {
            setUploadFileCount(Object.keys(files).length);
        }

        Object.keys(files).forEach((file, index) => {
            uploadFileToS3(files[file]);
        });
        setDragging(false);
        return false;
    };

    const handleChangeMediaName = (e, index) => {
        const files = [...uploadedFiles];
        files[index].mediaName = e.target.value;
        setUploadedFiles(files);
    }

    const handleUploadYoutubeVideo = (youtubeFile) => {
        setPreviousFile([...previousFile, youtubeFile])
    }

    const handleSubmit = async (button) => {
        if (previousFile.length === 0) {
            return;
        }
        const payload = [...previousFile, ...documents]

        const res = await insertProjectMedia(uuid, payload)
        if (res.status === 200) {
            setPreviousFile([]);
            toast.success(res.data.message);
            history.push(`/projects/create-projects/additional-info/${uuid}?pt=${property}&tt=${transaction}`);
        }
    }

    return (
        <div className={`${style.creatproject}`}>
            <div className={`scrollable-div ${isActive ? 'active' : ''}`}>
                <ProjectHeader style={style} />
            </div>
            <div className={`${style.creatproject} overflow-auto`} >
                {/* <div className={`${style.projecttitle} border-color pt-30 pb-10 px-20 d-flex align-items-center justify-content-between border-top border-color`}>
                    <h1 className='fz24 black fw-po-semi inter mb-0'>Media Upload</h1>

                </div> */}
                <div className={`${style.mediaupalodtabsection} ${(previousFile.length > 0 || documents.length > 0) ? 'paddig_bottom_180' : ''} d-flex flex-fill flex-column overflow-auto mb-1 px-24 border-top border-color`} ref={scrollableRef}>
                    <div className='w-100 d-flex flex-fill flex-column'
                        onDrop={handleDrop}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                    >
                        <div className='md:w-75 w-100 border-bottom border-color pt-20 pb-12 d-flex flex-lg-row flex-column align-item-md-center justify-content-between sticky-top bg-white'>

                            <h1 className='fz22 black fw-po-semi inter mb-0'>Media ({previousFile.length + documents.length})</h1>
                            <div className='d-inline-flex align-items-center gap-x-20 mt-lg-0 mt-3 uplaodyunormalgrop'>
                                {(previousFile.length > 0 || documents.length > 0) &&
                                    <button className={`${style.upytbbtn} inter`} onClick={() => setShowModal(true)}>
                                        <YtbIcon />Upload From YouTube
                                    </button>}
                                <input type="file" className='d-none' id='upload-media-more' multiple onChange={handleOnChange} />
                                {(previousFile.length > 0 || documents.length > 0) &&
                                    <>
                                        {!uploading && <button onClick={() => {
                                            document.getElementById('upload-media-more').click();
                                        }} className='pr-btn px-16 fz14 inter border-0 d-inline-flex align-items-center gap-4 fw-po-medium'><PlushIcon />Upload More</button>}
                                        {uploading &&
                                            <Oval
                                                height={30}
                                                width={30}
                                                color="#0062ff"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel='oval-loading'
                                                secondaryColor="#0062ff"
                                                strokeWidth={4}
                                                strokeWidthSecondary={4}
                                            />
                                        }

                                    </>
                                }
                            </div>
                        </div>
                        {previousFile.length > 0 && <div className='md:w-75 w-100 border-bottom border-color pt-20 pb-10 '>
                            <h1 className='fz18 black fw-po-bold inter mb-0'>Gallery ({previousFile.length})</h1>

                        </div>}
                        {(previousFile.length === 0 && documents.length === 0) &&
                            <>
                                {!uploading && <div className='w-100 d-flex flex-fill h-100  pt-20 pb-20'
                                // onDrop={handleDrop}
                                // onDragLeave={handleDrag}
                                // onDragOver={handleDrag}
                                // onClick={() => {
                                //     document.getElementById('upload-media-more').click();
                                // }}
                                >
                                    <div className={`${style.drag_dropmedia} ${dragging ? 'drag-active' : 'd-flex h-100 flex-fill'}`}>

                                        <div className='text-center flex flex-col items-center'>
                                            <UploadIcon className='mb-24' style={{ height: '80px' }} />
                                            <h1 className='fz20 black-dark-700 mb-12 inter fw-po-medium'>Drag & Drop Or Upload Multiple Files</h1>
                                            <p className='fz16 black-dark-600 mb-0 inter'>
                                                Supported: Images, Videos, Documents, and PDFs
                                            </p>
                                            <input type="file" className='d-none' id='upload-media-more' multiple onChange={handleOnChange} />
                                            <button
                                                className={`${style.mediauploadbtn} pr-btn mt-32 inter`}
                                                onClick={() => {
                                                    document.getElementById('upload-media-more').click();
                                                }}
                                            >
                                                <PlushIcon />Upload
                                            </button>
                                        </div>
                                        <div className={`${style.dragsecure}`}><SecureIcon /> Data Secured by Propacity</div>
                                        <div className={`${style.uplaodytbtext}`}>
                                            <button className='border border-primary p-2 rounded-lg underline-offset-0 hover:bg-primary hover:text-white' onClick={() => setShowModal(true)}>Upload From YouTube</button>
                                        </div>
                                    </div>

                                </div>}
                                {
                                    uploading && <div className='w-100 d-flex flex-fill h-100  pt-20 pb-20 justify-content-center align-items-center'>
                                        <Oval
                                            height={30}
                                            width={30}
                                            color="#0062FF"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#0062FF"
                                            strokeWidth={2}
                                            strokeWidthSecondary={2}
                                        />
                                    </div>
                                }
                                {/* currently its hidden and need to be deleted */}
                                <div className={`${style.mediauploadtable} mt-32 d-none`}>
                                    <div className='d-flex align-items-center justify-content-between flex-md-row flex-md:nowrap flex-wrap'>
                                        <h1 className='fz24 black fw-po-semi inter mb-0'>Media Upload</h1>
                                        <div className={`${style.mediauplaodtableheading} d-flex justify-content-end mt-md-0 mt-3`}>
                                            <button onClick={() => {
                                                setUploadedFiles([]);
                                            }} className='outline-btn'>Clear all</button>
                                            <button onClick={() => {
                                                handleSubmit('save');
                                            }} className='pr-btn ml-8 px-16'>Finish</button>
                                        </div>
                                    </div>
                                    <div className={`${style.overflowcontent} d-flex flex-fill flex-col overflow-auto mb-1  pt-20`}>
                                        <div className='w-100'>
                                            <div className={`${style.uploadmediatable} uploadmediatable h-auto def-table pb-0 pr-0 overflow-auto`}>
                                                <table className='table mb-0'>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>File Name</th>
                                                            <th>Upload</th>
                                                            <th>Media Name</th>
                                                            <th>File Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            uploadedFiles.map((file, index) => (
                                                                <tr className='text-left' key={`${file.name}_${index}`}>
                                                                    <td  >{index + 1}</td>
                                                                    <td  >
                                                                        {file.fileName.length > 32 ?
                                                                            <OverlayTrigger
                                                                                placement="bottom"
                                                                                overlay={
                                                                                    <Tooltip id="button-tooltip" >
                                                                                        <div className='info-msg'>
                                                                                            {/* <h4>Total Leads</h4> */}
                                                                                            <p className='text-capitalize'>{file.fileName}</p>
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <div className='three-dots'>{file.fileName}</div>
                                                                            </OverlayTrigger>
                                                                            :
                                                                            <div>{file.fileName}</div>
                                                                        }
                                                                    </td>
                                                                    <td  >
                                                                        {file.status === 'uploading' &&
                                                                            <button>
                                                                                <Oval
                                                                                    height={30}
                                                                                    width={30}
                                                                                    color="#0062FF"
                                                                                    wrapperStyle={{}}
                                                                                    wrapperClass=""
                                                                                    visible={true}
                                                                                    ariaLabel='oval-loading'
                                                                                    secondaryColor="#0062FF"
                                                                                    strokeWidth={2}
                                                                                    strokeWidthSecondary={2}
                                                                                />
                                                                            </button>}
                                                                        {file.status === 'success' && <button className={`${style.sucessbtn}`}>Success</button>}
                                                                        {/* <div className={`${style.failbtn}`}>Failed</div> */}
                                                                        {/* <button className='text-red bg-red/40 rounded py-1 px-2 font-sm font-medium'>Failed</button> */}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className='form-control text-input'
                                                                            value={file.mediaName}
                                                                            onChange={(e) => handleChangeMediaName(e, index)}
                                                                        />
                                                                    </td>
                                                                    <td  >{file.type}</td>

                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='w-100 mt-34'>
                                                <div className={`${style.addpaymentplan} mediauploadmore bg-white`}>
                                                    <input type="file" className='d-none' id='upload-media-more' multiple onChange={handleOnChange} />
                                                    <button
                                                        className='pr-btn'
                                                        onClick={() => {
                                                            document.getElementById('upload-media-more').click();
                                                        }}
                                                    ><PlushIcon />Upload More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {previousFile.length > 0 && <div className=' w-100 mt-32'>
                            <div className={`${style.basic_form} ${style.mediafileupload}  mediafileupload`}>
                                {
                                    previousFile.map((file, index) => (
                                        <div key={`${file.uuid}_${index}`}>
                                            {<label className={`${style.setthumbnail} ${((!['mp4', 'mp3', 'mkv'].includes(file.file_name.split('.').pop())) && file.type !== 'youtube_video') ? '' : 'opacity-0'}`}>
                                                Set As Project Thumbnail
                                                <input
                                                    type="radio"
                                                    name='thumbnail'
                                                    id=""
                                                    onChange={() => {
                                                        const newList = [...previousFile];
                                                        const coverIndex = newList.findIndex(item => item.is_cover);
                                                        if (coverIndex > -1 && coverIndex !== index) {
                                                            newList[coverIndex].is_cover = false
                                                        }
                                                        newList[index].is_cover = true;
                                                        setPreviousFile([...newList]);
                                                    }}
                                                    checked={file?.is_cover}
                                                />
                                            </label>}


                                            <div className={`${style.upload} ${style.previewupload}`}>

                                                {['mp4', 'mp3', 'mkv'].includes(file.file_name.split('.').pop()) && <img src={videoimg} alt="" className=' w-100 h-100' />}
                                                {['jpg', 'png', 'gif', 'svg', 'jpeg'].includes(file.file_name.split('.').pop()) && <img src={file.url} alt="" className=' w-100 h-100' style={{ objectFit: 'cover' }} />}
                                                {file.type === 'youtube_video' &&
                                                    // <img src={youtubeIcone} alt="" className=' w-100 h-100' style={{ background: 'white' }} />
                                                    <iframe className=' w-100 h-100' src={`${getYoutubeVideoId(file.url)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                }
                                                <input type="file" className='d-none' id={`upload-media-${index}`} onChange={handleOnChangeUpload} />
                                                <div className={`${style.hoverbox}`}>
                                                    {file.type !== 'youtube_video' && <button
                                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                        onClick={() => {
                                                            setSelectedIndex(index);
                                                            document.getElementById(`upload-media-${index}`).click();
                                                        }}
                                                    ><PluswhiteIcon />Replace</button>}
                                                    {/* <button
                                                        className={`${style.delbtn} inter border-0 bg-white w-auto black-dark-700 fw-po-medium fz14 px-12`}

                                                    >View</button> */}
                                                    <button
                                                        className={`${style.delbtn} inter border-0 bg-white`}
                                                        onClick={() => {
                                                            let newFiles = [...previousFile];
                                                            newFiles.splice(index, 1);
                                                            setPreviousFile(newFiles);
                                                        }}
                                                    ><TrashIcon /></button>
                                                    {file.type !== 'youtube_video' && <button
                                                        onClick={() => {
                                                            const link = document.createElement("a");
                                                            link.href = file.url;
                                                            link.download = 'media';
                                                            link.click();
                                                        }}
                                                        className={`${style.downloadbtn} inter border-0 bg-white`}><DownloadIcon /></button>}
                                                </div>
                                            </div>
                                            <div className='mt-8'>
                                                <CreatableSelect
                                                    placeholder='Search & Select Gallery'
                                                    onCreateOption={(value) => {
                                                        setMediaNameList([...mediaNameList, { label: value, value: value }])
                                                        const newList = [...previousFile];
                                                        newList[index].media_name = value;
                                                        setPreviousFile([...newList]);
                                                    }}
                                                    onChange={(value) => {
                                                        const newList = [...previousFile];
                                                        newList[index].media_name = value.value;
                                                        setPreviousFile([...newList]);
                                                    }}
                                                    value={file.media_name ? { label: file.media_name, value: file.media_name } : ''}
                                                    options={mediaNameList}
                                                // error={errors?.state?.message ? 'Please select State' : ''}
                                                />
                                            </div>
                                            {/* {file.type !== 'application/pdf' && file.type !== 'video/mp4' && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                                        </div>
                                    ))}

                            </div>

                        </div>
                        }


                        {documents.length > 0 && <>
                            <div className='w-100'>
                                <div className='md:w-75 w-100 border-bottom border-color pb-10 '>
                                    <h1 className='fz18 black fw-po-bold inter mb-0'>Documents ({documents.length})</h1>
                                </div>
                            </div>
                            <div>
                                <div className=' w-100 mt-32 mb-5'>
                                    <div className={`${style.basic_form} ${style.mediafileupload}  mediafileupload`}>
                                        {
                                            documents.map((file, index) => (
                                                <div key={`${file.uuid}_${index}`}>
                                                    <div className={`${style.upload} ${style.previewupload}`}>
                                                        {['ppt', 'pptx'].includes(file.file_name.split('.').pop()) && <img src={pptimg} alt="" className=' w-100 h-100' />}
                                                        {['doc', 'docx'].includes(file.file_name.split('.').pop()) && <img src={docImg} alt="" className=' w-100 h-100' />}
                                                        {['xls', 'xlsx', 'csv'].includes(file.file_name.split('.').pop()) && <img src={sheetImg} alt="" className=' w-100 h-100' />}
                                                        {['pdf', 'pdfx'].includes(file.file_name.split('.').pop()) && <img src={pdfimg} alt="" className=' w-100 h-100' />}

                                                        <input type="file" className='d-none' id={`upload-media-${index}`} onChange={handleOnChangeUpload} />

                                                        <div className={`${style.hoverbox}`}>
                                                            <button
                                                                className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                                onClick={() => {
                                                                    setSelectedMedia(file);
                                                                    document.getElementById(`upload-media-${index}`).click();
                                                                }}
                                                            ><PluswhiteIcon />Replace</button>
                                                            {/* <button
                                                        className={`${style.delbtn} inter border-0 bg-white w-auto black-dark-700 fw-po-medium fz14 px-12`}
                                                    >View</button> */}
                                                            <button
                                                                className={`${style.delbtn} inter border-0 bg-white`}
                                                                onClick={() => {
                                                                    let newFiles = [...documents];
                                                                    newFiles.splice(index, 1);
                                                                    setDocuments(newFiles);
                                                                }}
                                                            ><TrashIcon /></button>
                                                            <button
                                                                onClick={() => {
                                                                    const link = document.createElement("a");
                                                                    link.href = file.url;
                                                                    link.download = 'media';
                                                                    link.click();
                                                                }}
                                                                className={`${style.downloadbtn} inter border-0 bg-white`}><DownloadIcon /></button>
                                                        </div>
                                                    </div>

                                                    {/* {file.type !== 'application/pdf' && file.type !== 'video/mp4' && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                                                    <div className='mt-8'>
                                                        <CreatableSelect
                                                            placeholder='Search & Select Documents'
                                                            onCreateOption={(value) => {
                                                                setDocumentNamesList([...documentNamesList, { label: value, value: value }])
                                                                const newList = [...documents];
                                                                newList[index].media_name = value;
                                                                setDocuments([...newList]);
                                                            }}
                                                            onChange={(value) => {
                                                                const newList = [...documents];
                                                                newList[index].media_name = value.value;
                                                                setDocuments([...newList]);
                                                            }}
                                                            value={file.media_name ? { label: file.media_name, value: file.media_name } : ''}
                                                            options={documentNamesList}
                                                        // error={errors?.state?.message ? 'Please select State' : ''}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>

                            </div></>}
                    </div>
                </div>
                <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                    <button
                        className={`${style.cancelbtn}`}
                        onClick={() => {
                            history.push(`/projects/create-projects/basic-details/${uuid}?pt=${property}&tt=${transaction}`);
                        }}
                    ><LeftIcon />Previous</button>
                    {(previousFile.length > 0 || documents.length > 0) &&
                        <button
                            type='button'
                            onClick={handleSubmit}
                            className={`${style.savebtn}`}
                            disabled={uploading}
                        > Save & Next</button>}
                    {(previousFile.length === 0 && documents.length === 0) &&
                        <button
                            type='button'
                            onClick={() => {
                                history.push(`/projects/create-projects/additional-info/${uuid}?pt=${property}&tt=${transaction}`);
                            }}
                            className={`${style.savebtn}`}
                            disabled={uploading}
                        > Next</button>}
                </div>
            </div>
            {showModal && <YoutubeModal show={showModal} closeModal={() => setShowModal(false)} style={style} handleUploadYoutubeVideo={handleUploadYoutubeVideo} />}
        </div >
    )
}

export default MediaUpload;
