import { toast } from 'react-toastify';
import React, { useContext, useEffect, useState } from 'react';
import { createSiteVisitCall, getLeadByUuid, reassignLeads } from '../../../services/private/leads.service';
import DateTimePicker from '../../InputGroup/DateTime';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import InputSelect from '../../InputGroup/InputSelect';
import { validityUnit } from '../../../helpers/enums';
import { checkIsSomeItemAvailableInList } from '../../../helpers/helpers';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../services/private/company.service';
import InputText from '../../InputGroup/InputText';
import { ReactComponent as PlusPrimaryIcon } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',

        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


export default function CreateForm({
    reloadSiteVists,
    projectsOptions,
    intentOptions,
    leadInfoData,
    leadAccess,
    userProfile,
    allowedPermissions
}) {

    const [scheduleDate, setScheduleDate] = useState();
    const [remarks, setRemarks] = useState("");
    const [followupScheduleDate, setFollowupScheduleDate] = useState();
    const [projects, setProjects] = useState([])
    const [svType, setSvType] = useState('offline')
    const [error, setError] = useState(false)
    const [selectedIntent, setSelectedIntent] = useState((leadInfoData.intent && leadInfoData.intent?.display_name != 'New Lead') ? {
        label: leadInfoData.intent?.display_name,
        value: leadInfoData.intent?.id
    } : '');
    const [followupErrorMsg, setFollowupErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([{}]);
    const [allUsers, setAllUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState('');
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [statusShareUsers, setStatusShareUsers] = useState([]);

    const params = useParams();

    const getReminderMessage = () => {
        // Remind 10 minutes before
        if (followupScheduleDate) {
            return <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-16'>We will remind you <span className='black fw-po-medium'>15 mins</span>  prior to <span className='black fw-po-medium'>{moment(followupScheduleDate).format('h:mm a, Do MMMM YYYY')}</span>.</p>

            // <p className="text-center px-16 fz14 mt-16 black-dark-700 mb-0">
            //     We will remind you 15 mins before <span className='black fw-po-medium'>{moment(followupScheduleDate).subtract(15, 'minutes').format('LT')}</span> on <span className='black fw-po-medium'>{moment(followupScheduleDate).format('LL')}</span>.
            // </p>
        }
    }

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.uuid
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(params?.id);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: leadInfoData?.lead?.status?.id });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                setAssignedTo(res.data.data?.user_uuid)
            } else {
                setAssignedTo(leadInfoData?.lead?.assignedTo?.uuid)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: leadInfoData?.lead?.status?.id });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers])

    const handleReassignModalSubmit = () => {
        reassignLeads([params.id], assignedTo, '').then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned")
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleSubmitLeadShare = async () => {
        let payload = users.filter(item => item.user_id);
        let count = 0
        payload.forEach(item => {
            if (!item.user_id || !item.validity || !item.access || !item.validity_unit) {
                count++
            }
        })
        if (count > 0) {
            toast.error('Please fill all values');
            return;
        }

        let res = {}

        payload = payload.map(item => ({ ...item, expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString() }))
        res = await saveLeadAccess(params.id, { status_id: null, users: payload })

        if (res.data.status === 200) {
            toast.success(res.data.message);
            reloadSiteVists();
            window.location.reload();
        }
    }

    const saveSiteVisit = async () => {
        if (!scheduleDate || !followupScheduleDate || projects.length === 0 || !selectedIntent.value) {
            setError(true);
            return;
        } else {
            setError(false)
        }

        if (moment(followupScheduleDate) >= moment(scheduleDate)) {
            setFollowupErrorMsg('Followup Date should be before SV Scheduled Date');
            setError(true);
            return;
        } else {
            setFollowupErrorMsg('');
            setError(false);
        }

        const projectId = projects.map(proj => proj.value)
        setLoading(true);
        await createSiteVisitCall(params.id, {
            remarks,
            scheduleDate,
            projectId,
            followupScheduleDate,
            intentId: selectedIntent.value
        }).then(res => {
            if (res.status === 200) {
                toast.success("Site Visit Created");
                if (userProfile?.allowed_modules?.includes('lead_sharing')) {
                    handleSubmitLeadShare();
                    if (assignedTo) {
                        handleReassignModalSubmit();
                    }
                } else {
                    reloadSiteVists();
                    window.location.reload();
                }

            }
        }).catch(err => {
            console.log(err, 'err')
        })
        setLoading(false);
    }

    useEffect(() => {
        if (userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccess();
        }
        if (leadInfoData?.lead?.status?.id && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccessAssignedToByStatus();
            getShareLeadAccessUsingStatusId();
        }
    }, [leadInfoData, userProfile]);

    useEffect(() => {
        reloadSiteVists();
        getAllUsers();
    }, []);

    return (
        <>

            {/* <form action=""> */}
            <div className='form-group'>

                <InputSelect
                    index={1}
                    name="project"
                    label='Projects'
                    isMulti={true}
                    options={projectsOptions}
                    placeholder="Select Project"
                    styles={customStyles}
                    value={projects}
                    error={error && !(projects?.length) && 'Project is required'}
                    onChange={(e) => {
                        setProjects(e)
                    }}
                />
            </div>
            <div className='form-group'>
                <label>Type of Site Visit</label>
                <div>
                    <label className='mr-4'>
                        <input
                            type='radio'
                            name='sitevisit'
                            value='offline'
                            checked={svType === 'offline'}
                            onChange={() => {
                                setSvType('offile')
                            }}
                        /> Onsite
                    </label>
                    <label>
                        <input
                            type='radio'
                            name='sitevisit'
                            value='online'
                            checked={svType === 'online'}
                            onChange={() => {
                                setSvType('online')
                            }}
                        /> Video Call
                    </label>
                </div>
                {/* {values['siteVisitType'] === 'online' && <InputText
                                                    index={8}
                                                    placeholder="Paste Online Meet Link (Optional)"
                                                    value={values['meetLink']}
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "meetLink",
                                                                value: e.target.value
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                />} */}
            </div>
            <div className="form-group date-time">

                <div className="mb-16 align-items-center">
                    <DateTimePicker
                        onValueChange={(e) => setScheduleDate(e)}
                        label="Select Site Visit Date & Time"
                        name="scheduleDate"
                        error={error && !scheduleDate && 'Schedule date is required'}
                    />
                </div>
                {/* {error && !scheduleDate && <p className="text-danger">Schedule date is required.</p>} */}
            </div>
            <div className='mb-16'>
                <InputSelect
                    index={2}
                    label="Intent"
                    name="Intent"
                    styles={customStyles}
                    value={selectedIntent}
                    options={intentOptions.filter(intent => intent.label != 'New Lead')}
                    placeholder="Select Intent"
                    onChange={(e) => { setSelectedIntent(e) }}
                    error={error && !selectedIntent.value && 'Intent is required'}
                />
            </div>
            <div className="form-group">
                <div className='flex items-center justify-between !mt-4 !mb-2'>
                    <label>Comments (optional)</label>
                    <button
                        type='button'
                        className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                        onClick={() => {
                            // console.log('hello', re)
                            setRemarks('');
                        }}
                    >Clear <CloseIcon className='h-3 w-3' /></button>
                </div>

                <textarea
                    className="form-control mb-16"
                    placeholder="Enter comments"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}>
                </textarea>
                {/* {error && !remarks && <p className="text-danger">Comments is required.</p>} */}

                {/* Followup Date */}
            </div>
            <div className="form-group ml-0">
                <div className="row  align-items-center">
                    <div className="col-md-12 flex items-center">
                        {/* <h3 className="fz16 po mb-0"></h3> */}
                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M15.7 4V3C15.7 2.44772 16.1477 2 16.7 2C17.2523 2 17.7 2.44772 17.7 3V4.00491C19.8162 4.10912 21.5 5.85793 21.5 8V17C21.5 19.2091 19.7091 21 17.5 21H7.5C5.29086 21 3.5 19.2091 3.5 17V8C3.5 5.79086 5.29086 4 7.5 4H7.7V3C7.7 2.44772 8.14772 2 8.7 2C9.25228 2 9.7 2.44772 9.7 3V4H11.7V3C11.7 2.44772 12.1477 2 12.7 2C13.2523 2 13.7 2.44772 13.7 3V4H15.7ZM15.7 6H13.7C13.7 6.55228 13.2523 7 12.7 7C12.1477 7 11.7 6.55228 11.7 6H9.7C9.7 6.55228 9.25229 7 8.7 7C8.14772 7 7.7 6.55228 7.7 6H7.5C6.39543 6 5.5 6.89543 5.5 8V17C5.5 18.1046 6.39543 19 7.5 19H17.5C18.6046 19 19.5 18.1046 19.5 17V8C19.5 6.96291 18.7106 6.11019 17.7 6.00987C17.6947 6.55761 17.249 7 16.7 7C16.1477 7 15.7 6.55228 15.7 6ZM9.5 12C8.94772 12 8.5 11.5523 8.5 11C8.5 10.4477 8.94772 10 9.5 10H16.5C17.0523 10 17.5 10.4477 17.5 11C17.5 11.5523 17.0523 12 16.5 12H9.5ZM9.5 16C8.94772 16 8.5 15.5523 8.5 15C8.5 14.4477 8.94772 14 9.5 14H14.5C15.0523 14 15.5 14.4477 15.5 15C15.5 15.5523 15.0523 16 14.5 16H9.5Z" fill="#3DD598"></path></svg>
                        Followup <span></span><br />

                    </div>
                    <div className="col-md-12 mt-16">
                        <DateTimePicker
                            onValueChange={(e) => setFollowupScheduleDate(e)}
                            label="Select Followup Date & Time"
                            name="scheduleDate"
                            error={error && (followupErrorMsg || !followupScheduleDate && 'Follow up date is required.')}
                        />
                    </div>
                </div>
                {/* {error && !followupScheduleDate && <p className="text-danger">Follow up date is required.</p>} */}


                {/* Reminder message */}
                {getReminderMessage()}
                {userProfile?.allowed_modules?.includes('lead_sharing') && checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) &&
                    <>
                        <div className='mt-3' >
                            <InputSelect
                                label={'Assigned User'}
                                placeholder={'Select User'}
                                styles={customStyles}
                                options={usersForAssigned}
                                value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                onChange={(value) => {
                                    setAssignedTo(value.value);
                                }}
                                disable={!allowedPermissions.includes('edit_lead_sharing')}
                            />
                        </div>
                        <label className='mt-3'>Shared With</label>
                        {
                            users.map((item, index) => (
                                <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                    <div className='flex justify-between items-center form-group'>
                                        <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                        {allowedPermissions.includes('edit_lead_sharing') && <button
                                            type='button'
                                            className='!text-primary text-base font-medium'
                                            onClick={() => {
                                                let newUsers = [...users];
                                                newUsers.splice(index, 1);
                                                if (users.length === 1) {
                                                    setUsers([{}])
                                                } else {
                                                    setUsers([...newUsers])
                                                }

                                            }}
                                        >Remove</button>}
                                    </div>
                                    <div className='form-group'>
                                        <InputSelect
                                            placeholder={'Select User'}
                                            options={allUsers}
                                            styles={customStyles}
                                            value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                            onChange={(value) => {
                                                let newUsers = [...users];
                                                newUsers[index].user_id = value.value;
                                                // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                // setAllUsers(filteredUser)
                                                setUsers([...newUsers]);
                                            }}
                                            disable={!allowedPermissions.includes('edit_lead_sharing')}
                                        />
                                    </div>
                                    <div className='mt-3 flex'>
                                        <label htmlFor={`edit_${index}`} className='mr-4 flex items-center'>
                                            <input
                                                type="radio"
                                                className='mr-2'
                                                name={`permission_${index}`}
                                                id={`edit_${index}`}
                                                value='edit'
                                                checked={item.access === 'edit'}
                                                onChange={() => {
                                                    let newUsers = [...users];
                                                    newUsers[index].access = 'edit'
                                                    setUsers(newUsers);
                                                }}
                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                            />
                                            Edit/View Access
                                        </label>
                                        <label htmlFor={`view_${index}`} className='mr-4 flex items-center'>
                                            <input
                                                type="radio"
                                                className='mr-2'
                                                name={`permission_${index}`}
                                                id={`view_${index}`}
                                                value='view'
                                                checked={item.access === 'view'}
                                                onChange={() => {
                                                    let newUsers = [...users];
                                                    newUsers[index].access = 'view'
                                                    setUsers(newUsers);
                                                }}
                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                            />
                                            View Only
                                        </label>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="">Validity</label>
                                        <div className='grid grid-cols-2 gap-2'>
                                            <InputText
                                                placeholder={'e.g. 30'}
                                                value={item.validity}
                                                onChange={(e) => {
                                                    let newUsers = [...users];
                                                    newUsers[index].validity = e.target.value
                                                    setUsers(newUsers);
                                                }}
                                                disable={!allowedPermissions.includes('edit_lead_sharing')}
                                            />
                                            <InputSelect
                                                inputclass={'m-0'}
                                                styles={customStyles}
                                                placeholder={'Select Time'}
                                                value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                options={validityUnit}
                                                onChange={(value) => {
                                                    let newUsers = [...users];
                                                    newUsers[index].validity_unit = value.value;
                                                    setUsers(newUsers);
                                                }}
                                                disable={!allowedPermissions.includes('edit_lead_sharing')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <button
                            type='button'
                            className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold'
                            onClick={() => setUsers([...users, {}])}
                            disable={!allowedPermissions.includes('edit_lead_sharing')}
                        >
                            <PlusPrimaryIcon />Add another
                        </button>
                    </>}
                <button className="pr-btn text-center w-100 py-8 mt-16" onClick={saveSiteVisit} disabled={(loading || (userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view'))}>
                    Create New Site Visit
                </button>
            </div >
        </>
    )
}