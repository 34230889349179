import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom';
import style from '../../modules/Settings/Projects/style.module.css';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import ProjectHeader from './project-header'
import TeamCard from '../../modules/Settings/User&Teams/Teams/TeamCard';
import AddTeamInProject from './Modal/AddTeamInProject';
import { deleteTeamFromProject, getProjectTeams } from '../../services/public/public.service';
import AddTeamModal from '../Modals/TeamModal/AddTeamModal';
import { toast } from 'react-toastify';
import { ReactComponent as ResultSearchIcon } from '../../assets/icons/searchicon.svg';

const Team = () => {
    const [showAddTeamModal, setShowAddTeamModal] = useState(false);
    const [projectTeams, setProjectTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [showModal, setShowModal] = useState(false);

    const history = useHistory();
    const { uuid } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const propertyType = searchParams.get('pt');
    const transactionType = searchParams.get('tt');

    const getTeams = async () => {
        const res = await getProjectTeams(uuid);
        if (res.data.status === 200) {
            setProjectTeams(res.data.data);
        }
    }

    useEffect(() => {
        if (uuid) {
            getTeams()
        }
    }, [uuid]);

    const handleRemoveTeam = async (id) => {
        const payload = {
            project_uuid: uuid,
            team_id: id
        }
        const res = await deleteTeamFromProject(payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getTeams();
        }
    }

    return (
        <div className={`${style.creatproject}`}>
            <ProjectHeader style={style} />
            <div className={`${style.creatproject} overflow-auto border-top border-color`} id='team'>
                <div className={`${style.Location} d-flex flex-fill flex-col mb-1 pl-24 pt-20`}>
                    <div className='w-100 max-w-[1028px]'>
                        <div className='flex justify-between border-b border-grayLight !pb-2'>
                            <h1 className='fz18 black font-medium inter mb4'>Project Details </h1>
                            <button
                                className='pr-btn pr-btn inter inline-flex gap-x-1'
                                onClick={() => setShowAddTeamModal(true)}
                            >
                                <PlushIcon />Add/Create Team
                            </button>
                        </div>

                        {projectTeams?.length > 0 ? <div className='grid grid-cols-3 gap-x-4 gap-y-5 !pt-4'>
                            {projectTeams.map((team, index) => (
                                <TeamCard
                                    key={`${team.uuid}_${index}`}
                                    team={team}
                                    setShowAddTeamModal={setShowModal}
                                    setSelectedTeam={setSelectedTeam}
                                    handleRemove={handleRemoveTeam}
                                />
                            ))
                            }
                        </div> :
                            <div style={{ height: '60vh' }}>
                                <div className='flex justify-center flex-column items-center h-100'>
                                    <figure><ResultSearchIcon /></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Teams Added</h2>
                                    <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add teams to see them here</p>

                                </div>
                            </div>
                        }

                    </div>
                </div>

            </div>
            <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                <button
                    type='button'
                    className={`${style.cancelbtn}`}
                    onClick={() => {
                        history.push(`/projects/create-projects/team-info/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                    }}
                >
                    <LeftIcon />Previous </button>
                <button form='additioninfo' onClick={() => {
                    history.push(`/projects/project-manager`);
                }} className={`${style.savebtn}`}>
                    Save & Next
                </button>
            </div>

            {
                showAddTeamModal &&
                <AddTeamInProject
                    uuid={uuid}
                    show={showAddTeamModal}
                    getTeams={getTeams}
                    projectTeams={projectTeams}
                    closeModal={() => setShowAddTeamModal(false)}
                />
            }
            {showModal &&
                <AddTeamModal
                    show={showModal}
                    closeModal={() => {
                        setShowModal(false);
                        setSelectedTeam({});
                    }}
                    selectedTeam={selectedTeam}
                    getAll={getTeams}
                />}
        </div>
    )
}

export default Team
