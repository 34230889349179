import React, { useEffect, useState } from 'react';
import Icontwo from '../../../assets/pc2.png';
import IconThree from '../../../assets/pc3.png';
import Iconone from '../../../assets/pc1.png';
import { ReactComponent as PropacityLogo } from '../../../assets/propacityplush.svg';
import { ReactComponent as LinkLogo } from '../../../assets/external-link.svg';
import { ReactComponent as SucessIocn } from '../../../assets/sucess.svg';
import backgroundImage from '../../../assets/background.jpg';
import { isMobile } from 'react-device-detect';
import AppDownloadModal from '../../Signup/AppDownloadModal';
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {

    const [appDownloadModal, setAppDownloadModal] = useState(false);

    // useEffect(() => {
    //     console.log(isMobile, 'ismobile')
    // }, [isMobile])
    return (
        <div className='flex flex-fill items-center justify-center h-full overflow-y-auto overflow-x-hidden xl:!py-0 !py-10 ' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className={`bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-14 pb-8 !px-6 grow-0 shrink-0 w-11/12 shadow-shadowcard mt-10  `}>
                <div className=''>
                    <div className='flex justify-center '>
                        <figure className='!mb-5'><SucessIocn className='h-16 w-16' /></figure>
                    </div>
                    <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>

                    <div className='!py-4 !px-5 !border border-black600 rounded-xl'>
                        <h2 className='md:text-xl text-base font-semibold text-textblack !mb-4'>🥳 Propacity Premium Unlocked! </h2>

                        <div className='flex flex-col md:gap-y-3 !gap-y-4'>
                            <div className='flex items-center !gap-4'>
                                <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={Iconone} alt="" /></div>
                                <p className='m-0 text-black700 text-sm font-medium'>Unlock unlimited live and pre-recorded sessions, courses</p>
                            </div>
                            <div className='flex items-center !gap-4'>
                                <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={Icontwo} alt="" /></div>
                                <p className='m-0 text-black700 text-sm font-medium'>Engage with industry experts to get personalised insights and guidance.</p>
                            </div>
                            <div className='flex items-center !gap-4'>
                                <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={IconThree} alt="" /></div>
                                <p className='m-0 text-black700 text-sm font-medium'>Cancel subscription at anytime</p>
                            </div>
                        </div>
                    </div>
                    {!isMobile && <div className='!mt-6'>
                        <Link to='/learning-center' className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-3 flex justify-center'>Open Propacity Web <span className='ml-2'><LinkLogo /></span></Link>
                    </div>}
                    {/* {!isMobile && <div className='!mt-6'>
                        <Link href='propacityPlus://propacity.com/learningCentre?reload=true' className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-3 flex justify-center'>Open Propacity Web <span className='ml-2'><LinkLogo /></span></Link>
                    </div>} */}
                    {isMobile && <div className='!mt-6'>
                        <a href='https://play.google.com/store/apps/details?id=com.dev_propacity.propacity_broker' className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-3 flex justify-center'>Open Mobile App <span className='ml-2'><LinkLogo /></span></a>
                    </div>}
                </div>
            </div>

            {appDownloadModal && <AppDownloadModal show={appDownloadModal} closeModal={() => setAppDownloadModal(false)} />}

            <div className='md:flex hidden items-center justify-center md:flex-row flex-col gap-3 fixed bottom-0 left-0 right-0 z-10 bg-textblack text-white !py-2'>
                <p className='m-0 text-sm font-medium '>Kickstart your Real Estate journey today!</p>
                <button onClick={() => setAppDownloadModal(true)} className='text-sm shadow-white-btn-shadow bg-whtie font-medium !rounded-lg !border !border-black400 bg-white !py-2 text-textblack px-2.5'>Download Mobile App Now</button>

            </div>

        </div>)
}

export default PaymentSuccess;

