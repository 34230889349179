import React, { useCallback, useContext, useEffect, useState } from 'react'
import Guides from '../../components/learning-center/tabs/guides';
import Shorts from '../../components/learning-center/tabs/shorts';
import Workshops from '../../components/learning-center/tabs/workshops';
import Category from '../../components/learning-center/Category/category';
import { getAllCourses, getAllGuides, getAllLiveWorkshops, getCategory, insertCallRequested } from '../../services/public/public.service';
import './LearningCenter.css';
import SearchIcon from '../../assets/icons/search.svg'
import Courses from '../../components/learning-center/tabs/Courses';
import style from '../../components/learning-center/CourseDetails/style.module.css'
import moment from 'moment';
import Consumer from '../../helpers/context';
import strip from '../../assets/icons/subscriptionStrip.png';
import PaymentPlanModal from '../Settings/Modal/PaymentPlanModal';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as LiveActive } from '../../assets/icons/live-active.svg';
import { ReactComponent as LiveInactive } from '../../assets/icons/live-inactive.svg';
import { ReactComponent as CallingIcon } from '../../assets/icons/calling.svg';
import { ReactComponent as CallRingIcon } from '../../assets/icons/Phone_call_ring.svg'
import AppDownloadModal from '../Signup/AppDownloadModal';
import BookCallModal from '../Signup/Modal/BookCallModal';
import SuccessBookingModal from '../Signup/Modal/SuccessBookingModal';
import PaymentSuccessModal from '../Signup/Modal/PaymentSuccessModal';
import FreemiumSuccessLoginModal from '../../components/Modals/FreemiumSuccessLoginModal';
import FreemiumContentModal from '../../components/Modals/FreemiumContentModal';

const whatsappText = `Hey,

Thank you for booking a call with us at Propacity Plus.🎉

Our customer success executive will reach out to you shortly within the next few hours.

We look forward to assisting you with your real estate business! 🚀💪

- Team Propacity Plus`

const LearningCenter = () => {

    const [workshops, setWorkshops] = useState([]);
    const [allWorkshops, setAllWorkshop] = useState([]);
    const [recordings, setRecordings] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState('courses');
    const [guides, setGuides] = useState([]);
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [appDownloadModal, setAppDownloadModal] = useState(false);
    const [showBookCallModal, setShowBookCallModal] = useState(false);
    const [showBookingSuccessModal, setShowBookingSuccessModal] = useState(false);
    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(true);
    const [showFreemiumUser, setShowFreemiumUser] = useState(localStorage.getItem('freemium_user'))

    const { userProfile, isSubscribed, borkerDetails } = useContext(Consumer);

    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (location.search) {
            const params = location.search.split('=');
            setActiveTab(params[1])
        } else {
            setActiveTab('courses')
        }
    }, [location.search])
    // filter live sessions and recordings from workshops
    const filterWorkshop = useCallback((workshops) => {
        let recordings = workshops.filter(el => el.status === 'COMPLETED' && (el.recording_url || el?.file_name))
        let workshop = workshops.filter(el => (!el.recording_url && !el?.file_name));
        setRecordings(recordings);
        setWorkshops(workshop);
    }, []);

    const getWorkshop = async () => {
        await getAllLiveWorkshops()
            .then((res) => {
                filterWorkshop(res.data)
                setAllWorkshop(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getAllActiveCourses = async (searchText) => {
        const res = await getAllCourses({ name: searchText });
        if (res.data.status === 200 && res.data.data.length > 0) {
            let courses = []
            res.data.data.forEach((course, index) => {
                courses[index] = { ...course }
                courses[index]['liveSession'] = course?.course_content?.find(item => {
                    return item?.content_type === 'live' && (moment(item.session_start_time).add('5:30') >= moment() || moment(item.session_end_time).add('5:30') >= moment())
                });
                if (courses[index]['liveSession']) {
                    courses[index]['liveSession'].session_start_time = moment(courses[index]['liveSession'].session_start_time).add('5:30')
                    courses[index]['liveSession'].session_end_time = moment(courses[index]['liveSession'].session_end_time).add('5:30')
                }
            })
            setCourses(courses)
        }
    }

    const getAllGuide = async (searchText) => {
        const res = await getAllGuides({ searchText })
        if (res.data.status === 200) {
            setGuides(res.data.data || []);
        }
    }

    const getAllCategory = async () => {
        const res = await getCategory();
        if (res.data.status === 200) {
            setCategories(res.data.data)
        }
    }

    useEffect(() => {
        getWorkshop();
        getAllGuide();
        getAllCategory();
        // getAllActiveCourses('');
        // if (localStorage.getItem('tab') === 'courses') {
        //     document.getElementById('courses-tab').click();
        //     localStorage.removeItem('tab')
        // }
        // const el = document.getElementById('main-container')
        // el?.classList?.add('learning-center')

        // return (() => el.classList.remove('learning-center'))
    }, []);

    useEffect(() => {
        if (searchText.length > 2 && activeTab === 'guides') {
            getAllGuide(searchText);
        }
        if (searchText.length === 0 && activeTab === 'guides') {
            getAllGuide('');
        }
        if (searchText.length > 2 && activeTab === 'courses') {
            getAllActiveCourses(searchText)
        }
        if (searchText.length === 0 && activeTab === 'courses') {
            getAllActiveCourses('')
        }
    }, [activeTab, searchText, allWorkshops]);

    const handleSearch = useCallback((e) => {
        setSearchText(e.target.value);
        const filteredData = allWorkshops.filter(item => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        filterWorkshop(filteredData);
    }, [workshops]);

    const handleClearSearch = useCallback(() => {
        setSearchText('');
        filterWorkshop(allWorkshops)
    }, [allWorkshops]);

    const handleSelectCategory = useCallback((category) => {
        const index = selectedCategories.findIndex(item => item.id === category.id);
        if (index === -1) {
            let selected = [...selectedCategories];
            selected.push(category)
            setSelectedCategories(selected)
        }
    }, [selectedCategories]);

    const handleRemoveCategory = useCallback((category) => {
        const categories = selectedCategories.filter(item => item.id != category.id);
        setSelectedCategories(categories);
    }, [selectedCategories]);

    const styles = {
        backgroundImage: `url(${strip})`,
        backgroundSize: 'cover',
        backgroundPositon: 'left center'
    };

    const handleBookCall = async () => {
        const res = await insertCallRequested();
        if (res.data.status === 200) {
            // console.log(res.data.data, 'data')
            setShowBookCallModal(false);
            setShowBookingSuccessModal(true);
        }
    }
    return (
        <div className={`${style.learingMainSection} main-section position-relative`}>
            <div className="lead-head !pr-6">
                <div className='lead-title learing-center-main mb-18 '>
                    <div className="row align-items-center">
                        <div className="col-md-5 col-sm-6 d-flex align-items-center justify-between">
                            <h1 className="mb-0 mr-2 fz24 black h-font fw-po-medium">
                                Learning Center
                            </h1>
                            <button
                                className='rounded-full mb-2 p-2 sm:hidden bg-primary '
                                onClick={() => setShowBookCallModal(true)}
                            >
                                <CallRingIcon />
                            </button>
                        </div>
                        <div className="col-md-7 col-sm-6 align-items-center justify-content-end d-flex pr-sm-4">
                            <div>

                            </div>
                            {/* <a
                                className='border rounded-full p-2 text-black flex items-center hover:bg-primary hover:text-white hover:stroke-white hover:fill-white'
                                target='_blank'
                                href={`https://wa.me/+918956808801?text=${whatsappText}`}
                            > */}
                            <button
                                className='border rounded-full p-2 text-black hidden sm:flex items-center hover:bg-primary hover:text-white hover:stroke-white hover:fill-white'
                                onClick={() => setShowBookCallModal(true)}
                            >
                                <span className='mr-2'><CallingIcon /></span>
                                Book a call
                            </button>
                            {/* </a> */}
                        </div>
                        {/* Mobile view search box */}
                        <div className="col-md-7 col-sm-6 align-items-center justify-content-end d-lg-none d-flex pr-sm-4">
                            <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                <div className="serch-icon">
                                    <img src={SearchIcon} className="" /></div>
                                <input type="text" placeholder="Search" onChange={(e) => handleSearch(e)} className="searchinput" value={searchText} />
                                {searchText &&
                                    <div className="close-icon" onClick={handleClearSearch} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                        </svg>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="video-tabs all-tabs">
                    <div className="row">
                        <div className="col-md-8">
                            <ul className="nav nav-tabs" id="video-tabs" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'courses' ? 'active' : ''}`}
                                        onClick={() => {
                                            // setActiveTab('courses')
                                            const searchParams = new URLSearchParams(location.search);
                                            searchParams.set('tab', 'courses');
                                            // Replace the current URL with the new one containing the updated query parameter
                                            history.replace({
                                                search: searchParams.toString(),
                                            });
                                            // history.replace({search: (new URLSearchParams({tab: 'courses' })).toString() });
                                        }}
                                    >
                                        Courses
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'video' ? 'active' : ''}`}
                                        onClick={() => {
                                            // setActiveTab('recording')
                                            const searchParams = new URLSearchParams(location.search);
                                            searchParams.set('tab', 'video');
                                            // Replace the current URL with the new one containing the updated query parameter
                                            history.replace({
                                                search: searchParams.toString(),
                                            });
                                        }}
                                    >
                                        Videos
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link items-center ${activeTab === 'workshops' ? 'active' : ''}`}
                                        onClick={() => {
                                            // setActiveTab('workshop')
                                            const searchParams = new URLSearchParams(location.search);
                                            searchParams.set('tab', 'workshops');
                                            // Replace the current URL with the new one containing the updated query parameter
                                            history.replace({
                                                search: searchParams.toString(),
                                            });
                                        }}
                                        style={{ display: 'flex' }}
                                    >
                                        <span className='mr-1'>
                                            {activeTab === 'workshops' ? <LiveActive /> : <LiveInactive />}
                                        </span> Sessions
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'guides' ? 'active' : ''}`}
                                        onClick={() => {
                                            // setActiveTab('guide')
                                            const searchParams = new URLSearchParams(location.search);
                                            searchParams.set('tab', 'guides');

                                            // Replace the current URL with the new one containing the updated query parameter
                                            history.replace({
                                                search: searchParams.toString(),
                                            });
                                        }
                                        }
                                    >
                                        Guides
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* Desktop search box */}
                        <div className="col-md-4 justify-content-end  d-lg-flex d-none">
                            <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                <div className="serch-icon">
                                    <img src={SearchIcon} className="" /></div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => handleSearch(e)}
                                    className="searchinput"
                                    value={searchText}
                                />
                                {searchText &&
                                    <div className="close-icon" onClick={handleClearSearch}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                        </svg>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {!isSubscribed && <div style={styles} className='w-full !py-2 flex md:flex-row flex-col items-center justify-center rounded-lg text-center gap-3 md:!mt-0 !mt-2'>
                    <span className='text-white sm:text-lg text-base font-semibold'>Unlock all videos now, upgrade to Premium 🌟</span>
                    <button
                        className='bg-white text-black text-sm font-semibold rounded-lg px-2.5 py-1 '
                        onClick={() => setShowPaymentModal(true)}
                    >Get Premium</button>
                </div>}
            </div>
            {/* {activeTab === 'courses' && <div className='mt-24'>
                <Category
                    categories={categories}
                    handleRemoveCategory={handleRemoveCategory}
                    handleSelectCategory={handleSelectCategory}
                    selectedCategories={selectedCategories}
                />
            </div>} */}
            <div className="video-cards-main mt-20">
                <div className="tab-content pt4" id="video-tabsContent">
                    <div className={`tab-pane fade ${activeTab === 'courses' ? 'show active' : ''}`} id="courses" >
                        <Courses
                            courses={courses}
                            isSubscriptionExpire={!isSubscribed}
                            setShowPaymentModal={setShowPaymentModal}
                            borkerDetails={borkerDetails}
                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'video' ? 'show active' : ''}`} id="video" >
                        {/* <Category /> */}
                        <Workshops
                            workshops={recordings}
                            isSubscriptionExpire={!isSubscribed}
                            setShowPaymentModal={setShowPaymentModal}
                            borkerDetails={borkerDetails}
                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'workshops' ? 'show active' : ''}`} id="workshops">
                        {/* <Category /> */}
                        <Workshops
                            workshops={workshops}
                            isSubscriptionExpire={!isSubscribed}
                            setShowPaymentModal={setShowPaymentModal}
                            borkerDetails={borkerDetails}
                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'guides' ? 'show active' : ''}`} id="guides" >
                        {/* <Category /> */}
                        <Guides
                            guides={guides}
                            isSubscriptionExpire={!isSubscribed}
                            setShowPaymentModal={setShowPaymentModal}
                            borkerDetails={borkerDetails}
                        />
                    </div>

                </div>
            </div>
            <div className='md:flex absolute bottom-0 w-full hidden items-center justify-center md:flex-row flex-col gap-3 z-10 bg-textblack text-white !py-2'>
                <p className='m-0 text-sm font-medium '>Kickstart your Real Estate journey today!</p>
                <button onClick={() => setAppDownloadModal(true)} className='text-sm shadow-white-btn-shadow bg-whtie font-medium !rounded-lg !border !border-black400 bg-white !py-2 text-textblack px-2.5'>Download Mobile App Now</button>
            </div>
            {showPaymentModal && <PaymentPlanModal show={showPaymentModal} closeModal={() => setShowPaymentModal(false)} paymentFrom={'modal'} />}
            {appDownloadModal && <AppDownloadModal show={appDownloadModal} closeModal={() => setAppDownloadModal(false)} />}
            {
                showBookCallModal && <BookCallModal show={showBookCallModal} closeModal={() => setShowBookCallModal(false)} handleSubmit={handleBookCall} />
            }
            {
                showBookingSuccessModal && <SuccessBookingModal show={showBookingSuccessModal} closeModal={() => setShowBookingSuccessModal(false)} />
            }

            {showFreemiumUser && <FreemiumSuccessLoginModal
                show={showFreemiumUser}
                closeModal={() => {
                    setShowFreemiumUser(false);
                    localStorage.removeItem('freemium_user')
                }}
                borkerDetails={borkerDetails}

            />}
            {
                // showPaymentSuccessModal && <FreemiumContentModal show={showPaymentSuccessModal} closeModal={() => setShowPaymentSuccessModal(false)} borkerDetails={borkerDetails} />
            }

        </div >
    )
}
export default LearningCenter;