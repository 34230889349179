import React, { Component } from 'react';
import { ACTIVITY_VIEWS } from '../Detail Sidebar/ACTIVITY_VIEWS';

export default class SiteVisitDone extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <>
            {/* Site visit done */}
            <div className="site-visit-done">
                <h2 className="fz16 po black po fw-po-medium mb-24">
                    <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.5 15C14.7091 15 16.5 13.2091 16.5 11C16.5 8.79086 14.7091 7 12.5 7C10.2909 7 8.5 8.79086 8.5 11C8.5 13.2091 10.2909 15 12.5 15ZM12.5 13C11.3954 13 10.5 12.1046 10.5 11C10.5 9.89543 11.3954 9 12.5 9C13.6046 9 14.5 9.89543 14.5 11C14.5 12.1046 13.6046 13 12.5 13Z" fill="#FC5A5A"></path><path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.8701C3.5 5.96756 7.53307 2 12.5 2C17.4669 2 21.5 5.96756 21.5 10.8701C21.5 13.2916 20.5094 15.5597 18.795 17.2093L13.2013 22.7128C12.8122 23.0957 12.1879 23.0957 11.7987 22.7129L6.19735 17.2024C4.48444 15.5459 3.5 13.2842 3.5 10.8701ZM17.4003 15.7759C18.7357 14.4909 19.5 12.7409 19.5 10.8701C19.5 7.07953 16.3696 4 12.5 4C8.63037 4 5.5 7.07953 5.5 10.8701C5.5 12.7352 6.25964 14.4804 7.59383 15.7707L12.5 20.5972L17.4003 15.7759Z" fill="#FC5A5A"></path></svg>Site Visit Done</h2>
                <form action="">
                    <div className="form-group">
                        <select name="" className='form-control' id="">
                            <option value="">1</option>
                            <option value="">2</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <select name="" className='form-control' id="">
                            <option value="">1</option>
                            <option value="">2</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className='black-dark-700 mb-8 fz14'>Comments (optional)</label>
                        <textarea name="" className='form-control' placeholder='Enter comments'></textarea>
                    </div>
                    <div className="form-inline cancle-ok-btn">
                        <button className='outline-btn' onClick={(e) => this.props.changeActivityView(ACTIVITY_VIEWS.NEW_ACTIVITY_OPTIONS)}>Cancel</button>
                        <button className='pr-btn'>Confirm</button>
                    </div>
                </form>
            </div>
        </>
    }
}