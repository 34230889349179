import React, { useEffect, useState } from 'react';
import { ReactComponent as PropacityLogo } from '../../assets/propacityplush.svg';
import MobileInput from '../../components/InputGroup/MobileInput';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import InputText from '../../components/InputGroup/InputText';
import InputSelect from '../../components/InputGroup/InputSelect';
import { ReactComponent as WhiteRightNavIcon } from '../../assets/icons/whiteRightNav.svg';
import AsyncSelect from 'react-select/async';
import { getCities } from '../../services/private/csv.service';
import { toast } from 'react-toastify';
import { brokerSignup } from '../../services/public/public.service';
import { fetchToken } from '../../firebase';
import { Oval } from 'react-loader-spinner';

const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const SignupForm = ({ setComponentName, userMobile }) => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState(userMobile);
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [city, setCity] = useState('');
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isTokenFound, setTokenFound] = useState(false);

    useEffect(() => {
        if (userMobile) {
            setMobile(userMobile);
        }
    }, [userMobile]);

    const handleSubmit = async () => {

        if (!name || !mobile || !email || !city) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        if (!EmailRegex.test(email)) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false);
        }

        setLoading(true);
        const payload = {
            name: name,
            email: email,
            company_name: companyName,
            mobile: mobile,
            operational_cities: city.map(c => c.value)
        }
        try {
            const res = await brokerSignup(payload);
            if (res.data.status === 200) {
                localStorage.setItem('authToken', res.data.data?.token)
                localStorage.setItem('name', name);
                localStorage.setItem('email', email);
                localStorage.setItem('mobile', mobile);
                localStorage.setItem('showCard', true);
                toast.success(res.data.message)
                setComponentName('paymentplan');
                fetchToken(setTokenFound);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message)
        }
    }

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {

                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    return (
        <div className='bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-[72px] pb-[72px] px-6 grow-0 shrink-0 w-11/12 md:!mt-0 !mt-10 md:!mb-0 !mb-10 shadow-shadowcard '>
            <div className='w-[300px] !table mx-auto'>
                <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>
                {/* <div className='text-textblack font-semibold text-xl leading-8'>Fill Personal Information</div> */}

                <div className='!space-y-5 [&>*]:!m-0 !mt-6'>
                    <InputText
                        label='Name'
                        placeholder='Rahul'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required={true}
                        error={(error && !name) ? 'Please enter name' : ''}
                    />
                    <MobileInput
                        label='Mobile'
                        value={mobile}
                        onChange={(e) => setMobile(e.phone)}
                        error={(error && !mobile) ? 'Please enter mobile' : ''}
                        disable={userMobile ? true : false}
                        inputclass='!h-10'
                    />
                    <InputText
                        label='Email'
                        placeholder='olivia@untitledui.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                        error={(error && !email) ? 'Please enter email' : emailError ? 'Please enter a valid email.' : ''}
                    />
                    <InputText
                        label='Company Name (optional)'
                        placeholder='Enter company name'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <div className={`form-group font-medium ${(error && city.length === 0) ? 'invalid' : ''}  position-relative`}>
                        <label htmlFor="">Operational City <sup>*</sup></label>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            placeholder='Search and Select'
                            isMulti={true}
                            value={city}
                            // styles={customStyles}
                            onChange={(e) => {
                                setCity(e)
                            }}
                        />
                        {(error && city.length === 0) && (
                            <>
                                <div className="input-feedback mt-8"> Please select operational city
                                </div>
                            </>)}

                    </div>
                    <div className='!mt-6'>
                        {!loading && <button
                            type='button'
                            onClick={handleSubmit}
                            disabled={loading}
                            className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2 !mt-1 inline-flex items-center gap-x-2.5 justify-center'
                        >
                            Create Profile <WhiteRightNavIcon />

                        </button>}
                        {
                            loading && <button
                                disabled={true}
                                className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2 !mt-1 inline-flex items-center gap-x-2.5 justify-center'
                            >
                                <Oval
                                    height={30}
                                    width={30}
                                    color="#FFFFFF"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#FFFFFF"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />
                            </button>
                        }
                    </div>
                </div>



            </div>
        </div>
    )
}

export default SignupForm
