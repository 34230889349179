import React, { useEffect, useState } from 'react'
import RightModal from '../RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Close } from '../../../assets/icons/closeGray.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputTextarea from '../../InputGroup/InputTextarea';
import InputText from '../../InputGroup/InputText';
import { insertTeam, updateTeam } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { getExistingUsers, getUsersForSettings } from '../../../services/private/company.service';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : '',
        color: state.isSelected ? '#44444f' : '',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const AddTeamModal = ({ show, closeModal, getAll, selectedTeam }) => {
    const [name, setName] = useState('');
    const [teamLead, setTeamLead] = useState('');
    const [users, setUsers] = useState([]);
    const [remarks, setRemarks] = useState('');
    const [error, setError] = useState(false);
    const [userList, setUserList] = useState([]);

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                // const usersForAssign = res.data.data.map((data) => {
                //     return {
                //         label: data.name + " - " + data.email,
                //         // label: data.name,
                //         value: data.uuid
                //     }
                // });
                // setUsersForAssigned(usersForAssign);
                setUserList(userData)
            }
        })
    }

    useEffect(() => {
        getAllUsers()
    }, []);

    useEffect(() => {
        if (Object.keys(selectedTeam).length > 0) {
            console.log({ selectedTeam })
            setName(selectedTeam?.name);
            setUsers(selectedTeam?.users ? selectedTeam?.users?.map(u => ({ label: u.name, value: u.id })) : [])
            setTeamLead(selectedTeam?.leader_id);
            setRemarks(selectedTeam?.description)
        }
    }, [selectedTeam])

    const handleSubmit = async () => {
        if (!name || !teamLead || users.length === 0) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        const payload = {
            name: name,
            description: remarks,
            leader_id: teamLead,
            users: users.map(u => u.value)
        }
        let res = {}
        if (selectedTeam?.uuid) {
            res = await updateTeam(selectedTeam?.uuid, payload);
        } else {
            res = await insertTeam(payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAll();
            closeModal();
        }
    }
    return (
        <RightModal show={show} closeModal={closeModal}>
            <div className='d-flex justify-content-between !px-5 !py-4'>
                <h1 className='text-black text-2xl inter m-0'>Add Teams</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='flex h-100 overflow-auto flex-column !px-5'>
                <div className='flex flex-col !gap-y-4'>
                    <InputText
                        label='Team Name'
                        placeholder='Enter Team Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required={true}
                        error={(error && !name) ? 'Please Enter Team Name' : ''}
                    />
                    <InputSelect
                        label='Select Team Lead'
                        placeholder='Select Team Lead'
                        value={teamLead ? userList.find(i => i.value === teamLead) : ''}
                        onChange={(value) => {
                            setTeamLead(value.value)
                        }}
                        styles={customStyles}
                        required={true}
                        options={userList}
                        error={(error && !teamLead) ? 'Please Select Team Lead' : ''}
                    />
                    <InputSelect
                        label='Select Users'
                        placeholder='Select Users'
                        onChange={(value) => {
                            setUsers([...users, value])
                        }}
                        styles={customStyles}
                        required={true}
                        value={''}
                        options={userList.filter(i => i.value !== teamLead)}
                        error={(error && users.length === 0) ? 'Please Select User' : ''}
                    />

                    {users.length > 0 && <div className='flex flex-wrap gap-2'>
                        {users.map((item, index) => (
                            <div
                                className='inline-flex gap-x-2 text-xs !border !border-grayLight bg-[#F2F3F6] rounded-md !p-2 inter font-medium text-textblack'>
                                {item.label}
                                <button className='border-0 bg-transparent' onClick={() => {
                                    let newUsers = [...users];
                                    newUsers.splice(index, 1);
                                    setUsers(newUsers);
                                }}>
                                    <Close className='strokeck800' />
                                </button>
                            </div>
                        ))
                        }

                    </div>}
                    <InputTextarea
                        label='Team Description'
                        placeholder='Add Description'
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </div>
                <div>

                </div>
            </div>
            <div className={`d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow`}>
                <button className='outline-btn w-36 basis-36 shrink-0 grow-0  !text-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 !text-lg'>Confirm</button>
            </div>
        </RightModal>
    )
}

export default AddTeamModal
