import React from 'react'
import { Button } from 'react-bootstrap'
import PopUpModal from './PopUpModal'

const ConfirmDeletion = ({ show, setShow, deleteHandler, title, deleteText, subText }) => {
    return (

        <PopUpModal
            setShow={setShow}
            show={show}
            heading={<>
                {title ? title : 'Confirm Delete?'}
            </>}
            body={<>

                <p className='black-dark-800 fz16 po m-0'>Are you sure you want to delete {` ${deleteText ? deleteText : 'Lead(s)'}`}?</p>
                {subText && <p className='black-dark-800 fz16 po m-0'>{subText}</p>}

                <div className="justify-content-end d-flex modal-footer mt-3">
                    <Button variant="" className="cancel" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="" className='del-btn' onClick={deleteHandler}>
                        Delete
                    </Button>
                </div>
            </>}
        />
    )
}

export default ConfirmDeletion