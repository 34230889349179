import React, { useEffect, useState } from 'react'
import InputText from '../../../components/InputGroup/InputText'
import { getPaymentDetails, savePaymentDetails } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { BankList } from '../../../helpers/enums';
import InputSelect from '../../../components/InputGroup/InputSelect';

const FinancialDetails = () => {
    const [bankName, setBankName] = useState('');
    const [name, setName] = useState('');
    const [account, setAccount] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [upiID, setUpiID] = useState('');

    const getFinanceData = async () => {
        try {
            const res = await getPaymentDetails();
            if (res.data.status === 200) {
                setBankName(res.data.data?.bank_name || '');
                setName(res.data.data?.account_holder_name || '');
                setAccount(res.data.data?.account_holder_name || '');
                setIfsc(res.data.data?.ifsc || '');
                setUpiID(res.data.data?.upi_id || '')
            }
        } catch (err) {
            console.log({ err })
            // toast.error(err.response?.data?.message);
        }
    }

    useEffect(() => {
        getFinanceData();
    }, []);

    const handleSubmit = async () => {
        if (!upiID && !name && !bankName && !account && !ifsc) {
            toast.error('Please fill Bank details or UPI ID')
            return;
        }
        const payload = {
            upi_id: upiID,
            account_holder_name: name,
            bank_name: bankName,
            account_no: account,
            ifsc: ifsc
        }
        const res = await savePaymentDetails(payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getFinanceData()
        }
    }

    return (
        <div className='w-100 flex flex-col h-full'>
            <div className='!pt-6 !pb-4 !ml-5 !pr-6 max-w-100 !border-b !border-black400 flex justify-between sm:flex-row flex-col items-start sm:gap-0 !gap-4'>
                <div>
                    <h2 className='mb-0 text-black800 text-2xl font-medium leading-7'>Financial Details</h2>
                </div>
            </div>

            <div className='!px-6 !py-5 h-full overflow-auto'>

                <div className='max-w-[362px] grid sm:grid-cols-1 grid-cols-1 [&>*]:!m-0 !gap-x-8 gap-y-6'>
                    <InputText
                        label='Account Holder Name'
                        placeholder='xxxxxxxxxxxx'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <InputSelect
                        label="Bank Name"
                        placeholder="xxxxxxxxxxxx"
                        type="text"
                        name="name"
                        inputclass='m-0 inter'
                        value={bankName ? BankList.find(item => item.value === bankName) : ''}
                        onChange={(value) => { setBankName(value.value) }}
                        options={BankList}
                    />
                    {/* <InputText
                        label='Bank Name'
                        placeholder='xxxxxxxxxxxx'
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                    /> */}
                    <InputText
                        label='Bank Account no.'
                        placeholder='xxxxxxxxxxxx'
                        value={account}
                        onChange={(e) => setAccount(e.target.value)}
                    />
                    <InputText
                        label='IFSC Code'
                        placeholder='XXXX00000XXX'
                        value={ifsc}
                        onChange={(e) => setIfsc(e.target.value)}
                    />

                    <InputText
                        label='UPI'
                        placeholder='e.g. olivia@oksbi'
                        value={upiID}
                        onChange={(e) => setUpiID(e.target.value)}
                    />
                    <div>
                        <button
                            className='gradient-blue shadow-inset-blue w-full h-12 inline-flex items-center justify-center text-white !rounded-lg font-semibold'
                            onClick={handleSubmit}
                        >Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialDetails