export const TIMELINE_ACTIONS = {
    GET_TIMELINE: 'GET_TIMELINE',
    SET_TIMELINE: 'SET_TIMELINE',
}

export function action_getTimeline(leadUUID) {
    return {
        type: TIMELINE_ACTIONS.GET_TIMELINE,
        leadUUID
    }
}

export function action_setTimeline(timeline) {
    return {
        type: TIMELINE_ACTIONS.SET_TIMELINE,
        timeline
    }
}

