import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import RightModal from '../../Modals/RightModal';
import SearchInput from '../../InputGroup/SearchInput';

const OfferModal = ({ show, closeModal, style, handleUpdateOffers, previousOffer }) => {

    const [offers, setOffers] = useState(['', '']);

    const addOffer = () => {
        setOffers([...offers, '']);
    };
    let error = '';

    const handleChangeOffers = (e, index) => {
        const newOffers = [...offers];
        newOffers[index] = e.target.value;
        setOffers(newOffers)
    }

    useEffect(() => {
        if (previousOffer.length > 0) setOffers(previousOffer)
    }, [previousOffer])

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`${style.configurationModal} ${style.addbanckAccountModal}  configurationModal`
        }>

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Offers</h1>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>
            <form className={`${style.modalForm} modalForm d-flex h-100 overflow-auto flex-column px-20`}>

                {offers.map((offer, index) => (

                    <div className='form-group'>
                        <label >Offer {index + 1}</label>
                        <input
                            type='text'
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            placeholder='e.g. 10% OFF + Free Car'
                            value={offer}
                            onChange={(e) => handleChangeOffers(e, index)}
                        // {...register('appartmentNumber')}
                        />
                        {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                    </div>
                ))}

                <div className='d-flex justify-content-center'><button className={`${style.addlandmarkbtn}`} onClick={addOffer} type='button'>+ Add More Offers</button></div>

            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button className='outline-btn w-100' onClick={closeModal}>Cancel</button>
                <button type='button' onClick={() => {
                    handleUpdateOffers(offers)
                    closeModal();
                }} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal >
    )
}

export default OfferModal
