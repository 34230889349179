import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { toast } from 'react-toastify';
import { getMediaName, uploadFile } from '../../../services/public/public.service';
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as PlusBlueIcon } from '../../../assets/icons/plusBlue.svg'
import InputTextarea from '../../InputGroup/InputTextarea';
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import CreatableSelect from 'react-select';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg'
import { createConstructionUpdate } from '../../../services/private/inventory.service';
import { ReactComponent as BackICon } from '../../../assets/icons/back.svg';
import DateTimePicker from '../../InputGroup/DateTime';
import moment from 'moment';

const UploadConstructionDoc = ({ show, closeModal, uuid, selectedProject, getConstructionDocs }) => {

    const [constructionDoc, setConstructionDoc] = useState([]);
    const [mediaNameList, setMediaNameList] = useState([]);
    const [description, setDescription] = useState('');
    const [updatedDate, setUpdatedDate] = useState('');

    const getAllMediaName = async () => {
        const res = await getMediaName();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ label: item.name, value: item.name }));
            setMediaNameList(list);
        }
    }

    useEffect(() => {
        getAllMediaName();
    }, []);

    const uploadFileToS3 = async (file, index) => {
        console.log(file, 'file');
        // if (file.type !== 'video/' && file.size / 1048576 > 5) {
        //     toast.warning('File size exceeds 5mb');
        //     return;
        // }
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: '',
                media_name: ''
            }
            if (index >= 0) {
                let newProofs = [...constructionDoc];
                newProofs[index] = newFile;
                setConstructionDoc(newProofs)
            } else {
                setConstructionDoc([...constructionDoc, newFile])
            }

        }
    }

    const handleOnChange = async (e, index) => {
        uploadFileToS3(e.target.files[0], index);
    }

    const handleSubmit = async () => {
        const payload = {
            update_description: description,
            images: constructionDoc,
            update_date: updatedDate || moment()
        }
        const res = await createConstructionUpdate(selectedProject?.uuid, payload)
        if (res.data.status === 200) {
            // console.log(res.data.data)
            getConstructionDocs();
            closeModal();
        }
    }
    return (
        <div>
            <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
                <div className='p-4 h-[100vh] overflow-y-auto'>
                    <div className='flex items-center !mb-6'>
                        <button onClick={closeModal} className='mr-2'>
                            <BackICon />
                        </button>
                        <h3 className='text-lg font-semibold m-0'>Construction Updates</h3>
                    </div>

                    <div>
                        <InputTextarea
                            placeholder={'Enter your Update'}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className='mt-3'>
                            <DateTimePicker
                                placeholder='Select Date'
                                label='Update Date'
                                inputclass='mt-0'
                                value={updatedDate}
                                onValueChange={(date) => {
                                    // error={errors?.scheduleDate ? errors?.scheduleDate.message : ''
                                    setUpdatedDate(date)
                                }}
                                required={false}
                                showTime={false}
                            />
                        </div>
                        <div className='my-3'>

                            <label htmlFor=""> Upload Document</label>
                            <div
                                className={`relative group border border-primary bg-blueHighlight h-[100px] rounded-lg flex flex-col items-center justify-center`}
                            >
                                <input type="file" className='d-none' id={`upload-construction-doc`} onChange={(e) => handleOnChange(e)} />

                                <div className={`${constructionDoc.length > 0 ? 'flex-col' : ''} flex items-center`}>
                                    <button
                                        type='button'
                                        className='!text-primary  flex items-center justify-center py-1 px-2 gap-1 text-sm font-medium'
                                        onClick={() => {
                                            document.getElementById(`upload-construction-doc`).click();
                                        }}
                                    >
                                        <PlusBlueIcon />
                                        Add Media
                                    </button>

                                </div>

                            </div>
                        </div>
                        {constructionDoc.length > 0 && <div className='h-[50vh]'>
                            {
                                constructionDoc.map((doc, index) => (
                                    <div className=' bg-[#FAFAFB] !p-4 rounded-lg relative '>
                                        <input type="file" className='d-none' id={`upload-payment-proof-${index}`} onChange={(e) => handleOnChange(e, index)} />
                                        <figure className='mb-0 !table !mx-auto relative group'>
                                            {/* <Imageplacehoder className='w-full ' /> */}
                                            {['jpg', 'png', 'gif', 'svg', 'jpeg', 'webp'].includes(doc.fileName.split('.').pop()) ? <img src={doc.url} alt="" className='h-100 w-100' style={{ objectFit: 'cover' }} />
                                                :
                                                <video width="100%" height="250" controls muted style={{ objectFit: 'cover', borderRadius: '8px' }}>
                                                    <source src={doc.url} type="video/mp4" />
                                                </video>
                                            }
                                            <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                <button
                                                    className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                    onClick={() => {
                                                        document.getElementById(`upload-payment-proof-${index}`).click();
                                                    }}
                                                ><PluswhiteIcon />Replace</button>

                                                <button
                                                    className={`${style.delbtn} inter border-0 bg-white`}
                                                    onClick={() => {
                                                        let newFiles = [...constructionDoc];
                                                        newFiles.splice(index, 1);
                                                        setConstructionDoc(newFiles);

                                                    }}
                                                ><TrashIcon /></button>
                                            </div>
                                        </figure>
                                        <div className='mt-3'>

                                            <CreatableSelect
                                                placeholder='Search & Select Document Name'
                                                onCreateOption={(value) => {
                                                    setMediaNameList([...mediaNameList, { label: value, value: value }])
                                                    const newList = [...constructionDoc];
                                                    newList[index].media_name = value;
                                                    setConstructionDoc([...newList]);
                                                }}
                                                onChange={(value) => {
                                                    const newList = [...constructionDoc];
                                                    newList[index].media_name = value.value;
                                                    setConstructionDoc([...newList]);
                                                }}
                                                value={doc.media_name ? { label: doc.media_name, value: doc.media_name } : ''}
                                                options={mediaNameList}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>}
                    </div>
                    <div className='absolute bottom-2 right-2 w-full'>
                        <div className='flex justify-end w-full'>

                            <button onClick={handleSubmit} className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">Post</button>
                        </div>
                    </div>
                </div>
            </RightModal>
        </div>
    )
}

export default UploadConstructionDoc