

import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';
import URLS  from '../../helpers/urls';

  export async function updateCustomerByIdAndKey(customerId, payload){
      return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateCustomer}${"/"+customerId}`, null, payload, null)
  }
  export async function getCustomerEnumsCall() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customer.getEnums}`, null, null, null);
  }

  export async function updateCustomerByIdAndKey2(customerId, payload){
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateByKeyValue2}${"/"+customerId}`, null, payload, null)
}


