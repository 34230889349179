import React from 'react'
import ReferImg from '../../../../assets/referiocn2.png';
const ReferCardTwo = ({ classname }) => {
    return (
        <div className={`${classname} gradient800 flex flex-col items-center justify-center !px-8 pb-10`}>

            <p className='md:text-2xl text-lg font-semibold text-white text-center !mb-12'>For your referrals, earn up to ₹10,000 in rewards.</p>
            <div>
                <img src={ReferImg} alt="" />

            </div>

        </div>
    )
}

export default ReferCardTwo
