import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './CustomTable.css';
import { createDatewithTime } from '../../helpers/date';
import { getCompanyAgency, getMasterEnumsCall, getTimelineCall, updateleadByKeyValue } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import dotMenuIcon from '../../assets/icons/dotmenu.svg';

import NoResultFound from '../NotFound/NoResultFound';
import ConfirmDeletion from '../Modals/ConfirmDeletion';
import { getSelectedValue } from '../../utils/selectedOptionUtils';
import LeadName from './TableColumns/LeadName';
import Projects from './TableColumns/Projects';
import SourceLabel from './TableColumns/SourceLabel';
import StatusDropDown from './TableColumns/StatusDropDown';
import DynamicIntent from './TableColumns/DynamicIntent';
import AssignedTo from './TableColumns/AssignedTo';
import checkAssignedTo from '../../utils/checkAssignedTo';
import MobileNo from './TableColumns/MobileNo';
import ASCIcon from '../../assets/icons/asc_sort.svg';
import DSCIcon from '../../assets/icons/dsc_sort.svg';
import SortIconDisplay from './sortIconDisplay';
import RecentActivities from '../../modules/Lead Details/components/Recent Activities/RecentActivities';
import { Oval } from 'react-loader-spinner';
import MiniTimline from '../Modals/MiniTimline';
import RenderTableData from './RenderTableData';
import CustomColumnModal from '../Modals/CustomColumnModal';
import ShareLeadAccessModal from '../Modals/ShareLeadAccessModal';
import { checkIsSomeItemAvailableInList } from '../../helpers/helpers';


const NewLeadsTable = ({
    leadData,
    filters,
    startIndex,
    showCheck,
    updateData,
    selectedTableValues,
    setSelectedTableValues,
    setSelectedTableData,
    selectedTableData,
    handleUserDeletion,
    handleUserReassignLead,
    columns,
    col,
    count,
    searchText,
    reAssignRole,
    sort,
    intentStatuses,
    statuses,
    sourceStatuses,
    setLeadName,
    selectedLeadName,
    agencyList,
    showCreateLeadModal,
    newLeadsColumn,
    saveCurrentUserColumnPrefrence,
    handleChangeSelection,
    updateColumnList,
    dashboardColumns,
    allowedPermissions,
    isRolesModule,
    userProfile
}) => {
    const [deadUuid, setDeadUuid] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedSingleUserIdFromTable, setSelectedSingleUserIdFromTable] = useState();
    const [selectedUserName, setSelectedUserName] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [timeline, setTimeline] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState('');
    const ROLE_NAME = localStorage.getItem('role');
    const [showTimeline, setShowTimeline] = useState(false);
    const [timelineLeadName, setTimelineLeadName] = useState('');
    const [showCustomColumn, setShowCustomColumn] = useState(false)
    const [columnsList, setColumnsList] = useState(newLeadsColumn);
    const [tableColumn, setTableColumn] = useState([]);
    const [isCustomColumnAvailable, setIsCustomColumnAvailable] = useState(false);
    const [reAssignAvailable, setReAssignAvailable] = useState(false);
    const [deleteAvailable, setDeleteAvailable] = useState(false);
    const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({})

    const tableRef = useRef();

    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN') {
                setIsCustomColumnAvailable(true);
                setReAssignAvailable(true);
                setDeleteAvailable(true);
            }
            if (reAssignRole?.includes('re_assign_leads')) {
                setReAssignAvailable(true);
            }
        } else {
            if (allowedPermissions.includes('customise_column_self')) {
                setIsCustomColumnAvailable(true);
            }
            if (allowedPermissions.includes('re_assign_leads_single')) {
                setReAssignAvailable(true);
            }
            if (allowedPermissions.includes('delete_leads')) {
                setDeleteAvailable(true);
            }
        }
    }, [isRolesModule]);

    // TBD REMOVED AFTER USE CASE SOLVED
    const handelChange = (e, data) => {
        if (e.target.checked) {
            setLeadName(data.name)
            setSelectedTableValues([...selectedTableValues, data.id])
            setSelectedTableData([...selectedTableData, data]);
        }
        else {
            const filteredValues = selectedTableValues.filter((value) => value != data.id);
            const filteredData = selectedTableData.filter((value) => value.id != data.id);
            setSelectedTableValues(filteredValues);
            setSelectedTableData(filteredData);
            setLeadName('')
        }
    }

    useEffect(() => {
        if (!selectedLeadName && selectedTableValues.length === 1) {
            const leadVal = leadData.find((lead) => lead.id === selectedTableValues[0]);
            setLeadName(leadVal.name)
        }
    }, [selectedLeadName, selectedTableValues])

    const onHandleSelectAllLeads = () => {
        if (!selectAll) {
            const selectedVal = leadData.map((lead) => lead.id)
            setSelectedTableValues(selectedVal);
            setSelectedTableData(leadData);
        } else {
            setSelectedTableValues([])
            setSelectedTableData([]);
        }
        setSelectAll(!selectAll)
    }

    const handleRowPosition = (e, index) => {
        localStorage.setItem('rowIndex', index)
        localStorage.setItem('position', document.getElementById(`leads_table_${index}`).getBoundingClientRect().top)
    }

    useEffect(() => {
        tableRef.current.scrollTop = parseInt(localStorage.getItem('rowIndex')) * 50;
    }, [leadData])

    useEffect(() => {
        const sortedList = newLeadsColumn.sort((a, b) => a.placement - b.placement)
        setTableColumn(sortedList);
    }, [newLeadsColumn]);

    const handleLeadTimeline = useCallback((data, index) => {
        setSelectedIndex(index)
        if (data.id) {
            setTimelineLeadName(data.name)
            getTimelineCall(data.id).then((res) => {
                if (res.data.status === 200) {
                    setTimeline(res.data.data)
                    setShowTimeline(true)
                }
            }).catch((err) => {
                console.log({ err })
            })
        }
    }, []);

    const closeTimelineDropdown = useCallback(() => {
        setSelectedIndex('');
        setTimeline([]);
        setShowTimeline(false);
    }, []);

    const closeCustomColumnModal = useCallback(() => {
        setShowCustomColumn(false)
    }, []);

    return (
        <>
            <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedUserName}?`}
                deleteHandler={() => handleUserDeletion(selectedSingleUserIdFromTable)}
            />
            <div className="all-leads !pt-1 md:!pl-5 !pl-4 ">
                <div className={`lead-table all_lead-table h-full  ${leadData?.length === 0 ? 'overflow-hidden shownofound' : ''}`} ref={tableRef}>

                    <table className="table">
                        <thead>
                            <tr>
                                <th
                                    className={`${showCheck ? 'cursor-pointer' : ''}`}
                                >
                                    {showCheck ?

                                        <input
                                            type="checkbox"
                                            onChange={onHandleSelectAllLeads}
                                            checked={selectAll}
                                            id='selectAll_leads'
                                            className='select-all-check resasign-input'
                                            title='Select all'
                                        />
                                        :
                                        'S.No.'
                                    }
                                </th>
                                {
                                    tableColumn?.map((item, index) => {
                                        if (item.label === 'Marketing Agency' && agencyList.length === 0) {
                                            return null
                                        }
                                        return (
                                            <th key={`${item.db_value}_${index}`}
                                                className={`${item.label === 'Name' ? 'sticky-th' : ''} ${(item.label === 'Mobile No.' && userProfile?.company_id === 581) ? 'sticky left-[252px]' : ''}`}
                                            >
                                                <span className='flex items-center gap-1'>
                                                    {item.label}
                                                    <SortIconDisplay sortCol={col} sort={sort} colName={item.label} />
                                                </span>
                                            </th>
                                        )
                                    })
                                }
                                {userProfile?.allowed_modules?.includes('lead_sharing') && <th>Shared With</th>}
                                <th>Lead Access Validity</th>
                                <th>Access</th>
                                {isCustomColumnAvailable ? <th>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="button-tooltip" className='leadmanager-tooltip'>
                                                <div className='info-msg'>
                                                    Customise Columns
                                                </div>
                                            </Tooltip>
                                        }
                                    >

                                        <button className='cust-col-btn' onClick={() => setShowCustomColumn(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <line x1="5.81445" y1="10.1094" x2="14.4082" y2="10.1094" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                                <line x1="10.1113" y1="5.8125" x2="10.1113" y2="14.4062" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                            </svg>

                                        </button>
                                    </OverlayTrigger>
                                </th> : <th></th>}
                                {/* <th>
                                    Name
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Name' />
                                </th>
                                <th>
                                    Mobile No.
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Mobile No.' />
                                </th>
                                <th>
                                    Assigned Date
                                </th>
                                <th>
                                    Project
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Project' />
                                </th>
                                <th>
                                    Status
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Status' />
                                </th>
                                <th>
                                    Intent
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Intent' />
                                </th>
                                <th>
                                    Source
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Source' />
                                </th>
                                <th>
                                    City
                                    <SortIconDisplay sortCol={col} sort={sort} colName='City' />
                                </th>
                                {ROLE_NAME === 'ADMIN' && <th style={{ backgroundColor: `${col === 'Assigned To' && ''}` }}>
                                    Assigned User
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Assigned To' />
                                </th>}

                                {agencyList.length > 0 && <th>
                                    Marketing Agency
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Marketing' />
                                </th>}

                                {ROLE_NAME === 'ADMIN' && <th>
                                    Created Date
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Date' />
                                </th>} */}

                            </tr>
                        </thead>
                        <tbody>
                            {
                                leadData && leadData.length > 0 &&
                                leadData.map((data, index) => {
                                    return (
                                        <tr
                                            key={data.id + data.intent + data.status}
                                            className={`${(!data.status) ? 'new-leads' : 'old-leads'}`}
                                            id={`leads_table_${index}`}
                                            onClick={(e) => handleRowPosition(e, index)}
                                        >
                                            <td>
                                                {showCheck ?
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => handelChange(e, data)}
                                                        checked={selectedTableValues.includes(data.id)}
                                                    /> :
                                                    index + startIndex
                                                }
                                            </td>
                                            {
                                                tableColumn?.map((item, index) => {
                                                    return (
                                                        <RenderTableData
                                                            item={item}
                                                            data={data}
                                                            updateData={updateData}
                                                            intentStatuses={intentStatuses}
                                                            statuses={statuses}
                                                            leadData={leadData}
                                                            agencyList={agencyList}
                                                            sourceStatuses={sourceStatuses}
                                                            key={`${item.db_value}_${index}`}
                                                            userProfile={userProfile}
                                                        />
                                                    )
                                                })
                                            }
                                            {/* <td id='lead_name'>
                                                <LeadName
                                                    data={data}
                                                    intent={data.Intent}
                                                    intentStatuses={intentStatuses}
                                                />
                                            </td>
                                            <td className='pr4' style={{ maxWidth: '200px', overflow: 'hidden', }}>
                                                <MobileNo mobileNo={data['Mobile No.']} />
                                            </td>
                                            <td>
                                                {data['Updated Date'] ? createDatewithTime(new Date(data['Updated Date'])) : '-'}
                                            </td>
                                            <td>
                                                <div className='d-flex flex-row flex-nowrap align-itmes-center project-td'>
                                                    <Projects
                                                        projectsArr={data.Project.filter((project) => project !== null)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <StatusDropDown
                                                    value={getSelectedValue(data.status, statuses)}
                                                    options={statuses}
                                                    id={data.id}
                                                    reloadData={updateData}
                                                    leadName={data.name}
                                                    intentOptions={intentStatuses}
                                                    data={data}
                                                />
                                            </td>
                                            <td>
                                                <DynamicIntent
                                                    selectedIntent={data.Intent}
                                                    intentOptions={intentStatuses}
                                                    reloadData={updateData}
                                                    id={data.id}
                                                    data={data}
                                                    status={getSelectedValue(data.status, statuses)}
                                                />
                                            </td>

                                            <td>
                                                <SourceLabel
                                                    allSources={sourceStatuses}
                                                    sources={data.Source}
                                                />
                                            </td >
                                            <td>
                                                {data.City ? data.City : '-'}
                                            </td>
                                            {ROLE_NAME === 'ADMIN' &&
                                                <td className='asign-td'>
                                                    {/* {checkAssignedTo(columns) ?  <AssignedTo
                                                        columns={columns}
                                                        assignedToName={data["Assigned To"]}
                                                    /> : "-"} 
                                                    {data["Assigned To"] ? data["Assigned To"] : '-'}
                                                </td>}

                                            {agencyList.length > 0 && <td className='text-capitalize'>{data['Lp Agency'] || data['Fb Agency'] || '-'}</td>}
                                            {ROLE_NAME === 'ADMIN' &&
                                                <td>
                                                    {data.Date ? createDatewithTime(new Date(data.Date)) : '-'}
                                                </td>} */}

                                            {/* {(ROLE_NAME === 'ADMIN' || reAssignRole?.includes('re_assign_leads')) && */}
                                            {userProfile?.allowed_modules?.includes('lead_sharing') && <td>
                                                {
                                                    data.shared_with_count > 0 ?
                                                        <div className='view-notes'>
                                                            <button className="btn btn-secondary dropdown-toggle" type="button" id=""
                                                                onClick={() => {
                                                                    setSelectedItem(data)
                                                                    setShowShareLeadAccessModal(true)
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                        </div>
                                                        : '-'
                                                }
                                            </td>}
                                            <td>{data.validity ? `${data?.validity} ${data.validity_unit}` : '-'}</td>
                                            <td className='capitalize'>{data?.access ? data?.access === 'edit' ? 'Edit/View' : 'View' : '-'}</td>
                                            <td className='dropdown view-more'>

                                                <button
                                                    className={`bg-tr border-0 ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''} `}
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    {reAssignAvailable &&
                                                        <a className="dropdown-item"
                                                            onClick={() => {
                                                                setLeadName(data.name);
                                                                handleUserReassignLead(data.id)
                                                            }}
                                                        >
                                                            Re-assign Lead
                                                        </a>}

                                                    <a className="dropdown-item" onClick={() => {
                                                        setTimeline([]);
                                                        handleLeadTimeline(data, index);
                                                    }}>
                                                        View Recent Activity
                                                    </a>
                                                    {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                                        checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) &&
                                                        <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowShareLeadAccessModal(true)
                                                        }}>

                                                            Share Lead Access
                                                        </a>}
                                                    {deleteAvailable && <a className="dropdown-item del-menu-itme" onClick={() => {
                                                        setShowDeleteModal(true)
                                                        setSelectedSingleUserIdFromTable(data.id)
                                                        setSelectedUserName(data.name)
                                                    }}>Delete Lead</a>}
                                                </div>
                                                {/* {selectedIndex === index && <div className="dropdown view-more">
                                                        <div className="dropdown-menu mini-timeline show">
                                                            {timeline?.length > 0 && <RecentActivities ActivityTimeline={timeline} leadName={data.name} closeTimelineDropdown={closeTimelineDropdown} />}
                                                            {timeline?.length === 0 && <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                                                                <Oval
                                                                    height={30}
                                                                    width={30}
                                                                    color="#0062FF"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                    ariaLabel='oval-loading'
                                                                    secondaryColor="#0062FF"
                                                                    strokeWidth={2}
                                                                    strokeWidthSecondary={2}
                                                                />
                                                            </div>}
                                                        </div>
                                                    </div>} */}
                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {leadData?.length === 0 && <NoResultFound count={count} filters={filters} searchText={searchText} showCreateLeadModal={showCreateLeadModal} />}
                    {showTimeline &&
                        <MiniTimline
                            setShow={setShowTimeline}
                            show={showTimeline}
                            ActivityTimeline={timeline}
                            leadName={timelineLeadName}
                            closeTimelineDropdown={closeTimelineDropdown}
                        />}
                    {showCustomColumn &&
                        <CustomColumnModal
                            subHeading={'New Leads'}
                            activeColumnTab='New'
                            show={showCustomColumn}
                            setShow={closeCustomColumnModal}
                            updateColumnList={updateColumnList}
                            dashboardColumns={dashboardColumns}
                            tab={'New'}
                            handleChangeSelection={handleChangeSelection}
                            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        />
                    }
                    {showShareLeadAccessModal &&
                        <ShareLeadAccessModal
                            show={showShareLeadAccessModal}
                            selectedItem={selectedItem}
                            closeModal={() => setShowShareLeadAccessModal(false)}
                            onSuccess={updateData}
                            allowedPermissions={allowedPermissions}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default NewLeadsTable;