import React from 'react'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as CloseSearchIcon } from '../../assets/icons/searchClose.svg';

const Searchbox = ({ label, placeholder, value, onChange, handelClear, inputClass, searchIconClass }) => {
    return (
        <div className='form-group w-100'>
            {label && <label htmlFor="">{label}</label>}
            <div className='relative w-100'>
                <div className="absolute !top-2 !left-2"><SearchIcon className={`${searchIconClass}`} /></div>
                <input
                    type="text"
                    className={`${inputClass} w-100 ${value ? '!border-primary' : 'border-grayLight  focus:outline-0 focus:!border-primary w-100 '} placeholder:!text-black700 !text-black700 !border`}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                />
                {value && <button className='absolute !top-3 !right-2' onClick={handelClear}><CloseSearchIcon /></button>}
            </div>
        </div>
    )
}

export default Searchbox
