import React from 'react'
import ReferImg from '../../../../assets/referIcon.png';

const ReferCard = ({ classname }) => {
    return (
        <div className={`${classname} gradient800 flex flex-col items-center justify-center !px-8 pb-10`}>

            <div>
                <img src={ReferImg} alt="" />

            </div>
            <p className='md:text-2xl text-lg font-semibold text-white text-center !mt-6 mb-0'>Refer your friend to Propacity Plus to earn ₹1000.</p>
        </div>
    )
}

export default ReferCard
