import { put, all, call, takeLatest } from "redux-saga/effects";
import request from '../../helpers/requests';
import { LEAD_DETAIL_ACTIONS, action_setLeadDetail, action_setActivityStats, action_setLatestActivity, action_getLeadDetail, action_setMasterEnums, action_setCustomerEnums } from "./actions";
import { API_URLS, API_METHOD } from '../../config/apiUrl';
import { action_getTimeline } from "./components/Recent Activities/actions";
import { getMasterEnumsCall } from "../../services/private/company.service";
// Get lead detail
function getLeadDetail(uuid) {
  return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getLeadDetail}/${uuid}`, null, null, null);
}

function* getLeadDetailWorker(action) {
  try {
    let response = yield call(getLeadDetail, action.uuid);

    yield put(action_setLeadDetail(response.data.data));

  } catch (error) {
  }
}

// Get activity stats
function getActivityStatsByLeadUUIDCall(uuid) {
  return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getActivityStatsByLeadUUID}/${uuid}`, null, null, null);
}

function* getActivityStatsByLeadUUIDWorker(action) {
  try {
    let response = yield call(getActivityStatsByLeadUUIDCall, action.uuid);

    yield put(action_setActivityStats(response.data.data));

  } catch (error) {
  }
}

// Get latest activity
function getLatestActivityByLeadUUIDCall(uuid) {
  return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getLatestActivityByLeadUUID
    }/${uuid}`, null, null, null);
}

function* getLatestActivityByLeadUUIDWorker(action) {
  try {
    let response = yield call(getLatestActivityByLeadUUIDCall, action.uuid);
    yield put(action_setLatestActivity(response.data.data));

  } catch (error) {
  }
}

// update customer by key and value
function updateCustomerByKeyValue(uuid, payload) {
  return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateByKeyValue}/${uuid}`, null, payload, null);
}

function* updateCustomerByKeyValueUUIDWorker(action) {
  try {
    let payload = {
      key: action.key,
      value: action.value
    }
    let response = yield call(updateCustomerByKeyValue, action.uuid, payload);
    yield put(action_getLeadDetail(action.leadUUID));

  } catch (error) {
  }
}

// update customer by 2 key and value
function updateCustomerByKeyValue2(uuid, payload) {
  return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateByKeyValue2}/${uuid}`, null, payload, null);
}

function* updateCustomerByKeyValue2UUIDWorker(action) {
  try {
    let payload = {
      key1: action.key1,
      value1: action.value1,
      key2: action.key2,
      value2: action.value2,
    }
    let response = yield call(updateCustomerByKeyValue2, action.uuid, payload);
    yield put(action_getLeadDetail(action.leadUUID));

  } catch (error) {
  }
}

// update lead by key and value
function updateleadByKeyValue(uuid, payload) {
  return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.lead.updateByKeyValue}/${uuid}`, null, payload, null);
}

function* updateleadByKeyValueWorker(action) {
  try {
    let payload = {
      key: action.key,
      value: action.value
    }
    let response = yield call(updateleadByKeyValue, action.uuid, payload);
    yield put(action_getLeadDetail(action.leadUUID));

    // Get Timeline
    yield put(action_getTimeline(action.leadUUID))

  } catch (error) {
  }
}

// update Budget
function updateleadBudget(uuid, payload) {
  return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateBudget}/${uuid}`, null, payload, null);
}

function* updateleadBudgetWorker(action) {
  try {
    let payload = {
      minimumBudget: action.minimumBudget,
      maximumBudget: action.maximumBudget
    }
    let response = yield call(updateleadBudget, action.uuid, payload);
    yield put(action_getLeadDetail(action.leadUUID));

  } catch (error) {
  }
}


// Get Master Enums
// function getMasterEnumsCall() {
//   return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMasterEums}`, null, null, null);
// }

function* getMasterEnumsWorker(action) {
  try {
    let response = yield call(getMasterEnumsCall);
    let optonStatuses = response.data.data.statuses.map((item) => {
      return { label: item.display_name, value: item.id }
    });
    let optonIntents = response.data.data.intents.map(item => {
      return { label: item.display_name, value: item.id }
    });
    let optonSources = response.data.data.sources.map(item => {
      return { label: item.display_name, value: item.id }
    });
    yield put(action_setMasterEnums(optonStatuses, optonIntents, optonSources));

  } catch (error) {
  }
}

// Get Customer Enums
function getCustomerEnumsCall() {
  return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customer.getEnums}`, null, null, null);
}

function* getCustomerEnumsWorker(action) {
  try {
    let response = yield call(getCustomerEnumsCall);
    yield put(action_setCustomerEnums(response.data.data));

  } catch (error) {
  }
}


export default function* leadDetailSaga() {
  yield all([
    takeLatest(LEAD_DETAIL_ACTIONS.GET_LEAD_DETAIL, getLeadDetailWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.GET_ACTIVITY_STATS, getActivityStatsByLeadUUIDWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.GET_LATEST_ACTIVITY, getLatestActivityByLeadUUIDWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.UPDATE_CUSTOMER, updateCustomerByKeyValueUUIDWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.UPDATE_LEAD, updateleadByKeyValueWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.UPDATE_BUDGET, updateleadBudgetWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.GET_MASTER_ENUMS, getMasterEnumsWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.GET_CUSTOMER_ENUMS, getCustomerEnumsWorker),
    takeLatest(LEAD_DETAIL_ACTIONS.UPDATE_CUSTOMER2, updateCustomerByKeyValue2UUIDWorker),

  ]);
}