import React, { useState } from 'react'

import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as PlushIcons } from '../../../assets/icons/whitePlush.svg';
import { ReactComponent as ViewlistIcon } from '../../../assets/icons/Viewlist.svg';
import { ReactComponent as ViewgridIcon } from '../../../assets/icons/Viewgrid.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg';
import { ReactComponent as FilePng } from '../../../assets/icons/FilePng.svg';
import { ReactComponent as FilePdf } from '../../../assets/icons/FilePdf.svg';
import { ReactComponent as FileXls } from '../../../assets/icons/FileXls.svg';
import { ReactComponent as HeaderMenu } from '../../../assets/icons/HeaderMenu.svg';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import SearchBox from '../../InputGroup/Searchbox';
import moment from 'moment';
import Lightbox from 'yet-another-react-lightbox';
import TooltipText from '../../Tooltip';
import pdfimg from '../../../assets/icons/pdfview.jpg';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';

const Document = ({ documents, name }) => {

    const [showLightModal, setShowLightModal] = useState(false);
    const [selectdImg, setSelectdImg] = useState();

    return (
        <div className='bg-white !mr-2.5 !border !border-grayLight shadow-boxshadow flex flex-col gap-y-8 !px-5 !py-5 rounded-lg'>
            <div>
                <div className='flex flex-row items-center'>
                    <div className='w-6/12'>
                        <h2 className='inter text-textblack font-semibold text-xl mb-0'>{name} Documents</h2>
                    </div>
                    <div className='w-6/12'>
                        <div className='flex items-center gap-x-3 justify-end'>
                            {/* <div className='w-[218px] shrink-0 grow-0 basis-[218px]'>
                                <SearchBox
                                    inputClass='!pl-8 !py-1.5 !pr-8 text-sm leading-5 rounded'
                                    searchIconClass='w-5 h-5'
                                    placeholder='Search'
                                />
                            </div> */}
                            {/* <div>
                                <div className='flex flex-row !border border-grayLight whitespace-nowrap h-8 rounded w-[144px] grow-0 shrink-0 basis-[144px]'>
                                    <button className='flex items-center h-[30px] !px-2 gap-x-1 border-r border-grayLight text-sm text-black700 tracking-[0.5px] inter bg-black200'><ViewgridIcon />Grid</button>
                                    <button className='flex items-center h-[30px] !px-2 gap-x-1 border-l border-grayLight text-sm text-black700 tracking-[0.5px] inter'><ViewlistIcon />List</button>
                                </div>
                            </div> */}
                            {/* <div>
                                <button className='inline-flex items-center gap-x-2 text-sm font-medium !border !border-grayLight rounded bg-black100 h-[32px] !px-4 inter'><ExportIcon />Export</button>
                            </div>
                            <div>
                                <button className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'><PlushIcons /> Upload</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='!mt-5'>
                    {documents.length > 0 && <div className='grid sm:grid-cols-3 gap-x-6'>
                        {documents.map((item, index) => (
                            <div >
                                <div className='!border border-grayLight rounded h-[210px] group  cursor-pointer relative flex items-center justify-center overflow-hidden'
                                >
                                    {!['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                        <img
                                            onClick={() => {
                                                if (!['video/mp4'].includes(item?.file_type)) {
                                                    setSelectdImg(index)
                                                    setShowLightModal(true)
                                                } else {
                                                    window.open(item.url, '_blank')
                                                }

                                            }}
                                            src={item.url} alt="" className='w-full' />}
                                    {['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                        <img
                                            onClick={() => {
                                                window.open(item.url, '_blank')
                                            }}
                                            src={pdfimg} alt="" className='w-full' />}
                                    <div
                                        className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-grayLight !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'
                                        onClick={(e) => {
                                            // e.stopImmediatePropagation();
                                            setSelectdImg('')
                                            setShowLightModal(false)
                                            const link = document.createElement("a");
                                            link.href = item.url;
                                            link.download = `${item.name}`;
                                            link.click();
                                        }}
                                    >
                                        <DownloadIcon />
                                    </div>
                                </div>
                                {/* <TooltipText title={item?.name}> */}
                                <p className='text-textblack text-base font-semibold inter !mt-1 w-100 truncate'>
                                    {item?.category}
                                </p>
                                {/* </TooltipText> */}
                            </div>
                        ))}
                    </div>}
                    {
                        documents.length === 0 && <div>
                            <div className='flex justify-center flex-column items-center h-100'>
                                <figure><ResultSearchIcon /></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Documents Added</h2>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add document to see them here</p>

                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                            </div>
                        </div>
                    }
                    {/* <div className='def-table plantable h-100 !overflow-auto !p-0'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="w-auto text-left !min-w-fit">Name</th>
                                    <th>Uploaded By</th>
                                    <th>Size</th>
                                    <th>Category</th>
                                    <th>Kind</th>
                                    <th>Last Opened</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    documents?.map((doc, index) => (
                                        <tr key={doc.uuid}>
                                            <td className="w-auto text-left !min-w-fit gap-x-1 items-center capitalize">
                                                <span className='inline-flex items-center gap-x-1'>
                                                    <FileXls />
                                                    {doc.name}
                                                </span>
                                            </td>
                                            <td>-</td>
                                            <td>{doc.size}</td>
                                            <td>{doc.category || '-'}</td>
                                            <td>Folder</td>
                                            <td>{doc?.updated_on ? moment(doc?.updated_on).format('h:mm a, Do MMM YYYY') : '-'}</td>
                                            <td className='dropdown view-more '>

                                                <button className="bg-tr border-0 !px-4 !py-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <HeaderMenu />
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                                    <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                                    <a className='dropdown-item'>Create Customer link</a>
                                                    <a className='dropdown-item'>Add New Booking</a>
                                                    <a className='dropdown-item'>Raise New Demand</a>
                                                    <div className="head-itme !mt-3 whitespace-nowrap">Bulk Action <span></span></div>
                                                    <a className='dropdown-item'>Re- assign Lead</a>
                                                    <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete Lead</a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div> */}
                </div>
            </div>
            {showLightModal && <Lightbox
                open={showLightModal}
                close={() => setShowLightModal(false)}
                slides={documents?.filter(item => (!['video/mp4', 'application/pdf'].includes(item?.file_type))).map(item => ({ src: item.url }))}
                carousel={{
                    finite: true
                }}
                index={selectdImg}
                plugins={[Thumbnails]}
            // video={{
            //     controls: true,
            //     playsInline: true,
            //     autoPlay: false,
            //     loop: false,
            //     muted: true,
            //     disablePictureInPicture: false,
            //     disableRemotePlayback: false,
            //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
            //     crossOrigin: '',
            //     preload: '',
            // }}
            />}
        </div>
    )
}

export default Document
