import React, { useState } from 'react';
import backgroundImage from '../../assets/background.jpg';
import { ReactComponent as PropacityLogo } from '../../assets/propacityplush.svg';
import MobileInput from '../../components/InputGroup/MobileInput';
import { Link } from 'react-router-dom';
import OTP from './OTP';

import SignupForm from './SignupForm';
import PaymentPlan from './PaymentPlan';
import Offers from './Offers';
import Coupons from './Coupons';
import { brokerSignup, sendBrokerOtpForLogin } from '../../services/public/public.service';
import { toast } from 'react-toastify';
import AppDownloadModal from './AppDownloadModal';


const Signup = () => {

    const [componentName, setComponentName] = useState('login')
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('');
    const [code, setCode] = useState('');
    const [selectedOffer, setSelectedOffer] = useState({});
    const [appDownloadModal, setAppDownloadModal] = useState(false);

    const handleSubmit = async () => {
        if (!mobile) {
            setError('Please enter mobile number.');
            return;
        } else {
            setError('')
        }
        try {
            const res = await sendBrokerOtpForLogin({ mobile });
            if (res.data.status === 200) {
                toast.success(res.data.message);
                setComponentName('otp')
            }
        } catch (err) {
            console.log({ err });
            toast.error(<div>
                {err?.response?.data?.message}
                <p>{err?.response?.data?.data?.mobile}</p>
            </div>,
                {
                    autoClose: 5000,
                    closeOnClick: true,
                }
            )
        }
    }

    return (
        <div className={`${componentName === 'signupform' ? 'md:items-center items-start' : 'items-center'} flex flex-fill justify-center h-full overflow-y-auto overflow-x-hidden xl:!py-0`} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            {componentName === 'login' && (<div className='bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-[72px] pb-[72px] px-6 grow-0 shrink-0 w-11/12 shadow-shadowcard'>
                <div className='w-[300px] !table mx-auto'>
                    <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>
                    <div className='text-textblack font-semibold text-xl leading-8'>Sign In</div>

                    <div className='font-inter [&>*]:!border-0 !mt-6'>
                        <label className='text-sm font-medium text-black800 !mb-2'>Mobile<sup className='text-blue-900 !text-sm !top-0 font-medium'>*</sup></label>
                        <MobileInput
                            value={mobile}
                            onChange={(e) => setMobile(e.phone)}
                            error={error}
                            inputclass='!h-10'
                            dropdownDisable={true}
                        />
                    </div>
                    <div className='!mt-6'>
                        <button className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2' onClick={handleSubmit}>Continue</button>
                    </div>
                    <div className='!mt-6 text-black800 text-xs leading-5'>
                        By continuing, you agree to our
                        <a className='!text-blue-900 hover:!text-text-blue-900 cursor-pointer' href='https://www.propacity.in/terms-of-service.php' target='_blank'>Terms of Service</a> and
                        <a className='!text-blue-900 hover:!text-text-blue-900 cursor-pointer' href='https://www.propacity.in/privacy-policy.php' target='_blank'>Privacy Policy</a>.
                    </div>
                    {/* <div className='mt-6 text-black800 text-xs leading-5'>
                        Already registered? <Link to='/login' className='!text-blue-900 hover:!text-text-blue-900 cursor-pointer font-semibold'>Log in</Link>
                    </div> */}
                </div>
            </div>
            )}

            {componentName === 'otp' && <OTP setComponentName={setComponentName} mobile={mobile} handleResendOTP={handleSubmit} />}
            {componentName === 'signupform' && <SignupForm setComponentName={setComponentName} userMobile={mobile} />}
            {componentName === 'paymentplan' && <PaymentPlan setComponentName={setComponentName} page='outer' setSelectedPlan={setSelectedPlan} selectedPlan={selectedPlan} />}
            {componentName === 'offers' &&
                <Offers
                    setComponentName={setComponentName}
                    page='outer'
                    selectedPlan={selectedPlan}
                    setCode={setCode}
                    code={code}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                />}
            {componentName === 'coupons' && <Coupons
                setComponentName={setComponentName}
                page='outer'
                selectedPlan={selectedPlan}
                setCode={setCode}
                code={code}
                selectedOffer={selectedOffer}
                setSelectedOffer={setSelectedOffer}
            />}

            {appDownloadModal && <AppDownloadModal show={appDownloadModal} closeModal={() => setAppDownloadModal(false)} />}

            <div className='md:flex hidden items-center justify-center md:flex-row flex-col gap-3 fixed bottom-0 left-0 right-0 z-10 bg-textblack text-white !py-2'>
                <p className='m-0 text-sm font-medium '>Kickstart your Real Estate journey today!</p>
                <button onClick={() => setAppDownloadModal(true)} className='text-sm shadow-white-btn-shadow bg-whtie font-medium !rounded-lg !border !border-black400 bg-white !py-2 text-textblack px-2.5'>Download Mobile App Now</button>

            </div>

        </div>

    )
}

export default Signup