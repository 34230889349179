import React from 'react'
import CenterModal from '../../../../components/Modals/center-modal'
import { ReactComponent as SucessIocn } from '../../../../assets/sucess.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';

const PaymentDetailSavedModal = ({ show, closeModal }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!w-[400px]  [&>*]:!border-0 [&>*]:!rounded-xl [&>*]:!overflow-hidden'>
            <div className='!p-6 relative'>
                <button onClick={closeModal} className='border-0 bg-tr absolute right-4 top-5 z-10'><CloseIcon /></button>

                <figure className='!mb-5'><SucessIocn /></figure>
                <h3 className='text-2xl font-semibold text-textblack !mb-2'>
                    Payment details saved
                </h3>
                <p className='text-black700 text-sm !mb-8'>
                    Referral money will be sent to your account/UPI once the refferal is complete
                </p>
                <button className='gradient-blue inline-flex gap-x-2 text-white font-semibold inset-blue text-base !rounded-lg !px-2.5 !py-2 items-center w-full justify-center' onClick={closeModal}>Got it</button>
            </div>
        </CenterModal>
    )
}

export default PaymentDetailSavedModal