import React, { useState } from 'react'
import { ReactComponent as HeaderMenu } from '../../../assets/icons/HeaderMenu.svg';
import RecentCollection from './RecentCollection';
import AddCollection from '../Modal/AddCollection';
import { getMobileNumber, toINRFormat } from '../../../helpers/helpers';
import { createDatewithTime } from '../../../helpers/date';
import moment from 'moment';
import DemandRaise from './DemandRaise';
import { ReactComponent as NoteIcon } from '../../../assets/icons/NoteIcon.svg';
import { getBookingPaymentMilestonesByBookingId } from '../../../services/private/inventory.service';
import AddDemand from '../Modal/AddDemand';
import ReassignLeadModal from '../../Modals/ReassignLeadModal';
import { reassignLeads } from '../../../services/private/leads.service';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';


const BookingCard = ({
    item,
    setShowViewNotes,
    setSelectedCollection,
    setShowReassignModal,
    setSelectedItem,
    getPaymentMilestones,
    bookingCollections,
    setShowDebitTransaction,
    setShowVerifyCollection,
    selectedProject,
    setShowOtherTransaction,
    allowedPermission,
    currentTab,
    setShowAddCollection,
    setIsEditDemand,
    handleGetDemandDetails,
    setShowDeleteModal,
    setBookingStatus,
    getBookingDetails,
    setShowAddPDCModal
}) => {
    const history = useHistory();
    // const [show, setShow] = useState(false);
    // const [paymentMilestones, setPaymentMilestones] = useState([]);

    // const getPaymentMilestones = async () => {
    //     const res = await getBookingPaymentMilestonesByBookingId(item?.booking_uuid);
    //     if (res.data.status === 200) {
    //         console.log(res.data.data);
    //         const milestones = res.data.data.map(milestone => ({ ...milestone, label: `${milestone.count}. ${milestone.description}`, value: milestone.id }));
    //         setPaymentMilestones(milestones);
    //         setShow(true);
    //     }
    // }

    // const handleReassignModalSubmit = (userDetails, remarks) => {
    //     reassignLeads([selectedItem.lead_uuid], userDetails.value, remarks).then(res => {
    //         if (res.status === 200) {
    //             toast.success("Lead Reassigned")
    //             // getLeadsData();
    //             setSelectedItem('');
    //             setShowReassignModal(false)
    //         }
    //     }).catch(e => {
    //         toast.error(e.response.data.message)
    //     })
    //     // resetDataMods()
    // }

    return (
        <div className='border border-grayLight !p-4 flex flex-col shadow-boxshadow rounded-lg bg-white'>
            <div className='flex flex-row !border-b !border-grayLight !pb-3'>
                <div className='w-5/12'>
                    <Link to={`/collection-manager/detail/${item?.booking_uuid}?project=${selectedProject?.label}&tab=${currentTab}`}
                        className='hover:text-primary text-xl inter text-textblack mb-0 font-semibold !leading-7'>
                        <span className='text-black700 mr-1'>
                            #{item?.booking_unique_id}
                        </span>
                        {item?.customer_name}
                    </Link>
                    <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium !mt-2'>
                        {/* <div>#{item?.booking_unique_id || '-'}</div> */}
                        {/* <div className='w-2 h-2 rounded-full bg-black700'></div> */}
                        {item?.approval_status === 'pending' && <button
                            className='m-0 text-[#171725] rounded-lg p-1 bg-[#FFCE3E] flex items-center'
                            onClick={() => {
                                setBookingStatus(item?.approval_status)
                                getBookingDetails(item?.booking_uuid)
                            }}
                        >
                            Pending Approval
                            <span className='ml-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="#171725"><path fillRule="evenodd" clipRule="evenodd" d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z" fill="#171725"></path></svg>
                            </span>
                        </button>}
                        {/* {item?.approval_status === 'verified' && <p className='m-0'>
                            Verified
                        </p>} */}
                        {item?.approval_status === 'approved' && <p className='m-0 text-white bg-green p-1 rounded-lg'>
                            Approved
                        </p>}
                        {item?.approval_status === 'rejected' && <p className='m-0 text-white p-1 bg-red rounded-lg'>
                            Rejected
                        </p>}
                        <div >
                            <a href={`tel:${getMobileNumber(item?.customer_mobile)}`} className='hover:text-primary text-black700 flex items-center'>
                                <span className='mr-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.62125 0.19236C4.75425 -0.519097 6.90971 0.817014 7.50842 2.98023C7.91639 4.45429 7.53002 5.99994 6.58787 7.05833C6.78083 7.30824 6.99168 7.54852 7.22057 7.77759C7.44836 8.00554 7.6872 8.2156 7.93554 8.40791C8.99355 7.45831 10.5439 7.0676 12.0222 7.47736C14.185 8.07685 15.5186 10.234 14.8082 12.3669C14.3422 13.7663 13.4731 14.6166 12.314 14.8965C11.237 15.1566 10.0758 14.8801 9.05163 14.4544C6.98887 13.5969 4.95202 11.9078 4.02179 10.9769C3.0696 10.024 1.38785 7.98421 0.538138 5.92533C0.116188 4.90293 -0.155499 3.74602 0.105165 2.67396C0.385601 1.52059 1.23155 0.655861 2.62125 0.19236ZM5.80821 3.45079C5.44419 2.13556 4.23468 1.51388 3.17944 1.86585C2.25186 2.17522 1.93014 2.63512 1.81935 3.09076C1.68879 3.62772 1.80232 4.36423 2.16885 5.25232C2.89608 7.01443 4.40431 8.86392 5.26968 9.72993C6.11411 10.575 7.96164 12.0908 9.7288 12.8254C10.6196 13.1957 11.3597 13.3121 11.8999 13.1817C12.3581 13.071 12.8221 12.7474 13.1345 11.8095C13.4868 10.7517 12.8641 9.54135 11.551 9.17739C10.5737 8.90652 9.56904 9.22035 8.97109 9.86073C8.58497 10.2743 7.84895 10.51 7.22273 10.0729C6.78212 9.76533 6.36367 9.41583 5.97268 9.02455C5.58107 8.63265 5.23143 8.21319 4.92382 7.7716C4.48947 7.14805 4.72124 6.41489 5.13177 6.02786C5.76741 5.42861 6.07814 4.42608 5.80821 3.45079Z" fill="#92929D" />
                                    </svg>
                                </span>
                                {item?.customer_mobile ? getMobileNumber(item?.customer_mobile) : '-'}
                            </a>
                        </div>
                    </div>
                    <div className='!mt-2 flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium '>
                        <div>{moment(new Date(item?.schedule_date)).format("Do MMM YYYY")}</div>
                        <div className='w-2 h-2 rounded-full bg-black700'></div>
                        {item?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
                            <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                                B
                            </span>{item?.channel_partner_name}
                        </div>}
                        {!item?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
                            <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                                S
                            </span>{item?.assigned_user_name}
                        </div>}
                        <div className='w-2 h-2 rounded-full bg-black700'></div>
                        <div>{item?.payment_plan || '-'}</div>

                    </div>
                </div>
                <div className='w-7/12'>
                    <div className='flex gap-x-6 items-center justify-end'>
                        {/* {item?.approval_status === 'pending' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-[#FBBC05] font-semibold m-0 leading-6 text-right'>Pending</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>
                                Status
                            </div>
                        </div>}
                        {item?.approval_status === 'approved' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-green font-semibold m-0 leading-6 text-right'>Approved</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                Status
                            </div>
                        </div>}
                        {item?.approval_status === 'verified' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-green font-semibold m-0 leading-6 text-right'>Verified</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                Status
                            </div>
                        </div>} */}
                        {/* {item?.collection_approval_status === 'rejected' && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-red font-semibold m-0 leading-6 text-right inline-flex items-center gap-x-1'><InfoRedIcon />Rejected</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                Status
                            </div>
                        </div>} */}
                        {/* <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 text-right'>{item?.unit_name}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                {item?.unit_configuration && <>
                                    <div>{item?.unit_configuration}</div>
                                    <div className='w-2 h-2 rounded-full bg-black700'></div>
                                </>}
                                <div>{item?.plot_area} {item?.plot_area_unit}</div>
                            </div>
                        </div> */}
                        {(item?.unit_configuration || item?.plot_area) && <div className='flex flex-col gap-y-2'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 text-right'>{item?.unit_name}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                {item?.unit_configuration && <div>{item?.unit_configuration || '-'}</div>}
                                {item?.unit_configuration && <div className='w-2 h-2 rounded-full bg-black700'></div>}
                                <div>{item?.plot_area ? `${Math.trunc(item?.plot_area)} ${item?.plot_area_unit}` : '-'}</div>
                            </div>
                        </div>}
                        <div className='flex flex-col gap-y-2 text-right'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.block || '-'}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div>Block</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2 text-right'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{(item?.final_price || item?.agreement_price || 0) ? '₹' + `${toINRFormat((item?.final_price || item?.agreement_price || 0))}` : '-'}</h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div> Net Purchase Price </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-2 text-right'>
                            <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '> {(item?.final_price || item?.agreement_price || 0) ? '₹' + `${toINRFormat(Math.abs(parseInt((item?.final_price || item?.adjusted_price || 0)) - parseInt(item?.received_amount || 0)) || 0)}` : '-'} </h3>
                            <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                <div>
                                    {parseInt((item?.final_price || item?.adjusted_price || 0)) - parseInt(item?.received_amount || 0) > 0 ? 'Pending Amt.' : 'Surplus Amt.'}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="dropdown view-more ">
                                <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <HeaderMenu />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                    <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                    {/* <a className='dropdown-item'>Bulk Action</a> */}
                                    <a className='dropdown-item' onClick={() => {
                                        setSelectedItem(item);
                                        setShowDebitTransaction(true)
                                    }}>Add Debit Trans.</a>
                                    <a className='dropdown-item'
                                        onClick={() => {
                                            history.push(`/collection-manager/detail/${item?.booking_uuid}?project=${selectedProject?.label}&tab=${currentTab}`)
                                        }}
                                    >View Customer Details</a>
                                    {/* <a className='dropdown-item'>Print Booking</a> */}
                                    {item?.approval_status === 'pending' && <a className='dropdown-item'
                                        onClick={() => {
                                            setBookingStatus(item?.approval_status)
                                            getBookingDetails(item?.booking_uuid)
                                        }}
                                    >
                                        Approve Booking
                                    </a>}
                                    <a
                                        className='dropdown-item'
                                        onClick={() => {
                                            setSelectedItem(item);
                                            setShowAddPDCModal(true);
                                        }}
                                    >View/Add PDC</a>
                                    <a
                                        className='dropdown-item'
                                        onClick={() => {
                                            setSelectedItem(item);
                                            setShowAddCollection(true);
                                        }}
                                    >Add Collection</a>
                                    <a className='dropdown-item'
                                        onClick={() => {
                                            setShowOtherTransaction(true)
                                        }}
                                    >Add Other Payment</a>
                                    {allowedPermission.includes('edit_demand') && item?.demand_uuid && <a className='dropdown-item' onClick={() => {
                                        setSelectedItem(item);
                                        setIsEditDemand(true);
                                        getPaymentMilestones(item?.booking_uuid, item?.demand_uuid);
                                        // handleGetDemandDetails(item?.demand_uuid)
                                    }}>Edit Demand</a>}
                                    {allowedPermission.includes('raise_demand') && !item?.demand_uuid && <a className='dropdown-item' onClick={() => {
                                        setSelectedItem(item)
                                        getPaymentMilestones(item?.booking_uuid)
                                    }}>New Demand</a>}
                                    {allowedPermission.includes('re_assign_booking_single') && <a className='dropdown-item'
                                        onClick={() => {
                                            setSelectedItem(item)
                                            setShowReassignModal(true);
                                        }}
                                    >Re-assign Booking</a>}
                                    {allowedPermission.includes('delete_booking') && <a
                                        className="dropdown-item !text-red hover:!bg-red hover:!text-white"
                                        onClick={() => {
                                            setSelectedItem(item);
                                            setShowDeleteModal(true);
                                        }}
                                    >
                                        Delete Booking
                                    </a>}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {(item?.demand_uuid || item?.collection_amount) && <div className='flex flex-row justify-between !mt-3 w-full'>
                {/* <div className='w-4/12'>
                    <div className='max-w-[400px]'>
                        <div className='flex flex-row justify-between mb-2'>
                            <div className='text-textblack itner font-sm font-medium leading-5'>Note :</div>

                            <div className='flex flex-row inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                <div>View all (4)</div>
                                <div className='w-1 h-1 rounded-full bg-black700'></div>
                                <div>Add Note</div>
                            </div>
                        </div>
                        <div className='text-xs inter italic text-black700 leading-4'>
                            Lorem ipsum dolor sit amet consectetur. Adipiscing ac odio placerat quis bibendum id convallis turpis. ac odio placerat quis
                        </div>
                        <div className='flex flex-row inter text-xs text-black700 gap-x-3 items-center font-medium leading-4 !mt-1'>
                            <div>Friday 2:20pm</div>
                            <div className='w-1 h-1 rounded-full bg-black700'></div>
                            <div>Phoenix Baker</div>
                        </div>

                    </div>
                </div> */}
                <div className='w-[140px] h-[104px] grow-0 shrink-0 basis-[140px] !border !border-grayLight shadow-boxshadow rounded-lg !py-2 !px-3 flex flex-col'>
                    <h2 className='flex items-center text-sm text-black700 font-medium leading-5 border-b border-grayLight !pb-2 m-0'>
                        <span className='w-4'><NoteIcon /></span> Note</h2>
                    <div className='flex w-100 h-100 justify-center items-center'>
                        <button
                            className='text-textblack font-semibold text-base inter hover:!text-primary cursor-pointer'
                            onClick={() => {
                                setShowViewNotes(true);
                                setSelectedCollection(item)
                            }}
                        >
                            View Note</button>
                    </div>
                </div>
                <div className='w-8/12'>
                    <div className='flex justify-end !gap-3'>
                        {/* {item?.collection_amount && */}
                        <RecentCollection
                            classname='bg-white border-grayLight'
                            item={item}
                            bookingCollections={bookingCollections}
                            selectedProject={selectedProject}
                            setShowAddCollection={setShowAddCollection}
                            setShowVerifyCollection={setShowVerifyCollection}
                            setSelectedItem={setSelectedItem}
                        />
                        {/* } */}
                        {/* {item?.demand_uuid && */}
                        <DemandRaise
                            item={item}
                            getPaymentMilestones={getPaymentMilestones}
                            setSelectedItem={setSelectedItem}
                            allowedPermission={allowedPermission}

                        />
                        {/* } */}
                    </div>
                </div>
            </div>}
            {/* {show && <AddDemand show={show} closeModal={() => setShow(false)} item={item} paymentMilestones={paymentMilestones} />} */}
            {/* {
                showReassignModal && <ReassignLeadModal
                    setShow={setShowReassignModal}
                    show={showReassignModal}
                    handleSubmit={(userId, remarks) => handleReassignModalSubmit(userId, remarks)}
                    count={1}
                    selectedLeadName={selectedItem.customer_name}
                />
            } */}
        </div>
    )
}

export default BookingCard
