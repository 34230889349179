import React from 'react'
import { Modal } from "react-bootstrap";

const BasicModal = ({ show, closeModal, children, backDropClassName, modalClass }) => {
    return (
        <Modal show={show} onHide={closeModal} backdrop="static" keyboard={false} fullscreen centered dialogClassName={`${modalClass}`} backdropClassName={backDropClassName}>
            {children}

        </Modal>
    )
}

export default BasicModal
