import React, { useEffect, useState } from 'react'
import { ReactComponent as PaiIcon } from '../../../assets/icons/pai.svg';
import { ReactComponent as PaiIcontwo } from '../../../assets/icons/pai.svg';
import { ReactComponent as EditBlackIcon } from '../../../assets/icons/editgray.svg';
import { toINRFormat } from '../../../helpers/helpers';
import { getAllNotesForLead } from '../../../services/private/leads.service';
import moment from 'moment';

const calculatePercentage = (partial, total) => {
    const percentage = ((100 * partial) / parseInt(total));
    return percentage > 0 ? percentage.toFixed(2) : 0;
}

const Overview = ({ demand, collection, payments, collections, bookingDetails, lead_uuid, pdc }) => {
    // console.log({ collection })
    const [notesList, setNotesList] = useState([]);
    const getAllNotes = () => {
        getAllNotesForLead(lead_uuid).then(res => {
            if (res.status === 200) {
                setNotesList(res.data.data);
            }
        })
    }

    useEffect(() => {
        if (lead_uuid)
            getAllNotes();
    }, [lead_uuid]);

    return (
        <div className='grid grid-cols-2 gap-x-6 pb-6'>
            <div className='flex flex-col gap-y-5 '>
                <div className='border border-grayLight rounded-lg bg-white shadow-boxshadow !p-4'>
                    <div className='flex justify-between items-center !border-b !border-grayLight !pb-4'>
                        <h6 className='text-sm inter text-black700 font-semibold leading-5 m-0'>Demand Details</h6>
                        {/* <button className='!text-primary text-sm font-medium leading-5 inter'>+ Demand</button> */}
                    </div>
                    <div className='flex flex-col gap-y-4 !mt-4'>
                        <div className='flex'>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'> {demand?.due_amount ? `₹ ${toINRFormat(Math.trunc(demand?.total_amount_due))}` : '-'}</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Due Amount</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>{demand?.due_date ? moment(demand?.due_date).format('Do MMM YYYY') : '-'}</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Due Date</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>{demand?.milestone_no ? demand?.milestone_no.join(', ') : '-'}</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Milestone</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border border-grayLight rounded-lg bg-white shadow-boxshadow !p-4'>
                    <div className='flex justify-between items-center !border-b !border-grayLight !pb-4'>
                        <h6 className='text-sm inter text-black700 font-semibold leading-5 m-0'>Collection Details</h6>
                        {/* <button className='!text-primary text-sm font-medium leading-5 inter'>+ Collection</button> */}
                    </div>
                    <div className='flex items-center gap-x-3 !border-b !border-grayLight !pb-4 !pt-4'>
                        <div className='w-11 h-11 grow-0 shrink-0 basis-11'><PaiIcon /></div>
                        <div>
                            <h4 className='inter text-base text-black font-semibold leading-6 !mb-1'>
                                {calculatePercentage(parseInt(bookingDetails?.booking?.received_amount), (bookingDetails?.bookingPricing?.adjusted_price || bookingDetails?.bookingPricing?.final_price))} % Collected
                            </h4>
                            <div className='inline-flex items-center text-sm text-black700 inter'>
                                <span>{collections.length} Collections</span>
                                <span className='w-1 h-1 rounded-full bg-black700 !mx-2 shrink-0 basis-1 grow-0 inline-block'></span>
                                <span>Last Collected, {moment(collection?.updated_on).format('DD/MM/YYYY')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-y-4 !mt-4'>
                        <div className='flex flex-wrap gap-y-4'>

                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>{collection?.total_amount_paid ? `₹ ${toINRFormat(Math.trunc(collection?.total_amount_paid))}` : '-'} </h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Received Amount</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>{collection?.updated_on ? moment(collection?.updated_on).format('Do MMM YYYY') : '-'}</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Received Date</p>
                            </div>
                            {/* <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>₹ 74,74,000</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Total Interest</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>₹ 74,74,000</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Interest Paid</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>₹ 74,74,000</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Outstanding w/ interest</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>₹ 74,74,000</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Pending Verification</p>
                            </div> */}


                        </div>

                    </div>
                </div>

                {/* <div className='border border-grayLight rounded-lg bg-white shadow-boxshadow !p-4'>
                    <div className='flex justify-between items-center !border-b !border-grayLight !pb-4'>
                        <h6 className='text-sm inter text-black700 font-semibold leading-5 m-0'>Broker / CP Details</h6>
                        <button className='!text-primary text-sm font-medium leading-5 inter'>+ Update</button>
                    </div>
                    <div className='flex flex-col gap-y-4 !mt-4'>
                        <div className='flex flex-wrap gap-y-4'>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>Ramesh Kumar</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Assigned To</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>Bhavesh Kumar</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Code</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>Pending</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Brokerage Status</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>Ramesh Kumar</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Total Brokerage Amount</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>Bhavesh Kumar</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Amount Paid</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>Pending</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Amount Due</p>
                            </div>

                        </div>

                    </div>
                </div> */}
                <div className='border border-grayLight rounded-lg bg-white shadow-boxshadow !p-4'>
                    <div className='flex justify-between items-center !border-b !border-grayLight !pb-4'>
                        <h6 className='text-sm inter text-black700 font-semibold leading-5 m-0'>Post Credited Cheques</h6>
                        {/* <button className='!text-primary text-sm font-medium leading-5 inter'>+ Add</button> */}
                    </div>
                    <div className='flex flex-col gap-y-4 !mt-4'>
                        <div className='flex flex-wrap gap-y-4'>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>{pdc?.cheque_no}</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Cheque No.</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>{pdc?.cheque_date ? moment(pdc.cheque_date).format('Do MMM YYYY') : '-'}</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Cheque Date</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>{pdc?.cheque_amount ? `₹ ${toINRFormat(Math.trunc(pdc?.cheque_amount))}` : '-'}</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Cheque Amount</p>
                            </div>


                        </div>

                    </div>
                </div>
                {/* <div className='border border-grayLight rounded-lg bg-white shadow-boxshadow !p-4'>
                    <div className='flex justify-between items-center !border-b !border-grayLight !pb-4'>
                        <h6 className='text-sm inter text-black700 font-semibold leading-5 m-0'>Loan Status</h6>
                        <button className='!text-primary text-sm font-medium leading-5 inter'>+ Update</button>
                    </div>
                    <div className='flex flex-col gap-y-4 !mt-4'>
                        <div className='flex flex-wrap gap-y-4'>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>HDFC Bank</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Bank</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>Approved</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Loan Status</p>
                            </div>
                            <div className='w-4/12'>
                                <h4 className='inter text-sm text-black font-semibold leading-5 !mb-1'>₹ 74,74,000</h4>
                                <p className='inter text-xs text-black700 leading-4 mb-0'>Loan Amount (Preferred)</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='flex flex-col gap-y-5  pr-2.5'>
                {/* <div className='border border-grayLight rounded-lg bg-white shadow-boxshadow !p-4'>
                    <div className='flex justify-between items-center !border-b !border-grayLight !pb-4'>
                        <h6 className='text-sm inter text-black700 font-semibold leading-5 m-0'>Next Followup</h6>
                        <button className='!text-primary text-sm font-medium leading-5 inter'>Edit</button>
                    </div>
                    <div className='!text-red text-base inter font-semibold !pt-4'>9th August 2023, 10:00 AM</div>
                </div> */}

                <div className='border border-grayLight rounded-lg bg-white shadow-boxshadow !p-4'>
                    <div className='flex justify-between items-center !border-b !border-grayLight !pb-4'>
                        <h6 className='text-sm inter text-black700 font-semibold leading-5 m-0'>Notes</h6>
                        {/* <button className='!text-primary text-sm font-medium leading-5 inter'>+ Add Note</button> */}
                    </div>
                    <div className='flex flex-col divide-y divide-grayLight'>
                        {
                            notesList.map((list, index) => (

                                <div className='!pt-5 !pb-3 !pr-5 flex flex-row items-center gap-x-6'>
                                    <div className='w-100'>
                                        <p className='!mb-1 iner text-sm text-black700 font-medium'>{list.description}</p>
                                        <p className='!mb-0 text-xs inter text-black700 font-medium'>{list.name} • {moment(list.updated_on).format("h:mm a, Do MMM YYYY")}</p>
                                    </div>
                                    {/* <div className='w-6 h-6 basis-6 shrink-0 grow-0 pr-hover'>
                                <button className='w-6 h-6 inline-flex items-start justify-center'> <EditBlackIcon /></button>
                            </div> */}
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview
