import React, { useEffect, useState } from 'react'
import { ReactComponent as PropacityLogo } from '../../assets/propacityplush.svg';
import Icontwo from '../../assets/pc2.png';
import IconThree from '../../assets/pc3.png';
import Iconone from '../../assets/pc1.png';
import { useHistory, useParams } from 'react-router-dom';
import { addSubscription, getAllSubscriptions } from '../../services/public/public.service';
import axios from 'axios';
import { API_URLS } from '../../config/apiUrl';
import { toast } from 'react-toastify';
import { ReactComponent as PlanIcon } from '../../assets/icons/plan.svg';

const PaymentPlan = ({ setComponentName, page, setSelectedPlan, selectedPlan, uuid, closeModal }) => {

    const [plans, setPlans] = useState([]);
    // const [loading, setLoading] = use

    const history = useHistory();

    const getPlans = async () => {
        const res = await getAllSubscriptions();
        if (res.data.status === 200) {
            setPlans(res.data.data);
            if (res.data.data.length === 1) {
                setSelectedPlan(res.data.data[0])
            } else {
                const plan = res.data.data.find(plan => plan.is_preferred)
                setSelectedPlan(plan)
            }
        }
    }

    const getSubscriptionPlans = async () => {
        try {
            const res = await axios.get(`${API_URLS.baseurl}${API_URLS.company.getAllSubscriptions}/${uuid}`);
            if (res.data.status === 200) {
                setPlans(res.data.data);
                if (res.data.data.length === 1) {
                    setSelectedPlan(res.data.data[0])
                } else {
                    const plan = res.data.data.find(plan => plan.is_preferred)
                    setSelectedPlan(plan)
                }
            }
        } catch (err) {
            console.log({ err })
            toast.error(<div>Invalid URL, Please contact admin <p>+91 7743877551</p></div>, {
                autoClose: 5000,
                closeOnClick: true,
            });
            history.push('/404')
        }
    }

    useEffect(() => {
        if (!uuid) {
            getPlans();
        } else {
            getSubscriptionPlans();
        }
    }, [uuid])

    const handleSubmit = async () => {
        // const res = await addSubscription(selectedPlan);
        // console.log(res.data.data)
        setComponentName('offers')
    }

    return (
        <div className={`bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-14 pb-8 !px-6 grow-0 shrink-0 ${page === 'inner' ? 'w-full' : 'w-11/12 shadow-shadowcard'} mt-10  `}>
            <div className=''>
                <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>

                <div className='!py-4 !px-5 !border border-black600 rounded-xl'>
                    <h2 className='md:text-xl text-base font-semibold text-textblack !mb-4'>Upgrade to Propacity Premium</h2>

                    <div className='flex flex-col md:gap-y-3 !gap-y-4'>
                        <div className='flex items-center !gap-4'>
                            <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={Iconone} alt="" /></div>
                            <p className='m-0 text-black700 text-sm font-medium'>Unlock unlimited live and pre-recorded sessions, courses</p>
                        </div>
                        <div className='flex items-center !gap-4'>
                            <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={Icontwo} alt="" /></div>
                            <p className='m-0 text-black700 text-sm font-medium'>Engage with industry experts to get personalised insights and guidance.</p>
                        </div>
                        <div className='flex items-center !gap-4'>
                            <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={IconThree} alt="" /></div>
                            <p className='m-0 text-black700 text-sm font-medium'>Cancel subscription at anytime</p>
                        </div>

                    </div>

                </div>
                <div className='!mt-4 flex !gap-4 md:flex-row flex-col'>
                    {
                        plans.length === 1 && plans.map((plan, index) => (

                            <div
                                className={`w-full !border  rounded-xl relative cursor-pointer  overflow-hidden ${selectedPlan.uuid === plan.uuid ? 'bg-blue-900/[0.1] !border-blue-900' : '!border-black400'}`}
                                key={plan.uuid}
                                onClick={() => setSelectedPlan(plan)}
                            >
                                <div className='absolute right-0 top-0 gradient-blue_small text-white text-[10px] font-bold translate-[0.12px] py-1 px-2.5 rounded-bl-xl uppercase'>
                                    {plan?.discount_percentage}% OFF
                                </div>
                                <div className='!pt-5 !px-4 !pb-6 flex justify-between items-center'>

                                    <p className='m-0 text-xl font-semibold text-textblack flex items-center'>
                                        <span className='mr-2'>
                                            <PlanIcon />
                                        </span>
                                        Basic Plan
                                    </p>

                                    <div>
                                        <h2 className='m-0 text-2xl font-semibold text-textblack'>
                                            ₹{parseInt(plan?.amount) - parseInt(plan?.discount_value)}/
                                            <span className='text-xs'>{plan?.validity_unit === 'year' ? plan?.validity_unit : `${plan?.validity} ${plan?.validity_unit}`}</span>
                                        </h2>
                                        <h3 className='m-0 line-through	text-gray-600 text-xs'>
                                            ₹{plan?.amount}/
                                            <span>yr</span>
                                        </h3>
                                    </div>

                                </div>

                            </div>
                        ))
                    }
                    {
                        plans.length > 1 && plans.map((plan, index) => (

                            <div
                                className={`${plans?.length === 1 ? 'w-full' : 'md:w-1/2'} w-full !border  rounded-xl relative cursor-pointer  overflow-hidden ${selectedPlan.uuid === plan.uuid ? 'bg-blue-900/[0.1] !border-blue-900' : '!border-black400'}`}
                                key={plan.uuid}
                                onClick={() => setSelectedPlan(plan)}
                            >
                                <div className='absolute right-0 top-0 gradient-blue_small text-white text-[10px] font-bold translate-[0.12px] py-1 px-2.5 rounded-bl-xl uppercase'>
                                    {plan?.discount_percentage}% OFF
                                </div>
                                <div className='!pt-5 !px-4'>
                                    <h2 className='m-0 text-xl font-semibold text-textblack'>
                                        ₹{parseInt(plan?.amount) - parseInt(plan?.discount_value)}/
                                        <span className='text-xs'>{plan?.validity_unit === 'year' ? plan?.validity_unit : `${plan?.validity} ${plan?.validity_unit}`}</span>
                                    </h2>
                                    <h3 className='m-0 line-through	text-gray-600 text-xs'>
                                        ₹{plan?.amount}/
                                        <span>yr</span>
                                    </h3>

                                </div>
                                {
                                    plan.is_preferred && <div className='gradient-blue_small text-center !block text-white text-[10px] font-bold !py-1 tracking-[0.12px]'>Preferred</div>
                                }
                                {
                                    !plan.is_preferred && <div className='h-[23px]'></div>
                                }
                            </div>
                        ))
                    }
                    {/* <div className='md:w-1/2 w-full !border !border-black400 rounded-xl overflow-hidden relative'>
                        <div className='absolute right-0 top-0 gradient-blue_small text-white text-[10px] font-bold translate-[0.12px] py-1 px-2.5 rounded-bl-xl uppercase'>
                            28% OFF
                        </div>
                        <div className='!pt-5 !px-4'>
                            <h2 className='m-0 text-xl font-semibold text-textblack'>
                                ₹3600/
                                <span className='text-xs'>year</span>
                            </h2>
                            <h3 className='m-0 line-through	text-gray-600 text-xs'>
                                ₹1050/
                                <span>yr</span>
                            </h3>

                        </div>
                        <div className='h-[23px]'></div>
                    </div> */}

                </div>

                {plans.length > 0 &&
                    <div className='!mt-6 flex md:flex-row flex-col !gap-4'>
                        {!uuid && <button
                            className='md:w-1/2 w-full shadow-white-btn-shadow focus:!shadow-white-btn-shadow !border !border-black400 text-base font-medium !rounded-lg	 !py-2 text-black'
                            onClick={() => {
                                if (page !== 'inner') {
                                    // setTimeout(() => {
                                    //     window.location.reload();
                                    //     // setLoading(false);
                                    // }, 1000)
                                    // history.push('/learning-center');
                                    window.location.reload();
                                } else {
                                    closeModal();
                                }
                            }}
                        >
                            {page !== 'inner' ? 'Start' : 'Continue'} for free
                        </button>}
                        <button className={` ${uuid ? '' : 'md:w-1/2'} w-full shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2`} onClick={handleSubmit}>Get Premium</button>
                    </div>
                }

            </div>
        </div>
    )
}

export default PaymentPlan
