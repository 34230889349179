import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import CoursesCard from '../tabs/CoursesCard';
import leftsvg from './mentorleft.svg';
import rightsvg from './mentorright.svg';
import leftsvghover from './mentorlefthover.svg'
import rightsvghover from './mentorrighthover.svg'

const OtherRelevantCourses = ({ style, courses }) => {

    return (
        <div className={`${style.OtherRelevantCourses} relevantCourses overflow-hidden mt-56`}>
            <div className="d-flex justify-content-center">
                <div className={style.other_relivant_block}>
                    <h1 className='fz28 black fw-po-medium inter text-md-center mb-24'>Other Relevant Courses</h1>
                    <div className='mt-26'>
                        {courses.length > 3 ? < OwlCarousel
                            className='owl-theme'
                            dots={false}
                            loop={true}
                            nav={true}
                            navText={[`<img src=${leftsvg} /> <img src=${leftsvghover} />`, `<img src=${rightsvg} /> <img src=${rightsvghover} />`]}
                            margin={30}
                            responsive={{
                                0: {
                                    items: 1,
                                    autoWidth: true,
                                    margin: 24
                                },
                                600: {
                                    items: 2,
                                    autoWidth: true,
                                },
                                900: {
                                    items: 3,
                                    autoWidth: true,
                                },
                                1000: {
                                    items: 3,
                                    autoWidth: true,
                                },
                                1200: {
                                    items: 3,
                                    autoWidth: true,
                                }
                            }
                            }>

                            {
                                courses.map((course, index) => {
                                    return (
                                        course.name && <div className='item' style={{ width: '295px' }} key={`${index}_${course.uuid}`}>
                                            <div className={`${style.releventcoursecard} video-cards courses`}>
                                                <CoursesCard course={course} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                            :
                            <div className='d-flex flex-lg-row flex-column align-item-md-start align-items-start justify-content-center onlytreerelivant'>
                                {
                                    courses.map((course, index) => {
                                        return (
                                            course.name && <div className='item' key={`${index}_${course.uuid}`}>
                                                <div className={`${style.releventcoursecard} video-cards courses`}>
                                                    <CoursesCard course={course} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default OtherRelevantCourses
