import React from 'react'
import Certificate from '../../../assets/Certificate.jpg'

const CertificationBy = ({ style }) => {
    return (
        <div className={`${style.certificationBy} overflow-hidden`}>
            <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-8 col-12">
                    <h1 className='fz28 black fw-po-medium inter text-md-center mb-36'>Certification by Propacity</h1>

                    <figure>
                        <img src={Certificate} alt="" />
                    </figure>
                    <ul>
                        <li>You will be awarded this certificate post course completion.</li>
                        <li>You can share it with your friends on whatsapp & linkedin as part of your achievement.</li>

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CertificationBy
