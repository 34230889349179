import React, { useCallback, useEffect, useState } from 'react'
import BasicModal from '../../Modals/BasicModal'
import { ReactComponent as AddImageIcon } from '../../../assets/icons/addImage.svg';
import { ReactComponent as UploadImgIcon } from '../../../assets/icons/imgupload.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import style from '../../../modules/Settings/Projects/style.module.css';
import '../../../modules/Settings/Projects/style.css';
import InputSelect from '../../InputGroup/InputSelect';
import { getMediaName, uploadFile } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { createConstructionUpdate, updateConstructionUpdate } from '../../../services/private/inventory.service';
import CreatableSelect from 'react-select';
import InputText from '../../InputGroup/InputText';

const AddConstructionUpdatesModal = ({ show, closeModal, getConstructionDocs, uuid, selectedDocs }) => {

    const [constructionDoc, setConstructionDoc] = useState([]);
    const [dragging, setDragging] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [mediaNameList, setMediaNameList] = useState([]);
    const [update, setUpdate] = useState('');

    const getAllMediaName = async () => {
        const res = await getMediaName();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ label: item.name, value: item.name }));
            setMediaNameList(list);
        }
    }

    useEffect(() => {
        getAllMediaName();
    }, []);

    useEffect(() => {
        if (Object.keys(selectedDocs).length > 0) {
            let imgs = selectedDocs.images.map(i => ({
                name: i.file_name,
                fileName: i.file_name,
                type: i.file_type,
                url: i.file_url,
                is_private: false,
                extension: i.file_name.split('.').pop(),
                file_type: i.file_type,
                category: ''
            }))
            setConstructionDoc(imgs);
            setRemarks(selectedDocs.update_description);
        }
    }, [selectedDocs])

    const uploadFileToS3 = async (file, index) => {
        if (file.size / 1048576 > 5) {
            toast.warning('File size exceeds 5mb');
            return;
        }
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }
            if (index >= 0) {
                let newDoc = [...constructionDoc];
                newDoc[index] = newFile;
                setConstructionDoc(newDoc)
            } else {

                setConstructionDoc([...constructionDoc, newFile])
                // setPdcDocument([...pdcDocument, newFile])
            }

        }
    }

    const handleOnChange = async (e, index) => {
        uploadFileToS3(e.target.files[0], index);
    }

    const handleDrag = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setDragging(event.type !== 'dragleave');
    }, []);

    const handleDrop = useCallback((e, index) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer || e.target;
        if (files[0]) {
            uploadFileToS3(files[0], index);
        }
        setDragging(false);
        return false;
    }, []);

    const handleSubmit = async () => {
        const payload = {
            update_description: remarks,
            heading: update,
            images: constructionDoc
        }
        let res = {};
        if (selectedDocs?.uuid) {
            res = await updateConstructionUpdate(selectedDocs?.uuid, payload);
        } else {
            res = await createConstructionUpdate(uuid, payload);
        }

        if (res.data.status === 200) {
            // console.log(res.data.data)
            getConstructionDocs();
            closeModal();
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[680px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!p-6 !pb-0 relative flex items-center justify-between'>
                    <div className='flex gap-x-4 border rounded-lg p-2'>
                        <AddImageIcon />
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <div className=' pb-8 h-full overflow-y-auto'>
                    <div className=' '>

                        <div className='w-full p-6'>
                            <div className=''>
                                <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter flex'>
                                    Upload Image
                                </h1>
                                <p className='m-0 inter text-sm text-black700'> Mark the milestone for the update and add construction description.</p>
                            </div>
                            <div className='mt-3'>
                                <InputText
                                    inputclass='!m-0'
                                    label='Enter Update'
                                    placeholder='Enter Update'
                                    value={update}
                                    onChange={(e) => setUpdate(e.target.value)}
                                />

                                <div className='flex items-center justify-between mt-3'>
                                    <label className='m-0'>Description</label>
                                    <button
                                        type='button'
                                        className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                        onClick={() => {
                                            setRemarks('')
                                        }}
                                    >Clear <CloseIcon className='h-3 w-3' /></button>
                                </div>
                                <textarea
                                    className="form-control mb-16"
                                    name="remarks"
                                    value={remarks || ''}
                                    placeholder="Type a shot description..."
                                    onChange={(e) => {
                                        setRemarks(e.target.value)
                                    }}
                                >
                                </textarea>
                            </div>

                            <div className='mt-3'>
                                <label className='text-lg font-semibold text-textblack !mb-2 inter'>Upload Document</label>
                                <div className={`!mt-3 grid gap-3 ${(constructionDoc.length === 0) ? 'grid-cols-1' : 'grid-cols-3'} `}>
                                    <div >
                                        <div
                                            className={`relative group border-2 border-dashed border-primary h-[154px] rounded-lg flex flex-col items-center justify-center ${dragging ? 'file-drag' : ''}`}
                                            draggable="true"
                                            onDrop={handleDrop}
                                            onDragLeave={handleDrag}
                                            onDragOver={handleDrag}
                                        >
                                            <input type="file" className='d-none' id={`upload-payment-proof`} onChange={(e) => handleOnChange(e)} />
                                            <div>
                                                <UploadImgIcon />
                                            </div>
                                            <div className={`${constructionDoc.length > 0 ? 'flex-col' : ''} flex items-center`}>
                                                <button
                                                    type='button'
                                                    className='!text-primary flex items-center justify-center py-1 px-2 gap-1 text-sm font-medium underline-offset-1 underline'
                                                    onClick={() => {
                                                        document.getElementById(`upload-payment-proof`).click();
                                                    }}
                                                >
                                                    {/* <Pluswhite /> */}
                                                    Click To upload
                                                </button>
                                                <span className=''>or drag and drop</span> <br />
                                            </div>
                                            <span className='block'>Image size : 5 Mb or less</span>
                                        </div>
                                    </div>
                                    {constructionDoc.length > 0 && constructionDoc.map((doc, docIndex) => (
                                        <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg relative'>
                                            <input type="file" className='d-none' id={`upload-payment-proof-${docIndex}`} onChange={(e) => handleOnChange(e, docIndex)} />
                                            <figure className='mb-0 !table !mx-auto relative group'>
                                                {['jpg', 'png', 'gif', 'svg', 'jpeg', 'webp'].includes(doc.fileName.split('.').pop()) ?
                                                    <img src={doc.url} alt="" className='h-100 w-100' style={{ objectFit: 'cover' }} />
                                                    :
                                                    <video width="100%" height="250" controls muted style={{ objectFit: 'cover', borderRadius: '8px' }}>
                                                        <source src={doc.url} type="video/mp4" />
                                                    </video>
                                                }
                                                <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                    <button
                                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                        onClick={() => {
                                                            document.getElementById(`upload-payment-proof-${docIndex}`).click();
                                                        }}
                                                    ><PluswhiteIcon />Replace</button>

                                                    <button
                                                        className={`${style.delbtn} inter border-0 bg-white`}
                                                        onClick={() => {
                                                            let newFiles = [...constructionDoc];
                                                            newFiles.splice(docIndex, 1);
                                                            setConstructionDoc(newFiles);

                                                        }}
                                                    ><TrashIcon /></button>
                                                </div>
                                            </figure>
                                            <div className='mt-3'>

                                                <CreatableSelect
                                                    placeholder='Search & Select'
                                                    onCreateOption={(value) => {
                                                        setMediaNameList([...mediaNameList, { label: value, value: value }])
                                                        const newList = [...constructionDoc];
                                                        newList[docIndex].media_name = value;
                                                        setConstructionDoc([...newList]);
                                                    }}
                                                    onChange={(value) => {
                                                        const newList = [...constructionDoc];
                                                        newList[docIndex].media_name = value.value;
                                                        setConstructionDoc([...newList]);
                                                    }}
                                                    value={doc.media_name ? { label: doc.media_name, value: doc.media_name } : ''}
                                                    options={mediaNameList}
                                                // error={errors?.state?.message ? 'Please select State' : ''}
                                                />
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-end shadow-topshadow sm:flex-row flex-col">
                    <button
                        className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter inline-flex items-center justify-center gap-x-2"
                        onClick={closeModal}
                    >Cancel</button>
                    <button
                        onClick={handleSubmit}
                        className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">
                        {selectedDocs?.uuid ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>
        </BasicModal>
    )
}

export default AddConstructionUpdatesModal