import React, { useEffect, useState } from 'react'
import CenterModal from '../../../../components/Modals/center-modal'
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ReactComponent as VoiletIcon } from '../../../../assets/voilet.svg';
import InputText from '../../../../components/InputGroup/InputText';
import { getPaymentDetails, savePaymentDetails } from '../../../../services/public/public.service';
import { toast } from 'react-toastify';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { BankList } from '../../../../helpers/enums';

const PaymentDetail = ({ show, closeModal, setShowPaymentDetailSavedModal }) => {
    const [bankName, setBankName] = useState('');
    const [name, setName] = useState('');
    const [account, setAccount] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [upiID, setUpiID] = useState('');

    const getFinanceData = async () => {
        try {
            const res = await getPaymentDetails();
            if (res.data.status === 200) {
                setBankName(res.data.data?.bank_name || '');
                setName(res.data.data?.account_holder_name || '');
                setAccount(res.data.data?.account_holder_name || '');
                setIfsc(res.data.data?.ifsc || '');
                setUpiID(res.data.data?.upi_id || '')
            }
        } catch (err) {
            console.log({ err })
            // toast.error(err.response?.data?.message);
        }
    }

    useEffect(() => {
        getFinanceData();
    }, []);

    const handleSubmit = async () => {
        if (!upiID && !name && !bankName && !account && !ifsc) {
            toast.error('Please fill Bank details or UPI ID')
            return;
        }
        const payload = {
            upi_id: upiID,
            account_holder_name: name,
            bank_name: bankName,
            account_no: account,
            ifsc: ifsc
        }
        const res = await savePaymentDetails(payload);
        if (res.data.status === 200) {
            // toast.success(res.data.message);
            // getFinanceData()
            closeModal();
            setShowPaymentDetailSavedModal(true);
        }
    }

    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!w-[560px]  [&>*]:!border-0 [&>*]:!rounded-xl [&>*]:!overflow-hidden'>
            <div className='relative bg-white !p-6'>
                <button onClick={closeModal} className='border-0 bg-tr absolute right-4 top-4'><CloseIcon /></button>
                <div>
                    <VoiletIcon />
                </div>
                <div className='!mt-5'>
                    <h2 className='!mb-2 text-lg text-textblack font-semibold'>Enter Payment details</h2>
                    <p className='!mb-4 text-sm text-black700'>Referral money will be sent to this account/UPI</p>

                </div>
                <div className='grid grid-cols-2 gap-y-4 gap-x-4 [&>*]:!m-0'>
                    <div className='col-span-2'>
                        <InputText
                            label='UPI'
                            placeholder='e.g. olivia@oksbi'
                            value={upiID}
                            onChange={(e) => setUpiID(e.target.value)}
                        />
                        <div className='flex items-center gap-x-2 text-[#667085] text-xs font-semibold'>
                            <span className='w-full h-px bg-black400'></span>OR<span className='w-full h-px bg-black400'></span>
                        </div>
                    </div>

                    <InputText
                        label='Account Holder Name'
                        placeholder='xxxxxxxxxxxx'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <InputSelect
                        label="Bank Name"
                        placeholder="xxxxxxxxxxxx"
                        type="text"
                        name="name"
                        inputclass='m-0 inter'
                        value={bankName ? BankList.find(item => item.value === bankName) : ''}
                        onChange={(value) => { setBankName(value.value) }}
                        options={BankList}
                    />
                    {/* <InputText
                        label='Bank Name'
                        placeholder='xxxxxxxxxxxx'
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                    /> */}
                    <InputText
                        label='Bank Account no.'
                        placeholder='xxxxxxxxxxxx'
                        value={account}
                        onChange={(e) => setAccount(e.target.value)}
                    />
                    <InputText
                        label='IFSC Code'
                        placeholder='xxxxoooooxxx'
                        value={ifsc}
                        onChange={(e) => setIfsc(e.target.value)}
                    />

                </div>

                <div className='!mt-8'>
                    <button
                        className='w-full h-12 gradient-blue shadow-inset-blue text-white !rounded-lg text-base font-semibold'
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>

            </div>

        </CenterModal >
    )
}

export default PaymentDetail
