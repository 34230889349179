import React, { useEffect, useState } from 'react'
import BasicModal from '../../Modals/BasicModal'
import CreatableSelect from 'react-select/creatable';
import InputSelect from '../../InputGroup/InputSelect'
import Dropdown from '../../InputGroup/dropdown'
import InputText from '../../InputGroup/InputText'
import DateTimePicker from '../../InputGroup/DateTime'
import style from '../../../modules/Settings/Projects/style.module.css'
import { ReactComponent as FlagIcon } from '../../../assets/icons/flag.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { toast } from 'react-toastify';

const eventsType = [
    {
        label: 'Time Based',
        value: 'Time Based'
    },
    {
        label: 'Construction Based',
        value: 'Construction Based'
    }
]

const eventDuration = [
    {
        label: 'Days',
        value: 'days'
    },
    {
        label: 'Month',
        value: 'month'
    }

]
const eventStart = [
    {
        label: 'Booking Date',
        value: 'booking'
    },
    {
        label: 'Agreement Date',
        value: 'agreement'
    },
]

const AddMilestoneModal = ({ show, closeModal, descriptionListData, handleAddMilestone, selectedMilestone, setDescriptionListData }) => {
    const [descriptionList, setDescriptionList] = useState([]);
    const [selectAmount, setSelectAmountType] = useState('percent');
    const [timeBasedField, setTimeBasedField] = useState(false);
    const [error, setError] = useState(false);
    const [makeMultiple, setMakeMultiple] = useState(false);
    const [milestones, setMilestones] = useState({
        milestone_id: '',
        description: '',
        payment_percentage: '',
        payment_amount: '',
        due_date: '',
        tentative_due_date: '',
        type: [],
        duration: '',
        duration_unit: 'days',
        start_event: '',
        remarks: ''
    });

    useEffect(() => {
        if (Object.keys(selectedMilestone).length > 0) {
            let type = []
            if (selectedMilestone?.type?.length === 1) {
                type = eventsType.filter(item => item.value === selectedMilestone?.type[0])
            } else if (selectedMilestone?.type?.length === 2) {
                type = [...eventsType]
            }

            if (selectedMilestone?.payment_amount) {
                setSelectAmountType('amount')
            }
            if (selectedMilestone?.payment_payment_percentage) {
                setSelectAmountType('percent')
            }
            if (selectedMilestone?.type?.some(item => item === 'Time Based')) {
                setTimeBasedField(true)
            }
            setMilestones({ ...selectedMilestone, type: type })
        }
    }, [selectedMilestone])

    useEffect(() => {
        if (descriptionListData) {
            setDescriptionList(descriptionListData)
        }
    }, [descriptionListData]);

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[760px] !max-w-[80%] md:!mx-auto !mx-4 '>
            <div className='!mt-6 px-4' id='milestone'>
                <div className='flex justify-between items-center'>
                    <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter flex items-center" >
                        <span className='border border-grayLight p-1 mr-3 rounded-lg'>
                            <FlagIcon />
                        </span>
                        Add Milestone
                    </h5>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>

                <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !pt-5 md:!px-6 !px-4'>
                    {/* <InputTextarea
                                type="text"
                                label="Milestone Description"
                                placeholder="Enter Payment Plan Description"
                                name="description"
                                inputclass='m-0 inter'
                                row='2'
                                value={milestones.description}
                                onChange={(e) => setMilestones({ ...milestones, [e.target.name]: e.target.value })}
                            /> */}
                    {/* <InputSelect
                                label={'Milestone Description'}
                                placeholder='Search & Select Description'
                                onChange={(value) => {
                                    console.log(value, 'value')
                                    setMilestones({
                                        ...milestones,
                                        milestone_id: value.id,
                                        description: value.label,
                                        type: value.type,
                                        duration: value.duration,
                                        duration_unit: value.duration_unit,
                                        start_event: value.start_event
                                    })
                                }}
                                value={milestones?.milestone_id ? descriptionList.find(item => item.id === milestones.milestone_id) : ''}
                                options={descriptionList}
                            // error={errors?.state?.message ? 'Please select State' : ''}
                            /> */}
                    <div className='form-group'>
                        <label htmlFor="">Milestone Name <sup>*</sup></label>
                        <CreatableSelect
                            placeholder='Search & Select Description'
                            onCreateOption={(value) => {
                                setDescriptionList([...descriptionList, {
                                    label: value,
                                    value: value
                                }])
                                setDescriptionListData([...descriptionList, {
                                    label: value,
                                    value: value
                                }])
                                setMilestones({
                                    ...milestones,
                                    description: value,
                                    milestone_id: '',
                                    type: [],
                                    duration: '',
                                    duration_unit: '',
                                    start_event: ''
                                })

                            }}
                            onChange={(value) => {
                                if (value.id) {
                                    const istimeBased = value.type.some(item => item === 'Time Based')
                                    if (istimeBased) {
                                        setTimeBasedField(true);
                                    } else {
                                        setTimeBasedField(false);
                                    }
                                    setMilestones({
                                        ...milestones,
                                        milestone_id: value.id,
                                        description: value.label,
                                        type: value.type.map(i => ({ label: i, value: i })),
                                        duration: value.duration,
                                        duration_unit: value.duration_unit,
                                        start_event: value.start_event
                                    })

                                } else {
                                    setMilestones({
                                        ...milestones,
                                        description: value.label,
                                        milestone_id: null,
                                        type: [],
                                        duration: null,
                                        duration_unit: 'days',
                                        start_event: null
                                    })
                                }
                            }}
                            value={milestones.description ?
                                {
                                    label: milestones.description,
                                    value: milestones.description
                                } : ''}
                            options={descriptionList}
                        // error={errors?.state?.message ? 'Please select State' : ''}
                        />
                        {(error && !milestones.description) && (
                            <div className="input-feedback mt-8">
                                Please Select Description
                            </div>
                        )}
                    </div>

                    <InputSelect
                        label="Milestone Type"
                        placeholder="Select Milestone Type"
                        type="text"
                        options={eventsType}
                        inputclass='m-0 inter'
                        isMulti={true}
                        value={milestones?.type}
                        onChange={(value) => {
                            const istimeBased = value.some(item => item.value === 'Time Based')
                            if (istimeBased) {
                                setTimeBasedField(true);
                            } else {
                                setTimeBasedField(false);
                            }
                            setMilestones({ ...milestones, type: value })
                        }}
                        error={(error && milestones?.type.length === 0) ? 'Please Select Milestone Type' : ''}
                        required={true}
                    />
                    {timeBasedField && <div className='form-group position-relative m-0'>
                        <label >Duration</label>
                        <Dropdown
                            list={eventDuration}
                            onChange={(value) => setMilestones({ ...milestones, duration_unit: value.value })}
                            value={milestones.duration_unit ? eventDuration.find(item => item.value === milestones.duration_unit) : ''}
                            style={style}
                        />
                        <input
                            type='text'
                            className='form-control text-input'
                            style={{ borderColor: (error && !milestones.duration) ? '#FC5A5A' : '' }}
                            placeholder='e.g. 3'
                            value={milestones.duration}
                            onChange={(e) => {
                                let value = e.target.value
                                    .replace(/^0+/g, '')
                                    .replace(/[^0-9]/g, '')
                                setMilestones({ ...milestones, duration: value })
                            }}
                        />
                        {(error && !milestones.duration) && (
                            <div className="input-feedback mt-8">
                                Please Enter Duration
                            </div>
                        )}
                    </div>}
                    {timeBasedField && <InputSelect
                        label="Duration from"
                        placeholder="Enter Duration from"
                        type="text"
                        // name="payment_percentage"
                        inputclass='m-0 inter'
                        options={eventStart}
                        value={milestones?.start_event ? eventStart.find(item => item.value === milestones.start_event) : ''}
                        onChange={(value) => setMilestones({ ...milestones, start_event: value.value })}
                        error={(error && !milestones?.start_event) ? 'Please Select Duration from' : ''}
                    // disable={true}
                    />}

                    {/* {milestones.milestone?.type === 'Time Based' && <InputText
                                label="Duration"
                                placeholder="Enter Total Milestones"
                                type="text"
                                name="payment_percentage"
                                inputclass='m-0 inter'
                                value={milestones.milestone?.duration}
                                onChange={(e) => setMilestones({ ...milestones, [e.target.name]: e.target.value })}
                                disable={true}
                            />} */}
                    <div className='form-group m-0'>
                        <label htmlFor="">Part payment</label>
                        <div className='flex items-center justify-between'>
                            <div className=''>
                                <input type="radio" name='payment' value='percent' id='percent' checked={selectAmount === 'percent'} onChange={() => setSelectAmountType('percent')} />
                                <label htmlFor="percent" className='ml-2'>% of total payment</label>
                            </div>
                            <div>
                                <input type="radio" name='payment' id='amount' value='amount' checked={selectAmount === 'amount'} onChange={() => setSelectAmountType('amount')} />
                                <label htmlFor="amount" className='ml-2'>Absloute</label>
                            </div>
                        </div>
                        {selectAmount === 'percent' && <InputText
                            placeholder="Enter % of total payment"
                            type="text"
                            name="payment_percentage"
                            inputclass='m-0 inter'
                            value={milestones.payment_percentage}
                            onChange={(e) => setMilestones({ ...milestones, [e.target.name]: e.target.value })}
                            error={(error && !milestones?.payment_percentage) ? 'Please Enter Payment %' : ''}
                        />}
                        {selectAmount === 'amount' && <InputText
                            placeholder="Enter Payment Amount"
                            type="text"
                            name="payment_amount"
                            inputclass='m-0 inter'
                            value={milestones.payment_amount}
                            // showRupees={true}
                            onChange={(e) => {
                                let value = e.target.value
                                    .replace(/^0+/g, '')
                                    .replace(/[^0-9]/g, '')
                                if (value) {
                                    setMilestones({ ...milestones, [e.target.name]: value })
                                }
                            }}
                            error={(error && !milestones?.payment_amount) ? 'Please Enter Payment Amount' : ''}
                        />}
                    </div>
                    {/* <div className='form-group'>

                        
                    </div> */}
                    {/* <DateTimePicker
                        label='Collection Due Date'
                        inputclass='mt-0'
                        placeholder='Select Date'
                        name='due_date'
                        value={milestones.due_date}
                        showTime={false}
                        onValueChange={(date) => setMilestones({ ...milestones, ['due_date']: date })}
                    // error={(error && !milestones?.due_date) ? 'Please Select Collection Due Date' : ''}
                    /> */}
                    <div>
                        <div className='flex items-center justify-between'>
                            <label className='m-0'>Description</label>
                            <button
                                type='button'
                                className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                onClick={() => {
                                    setMilestones({ ...milestones, ['remark']: '' })
                                }}
                            >Clear <CloseIcon className='h-3 w-3' /></button>
                        </div>
                        <textarea
                            className="form-control mb-16"
                            name="remarks"
                            value={milestones?.remarks || ''}
                            placeholder="Type a shot description..."
                            onChange={(e) => {
                                setMilestones({ ...milestones, [e.target.name]: e.target.value })
                            }}
                        >
                        </textarea>
                    </div>
                    {/* <DateTimePicker
                        label='Tentative Due Date'
                        inputclass='mt-0'
                        placeholder='Select Date'
                        name='tentative_due_date'
                        value={milestones.tentative_due_date}
                        showTime={false}
                        onValueChange={(date) => setMilestones({ ...milestones, ['tentative_due_date']: date })}
                    // error={(error && !milestones?.tentative_due_date) ? 'Please Select Tentative Due Date' : ''}
                    /> */}
                </div>

            </div>
            <div className='!px-6 !py-4 !mt-5 flex gap-3 items-center justify-between shadow-topshadow'>
                <div>

                    <label htmlFor="" className='mr-2'>Make Multiple</label>
                    <button
                        type="button"
                        className={`btn btn-lg btn-toggle ${makeMultiple ? 'active' : ''} mr-2`}
                        value={makeMultiple}
                        onClick={() => {
                            setMakeMultiple(!makeMultiple)
                        }}
                    >
                        <div className="handle"></div>
                        <span className={`${makeMultiple ? 'on' : 'off'}`}>{makeMultiple ? 'ON' : 'OFF'}</span>
                    </button>
                </div>
                <div>
                    <button
                        className='text-base mr-2 font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center'
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center'
                        // disabled={.milestones.length === 0}
                        onClick={() => {
                            if (
                                !milestones.description ||
                                (selectAmount === 'percent' && !milestones.payment_percentage) ||
                                (selectAmount === 'amount' && !milestones.payment_amount) ||
                                // !milestones.due_date ||
                                // !milestones.tentative_due_date ||
                                !milestones.type
                            ) {
                                setError(true);
                                return;
                            } else {
                                setError(false);
                            }
                            if (timeBasedField && (!milestones.duration ||
                                !milestones.duration_unit ||
                                !milestones.start_event)) {
                                setError(true);
                                return;
                            } else {
                                setError(false);
                            }
                            const payload = {
                                ...milestones,
                                type: milestones.type.map(item => item.value)
                            }
                            handleAddMilestone(payload);
                            if (makeMultiple) {
                                toast.success('Milestone added.')
                                setMilestones({
                                    milestone_id: '',
                                    description: '',
                                    payment_percentage: '',
                                    payment_amount: '',
                                    due_date: '',
                                    tentative_due_date: '',
                                    type: [],
                                    duration: '',
                                    duration_unit: 'days',
                                    start_event: ''
                                })
                            } else {
                                closeModal();
                            }
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>
        </BasicModal>
    )
}

export default AddMilestoneModal