import FinancialDetails from "../modules/Settings/Financial Details";
import PersonalDetails from "../modules/Settings/Personal Details";
import ReferAndEarn from "../modules/Settings/Refer";
import Subscription from "../modules/Settings/Subscriptions";

export const settingsBaseUrl = "/settings";

export const settingsRoutes = [
  {
    name: "Personal Details",
    url: "my-profile",
    component: PersonalDetails,
  },
  {
    name: "Financial Details",
    url: "financial-details",
    component: FinancialDetails,
  },
  // {
  //   name: "Subscription Invoice",
  //   url: "invoice",
  //   component: Subscription,
  // },
  {
    name: "Refer and Earn",
    url: "refer",
    component: ReferAndEarn,
  }

]

// export const projectURL = {
//   name: "Projects",
//   url: "projects",
//   childRoutes: [
//     {
//       name: "Project Manager",
//       url: "project-manager",
//       component: Projects,
//     },
//     // {
//     //   name: "Create Project",
//     //   url: "create-projects",
//     //   component: CreateProjects,
//     // },
//     {
//       // name: "Basic Details",
//       url: "create-projects/basic-details",
//       component: BasicDetails,
//     },
//     {
//       // name: "Basic Details",
//       url: "create-projects/basic-details/:uuid",
//       component: BasicDetails,
//     },

//     {
//       // name: "Media Upload",
//       url: "create-projects/mediaupload/:uuid",
//       component: MediaUpload,
//     },
//     {
//       // name: "Additional Info",
//       url: "create-projects/additional-info/:uuid",
//       component: AdditionalInfo,
//     },
//     {
//       // name: "Additional Info",
//       url: "create-projects/team/:uuid",
//       component: Team,
//     },
//     {
//       // name: "Team Info",
//       url: "create-projects/team-info/:uuid",
//       component: TeamInfo,
//     },
//     {
//       // name: "Payment Plan",
//       url: "create-projects/payment-plan/:uuid",
//       component: PaymentPlan,
//     },

//     {
//       // name: "Payment Plan",
//       url: "create-projects/construction-updates/:uuid",
//       component: ConstructionUpdate,
//     },
//     {
//       // name: "Payment Plan",
//       url: "create-projects/pricing/:uuid",
//       component: PricingModule,
//     },
//   ],
// }

