export const statusEnum = {
    NOT_CONNECTED: 'Not Connected',
    IN_PROGRESS: 'In Progress',
    SV_SCHEDULES: 'SV Scheduled',
    SV_COMPLETED: 'SV Completed',
    EOI_COMPLETED: 'EOI Completed',
    BOOKING_COMPLETED: 'Booking Completed',
}

export const eventEnum = {
    CREATE_FOLLOWUP: 'CREATE_FOLLOWUP',
    CREATE_SV: 'CREATE_SV',
    DEAD_LEAD_REMARK: 'DEAD_LEAD_REMARK'
}

export const intentEnum = {
    New_Lead: 'New Lead',
    Hot: 'Hot Lead',
    Warm: 'Warm Lead',
    Cold: 'Cold Lead',
    Dead: 'Dead Lead',
}

export const leadTypesEnum = {
    ALL_LEADS: 'all_leads',
    PENDING_LEADS: 'pending-leads',
    FOLLOWUPS: 'followups',
    SITE_VISITS: 'site_visits',
    BOOKINGS: 'bookings',
    NEW_LEADS: 'new_leads',
    DEAD_LEAD: 'dead_leads',
    NEWBOOKINGS: 'booking_only',
    NEWEOI: 'eoi_only',
    FINAL_NEGOTIATION: 'final_negotiation'
}