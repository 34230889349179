import React from 'react'
import CloseIcon from './Icons/CloseIcon'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Category = ({
    categories,
    categoryclassName,
    defaultclass,
    handleSelectCategory,
    handleRemoveCategory,
    selectedCategories
}) => {

    console.log(selectedCategories)
    const isCategorySelected = (category) => {
        const index = selectedCategories?.findIndex(item => item.id === category.id);
        // console.log(index)
        return index === -1 ? false : true;
    }

    return (
        <div className={`${categoryclassName} ${defaultclass}`}>
            <OwlCarousel
                className='owl-theme'
                dots={false}
                res='true'
                loop={false}
                navText={["<img src='/owlleft.svg' /> <img src='/hoverleft.svg' />", "<img src='/right.svg' /> <img src='/hoverright.svg' />"]}
                margin={10}
                autoWidth={true}
                responsive={{
                    0: {
                        items: 2,
                        nav: true,
                        margin: 18

                    },
                    600: {
                        items: 3,
                        nav: true,
                        margin: 18
                    },
                    900: {
                        items: 4,
                        nav: true,
                        margin: 18
                    },
                    1000: {
                        items: 5,
                        nav: true,
                        margin: 18
                    },
                    1200: {
                        items: 3,
                        loop: false,
                        autoWidth: true,
                        nav: true,
                        margin: 18

                    }
                }
                }>
                {categories.map((category, index) => (
                    <div className="item" key={`${category.id}_${index}`}>
                        <div className={`tag-group ${isCategorySelected(category) ? 'active' : ''}`} >
                            <div onClick={() => handleSelectCategory(category)}>{category.name}</div>
                            {isCategorySelected(category) &&
                                <button className='border-0 p-0 bg-tr' onClick={() => handleRemoveCategory(category)}>
                                    <CloseIcon />
                                </button>}
                        </div>
                    </div>
                ))
                }
                {/* <div className="item">
                    <div className="tag-group"> Upcoming <button className='border-0 p-0 bg-tr'><CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group"> Beginner Workshops < button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group"> Real estate <button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group"> Build successful < button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group">
                        <span>Business</span>
                        <button className='border-0 p-0 bg-tr'>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
                <div className="item">
                    <div className="tag-group"> How to videos < button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group"> How to videos < button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group"> How to videos < button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group"> How to videos < button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div>
                <div className="item">
                    <div className="tag-group"> How to videos < button className='border-0 p-0 bg-tr'> <CloseIcon /></button></div>
                </div> */}
            </OwlCarousel >

        </div >
    )
}

Category.defaultProps = {
    defaultclass: 'Lctags',
    categoryclassName: ''
}

export default Category
