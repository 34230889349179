import React from 'react'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as CloseSearchIcon } from '../../assets/icons/searchClose.svg';

const SearchInput = ({ label, placeholder, searchText, onChange, handelClear, disabled }) => {
    return (
        <div className='form-group'>
            {label && <label htmlFor="">{label}</label>}
            <div className={`search_input position-relative`}>
                <div className="search_icon"><SearchIcon /></div>
                <input
                    type="text"
                    className={`${searchText ? 'border-primary' : ''} form-control search_input`}
                    onChange={onChange}
                    value={searchText}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {searchText && <button className='bg-tr p-0 border-0 search_close' onClick={handelClear}><CloseSearchIcon /></button>}
            </div>
        </div>
    )
}

export default SearchInput
