import React from 'react';
import Icontwo from '../../../assets/pc2.png';
import IconThree from '../../../assets/pc3.png';
import Iconone from '../../../assets/pc1.png';
import { ReactComponent as PropacityLogo } from '../../../assets/propacityplush.svg';
import { ReactComponent as SucessIocn } from '../../../assets/sucess.svg';
import CenterModal from '../../../components/Modals/center-modal';
import { useHistory } from 'react-router-dom';

const PaymentSuccessModal = ({ show, closeModal, paymentFrom, redirectTo }) => {
    const history = useHistory();

    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!w-[560px]  [&>*]:!border-0 [&>*]:!rounded-xl [&>*]:!overflow-hidden'>
            <div className={`bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-14 pb-8 !px-6 grow-0 shrink-0 w-11/12 shadow-shadowcard mt-10  `}>
                <div className=''>
                    <div className='flex justify-center '>
                        <figure className='!mb-5'><SucessIocn className='h-16 w-16' /></figure>
                    </div>
                    <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>

                    <div className='!py-4 !px-5 !border border-black600 rounded-xl'>
                        <h2 className='md:text-xl text-base font-semibold text-textblack !mb-4'>🥳 Congrats you have unlocked premium offer! </h2>

                        <div className='flex flex-col md:gap-y-3 !gap-y-4'>
                            <div className='flex items-center !gap-4'>
                                <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={Iconone} alt="" /></div>
                                <p className='m-0 text-black700 text-sm font-medium'>Unlock unlimited live and pre-recorded sessions, courses</p>
                            </div>
                            <div className='flex items-center !gap-4'>
                                <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={Icontwo} alt="" /></div>
                                <p className='m-0 text-black700 text-sm font-medium'>Engage with industry experts to get personalised insights and guidance.</p>
                            </div>
                            <div className='flex items-center !gap-4'>
                                <div className='w-12 h-12 grow-0 shrink-0 basis-12'><img src={IconThree} alt="" /></div>
                                <p className='m-0 text-black700 text-sm font-medium'>Cancel subscription at anytime</p>
                            </div>
                        </div>
                    </div>
                    <div className='!mt-6'>
                        <button
                            className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-3 flex justify-center'
                            onClick={() => {
                                if (redirectTo) {
                                    history.push(redirectTo);
                                } else {
                                    history.push('/learning-center');
                                }
                                // if (paymentFrom !== 'modal') {
                                window.location.reload();
                                // }
                                closeModal()
                            }}
                        >
                            Great!
                        </button>
                    </div>
                </div>
            </div>
        </CenterModal>
    )
}

export default PaymentSuccessModal