import React, { useEffect, useState } from 'react'

import { BankList, PaidBy, PaymentModeList } from '../../../helpers/enums';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import pdfimg from '../../../assets/icons/pdfview.jpg';
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import { toNumber } from '../../../helpers/helpers';
import { toast } from 'react-toastify';
import DateTimePicker from '../../InputGroup/DateTime';
import InputText from '../../InputGroup/InputText';
import InputRupees from '../../InputGroup/InputRupees';
import InputSelect from '../../InputGroup/InputSelect';
import { uploadFile } from '../../../services/public/public.service';
import { eoiSaveTransaction } from '../../../services/private/inventory.service';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const EOIPaymentProof = ({ setSteps, selectedBooking, eoiId, selectedProject }) => {
    const [paymentProof, setPaymentProof] = useState([{}]);
    const [paymentDocs, setPaymentDocs] = useState({});
    const [selectedDemand, setSelectedDemand] = useState({})

    useEffect(() => {
        if (selectedBooking && Object.keys(selectedBooking).length > 0) {
            const { transactions } = selectedBooking;
            setPaymentProof(transactions?.length > 0 ? transactions : [{}]);
            transactions?.forEach((item, index) => {
                let payment = { ...paymentDocs };
                payment[index] = item.documents;
                setPaymentDocs(payment);
            })
        }
    }, [selectedBooking]);

    const uploadFileToS3 = async (file, fileIndex, docs, setDocs, docIndex) => {
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: 'Payment Proof'
            }
            let newDocs = { ...docs };
            if (!newDocs[fileIndex]) {
                newDocs[fileIndex] = [];
                newDocs[fileIndex].push(newFile);
            } else {
                if (docIndex > -1) {
                    newDocs[fileIndex][docIndex] = newFile;
                } else {
                    newDocs[fileIndex].push(newFile);
                }
            }
            setDocs(newDocs);

        }
    }

    const handleOnChange = async (e, fileIndex, docs, setDocs, docIndex) => {
        const files = [...e.target.files];
        files.forEach((file, index) => {
            uploadFileToS3(file, fileIndex, docs, setDocs, docIndex);
        });
    }

    const handleSubmit = async () => {
        let transaction = paymentProof.filter(item => Object.keys(item).length > 0);
        if (transaction.length === 0) {
            toast.warning('Please fill payment proof details.');
            return;
        }
        transaction.forEach((item, index) => {
            if (!item.paid_by || !item.amount || !item.mode) {
                toast.warning('Please fill payment proof details.');
                return;
            }
        })
        transaction.forEach((item, index) => {
            item.documents = paymentDocs[index]
        })

        const payload = {
            token_amount: transaction.length > 0 ? transaction.reduce((accumulator, object) => {
                return parseInt(accumulator) + parseInt(object.amount);
            }, 0) : 0,
            transaction: transaction.length > 0 ? transaction : null
        }
        const res = await eoiSaveTransaction(eoiId, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message)
            setSteps('EOIOther');
        }
    }

    return (
        <>

            <div className='!pt-5 sm:!px-6 px-3 pb-8 h-full overflow-auto'>
                <div className=''>
                    <h2 className='m-0 text-textblack text-lg font-semibold inter'>Payment Proof for ({selectedProject?.name})</h2>
                </div>
                {
                    paymentProof.map((proof, index) => (
                        <div className='!mt-4' key={String(index)}>
                            <div className='!mb-3 flex justify-between'>
                                <h5 className="text-textblack text-sm leading-6 font-semibold tracking-[-0.2px]" >
                                    Payment Proof {index + 1}
                                </h5>
                                {paymentProof.length > 1 &&
                                    <button className='border-0 bg-tr ' onClick={() => {
                                        const paymens = [...paymentProof];
                                        paymens.splice(index, 1)
                                        setPaymentProof(paymens);
                                    }}
                                    >
                                        <CloseIcon />
                                    </button>}
                            </div>
                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                <InputSelect
                                    label="Paid By"
                                    placeholder="Enter Paid By"
                                    type="text"
                                    name='paid_by'
                                    value={proof?.paid_by ? PaidBy.find(item => item.value === proof?.paid_by) : ''}
                                    inputclass='m-0 inter'
                                    onChange={(value) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index]['paid_by'] = value.value;
                                        setPaymentProof(payProofs)
                                    }}
                                    options={PaidBy}
                                    required={true}
                                />
                                <InputSelect
                                    label='Mode Of Payment'
                                    placeholder='Select Mode Of Payment'
                                    inputclass='m-0 inter'
                                    isMulti={false}
                                    value={proof?.mode ? PaymentModeList.find(item => item.value === proof?.mode) : ''}
                                    options={PaymentModeList}
                                    styles={customStyles}
                                    onChange={(value) => {
                                        let proofs = [...paymentProof];
                                        proofs[index]['mode'] = value.value;
                                        setPaymentProof(proofs)
                                    }}
                                    required={true}
                                />
                                <InputRupees
                                    label="Token Amount"
                                    placeholder="Enter Amount"
                                    type="text"
                                    name='amount'
                                    value={proof?.amount}
                                    inputclass='m-0 inter'
                                    showRupees={true}
                                    onChange={(value) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index]['amount'] = value
                                        setPaymentProof(payProofs)
                                    }}
                                    required={true}
                                    handleBlur={() => { }}
                                />
                                {proof?.mode === 'cheque' && <InputText
                                    label="Cheque No."
                                    placeholder="Enter Cheque No."
                                    type="text"
                                    name='check_no'
                                    value={proof?.check_no}
                                    inputclass='m-0 inter'
                                    onChange={(e) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index][e.target.name] = e.target.value;
                                        setPaymentProof(payProofs)
                                    }}
                                />}
                                <InputText
                                    label="Transaction Id"
                                    placeholder="Enter Transaction Id"
                                    type="text"
                                    name='transaction_id'
                                    value={proof?.transaction_id}
                                    inputclass='m-0 inter'
                                    onChange={(e) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index][e.target.name] = e.target.value;
                                        setPaymentProof(payProofs)
                                    }}
                                />
                                <DateTimePicker
                                    label='Transaction Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    value={proof?.transaction_date}
                                    onValueChange={(date) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index]['transaction_date'] = date;
                                        setPaymentProof(payProofs)
                                    }}
                                    showTime={false}
                                />
                                {proof?.mode === 'cheque' && <DateTimePicker
                                    label='Cheque Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    value={proof?.cheque_date}
                                    onValueChange={(date) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index]['cheque_date'] = date;
                                        setPaymentProof(payProofs)
                                    }}
                                    showTime={false}
                                />}
                                <DateTimePicker
                                    label='Amount Received Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    value={proof?.amount_received_date}
                                    onValueChange={(date) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index]['amount_received_date'] = date;
                                        setPaymentProof(payProofs)
                                    }}
                                    showTime={false}
                                />
                                <InputSelect
                                    label="Bank Name"
                                    placeholder="Enter Bank Name"
                                    type="text"
                                    name='bank_name'
                                    value={proof?.receiver_bank_name ? BankList.find(item => item.value === proof?.receiver_bank_name) : ''}
                                    inputclass='m-0 inter'
                                    onChange={(value) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index]['receiver_bank_name'] = value.value;
                                        setPaymentProof(payProofs)
                                    }}
                                    options={BankList}
                                />
                                {proof?.mode !== 'upi' && <InputText
                                    label="Branch Name"
                                    placeholder="Enter Branch Name"
                                    type="text"
                                    name='receiver_bank_branch'
                                    value={proof?.receiver_bank_branch}
                                    inputclass='m-0 inter'
                                    onChange={(e) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index][e.target.name] = e.target.value;
                                        setPaymentProof(payProofs)
                                    }}
                                />}
                                {proof?.mode !== 'upi' && <InputText
                                    label="IFSC Code"
                                    placeholder="Enter IFSC Code"
                                    type="text"
                                    name='receiver_ifsc_code'
                                    value={proof?.receiver_ifsc_code}
                                    inputclass='m-0 inter'
                                    onChange={(e) => {
                                        let payProofs = [...paymentProof];
                                        payProofs[index][e.target.name] = e.target.value;
                                        setPaymentProof(payProofs)
                                    }}
                                />}

                                <div>
                                    <label className='text-sm text-black700 !mb-2 inter'>Payment Proof</label>
                                    <div className='relative group border border-grayLight h-[154px] rounded-lg flex items-center justify-center overflow-hidden cursor-pointer'>
                                        <input type="file" className='d-none' id={`upload-payment-proof-${index}`} accept="application/pdf, image/*" multiple onChange={(e) => handleOnChange(e, index, paymentDocs, setPaymentDocs)} />
                                        <button
                                            type='button'
                                            className='!bg-primary flex items-center justify-center py-1 px-2 text-white gap-1 rounded-lg text-sm font-medium'
                                            onClick={() => {
                                                document.getElementById(`upload-payment-proof-${index}`).click();
                                            }}
                                        >
                                            <Pluswhite /> Upload
                                        </button>

                                        {/* <div className="group-hover:flex absolute left-0 right-0 top-0 bottom-0 w-100 h-100 bg-black bg-opacity-30 hidden items-center justify-center">
                                                        <div className='flex items-end gap-x-1 relative '>
                                                            <button type='button' className='py-1 px-2.5 text-white text-xs font-medium  !bg-primary rounded-md  tracking-[0.5px] inline-flex items-center inter'><Pluswhite /> Replace</button>
                                                            <button type='button' className='trashbtn w-8 h-8 bg-white rounded-md inline-flex items-center justify-center'><TrashIcon /></button>
                                                        </div>
                                                    </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='!mt-4'>
                                <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                                    {paymentDocs[index]?.length > 0 && paymentDocs[index].map((doc, docIndex) => (
                                        <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                            <input type="file" className='d-none' id={`replace-payment-proof-${docIndex}`} accept="application/pdf, image/*" multiple onChange={(e) => handleOnChange(e, index, paymentDocs, setPaymentDocs, docIndex)} />
                                            <figure className='mb-0 !table !mx-auto relative group'>
                                                {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                    <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                    : <img src={pdfimg} />}
                                                <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                    <button
                                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                        onClick={() => {
                                                            document.getElementById(`replace-payment-proof-${docIndex}`).click();
                                                        }}
                                                    ><PluswhiteIcon />Replace</button>

                                                    <button
                                                        className={`${style.delbtn} inter border-0 bg-white`}
                                                        onClick={() => {
                                                            let newFiles = { ...paymentDocs };
                                                            delete newFiles[index]
                                                            setPaymentDocs(newFiles);

                                                        }}
                                                    ><TrashIcon /></button>
                                                </div>
                                            </figure>
                                            <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {doc.name}</p>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className="!mt-3">
                    <button
                        type='button'
                        className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'
                        onClick={() => {
                            setPaymentProof([...paymentProof, {}])
                        }}
                    >
                        <PlusPrimary /> Add Payment Proof</button>
                </div>
            </div>
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-end shadow-topshadow sm:flex-row flex-col'>
                <div className='flex gap-3 items-center justify-end shadow-topshadow'>
                    <button type='button' className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black w-[128px] h-[44px] text-center' onClick={() => setSteps('EOIPaymentplan')}>Previous</button>
                    <button onClick={handleSubmit} className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center inter'>Next</button>
                </div>
            </div>
        </>
    )
}

export default EOIPaymentProof;