import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getDisplayDateTime } from '../../../helpers/helpers';
import { removeNoteCall, updateNoteCall } from '../../../services/private/leads.service';
import ConfirmDeletion from '../../Modals/ConfirmDeletion';
import { useEffect } from 'react';
import TooltipText from '../../Tooltip';

export default function DisplayNote({
    note,
    reloadAllNotes,
    index,
    notes,
    userProfile,
    leadAccess,
    isRolesModule,
    allowedPermissions
}) {

    const [editEnabled, setEditEnabled] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [description, setDescription] = useState()
    const [error, setError] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [btnDisable, setBtnDisable] = useState(false);

    const updateNoteHandler = () => {
        if (!description) {
            setError(true);
            return false;
        } else {
            setError(false);
        }
        setBtnDisable(true);

        updateNoteCall(note.uuid, description).then(res => {
            if (res.status === 200) {
                toast.success("Note Updated")
                setEditEnabled(false)
                setBtnDisable(false)
                reloadAllNotes();
            }
        })
    }

    const deleteNoteHanlder = () => {
        removeNoteCall(note.uuid).then(res => {
            if (res.status === 200) {
                toast.success("Note Deleted")
                reloadAllNotes();
                setDeleteModal(false)
            }
        })
    }

    // useEffect(() => {
    //     if (editIndex) {
    //         console.log(notes[editIndex].description, 'decs')
    //         setDescription(notes[editIndex].description)
    //     }
    // }, [editIndex])

    return (
        <>
            <div className={`${editEnabled ? 'active' : ''} card-activity  mb-20 py-12 px-20`}>
                {/* edit box */}
                {editEnabled && editIndex === index ?
                    <div className={`edit-follow notes-edit w-100`}>
                        <div className="part-tow">
                            <div className="row align-items-center mb-8">
                                <div className="col-6">
                                    <h2 className='black-dark-700 fz16 mb-0 fw-po-medium po flex items-center'>
                                        <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7 1H15.1482C15.7514 1 16.3224 1.27227 16.7022 1.74098L20.554 6.49524C20.8425 6.8514 21 7.29587 21 7.75425V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5C3 2.79086 4.79086 1 7 1ZM15.625 3.58854V6.4H17.9028L15.625 3.58854ZM7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.4H15.625C14.5204 8.4 13.625 7.50457 13.625 6.4V3H7Z" fill="#FF974A" />
                                        </svg>
                                        Note</h2>
                                </div>
                            </div>
                            <div className="edit-textarea">
                                <textarea name="" id="" cols="30" rows="2" className='form-control border-0' placeholder="Enter your note..." value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                {error && <p className="text-danger">Note is required.</p>}
                            </div>

                            <div className="save-cancel-btn d-flex aling-items-center justify-content-end">
                                <button className='cancel-btn outline-btn' onClick={() => setEditEnabled(false)}>Cancel</button>
                                <button className='save-btn pr-btn' onClick={updateNoteHandler} disabled={btnDisable}>Save</button>
                            </div>
                        </div>
                    </div>
                    :
                    <>

                        <div className="row">
                            <div className="col-6">
                                <h2 className="black-dark-700 fz16 mb-0 fw-po-medium po flex items-center">
                                    <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7 1H15.1482C15.7514 1 16.3224 1.27227 16.7022 1.74098L20.554 6.49524C20.8425 6.8514 21 7.29587 21 7.75425V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5C3 2.79086 4.79086 1 7 1ZM15.625 3.58854V6.4H17.9028L15.625 3.58854ZM7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.4H15.625C14.5204 8.4 13.625 7.50457 13.625 6.4V3H7Z" fill="#FF974A"></path></svg>
                                    Note
                                </h2>
                            </div>
                            <div className="col-6">
                                <div className="edit-delte d-flex flex-row align-items-center justify-content-end">
                                    {/* Edit button */}
                                    <button className="bg-tr mr-2 border-0 pr-hover" onClick={() => {
                                        setEditEnabled(true);
                                        setEditIndex(index)
                                        setDescription(notes[index].description)
                                    }}
                                        disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view')}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.8841 6.33009L15.8131 5.40113L13.7655 3.35357L12.8365 4.28254L14.8841 6.33009ZM13.7118 7.5024L11.6642 5.45485L4.00467 13.1144L3.53341 15.6332L6.05222 15.162L13.7118 7.5024ZM17.0349 4.27833C17.655 4.89843 17.655 5.90382 17.0349 6.52393L7.04308 16.5157C6.92487 16.6339 6.77369 16.7136 6.60937 16.7444L2.64842 17.4855C2.07565 17.5926 1.574 17.091 1.68116 16.5182L2.42224 12.5573C2.45299 12.3929 2.53269 12.2418 2.6509 12.1236L12.6427 2.13175C13.2628 1.51165 14.2682 1.51165 14.8883 2.13175L17.0349 4.27833ZM16.6749 17.5H9.99171C8.8916 17.5 8.8916 15.8333 9.99171 15.8333H16.6749C17.775 15.8333 17.775 17.5 16.6749 17.5Z" fill="#92929D" />
                                        </svg>
                                    </button>
                                    {/* Delete button */}
                                    {/* data-toggle="modal" data-target="#deleteNoteModal" */}

                                    {((userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view') ||
                                        (isRolesModule && allowedPermissions.includes('delete_notes'))) ?
                                        <TooltipText title={"You don't have delete permission"}>
                                            <button
                                                className="bg-tr border-0 del-hover"
                                                data-toggle="modal" data-target="#deleteNoteModal"
                                                disabled={true}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.38605 3.91666L6.94612 2.23647C7.05954 1.89619 7.37799 1.66666 7.73668 1.66666H12.2367C12.5954 1.66666 12.9138 1.89619 13.0273 2.23647L13.5873 3.91666H17.4867C17.9469 3.91666 18.32 4.28976 18.32 4.75C18.32 5.21024 17.9469 5.58333 17.4867 5.58333H16.7724L16.1609 15.9801C16.0831 17.3015 14.9889 18.3333 13.6652 18.3333H6.30819C4.9845 18.3333 3.89024 17.3015 3.81251 15.9801L3.20093 5.58333H2.49996C2.03972 5.58333 1.66663 5.21024 1.66663 4.75C1.66663 4.28976 2.03972 3.91666 2.49996 3.91666H6.38605ZM8.14287 3.91666H11.8305L11.6361 3.33333H8.33732L8.14287 3.91666ZM15.1029 5.58333H4.87048L5.4763 15.8823C5.50221 16.3227 5.86696 16.6667 6.30819 16.6667H13.6652C14.1064 16.6667 14.4712 16.3227 14.4971 15.8823L15.1029 5.58333ZM11.405 7.69802C11.4337 7.23867 11.8293 6.88958 12.2887 6.91829C12.748 6.947 13.0971 7.34264 13.0684 7.80198L12.6934 13.802C12.6647 14.2613 12.269 14.6104 11.8097 14.5817C11.3504 14.553 11.0013 14.1574 11.03 13.698L11.405 7.69802ZM8.9434 13.698C8.9721 14.1574 8.62301 14.553 8.16367 14.5817C7.70433 14.6104 7.30868 14.2613 7.27997 13.802L6.90497 7.80198C6.87627 7.34264 7.22536 6.947 7.6847 6.91829C8.14404 6.88958 8.53969 7.23867 8.5684 7.69802L8.9434 13.698Z" fill="#92929D" />
                                                </svg>
                                            </button>
                                        </TooltipText>
                                        :
                                        <button
                                            className="bg-tr border-0 del-hover"
                                            data-toggle="modal" data-target="#deleteNoteModal"
                                            onClick={() => setDeleteModal(true)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.38605 3.91666L6.94612 2.23647C7.05954 1.89619 7.37799 1.66666 7.73668 1.66666H12.2367C12.5954 1.66666 12.9138 1.89619 13.0273 2.23647L13.5873 3.91666H17.4867C17.9469 3.91666 18.32 4.28976 18.32 4.75C18.32 5.21024 17.9469 5.58333 17.4867 5.58333H16.7724L16.1609 15.9801C16.0831 17.3015 14.9889 18.3333 13.6652 18.3333H6.30819C4.9845 18.3333 3.89024 17.3015 3.81251 15.9801L3.20093 5.58333H2.49996C2.03972 5.58333 1.66663 5.21024 1.66663 4.75C1.66663 4.28976 2.03972 3.91666 2.49996 3.91666H6.38605ZM8.14287 3.91666H11.8305L11.6361 3.33333H8.33732L8.14287 3.91666ZM15.1029 5.58333H4.87048L5.4763 15.8823C5.50221 16.3227 5.86696 16.6667 6.30819 16.6667H13.6652C14.1064 16.6667 14.4712 16.3227 14.4971 15.8823L15.1029 5.58333ZM11.405 7.69802C11.4337 7.23867 11.8293 6.88958 12.2887 6.91829C12.748 6.947 13.0971 7.34264 13.0684 7.80198L12.6934 13.802C12.6647 14.2613 12.269 14.6104 11.8097 14.5817C11.3504 14.553 11.0013 14.1574 11.03 13.698L11.405 7.69802ZM8.9434 13.698C8.9721 14.1574 8.62301 14.553 8.16367 14.5817C7.70433 14.6104 7.30868 14.2613 7.27997 13.802L6.90497 7.80198C6.87627 7.34264 7.22536 6.947 7.6847 6.91829C8.14404 6.88958 8.53969 7.23867 8.5684 7.69802L8.9434 13.698Z" fill="#92929D" />
                                            </svg>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="desc position-relative mt-8">
                                    {/* <h1 className="fz14 black po mb4">{note.name}</h1> */}
                                    <p className="fz16 ro black fw-regular mb-8">{note.description}</p>
                                    <div className="due fz12 mb-0 black-dark-700">{note.name} • {moment(note.updated_on).format("hh:mm a, Do MMM YYYY")} </div>
                                </div>

                            </div>
                        </div>
                    </>
                }
            </div>
            <ConfirmDeletion
                show={deleteModal}
                setShow={setDeleteModal}
                deleteHandler={deleteNoteHanlder}
                deleteText="Note"
            />
        </>
    )
}