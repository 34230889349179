import React, { useContext, useEffect, useState } from 'react'
import BasicModal from '../../../components/Modals/BasicModal'
import InputSelect from '../../../components/InputGroup/InputSelect'
import InputTextarea from '../../../components/InputGroup/InputTextarea'
import StatusDropDown from '../../../components/Custom Table/TableColumns/StatusDropDown'
import { getSelectedValue } from '../../../utils/selectedOptionUtils'
import Consumer from '../../../helpers/context';
import { getDeadReasons } from '../../../services/public/public.service'
import { EOICancelReasonList } from '../../../utils/constants'

const CancelBooking = ({ show, closeModal, data, ModalType, handleSubmit, updateData }) => {
    const [intentStatuses, setIntentStatuses] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [reason, setReason] = useState('');
    const [remarks, setRemarks] = useState('');
    const [leadStatus, setLeadStatus] = useState('');
    const [reasonList, setReasonList] = useState([])

    const { companyConfig } = useContext(Consumer);

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setIntentStatuses(companyConfig.Intent)
            const mappedStatuses = companyConfig.Status.filter(item => (item.label !== 'Booking Completed' && item.label !== 'EOI Completed'))
            setStatuses(mappedStatuses)

        }
    }, [companyConfig]);

    const getDeadReasonsStatus = () => {
        getDeadReasons().then(res => {
            if (res.status === 200) {
                const reasonsData = res.data.data.map((data) => {
                    return {
                        label: data.reason,
                        value: data.reason
                    }
                })
                setReasonList(reasonsData);
            }
        })
    }

    useEffect(() => {
        getDeadReasonsStatus()
    }, []);

    return (
        <BasicModal show={show} closeModal={closeModal}>
            <div className='p-3'>
                <p className='m-0 text-xl font-semibold text-textblack inter capitalize'>Cancel {ModalType}?</p>
                <p className='m-0 text-sm text-black700'>
                    Please select the state before canceling the {ModalType} from the below list.
                </p>
                <div className='grid grid-cols-1 gap-3 mt-3'>
                    <InputSelect
                        label={'Reason'}
                        placeholder={'Select Reason'}
                        value={reason ? EOICancelReasonList.find(item => item.value === reason) : ''}
                        onChange={(value) => {
                            setReason(value.value);
                        }}
                        options={EOICancelReasonList}
                    />
                    <InputTextarea
                        type="text"
                        label="Comments"
                        placeholder="Enter Comments"
                        name="name"
                        inputclass='m-0 inter'
                        row='2'
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                    <div className=''>
                        <label htmlFor="">Status</label>
                        <StatusDropDown
                            value={getSelectedValue((leadStatus || data.status), statuses)}
                            options={statuses}
                            id={data.id}
                            reloadData={() => { }}
                            updateLead={(value) => {
                                setLeadStatus(value)
                            }}
                            leadName={data.name}
                            intentOptions={intentStatuses}
                            data={data}
                            width={'full'}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <button className="cancel border rounded-lg py-2.5 text-base font-medium " onClick={closeModal}>
                            Cancel
                        </button>
                        <button className='del-btn border py-2.5 rounded-lg text-base font-medium' onClick={() => {
                            const payload = {
                                reason: 'reason',
                                remarks: remarks,
                                status: 'cancelled'
                            }
                            handleSubmit(payload)
                        }}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </BasicModal>
    )
}

export default CancelBooking;