import React, { useEffect, useState } from 'react'
import { ReactComponent as LinkIcon } from '../../../assets/whitelink.svg';
import { ReactComponent as RichtIcon } from '../../../assets/richticon.svg';
import { ReactComponent as GiftICon } from '../../../assets/gift.svg';
import { ReactComponent as CopyICon } from '../../../assets/copy.svg';
import { ReactComponent as WhatsappIcon } from '../../../assets/whatsapp.svg';
import ReferCard from './Card/ReferCard';
import ReferCardTwo from './Card/ReferCardTwo';
import ReferCardThree from './Card/ReferCardThree';
import OwlCarousel from 'react-owl-carousel';
import ReferModal from './Modal/ReferModal';
import PaymentDetail from './Modal/PaymentDetail';
import { getNewBrokerDetails, getNewBrokerReferralDetails, getSubscriptionFAQ, getUserReferralCode } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import PaymentDetailSavedModal from './Modal/PaymentDetailSavedModal';



const ReferAndEarn = () => {

    const [showModal, setShowModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [referLink, setReferLink] = useState('');
    const [refferal, setRefferal] = useState({});
    const [faqs, setFaqs] = useState([]);
    const [showPaymentDetailSavedModal, setShowPaymentDetailSavedModal] = useState(false);
    const [message, setMessage] = useState('');

    const getReferCode = async () => {
        const res = await getUserReferralCode();
        if (res.data.status) {
            setReferLink(res.data.data.link)
            setMessage(res.data.data.webMessage)
        }
    }

    const getPersonalData = async () => {
        const res = await getNewBrokerDetails();
        if (res.data.status === 200) {
            setRefferal(res.data.data.referral);
        }
    }

    const getFaqs = async () => {
        const res = await getSubscriptionFAQ();
        if (res.data.status === 200) {
            setFaqs(res.data.data)
        }
    }
    // const getReferralDetail = async () => {
    //     const res = await getNewBrokerReferralDetails();
    //     console.log(res.data.data)
    // }

    useEffect(() => {
        getReferCode();
        getPersonalData();
        getFaqs();
        if (localStorage.getItem('showCard')) {
            setShowModal(true)
        }
        // getReferralDetail();
    }, []);

    var refer = {
        dots: true,
        infinite: false,
        arrow: false,
        nav: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: false,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div className='w-100 flex flex-col h-full'>
            <div className='!pt-6 !pb-4 !ml-5 !pr-6 max-w-100 !border-b !border-black400 flex justify-between sm:flex-row flex-col items-start sm:gap-0 !gap-4'>
                <div>
                    <h2 className='mb-0 text-black800 text-2xl font-medium leading-7'>Share your referral Link</h2>
                </div>
            </div>

            <div className='sm:!px-6 !px-4 !py-5 h-full overflow-auto w-full'>

                <div className='flex sm:flex-row flex-col flex-start gap-6'>
                    <div className='w-full sm:!order-1 !order-2'>
                        <div>
                            <div className='!mb-4 text-base font-medium text-textblack '>Copy your referral link and share it anywhere</div>
                            <div className='flex sm:flex-row flex-col gap-3'>
                                {/* <input
                                    type="text"
                                    placeholder='Invitelink_Propacity/JZLPS1200754'
                                    className='w-full focus:!border-0 focus:outline-0 text-base placeholder:text-base'
                                    value={referLink}
                                /> */}



                                {referLink && <button
                                    className='gradient-blue inline-flex gap-x-2 text-white font-medium inset-blue text-sm !rounded-lg !px-2.5 !py-2 items-center sm:w-[134px] grow-0 shrink-0 sm:basis-[134px] w-full justify-center h-11'
                                    onClick={() => {
                                        navigator.clipboard.writeText(referLink).then(function () {
                                            toast.success('Link Copied');
                                        }, function (err) {
                                            toast.error('Could not copy');
                                        });
                                    }}
                                ><LinkIcon />Copy Link</button>}

                                <a
                                    href={`https://api.whatsapp.com/send?text=${message}`}
                                    className='!rounded-lg items-center whitespace-nowrap h-11 grident-green text-white inline-flex gap-x-2 text-sm font-medium sm:w-[196px] grow-0 shrink-0 sm:basis-[196px] w-full justify-center'><WhatsappIcon />Share on Whatsapp
                                </a>


                            </div>
                        </div>

                        <div className='flex !gap-4 items-start sm:mt-0 !mt-6 sm:flex-row flex-col'>
                            <div className='w-full'>
                                <div className='!mb-4 text-base font-medium text-textblack '>Your Earnings</div>
                                <div className='!border !border-black400 !rounded-lg inline-flex !py-4 px-3.5 justify-between items-center w-full grow-0 shrink-0 basis-full shadow-shadowmd'>
                                    <div className='inline-flex items-start gap-x-4 '>
                                        <div><GiftICon /></div>
                                        <div>
                                            <div className='text-black text-xl font-semibold mb-px'>₹{refferal?.balance}</div>
                                            <p className='m-0 text-xs text-black700'>Your Earnings</p>
                                        </div>

                                    </div>
                                    {/* <button><RichtIcon /></button> */}
                                </div>
                            </div>
                            <div>
                                <div className='!mb-4 text-base font-medium text-textblack opacity-0 sm:block hidden'>Your Earnings</div>
                                <button className='text-sm shadow-white-btn-shadow bg-whtie font-medium !rounded-lg !border !border-black400 bg-white !py-3 w-[230px]' onClick={() => setShowPaymentModal(true)}>Enter Payment Details</button>
                            </div>

                        </div>
                        {/* <div className='flex !gap-4 items-start !mt-6'>
                            <div className='sm:w-[345px] w-full grow-0 shrink-0 sm:basis-[345px] basis-full'>
                                <div className='!mb-2 text-base font-medium text-textblack '>Amount credited</div>
                                <div className='shadow-shadowmd !border !border-l-8 !border-black400 !px-2 !py-4 !rounded-xl '>
                                    <h1 className='!mb-3 !text-black text-base font-bold '>Manu Jha</h1>
                                    <div className='bg-green inline-block py-2.5 !px-4 rounded text-white shadow-shadowmd text-[10px] font-semibold'>₹500 credited to account</div>

                                    <div className='inline-flex gap-x-2 items-center !mt-3'>
                                        <div className='text-xs ffont-medium text-textblack'>Txn ID: 1234sder23tgr4</div>

                                        <button
                                            className='inline-flex gap-x-1 items-center !text-primary text-xs font-medium'
                                            onClick={() => {
                                                // 1234sder23tgr4
                                                navigator.clipboard.writeText('1234sder23tgr4').then(function () {
                                                    toast.success('Link Copied');
                                                }, function (err) {
                                                    toast.error('Could not copy');
                                                });
                                            }}><CopyICon /> Copy</button>

                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='mt-6'>
                            <div className='shadow-shadowmd w-full !py-5 !px-4 !border !border-black400 !rounded-lg'>
                                <h1 className='!mb-3 !text-black text-2xl font-semibold'>FAQ</h1>

                                <div className='space-y-8'>

                                    {/* Repeate this div for faq qa and ans */}
                                    {
                                        faqs.map((faq, index) => (
                                            <div>
                                                <h2 className='text-sm text-gray900 font-medium mb-2'>{faq?.question}</h2>
                                                <p className='mb-0 text-black700 text-sm'>{faq?.answer}</p>
                                            </div>
                                        ))
                                    }
                                    {/* <div>
                                        <h2 className='text-sm text-gray900font-medium mb-2'>How does the money get credited to me?</h2>
                                        <p className='mb-0 text-black700 text-xs'>The expected completion date is [04, 2025]. However, construction timelines may be subject to change based on various factors,</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[420px] grow-0 shrink-0 sm:basis-[420px] overflow-hidden sm:!order-2 !order-1'>
                        {!localStorage.getItem('showCard') && <OwlCarousel className='owl-theme referslider'
                            dots={true}
                            loop={true}
                            nav={false}
                            autoplay={true}
                            autoplayTimeout={4000}
                            margin={8}
                            smartSpeed={1000}
                            responsive={{
                                0: {
                                    items: 1,
                                },

                            }
                            }>
                            <div>
                                <ReferCard
                                    classname='!rounded-xl sm:h-[500px] h-[400px]'
                                />
                            </div>
                            <div>
                                <ReferCardTwo
                                    classname='!rounded-xl sm:h-[500px] h-[400px]'
                                />
                            </div>
                            <div>
                                <ReferCardThree
                                    classname='!rounded-xl sm:h-[500px] h-[400px]'
                                />
                            </div>
                        </OwlCarousel>}
                        {localStorage.getItem('showCard') &&
                            <ReferCard
                                classname='!rounded-xl sm:h-[500px] h-[400px]'
                            />
                        }
                    </div>
                </div>

            </div>
            {showModal && <ReferModal message={message} show={showModal} closeModal={() => {
                setShowModal(false)
                localStorage.removeItem('showCard')
            }} referLink={referLink} />}
            {showPaymentModal && <PaymentDetail show={showPaymentModal} closeModal={() => setShowPaymentModal(false)} setShowPaymentDetailSavedModal={setShowPaymentDetailSavedModal} />}
            {showPaymentDetailSavedModal && <PaymentDetailSavedModal show={showPaymentDetailSavedModal} closeModal={() => setShowPaymentDetailSavedModal(false)} />}
        </div>
    )
}

export default ReferAndEarn