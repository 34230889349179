import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as SearchClosIoicn } from '../../assets/icons/searchClose.svg';

const SearchBox = ({ searchText, setSearchText, placeholder }) => {
    return (
        <div className={`border rounded px-2 py-1 flex items-center ${searchText ? 'border-primary' : ''} `}>
            <SearchIcon className='mr-2' />
            <input
                type="text"
                placeholder={placeholder}
                className={` w-100 focus:outline-none text-sm `}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText &&
                <div className="cursor-pointer" onClick={() => setSearchText('')}>
                    <SearchClosIoicn />
                </div>}
        </div>
    )
}

export default SearchBox