import React from 'react'
import { Modal } from "react-bootstrap";


const CenterModal = ({ show, closeModal, children, backDropClassName, modalClass }) => {
    return (
        <Modal size="xl" show={show} onHide={closeModal} backdrop="static" fullscreen centered dialogClassName={`${modalClass} modal-radius import-modals`} backdropClassName={backDropClassName}>
            {children}
        </Modal>
    )
}

export default CenterModal;