import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import { insertPricingLegalCharges, updatePricingLegalChargesByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import InputRupees from '../../InputGroup/InputRupees';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const AddLeagalChargesModal = ({ show, closeModal, modalClass, uuid, getLegelChargesData, selectedPrice }) => {
    const [legelCharge, setLegelCharge] = useState('absolute');
    const [absoluteAmount, setAbsoluteAmount] = useState('');
    const [amountPercent, setAmountPercent] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            setAbsoluteAmount(selectedPrice.payment_amount);
            setAmountPercent(selectedPrice.payment_percentage);
            setDescription(selectedPrice.description);
            if (selectedPrice.payment_amount) {
                setLegelCharge('absolute');
            }
            if (selectedPrice.payment_percentage) {
                setLegelCharge('percent');
            }

        }
    }, [selectedPrice]);

    const handleSubmit = async () => {
        const payload = {
            payment_amount: absoluteAmount || null,
            payment_percentage: amountPercent || null,
            description: description,
        }

        let res = {};
        if (selectedPrice?.uuid) {
            res = await updatePricingLegalChargesByUUID(selectedPrice?.uuid, payload);
        } else {
            res = await insertPricingLegalCharges(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            closeModal();
            getLegelChargesData();
        }
    }

    const handleClose = () => {
        closeModal();
        setLegelCharge('absolute');
        setAbsoluteAmount('');
        setAmountPercent('');
        setDescription('');

    }

    return (
        <RightModal show={show} closeModal={handleClose} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add Legal Charges</h1>
                <button className='border-0 bg-tr ' onClick={handleClose}><CloseIcon /></button>
            </div>
            <div action="" className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>

                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Legal Charges
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='absolute' checked={legelCharge === 'absolute'} onChange={() => setLegelCharge('absolute')} />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='percent' checked={legelCharge === 'percent'} onChange={() => setLegelCharge('percent')} />
                            Percentage
                        </label>
                    </div>
                    {legelCharge === 'percent' &&
                        <InputText
                            placeholder='Enter Percentage'
                            value={amountPercent}
                            onChange={(e) => setAmountPercent(e.target.value)}
                        />}
                    {legelCharge === 'absolute' &&
                        <InputRupees
                            placeholder='Enter Absolute'
                            value={absoluteAmount}
                            onChange={(value) => setAbsoluteAmount(value)}
                            rupayclass='top-4 left-3'
                            handleBlur={() => { }}
                        />}
                </div>
                {/* <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Legal Charges
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" checked />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" />
                            Percentage
                        </label>
                    </div>
                    <InputText
                        placeholder='Enter value' />
                </div> */}
                {/* <InputText
                    placeholder='Enter Percentage'
                    label='Stamp Duty Charge (%)'
                /> */}

                <InputTextarea
                    label='Description'
                    placeholder='Enter Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={handleClose}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddLeagalChargesModal
