import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputSelect from '../../InputGroup/InputSelect';
import InputTextarea from '../../InputGroup/InputTextarea';
import InputText from '../../InputGroup/InputText';
import DateTimePicker from '../../InputGroup/DateTime';
import { updateEOIApprovalStatus, updateEOIOtherDetails } from '../../../services/private/inventory.service';
import { toast } from 'react-toastify';
import { BankList, validityUnit } from '../../../helpers/enums';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../services/private/company.service';
import MobileInput from '../../InputGroup/MobileInput';
import InputRupees from '../../InputGroup/InputRupees';
import AddDemand from '../../Collection-Manager/Modal/AddDemand';
import { ReactComponent as PlusPrimaryIcon } from '../../../assets/icons/PlusPrimary.svg'
import moment from 'moment';
import Consumer from '../../../helpers/context';
import { reassignLeads } from '../../../services/private/leads.service';
import { checkIsArrayEmpty, checkIsSomeItemAvailableInList } from '../../../helpers/helpers';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const loanStatus = [
    {
        label: 'Pending',
        value: "Pending"
    },
    {
        label: 'Approved',
        value: "Approved"
    },
]

const EOIOther = ({ setSteps, eoiId, onSuccess, selectedBooking, data, selectedPaymentPlan, selectedProject, adjustedPrice, closeModal }) => {

    const [allUser, setAllUsers] = useState([]);
    const [showRaiseDemand, setShowRaiseDemand] = useState(false);
    const [users, setUsers] = useState([{
        validity: 30,
        validity_unit: 'days'
    }]);
    const [loading, setLoading] = useState(false);
    const [statusShareUsers, setStatusShareUsers] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState();
    const [usersForAssigned, setUsersForAssigned] = useState([]);

    const { userProfile, allowedPermissions } = useContext(Consumer);
    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            followupScheduleDate: '',
            remarks: '',
            status: '',
            amount: '',
            bank: '',
            sanction_amount: '',
            interest_rate: '',
            agreement_status: '',
            date: '',
            propose_date: '',
            agreeement: '',
            closing_manager: '',
            sourcing_manager: '',
            poc: '',
            name: '',
            mobile: ''
        }
    })

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.uuid
                    }
                })
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    useEffect(() => {
        if (selectedBooking && Object.keys(selectedBooking).length > 0) {
            const { agreement, loan, developer, referred_by } = selectedBooking;
            reset({
                status: loan?.status ? loanStatus.find(item => item.value === loan?.status) : '',
                amount: loan?.amount,
                bank: loan?.bank ? BankList.find(item => item.value === loan.bank) : '',
                sanction_amount: loan?.sanction_amount,
                interest_rate: loan?.interest_rate,

                agreement_status: agreement?.status ? loanStatus.find(item => item.value === agreement?.status) : '',
                date: agreement?.date,
                propose_date: agreement?.propose_date,
                agreeement: agreement?.agreeement,

                closing_manager: developer?.closing_manager ? allUser.find(item => item.value === developer?.closing_manager) : '',
                sourcing_manager: developer?.sourcing_manager ? allUser.find(item => item.value === developer?.sourcing_manager) : '',
                poc: developer?.poc ? allUser.find(item => item.value === developer?.poc) : '',

                name: referred_by?.name,
                mobile: referred_by?.mobile
            },
                { keepStateOptions: true }
            )


        }
    }, [selectedBooking, allUser, loanStatus])


    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(data.id);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: data?.status });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                setAssignedTo(res.data.data?.user_uuid)
            } else {
                setAssignedTo(data?.assigned_user_uuid)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: data?.status });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers]);


    useEffect(() => {
        getAllUsers()
        if (data && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccess();
            getShareLeadAccessAssignedToByStatus();
            getShareLeadAccessUsingStatusId();
        }
    }, [data]);

    const handleReassignModalSubmit = () => {
        reassignLeads([data.id], assignedTo, '').then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned")
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleSubmitSaveLeadAccess = async () => {
        let payload = users.filter(item => item.user_id);
        // let count = 0
        // payload.forEach(item => {
        //     if (!item.user_id || !item.validity || !item.access || !item.validity_unit) {
        //         count++
        //     }
        // })
        // if (count > 0) {
        //     toast.error('Please fill all values');
        //     return;
        // }

        let res = {}

        payload = payload.map(item => ({
            ...item,
            validity: item.validity || '30',
            access: item.access || 'edit',
            validity_unit: item.validity_unit || 'days',
            expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString()
        }))
        res = await saveLeadAccess(data.id, { status_id: data.status, users: payload })

        if (res.data.status === 200) {
            // toast.success(res.data.message);
            setLoading(false);
            // onSuccess();
            document.getElementById('eoi-tab').click();
            toast.success('Token created successfully');
            closeModal();
        }
    }

    const handleUpdateStatus = async () => {
        const res = await updateEOIApprovalStatus(eoiId, { status: 'pending' });
        if (res.data.status === 200) {
            if (assignedTo) {
                handleReassignModalSubmit();
            }

            if (!checkIsArrayEmpty(users)) {
                handleSubmitSaveLeadAccess();
            } else {
                setLoading(false);
                // onSuccess();
                document.getElementById('eoi-tab').click();
                toast.success('Token created successfully');
                closeModal();
            }

        }
    }

    const onFormSubmit = async (values) => {
        const payload = {
            // followupScheduleDate: values.followupScheduleDate,
            // remarks: values.remarks,
            loan: {
                status: values.status?.value || null,
                amount: values.amount || null,
                bank: values.bank?.value || null,
                sanction_amount: values.sanction_amount || null,
                interest_rate: values.interest_rate || null,
                // sanction_letter: values.sanction_letter || null
            },
            agreement: {
                status: values.agreement_status?.value || null,
                date: values.date || null,
                propose_date: values.propose_date || null,
                // agreeement: values.agreeement || null
            },
            developer: {
                closing_manager: values.closing_manager?.value || null,
                sourcing_manager: values.sourcing_manager?.value || null,
                poc: values.poc?.value || null
            },
            referred_by: {
                name: values.name,
                mobile: values.mobile
            }
        }
        setLoading(true);
        const res = await updateEOIOtherDetails(eoiId, payload);
        if (res.data.status === 200) {
            localStorage.setItem('leadType', 'eoi_only');
            handleUpdateStatus();
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onFormSubmit)} id='other-detail' className='!pt-5 !px-6 pb-8 h-full overflow-auto'>
                <div className='mb-3'>
                    <h2 className='m-0 text-textblack text-lg font-semibold inter'>Project ({selectedProject?.name})</h2>
                </div>
                {/* <div>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Followup
                    </h5>
                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                        <div>
                            <Controller
                                control={control}
                                name='followupScheduleDate'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <DateTimePicker
                                        label='Next Payment Followup (optional)'
                                        inputclass='mt-0'
                                        placeholder='Select Followup Date & Time'
                                        value={value}
                                        onValueChange={onChange}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name='remarks'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputTextarea
                                        type="text"
                                        label="Comments"
                                        placeholder="Enter your comments..."
                                        name="name"
                                        inputclass='m-0 inter'
                                        row='2'
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </div>

                    </div>
                </div> */}
                <div>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Loan Status
                    </h5>
                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                        <div>
                            <Controller
                                control={control}
                                name='bank'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputSelect
                                        label='Bank (Preferred)'
                                        placeholder='Select Bank (Preferred)'
                                        inputclass='m-0 inter'
                                        isMulti={false}
                                        styles={customStyles}
                                        onChange={onChange}
                                        value={value}
                                        options={BankList}
                                    />
                                )}
                            />
                        </div>

                        <div>
                            <Controller
                                control={control}
                                name='amount'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputRupees
                                        type="text"
                                        label="Loan Amount (Preferred)"
                                        placeholder="Enter Preferred Loan Amount"
                                        name="name"
                                        inputclass='m-0 inter'
                                        onChange={onChange}
                                        value={value}
                                        showRupees={true}
                                        handleBlur={() => { }}
                                    />
                                )}
                            />
                        </div>

                        {/* <div>
            <Controller
                control={control}
                name='cp_id'
                render={({
                    field: { onChange, onBlur, value, ref },
                }) => (
                    <InputText
                        type="text"
                        label="Loan Amount (Preferred)"
                        placeholder="Enter Preferred Loan Amount"
                        name="name"
                        inputclass='m-0 inter'
                        onChange={onChange}
                        value={value}
                    />
                )}
            />
        </div> */}
                        <div>
                            <Controller
                                control={control}
                                name='status'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputSelect
                                        label='Loan Status'
                                        placeholder='Select Loan Status'
                                        inputclass='m-0 inter'
                                        isMulti={false}
                                        styles={customStyles}
                                        onChange={onChange}
                                        value={value}
                                        options={loanStatus}
                                    />
                                )}
                            />
                        </div>

                        <div>
                            <Controller
                                control={control}
                                name='sanction_amount'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputRupees
                                        label='Sanction Amount'
                                        placeholder='Select Sanction Amount'
                                        inputclass='m-0 inter'
                                        isMulti={false}
                                        styles={customStyles}
                                        onChange={onChange}
                                        value={value}
                                        showRupees={true}
                                        handleBlur={() => { }}
                                    />
                                )}
                            />
                        </div>

                        <div>
                            <Controller
                                control={control}
                                name='interest_rate'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputText
                                        label='Interest Rate %'
                                        placeholder='Select Interest Rate'
                                        inputclass='m-0 inter'
                                        isMulti={false}
                                        styles={customStyles}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </div>

                    </div>
                </div>
                <div className='!mt-5 !border-t !border-grayLight !pt-5'>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Developer RM
                    </h5>
                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                        <div>
                            <Controller
                                control={control}
                                name='closing_manager'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputSelect
                                        label="Developer Closing Manager"
                                        placeholder="Enter Developer Closing Manager"
                                        name="name"
                                        inputclass='m-0 inter'
                                        value={value}
                                        onChange={onChange}
                                        options={allUser}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name='sourcing_manager'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputSelect
                                        label="Developer Sourcing Manager"
                                        placeholder="Enter Developer Sourcing Manager"
                                        name="name"
                                        inputclass='m-0 inter'
                                        value={value}
                                        onChange={onChange}
                                        options={allUser}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name='poc'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (

                                    <InputSelect
                                        label="Developer Collection POC"
                                        placeholder="Enter Developer Collection POC"
                                        name="name"
                                        inputclass='m-0 inter'
                                        value={value}
                                        onChange={onChange}
                                        options={allUser}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className='!mt-5 !border-t !border-grayLight !pt-5'>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Agreement
                    </h5>
                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                        <div>
                            <Controller
                                control={control}
                                name='agreement_status'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (

                                    <InputSelect
                                        label='Agreement status'
                                        placeholder='Select status'
                                        inputclass='m-0 inter'
                                        isMulti={false}
                                        styles={customStyles}
                                        onChange={onChange}
                                        value={value}
                                        options={loanStatus}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name='propose_date'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (

                                    <DateTimePicker
                                        label='Proposed Agreement Date'
                                        inputclass='mt-0'
                                        onValueChange={onChange}
                                        value={value}
                                        showTime={false}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name='date'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (

                                    <DateTimePicker
                                        label='Agreement Date'
                                        inputclass='mt-0'
                                        onValueChange={onChange}
                                        value={value}
                                        showTime={false}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className='!mt-5 !border-t !border-grayLight !pt-5'>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Refer By
                    </h5>
                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                        <div>
                            <Controller
                                control={control}
                                name='name'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputText
                                        type="text"
                                        label="Customer Name"
                                        placeholder="Enter Customer Name"
                                        name="name"
                                        inputclass='m-0 inter'
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name='mobile'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <MobileInput
                                        type="text"
                                        label="Customer Mobile"
                                        placeholder="Enter Customer Mobile"
                                        name="name"
                                        inputclass='m-0 inter'
                                        value={value}
                                        onChange={(e) => { onChange(e.phone) }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className='!mt-5 !border-t !border-grayLight !pt-5'>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Lead Sharing
                    </h5>

                    {userProfile?.allowed_modules?.includes('lead_sharing') && checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing', 'can_share_leads']) && < >
                        <div className='mb-3'>
                            <InputSelect
                                label={'Assigned User'}
                                placeholder={'Select User'}
                                options={usersForAssigned}
                                value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                onChange={(value) => {
                                    setAssignedTo(value.value);
                                }}
                                disable={!allowedPermissions.includes('edit_lead_sharing')}
                            />
                        </div>
                        {
                            users.map((item, index) => (
                                <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                    <div className='flex justify-between items-center'>
                                        <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                        {allowedPermissions.includes('edit_lead_sharing') && <button
                                            className='text-primary text-base font-medium'
                                            type='button'
                                            onClick={() => {
                                                let newUsers = [...users];
                                                newUsers.splice(index, 1);
                                                if (users.length === 1) {
                                                    setUsers([{}])
                                                } else {
                                                    setUsers([...newUsers])
                                                }

                                            }}
                                        >Remove</button>}
                                    </div>
                                    <div>
                                        <InputSelect
                                            placeholder={'Select User'}
                                            options={allUser}
                                            value={item.user_id ? allUser.find(user => user.value === item.user_id) : ''}
                                            onChange={(value) => {
                                                let newUsers = [...users];
                                                newUsers[index].user_id = value.value;
                                                // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                // setAllUsers(filteredUser)
                                                setUsers([...newUsers]);
                                            }}
                                            disable={!allowedPermissions.includes('edit_lead_sharing')}
                                        />
                                        <div className='mt-3'>
                                            <label htmlFor={`edit_${index}`} className='mr-4'>
                                                <input
                                                    type="radio"
                                                    className='mr-2'
                                                    name={`permission_${index}`}
                                                    id={`edit_${index}`}
                                                    value='edit'
                                                    checked={item.access === 'edit'}
                                                    onChange={() => {
                                                        let newUsers = [...users];
                                                        newUsers[index].access = 'edit'
                                                        setUsers(newUsers);
                                                    }}
                                                    disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                />
                                                <span>Edit/View Access</span>
                                            </label>
                                            <label htmlFor={`view_${index}`}>
                                                <input
                                                    type="radio"
                                                    className='mr-2'
                                                    name={`permission_${index}`}
                                                    id={`view_${index}`}
                                                    value='view'
                                                    checked={item.access === 'view'}
                                                    onChange={() => {
                                                        let newUsers = [...users];
                                                        newUsers[index].access = 'view'
                                                        setUsers(newUsers);
                                                    }}
                                                    disable={!allowedPermissions.includes('edit_lead_sharing')}
                                                />
                                                <span>View Only</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label htmlFor="">Validity</label>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <InputText
                                                    placeholder={'e.g. 30'}
                                                    value={item.validity}
                                                    onChange={(e) => {
                                                        let newUsers = [...users];
                                                        newUsers[index].validity = e.target.value
                                                        setUsers(newUsers);
                                                    }}
                                                    disable={!allowedPermissions.includes('edit_lead_sharing')}
                                                />
                                                <InputSelect
                                                    inputclass={'m-0'}
                                                    styles={customStyles}
                                                    placeholder={'Select Time'}
                                                    value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                    options={validityUnit}
                                                    onChange={(value) => {
                                                        let newUsers = [...users];
                                                        newUsers[index].validity_unit = value.value;
                                                        setUsers(newUsers);
                                                    }}
                                                    disable={!allowedPermissions.includes('edit_lead_sharing')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <button
                            className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold'
                            type='button'
                            onClick={() => setUsers([...users, {
                                validity: 30,
                                validity_unit: 'days'
                            }])}
                            disable={!allowedPermissions.includes('edit_lead_sharing')}
                        >
                            <PlusPrimaryIcon />Add another
                        </button>
                    </>}
                </div>
            </form>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-between shadow-topshadow'>
                <div>
                    <button
                        type='button'
                        className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black w-[128px] h-[44px] text-center mr-3'
                        onClick={() => setShowRaiseDemand(true)}
                    >
                        Raise Demand
                    </button>
                </div>
                <div >
                    <button type='button' className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black w-[128px] h-[44px] text-center mr-3' onClick={() => setSteps('EOIPaymentProof')}>Previous</button>
                    <button
                        type='submit'
                        form='other-detail'
                        disabled={loading}
                        className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary w-max px-2 h-[44px] text-center'>Send For Approval</button>
                </div>
            </div>

            {showRaiseDemand && <AddDemand
                show={showRaiseDemand}
                closeModal={() => {
                    setShowRaiseDemand(false)
                }}
                item={{
                    eoi_uuid: eoiId,
                    customer_name: data.name,
                    adjusted_price: adjustedPrice,
                    lead_uuid: data?.id
                }}
                demandRaiseFrom={'EOI table'}
                paymentMilestones={selectedPaymentPlan?.milestones?.filter(item => !item.is_completed).map(milestone => ({ ...milestone, label: `${milestone.count}. ${milestone.description}`, value: milestone.id }))}
                onSuccess={() => {
                    setShowRaiseDemand(false)
                }}
            />}
        </>
    )
}

export default EOIOther
