import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import RightModal from '../../Modals/RightModal';
import SearchInput from '../../InputGroup/SearchInput';
import { useEffect } from 'react';

const NearbyLandmarksModal = ({ show, closeModal, style, handleUpdateLandMarks, previousLandmark }) => {

    const [landmarks, setLandmarks] = useState(['', '']);

    const addLandmark = () => {
        setLandmarks([...landmarks, '']);
    };

    let error = '';

    useEffect(() => {
        if (previousLandmark.length > 0) setLandmarks(previousLandmark)
    }, [previousLandmark]);

    const handleChangeLandMark = (e, index) => {
        const landmark = [...landmarks];
        landmark[index] = e.target.value;
        setLandmarks(landmark)
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`${style.configurationModal} ${style.addbanckAccountModal}  configurationModal`}>

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Nearby Landmarks</h1>
                <button className='border-0 bg-tr ' onClick={() => {

                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <form className={`${style.modalForm} modalForm d-flex h-100 overflow-auto flex-column px-20`}>

                {landmarks.map((landmark, index) => (

                    <div className='form-group' key={index}>
                        <label >Landmark {index + 1}</label>
                        <input
                            type='text'
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            placeholder='e.g. Delhi Public School'
                            value={landmark}
                            onChange={(e) => handleChangeLandMark(e, index)}
                        // {...register('appartmentNumber')}
                        />
                        {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                    </div>
                ))}

                <div className='d-flex justify-content-center'><button className={`${style.addlandmarkbtn}`} onClick={addLandmark} type='button'>+ Add More Landmarks</button></div>

            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button className='outline-btn w-100' onClick={closeModal}>Cancel</button>
                <button type='button' onClick={() => {
                    handleUpdateLandMarks(landmarks);
                    closeModal();
                }} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal >
    )
}

export default NearbyLandmarksModal
