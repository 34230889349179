import { put, all, call, takeLatest, select, takeEvery } from "redux-saga/effects";
import request from '../../helpers/requests';
import { browserRedirect } from '../../helpers/helpers';
import URLS from '../../helpers/urls';
import AWSConfig from '../../aws-exports';
import AmplifySdk from "../../helpers/amplify";
import { LEAD_CREAT_NEW_REQUEST, LEAD_FILTER_ON_REMOVE, LEAD_FILTER_ON_SUBMIT, LEAD_FILTER_ON_SEARCH, LEAD_PAGE_INIT, LEAD_LOAD_MORE, LEAD_GLOBAL_SEARCH, LEAD_TABLE_SORT, LEAD_TABLE_ON_PAGE_OR_LIMIT_CHANGE } from "./actions";
import { leadPageInItSuccess, leadPageInItError, leadFilterOnSearchSuccess, leadPageCreateNewLeadSuccess, leadLoadMoreStop, leadLoadMoreSuccess, leadPageCreateNewLeadError, leadPageHideSideBar } from "./actions";








function getALLLeads(payload) {
  let limit = payload.limit || 10;
  let pageNumber = payload.pageNumber || 1;
  let url = `${process.env.REACT_APP_DEV_API_URL}/${URLS.LEAD_URL}/dashboard`;
  let body = { limit, pageNumber }
  switch (payload.type) {
    case 'Search':
      body['text'] = payload.text;
      break;
    case 'Sort':
      body['col'] = payload.text;
      body['sort'] = payload.direction;
      break;

    case 'Filters':
      body['filters'] = payload.filters;
      break;

  }

  return request("POST", url, null, body, null)

}



function getFilterValuesOnSearch(payload) {
  let { filtertype, value } = payload
  return request("GET", `${process.env.REACT_APP_DEV_API_URL}/${URLS.LEAD_URL}/filter/value`, { filterType: filtertype, value }, payload, null)

}



function creatNewLead(payload) {

  return request("POST", `${process.env.REACT_APP_DEV_API_URL}/${URLS.LEAD_URL}/insert`, null, payload, null)

}

function* leadPageInItWorker({ payload }) {
  try {


    let leadsresponse = yield call(getALLLeads, { 'type': 'init' });

    yield put(leadPageInItSuccess({ columns: leadsresponse.data.data.Columns, rows: leadsresponse.data.data.Result, totalcount: leadsresponse.data.data['TotalCount'] && leadsresponse.data.data['TotalCount'].length ? leadsresponse.data.data['TotalCount'][0]['count'] : 0 }));
  } catch (err) {
    console.log('Message', err.message)
    yield put(leadPageInItError({ ...err, message: err.message }));
  }

}



function* leadPageSearchOnText(payload) {
  try {


    let leadsresponse = yield call(getALLLeads, { type: 'Search', "text": payload.action });

    yield put(leadPageInItSuccess({ columns: leadsresponse.data.data.Columns, rows: leadsresponse.data.data.Result, totalcount: leadsresponse.data.data['TotalCount'] && leadsresponse.data.data['TotalCount'].length ? leadsresponse.data.data['TotalCount'][0]['count'] : 0 }));
  } catch (err) {
    console.log('Message', err.message)
    yield put(leadPageInItError({ ...err, message: err.message }));
  }

}

function* leadPageSortOnText(payload) {
  try {

    let leadsresponse = yield call(getALLLeads, { type: 'Sort', "text": payload.columns, "direction": payload.direction });
    yield put(leadPageInItSuccess({ columns: leadsresponse.data.data.Columns, rows: leadsresponse.data.data.Result, totalcount: leadsresponse.data.data['TotalCount'] && leadsresponse.data.data['TotalCount'].length ? leadsresponse.data.data['TotalCount'][0]['count'] : 0 }));
  } catch (err) {
    console.log('Message', err.message)
    yield put(leadPageInItError({ ...err, message: err.message }));
  }

}




function* leadPageOnNextPageOrSizeChange(payload) {
  try {

    let leadsresponse = yield call(getALLLeads, payload);
    yield put(leadPageInItSuccess({ columns: leadsresponse.data.data.Columns, rows: leadsresponse.data.data.Result, totalcount: leadsresponse.data.data['TotalCount'] && leadsresponse.data.data['TotalCount'].length ? leadsresponse.data.data['TotalCount'][0]['count'] : 0 }));
  } catch (err) {
    console.log('Message', err.message)
    yield put(leadPageInItError({ ...err, message: err.message }));
  }

}



function* leadGetFilterValue(payload) {

  try {

    let filterResponse = yield call(getFilterValuesOnSearch, payload);
    yield put(leadFilterOnSearchSuccess({ col: payload.filtertype, result: filterResponse.data.data }))

  } catch (err) {
    console.log('Message', err.message)
    yield put(leadPageInItError({ ...err, message: err.message }));
  }

}


function* leadPageOnFilterSubmit(payload) {
  try {

    let leadsresponse = yield call(getALLLeads, { type: 'Filters', "filters": payload.filters });

    yield put(leadPageInItSuccess({ columns: leadsresponse.data.data.Columns, rows: leadsresponse.data.data.Result, totalcount: leadsresponse.data.data['TotalCount'] && leadsresponse.data.data['TotalCount'].length ? leadsresponse.data.data['TotalCount'][0]['count'] : 0 }));
  } catch (err) {
    console.log('Message', err.message)
    yield put(leadPageInItError({ ...err, message: err.message }));
  }

}

function* leadCreatWorker(payload) {
  try {


    let newLead = payload.action;
    let allSelectedProjects = newLead['Project Name'].map(o => o.value);


    let new_lead_payload = {

      name: newLead['Lead Name'],
      email: newLead['Email Id'],
      mobile: newLead['Mobile No'],
      projectId: allSelectedProjects,
      sourceId: newLead['Lead Source']['value'],
      statusId: newLead['status_id'],
      intentId: newLead['intent_id'],


    }
    let response = yield call(creatNewLead, new_lead_payload);
    let leadsresponse = yield call(getALLLeads, { 'type': 'init' });
    yield put(leadPageHideSideBar());
    yield put(leadPageCreateNewLeadSuccess({ columns: leadsresponse.data.data.Columns, alerttype: 'Success', alertMessage: 'Lead Created !!!', rows: leadsresponse.data.data.Result, totalcount: leadsresponse.data.data['TotalCount'] && leadsresponse.data.data['TotalCount'].length ? leadsresponse.data.data['TotalCount'][0]['count'] : 0 }));

  } catch (err) {
    console.log('Message', err.message)
    yield put(leadPageCreateNewLeadError({ ...err, message: err.message }));
  }

}


// update lead by key and value
// function updateleadByKeyValue(uuid, payload) {
//   return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.lead.updateByKeyValue}/${uuid}`, null, payload, null);
// }

// function* updateleadByKeyValueWorker(action) {
//   try {
//     let payload = {
//       key: action.key,
//       value: action.value
//     }
//     let response = yield call(updateleadByKeyValue, action.uuid, payload);
//     yield put(action_getLeadDetail(action.leadUUID));

//     // Get Timeline
//     yield put(action_getTimeline(action.leadUUID))

//   } catch (error) {
//   }
// }



// Lead Watcher
export default function* leadSaga() {
  yield all([

    takeLatest(LEAD_PAGE_INIT, leadPageInItWorker),
    takeLatest(LEAD_CREAT_NEW_REQUEST, leadCreatWorker),
    takeEvery(LEAD_GLOBAL_SEARCH, leadPageSearchOnText),
    takeEvery(LEAD_TABLE_SORT, leadPageSortOnText),
    takeEvery(LEAD_TABLE_ON_PAGE_OR_LIMIT_CHANGE, leadPageOnNextPageOrSizeChange),
    takeLatest(LEAD_FILTER_ON_SEARCH, leadGetFilterValue),
    takeEvery(LEAD_FILTER_ON_SUBMIT, leadPageOnFilterSubmit),
    takeEvery(LEAD_FILTER_ON_REMOVE, leadPageOnFilterSubmit)

  ]);
}