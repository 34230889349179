export const SITE_VISIT_ACTIONS = {
    CREATE_SITE_VISIT: 'CREATE_SITE_VISIT',
    GET_SITE_VISITS: 'GET_SITE_VISITS',
    SET_SITE_VISITS: 'SET_SITE_VISITS',
    GET_CAN_CREATE: 'GET_CAN_CREATE',
    SET_CAN_CREATE: 'SET_CAN_CREATE',
    UPDATE_SITE_VISIT: 'UPDATE_SITE_VISIT',
    REMOVE_SITE_VISIT: 'REMOVE_SITE_VISIT',
    MARK_SITE_VISIT_COMPLETED: 'MARK_SITE_VISIT_COMPLETED'
}

export function action_canCreateSV(leadUUID) {
    return {
        type: SITE_VISIT_ACTIONS.GET_CAN_CREATE,
        leadUUID
    }
}

export function action_setCanCreateSV(canCreate) {
    return {
        type: SITE_VISIT_ACTIONS.SET_CAN_CREATE,
        canCreate
    }
}

export function action_getSV(leadUUID) {
    return {
        type: SITE_VISIT_ACTIONS.GET_SITE_VISITS,
        leadUUID
    }
}

export function action_setSV(siteVisits) {
    return {
        type: SITE_VISIT_ACTIONS.SET_SITE_VISITS,
        siteVisits
    }
}

export function action_createSV(leadUUID, payload) {
    return {
        type: SITE_VISIT_ACTIONS.CREATE_SITE_VISIT,
        leadUUID,
        payload
    }
}

export function action_updateSV(siteVisitUUID, payload, leadUUID) {
    return {
        type: SITE_VISIT_ACTIONS.UPDATE_SITE_VISIT,
        siteVisitUUID,
        payload,
        leadUUID
    }
}

export function action_removeSV(siteVisitUUID, leadUUID) {
    return {
        type: SITE_VISIT_ACTIONS.REMOVE_SITE_VISIT,
        siteVisitUUID,
        leadUUID
    }
}

export function action_markSVCompleted(siteVisitUUID, leadUUID) {
    return {
        type: SITE_VISIT_ACTIONS.MARK_SITE_VISIT_COMPLETED,
        siteVisitUUID,
        leadUUID
    }
}