import React from 'react'
import Lottie from 'react-lottie';
import CenterModal from '../../../components/Modals/center-modal';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import * as animation from '../../../assets/celebrate_animation.json';

const UserFoundModal = ({ show, closeModal, redirectURL }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!max-w-[450px]  [&>*]:!border-0 [&>*]:!rounded-lg [&>*]:!overflow-hidden' backDropClassName={''}>
            <div className=' relative !p-5'>
                <div>
                    <div className='flex justify-start m-0 w-[100px]'>
                        <Lottie
                            height={100}
                            width={100}
                            margin={0}
                            options={
                                { animationData: animation }
                            }
                        />
                    </div>
                    <h4 className='text-2xl font-semibold m-0'>Great news! </h4>
                    <button onClick={closeModal} className='absolute right-5 top-5'><CloseIcon /></button>
                </div>

                <div className='!mt-4'>
                    <div className='text-black700 text-base font-medium !mb-3'> You are already a Propacity Plus Premium user, this workshop is already available in your account. No need to purchase it again.</div>
                    <div className='text-gray700 text-base font-medium !mt-3'> Continue to Login and watch the video.</div>
                    <div className='!mt-5 w-full'>
                        <a
                            className='!px-4 bg-primary text-white text-base font-semibold shadow-inset-blue gradient-blue rounded-lg flex items-center justify-center !py-3'
                            // onClick={closeModal}
                            href={`${redirectURL}`}
                        // target='_blank'
                        >

                            Watch Now
                            <span className='ml-2.5'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 18L15 12L9 6" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>

        </CenterModal>
    )
}

export default UserFoundModal;