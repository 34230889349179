import { put, all, call, takeLatest } from "redux-saga/effects";

import { FB_LEAD_GEN_IN_IT, FB_LEAD_GEN_AUTHENTICATE } from "./action";
import { fbLeadGenAuthenticateSucess, fbLeadGenInitSuccess, fbLeadGenInitError, fbLeadGenAuthenticateError } from "./action";
import request from "../../../helpers/requests";
import adapters from "../../../helpers/adapters";
const env = process.env.REACT_APP_ENV



// FB AUTHENTICATE API call
function fbAuthenticateCall(payload) {
  return request("POST", `${adapters.AUTH}/${env}/fb-auth`, null, { tkn: payload.accessToken, expIn: payload.data_access_expiration_time, email: payload.email, userid: payload.userID, name: payload.name })
}


// FB IN IT API call
function fbAuthenticateInItCall(payload) {
  return request("GET", `${adapters.AUTH}/${env}/facebook/auth`, null, null)
}



// fb Lead Gen IN IT Worker
function* fbLedGenInItWorker({ payload }) {
  try {

    let response = yield call(fbAuthenticateInItCall, payload);
    if (response && response.data && response.data.result && response.data.result[0].loggedIn == true) yield put(fbLeadGenInitSuccess());
  } catch (err) {
    yield put(fbLeadGenInitError({ ...err, message: err.message }));
  }
}


// fb Lead Gen Authenticate Worker
function* fbLedGenAuthenticateWorker({ payload }) {
  try {
    let response = yield call(fbAuthenticateCall, payload);
    yield put(fbLeadGenAuthenticateSucess());
  } catch (err) {
    yield put(fbLeadGenAuthenticateError({ ...err, message: err.message }));
  }
}




// fbIntegeration Watcher
export default function* fbIntegerationSaga() {
  yield all([
    takeLatest(FB_LEAD_GEN_IN_IT, fbLedGenInItWorker),
    takeLatest(FB_LEAD_GEN_AUTHENTICATE, fbLedGenAuthenticateWorker)
  ]);
}