export const FOLLOWUP_ACTIONS = {
    CREATE_FOLLOWUP: 'CREATE_FOLLOWUP',
    GET_FOLLOWUPS: 'GET_FOLLOWUPS',
    SET_FOLLOWUPS: 'SET_FOLLOWUPS',
    GET_CAN_CREATE: 'GET_CAN_CREATE',
    SET_CAN_CREATE: 'SET_CAN_CREATE',
    UPDATE_FOLLOWUP: 'UPDATE_FOLLOWUP',
    REMOVE_FOLLOWUP: 'REMOVE_FOLLOWUP'
}

export function action_canCreateFollowup(leadUUID) {
    return {
        type: FOLLOWUP_ACTIONS.GET_CAN_CREATE,
        leadUUID
    }
}

export function action_setCanCreateFollowup(canCreate) {
    return {
        type: FOLLOWUP_ACTIONS.SET_CAN_CREATE,
        canCreate
    }
}

export function action_getFollowups(leadUUID) {
    return {
        type: FOLLOWUP_ACTIONS.GET_FOLLOWUPS,
        leadUUID
    }
}

export function action_setFollowups(followups) {
    return {
        type: FOLLOWUP_ACTIONS.SET_FOLLOWUPS,
        followups
    }
}

export function action_createFollowup(leadUUID, payload) {
    return {
        type: FOLLOWUP_ACTIONS.CREATE_FOLLOWUP,
        leadUUID,
        payload
    }
}

export function action_updateFollowup(followupUUID, payload, leadUUID) {
    return {
        type: FOLLOWUP_ACTIONS.UPDATE_FOLLOWUP,
        followupUUID,
        payload,
        leadUUID
    }
}

export function action_removeFollowup(followupUUID, leadUUID) {
    return {
        type: FOLLOWUP_ACTIONS.REMOVE_FOLLOWUP,
        followupUUID,
        leadUUID
    }
}