import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import { getStates, insertPricingStampDuty, updatePricingStampDutyByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';

const categoryList = [
    {
        label: 'Male',
        value: 'Male',

    },
    {
        label: 'Female',
        value: 'Female',

    },
    {
        label: 'Joint',
        value: 'Joint',

    }

];

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const AddStampDutyModal = ({ show, closeModal, modalClass, uuid, getStampDutyData, selectedPrice, statesList }) => {
    const [state, setState] = useState('');
    const [stampDuty, setStampDuty] = useState('');
    const [category, setCategory] = useState('');
    const [includePrice, setIncludePrice] = useState(true);
    const [description, setDescription] = useState('')

    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            setState(statesList.find(item => item.value === selectedPrice.state));
            setIncludePrice(selectedPrice.include_in_price);
            setDescription(selectedPrice.description);
            setStampDuty(selectedPrice.payment_percentage);
            setCategory(categoryList.find(item => item.value === selectedPrice.category));
        }
    }, [selectedPrice]);



    const handleSubmit = async () => {
        const payload = {
            state: state.value,
            payment_percentage: stampDuty,
            category: category.value,
            description: description,
            include_in_price: includePrice
        }

        let res = {}
        if (selectedPrice?.uuid) {
            res = await updatePricingStampDutyByUUID(selectedPrice.uuid, payload);
        } else {
            res = await insertPricingStampDuty(uuid, payload);
        }

        if (res.data.status === 200) {
            toast.success(res.data.message);
            handleClose();
            getStampDutyData();
        }
    }

    const handleClose = () => {
        closeModal();
        setState('');
        setStampDuty('');
        setCategory('');
        setIncludePrice(true);
        setDescription('');
    }

    return (
        <RightModal show={show} closeModal={handleClose} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add Stamp Duty </h1>
                <button className='border-0 bg-tr ' onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>

                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Select State'
                    value={state}
                    onChange={(value) => setState(value)}
                    options={statesList}
                />
                <InputText
                    placeholder='Enter Percentage'
                    label='Stamp Duty Charge (%)'
                    value={stampDuty}
                    onChange={(e) => setStampDuty(e.target.value)}
                />
                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Category'
                    value={category}
                    onChange={(value) => setCategory(value)}
                    options={categoryList}
                />
                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Include in price
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" value={true} onChange={() => setIncludePrice(true)} checked={includePrice} />
                            Yes
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" value={false} onChange={() => setIncludePrice(false)} checked={!includePrice} />
                            No
                        </label>
                    </div>

                    {/* <InputText
                        placeholder='Enter Percentage' /> */}
                </div>

                <InputTextarea
                    label='Description'
                    placeholder='Enter Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={handleClose}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddStampDutyModal
