import React, { useContext, useEffect, useState } from 'react'
import SearchBox from '../../components/SearchBox';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import BookindDetailsModal from './Modal/bookindDetails';
import DetailsModal from './Modal/details';
import UnitDetails from './Modal/unitDetails';
import EditUnitDetails from './Modal/editUnitDetails';
import TableView from './views/tableView';
import { getProjectsForFilter } from '../../services/private/leads.service';
import { getDetailsByLeadId, getEOIDetails, getUnitBooking, getUnitStatusEnum, getUnits, updateUnitAvailability } from '../../services/private/inventory.service';
import { getPricingBasePrice } from '../../services/public/public.service';
import InputSelect from '../../components/InputGroup/InputSelect';
import { getProjects } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import Consumer from '../../helpers/context';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        zIndex: 999
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const Inventory = () => {
    const [searchText, setSearchText] = useState('');
    const [showUnitDetailModal, setShowUnitDetailModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showUnitDetails, setShowUnitDetails] = useState(false);
    const [showEditUnitModal, setShowEditUnitModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [unitList, setUnitList] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState({});
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [leadsCount, setLeadsCount] = useState();
    const [tripleDot, setTripleDot] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [inventoryStats, setInventoryStats] = useState([]);
    const [inventoryStatsCount, setInventoryStatsCount] = useState({});
    const [basePriceData, setBasePriceData] = useState([]);
    const [basePriceConfigMap, setBasePriceConfigMap] = useState(null);
    const [isUnitTypePlot, setIsUnitTypePlot] = useState(false);

    const { allowedPermissions } = useContext(Consumer);
    /**
     * Base price config map
     * {
     *  config_id: rate (price)
     * }
     */

    const getAllProjects = async () => {
        const res = await getProjects();
        if (res.data.status === 200) {
            const mapped = res.data.data.projects.map(item => ({ ...item, label: item.name, value: item.uuid }))
            setProjects(mapped);
            if (localStorage.getItem('inventoryProject')) {
                setSelectedProject(JSON.parse(localStorage.getItem('inventoryProject')));
            } else {
                setSelectedProject(mapped[0]);
                localStorage.setItem('inventoryProject', JSON.stringify(mapped[0]))
            }
            // getProjectUnitLists(mapped[0].value);
        }
    }

    const getInventoryStats = async () => {
        const res = await getUnitStatusEnum();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ ...item, label: item.display_name, value: item.display_name }))
            setInventoryStats(list)
        }
    }
    useEffect(() => {
        getAllProjects();
        getInventoryStats();
        if (localStorage.getItem('inventoryProject')) {
            setSelectedProject(JSON.parse(localStorage.getItem('inventoryProject')));
        }
    }, []);

    const getProjectUnitLists = async (uuid) => {
        const payload = {
            query: {
                status: selectedStatus,
                searchText
            }
        }
        const res = await getUnits(uuid, showingResults, currentPage, payload);
        if (res.data.status === 200) {
            // console.log(res.data.data.units, 'units')
            const isPlot = res.data.data.units.every(item => item.configuration.unit_type === 'Plot')
            setIsUnitTypePlot(isPlot)
            setUnitList(res.data.data.units)
            setInventoryStatsCount(res.data.data.stats);
            let stats = selectedStatus ? res.data.data.stats[selectedStatus] : res.data.data.stats.total;
            const totalPages = Math.ceil(
                Number(stats) / showingResults
            );
            setMaxPage(totalPages);
            setLeadsCount(stats);
        }
    }

    const getBasePriceData = async (uuid) => {
        const res = await getPricingBasePrice(uuid);
        if (res.data.status === 200) {
            setBasePriceData(res.data.data);
            if (res.data.data && res.data.data.length) {
                let temp = {};
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];
                    if (element.config && element.config.length) {
                        temp[element.config[0].id] = element.rate
                    }
                }
                if (Object.keys(temp).length) {
                    setBasePriceConfigMap(temp);
                }
            }
        }
    }

    const getEOILeadDetails = async (uuid, detailShowOrEdit) => {
        const res = await getDetailsByLeadId(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedUnit(res.data.data);
                if (detailShowOrEdit === 'view') {
                    setShowUnitDetailModal(true)
                } else {
                    setShowEditUnitModal(true);
                }
            }
        }
    }

    useEffect(() => {
        if (selectedProject) {
            getProjectUnitLists(selectedProject.value);
            getBasePriceData(selectedProject.value);
        }

    }, [selectedStatus, searchText, showingResults, currentPage, selectedProject])

    const getSelectedUnitBookings = async (uuid, detailShowOrEdit, unit) => {
        try {
            const res = await getUnitBooking(uuid);
            if (res.data.status === 200) {
                setSelectedUnit(res.data.data);
                if (detailShowOrEdit === 'view') {
                    setShowUnitDetailModal(true)
                } else {
                    setShowEditUnitModal(true);
                }
            }
        } catch (err) {
            // toast.error(err.response.data.message);
            setSelectedUnit(unit);
            setShowUnitDetails(true)
        }
    }

    const handleMarkAvailableAndUnavailable = async (uuid, value) => {
        const res = await updateUnitAvailability(uuid, { status: value });
        if (res.data.status === 200) {
            getProjectUnitLists(selectedProject.value);
            toast.success(res.data.message);
        }
    }

    return (

        <div className="flex flex-col basis-[calc(100%-64px)] shrink-0 grow-0 w-[calc(100%-64px)] h-full">
            <div className="settings-title mb-16">
                <div className="flex flex-fill flex-wrap">
                    <div className="d-flex flex-fill ">
                        <h1 className="mb-0 mr-2 fz24 black h-font fw-po-medium">Inventory </h1>
                        <div className="form-group w-[200px] ml-2 ">
                            <InputSelect
                                value={selectedProject}
                                onChange={(val) => {
                                    setSelectedProject(val)
                                    // getProjectUnitLists(val.value)
                                    localStorage.setItem('inventoryProject', JSON.stringify(val))
                                }}
                                options={projects}
                                placeholder='Select Project'
                                styles={customStyles}
                                boxclass='!mr-0'
                            />
                        </div>
                    </div>
                    <div className="justify-content-md-end d-flex align-items-center gap-12">
                        <SearchBox searchText={searchText} setSearchText={setSearchText} placeholder={'Search for Units'} classname='mr-0' />
                        {/* <div>
                                <button
                                    className={`${style.filter_btn} ${style.filter_btn_active} inter fz14 fw-po-medium black-dark-700 inline-flex items-center`}
                                    onClick={() => setShowfilter(true)}
                                >
                                    <FilterIcon />
                                    Filter ({Object.keys(filters).length})
                                </button>
                            </div> */}
                        {/* <div className={`${style.view_grid_listBtngrp} d-sm-inline d-none`}>
                                <button className={`${isGridViewActive ? style.active : ''} flex items-center`} onClick={handleGridViewClick}
                                >
                                    <ViewgridIcon />
                                    Grid View
                                </button>                            
                                <button className={`${isListViewActive ? style.active : ''} flex items-center`} onClick={handleListViewClick}>
                                    <ViewlistIcon />
                                    List View
                                </button>
                            </div> */}

                    </div>
                </div>
            </div>
            <div className='grid grid-cols-6 gap-6 px-[20px]'>
                <div
                    className={`${!selectedStatus ? 'border-primary bg-blue-light' : ' border-grayLight'} border rounded-md p-2 cursor-pointer`}
                    onClick={() => setSelectedStatus(null)}
                >
                    <h4 className='m-0'>{inventoryStatsCount.total || '-'}</h4>
                    <p className='m-0 text-primary'>All</p>
                </div>
                {
                    inventoryStats.length > 0 && inventoryStats.map((stat, index) => (
                        <div
                            className={`${selectedStatus === stat.display_name ? 'border-primary bg-blue-light' : ' border-grayLight'} border rounded-md p-2 cursor-pointer`}
                            onClick={() => setSelectedStatus(stat.display_name)}
                            key={`${stat.uuid}_${index}`}
                        >
                            <h4 className='m-0'>{inventoryStatsCount[stat.display_name] || '-'}</h4>
                            <p className='m-0 text-primary'>{stat.display_name}</p>
                        </div>
                    ))
                }
                <div
                    className={`${selectedStatus === 'Pending_confirmation' ? 'border-primary bg-blue-light' : ' border-grayLight'} border rounded-md p-2 cursor-pointer`}
                    onClick={() => setSelectedStatus('Pending_confirmation')}

                >
                    <h4 className='m-0'>{inventoryStatsCount['Pending_confirmation'] || '-'}</h4>
                    <p className='m-0 text-primary'>Pending Confirmation</p>
                </div>
            </div>
            <div className='!pl-5 h-full flex flex-col !mt-5 overflow-auto'>
                <TableView
                    unitList={unitList}
                    basePriceData={basePriceData}
                    setSelectedUnit={setSelectedUnit}
                    setShowUnitDetailModal={setShowUnitDetailModal}
                    setShowEditUnitModal={setShowEditUnitModal}
                    searchText={searchText}
                    getSelectedUnitBookings={getSelectedUnitBookings}
                    handleMarkAvailableAndUnavailable={handleMarkAvailableAndUnavailable}
                    basePriceConfigMap={basePriceConfigMap}
                    allowedPermission={allowedPermissions || []}
                    getEOILeadDetails={getEOILeadDetails}
                    isUnitTypePlot={isUnitTypePlot}
                />

            </div>
            <LeadsFooterBar
                showingResults={showingResults}
                setShowingResults={setShowingResults}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                maxPage={maxPage}
                leadsCount={leadsCount}
                classname='!relative !py-3 !basis-0 !w-full'
            />

            {/* Unit details */}
            {showUnitDetailModal && <BookindDetailsModal
                show={showUnitDetailModal}
                selectedBooking={selectedUnit}
                closeModal={() => setShowUnitDetailModal(false)}
                handleViewMore={() => {
                    setShowUnitDetailModal(false);
                    setShowDetailsModal(true);
                }}
                editModal={() => {
                    setShowUnitDetailModal(false);
                    setShowEditUnitModal(true);
                }}
                allowedPermission={allowedPermissions || []}
            />}

            {showDetailsModal && <DetailsModal
                show={showDetailsModal}
                selectedBooking={selectedUnit}
                closeModal={() => setShowDetailsModal(false)}
                handleBack={() => {
                    setShowUnitDetailModal(true);
                    setShowDetailsModal(false);
                }}
                allowedPermission={allowedPermissions || []}
            />}
            {/* Unit details for non booked unit */}
            {
                showUnitDetails && <UnitDetails
                    selectedUnit={selectedUnit}
                    show={showUnitDetails}
                    selectedProject={selectedProject}
                    closeModal={() => setShowUnitDetails(false)} />
            }
            {
                showEditUnitModal && <EditUnitDetails
                    selectedUnit={selectedUnit}
                    show={showEditUnitModal}
                    selectedProject={selectedProject}
                    inventoryStats={inventoryStats}
                    getProjectUnitLists={getProjectUnitLists}
                    closeModal={() => setShowEditUnitModal(false)}
                    allowedPermission={allowedPermissions || []}
                />
            }
        </div>
    )
}

export default Inventory;