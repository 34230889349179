import React, { Component } from 'react';
import { eventEnum, intentEnum } from '../../../../config/enums';
import { ACTIVITY_VIEWS } from '../Detail Sidebar/ACTIVITY_VIEWS';

export default class IntentDropDown extends Component {
    constructor(props) {
        super(props);
    }

    clickHandler = (label, value) => {
        let event = '';
        if (label == intentEnum.Dead) {
            event = eventEnum.DEAD_LEAD_REMARK;
        }

        this.props.onClickHandler(value, event);
    };


    getClassName = (label) => {
        switch (label) {
            case 'New Lead':
                return 'pr-btn';
            case 'Cold Lead':
                return 'cold-btn';
            case 'Hot Lead':
                return 'hot-btn';
            case 'Warm Lead':
                return 'warm-btn';
            case 'Dead Lead':
                return 'dead-btn';
        }
    }
    render() {
        return <>
            {/* Intent Drodown */}
            <div className="intent-drop">
                <button className={this.getClassName(this.props.defaultLabel)} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.props.defaultLabel}
                    <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="white" />
                    </svg>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        this.props.options.map(option => {
                            return <a
                                key={option.value}
                                className={this.getClassName(option.label)}
                                onClick={() => this.clickHandler(option.label, option.value)}

                            >
                                {option.label}
                            </a>
                        })
                    }
                    {/* <a className="pr-btn" >New Lead</a>
                    <a className="cold-btn" >Cold Lead</a>
                    <a className="hot-btn" >Hot Lead</a>
                    <a className="warm-btn" >Warm Lead</a>
                    <a className="dead-btn" >Dead Lead</a> */}
                </div>
            </div>
        </>
    }
}