import React, { useState } from 'react'
import BasicModal from '../../Modals/BasicModal';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import { PaymentModeList } from '../../../helpers/enums';
import DateTimePicker from '../../InputGroup/DateTime';
import { uploadFile } from '../../../services/public/public.service';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg'
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import { addDebitTransaction } from '../../../services/private/inventory.service';
import InputTextarea from '../../InputGroup/InputTextarea';
import { toast } from 'react-toastify';

const TransactionReasonList = [
    {
        label: 'Stamp Duty',
        value: 'Stamp Duty'
    },
    {
        label: 'Legal Charges',
        value: 'Booking Refund'
    },
    {
        label: 'Maintenance Charges',
        value: 'Maintenance Charges'
    },
    {
        label: 'Assured Return',
        value: 'Assured Return'
    },
    {
        label: 'Token Refund',
        value: 'Booking Refund'
    },
    {
        label: 'Penalty',
        value: 'Penalty'
    },
    {
        label: 'Others',
        value: 'Others'
    }
];

const transationTypeList = [
    {
        label: 'Credit',
        value: 'Credit'
    },
    {
        label: 'Debit',
        value: 'Debit'
    }
]

const AddOtherTransaction = ({ show, closeModal, item }) => {
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [transactionReason, setTransactionReason] = useState('');
    const [amountPaid, setAmountPaid] = useState('');
    const [paymentProof, setPaymentProof] = useState([]);
    const [checkNo, setCheckNo] = useState('')
    const [checkDate, setCheckDate] = useState('')
    const [senderBankName, setSenderBankName] = useState('')
    const [senderBankAccount, setSenderBankAccount] = useState('');
    const [receiverBankName, setReceiverBankName] = useState('');
    const [receiverBankAccount, setReceiverAccount] = useState('');
    const [transactionDate, setTransactionDate] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [remarks, setRemarks] = useState('');
    const [transactionType, setTransactionType] = useState('');

    const uploadFileToS3 = async (file, index) => {
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }
            if (index >= 0) {
                let newProofs = [...paymentProof];
                newProofs[index] = newFile;
                setPaymentProof(newProofs)
            } else {
                setPaymentProof([...paymentProof, newFile])
            }

        }
    }

    const handleOnChange = async (e, index) => {
        uploadFileToS3(e.target.files[0], index);
    }

    const handleubmit = async () => {
        const payload = {
            type: transactionType,
            reason: transactionReason || null,
            remarks: remarks,
            amount: amountPaid || null,
            mode: paymentMode || null,
            cheque_no: checkNo || null,
            cheque_date: checkDate || null,
            transaction_date: transactionDate || null,
            amount_received_date: null,
            sender_bank_name: senderBankName || null,
            sender_bank_branch: null,
            sender_ifsc_code: null,
            sender_bank_account: senderBankAccount || null,
            receiver_bank_name: receiverBankName || null,
            receiver_bank_branch: null,
            receiver_ifsc_code: null,
            receiver_bank_account: receiverBankAccount || null,
            transaction_id: transactionId || null,
            status: null,
            documents: paymentProof
        }

        const res = await addDebitTransaction(item?.booking_uuid, payload);
        if (res.data.status === 200) {
            toast(res.data.message);
            console.log(res.data.data);
            closeModal();
        }
    }
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-7 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>

                        <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>Add Other Transaction  </h1>
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <div className=' pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <div className='flex items-start gap-x-6 '>
                        <div className='w-full p-6'>

                            <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5'>
                                <InputSelect
                                    label='Transaction Type'
                                    placeholder='Select Transaction Type'
                                    inputclass='!m-0'
                                    options={transationTypeList}
                                    value={transactionType ? transationTypeList.find(item => item.value === transactionType) : ''}
                                    onChange={(value) => setTransactionType(value.value)}
                                />
                                <InputSelect
                                    label='Transaction Reason'
                                    placeholder='Enter Transaction Reason'
                                    inputclass='!m-0'
                                    options={TransactionReasonList}
                                    value={transactionReason ? TransactionReasonList.find(item => item.value === transactionReason) : ''}
                                    onChange={(value) => setTransactionReason(value.value)}
                                />

                                <InputText
                                    inputclass='!m-0'
                                    label='Payment ID'
                                    placeholder='Enter Payment ID'
                                    value={paymentId}
                                    onChange={(e) => setPaymentId(e.target.value)}

                                />

                                <InputText
                                    inputclass='!m-0'
                                    label='Amt. Paid'
                                    placeholder='Enter Amt. Paid'
                                    showRupees={true}
                                    value={amountPaid}
                                    onChange={(e) => setAmountPaid(e.target.value)}
                                />

                                <InputSelect
                                    label='Mode Of Payment'
                                    placeholder='Select Mode Of Payment'
                                    inputclass='m-0 inter'
                                    isMulti={false}
                                    options={PaymentModeList}
                                    value={paymentMode ? PaymentModeList.find(item => item.value === paymentMode) : ''}
                                    onChange={(value) => { setPaymentMode(value.value) }}

                                />

                                {paymentMode === 'cheque' && <InputText
                                    label="Cheque No."
                                    placeholder="Enter Cheque No."
                                    type="text"
                                    name='check_no'
                                    inputclass='m-0 inter'
                                    value={checkNo}
                                    onChange={(e) => setCheckNo(e.target.value)}
                                />}

                                {paymentMode === 'cheque' && <DateTimePicker
                                    label='Cheque Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    value={checkDate}
                                    onValueChange={(date) => setCheckDate(date)}
                                    showTime={false}
                                />}


                                <InputText
                                    label="Sender Bank Name"
                                    placeholder="Enter Bank Name"
                                    type="text"
                                    name='senderBankName'
                                    inputclass='m-0 inter'
                                    value={senderBankName}
                                    onChange={(e) => setSenderBankName(e.target.value)}
                                />
                                <InputText
                                    label="Sender Bank A/C No. "
                                    placeholder="Enter Bank A/C No."
                                    type="text"
                                    name='bank_name'
                                    inputclass='m-0 inter'
                                    value={senderBankAccount}
                                    onChange={(e) => setSenderBankAccount(e.target.value)}
                                />

                                <InputText
                                    label="Receiver Bank Name"
                                    placeholder="Enter Bank Name"
                                    type="text"
                                    name='receiverBankName'
                                    inputclass='m-0 inter'
                                    value={receiverBankName}
                                    onChange={(e) => setReceiverBankName(e.target.value)}
                                />
                                <InputText
                                    label="Receiver Bank A/C No. "
                                    placeholder="Enter Bank A/C No."
                                    type="text"
                                    inputclass='m-0 inter'
                                    value={receiverBankAccount}
                                    onChange={(e) => setReceiverAccount(e.target.value)}
                                />
                                <DateTimePicker
                                    label='Transaction Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    showTime={false}
                                    value={transactionDate}
                                    onValueChange={(date) => setTransactionDate(date)}
                                />
                                <InputText
                                    label="Transaction Id "
                                    placeholder="Enter Transaction Id"
                                    type="text"
                                    inputclass='m-0 inter'
                                    value={transactionId}
                                    onChange={(e) => setTransactionId(e.target.value)}
                                />
                                <InputTextarea
                                    type="text"
                                    label="Remarks"
                                    placeholder="Enter Remarks"
                                    inputclass='m-0 inter'
                                    row='2'
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                />
                                <div>
                                    <label className='text-sm text-black700 !mb-2 inter'>Upload Payment Proof</label>
                                    <div className='relative group border border-grayLight h-[154px] rounded-lg flex items-center justify-center overflow-hidden'>
                                        <input type="file" className='d-none' id={`upload-payment-proof`} onChange={(e) => handleOnChange(e)} />
                                        <button
                                            type='button'
                                            className='!bg-primary flex items-center justify-center py-1 px-2 text-white gap-1 rounded-lg text-sm font-medium'
                                            onClick={() => {
                                                document.getElementById(`upload-payment-proof`).click();
                                            }}
                                        >
                                            <Pluswhite /> Upload
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div className='!mt-4'>

                                <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                                    {paymentProof?.length > 0 && paymentProof.map((doc, index) => (
                                        <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                            <input type="file" className='d-none' id={`upload-payment-proof-${index}`} onChange={(e) => handleOnChange(e, index)} />
                                            <figure className='mb-0 !table !mx-auto relative group'>
                                                {
                                                    ['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                        <img src={doc.url} />
                                                        :
                                                        <Imageplacehoder className='w-full ' />
                                                }
                                                <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                    <button
                                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                        onClick={() => {
                                                            document.getElementById(`upload-payment-proof-${index}`).click();
                                                        }}
                                                    ><PluswhiteIcon />Replace</button>

                                                    <button
                                                        className={`${style.delbtn} inter border-0 bg-white`}
                                                        onClick={() => {
                                                            let newFiles = [...paymentProof];
                                                            newFiles.splice(index, 1);
                                                            setPaymentProof(newFiles);

                                                        }}
                                                    ><TrashIcon /></button>
                                                </div>
                                            </figure>
                                            <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {doc.fileName}</p>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col"> */}

                <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col">
                    <button className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter inline-flex items-center justify-center gap-x-2">Cancel</button>
                    <button onClick={handleubmit} className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">Submit</button>
                </div>
                {/* </div> */}
            </div>
        </BasicModal>
    )
}

export default AddOtherTransaction