import moment from 'moment'
import React from 'react'
import { useContext } from 'react'
import Consumer from '../../helpers/authRoutes'
import EnrollmentCard from './enrollment-card'

const Overview = ({ workshop, createDuration, mentors, uuid, zoomLinkStatus }) => {
    let date = String(new Date(workshop.start_date)).split(" ")
    let hours = (Math.abs(new Date(workshop.end_time).getTime() - new Date(workshop.start_time).getTime()) / 36e5).toFixed(2)

    return (
        <>
            <div className='d-md-none mt-24'>
                <EnrollmentCard workshop={workshop} uuid={uuid} createDuration={createDuration} zoomLinkStatus={zoomLinkStatus} mentors={mentors} />
            </div>
            <div className="over-card">
                <h1 className="h-font text-center">Overview</h1>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="icon">
                                <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.8123 14.504H15.0495C14.7454 14.504 14.4963 14.7564 14.4963 15.0669V17.2215C14.4963 17.5321 14.7422 17.7844 15.0495 17.7844H17.8123C18.1164 17.7844 18.3656 17.5321 18.3656 17.2215V15.0669C18.3656 14.7564 18.1164 14.504 17.8123 14.504Z" fill="#0062FF"></path>
                                    <path d="M22.5069 14.504H19.7441C19.44 14.504 19.1909 14.7564 19.1909 15.0669V17.2215C19.1909 17.5321 19.4368 17.7844 19.7441 17.7844H22.5069C22.811 17.7844 23.0601 17.5321 23.0601 17.2215V15.0669C23.0601 14.7564 22.8143 14.504 22.5069 14.504Z" fill="#0062FF"></path>
                                    <path d="M8.42074 18.5317H5.65794C5.35384 18.5317 5.10474 18.7841 5.10474 19.0947V21.2492C5.10474 21.5598 5.35061 21.8122 5.65794 21.8122H8.42074C8.72485 21.8122 8.97395 21.5598 8.97395 21.2492V19.0947C8.97395 18.7841 8.72808 18.5317 8.42074 18.5317Z" fill="#0062FF"></path>
                                    <path d="M13.1185 18.5317H10.3557C10.0516 18.5317 9.80249 18.7841 9.80249 19.0947V21.2492C9.80249 21.5598 10.0484 21.8122 10.3557 21.8122H13.1185C13.4226 21.8122 13.6717 21.5598 13.6717 21.2492V19.0947C13.6685 18.7841 13.4226 18.5317 13.1185 18.5317Z" fill="#0062FF"></path>
                                    <path d="M17.8123 18.5317H15.0495C14.7454 18.5317 14.4963 18.7841 14.4963 19.0947V21.2492C14.4963 21.5598 14.7422 21.8122 15.0495 21.8122H17.8123C18.1164 21.8122 18.3656 21.5598 18.3656 21.2492V19.0947C18.3656 18.7841 18.1164 18.5317 17.8123 18.5317Z" fill="#0062FF"></path>
                                    <path d="M22.5069 18.5317H19.7441C19.44 18.5317 19.1909 18.7841 19.1909 19.0947V21.2492C19.1909 21.5598 19.4368 21.8122 19.7441 21.8122H22.5069C22.811 21.8122 23.0601 21.5598 23.0601 21.2492V19.0947C23.0601 18.7841 22.8143 18.5317 22.5069 18.5317Z" fill="#0062FF"></path>
                                    <path d="M8.42074 22.5596H5.65794C5.35384 22.5596 5.10474 22.8119 5.10474 23.1225V25.2771C5.10474 25.5877 5.35061 25.84 5.65794 25.84H8.42074C8.72485 25.84 8.97395 25.5877 8.97395 25.2771V23.1225C8.97395 22.8119 8.72808 22.5596 8.42074 22.5596Z" fill="#0062FF"></path>
                                    <path d="M13.1185 22.5596H10.3557C10.0516 22.5596 9.80249 22.8119 9.80249 23.1225V25.2771C9.80249 25.5877 10.0484 25.84 10.3557 25.84H13.1185C13.4226 25.84 13.6717 25.5877 13.6717 25.2771V23.1225C13.6685 22.8119 13.4226 22.5596 13.1185 22.5596Z" fill="#0062FF"></path>
                                    <path d="M17.8123 22.5596H15.0495C14.7454 22.5596 14.4963 22.8119 14.4963 23.1225V25.2771C14.4963 25.5877 14.7422 25.84 15.0495 25.84H17.8123C18.1164 25.84 18.3656 25.5877 18.3656 25.2771V23.1225C18.3656 22.8119 18.1164 22.5596 17.8123 22.5596Z" fill="#0062FF"></path>
                                    <path d="M20.3558 7.30261H21.1387C21.8602 7.30261 22.4457 6.8141 22.4457 6.2156V1.79965C22.4457 1.19792 21.8602 0.712646 21.1387 0.712646H20.3558C19.6344 0.712646 19.0488 1.20115 19.0488 1.79965V6.21237C19.0488 6.8141 19.6344 7.30261 20.3558 7.30261Z" fill="#0062FF"></path>
                                    <path d="M25.6837 3.1908H22.9468V6.53268C22.9468 7.46764 22.1898 8.22789 21.2516 8.22789H20.2422C19.304 8.22789 18.547 7.47087 18.547 6.53268V3.1908H9.17808V6.53268C9.17808 7.46764 8.42106 8.22789 7.48287 8.22789H6.47351C5.53532 8.22789 4.7783 7.47087 4.7783 6.53268V3.1908H2.04138C1.21966 3.1908 0.553223 3.85723 0.553223 4.67896V28.0107C0.553223 28.8324 1.21966 29.4989 2.04138 29.4989H25.6837C26.5054 29.4989 27.1719 28.8324 27.1719 28.0107V4.67896C27.1719 3.85723 26.5054 3.1908 25.6837 3.1908ZM24.7002 25.29C24.7002 26.1893 24.0338 26.914 23.2121 26.914H4.51302C3.6913 26.914 3.02486 26.1861 3.02486 25.29V14.724C3.02486 13.8247 3.6913 13.1 4.51302 13.1H23.2121C24.0338 13.1 24.7002 13.8279 24.7002 14.724V25.29Z" fill="#0062FF"></path>
                                    <path d="M6.58702 7.30261H7.36992C8.09136 7.30261 8.67691 6.8141 8.67691 6.2156V1.79965C8.67691 1.19792 8.09136 0.712646 7.36992 0.712646H6.58702C5.86559 0.712646 5.28003 1.20115 5.28003 1.79965V6.21237C5.28003 6.8141 5.86559 7.30261 6.58702 7.30261Z" fill="#0062FF"></path>
                                </svg>
                            </div>
                            <div>
                                <h5>Date</h5>
                                <h6> {workshop.start_date !== workshop.end_date ? moment(workshop.start_date).format("MMM DD") + "-" + moment(workshop.end_date).format("MMM DD, YYYY") : moment(workshop.start_date).format(" Do MMMM YYYY")}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="icon">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.4723 15.1059C2.4723 8.22116 8.06162 2.63185 14.9463 2.63185C21.831 2.63185 27.4203 8.22116 27.4203 15.1059C27.4203 21.9905 21.831 27.5799 14.9463 27.5799C11.4574 27.5799 8.30054 26.1444 6.03603 23.8319C5.76352 23.5536 5.349 23.4682 4.98821 23.615C4.62743 23.7628 4.39138 24.114 4.39138 24.5036V28.5394C4.39138 29.0691 4.82125 29.4989 5.35092 29.4989C5.88058 29.4989 6.31046 29.0691 6.31046 28.5394V26.6184C8.71698 28.4271 11.7069 29.4989 14.9463 29.4989C22.8903 29.4989 29.3394 23.0399 29.3394 15.1059C29.3394 7.16183 22.8903 0.712769 14.9463 0.712769C7.00229 0.712769 0.553223 7.16183 0.553223 15.1059C0.553223 15.6355 0.983096 16.0654 1.51276 16.0654C2.04243 16.0654 2.4723 15.6355 2.4723 15.1059ZM14.9463 4.55093C9.12095 4.55093 4.39138 9.28049 4.39138 15.1059C4.39138 20.9312 9.12095 25.6608 14.9463 25.6608C20.7717 25.6608 25.5012 20.9312 25.5012 15.1059C25.5012 9.28049 20.7717 4.55093 14.9463 4.55093ZM13.9868 9.34862V15.1059C13.9868 15.3601 14.0875 15.6048 14.2679 15.7843L18.1061 19.6224C18.4803 19.9966 19.0886 19.9966 19.4629 19.6224C19.8371 19.2482 19.8371 18.6398 19.4629 18.2656L15.9058 14.7086V9.34862C15.9058 8.81896 15.476 8.38908 14.9463 8.38908C14.4166 8.38908 13.9868 8.81896 13.9868 9.34862Z" fill="#0062FF"></path>
                                </svg>
                            </div>
                            <div>
                                <h5>Total Duration</h5>
                                <h6>{createDuration}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="icon">
                                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.879 15.3896C29.913 19.1828 29.6879 22.9738 29.2052 26.7363C29.1203 27.353 28.844 27.9275 28.4153 28.3788C27.9865 28.8301 27.427 29.1354 26.8155 29.2519C22.9844 29.8669 19.1055 30.1346 15.2262 30.0514C11.3469 30.1346 7.46797 29.8669 3.63684 29.2519C3.02534 29.1354 2.46579 28.8301 2.03706 28.3788C1.60833 27.9275 1.33203 27.353 1.2471 26.7363C0.758479 22.9742 0.527353 19.1832 0.555331 15.3896C0.521423 11.5964 0.746547 7.80532 1.22913 4.04281C1.31406 3.42615 1.59036 2.85169 2.01909 2.40039C2.44782 1.94909 3.00737 1.64371 3.61887 1.52729C7.45296 0.911668 11.3349 0.644053 15.2172 0.727719C19.0965 0.644596 22.9754 0.912208 26.8065 1.52729C27.418 1.64371 27.9776 1.94909 28.4063 2.40039C28.835 2.85169 29.1113 3.42615 29.1963 4.04281C29.6818 7.80511 29.91 11.5962 29.879 15.3896ZM20.4908 14.6349L12.6657 9.63084C12.5301 9.54383 12.3736 9.49489 12.2125 9.48916C12.0515 9.48344 11.8919 9.52113 11.7504 9.59829C11.6089 9.67546 11.4908 9.78925 11.4085 9.92775C11.3261 10.0662 11.2825 10.2244 11.2822 10.3855V20.3936C11.2825 20.5548 11.3261 20.7129 11.4085 20.8514C11.4908 20.9899 11.6089 21.1037 11.7504 21.1809C11.8919 21.258 12.0515 21.2957 12.2125 21.29C12.3736 21.2843 12.5301 21.2353 12.6657 21.1483L20.4908 16.1442C20.6169 16.0628 20.7205 15.951 20.7923 15.8192C20.8641 15.6874 20.9017 15.5397 20.9017 15.3896C20.9017 15.2395 20.8641 15.0918 20.7923 14.9599C20.7205 14.8281 20.6169 14.7164 20.4908 14.6349Z" fill="#0062FF"></path>
                                </svg>
                            </div>
                            <div>
                                <h5>Timing</h5>
                                <h6>{(new Date(workshop.start_time).getHours() + 24) % 12 || 12} {new Date(workshop.start_time).getHours() >= 12 ? 'PM' : 'AM'} - {(new Date(workshop.end_time).getHours() + 24) % 12 || 12} {new Date(workshop.end_time).getHours() >= 12 ? 'PM' : 'AM'}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                workshop.section_included_points &&
                workshop.section_included_points.length !== 0 &&
                !(workshop.section_included_points.every(element => element === null)) &&
                !(workshop.section_included_points.every(element => element === undefined)) &&
                <div className="what-you-lern">
                    <h2 className="h-font text-center">What you’ll learn in the workshop</h2>
                    <div className="row">
                        {
                            workshop.section_included_points && workshop.section_included_points.map((point, index) => {
                                return <div className="col-md-6" key={index}>
                                    {
                                        point !== '' && point !== null && point !== undefined && <div className="c-box">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 28.8333C23.0877 28.8333 28.8333 23.0876 28.8333 16C28.8333 8.91235 23.0877 3.16669 16 3.16669C8.91241 3.16669 3.16675 8.91235 3.16675 16C3.16675 23.0876 8.91241 28.8333 16 28.8333Z" fill="#0062FF" stroke="#0062FF"></path>
                                                <path d="M10 16.6667L14.6667 20L22 11.3334" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <span>{point}</span>
                                        </div>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            }
            {
                workshop.section_included &&
                <div className="wht-include">
                    <h2 className="h-font text-center">What's Included</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="blocks">
                                <div className="left-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.66683 9.99992H3.3335V33.3333C3.3335 35.1666 4.8335 36.6666 6.66683 36.6666H30.0002V33.3333H6.66683V9.99992ZM33.3335 3.33325H13.3335C11.5002 3.33325 10.0002 4.83325 10.0002 6.66659V26.6666C10.0002 28.3999 11.5002 29.9999 13.3335 29.9999H33.3335C35.1668 29.9999 36.6668 28.3999 36.6668 26.6666V6.66659C36.6668 4.83325 35.1668 3.33325 33.3335 3.33325ZM20.0002 24.1666V9.16659L30.0002 16.6666L20.0002 24.1666Z" fill="#0062FF"></path>
                                    </svg>
                                </div>
                                <div className="right-desc">
                                    <h3>Lifetime Access</h3>
                                    <p>Get lifetime access to the recordings of the masterclass with subscription of propacity platform. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="blocks">
                                <div className="left-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M33.3333 30.0001C35.1667 30.0001 36.65 28.5001 36.65 26.6667L36.6667 10.0001C36.6667 8.16675 35.1667 6.66675 33.3333 6.66675H6.66667C4.83333 6.66675 3.33333 8.16675 3.33333 10.0001V26.6667C3.33333 28.5001 4.83333 30.0001 6.66667 30.0001H1.66667C0.75 30.0001 0 30.7501 0 31.6667C0 32.5834 0.75 33.3334 1.66667 33.3334H38.3333C39.25 33.3334 40 32.5834 40 31.6667C40 30.7501 39.25 30.0001 38.3333 30.0001H33.3333ZM8.33333 10.0001H31.6667C32.5833 10.0001 33.3333 10.7501 33.3333 11.6667V25.0001C33.3333 25.9167 32.5833 26.6667 31.6667 26.6667H8.33333C7.41667 26.6667 6.66667 25.9167 6.66667 25.0001V11.6667C6.66667 10.7501 7.41667 10.0001 8.33333 10.0001Z" fill="#0062FF"></path>
                                        <path d="M13.3333 23.3333C14.2542 23.3333 15 22.5875 15 21.6667C15 20.7458 14.2542 20 13.3333 20C12.4125 20 11.6667 20.7458 11.6667 21.6667C11.6667 22.5875 12.4125 23.3333 13.3333 23.3333ZM13.3333 24.1667C12.2208 24.1667 10 24.725 10 25.8333V26.6667H16.6667V25.8333C16.6667 24.725 14.4458 24.1667 13.3333 24.1667Z" fill="#0062FF"></path>
                                        <path d="M26.6668 23.3333C27.5877 23.3333 28.3335 22.5875 28.3335 21.6667C28.3335 20.7458 27.5877 20 26.6668 20C25.746 20 25.0002 20.7458 25.0002 21.6667C25.0002 22.5875 25.746 23.3333 26.6668 23.3333ZM26.6668 24.1667C25.5543 24.1667 23.3335 24.725 23.3335 25.8333V26.6667H30.0002V25.8333C30.0002 24.725 27.7793 24.1667 26.6668 24.1667Z" fill="#0062FF"></path>
                                        <path d="M26.6668 14.4444C27.5877 14.4444 28.3335 13.6986 28.3335 12.7778C28.3335 11.8569 27.5877 11.1111 26.6668 11.1111C25.746 11.1111 25.0002 11.8569 25.0002 12.7778C25.0002 13.6986 25.746 14.4444 26.6668 14.4444ZM26.6668 15.2778C25.5543 15.2778 23.3335 15.8361 23.3335 16.9444V17.7778H30.0002V16.9444C30.0002 15.8361 27.7793 15.2778 26.6668 15.2778Z" fill="#0062FF"></path>
                                        <path d="M13.3333 14.4444C14.2542 14.4444 15 13.6986 15 12.7778C15 11.8569 14.2542 11.1111 13.3333 11.1111C12.4125 11.1111 11.6667 11.8569 11.6667 12.7778C11.6667 13.6986 12.4125 14.4444 13.3333 14.4444ZM13.3333 15.2778C12.2208 15.2778 10 15.8361 10 16.9444V17.7778H16.6667V16.9444C16.6667 15.8361 14.4458 15.2778 13.3333 15.2778Z" fill="#0062FF"></path>
                                        <path d="M5.55566 18.3889H33.3334" stroke="#0062FF"></path>
                                        <path d="M20.0513 32.2222V8.88882" stroke="#0062FF"></path>
                                    </svg>
                                </div>
                                <div className="right-desc">
                                    <h3>Specialized workshops</h3>
                                    <p>Access to specialized workshops
                                        Get free access to future topic specific workshops regularly organized by propacity. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="blocks">
                                <div className="left-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.3335 2.22217H5.22239C4.1835 2.22217 3.34294 3.07217 3.34294 4.11106L3.3335 21.1111L7.11127 17.3333H20.3335C21.3724 17.3333 22.2224 16.4833 22.2224 15.4444V4.11106C22.2224 3.07217 21.3724 2.22217 20.3335 2.22217ZM8.05572 8.83328H17.5002C18.0196 8.83328 18.4446 9.25828 18.4446 9.77772C18.4446 10.2972 18.0196 10.7222 17.5002 10.7222H8.05572C7.53627 10.7222 7.11127 10.2972 7.11127 9.77772C7.11127 9.25828 7.53627 8.83328 8.05572 8.83328ZM13.7224 13.5555H8.05572C7.53627 13.5555 7.11127 13.1305 7.11127 12.6111C7.11127 12.0916 7.53627 11.6666 8.05572 11.6666H13.7224C14.2418 11.6666 14.6668 12.0916 14.6668 12.6111C14.6668 13.1305 14.2418 13.5555 13.7224 13.5555ZM17.5002 7.88883H8.05572C7.53627 7.88883 7.11127 7.46384 7.11127 6.94439C7.11127 6.42495 7.53627 5.99995 8.05572 5.99995H17.5002C18.0196 5.99995 18.4446 6.42495 18.4446 6.94439C18.4446 7.46384 18.0196 7.88883 17.5002 7.88883Z" fill="#0062FF"></path>
                                        <path d="M34.7776 20.7778H19.6665V32.1111H33.6726L34.7776 33.2161V20.7778ZM34.7776 18.8889C35.8165 18.8889 36.6571 19.7389 36.6571 20.7778L36.6665 37.7778L32.8887 34H19.6665C18.6276 34 17.7776 33.15 17.7776 32.1111V20.7778C17.7776 19.7389 18.6276 18.8889 19.6665 18.8889H34.7776ZM29.1109 29.2778C29.1109 28.7562 28.6881 28.3334 28.1665 28.3334H22.3998C21.9782 28.3334 21.5554 28.7562 21.5554 29.2778C21.5554 29.7994 21.9782 30.2222 22.3998 30.2222H28.1665C28.6881 30.2222 29.1109 29.7994 29.1109 29.2778ZM32.8887 26.4445C32.8887 25.9229 32.4659 25.5 31.9443 25.5H22.3998C21.9782 25.5 21.5554 25.9229 21.5554 26.4445C21.5554 26.9661 21.9782 27.3889 22.3998 27.3889H31.9443C32.4659 27.3889 32.8887 26.9661 32.8887 26.4445ZM32.8887 23.6111C32.8887 23.0895 32.4659 22.6667 31.9443 22.6667H22.3998C21.9782 22.6667 21.5554 23.0895 21.5554 23.6111C21.5554 24.1327 21.9782 24.5556 22.3998 24.5556H31.9443C32.4659 24.5556 32.8887 24.1327 32.8887 23.6111Z" fill="#0062FF"></path>
                                    </svg>
                                </div>
                                <div className="right-desc">
                                    <h3>Live Q&A</h3>
                                    <p>Get lifetime access to the weekly Q&A sessions and get all your doubts regarding real estate marketing solved.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="blocks">
                                <div className="left-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.75 6.25H10C8.625 6.25 7.5 7.375 7.5 8.75V25C7.5 25.6875 8.0625 26.25 8.75 26.25C9.4375 26.25 10 25.6875 10 25V10C10 9.3125 10.5625 8.75 11.25 8.75H23.75C24.4375 8.75 25 8.1875 25 7.5C25 6.8125 24.4375 6.25 23.75 6.25ZM24.4875 11.9875L30.525 18.025C30.9875 18.4875 31.25 19.125 31.25 19.7875V31.25C31.25 32.625 30.125 33.75 28.75 33.75H14.9875C13.6125 33.75 12.5 32.625 12.5 31.25L12.5125 13.75C12.5125 12.375 13.625 11.25 15 11.25H22.7125C23.375 11.25 24.0125 11.5125 24.4875 11.9875ZM23.75 20H29.375L22.5 13.125V18.75C22.5 19.4375 23.0625 20 23.75 20Z" fill="#0062FF"></path>
                                    </svg>
                                </div>
                                <div className="right-desc">
                                    <h3>Resources</h3>
                                    <p>Get templates for creatives for all major project types which can be used for your own campaigns.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

            {mentors && mentors.length > 0 &&
                mentors.map((mentor_data, index) => {
                    return (
                        <div className="director" key={`${index}_${mentor_data.name}`}>
                            <div className="row">
                                <div className="col-md-4">
                                    <figure className='mb-0'>
                                        <img src={mentor_data.picture_url} className='w-100' />
                                    </figure>
                                </div>
                                <div className="col-md-8">
                                    <h1 className="h-font">{mentor_data.name}</h1>
                                    <h3 className="h-font">{mentor_data.designation}</h3>
                                    <p>
                                        {mentor_data.short_description}
                                    </p>
                                    <figure className='d-flex mb-0 align-items-center flex-wrap' >
                                        {mentor_data.logo_1 && <img src={mentor_data.logo_1} style={{ height: "52px" }} />}
                                        {mentor_data.logo_2 && <img src={mentor_data.logo_2} style={{ height: "52px" }} />}
                                        {mentor_data.logo_3 && <img src={mentor_data.logo_3} style={{ height: "52px" }} />}
                                    </figure>
                                </div>
                            </div>
                        </div>
                    )
                })
            }


            {
                workshop.certificate_section ?
                    <div className="yes">
                        <h1 className="h-font black text-center fz24">Yes, you will be certified!</h1>
                        <div className="row d-flex align-items-center">
                            <div className="col-md-6">
                                <div className="part">
                                    <div className="left-part">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <path d="M20 7C12.8295 7 7 12.8295 7 20C7 27.1705 12.8295 33 20 33C27.1705 33 33 27.1705 33 20C33 12.8295 27.1705 7 20 7ZM26.2947 18.8232L23.9137 21.0127C23.7769 21.1496 23.7221 21.341 23.7496 21.5327L24.4063 24.6525C24.5705 25.4188 23.7494 25.9936 23.0652 25.6105L20.2738 24.0232C20.1096 23.9411 19.8906 23.9411 19.7264 24.0232L16.9348 25.6105C16.2506 25.9937 15.457 25.3916 15.5937 24.6525L16.2506 21.5053C16.2779 21.3136 16.2233 21.1221 16.0864 20.9852L13.7054 18.7957C13.1306 18.2756 13.4317 17.3179 14.2255 17.2356L17.4276 16.8797C17.6192 16.8524 17.7834 16.7429 17.8655 16.5514L19.179 13.6231C19.5073 12.9115 20.4926 12.9115 20.8212 13.6231L22.1623 16.5516C22.2443 16.7158 22.4085 16.8526 22.6002 16.8799L25.8023 17.2358C26.5684 17.3179 26.8695 18.2756 26.2948 18.8232H26.2947Z" fill="#0062FF" />
                                        </svg>
                                    </div>
                                    <div className="right-part">
                                        <h4>Official and Verified</h4>
                                        <p>Bag an instructor signed certificate to verify your achievements and increase your career prospects.</p>
                                    </div>
                                </div>
                                <div className="part">
                                    <div className="left-part">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <path d="M25.1667 11.5347H29.1548C29.1125 11.4803 29.0728 11.4245 29.0233 11.375L24.782 7.12934C24.7338 7.08081 24.6786 7.04171 24.625 7.00006V10.9927C24.625 11.2915 24.868 11.5347 25.1667 11.5347L25.1667 11.5347Z" fill="#0062FF" />
                                            <path d="M25.1667 12.9672C24.2706 12.9672 23.5417 12.2375 23.5417 11.3406V7H11.6251C10.7313 7 10 7.73209 10 8.62663V31.3734C10 32.2734 10.7313 33 11.6251 33H27.8753C28.7691 33 29.5004 32.2734 29.5004 31.3734V12.9668L25.1667 12.9672ZM16.4999 23.234C16.4999 23.3586 16.4836 23.4831 16.462 23.6024L23.4876 26.7604C23.8831 26.2672 24.4843 25.9422 25.1669 25.9422C26.3585 25.9422 27.3336 26.9173 27.3336 28.1089C27.3336 29.3061 26.3587 30.2757 25.1669 30.2757C23.9697 30.2757 23.0002 29.3061 23.0002 28.1089C23.0002 27.9844 23.0165 27.8653 23.0381 27.746L16.0125 24.5881C15.617 25.0811 15.0158 25.4005 14.3332 25.4005C13.136 25.4005 12.1664 24.4309 12.1664 23.2338C12.1664 22.0422 13.136 21.067 14.3332 21.067C15.0157 21.067 15.6169 21.392 16.0125 21.885L23.0381 18.727C23.0165 18.6082 23.0003 18.4834 23.0003 18.3588C23.0003 17.1672 23.9699 16.1921 25.1671 16.1921C26.3587 16.1921 27.3338 17.1672 27.3338 18.3588C27.3338 19.556 26.3589 20.5256 25.1671 20.5256C24.4845 20.5256 23.8833 20.2061 23.488 19.7132L16.4624 22.8711C16.4838 22.9902 16.4999 23.1094 16.4999 23.234L16.4999 23.234Z" fill="#0062FF" />
                                        </svg>
                                    </div>
                                    <div className="right-part">
                                        <h4>Easily Shareable</h4>
                                        <p>Add the certificate to your profile/CV/resume, share on LInkedin and other social media platforms.</p>
                                    </div>
                                </div>
                                <div className="part">
                                    <div className="left-part">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <path d="M31.0841 9.88751C24.5739 9.26208 20.9351 6.33393 20.8782 6.27722C20.4233 5.90759 19.7695 5.90759 19.3146 6.27722C19.2862 6.30558 15.6474 9.26228 9.10872 9.88771C8.48329 9.94463 8 10.4848 8 11.11C8 27.6555 19.144 32.6874 19.6273 32.8864C19.7979 32.9433 19.94 33 20.1106 33C20.2811 33 20.4517 32.9716 20.5938 32.8864C21.0771 32.6873 32.2211 27.6555 32.2211 11.11C32.1928 10.4846 31.7095 9.94442 31.0841 9.88751ZM20.0822 25.2958C16.6423 25.2958 13.8848 22.5099 13.8848 19.0984C13.8848 15.687 16.6422 12.9009 20.0822 12.9009C23.5223 12.9009 26.2797 15.6869 26.2797 19.0984C26.2797 22.51 23.5223 25.2958 20.0822 25.2958Z" fill="#0062FF" />
                                            <path d="M21.5332 17.1811L19.1736 19.5407L18.2355 18.6026C17.8092 18.1762 17.0985 18.1762 16.6719 18.6026C16.2455 19.0289 16.2455 19.7396 16.6719 20.1662L18.3777 21.872C18.6051 22.0995 18.8893 22.1847 19.1737 22.1847C19.4581 22.1847 19.7422 22.0711 19.9697 21.872L23.1253 18.7164C23.5516 18.2901 23.5516 17.5794 23.1253 17.1528C22.6706 16.7548 21.9599 16.7548 21.5333 17.1812L21.5332 17.1811Z" fill="#0062FF" />
                                        </svg>
                                    </div>
                                    <div className="right-part">
                                        <h4>Enhances Credibility</h4>
                                        <p>Use your certificate to strengthen your professional credibility and stand out among your peers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={`${process.env.PUBLIC_URL}/certificate.png`} />
                            </div>
                        </div>
                    </div> : ""
            }


        </>
    )
}

export default Overview