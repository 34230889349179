
import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ style, name }) => {
    return (
        <nav aria-label="breadcrumb" className={`${style.LDP_breadcrumb} ${style.container} fz14  inner-page-breadcrumb `}>
            <ul className="d-flex align-items-center justify-content-start flex-row mb-0">
                <li>
                    <Link to='/learning-center'>Learning Center</Link>
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                </svg>
                    <Link onClick={() => localStorage.setItem('tab', 'courses')} to='/learning-center'>Courses</Link>
                </li>
                <li className={style.pagetitle}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                </svg>
                    <span>{name}</span>
                </li>

            </ul>
        </nav>
    )
}

export default Breadcrumb
