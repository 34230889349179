import React from 'react'

import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';

import { ReactComponent as AlertIcon } from '../../../../assets/icons/alert.svg';
import CenterModal from '../../../../components/Modals/center-modal';

const ConfirmDeleteModal = ({ show, closeModal, handleSubmit }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!max-w-[450px]  [&>*]:!border-0 [&>*]:!rounded-lg [&>*]:!overflow-hidden' backDropClassName={''}>
            <div className=' relative !p-5'>
                <div>
                    <span className='mb-4 h-12 w-12 bg-[#FEE4E2] rounded-full flex items-center justify-center'>
                        <AlertIcon />
                    </span>
                    <button onClick={closeModal} className='absolute right-5 top-5'><CloseIcon /></button>
                </div>

                <div className='!mt-4'>
                    <h4 className='text-xl font-semibold m-0'>Are you sure? </h4>
                    <div className='text-black700 text-sm font-medium !mb-3'>Are you sure you want to leave us so soon?</div>
                    <div className='!mt-5 flex items-center !gap-4'>
                        <button
                            className='w-1/2 text-black700 border rounded-lg flex items-center justify-center !py-3'
                        // onClick={handleSubmit}
                        >
                            Cancel
                        </button>
                        <button
                            className='w-1/2 bg-red text-white rounded-lg flex items-center justify-center !py-3'
                            onClick={handleSubmit}
                        >

                            Yes
                        </button>
                    </div>
                </div>
            </div>

        </CenterModal>
    )
}

export default ConfirmDeleteModal;