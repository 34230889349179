import React, { useEffect, useState } from 'react'
import BasicModal from '../../Modals/BasicModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as DownIcon } from '../../../assets/icons/Down.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg'
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import pdfimg from '../../../assets/icons/pdfview.jpg'
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import DateTimePicker from '../../InputGroup/DateTime';
import { BankList, PaidBy, PaymentModeList } from '../../../helpers/enums';
import { addCollection, getBankDetails, getPendingDemandByBookingId, getMilestoneByDemand } from '../../../services/private/inventory.service';
import { toINRFormat } from '../../../helpers/helpers';
import { toast } from 'react-toastify';
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import { uploadFile } from '../../../services/public/public.service';
import InputRupees from '../../InputGroup/InputRupees';


const AddCollection = ({ show, closeModal, item, onSuccess, selectedProject, allBookings }) => {

    const [selectedDemand, setSelectedDemand] = useState('');
    const [demandList, setDemadList] = useState([])
    const [totalAmountPaid, setTotalAmountPaid] = useState('');
    const [amountPaid, setAmountPaid] = useState('');
    const [interestPaid, setInterestPaid] = useState('');
    const [gstPaid, setGSTPaid] = useState('');
    const [paidBy, setPaidBy] = useState('');
    const [senderBankName, setSenderBankName] = useState('');
    const [senderAccountNo, setSenderAccountNo] = useState('');
    const [receiverBankName, setReceiverBankName] = useState('');
    const [receiverAccountNo, setReceiverAccountNo] = useState('');
    const [senderBankBranch, setSenderBankBranch] = useState('');
    const [receiverBankBranch, setReceiverBankBranch] = useState('');
    const [senderBankIfsc, setSenderBankIfsc] = useState('');
    const [receiverBankIfsc, setReceiverBankIfsc] = useState('');
    const [remarks, setRemarks] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentSenderBankName, setPaymentSenderBankName] = useState('');
    const [chequeDate, setChequeDate] = useState('')
    const [chequeNo, setChequeNo] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [transactionDate, setTransactionDate] = useState('');
    const [amountReceivedDate, setAmountReceivedDate] = useState('');
    const [paymentDocs, setPaymentDocs] = useState([]);
    const [receiverBankList, setReceiverBankList] = useState([]);
    const [milestoneInfo, setMilestoneInfo] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState({});
    const [error, setError] = useState(false);

    const getPreviousDemand = async (uuid) => {
        const res = await getPendingDemandByBookingId(uuid);
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ ...item, label: `#${item.unique_id} - Due ₹ ${toINRFormat(item.total_amount_due)}`, value: item.unique_id }))
            setDemadList(mapped)
        }
    }

    const receiverBankDetails = async () => {
        const res = await getBankDetails(selectedProject.uuid);
        if (res.data.status === 200) {
            const mappedData = res.data.data.map(item => ({ ...item, label: item.bank_name, value: item.uuid }))
            setReceiverBankList(mappedData);
        }
    }

    const getMilestoneInfo = async (demandUUID) => {
        const res = await getMilestoneByDemand(demandUUID);
        if (res.data.status === 200) {
            setMilestoneInfo(res.data.data);
        } else {
            toast.warn(res.data.message);
        }
    }

    useEffect(() => {
        if (selectedDemand?.uuid) {
            getMilestoneInfo(selectedDemand.uuid);
        }
    }, [selectedDemand])

    useEffect(() => {
        if (selectedProject?.uuid) {
            receiverBankDetails();
        }
    }, [selectedProject])

    useEffect(() => {
        if (item?.booking_uuid) {
            setSelectedBooking(item)
            getPreviousDemand(item?.booking_uuid)
        }

    }, [item]);

    useEffect(() => {
        let amount = 0;
        if (amountPaid) {
            amount += parseInt(amountPaid)
        }
        if (interestPaid) {
            amount += parseInt(interestPaid)
        }
        if (gstPaid) {
            amount += parseInt(gstPaid)
        }

        setTotalAmountPaid(amount)
    }, [amountPaid, interestPaid, gstPaid])

    useEffect(() => {
        if (item?.demand_unique_id && demandList.length > 0) {
            const demand = demandList.find(i => i.unique_id === item?.demand_unique_id);
            setSelectedDemand(demand);
        }
    }, [item, demandList]);

    const uploadFileToS3 = async (file, index) => {
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }

            if (index > 0) {
                let docs = [...paymentDocs];
                docs[index] = newFile
                setPaymentDocs(docs)
            } else {
                setPaymentDocs([...paymentDocs, newFile])
            }

        }
    }

    const handleOnChange = async (e) => {
        const files = [...e.target.files];
        files.forEach((file, index) => {
            uploadFileToS3(file);
        });
    }

    const handleReplaceDocumnet = async (e, index) => {
        uploadFileToS3(e.target.files[0], index)
    }

    const handleSubmit = async () => {
        if (!selectedDemand?.id || !amountPaid || !totalAmountPaid || !paymentMode || !transactionDate) {
            if (!selectedDemand?.id || !amountPaid || !totalAmountPaid) {
                document.getElementById('collection').scrollIntoView({ behavior: 'smooth' })
            }
            if (!paymentMode || !transactionDate) {
                document.getElementById('payment').scrollIntoView({ behavior: 'smooth' })
            }
            setError(true);
            return;
        } else {
            setError(false);
        }
        const payload = {
            collection: {
                demand_id: selectedDemand?.id,
                paid_amount: amountPaid || null,
                gst_paid: gstPaid || null,
                interest_paid: interestPaid || null,
                total_amount_paid: totalAmountPaid || null,
                paid_by: paidBy,
                remarks: remarks
            },
            transaction: {
                mode: paymentMode,
                cheque_no: chequeNo || null,
                cheque_date: chequeDate || null,
                transaction_date: transactionDate || null,
                transaction_id: transactionId || null,
                amount_received_date: amountReceivedDate || null,
                sender_bank_name: senderBankName,
                sender_bank_branch: senderBankBranch || null,
                sender_ifsc_code: senderBankIfsc || null,
                sender_bank_account: senderAccountNo,
                receiver_bank_name: receiverBankName,
                receiver_bank_branch: receiverBankBranch || null,
                receiver_ifsc_code: receiverBankIfsc || null,
                receiver_bank_account: receiverAccountNo || null,
                documents: paymentDocs.length > 0 ? paymentDocs : null
            }
        }
        const res = await addCollection(selectedBooking?.booking_uuid, payload)
        if (res.data.status === 200) {
            toast.success(res.data.message);
            onSuccess();
            closeModal();
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[1216px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-7 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>

                        <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>Add Collection {selectedBooking?.customer_name ? `for ${selectedBooking?.customer_name}` : ''} </h1>
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <div className=' pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <div className='flex items-start gap-x-6 '>
                        <div className='w-full p-6'>
                            <div className='grid grid-cols-2'>
                                <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Collection</h2>
                                {Object.keys(item).length === 0 && <InputSelect
                                    // label='Select Demand'
                                    options={allBookings}
                                    value={selectedBooking ? allBookings?.find(item => item.value === selectedBooking?.value) : ''}
                                    onChange={(value) => {
                                        setSelectedBooking(value)
                                        getPreviousDemand(value.value)
                                    }}
                                />}
                            </div>
                            <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5' id='collection'>
                                <div>
                                    <InputSelect
                                        label='Select Demand'
                                        options={demandList}
                                        value={selectedDemand ? demandList.find(item => item.value === selectedDemand.value) : ''}
                                        onChange={(value) => setSelectedDemand(value)}
                                        error={(error && !selectedDemand) ? 'Please Select Demand' : ''}
                                        required={true}
                                    />
                                    <div>
                                        {milestoneInfo.map(milestone => {
                                            return <div>
                                                M{milestone.count} - {milestone.description}
                                            </div>
                                        })}

                                    </div>
                                </div>

                                <div>
                                    <InputRupees
                                        inputclass='!m-0'
                                        label='Amount Paid'
                                        placeholder='Enter Amount Paid'
                                        value={amountPaid}
                                        onChange={(value) => setAmountPaid(value)}
                                        showRupees={true}
                                        error={(error && !amountPaid) ? 'Please Enter Amount Paid' : ''}
                                        required={true}
                                        handleBlur={() => { }}
                                    />
                                    <div>
                                        Due - ₹ {toINRFormat(selectedDemand?.due_amount || '0')}
                                    </div>
                                </div>
                                <div>
                                    <InputRupees
                                        inputclass='!m-0'
                                        label='Interest Paid'
                                        placeholder='Enter Interest Paid'
                                        value={interestPaid}
                                        onChange={(value) => setInterestPaid(value)}
                                        handleBlur={() => { }}
                                    />
                                    <div>
                                        Due - ₹ {selectedDemand?.interest_due || '0'}
                                    </div>
                                </div>
                                <div>
                                    <InputRupees
                                        inputclass='!m-0'
                                        label='GST Paid'
                                        placeholder='Enter GST Paid'
                                        value={gstPaid}
                                        onChange={(value) => setGSTPaid(value)}
                                        handleBlur={() => { }}
                                    />
                                    <div>
                                        Due - ₹ {toINRFormat(parseInt(selectedDemand?.interest_gst || 0) + parseInt(selectedDemand?.gst_amount || 0))}
                                    </div>
                                </div>
                                <div>
                                    <InputRupees
                                        inputclass='!m-0'
                                        label='Total Amt. Paid'
                                        placeholder='Enter Total Amt. Paid'
                                        value={totalAmountPaid}
                                        onChange={(value) => setTotalAmountPaid(value)}
                                        showRupees={true}
                                        error={(error && !totalAmountPaid) ? 'Please Enter Total Amt. Paid' : ''}
                                        required={true}
                                        disable={true}
                                        handleBlur={() => { }}
                                    />
                                    <div>
                                        Due - ₹ {toINRFormat(selectedDemand?.total_amount_due || '0')}
                                    </div>
                                </div>
                                <InputSelect
                                    label='Paid By'
                                    placeholder='Enter Paid By'
                                    inputclass='!m-0'
                                    value={paidBy ? PaidBy.find(item => item.value === paidBy) : ''}
                                    onChange={(value) => { setPaidBy(value.value) }}
                                    options={PaidBy}
                                />
                            </div>
                            <div className='!mt-5'>
                                <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Bank Details</h2>
                            </div>
                            <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5'>

                                <InputSelect
                                    inputclass='!m-0'
                                    label='Sender Bank Name'
                                    // placeholder='Enter Sender Bank Name'                                    
                                    value={senderBankName ? BankList.find(item => item.value === senderBankName) : ''}
                                    options={BankList}
                                    onChange={(value) => setSenderBankName(value.value)}
                                />
                                <InputText
                                    inputclass='!m-0'
                                    label='Sender Account No.'
                                    placeholder='Enter Sender Account No.'
                                    value={senderAccountNo}
                                    onChange={(e) => setSenderAccountNo(e.target.value)}
                                />
                                <InputText
                                    inputclass='!m-0'
                                    label='Sender Bank Branch'
                                    placeholder='Enter Sender Bank Branch'
                                    value={senderBankBranch}
                                    onChange={(e) => setSenderBankBranch(e.target.value)}
                                />
                                <InputText
                                    inputclass='!m-0'
                                    label='Sender Bank IFSC Code'
                                    placeholder='Enter Sender Bank IFSC Code'
                                    value={senderBankIfsc}
                                    onChange={(e) => setSenderBankIfsc(e.target.value)}
                                />
                                <InputSelect
                                    inputclass='!m-0'
                                    label='Receiver Bank Name '
                                    // placeholder='Enter Receiver Bank Name '
                                    value={receiverBankName ? receiverBankList.find(item => item.label === receiverBankName) : ''}
                                    options={receiverBankList}
                                    onChange={(value) => {
                                        setReceiverBankName(value.bank_name)
                                        setReceiverAccountNo(value.account_no);
                                        setReceiverBankBranch(value.bank_branch);
                                        setReceiverBankIfsc(value.ifsc);
                                    }}
                                />
                                <InputText
                                    inputclass='!m-0'
                                    label='Receiver Account No.'
                                    placeholder='Enter Receiver Account No.'
                                    value={receiverAccountNo}
                                    onChange={(e) => setReceiverAccountNo(e.target.value)}
                                    disable={true}
                                />

                                <InputText
                                    inputclass='!m-0'
                                    label='Receiver Bank Branch'
                                    placeholder='Enter Receiver Bank Branch'
                                    value={receiverBankBranch}
                                    onChange={(e) => setReceiverBankBranch(e.target.value)}
                                    disable={true}
                                />
                                <InputText
                                    inputclass='!m-0'
                                    label='Receiver Bank IFSC Code'
                                    placeholder='Enter Receiver Bank IFSC Code'
                                    value={receiverBankIfsc}
                                    onChange={(e) => setReceiverBankIfsc(e.target.value)}
                                    disable={true}
                                />
                                <div className='col-span-2'>
                                    <InputTextarea
                                        label='Comments'
                                        placeholder='Enter your comments...'
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='!mt-5'>
                                <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Payment Details</h2>
                            </div>

                            <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5' id='payment'>

                                <InputSelect
                                    label='Payment Mode'
                                    placeholder='Select Payment Mode'
                                    inputclass='m-0 inter'
                                    isMulti={false}
                                    options={PaymentModeList}
                                    value={paymentMode ? PaymentModeList.find(item => item.value === paymentMode) : ''}
                                    onChange={(value) => setPaymentMode(value.value)}
                                    error={(error && !paymentMode) ? 'Please Select Payment Mode' : ''}
                                    required={true}

                                />

                                {paymentMode === 'cheque' && <InputText
                                    label="Cheque No."
                                    placeholder="Enter Cheque No."
                                    type="text"
                                    name='check_no'
                                    value={chequeNo}
                                    inputclass='m-0 inter'
                                    onChange={(e) => {
                                        setChequeNo(e.target.value)
                                    }}
                                />}
                                <DateTimePicker
                                    label='Transaction Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    value={transactionDate}
                                    onValueChange={(date) => {
                                        setTransactionDate(date)
                                    }}
                                    showTime={false}
                                    required={true}
                                    error={(error && !transactionDate) ? 'Please Select Transaction Date' : ''}
                                />
                                <InputText
                                    label="Transaction Id"
                                    placeholder="Enter Transaction Id"
                                    type="text"
                                    name='check_no'
                                    value={transactionId}
                                    inputclass='m-0 inter'
                                    onChange={(e) => {
                                        setTransactionId(e.target.value)
                                    }}
                                />
                                {paymentMode === 'cheque' && <DateTimePicker
                                    label='Cheque Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    value={chequeDate}
                                    onValueChange={(date) => {
                                        setChequeDate(date)
                                    }}
                                    showTime={false}
                                />}
                                <DateTimePicker
                                    label='Amount Received Date'
                                    inputclass='mt-0'
                                    placeholder='Select Date'
                                    value={amountReceivedDate}
                                    onValueChange={(date) => setAmountReceivedDate(date)}
                                    showTime={false}
                                />


                                <div>
                                    <label className='text-sm text-black700 !mb-2 inter'>Payment Document</label>
                                    <div className='relative group border border-grayLight h-[154px] rounded-lg flex items-center justify-center overflow-hidden'>
                                        <input type="file" className='d-none' id={`upload-payment-proof`} multiple onChange={(e) => handleOnChange(e)} />
                                        <button
                                            type='button'
                                            className='!bg-primary flex items-center justify-center py-1 px-2 text-white gap-1 rounded-lg text-sm font-medium'
                                            onClick={() => {
                                                document.getElementById(`upload-payment-proof`).click();
                                            }}
                                        >
                                            <Pluswhite /> Upload
                                        </button>

                                        {/* <div className="group-hover:flex absolute left-0 right-0 top-0 bottom-0 w-100 h-100 bg-black bg-opacity-30 hidden items-center justify-center">
                                                        <div className='flex items-end gap-x-1 relative '>
                                                            <button type='button' className='py-1 px-2.5 text-white text-xs font-medium  !bg-primary rounded-md  tracking-[0.5px] inline-flex items-center inter'><Pluswhite /> Replace</button>
                                                            <button type='button' className='trashbtn w-8 h-8 bg-white rounded-md inline-flex items-center justify-center'><TrashIcon /></button>
                                                        </div>
                                                    </div> */}
                                    </div>
                                </div>

                                {/* <InputText
                                    inputclass='!m-0'
                                    label='Sender Bank Name'
                                    value={paymentSenderBankName}
                                    onChange={(e) => setPaymentSenderBankName(e.target.value)}
                                /> */}

                            </div>
                            {paymentDocs?.length > 0 && <div className='!mt-5'>
                                <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Document</h2>
                            </div>}
                            <div className='!mt-5'>
                                {/* <button className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'>
                                    <PlusPrimary />Add Payment Proof
                                </button> */}
                                <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                                    {paymentDocs?.length > 0 && paymentDocs.map((doc, index) => (
                                        <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                            <input type="file" className='d-none' id={`upload-payment-${index}`} onChange={(e) => handleReplaceDocumnet(e, index)} />
                                            <figure className='mb-0 !table !mx-auto relative group'>
                                                {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                    <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                    : <img src={pdfimg} />}
                                                <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                    <button
                                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                        onClick={() => {
                                                            document.getElementById(`upload-payment-${index}`).click();
                                                        }}
                                                    ><PluswhiteIcon />Replace</button>

                                                    <button
                                                        className={`${style.delbtn} inter border-0 bg-white`}
                                                        onClick={() => {
                                                            let newFiles = [...paymentDocs];
                                                            newFiles.splice(index, 1);
                                                            setPaymentDocs(newFiles);
                                                        }}
                                                    ><TrashIcon /></button>
                                                </div>
                                            </figure>
                                            <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {doc.fileName}</p>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='w-[412px] grow-0 shrink-0 basis-[412px] p-6 bg-blue-light min-2-[412px]'>
                            <div>
                                <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Payments</h2>
                            </div>

                            <div id="accordion" className='!mt-5 space-y-5'>
                                <div className="!border !border-grayLight bg-white rounded-xl overflow-hidden">
                                    <div className="!p-4 bg-white flex justify-between" id="headingOne" >
                                        <div className='flex flex-col'>
                                            <h5 className='inter text-sm text-black700 font-medium !mb-1'>Demand Due</h5>
                                            <p className='m-0 text-xs inter text-black700 leading-4'>#{selectedDemand?.unique_id}</p>
                                        </div>
                                        <div className='flex items-center gap-x-1'>
                                            <p className='m-0 text-base font-semibold leading-6'>₹ {toINRFormat(selectedDemand?.total_amount_due || '0')}</p>
                                            <button data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><DownIcon /></button>
                                        </div>
                                    </div>

                                    <div id="collapseOne" className="collapse  px-4" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className='!mt-3 divide-y divide-grayLight'>
                                            <div className='flex justify-between bg-black200 text-sm text-black700 leading-5 font-medium inter !p-2'>
                                                <div className=''>Amount Breakup</div>
                                                <div className=''>#</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Amount Breakup</div>
                                                <div className='font-medium'> + ₹ {toINRFormat(selectedDemand?.total_amount_due)}</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Due Amount</div>
                                                <div className='font-medium'> + ₹ {toINRFormat(selectedDemand?.due_amount)}</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>$GST Amt</div>
                                                <div className='font-medium'> + ₹ {toINRFormat(selectedDemand?.gst_amount)}</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Previous Dues</div>
                                                <div className='font-medium'> + ₹ {selectedDemand?.previous_demand_dues || 0}</div>
                                            </div>

                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Total Discount</div>
                                                <div className='font-medium'> -</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Token Amt. discount</div>
                                                <div className='font-medium'> -</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="!border !border-grayLight bg-white rounded-xl overflow-hidden !p-4">
                                    <div className=" bg-white flex justify-between " id="headingTwo" >

                                        <h5 className='inter text-sm text-black700 font-medium !mb-1'>Collected Amt.</h5>
                                        <div className='flex items-center gap-x-1'>
                                            <p className='m-0 text-base font-semibold leading-6 text-green'>- ₹ {toINRFormat(totalAmountPaid)}</p>
                                            <button className='collapsed' data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"><DownIcon /></button>
                                        </div>


                                    </div>
                                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className='!mt-3 divide-y divide-grayLight'>
                                            <div className='flex justify-between bg-black200 text-sm text-black700 leading-5 font-medium inter !p-2'>
                                                <div className=''>Amount Breakup</div>
                                                <div className=''>#</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Amount Breakup</div>
                                                <div className='font-medium'> + ₹ {toINRFormat(totalAmountPaid)}</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Amount Paid</div>
                                                <div className='font-medium'> + ₹ {toINRFormat(amountPaid)}</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>GST Amt</div>
                                                <div className='font-medium'> + ₹ {toINRFormat(gstPaid)}</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Interest Paid</div>
                                                <div className='font-medium'> + ₹ {toINRFormat(interestPaid)}</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Previous Dues</div>
                                                <div className='font-medium'> + ₹ -</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Total Discount</div>
                                                <div className='font-medium'> + ₹ -</div>
                                            </div>
                                            <div className='flex justify-between  text-xs text-black700 leading-5 inter !p-2'>
                                                <div className='font-normal'>Token Amt. discount</div>
                                                <div className='font-medium'> + ₹ -</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='!border !border-grayLight bg-white rounded-xl overflow-hidden !p-4 !mt-5'>
                                <div className='flex justify-between  text-sm text-black700 leading-5 inter '>
                                    <div className='font-medium'>Remaining Amt.</div>
                                    <div className='text-base text-textblack font-medium'> ₹ {toINRFormat((parseInt(selectedDemand?.total_amount_due) - parseInt(totalAmountPaid) || '0'))}</div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col">
                    <div>
                        <h3 className="m-0 inter sm:text-xl text-lg text-textblack font-semibold">Collected Amt. : ₹ {toINRFormat(totalAmountPaid)}</h3>
                        <p className="m-0 text-sm inter text-black700 !mt-1">Remaining Balance : ₹ {toINRFormat((parseInt(selectedDemand?.total_amount_due) - parseInt(totalAmountPaid)) || '0')} </p>
                    </div>
                    <div className="flex gap-3 items-center justify-end">
                        <button className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter inline-flex items-center justify-center gap-x-2" onClick={closeModal}>Cancel</button>
                        <button onClick={handleSubmit} className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">Submit</button>
                    </div>
                </div>
            </div>
        </BasicModal>
    )
}

export default AddCollection
