import { TAG_ACTIONS } from "./action";

export const intialState = {
    tags: []
}

export function tagsReducer(state = intialState, action) {
    switch (action.type) {
        case TAG_ACTIONS.SET_TAGS:
            return { ...state, tags: action.tags }

        default:
            return state;

    }
}