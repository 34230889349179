import React, { useContext, useEffect, useState } from 'react';
import SliderModal from './SliderModal';
import { toast } from 'react-toastify';
import Select from 'react-select';
import InputSelect from '../InputGroup/InputSelect';
import { deadLeadSchema } from '../../validators/leads.validator';
import { Form, Formik } from 'formik';
import { markLeadAsDead } from '../../services/private/leads.service';
import { getDeadReasons } from '../../services/public/public.service';
import { budgetRange } from '../../utils/constants';
import InputText from '../InputGroup/InputText';
import { getCustomerEnumsCall, updateCustomerByIdAndKey, updateCustomerByIdAndKey2 } from '../../services/private/customer.service';
import { ConsoleLogger } from '@aws-amplify/core';
import { toINRFormat } from '../../helpers/helpers';
import Consumer from '../../helpers/context';

const SIZE_OPTIONS = [
    { label: 'sqft', value: 'sqft' },
    { label: 'sq yard', value: 'sq yard' },
    { label: 'sqm', value: 'sqm' },
    { label: 'yards', value: 'yards' },
    { label: 'acres', value: 'acres' },
    { label: 'hectares', value: 'hectares' },
    { label: 'gaj', value: 'gaj' },
    { label: 'biga', value: 'biga' },
]

export default function MarkLeadDead({
    show,
    setShow,
    uuid,
    updateData,
    selectedOption,
    data
}) {

    const [reasons, setReasons] = useState([]);
    const [propertyEnums, setPropertyEnums] = useState({
        enum_furnishing: [],
        enum_facing: [],
        enum_funding_source: [],
        enum_industry: [],
        enum_possession: [],
        enum_property_category: [],
        enum_property_type: [],
        enum_purpose: [],
        enum_transaction_type: [],
        enum_usage_type: []
    });
    const [requiredLoan, setRequiredLoan] = useState();
    const [purchasePrice, setPurchasePrice] = useState();
    const [budgetError, setBudgetError] = useState(false);

    const { userProfile } = useContext(Consumer);

    const getDeadReasonsStatus = () => {
        getDeadReasons().then(res => {
            if (res.status === 200) {
                const reasonsData = res.data.data.map((data) => {
                    return {
                        label: data.reason,
                        value: data.uuid
                    }
                })
                setReasons(reasonsData);
            }
        })
    }

    const updateUserInfo = (payload) => {
        updateCustomerByIdAndKey2(data.customer_uuid, payload).then(res => {
            if (res.status === 200) {
                toast.error(`${data?.name} marked as Dead lead`);
                setShow(false);
                updateData();
            }
        }).catch(err => {
            console.log({ err })
        })
    }


    const clickHandler = async (deadLeadInfo) => {
        // console.log({ deadLeadInfo })
        let budget = {}
        let keyPair = []
        let carpetArea = {}
        let budgetAndSizePayload = []

        if (deadLeadInfo?.reason?.label === 'Other' && !deadLeadInfo.comment) {
            toast.error('Please Enter Comments')
            return
        }

        for (const [key, value] of Object.entries(deadLeadInfo)) {
            if (key === 'reason' || key === 'comment') {
                continue;
            }
            if (key === 'minimum_budget') {
                budget = {
                    ...budget,
                    key1: key,
                    value1: value.value
                }
                continue;
            }
            if (key === 'maximum_budget') {
                budget = {
                    ...budget,
                    key2: key,
                    value2: value.value
                }
                continue;
            }
            if (key === 'carpet_area') {
                carpetArea = {
                    ...carpetArea,
                    key1: key,
                    value1: value.value
                }
                continue;
            }
            if (key === 'carpet_area_unit') {
                carpetArea = {
                    ...carpetArea,
                    key2: key,
                    value2: value.value
                }
                continue;
            }

            keyPair.push({
                key,
                value: value.value || value
            })
        }

        let payload = [...keyPair];

        if (Object.keys(budget).length > 0) {
            if (!Object.values(budget).includes('minimum_budget')) {
                setBudgetError(true);
                // toast.error('Please add minimum budget');
                return;
            }
            if (!Object.values(budget).includes('maximum_budget')) {
                budget = {
                    ...budget,
                    key2: 'maximum_budget',
                    value2: null
                }
            }
            setBudgetError(false);
            budgetAndSizePayload.push(budget)
        }
        if (Object.keys(carpetArea).length > 0) {
            budgetAndSizePayload.push(carpetArea)
        }

        await markLeadAsDead(uuid, {
            comment: deadLeadInfo.comment,
            reason: deadLeadInfo.reason.value,
            key: "status_id",
            value: selectedOption.value
        }).then((res) => {
            if (res.status === 200) {
                let result2 = []
                let result1 = []
                if (payload.length > 0) {
                    result1 = payload.map(async (item) => {
                        await updateCustomerByIdAndKey(data.customer_uuid, item)
                    })
                }

                if (budgetAndSizePayload.length > 0) {
                    result2 = budgetAndSizePayload.map(async (item) => {
                        await updateCustomerByIdAndKey2(data.customer_uuid, item)
                    })
                }

                if ((result1.length + result2.length) === (payload.length + budgetAndSizePayload.length)) {
                    toast.error(`${data?.name} marked as Dead lead`);
                    setShow(false);
                    updateData();
                }

            }
        }).catch((err) => {
        })
    }


    useEffect(() => {
        getDeadReasonsStatus();
        const load = async () => {
            const res = await getCustomerEnumsCall()
            setPropertyEnums(res.data.data);
        }
        load();
    }, [])

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                `Mark Lead as ${userProfile?.company_id === 581 ? 'Dump' : 'Dead'}`
            }
            body={
                <>
                    <Formik
                        onSubmit={(values, actions) => {
                            clickHandler(values)
                        }}
                        validationSchema={deadLeadSchema}
                    >
                        {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return (
                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5'>
                                            {data?.name && <div>
                                                <label className='mt-3'>Lead Name</label>
                                                <div className='text-capitalize fz16 fw-po-medium'>{data?.name}</div>
                                            </div>}

                                            <label className='mt-3'>Reason</label>
                                            <InputSelect
                                                index={4}
                                                name="reason"
                                                id="reason"
                                                error={errors['reason']}
                                                touched={touched['reason']}
                                                value={values['reason']}
                                                options={reasons}
                                                placeholder="Select Reason"
                                                onChange={(e) => {
                                                    const value = { target: { name: "reason", value: e } }
                                                    handleChange(value)
                                                }}
                                            />
                                            <label className='mt-3'>Any Comments {values?.reason?.label === 'Other' ? <sup className='text-red'>*</sup> : '(optional)'}</label>
                                            <textarea className="form-control mb-16 " id='comment' name="comment" onChange={handleChange} placeholder="Enter any Comments..."  ></textarea>
                                            {errors['comment'] && (
                                                <div className="input-feedback mt-8">
                                                    {errors['comment']}
                                                </div>
                                            )}
                                            {['Budget issues',
                                                'Location issues',
                                                'Looking to sell property',
                                                'Configuration Issues',
                                                'Vaastu Issue',
                                                'Property Size Issue',
                                                'Possession Date Issues',
                                                'Ready to move required',
                                                'RERA issue',
                                                'Looking for rented properties',
                                                'Loan eligibility',
                                                'Property Requirement is different'].includes(values?.reason?.label) && <div className='form-group'>
                                                    <label>Budget</label>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <InputSelect
                                                                name='minimum_budget'
                                                                options={budgetRange}
                                                                value={values['minimum_budget']}
                                                                error={budgetError && 'Please select minimum budget'}
                                                                onChange={(e) => {
                                                                    const value = { target: { name: "minimum_budget", value: e } }
                                                                    handleChange(value)
                                                                }}
                                                                placeholder="Min"

                                                            />
                                                        </div>
                                                        <div className="col-sm-6 mt-sm-0 mt-3">
                                                            <InputSelect
                                                                name='maximum_budget'
                                                                options={values['minimum_budget']?.value ? budgetRange.filter(item => item.value > values['minimum_budget']?.value) : budgetRange}
                                                                value={values['maximum_budget']}
                                                                onChange={(e) => {
                                                                    const value = { target: { name: "maximum_budget", value: e } }
                                                                    handleChange(value)
                                                                }}
                                                                placeholder='Max'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>}

                                            {['Budget issues',
                                                'Location issues',
                                                'Looking to sell property',
                                                'Possession Date Issues',
                                                'Ready to move required',
                                                'Looking for rented properties',
                                                'Already bought from other broker',
                                                'Booked with competitor',
                                                'Property Requirement is different'
                                            ].includes(values?.reason?.label) &&
                                                <InputText
                                                    value={values['preferred_location']}
                                                    onChange={handleChange}
                                                    name='preferred_location'
                                                    label='Preferred Location'
                                                    placeholder={'Preferred Location'}
                                                />}

                                            {['Budget issues',
                                                'Property Size Issue',
                                                'Looking to sell property',
                                                'Possession Date Issues',
                                                'Configuration Issues',
                                                'RERA issue',
                                                'Looking for rented properties',
                                                'Property Requirement is different',
                                                'Found another project',
                                            ].includes(values?.reason?.label) &&
                                                <InputSelect
                                                    name='property_type'
                                                    label='Requirement Type'
                                                    value={values['property_type']}
                                                    onChange={(e) => {
                                                        const value = { target: { name: "property_type", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    options={propertyEnums.enum_property_type}
                                                    placeholder={'Requirement Type'}
                                                />}

                                            {['Builder team', 'Booked another'].includes(values?.reason?.label) &&
                                                <InputText
                                                    name='builder_name'
                                                    value={values['builder_name']}
                                                    onChange={handleChange}
                                                    label='Builder Name'
                                                    placeholder={'Builder Name'}
                                                />}

                                            {['Builder team',
                                                'RERA issue',
                                                'Already bought from other broker',
                                                'Booked with competitor',
                                                'Booked another',
                                                'Found another project'].includes(values?.reason?.label) &&
                                                <InputText
                                                    name='project_name'
                                                    value={values['project_name']}
                                                    onChange={handleChange}
                                                    label='Project Name'
                                                    placeholder={'Project Name'}
                                                />}

                                            {['Configuration Issues', 'Ready to move required'].includes(values?.reason?.label) &&
                                                <InputText
                                                    name='configuration'
                                                    value={values['configuration']}
                                                    onChange={handleChange}
                                                    label='Configuration Type'
                                                    placeholder={'Configuration Type'}
                                                    type='text'
                                                />}

                                            {['Vaastu Issue'].includes(values?.reason?.label) &&
                                                <InputSelect
                                                    name='facing'
                                                    label='Facing'
                                                    value={values['facing']}
                                                    onChange={(e) => {
                                                        const value = { target: { name: "facing", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    options={propertyEnums.enum_facing}
                                                    placeholder={'Facing'}
                                                />}

                                            {['Ready to move required'].includes(values?.reason?.label) &&
                                                <InputSelect
                                                    name='possession'
                                                    label='Possession preference'
                                                    value={values['possession']}
                                                    onChange={(e) => {
                                                        const value = { target: { name: "possession", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    options={propertyEnums.enum_possession}
                                                    placeholder={'Possession'}
                                                />}

                                            {['Loan eligibility'].includes(values?.reason?.label) &&
                                                <InputSelect
                                                    name='funding_source'
                                                    label='Funding Type'
                                                    value={values['funding_source']}
                                                    onChange={(e) => {
                                                        const value = { target: { name: "funding_source", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    options={propertyEnums.enum_funding_source}
                                                    placeholder={'Funding Type'}
                                                />}

                                            {['Loan eligibility'].includes(values?.reason?.label) &&
                                                <InputText
                                                    name='required_loan'
                                                    type="text"
                                                    value={requiredLoan || values['required_loan']}
                                                    handleBlur={(e) => {
                                                        setRequiredLoan(`₹ ${toINRFormat(values.required_loan.trim())}`)
                                                    }}
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "required_loan",
                                                                value: e.target.value
                                                                    .replace(/^0+/g, '')
                                                                    .replace(/[^0-9]/g, '')
                                                            }
                                                        }
                                                        handleChange(value)
                                                        setRequiredLoan(value.value)
                                                    }}
                                                    label='Required Loan Amount'
                                                    placeholder={'Loan Amount'}
                                                />}
                                            {['Booked another'].includes(values?.reason?.label) &&
                                                <InputText
                                                    name='purchase_price'
                                                    value={purchasePrice || values['purchase_price']}
                                                    handleBlur={(e) => {
                                                        setPurchasePrice(`₹ ${toINRFormat(values.purchase_price.trim())}`)
                                                    }}
                                                    type="text"
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "purchase_price",
                                                                value: e.target.value
                                                                    .replace(/^0+/g, '')
                                                                    .replace(/[^0-9]/g, '')
                                                            }
                                                        }
                                                        handleChange(value)
                                                        setPurchasePrice(value.value)
                                                    }}
                                                    label='Purchase Price'
                                                    placeholder={'Purchase Price'}
                                                />}
                                            {['Already bought from other broker',
                                                'Booked with competitor', 'Broker query'].includes(values?.reason?.label) &&
                                                <InputText
                                                    value={values['broker_name']}
                                                    onChange={handleChange}
                                                    name='broker_name'
                                                    label='Broker/CP Name'
                                                    placeholder={'Broker/CP Name'}
                                                />}
                                            {['Builder Team',
                                                'Found another project', 'Booked Another'].includes(values?.reason?.label) &&
                                                <InputText
                                                    value={values['project_location']}
                                                    onChange={handleChange}
                                                    name='project_location'
                                                    label='Project Location'
                                                    placeholder={'Project Location'}
                                                />}
                                            {['Looking for investment purposes'].includes(values?.reason?.label) &&
                                                <InputSelect
                                                    name='purpose'
                                                    label='Purpose '
                                                    value={values['purpose']}
                                                    onChange={(e) => {
                                                        const value = { target: { name: "purpose", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    options={propertyEnums.enum_purpose}
                                                    placeholder={'Purpose'}
                                                />}
                                            {['Looking for rented properties',
                                                'Looking to sell property'
                                            ].includes(values?.reason?.label) &&
                                                <InputSelect
                                                    name='transaction_type'
                                                    label='Transaction type '
                                                    value={values['transaction_type']}
                                                    onChange={(e) => {
                                                        const value = { target: { name: "transaction_type", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    options={propertyEnums.enum_transaction_type}
                                                    placeholder={'Transaction type'}
                                                />}

                                            {['Future prospect'].includes(values?.reason?.label) &&
                                                <InputText
                                                    name='time_to_buy'
                                                    value={values['time_to_buy']}
                                                    onChange={handleChange}
                                                    label='Expected time to buy'
                                                    placeholder={'In months'}
                                                    type={'number'}
                                                />}
                                            {['Property Size Issue'].includes(values?.reason?.label) && <div className=''>
                                                <label htmlFor="">Size</label>
                                                <div className="row">
                                                    <div className="col"> <div className={`form-group position-relative `}>
                                                        <input
                                                            name='carpet_area'
                                                            value={values['carpet_area']}
                                                            onChange={handleChange}
                                                            autoComplete="off"
                                                            type="number"
                                                            min="0"
                                                            className="form-control w-100 searchinput"
                                                            placeholder={'Size'}
                                                        />

                                                    </div></div>
                                                    <div className="col">
                                                        <InputSelect
                                                            name='carpet_area_unit'
                                                            className=''
                                                            options={SIZE_OPTIONS}
                                                            value={values['carpet_area_unit']}
                                                            onChange={(e) => {
                                                                const value = { target: { name: "carpet_area_unit", value: e } }
                                                                handleChange(value)
                                                            }}
                                                        // defaultValue={{ label: this.state.options[0].label, value: this.state.options[0].value }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel" >
                                            <button
                                                className="cancel outline-btn"
                                                onClick={() => { setShow(false) }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="dead-btn"
                                                type="submit"
                                            >
                                                Mark as {userProfile?.company_id === 581 ? 'Dump' : 'Dead'}
                                            </button>

                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}