import React, { useContext, useEffect, useRef, useState } from 'react';
import ReferImg from '../../assets/refer.svg'
import referBg from '../../assets/refer_bg.jpg'
import { getAllLiveWorkshops, getAllVisibleBanners, getContinueWatching, getRecentSessions } from '../../services/public/public.service';
import { ReactComponent as RightIcon } from '../../assets/icons/righticon_primary.svg'
import { Link, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import Consumer from '../../helpers/context'
import PaymentPlanModal from '../Settings/Modal/PaymentPlanModal';
import DashboardCard from './DashboardCard';
import { ReactComponent as BackIcon } from '../../assets/icons/leftArrow.svg';
import { ReactComponent as LeftSlideIcon } from '../../assets/icons/Left_slider.svg';
import { ReactComponent as RightSlideIcon } from '../../assets/icons/Right.svg'

const LEARNING_CENTER_TAB = ['courses', 'video', 'workshops', 'guides']

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <RightSlideIcon />

        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <LeftSlideIcon />

        </div>
    );
}

const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

const watch_settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 1000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 4,
    //             slidesToScroll: 4,
    //             infinite: true,
    //             dots: true
    //         }
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 2,
    //             slidesToScroll: 2
    //         }
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1
    //         }
    //     }
    // ]
}
const Dashboard = () => {
    const [allWorkshops, setAllWorkshop] = useState([]);
    const [continueWatching, setContinueWatching] = useState([]);
    const [recentSessions, setRecentSessions] = useState([]);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [banners, setBanners] = useState([]);
    const [sliderRef, setSliderRef] = useState(null)

    const { userProfile, isSubscribed, borkerDetails } = useContext(Consumer);

    const slickRef = useRef()

    const history = useHistory();

    const getActiveBanners = async () => {
        const res = await getAllVisibleBanners()
        if (res.data.status === 200) {
            setBanners(res.data.data)
        }
    }
    const getDashboardData = async () => {
        const res = await getRecentSessions()
        if (res.data.status === 200) {
            setRecentSessions(res.data.data.slice(0, 5));
        }
    }

    const getWorkshop = async () => {
        await getAllLiveWorkshops()
            .then((res) => {
                setAllWorkshop(res.data.slice(0, 4))
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getContinueWatchingList = async () => {
        const res = await getContinueWatching()
        if (res.data.status === 200) {
            setContinueWatching(res.data.data);
        }
    }
    useEffect(() => {
        getDashboardData();
        getActiveBanners();
        getContinueWatchingList()
        // getWorkshop();
        // slickRef.innerSlider.onWindowResized()
    }, []);

    const styles = {
        backgroundImage: `url(${referBg})`,
        // backgroundSize: 'cover',        
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `-422px -159px`
    };

    return (
        <div className='w-full !p-4  h-full overflow-auto' >
            <div className={`h-[320px] !mb-10 banner-slider`} id='home'>
                {/* <img src={banner} alt="" className='h-[320px] w-full rounded-lg' /> */}
                <Slider {...settings}>
                    {
                        banners.map((banner, index) => (
                            <div
                                className='!px-6'
                                onClick={() => {
                                    if (LEARNING_CENTER_TAB.includes(banner.redirect_to)) {
                                        history.push(`/learning-center?tab=${banner.redirect_to}`)
                                    } else {
                                        history.push(`/${banner.redirect_to}`)
                                    }
                                }}
                                key={banner.uuid}
                            >
                                <img src={banner.web_image_url} alt="" className='h-[320px] w-full rounded-lg' />
                            </div>
                        ))
                    }
                </Slider>
            </div>
            {continueWatching?.length > 0 && <div className='!mt-4 relative'>
                <div className='flex justify-between items-center'>
                    <div className='flex item-center !mb-4'>
                        <h3 className='text-lg font-semibold mb-0 mr-2'>
                            Countinue Watching
                        </h3>
                        <Link className='text-primary flex items-center fill-primary' to='/learning-center'>
                            Show More
                            <span className='fill-primary'>
                                <RightIcon className='fill-primary' />
                            </span>
                        </Link>
                    </div>
                </div>
                <div className='w-full slider-container' >
                    <Slider
                        // ref={setSliderRef}
                        {...watch_settings}>
                        {
                            continueWatching.map((workshop, index) => (
                                <div key={`${workshop.id}_${index}`} className='!px-4'>
                                    <DashboardCard
                                        workshop={workshop}
                                        isSubscriptionExpire={!isSubscribed}
                                        setShowPaymentModal={setShowPaymentModal}
                                        borkerDetails={borkerDetails}
                                        showPlayIcon={true}
                                    />
                                </div>
                            ))}
                    </Slider>
                </div>
            </div>}
            <div className='!mt-4'>
                <div className='flex items-center !mb-4'>
                    <h3 className='text-lg font-semibold mr-2 mb-0'>
                        Learning Center
                    </h3>
                    <Link className='text-primary flex items-center fill-primary' to='/learning-center?tab=workshops'>
                        Show all
                        <span className='fill-primary'>
                            <RightIcon className='fill-primary' />
                        </span>
                    </Link>
                </div>
                <div className='flex item-center !gap-4'>

                    {/* <Slider setting={{
                        dots: true,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }}> */}
                    {

                        recentSessions.map((workshop) => {
                            return <div key={workshop.id} className='w-[250px]'>
                                <DashboardCard
                                    workshop={workshop}
                                    isSubscriptionExpire={!isSubscribed}
                                    setShowPaymentModal={setShowPaymentModal}
                                    borkerDetails={borkerDetails}
                                    showPlayIcon={false}
                                />
                            </div>
                        })
                    }
                    {/* </Slider> */}
                </div>
            </div>

            <div className='w-[55%] !mt-10'>
                <div className='w-full rounded-lg !mt-5 cursor-pointer' onClick={() => {
                    history.push('/settings/refer');
                }}>
                    <div className='flex justify-between !px-4 !pt-4 !pb-0 shadow-2xl rounded-lg' style={styles}>
                        <div className='mt-3 w-[300px]'>
                            <p className='m-0 text-2xl font-semibold'>Good things should be shared, right?</p>
                            <p className='m-0 text-base'>Refer the app to your friends to get 70% Off</p>
                            {/* <div className='!mt-4'>
                                <button className='bg-textblack rounded-lg !px-3 !py-2'>
                                    Refer a friend
                                </button>
                            </div> */}
                        </div>
                        <div className='!mt-7 !mr-5'>
                            <div className='flex justify-end'>
                                <BackIcon className='rotate-180 h-6 w-6 !mb-4' />
                            </div>
                            <img src={ReferImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {showPaymentModal && <PaymentPlanModal show={showPaymentModal} closeModal={() => setShowPaymentModal(false)} paymentFrom={'modal'} />}
        </div>
    )
}

export default Dashboard