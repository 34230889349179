import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { action_getLeadDetail, action_getLeadTags } from '../../actions';
import { action_addTag, action_getTags, action_removeTags } from './action';

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // tags: this.props.tags.tags || [],
            showTagInput: false,
            tagInput: '',
            leadUUID: this.props.match.params.id,
            editTag: false,
        }
        // Fetch tags
        this.getLeadTags(this.state.leadUUID);
    }

    tagInputHandler = (event) => {
        this.setState({ tagInput: event.target.value });
    }

    addTag = (e) => {
        e.preventDefault();
        if (this.state.tagInput) {
            this.props.addTag(this.state.leadUUID, this.state.tagInput)
            this.setState({
                tagInput: '',
                showTagInput: false
            });
        }

    }

    getLeadTags = (uuid) => {
        this.props.getTagsByLeadUUID(uuid);
    }

    removeTag = (e, tagId) => {
        // e.preventDefault();
        this.props.removeTag(this.state.leadUUID, tagId);
    }

    displayAddTagView = () => {
        if (this.state.showTagInput) {
            // Input box
            return <div className="edit-input-text">
                <form className='form-inline'>

                    <div className='form-group position-relative search-input mr-8'>
                        <div className='position-relative'>
                            <div className="icon"></div>
                            <input type="text" value={this.state.tagInput} className="pl4" placeholder="Enter Tag Name" onChange={this.tagInputHandler} />
                            <div className="close-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10.4189 4.43106L11.0692 3.78078L9.63587 2.3475L8.9856 2.99777L10.4189 4.43106ZM9.59827 5.25168L8.16498 3.81839L2.80329 9.18008L2.4734 10.9432L4.23658 10.6134L9.59827 5.25168ZM11.9244 2.99482C12.3585 3.4289 12.3585 4.13267 11.9244 4.56674L4.93017 11.561C4.84743 11.6438 4.7416 11.6995 4.62658 11.7211L1.85391 12.2398C1.45298 12.3148 1.10182 11.9637 1.17683 11.5627L1.69559 8.79008C1.71711 8.67506 1.7729 8.56923 1.85565 8.48648L8.84991 1.49222C9.28398 1.05815 9.98776 1.05815 10.4218 1.49222L11.9244 2.99482ZM11.6724 12.25H6.99421C6.22414 12.25 6.22414 11.0833 6.99421 11.0833H11.6724C12.4425 11.0833 12.4425 12.25 11.6724 12.25Z" fill="#92929D"></path></svg></div>
                        </div>
                        <button onClick={(e) => this.addTag(e)} className='border-0 ml4 bg-tr px-2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <rect width="20" height="20" rx="4" fill="#0062FF" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.58613 9.97668C5.25907 9.65288 4.73144 9.65552 4.40764 9.98259C4.08383 10.3097 4.08648 10.8373 4.41354 11.1611L7.60133 14.3005C7.94405 14.6398 8.50236 14.6182 8.81787 14.2535L15.6301 6.37854C15.9312 6.03047 15.8931 5.5042 15.545 5.2031C15.197 4.902 14.6707 4.94008 14.3696 5.28815L8.14051 12.489L5.58613 9.97668Z" fill="white" />
                        </svg></button>
                        <button onClick={(e) => this.setState({ showTagInput: false })} className='border-0 ml4 bg-tr'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#B5B5BE" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.1761 10.0006L15.5737 14.4021C15.899 14.7277 15.8987 15.2553 15.5731 15.5806C15.2476 15.9059 14.7199 15.9057 14.3946 15.5801L9.99674 11.1782L5.58865 15.5798C5.26298 15.905 4.73534 15.9046 4.41014 15.5789C4.08495 15.2532 4.08534 14.7256 4.41102 14.4004L8.81876 9.99919L4.41288 5.58931C4.08759 5.26373 4.08783 4.73609 4.41341 4.4108C4.739 4.08551 5.26663 4.08575 5.59192 4.41134L9.99815 8.82155L14.3948 4.43145C14.7205 4.10625 15.2481 4.10664 15.5733 4.43232C15.8985 4.758 15.8981 5.28564 15.5724 5.61083L11.1761 10.0006Z" fill="white" />
                        </svg></button>
                    </div>

                </form>
            </div>

        } else {
            // Button to display tag input
            return <button
                className="border-0 bg-tr flex items-center"
                onClick={(e) => this.setState({ showTagInput: true })}
                disabled={(this.props.userProfile?.allowed_modules?.includes('lead_sharing') && this.props.leadAccess === 'view')}
            >
                Add tags <svg className="ml-8" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 5.33333H11.3333C11.7015 5.33333 12 5.63181 12 6C12 6.36819 11.7015 6.66667 11.3333 6.66667H6.66667V11.3333C6.66667 11.7015 6.36819 12 6 12C5.63181 12 5.33333 11.7015 5.33333 11.3333V6.66667H0.666667C0.298477 6.66667 0 6.36819 0 6C0 5.63181 0.298477 5.33333 0.666667 5.33333H5.33333V0.666667C5.33333 0.298477 5.63181 0 6 0C6.36819 0 6.66667 0.298477 6.66667 0.666667V5.33333Z" fill="#92929D" />
                </svg>
            </button>
        }
    }
    render() {
        return <>
            <div className="tags mt-20 d-flex flex-row flex-wrap align-items-center">
                <ul className="d-flex flex-wrap flex-row align-items-md-center align-items-start mb-0">
                    <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20.1091 10.995C20.3895 11.2728 20.612 11.6034 20.7639 11.9677C20.9158 12.332 20.994 12.7228 20.994 13.1175C20.994 13.5122 20.9158 13.903 20.7639 14.2673C20.612 14.6316 20.3895 14.9622 20.1091 15.24L15.1441 20.1735C13.9651 21.345 12.0541 21.345 10.8736 20.1735L3.83858 13.1835C3.27908 12.6255 2.96108 11.871 2.95508 11.082L2.91908 6.02251C2.91749 5.62439 2.99488 5.2299 3.14677 4.86188C3.29866 4.49387 3.52204 4.15963 3.80398 3.87853C4.08592 3.59743 4.42082 3.37504 4.78928 3.22425C5.15775 3.07345 5.55246 2.99723 5.95058 3.00001L10.8466 3.02101C11.6426 3.02365 12.4055 3.3394 12.9706 3.90001L20.1091 10.995ZM19.0411 14.178C19.1813 14.0391 19.2925 13.8738 19.3685 13.6917C19.4444 13.5095 19.4835 13.3141 19.4835 13.1168C19.4835 12.9194 19.4444 12.724 19.3685 12.5419C19.2925 12.3597 19.1813 12.1944 19.0411 12.0555L11.9011 4.96051C11.6189 4.68057 11.2381 4.52273 10.8406 4.52101L5.94458 4.50001C5.10458 4.49701 4.42208 5.17651 4.42958 6.01201L4.46558 11.0715C4.46875 11.4662 4.62781 11.8436 4.90808 12.1215L11.9416 19.1115C12.5311 19.698 13.4866 19.698 14.0761 19.1115L19.0411 14.1765V14.178Z" fill="#92929D" />
                        <path d="M8.63598 10.5714C7.38331 10.5714 6.3584 9.54286 6.3584 8.28571C6.3584 7.02857 7.38331 6 8.63598 6C9.88865 6 10.9136 7.02857 10.9136 8.28571C10.9136 9.54286 9.88865 10.5714 8.63598 10.5714ZM8.63598 7.14286C8.00964 7.14286 7.49719 7.65714 7.49719 8.28571C7.49719 8.91429 8.00964 9.42857 8.63598 9.42857C9.26231 9.42857 9.77477 8.91429 9.77477 8.28571C9.77477 7.65714 9.26231 7.14286 8.63598 7.14286Z" fill="#92929D" />
                    </svg>
                    {
                        this.props.tags.map((tag, index) => {
                            return <li className='inline-flex gap-x-2' key={index}>{tag.name} <span onClick={(e) => this.removeTag(e, tag.id)} className='mt-px'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.94099 7.99988L12.459 11.5211C12.7193 11.7816 12.7191 12.2037 12.4586 12.4639C12.1981 12.7242 11.776 12.724 11.5158 12.4635L7.99748 8.94199L4.47097 12.4633C4.21043 12.7234 3.78832 12.7231 3.52816 12.4626C3.26801 12.202 3.26832 11.7799 3.52886 11.5197L7.05509 7.99875L3.5304 4.47087C3.27017 4.2104 3.27036 3.78829 3.53083 3.52806C3.79129 3.26783 4.2134 3.26802 4.47364 3.52848L7.9986 7.05664L11.5159 3.54459C11.7764 3.28444 12.1985 3.28475 12.4587 3.5453C12.7188 3.80584 12.7185 4.22795 12.458 4.4881L8.94099 7.99988Z" fill="#44444F" />
                            </svg></span></li>
                        })
                    }

                    <div className="dropdown">
                        {this.props.tags.length < 5 && this.displayAddTagView()}
                        {/* not nedded */}
                        {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <form >
                                <div className="form-group mb-0">
                                    <input type="text" className="form-control" onChange={this.tagInputHandler} />
                                    <button className="pr-btn mt-8 w-100" onClick={(e) => { this.addTag(e) }}>Add</button>
                                </div>
                            </form>

                        </div> */}

                        {/* enter tag input */}

                    </div>
                </ul>

            </div>
        </>
    }
}
function mapStateToProps(state) {
    return { tags: state.tagsReducer.tags };
}

function mapDispatchToProps(dispatch) {
    return {
        getTagsByLeadUUID: (uuid) => {
            dispatch(action_getTags(uuid))
        },
        removeTag: (leadUUID, tagId) => {
            dispatch(action_removeTags(leadUUID, tagId))
        },
        addTag: (leadUUID, name) => {
            dispatch(action_addTag(leadUUID, name))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Tags));
