import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import { insertPricingEDCCharges, updatePricingEDCChargesByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { carpet_area, unit_type } from '../../../utils/project-constant';
import InputRupees from '../../InputGroup/InputRupees';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


const AddEdcCharges = ({ show, closeModal, modalClass, configurations, uuid, getEDCChargedData, selectedPrice }) => {
    const [unitTypeAvailble, setUnitTypeAvailable] = useState();
    const [unitConfigurationAvailable, setUnitConfigurationAvailble] = useState();
    const [includePrice, setIncludePrice] = useState(true);
    const [legelCharge, setLegelCharge] = useState('absolute');
    const [absoluteAmount, setAbsoluteAmount] = useState('');
    const [amountPercent, setAmountPercent] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [selectedUnitValue, setSelectedUnitValue] = useState('');

    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            console.log({ selectedPrice }, 'edc');
            setLegelCharge(selectedPrice.type)
            setSelectedUnitValue(selectedPrice?.rate);
            setSelectedUnit(carpet_area.find(item => item.value === selectedPrice?.rate_unit));
            setAbsoluteAmount(selectedPrice.payment_amount);
            setAmountPercent(selectedPrice.payment_percentage);
            setIncludePrice(selectedPrice.include_in_price);
            setUnitConfigurationAvailble(configurations.find(item => item.value === selectedPrice.config));
            setUnitTypeAvailable(unit_type.find(item => item.value === selectedPrice.unit_type));
            if (selectedPrice.payment_amount) {
                setLegelCharge('absolute');
            }
            if (selectedPrice.payment_percentage) {
                setLegelCharge('percent');
            }
            if (selectedPrice.selectedUnitValue) {
                setLegelCharge('perUnit')
            }
        }
    }, [selectedPrice]);

    const handleSubmit = async () => {
        const payload = {
            unit_type: unitTypeAvailble?.value,
            unit_configuration: unitConfigurationAvailable?.value,
            include_in_price: includePrice,
            payment_amount: absoluteAmount || null,
            payment_percentage: amountPercent || null,
            type: legelCharge,
            rate: selectedUnitValue,
            rate_unit: selectedUnit?.label
            // description: description,
        }

        let res = {};
        if (selectedPrice?.uuid) {
            res = await updatePricingEDCChargesByUUID(selectedPrice.uuid, payload);
        } else {
            res = await insertPricingEDCCharges(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            handleClose();
            getEDCChargedData();
        }
    }

    const handleClose = () => {
        closeModal();
        setUnitTypeAvailable('');
        setUnitConfigurationAvailble('');
        setIncludePrice(true);
        setLegelCharge('absolute');
        setAbsoluteAmount('');
        setAmountPercent('');
        setSelectedUnit('');
        setSelectedUnitValue('');
    }

    return (
        <RightModal show={show} closeModal={handleClose} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add EDC Charges</h1>
                <button className='border-0 bg-tr ' onClick={handleClose}><CloseIcon /></button>
            </div>
            <div action="" className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>
                <InputSelect
                    label='Unit Type Applicability'
                    inputclass='!mt-0'
                    styles={customStyles}
                    options={unit_type}
                    value={unitTypeAvailble}
                    onChange={(value) => setUnitTypeAvailable(value)}
                />
                <InputSelect
                    label='Unit Configuration Applicability'
                    inputclass='!mt-0'
                    styles={customStyles}
                    options={configurations}
                    value={unitConfigurationAvailable}
                    onChange={(value) => setUnitConfigurationAvailble(value)}
                />

                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>EDC Charges
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='absolute' checked={legelCharge === 'absolute'} onChange={() => setLegelCharge('absolute')} />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='percent' checked={legelCharge === 'percent'} onChange={() => setLegelCharge('percent')} />
                            Percentage
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='perUnit' checked={legelCharge === 'perUnit'} onChange={() => setLegelCharge('perUnit')} />
                            Per Unit
                        </label>
                    </div>
                    {legelCharge === 'percent' &&
                        <InputText
                            placeholder='Enter Percentage'
                            value={amountPercent}
                            onChange={(e) => setAmountPercent(e.target.value)}
                        />}
                    {legelCharge === 'absolute' &&
                        <InputRupees
                            placeholder='Enter Absolute Amount'
                            value={absoluteAmount}
                            onChange={(value) => setAbsoluteAmount(value)}
                            rupayclass='top-4 left-3'
                            handleBlur={() => { }}
                        />}
                    {legelCharge === 'perUnit' &&
                        <div className='flex gap-x-5 [&>*]:w-6/12'>
                            <InputRupees
                                inputclass='!mt-0'
                                placeholder='Enter Rate'
                                styles={customStyles}
                                value={selectedUnitValue}
                                onChange={(value) => setSelectedUnitValue(value)}
                                rupayclass='top-4 left-3'
                                handleBlur={() => { }}
                            />
                            <InputSelect
                                inputclass='!mt-0'
                                styles={customStyles}
                                value={selectedUnit}
                                onChange={(value) => setSelectedUnit(value)}
                                options={carpet_area}

                            />
                        </div>
                    }
                </div>
                {/* <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>EDC Charges
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" checked />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" />
                            Percentage
                        </label>
                    </div>
                    <InputText
                        placeholder='Enter value' />
                </div> */}

                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Include in price
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" value={true} checked={includePrice} onChange={() => setIncludePrice(true)} />
                            Yes
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" value={false} checked={!includePrice} onChange={() => setIncludePrice(false)} />
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={handleClose}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddEdcCharges
