import React from 'react'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { getUnitTotalPrice, toINRFormat } from '../../../helpers/helpers';
import moment from 'moment';

const calculatePrice = (basePrice, percent) => {
    return (parseInt(basePrice) * (parseFloat(percent) / 100))
}

const getStatusClass = (status) => {
    switch (status) {
        // case 'pending':
        //     return 'text-yellow-dark bg-yellow-100'
        // case 'rejected':
        //     return 'text-red bg-red-light'
        case true:
            return 'text-green bg-green100'
        default:
            return 'text-yellow-dark bg-yellow-100'
    }
}

const UnitDetail = ({ bookingDetails }) => {
    const { agreement, bookingUnit, bookingAmeniites, channelPartner, project, bookingPricing, coapplicant, customer, developer, booking, paymentPlan } = bookingDetails;

    return (
        <div className='bg-white !mr-2.5 !border !border-grayLight shadow-boxshadow flex flex-col gap-y-8 !px-5 !py-5 rounded-lg'>
            {/* <div>
                <h1 className='text-xl text-textblack font-semibold leading-7 !mb-4'>Unit Details</h1>
                <div className='flex flex-wrap gap-y-4'>
                    <div className='w-4/12'>
                        <h3 className='textsme intertext-black700 text-textblack font-semibold leading-6 !mb-1'>Project</h3>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>Godrej Avenues</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Carpet Area </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>875 sq.ft.</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Balcony Area</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>120 sq.ft.</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Tower Number</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>H</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Super Built Up Area </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>724 sq.ft.</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Base Price</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>₹ 6254000</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Floor Number</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>3</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Furnishing</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Semi-Furnished</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>PLC Charges</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>₹ 254000</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Unit Number</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>03</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Facing</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>North East</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>All Pricing Charges</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>₹ 8254000</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Configuration</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>1BHK-874sq.ft.</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Allocated Car Parking</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>1</p>
                    </div>

                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Net. Purchase Price</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>₹ 9254000</p>
                    </div>

                </div>
            </div> */}
            <div>
                <p className='m-0 text-xl font-semibold text-textblack inter'>Unit Details</p>
                <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                    {project?.name && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Project</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{project?.name || '-'}</p>
                    </div>}
                    {bookingUnit?.configuration?.carpet_area && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Carpet Area</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.configuration?.carpet_area ? `${bookingUnit?.configuration?.carpet_area} ${bookingUnit?.configuration?.carpet_area_unit}` : '-'}</p>
                    </div>}
                    {bookingUnit?.configuration?.carpet_area && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Plot Area</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.configuration?.plot_area ? `${bookingUnit?.configuration?.plot_area} ${bookingUnit?.configuration?.plot_area_unit}` : '-'}</p>
                    </div>}
                    {bookingUnit?.configuration?.balcony_area && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Balcony Area</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.configuration?.balcony_area ? `${bookingUnit?.configuration?.balcony_area} ${bookingUnit?.configuration?.balcony_area_unit}` : '-'}</p>
                    </div>}
                    {bookingUnit?.block && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Tower/Block Number</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.block ? bookingUnit?.block : '-'}</p>
                    </div>}
                    {bookingUnit?.configuration?.super_area && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Super Built Up Area</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.configuration?.super_area ? `${bookingUnit?.configuration?.super_area} ${bookingUnit?.configuration?.super_area_unit}` : '-'} </p>
                    </div>}
                    {bookingUnit?.base_price && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Base Price</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'> {bookingUnit?.base_price ? `₹ ${bookingUnit?.base_price}` : '-'}</p>
                    </div>}
                    {bookingUnit?.floor_no && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Floor Number</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.floor_no || '-'}</p>
                    </div>}
                    {bookingUnit?.furnishing && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Furnishing</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.furnishing}</p>
                    </div>}
                    {bookingUnit?.plc_charge1 && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>PLC Charges 1</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>₹ {bookingUnit?.plc_charge1}</p>
                    </div>}
                    {bookingUnit?.plc_charge2 && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>PLC Charges 2</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>₹ {bookingUnit?.plc_charge2}</p>
                    </div>}
                    {bookingUnit?.plc_charge3 && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>PLC Charges 3</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>₹ {bookingUnit?.plc_charge3}</p>
                    </div>}
                    {bookingUnit?.plc_charge4 && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>PLC Charges 4</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>₹ {bookingUnit?.plc_charge4}</p>
                    </div>}
                    {bookingUnit?.name && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Unit Number</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.name}</p>
                    </div>}
                    {bookingUnit?.facing && <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>Facing</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{bookingUnit?.facing}</p>
                    </div>}
                    <div>
                        <p className='text-sm inter text-black700 leading-6 !mb-1'>All Pricing Charges</p>
                        <p className='inte text-base text-textblack font-semibold leading-5 m-0'>{getUnitTotalPrice(bookingUnit) || '-'}</p>
                    </div>
                </div>
            </div>
            {/* MEDIA */}
            {/* <div>
                <div className='!border-b !border-grayLight flex justify-between items-center !mb-4 !pb-4'>
                    <h1 className='text-xl text-textblack font-semibold leading-7 !mb-0'>Media</h1>
                    <button className='!bg-primary text-white inlin font-medium text-sm rounded py-2 !px-4'>+ Add Media</button>
                </div>
                <div className='grid sm:grid-cols-4 gap-x-6'>
                    <div>
                        <div className='!border border-grayLight rounded h-[210px] group relative'>
                            <img src="" alt="" />
                            <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                        </div>

                    </div>
                    <div>
                        <div className='!border border-grayLight rounded h-[210px] group relative'>
                            <img src="" alt="" />
                            <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                        </div>

                    </div>
                    <div>
                        <div className='!border border-grayLight rounded h-[210px] group relative'>
                            <img src="" alt="" />
                            <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                        </div>

                    </div>
                    <div>
                        <div className='!border border-grayLight rounded h-[210px] group relative'>
                            <img src="" alt="" />
                            <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                        </div>

                    </div>

                </div>
            </div> */}
            {/* <div>
                <h1 className='text-xl text-textblack font-semibold leading-7 !mb-4'>Payment Plan -  CLP Plan (4)</h1>
                <div className='def-table plantable h-auto !overflow-auto !p-0'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-auto text-left !min-w-fit">Milestones</th>
                                <th>Description</th>
                                <th>Due Date</th>
                                <th>Due Amount</th>
                                <th>%</th>
                                <th>Net. Purchase Price (Incl GST)</th>
                                <th>Total Due (Incl GST)</th>
                                <th>Received</th>
                                <th>Due Amount</th>
                                <th>Outstanding</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='!w-auto text-left !min-w-fit	!pl-2'>Milestones 1</td>
                                <td>Lorem ipsum dolor sit amet..</td>
                                <td>07/06/2023</td>
                                <td>189299</td>
                                <td>10%</td>
                                <td>189299</td>
                                <td>189299</td>
                                <td>189299000</td>
                                <td>189299000</td>
                                <td>0</td>

                            </tr>
                            <tr>
                                <td className='!w-auto text-left !min-w-fit	!pl-2'>Milestones 1</td>
                                <td>Lorem ipsum dolor sit amet..</td>
                                <td>07/06/2023</td>
                                <td>189299</td>
                                <td>10%</td>
                                <td>189299</td>
                                <td>189299</td>
                                <td>189299000</td>
                                <td>189299000</td>
                                <td>0</td>

                            </tr>
                            <tr>
                                <td className='!w-auto text-left !min-w-fit	!pl-2'>Milestones 1</td>
                                <td>Lorem ipsum dolor sit amet..</td>
                                <td>07/06/2023</td>
                                <td>189299</td>
                                <td>10%</td>
                                <td>189299</td>
                                <td>189299</td>
                                <td>189299000</td>
                                <td>189299000</td>
                                <td>0</td>

                            </tr>
                            <tr>
                                <td className='!w-auto text-left !min-w-fit	!pl-2'>Milestones 1</td>
                                <td>Lorem ipsum dolor sit amet..</td>
                                <td>07/06/2023</td>
                                <td>189299</td>
                                <td>10%</td>
                                <td>189299</td>
                                <td>189299</td>
                                <td>189299000</td>
                                <td>189299000</td>
                                <td>0</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}
            <div className="!mt-10 d-flex flex-fill flex-col">
                <p className='m-0 text-xl font-semibold text-textblack inter'>Payment Plan</p>
                <div className='w-100 !mt-3'>
                    <div className={`def-table h-auto !overflow-auto !p-0 booking-detail-table w-100`}>
                        <table className='table mb-0'>
                            <thead>
                                <tr>
                                    <th className='!w-auto text-left !max-w-fit !min-w-fit'>Milestones</th>
                                    <th>Description</th>
                                    <th>Due Date</th>
                                    <th>Payment %</th>
                                    <th>Payment Amount</th>
                                    <th>Completed</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    paymentPlan?.milestones?.map((milestone, index) => (
                                        <tr>
                                            <td className='!w-auto !max-w-fit text-left !min-w-fit'>Milestone {index + 1}</td>
                                            <td>{milestone.description}</td>
                                            <td>{milestone.due_date ? moment(new Date(milestone.due_date)).format('Do MMM YYYY') : '-'}</td>
                                            <td> {milestone.payment_percentage ? `${milestone.payment_percentage}%` : '-'}</td>
                                            <td> ₹ {milestone.payment_amount ? milestone.payment_amount : toINRFormat(calculatePrice(bookingPricing?.final_price, milestone.payment_percentage) || '0')}</td>
                                            <td>
                                                <div className={`${getStatusClass(milestone?.is_completed)}  text-sm font-medium !px-2.5 !py-1 rounded capitalize w-12 text-center`}>{milestone?.is_completed ? 'Yes' : 'No'}</div>

                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnitDetail
