import React, { useEffect, useState } from 'react'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as PlushIcons } from '../../../assets/icons/whitePlush.svg';
import SearchBox from '../../InputGroup/Searchbox';
import moment from 'moment';
import { convertToTitleCase, toINRFormat } from '../../../helpers/helpers';
import { CSVLink } from 'react-csv';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';

const Demannotes = ({ demands, collectionStats, planName, getPaymentMilestones }) => {

    const [searchText, setSearchText] = useState('');
    const [demandList, setDemandList] = useState([]);
    const [tempConfig, setTempConfig] = useState([])

    useEffect(() => {
        setDemandList(demands);
    }, [demands])

    useEffect(() => {
        if (searchText) {
            const filtered = demands.filter(item => item?.due_amount?.indexOf(searchText) > -1);
            setDemandList(filtered);

        } else {
            setDemandList(demands);
        }
    }, [searchText]);

    // export csv
    const exportConfig = () => {
        let temp = demandList.map((item, index) => {
            return {
                "Demand ID": item?.unique_id || '-',
                "Milestones": item?.milestone_no?.join(', ') || '-',
                "Description": item?.remarks,
                "Raise Date": item?.created_on ? moment(item?.created_on).format('Do MMM YYYY') : '-',
                "Due Amt. (i) (A)": toINRFormat(item?.due_amount || '-'),
                "Interest (B)": item?.net_interest ? `${item?.net_interest} %` : '-',
                "ATotal (A+B)": '-',
                "GST (C)": toINRFormat(item?.gst_amount || '-'),
                "Total Previous Due (D)": toINRFormat(item?.previous_demand_dues || '-'),
                "Gross Amt. (A+B+C+D)": toINRFormat(item?.previous_demand_dues || '-'),
                "Collected (i)": toINRFormat(item?.total_amount_paid || '-'),
                "Balance Remaining": toINRFormat(item?.collected_amount || '-'),
                "Pending at Finance": '-',
                "Raised By": '-',
            }
        })
        setTempConfig(temp);

    };

    return (
        <div className='bg-white !mr-2.5 !border !border-grayLight shadow-boxshadow flex flex-col gap-y-8 !px-5 !py-5 rounded-lg'>
            <div>
                <div className='flex flex-row items-center'>
                    <div className='w-6/12'>
                        <h2 className='inter text-textblack font-semibold text-xl mb-0'>Demand Notes</h2>
                    </div>
                    <div className='w-6/12'>
                        <div className='flex items-center gap-x-3 justify-end'>
                            <div className='w-[218px] shrink-0 grow-0 basis-[218px]'>
                                <SearchBox
                                    inputClass='!pl-8 !py-1.5 !pr-8 text-sm leading-5 rounded'
                                    searchIconClass='w-5 h-5'
                                    placeholder='Search Due Amount'
                                    value={searchText}
                                    onChange={(e) => setSearchText(
                                        e.target.value
                                            .replace(/^0+/g, '')
                                            .replace(/[^0-9]/g, ''))}
                                    handelClear={() => setSearchText('')}
                                />
                            </div>
                            <div>

                                {/* <DownloadIcon />Download */}
                                <CSVLink
                                    className={`inline-flex items-center text-black700 gap-x-2 text-sm font-medium !border !border-grayLight rounded bg-black100 h-[32px] !px-4 inter`}
                                    data={tempConfig}
                                    onClick={exportConfig}
                                    filename={'configuration.csv'}>
                                    <DownloadIcon />Download
                                </CSVLink>

                            </div>
                            <div>
                                <button
                                    className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'
                                    onClick={getPaymentMilestones}
                                >
                                    <PlushIcons /> Add New Demand</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='inline-flex flex-row !border border-grayLight rounded-lg shadow-boxshadow divide-x divide-grayLight !mt-5'>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{planName?.name}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Payment Plan</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'> {collectionStats?.total_demand_raised ? `₹ ${toINRFormat(collectionStats?.total_demand_raised)}` : '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Total Demand Raised</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{collectionStats?.total_amount_paid ? `₹ ${toINRFormat(collectionStats?.total_amount_paid)}` : '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Total Amt. Collected</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{collectionStats?.total_amount_paid && collectionStats?.total_demand_raised ? `₹ ${toINRFormat(parseFloat(collectionStats?.total_demand_raised) - parseFloat(collectionStats?.total_amount_paid))}` : '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Total Remaining Amt.</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{collectionStats?.pending_amount_verification ? `₹ ${toINRFormat(collectionStats?.pending_amount_verification)}` : '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Pending Verification</p>
                        </div>

                    </div>
                </div>
                <div className='!mt-5'>
                    <div className={`def-table plantable h-auto !p-0 ${demandList.length === 0 ? '!overflow-hidden' : '!overflow-auto'}`}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th class="">S. No.</th>
                                    <th>Demand ID</th>
                                    <th>Milestones</th>
                                    <th>Description</th>
                                    <th>Raise Date</th>
                                    <th>Due Amt. (A)</th>
                                    <th>Interest (B)</th>
                                    <th>ATotal (A+B)</th>
                                    <th>GST (C)</th>
                                    <th>Total Previous Due (D)</th>
                                    <th>Gross Amt. (A+B+C+D)</th>
                                    <th>Collected (i)</th>
                                    <th>Balance Remaining</th>
                                    <th>Pending at Finance</th>
                                    <th>Raised By</th>
                                    <th>View Demand Letter</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    demandList.map((item, index) => (
                                        <tr >
                                            <td>{index + 1}</td>
                                            <td>{item?.unique_id || '-'}</td>
                                            <td>{item?.milestone_no?.join(', ') || '-'}</td>
                                            <td>{item?.remarks}</td>
                                            <td>{item?.created_on ? moment(item?.created_on).format('Do MMM YYYY') : '-'}</td>
                                            <td>{item?.due_amount ? `₹ ${toINRFormat(item?.due_amount)}` : '-'}</td>
                                            <td>{item?.net_interest ? `₹ ${item?.net_interest} %` : '-'}</td>
                                            <td>{'-'}</td>
                                            <td>{item?.gst_amount ? `₹ ${toINRFormat(item?.gst_amount)}` : '-'}</td>
                                            <td>{item?.previous_demand_dues ? `₹ ${toINRFormat(item?.previous_demand_dues)}` : '-'}</td>
                                            <td>{item?.total_amount_paid ? `₹ ${toINRFormat(item?.total_amount_paid)}` : '-'}</td>
                                            <td>{item?.collected_amount ? `₹ ${toINRFormat(item?.collected_amount)}` : '-'}</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    ))
                                }
                                {
                                    searchText && demandList.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={11} style={{ height: '' }}>
                                            <div className='flex justify-center flex-column items-center'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                                                    {/* <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn> */}
                                                </p>
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                             <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                         } */}
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {
                                    !searchText && demandList.length === 0 && <tr className='nohover'>
                                        <td colSpan={11}>
                                            <div className='flex justify-center flex-col items-center h-100 w-full'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8">No demand raised</h2>
                                                {/* <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add document to see them here</p> */}
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Demannotes
