import React from 'react'

const FAQ = ({ style, faqs }) => {
    return (
        <div className={`${style.faq} overflow-hidden`}>
            <div className="row d-flex justify-content-center">
                <div className="col-md-12 px-md-5">
                    <div className={`${style.faq_title} mx-auot text-center d-block`}>FAQ</div>
                    <div className={style.faq_ac_area}>
                        <div id="accordion">
                            {
                                faqs.map((faq, index) => {
                                    return (faq.question && faq.answer) && <div className={style.card} key={`${index}`}>
                                        <div className={`${style.faq_header}`} id="headingTwo" data-toggle="collapse" data-target={`#collapse${faq.id}`} aria-expanded="false" aria-controls={`collapse${faq.id}`}>
                                            <span>{faq.question}</span>
                                            <div className={style.faq_icon}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579Z" fill="#44444F" />
                                            </svg>
                                            </div>
                                        </div>
                                        <div id={`collapse${faq.id}`} className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className={`${style.faq_body}`}>
                                                {faq.answer}
                                            </div>
                                        </div>
                                    </div>

                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ
