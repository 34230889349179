import React, { useState } from 'react'
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import AddCollection from '../Modal/AddCollection';
import { toINRFormat } from '../../../helpers/helpers';
import { createDatewithTime } from '../../../helpers/date';
import moment from 'moment';


const RecentCollection = ({ classname, item, setShowAddCollection, setShowVerifyCollection, setSelectedItem }) => {

    return (
        <div className={`${classname} !py-2 !px-3 !border max-w-[300px] w-[300px] shadow-boxshadow rounded-lg`}>
            <div className='flex justify-between inter border-b border-grayLight !pb-2'>
                <div className=' text-sm font-medium text-black700 leading-5'>Recent Collection</div>
                <button className='inline-flex items-center gap-x-1 text-xs !text-primary font-medium leading-4 justify-end cursor-pointer'
                    onClick={() => {
                        setSelectedItem(item);
                        setShowAddCollection(true);
                    }}
                >
                    <PlusPrimary className='w-3 h-3' />Add New</button>
            </div>

            <div className='flex flex-col justify-between inter !pt-2'>
                <div className={`flex flex-row justify-between ${item?.collection_approval_status === 'pending' ? 'cursor-pointer' : ''}`}
                    onClick={() => {
                        // if (item?.collection_approval_status === 'pending') {
                        //     document.getElementById('nav-collections-tab').click();
                        // }
                        setSelectedItem(item);
                        setShowVerifyCollection(true);
                    }}
                >
                    <h3 className='text-textblack text-base font-semibold !mb-2 leading-6'>{item?.collection_amount ? `₹ ${toINRFormat(item?.collection_amount)}` : '-'}</h3>
                    <h3
                        className={`text-sm font-medium ${(item?.collection_approval_status && item?.collection_approval_status !== 'pending') ? '!text-green' : '!text-red'} leading-5 capitalize text-right`}
                    >
                        {(item?.collection_approval_status && item?.collection_approval_status !== 'pending') ? item?.collection_approval_status : ('Pending ' + (item.pending_collection_approval ? `(${item.pending_collection_approval})` : ''))}</h3>
                </div>
                <div className='flex flex-row justify-between'>
                    <p className='text-xs text-black700 font-medium leading-4 mb-0'>{item?.collection_date ? moment(item?.collection_date).format('Do MMM YYYY') : '-'}</p>
                    {item?.milestone_no && <p className='text-xs text-black700 font-medium leading-4 text-right mb-0'>Milestone: {item?.milestone_no?.join(', ')}</p>}
                </div>
            </div>


        </div>
    )
}

export default RecentCollection
