import moment from 'moment';
import React, { useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PdfViewModal from '../../Modals/PdfViewModal';
import ReactPlayer from 'react-player';
import { insertContinueWatching } from '../../../services/public/public.service';

const SessionCard = ({ style, course, index, courseId }) => {
    const [showPdf, setShowPdf] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [totalVideoTime, setTotalVideoTime] = useState('');
    const [videoCurrentTime, setVideoCurrentTime] = useState('');

    const padToTwoDigits = (num) => {
        return num.toString().padStart(2, "0");
    }

    const toHoursAndMinutes = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return hours > 0 ? `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)} hrs` : `${padToTwoDigits(minutes)} mins`;
    }

    const handleGetDuration = (value) => {
        setTotalVideoTime(value * 1000)
    }

    const handleGetCurrentTime = (value) => {
        setVideoCurrentTime(value.playedSeconds * 1000)
    }


    const handleSaveVideoDetails = async () => {
        if (videoCurrentTime < totalVideoTime) {
            const payload = {
                workshop_id: '',
                total_duration: parseInt(totalVideoTime),
                watched_duration: parseInt(videoCurrentTime),
                course_id: courseId,
                course_content_id: course.uuid
            }
            const res = await insertContinueWatching(payload);
            if (res.data.status) {
                console.log(res.data.data);
            }
        }
        setShowVideo(false);
    }

    return (
        <div className={`${style.CourseCard}`}>
            <div className="row">
                <div className={`${course.content_type != 'guides' ? 'col-lg-8' : 'col-lg-7'} `}>
                    <div className={`${style.CourseCard_mainBlock} CourseCard_mainBlock`}>
                        <div className={style.card_left}>
                            <h2 className='fz32 inter fw-po-bold black-dark-700'>{padToTwoDigits(index + 1)}</h2>
                        </div>
                        <div className={style.card_right}>
                            <h1 className='fz20 inter fw-po-semi black-dark-800 mb4'>{course.name}</h1>
                            {course.mentors && <h3 className='fz12 fw-po-medium black-dark-700 mb-12 d-flex align-items-center inter'>
                                {course.content_type === 'live' && <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><rect x="1.59221" y="4.309" width="20.8148" height="15.1111" rx="2.11111" stroke="#97979D" strokeWidth="1.75309"></rect><circle cx="12.0004" cy="10.3705" r="3.25926" stroke="#97979D" strokeWidth="1.72839"></circle><path d="M10.3714 13.1231C9.45896 13.3746 8.62118 13.8299 7.92585 14.4519C7.23051 15.074 6.69714 15.8455 6.36893 16.7039C6.36893 16.7039 5.95067 17.655 5.83956 18.692" stroke="#97979D" strokeWidth="1.72839"></path><path d="M13.531 13.1231C14.4434 13.3746 15.2812 13.8299 15.9765 14.4519C16.6718 15.074 17.2052 15.8455 17.5334 16.7039C17.5334 16.7039 17.9517 17.655 18.0628 18.692" stroke="#97979D" strokeWidth="1.72839"></path></svg>
                                }
                                {course.content_type === 'recording' && <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_2234_22083)">
                                        <circle cx="12" cy="12" r="11" stroke="#97979D" strokeWidth="2"></circle>
                                        <path d="M9.75744 7.90433L16.5221 11.8074C16.6221 11.8651 16.6222 12.0094 16.5222 12.0672L9.75971 15.974C9.65972 16.0318 9.5347 15.9597 9.53467 15.8442L9.53247 8.03429C9.53244 7.91882 9.65742 7.84662 9.75744 7.90433Z" fill="#97979D" stroke="97979D" strokeWidth="1.7"></path>
                                    </g>

                                </svg>
                                }
                                <span>
                                    {course.content_type === 'live' && 'Live Session by '}
                                    {course.content_type === 'recording' && 'Recording by '}
                                    {course.mentors.map(item => item.name).join(', ')}
                                </span></h3>}
                            {course.content_type === 'guides' &&
                                <h3 className='fz12 fw-po-medium black-dark-700 mb-12 d-flex align-items-center inter'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2ZM6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H7ZM7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7ZM7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H7Z" fill="#92929D"></path></svg>
                                    Guide
                                </h3>
                            }
                            {course.short_description && course.content_type != 'guides' && <p className='fz14 inter black-dark-700 mb-12'>{course.short_description}</p>}

                            {course.content_type === 'live' && course.session_start_time && course.session_end_time && <p className="inter mb-0 black-dark-700 fz14 fw-po-semi">
                                <span className="mr-12">{moment(course.session_start_time).format('hh:mm a')} - {moment(course.session_end_time).format('hh:mm a')}</span> <span>{moment(course.session_date).format('Do MMM YYYY')}</span>
                            </p>}

                            {course.content_type === 'live' && !course.session_start_time && !course.session_end_time && course.session_date && <p className="inter mb-0 black-dark-700 fz14 fw-po-semi">
                                <span className="mr-12">{moment(course.session_date).format('Do MMM YYYY')}</span>
                            </p>}
                            {course.content_type === 'recording' && <p className="inter mb-0 black-dark-700 fz14 fw-po-semi">{toHoursAndMinutes(course.recording_length)}</p>}
                        </div>
                    </div>
                </div>
                <div className={`${course.content_type != 'guides' ? 'col-lg-4 d-flex justify-content-end align-items-sm-end flex-column mt-lg-0 mt-4' : 'col-lg-5 d-flex justify-content-end align-items-sm-end flex-column mt-lg-0 mt-4'} `}>
                    {/* guide */}
                    {course.content_type === 'guides' && <div className={`${style.btn_group} d-flex ml-sm-auto`}>
                        <button
                            className={style.outlinebtn}
                            onClick={() => setShowPdf(true)}
                            style={{ cursor: (!course.upload_link && !course.file_url) ? 'not-allowed' : '' }}
                            disabled={!course.upload_link && !course.file_url}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2ZM6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H7ZM7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7ZM7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H7Z" fill="#92929D" />
                            </svg>View</button>
                        <button className={style.downloadbtn}
                            onClick={() => {
                                if (course.upload_link || course.file_url)
                                    window.location.assign(course.upload_link || course.file_url)
                            }}
                            disabled={!course.upload_link && !course.file_url}
                            style={{ cursor: (!course.upload_link && !course.file_url) ? 'not-allowed' : '' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.575C11.8667 15.575 11.7417 15.554 11.625 15.512C11.5083 15.4707 11.4 15.4 11.3 15.3L7.7 11.7C7.51667 11.5167 7.425 11.2833 7.425 11C7.425 10.7167 7.51667 10.4833 7.7 10.3C7.88333 10.1167 8.12067 10.0207 8.412 10.012C8.704 10.004 8.94167 10.0917 9.125 10.275L11 12.15V5C11 4.71667 11.096 4.479 11.288 4.287C11.4793 4.09567 11.7167 4 12 4C12.2833 4 12.521 4.09567 12.713 4.287C12.9043 4.479 13 4.71667 13 5V12.15L14.875 10.275C15.0583 10.0917 15.296 10.004 15.588 10.012C15.8793 10.0207 16.1167 10.1167 16.3 10.3C16.4833 10.4833 16.575 10.7167 16.575 11C16.575 11.2833 16.4833 11.5167 16.3 11.7L12.7 15.3C12.6 15.4 12.4917 15.4707 12.375 15.512C12.2583 15.554 12.1333 15.575 12 15.575ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V16C4 15.7167 4.09567 15.479 4.287 15.287C4.479 15.0957 4.71667 15 5 15C5.28333 15 5.521 15.0957 5.713 15.287C5.90433 15.479 6 15.7167 6 16V18H18V16C18 15.7167 18.096 15.479 18.288 15.287C18.4793 15.0957 18.7167 15 19 15C19.2833 15 19.5207 15.0957 19.712 15.287C19.904 15.479 20 15.7167 20 16V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6Z" fill="#92929D" />
                            </svg>Download</button>
                    </div>}

                    {/* live */}
                    {course.content_type === 'live' &&
                        <>
                            {(moment(course.session_start_time) <= moment() && moment(course.session_end_time) > moment()) &&
                                < div className={`${style.btn_group} d-flex`}>
                                    <a className={style.joinnow} onClick={() => {
                                        if (!course.zoom_link) {
                                            toast.warning('Zoom link not available at the moment, please try again in few minutes.')
                                            return;
                                        }
                                        window.open(course.zoom_link, '_blank')
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect x="1.59221" y="4.309" width="20.8148" height="15.1111" rx="2.11111" stroke="#97979D" strokeWidth="1.75309" />
                                            <circle cx="12.0004" cy="10.3705" r="3.25926" stroke="#97979D" strokeWidth="1.72839" />
                                            <path d="M10.3714 13.1231C9.45896 13.3746 8.62118 13.8299 7.92585 14.4519C7.23051 15.074 6.69714 15.8455 6.36893 16.7039C6.36893 16.7039 5.95067 17.655 5.83956 18.692" stroke="#97979D" strokeWidth="1.72839" />
                                            <path d="M13.531 13.1231C14.4434 13.3746 15.2812 13.8299 15.9765 14.4519C16.6718 15.074 17.2052 15.8455 17.5334 16.7039C17.5334 16.7039 17.9517 17.655 18.0628 18.692" stroke="#97979D" strokeWidth="1.72839" />
                                        </svg>Join Now</a>
                                </div>}
                            {
                                (moment(course.session_start_time).diff(moment(), 'hours') < 24) &&
                                (moment(course.session_start_time) > moment()) &&
                                < div className={`${style.btn_group} d-flex`}>
                                    <div className={`${style.completebtn} ${style.liveinbtn}`}  >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect x="1.59221" y="4.309" width="20.8148" height="15.1111" rx="2.11111" stroke="#97979D" strokeWidth="1.75309" />
                                            <circle cx="12.0004" cy="10.3705" r="3.25926" stroke="#97979D" strokeWidth="1.72839" />
                                            <path d="M10.3714 13.1231C9.45896 13.3746 8.62118 13.8299 7.92585 14.4519C7.23051 15.074 6.69714 15.8455 6.36893 16.7039C6.36893 16.7039 5.95067 17.655 5.83956 18.692" stroke="#97979D" strokeWidth="1.72839" />
                                            <path d="M13.531 13.1231C14.4434 13.3746 15.2812 13.8299 15.9765 14.4519C16.6718 15.074 17.2052 15.8455 17.5334 16.7039C17.5334 16.7039 17.9517 17.655 18.0628 18.692" stroke="#97979D" strokeWidth="1.72839" />
                                        </svg>Live at {moment(course?.session_start_time).format('h:mm a')}</div>
                                </div>
                            }
                        </>
                    }
                    {course.content_type === 'live' &&
                        moment(course.session_end_time) < moment() &&
                        <>
                            <OverlayTrigger className=''
                                placement="auto"

                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                        <div className='info-msg'>
                                            <p className='d-flex fz18 w-auto'>Recording will be available shortly</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <button className={`${style.completebtn} d-md-flex d-none`}>
                                    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#97979D" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#97979D" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#97979D" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    Completed
                                </button>
                            </OverlayTrigger>

                            <button className={`${style.completebtn} d-md-none`} onClick={() => toast.warning('Recording will be available shortly')}>
                                <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#97979D" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#97979D" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#97979D" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                Completed
                            </button></>

                    }


                    {/* recording */}
                    {course.content_type === 'recording' &&
                        <div className={`${style.btn_group} d-flex ml-auto`}>
                            <button className={style.playbtn} onClick={() => {
                                if (course.recording_url || course.upload_link) {
                                    setShowVideo(true)
                                } else {
                                    toast.warning('Recording will be available soon.')
                                }
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_2234_22083)">
                                        <circle cx="12" cy="12" r="11" stroke="#97979D" strokeWidth="2"></circle>
                                        <path d="M9.75744 7.90433L16.5221 11.8074C16.6221 11.8651 16.6222 12.0094 16.5222 12.0672L9.75971 15.974C9.65972 16.0318 9.5347 15.9597 9.53467 15.8442L9.53247 8.03429C9.53244 7.91882 9.65742 7.84662 9.75744 7.90433Z" fill="#97979D" stroke="97979D" strokeWidth="1.7"></path>
                                    </g>
                                </svg>
                                Play Now
                            </button>
                        </div>
                    }
                </div>
            </div>
            {
                showVideo && <Modal size="xl" show={showVideo} onHide={() => setShowVideo(false)} backdrop="static" fullscreen centered dialogClassName="modal-radius">
                    <a onClick={handleSaveVideoDetails} className='cursor-pointer close position-absolute video-close'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#ffff"></path></svg>
                    </a>
                    <ReactPlayer
                        url={course.recording_url || course.upload_link}
                        controls={true}
                        className='learnvideo'
                        width={'100%'}
                        height={'auto'}
                        onDuration={handleGetDuration}
                        onProgress={handleGetCurrentTime}
                    />
                    {/* <video controls autoPlay controlsList="nodownload" preload='preloadauto' className='learnvideo' width={'100%'} height={'auto'}>
                        <source src={course.recording_url || course.upload_link} type="video/mp4" />
                    </video> */}
                </Modal>
            }

            {
                showPdf && <div className='modal-pdf'>
                    <PdfViewModal show={showPdf} handleClose={() => setShowPdf(false)} pdfItem={{ ...course, file_url: (course.upload_link || course.file_url) }} />
                </div>
            }
        </div >
    )
}

export default SessionCard
