import React, { useState } from 'react'
import BasicModal from '../../../Modals/BasicModal'
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import pdfimg from '../../../../assets/icons/pdfview.jpg';

const DocumentsGallery = ({ show, closeModal, documents }) => {
    const [selectdImg, setSelectdImg] = useState();
    const [showLightModal, setShowLightModal] = useState(false);

    console.log(documents)
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass=' !max-w-[80%] md:!mx-auto !mx-4'>
            <>
                <div className=' !pt-5 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <div className='flex items-center justify-between !px-6'>
                        <h2 className="inter text-textblack text-xl font-semibold">Payment Proofs</h2>
                        <button className='' onClick={closeModal}><CloseIcon /></button>
                    </div>
                    {documents?.length > 0 ? <div className='!mt-10 max-h-[70vh] overflow-auto !px-6'>
                        <div className='grid sm:grid-cols-3 gap-x-6 gap-y-6'>
                            {
                                documents.map((item, index) => (
                                    <div >
                                        <div className='!border border-grayLight rounded h-[210px] group  cursor-pointer relative' onClick={() => {
                                            if (!['video/mp4', 'application/pdf'].includes(item?.file_type)) {
                                                setSelectdImg(index)
                                                setShowLightModal(true)
                                            } else {
                                                window.open(item.url, '_blank')
                                            }

                                        }} >
                                            {!['pdf', 'pdfx'].includes(item.name.split('.').pop()) && <img src={item.url} alt="" className='w-full h-full object-contain' />}
                                            {['pdf', 'pdfx'].includes(item.name.split('.').pop()) && <img src={pdfimg} alt="" className=' w-100 h-100' />}
                                            <div
                                                className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer bg-black200'
                                                onClick={() => {
                                                    const link = document.createElement("a");
                                                    link.href = item.url;
                                                    link.download = `${item.name}`;
                                                    link.click();
                                                }}
                                            >
                                                <DownloadIcon />
                                            </div>
                                        </div>
                                        <p className='text-textblack text-base font-semibold inter !mt-1 truncate'>
                                            {item.name}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div> :
                        <div className='flex items-center justify-center'>
                            <p className='font-bold text-lg'>No Documents Found</p>
                        </div>
                    }
                </div>
                {showLightModal && <Lightbox
                    open={showLightModal}
                    close={() => setShowLightModal(false)}
                    slides={documents?.filter(item => (!['video/mp4', 'application/pdf'].includes(item?.file_type))).map(item => ({ src: item.url }))}
                    carousel={{
                        finite: true
                    }}
                    index={selectdImg}
                    plugins={[Thumbnails]}
                // video={{
                //     controls: true,
                //     playsInline: true,
                //     autoPlay: false,
                //     loop: false,
                //     muted: true,
                //     disablePictureInPicture: false,
                //     disableRemotePlayback: false,
                //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
                //     crossOrigin: '',
                //     preload: '',
                // }}
                />}
            </>
        </BasicModal>
    )
}

export default DocumentsGallery