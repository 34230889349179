import React from 'react'
import CenterModal from './center-modal'
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as AddyourlistImage } from '../../assets/addyourlist.svg';
import { useState } from 'react';

const AddYourListModal = ({ show, closeModal, style, handleAddToList, item }) => {
    const [check, setCheck] = useState(false);
    const handleChange = (e) => {
        setCheck(!check)
        localStorage.setItem('dontShow', !check);
    }

    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='AddYourListModal'>

            <div className={`${style.AddYourListHeader} d-flex align-items-center justify-content-between`}>
                <h2 className='fz18 black fw-po-medium mb-0 inter'>Project Added to your list</h2>
                <button onClick={closeModal} className='border-0 bg-tr'><CloseIcon /></button>
            </div>
            <div className={`${style.AddYourListBody}`}>
                <figure>
                    <AddyourlistImage />
                </figure>
                <p className='mt-20 fz16 inter fw-po-medium'>The add to my list feature allows you to add any project to your project list. Once added, you can access it later from <span className='pr-color'>Settings &gt; Project Manager</span></p>
            </div>
            <div className={`${style.AddYourListfooter}`}>
                <label>
                    <input type="checkbox" onChange={handleChange} checked={check} /> Dont show this again
                </label>
                <button className='pr-btn w-100' onClick={() => {
                    handleAddToList(item.uuid);
                    closeModal();
                }}>Got It</button>
            </div>
        </CenterModal>
    )
}

export default AddYourListModal