import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SliderModal from './SliderModal';
import { toast } from 'react-toastify';
import InputSelect from '../InputGroup/InputSelect';
import { eoiDoneSchema } from '../../validators/leads.validator';
import { Formik } from 'formik';
import { getLeadByUuid, getProjectsByLead, insertEoiDone } from '../../services/private/leads.service';
import DateTimePicker from '../InputGroup/DateTime';
import { getAllProjects } from '../../services/private/company.service';

export default function EoiDoneModal({
    show,
    setShow,
    leadId,
    onSuccess
}) {

    const [projects, setProjects] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [leadDetails, setLeadDetails] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getLeadProjects = async (leadId) => {
        await getProjectsByLead(leadId).then(res => {
            if (res.status === 200) {
                const projectData = res.data.data.map((data) => {
                    return {
                        value: data.id,
                        label: data.name
                    }
                })
                setProjects(projectData)
            }
        })
    }

    const handleEoiComplete = async (values) => {
        setIsSubmitting(true);
        await insertEoiDone(selectedLeadId, {
            "scheduleDate": values.scheduleDate,
            "remarks": values.remarks,
            "projectId": values.projectId,
            "followupScheduleDate": values.followupScheduleDate,
        }).then((res) => {
            if (res.status === 200) {
                toast.success(`Token Done for ${leadDetails?.customer?.data?.name}`)
                onSuccess();
                setShow(false)
            }
        }).catch(e => {
            toast.warn("Something Went Wrong")
        })
        setIsSubmitting(false);
    }


    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjectList(projects);
            }
        })
    }

    const getLeadDetails = async () => {
        getLeadByUuid(leadId).then(res => {
            if (res.status === 200) {
                setLeadDetails(res.data.data)
            }
        })
    }

    useEffect(() => {
        getLeadDetails();
        getAllProjectsByCompanyId();
    }, [])

    useEffect(() => {
        if (leadId) {
            setSelectedLeadId(leadId)
            getLeadProjects(leadId)
        }
    }, [leadId]);

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                "Token Done & Followup"
            }
            body={
                <>
                    <Formik
                        initialValues={{
                            scheduleDate: '',
                            remarks: '',
                            followupScheduleDate: '',
                            projectId: projects.length ? projects[0]?.value : '',
                        }}
                        onSubmit={async (values, actions) => {
                            await handleEoiComplete(values)
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={eoiDoneSchema}
                        enableReinitialize
                    >
                        {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return (

                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5'>
                                            <div className={`form-group ${errors['leadId'] ? 'invalid' : ''} `}>
                                                <label className='mt-3'>Lead Name</label>
                                                <div className='text-capitalize fz16 fw-po-medium'>
                                                    {leadDetails?.customer?.data?.name}
                                                </div>
                                            </div>
                                            <DateTimePicker
                                                onValueChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "scheduleDate",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                label="Token Date & Time"
                                                name="scheduleDate"
                                                error={errors['scheduleDate']}
                                                handelBlur={handleBlur}
                                                value={values['scheduleDate']}
                                            />
                                            <label className='mt-3'>Project</label>

                                            <InputSelect
                                                index={4}
                                                name="Project"
                                                error={errors['projectId']}
                                                touched={touched['projectId']}
                                                isMulti={false}
                                                options={[...projectList]}
                                                value={projectList.find(proj => proj.value === (values['projectId'] || projects[0]?.value))}
                                                placeholder="Select Project"
                                                onChange={(e) => {
                                                    console.log({ e })
                                                    const value = {
                                                        target: {
                                                            name: "projectId",
                                                            value: e.value
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                            />
                                            <label className='mt-3'>Any Comments (optional)</label>
                                            <textarea
                                                className="form-control mb-16 "

                                                placeholder=" Enter any Token comments"
                                                onChange={handleChange}
                                                value={values.remarks}
                                                name="remarks"
                                            ></textarea>

                                            <DateTimePicker
                                                onValueChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "followupScheduleDate",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                label="Select Followup Date & Time"
                                                name="followupScheduleDate"
                                                error={errors['followupScheduleDate']}
                                                handelBlur={handleBlur}
                                                value={values['followupScheduleDate']}

                                            />
                                            {values && values.followupScheduleDate ?
                                                <div className="w-100 text-center mt-2 px-24" >

                                                    <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-0'>We will remind you <span className='black fw-po-medium'>15 mins</span>  prior to <span className='black fw-po-medium'>{moment(values.followupScheduleDate).format("hh:mm a, Do MMM YYYY")}</span></p>
                                                </div>
                                                : " "}
                                        </div>

                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">

                                            <button
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="pr-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Confirm
                                            </button>

                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}