export const FB_LEAD_GEN_IN_IT = 'FB_LEAD_GEN_IN_IT';
export const FB_LEAD_GEN_IN_IT_SUCCESS = 'FB_LEAD_GEN_IN_IT_SUCCESS';
export const FB_LEAD_GEN_IN_IT_ERROR = 'FB_LEAD_GEN_IN_IT_ERROR';
export const FB_LEAD_GEN_AUTHENTICATE = 'FB_LEAD_GEN_IN_AUTHENTICATE';
export const FB_LEAD_GEN_AUTHENTICATE_SUCCESS = 'FB_LEAD_GEN_IN_AUTHENTICATE_SUCCESS';
export const FB_LEAD_GEN_AUTHENTICATE_ERROR = 'FB_LEAD_GEN_IN_AUTHENTICATE_ERROR';




export function fbLeadGenInit(  ) {
    
    return {
        type: FB_LEAD_GEN_IN_IT,
        
    };
}



export function fbLeadGenInitError( error ) {
    
    return {
        type: FB_LEAD_GEN_IN_IT_ERROR,
        error
        
    };
}



export function fbLeadGenInitSuccess(  ) {
    
    return {
        type: FB_LEAD_GEN_IN_IT_SUCCESS
        
        
    };
}


export function fbLeadGenAuthenticate( payload  ) {
    
    return {
        type: FB_LEAD_GEN_AUTHENTICATE,
        payload
    };
}

export function fbLeadGenAuthenticateSucess(   ) {
    
    return {
        type: FB_LEAD_GEN_AUTHENTICATE_SUCCESS,
        
    };
}


export function fbLeadGenAuthenticateError( error ) {
    
    return {
        type: FB_LEAD_GEN_AUTHENTICATE_ERROR,
        error
        
    };
}
