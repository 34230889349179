import React, { useCallback, useState } from 'react'
import BasicModal from '../../Modals/BasicModal';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import { BankList, PaymentModeList } from '../../../helpers/enums';
import DateTimePicker from '../../InputGroup/DateTime';
import { uploadFile } from '../../../services/public/public.service';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import { addDebitTransaction, addPDC } from '../../../services/private/inventory.service';
import InputTextarea from '../../InputGroup/InputTextarea';
import { toast } from 'react-toastify';
import { ReactComponent as AddImageIcon } from '../../../assets/icons/addImage.svg';
import { ReactComponent as UploadImgIcon } from '../../../assets/icons/imgupload.svg';
import InputRupees from '../../InputGroup/InputRupees';

const AddPDC = ({ show, closeModal, item }) => {
    const [pdcDocument, setPdcDocument] = useState([]);
    const [dragging, setDragging] = useState(false);
    const [pdcs, setPdcs] = useState([{}])

    const uploadFileToS3 = async (file, index, docIndex) => {
        if (file.size / 1048576 > 5) {
            toast.warning('File size exceeds 5mb');
            return;
        }
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }
            if (docIndex >= 0) {
                let newPdcs = [...pdcs];
                newPdcs[index]['documents'][docIndex] = newFile;
                setPdcDocument(newPdcs)
            } else {
                let newPdcs = [...pdcs];
                // console.log(newPdcs, 'new pdcs')
                if (!newPdcs[index]['documents']) {
                    newPdcs[index]['documents'] = [newFile]
                } else {

                    let docs = [...newPdcs[index]['documents'], ...newFile]
                    newPdcs[index]['documents'] = docs
                }
                setPdcDocument(newPdcs)
                // setPdcDocument([...pdcDocument, newFile])
            }

        }
    }

    const handleOnChange = async (e, index, docIndex) => {
        uploadFileToS3(e.target.files[0], index, docIndex);
    }

    const handleDrag = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setDragging(event.type !== 'dragleave');
    }, []);

    const handleDrop = useCallback((e, index) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer || e.target;
        if (files[0]) {
            uploadFileToS3(files[0], index);
        }
        setDragging(false);
        return false;
    }, []);

    const handleubmit = async () => {
        // if (!amountPaid || !paymentMode || !transtionReason) {
        //     setError(true);
        //     return;
        // } else {
        //     setError(false);
        // }
        const payload = {
            customerPDC: pdcs
        }

        const res = await addPDC(item?.booking_uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            closeModal();
        }
    }
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!p-6 !pb-0 relative flex items-center justify-between'>
                    <div className='flex gap-x-4 border rounded-lg p-2'>
                        <AddImageIcon />
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>

                <div className=' pb-8 h-full overflow-y-auto'>
                    <div className=' '>
                        {pdcs.map((pdc, index) => (
                            <div className='w-full p-6'>
                                <div className=''>
                                    <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter flex'>
                                        Post Dated Cheques
                                        {pdcs.length > 1 && <span className='ml-2'>{index + 1}</span>}
                                    </h1>
                                    {/* <p className='m-0 inter text-sm text-black700'> Upload post dated checks for customer</p> */}
                                </div>
                                <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5'>
                                    <InputText
                                        inputclass='!m-0'
                                        label='Check Number'
                                        placeholder='Enter Check Number'
                                        value={pdc?.cheque_no}
                                        onChange={(e) => {
                                            let newPdcs = [...pdcs];
                                            newPdcs[index]['cheque_no'] = e.target.value
                                            setPdcs(newPdcs)
                                        }}

                                    />
                                    <InputRupees
                                        inputclass='!m-0'
                                        label='Check Amount'
                                        placeholder='Enter Check Amount'
                                        value={pdc?.cheque_amount}
                                        onChange={(value) => {
                                            let newPdcs = [...pdcs];
                                            newPdcs[index]['cheque_amount'] = value
                                            setPdcs(newPdcs)
                                        }}
                                        handleBlur={() => { }}
                                    // error={error && !amountPaid ? 'Please Enter Amt. Paid' : ''}
                                    />
                                    <DateTimePicker
                                        label='Check Date'
                                        inputclass='mt-0'
                                        placeholder='Select Date'
                                        value={pdc?.cheque_date}
                                        onValueChange={(date) => {
                                            let newPdcs = [...pdcs];
                                            newPdcs[index]['transaction_date'] = date;
                                            setPdcs(newPdcs)
                                        }}
                                        showTime={false}

                                    />
                                    {/* <InputText
                                        label="Bank Branch"
                                        placeholder="Enter Bank Branch"
                                        type="text"
                                        inputclass='m-0 inter'
                                        value={bankBranch}
                                        onChange={(e) => setBankBranch(e.target.value)}
                                    /> */}
                                </div>
                                <div className='mt-3'>
                                    <label className='text-lg font-semibold text-textblack !mb-2 inter'>Upload Document</label>
                                    <div className={`!mt-4 grid gap-3 ${(!pdc?.documents || pdc?.documents?.length === 0) ? 'grid-cols-1' : 'grid-cols-3'} `}>
                                        <div >
                                            <div
                                                className={`relative group border-2 border-dashed border-primary h-[154px] rounded-lg flex flex-col items-center justify-center ${dragging ? 'file-drag' : ''}`}
                                                draggable="true"
                                                onDrop={handleDrop}
                                                onDragLeave={handleDrag}
                                                onDragOver={handleDrag}
                                            >
                                                <input type="file" className='d-none' id={`upload-payment-proof`} onChange={(e) => handleOnChange(e, index)} />
                                                <div>
                                                    <UploadImgIcon />
                                                </div>
                                                <div className={`${pdc?.documents?.length > 0 ? 'flex-col' : ''} flex items-center`}>
                                                    <button
                                                        type='button'
                                                        className='!text-primary flex items-center justify-center py-1 px-2 gap-1 text-sm font-medium underline-offset-1 underline'
                                                        onClick={() => {
                                                            document.getElementById(`upload-payment-proof`).click();
                                                        }}
                                                    >
                                                        {/* <Pluswhite /> */}
                                                        Click To upload
                                                    </button>
                                                    <span className=''>or drag and drop</span> <br />
                                                </div>
                                                <span className='block'>Image size : 5 Mb or less</span>
                                            </div>
                                        </div>
                                        {pdc?.documents?.length > 0 && pdc?.documents.map((doc, docIndex) => (
                                            <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg relative'>
                                                <input type="file" className='d-none' id={`upload-payment-proof-${docIndex}`} onChange={(e) => handleOnChange(e, index, docIndex)} />
                                                <figure className='mb-0 !table !mx-auto relative group'>
                                                    <Imageplacehoder className='w-full ' />
                                                    <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                        <button
                                                            className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                            onClick={() => {
                                                                document.getElementById(`upload-payment-proof-${docIndex}`).click();
                                                            }}
                                                        ><PluswhiteIcon />Replace</button>

                                                        <button
                                                            className={`${style.delbtn} inter border-0 bg-white`}
                                                            onClick={() => {
                                                                let newFiles = [...pdcs];
                                                                newFiles[index].splice(docIndex, 1);
                                                                setPdcs(newFiles);

                                                            }}
                                                        ><TrashIcon /></button>
                                                    </div>
                                                </figure>
                                                <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {doc.fileName}</p>
                                            </div>
                                        ))
                                        }
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='text-center border rounded-lg mx-4 py-3  border-primary bg-blueHighlight'>
                        <button className='text-primary font-medium' onClick={() => {
                            setPdcs([...pdcs, {}])
                        }} > + Add PDC</button>
                    </div>
                </div>
                {/* <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col"> */}

                <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-end shadow-topshadow sm:flex-row flex-col">
                    <button
                        className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter inline-flex items-center justify-center gap-x-2"
                        onClick={closeModal}
                    >Cancel</button>
                    <button onClick={handleubmit} className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">Submit</button>
                </div>
                {/* </div> */}
            </div>
        </BasicModal>
    )
}

export default AddPDC