import React, { useContext, useEffect, useState } from 'react'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'
import { settingsBaseUrl } from '../../../routes/settings.routes';
import { getAuthRolePermissions } from '../../../services/private/company.service';
import AreYouSureModal from '../../../components/Modals/AreYouSureModal';
import Consumer from '../../../helpers/context';
import { checkIsSomeItemAvailableInList } from '../../../helpers/helpers';
import { ReactComponent as RightIcon } from '../../../assets/icons/righticon.svg';

// const getSubText = () => {
//     if (localStorage.getItem('fb_steps') && +localStorage.getItem('fb_steps') < 4) {
//         return `Do you wish to cancel Integration? Changes that you have made may not have been saved.`
//     }
//     if (localStorage.getItem('project_state') === 'create') {
//         return "Do you want to cancel the project creation? Any unsaved changes you made may be lost."
//     }
// }

export default function SettingsSidebar({ settingsRoutes, closeSideBar }) {

    const currentRoute = window.location.href.split('/');

    const history = useHistory();

    return (
        <div className=''>
            {
                settingsRoutes?.length && settingsRoutes?.map((section) => {
                    const { name: sectionName, url: sectionUrl, childRoutes } = section
                    return (
                        <>
                            <a
                                className={` flex justify-between items-center !px-4 !pt-3 !pb-3  font-medium text-sm !border-b !border-[#E2E2EA] cursor-pointer  hover:!bg-blue100 hover:!text-blue-900 hover:!cursor-pointer text-capitalize ${currentRoute.includes(sectionUrl) ? '!bg-blue100 !text-blue-900' : '!bg-white !text-black'}`}
                                id={"heading" + sectionUrl}
                                onClick={() => {
                                    history.push(settingsBaseUrl + "/" + sectionUrl)
                                    closeSideBar()
                                }
                                }
                            >
                                {sectionName}
                                <span><RightIcon /></span>
                            </a >
                        </>
                    )

                })
            }

        </div >
    )
}