import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { ReactComponent as WhatsappIcon } from '../../../../assets/icons/whatsapp.svg';
import BasicModal from '../../../Modals/BasicModal';
import InputSelect from '../../../InputGroup/InputSelect';
import InputTextarea from '../../../InputGroup/InputTextarea';
import InputText from '../../../InputGroup/InputText';
const CreateCustomer = ({ show, closeModal }) => {
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[400px] !max-w-[95%] md:!mx-auto !mx-4'>

            <div className=''>
                <div className='!px-6 !pt-6'>
                    <button className='ml-auto !table' onClick={closeModal}><CloseIcon /></button>
                    <h1 className='!mt-1 text-textblack text-xl font-semibold !mb-2'>Create Customer-Dashboard Link</h1>
                    <p className='text-sm text-black700 leading-5 !mb-4'>Select Number which Customer will use for OTP Verification</p>
                    <InputSelect placeholder='Select Mobile No.' />
                </div>

                <div className="!py-4 !px-6 !mt-6 flex gap-3 items-center justify-end shadow-topshadow">
                    <button className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center inter">Cancel</button>
                    <button className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center inter">Create</button>
                </div>

            </div>

            <div className=''>
                <div className='!px-6 !pt-6'>
                    <button className='ml-auto !table' closeModal><CloseIcon /></button>
                    <h1 className='!mt-1 text-textblack text-xl font-semibold !mb-2'>Share Customer-Dashboard Link</h1>
                    <p className='text-sm text-black700 leading-5 !mb-4'>Share the below link with the customer for them to login and view their booking details.</p>
                    <div>
                        <InputTextarea
                            label='Message'
                        />
                    </div>
                    <div className='!mt-4 flex items-center gap-x-4' >
                        <InputText inputclass='w-100' label='Share link' value='www.untitledui.com/blog' />
                        <button className='!mt-8'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_571_6192)">
                                <path d="M4.16699 12.5001C3.39042 12.5001 3.00214 12.5001 2.69585 12.3732C2.28747 12.2041 1.96302 11.8796 1.79386 11.4712C1.66699 11.1649 1.66699 10.7767 1.66699 10.0001V4.33341C1.66699 3.39999 1.66699 2.93328 1.84865 2.57676C2.00844 2.26316 2.2634 2.00819 2.57701 1.8484C2.93353 1.66675 3.40024 1.66675 4.33366 1.66675H10.0003C10.7769 1.66675 11.1652 1.66675 11.4715 1.79362C11.8798 1.96277 12.2043 2.28723 12.3735 2.69561C12.5003 3.00189 12.5003 3.39018 12.5003 4.16675M10.167 18.3334H15.667C16.6004 18.3334 17.0671 18.3334 17.4236 18.1518C17.7372 17.992 17.9922 17.737 18.152 17.4234C18.3337 17.0669 18.3337 16.6002 18.3337 15.6667V10.1667C18.3337 9.23333 18.3337 8.76662 18.152 8.4101C17.9922 8.09649 17.7372 7.84153 17.4236 7.68174C17.0671 7.50008 16.6004 7.50008 15.667 7.50008H10.167C9.23357 7.50008 8.76686 7.50008 8.41034 7.68174C8.09674 7.84153 7.84177 8.09649 7.68198 8.4101C7.50033 8.76662 7.50033 9.23333 7.50033 10.1667V15.6667C7.50033 16.6002 7.50033 17.0669 7.68198 17.4234C7.84177 17.737 8.09674 17.992 8.41034 18.1518C8.76686 18.3334 9.23357 18.3334 10.167 18.3334Z" stroke="#667085" strokeWidth="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_571_6192">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></button>
                    </div>

                    <div className='!mt-4'>
                        <button className='flex items-center gap-x-2 !border !border-grayLight w-full rounded-lg !py-2 justify-center'><WhatsappIcon />Share Now</button>
                    </div>
                </div>

                <div className="!py-4 !px-6 !mt-6 flex gap-3 items-center justify-end shadow-topshadow">
                    <button className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center inter">Cancel</button>
                    <button className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center inter">Create</button>
                </div>

            </div>


        </BasicModal>
    )
}

export default CreateCustomer
