export const LEAD_DETAIL_ACTIONS = {
    GET_LEAD_DETAIL: 'GET_LEAD_DETAIL',
    SET_LEAD_DETAIL: 'SET_LEAD_DETAIL',
    GET_ACTIVITY_STATS: 'GET_ACTIVITY_STATS',
    SET_ACTIVITY_STATS: 'SET_ACTIVITY_STATS',
    GET_LATEST_ACTIVITY: 'GET_LATEST_ACTIVITY',
    SET_LATEST_ACTIVITY: 'SET_LATEST_ACTIVITY',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    UPDATE_CUSTOMER2: 'UPDATE_CUSTOMER2',
    UPDATE_LEAD: 'UPDATE_LEAD',
    UPDATE_BUDGET: 'UPDATE_BUDGET',
    GET_MASTER_ENUMS: 'GET_MASTER_ENUMS',
    SET_MASTER_ENUMS: 'SET_MASTER_ENUMS',
    GET_CUSTOMER_ENUMS: 'GET_CUSTOMER_ENUMS',
    SET_CUSTOMER_ENUMS: 'SET_CUSTOMER_ENUMS',

}

export function action_getLeadDetail(uuid) {
    return {
        type: LEAD_DETAIL_ACTIONS.GET_LEAD_DETAIL,
        uuid: uuid
    };
}

export function action_setLeadDetail(detail) {
    return {
        type: LEAD_DETAIL_ACTIONS.SET_LEAD_DETAIL,
        detail
    };
}

export function action_getActivityStats(uuid) {
    return {
        type: LEAD_DETAIL_ACTIONS.GET_ACTIVITY_STATS,
        uuid
    };
}

export function action_setActivityStats(stats) {
    return {
        type: LEAD_DETAIL_ACTIONS.SET_ACTIVITY_STATS,
        stats
    };
}

export function action_getLatestActivity(uuid) {
    return {
        type: LEAD_DETAIL_ACTIONS.GET_LATEST_ACTIVITY,
        uuid
    };
}

export function action_setLatestActivity(activity) {
    return {
        type: LEAD_DETAIL_ACTIONS.SET_LATEST_ACTIVITY,
        activity
    };
}

export function action_updateCustomer(uuid, key, value, leadUUID) {
    return {
        type: LEAD_DETAIL_ACTIONS.UPDATE_CUSTOMER,
        uuid,
        key,
        value,
        leadUUID
    };
}

export function action_updateCustomer2(key1, value1, key2, value2, uuid, leadUUID) {
    return {
        type: LEAD_DETAIL_ACTIONS.UPDATE_CUSTOMER2,
        key1,
        value1,
        key2,
        value2,
        uuid,
        leadUUID
    };
}

export function action_updateLead(uuid, key, value, leadUUID) {
    return {
        type: LEAD_DETAIL_ACTIONS.UPDATE_LEAD,
        uuid,
        key,
        value,
        leadUUID
    };
}

export function action_updateBudget(uuid, minimumBudget, maximumBudget, leadUUID) {
    return {
        type: LEAD_DETAIL_ACTIONS.UPDATE_BUDGET,
        uuid,
        minimumBudget,
        maximumBudget,
        leadUUID
    };
}

export function action_getMasterEnums() {
    return {
        type: LEAD_DETAIL_ACTIONS.GET_MASTER_ENUMS
    };
}

export function action_setMasterEnums(statuses, intents, sources) {
    return {
        type: LEAD_DETAIL_ACTIONS.SET_MASTER_ENUMS,
        statuses,
        intents,
        sources
    };
}

export function action_getCustomerEnums() {
    return {
        type: LEAD_DETAIL_ACTIONS.GET_CUSTOMER_ENUMS
    };
}

export function action_setCustomerEnums(enums) {
    return {
        type: LEAD_DETAIL_ACTIONS.SET_CUSTOMER_ENUMS,
        enums
    };
}