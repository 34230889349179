import React, { useContext, useEffect, useState } from "react";
import { useMemo } from "react";
import { getUsersForSettings } from "../../services/private/company.service";
import InputSelect from "../InputGroup/InputSelect";
import SliderModal from "./SliderModal";
import Consumer from '../../helpers/context';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',

        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};


export default function ReassignLeadModal({
    setShow,
    show,
    handleSubmit,
    assignedTo,
    count,
    selectedLeadName
}) {

    const [allUser, setAllUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [error, setError] = useState();
    const [remarks, setRemarks] = useState();

    const { userProfile } = useContext(Consumer);

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        value: data.uuid
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    const handleSubmitUser = () => {
        if (!selectedUser || count === 0) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        handleSubmit(selectedUser, remarks);
    }

    useEffect(() => {
        getAllUsers()
    }, [])

    return (
        <SliderModal
            setShow={setShow}
            show={show}
            title="Reassign Lead"
            body={<>
                <div className="h-full overflow-auto !pl-5 !pr-5">
                    {count === 1 &&
                        <div className="form-group">
                            <label className=''>Lead Name</label>
                            <div className='text-capitalize fz16 fw-po-medium '>
                                {selectedLeadName ? selectedLeadName : '-'}
                            </div>
                        </div>}

                    <InputSelect
                        index={4}
                        name="user"
                        menuPlacement="bottom"
                        value={selectedUser}
                        options={allUser.filter(user => user.value !== assignedTo)}
                        onChange={setSelectedUser}
                        placeholder="Select User"
                        label="Select User(s)"
                        error={error && !selectedUser ? 'Please Select User' : ''}
                        isMulti={userProfile?.mulit_user_assign && count > 1}
                        styles={customStyles}
                    />

                    {!(count > 1) &&
                        <>
                            <label className='mt-3'>Comments (optional)</label>
                            <textarea
                                className="form-control mb-16"
                                name="remarks"
                                value={remarks}
                                placeholder="Enter Comments..."
                                onChange={(e) => setRemarks(e.target.value)}
                            >
                            </textarea>
                        </>
                    }
                </div>
                <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                    <button
                        className="cancel outline-btn"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="pr-btn"
                        onClick={handleSubmitUser}
                    >
                        Reassign
                        {count > 1 && <span className="ml-1 fz14">{`(${count})`}</span>}
                    </button>

                </div>
            </>}
        />
    )
}