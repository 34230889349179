import React from 'react'
import ReferImg from '../../assets/proectbg.jpg';
import ProjectImg from '../../assets/projectimg.svg';
import { ReactComponent as ComingSoonIcon } from '../../assets/icons/comingsoon.svg'
import EmptyProjectImg from '../../assets/icons/Projectimg.png';

const NewProjectPage = () => {

    const styles = {
        backgroundImage: `url(${ReferImg})`,
        backgroundSize: 'cover',
        backgroundPositon: ''
    };
    return (
        <div className='sm:!px-6 !px-4 !py-5 h-full overflow-auto w-full'>
            <div className='w-full  relative' >
                <img src={ReferImg} alt="" className='w-full ' />
                <div className='absolute left-0 right-0 top-[120px] flex items-center justify-center'>
                    <div><ComingSoonIcon /></div>
                </div>
                <div className='absolute right-[142px] bottom-[-195px]'>
                    <div><img src={EmptyProjectImg} alt="" /></div>
                </div>
            </div>

            <div className='w-full mt-[140px]'>
                <h2 className='text-center text-base text-textblack font-semibold mb-2'>Exclusive Project Listing <br /> Coming soon!</h2>
                <p className='text-sm text-black600 text-center'>This area will light up once the project listings  <span className='sm:block'>page is Live.</span></p>
            </div>

        </div>
    )
}

export default NewProjectPage;