import React, { useCallback, useEffect, useState } from 'react'
import BasicModal from '../../Modals/BasicModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as AddImageIcon } from '../../../assets/icons/addImage.svg';
import { ReactComponent as UploadImgIcon } from '../../../assets/icons/imgupload.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
// import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import InputSelect from '../../InputGroup/InputSelect';
import { uploadFile } from '../../../services/public/public.service';
import { toast } from 'react-toastify';

const VisibilityList = [
    {
        label: 'Broker',
        value: 'broker'
    },
    {
        label: 'Customer',
        value: 'customer'
    }
]

const MarkMilestoneCompleteModal = ({ show, closeModal, selectedMilestone }) => {

    const [milestoneCompleted, setMilestoneCompleted] = useState(false);
    const [milestones, setMilestones] = useState([]);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        if (selectedMilestone) {
            setMilestones(selectedMilestone)
        }
    }, [selectedMilestone]);

    const uploadFileToS3 = async (file, index, docIndex) => {
        if (file.size / 1048576 > 5) {
            toast.warning('File size exceeds 5mb');
            return;
        }
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }
            if (docIndex >= 0) {
                let newMilestones = [...milestones];
                newMilestones[index]['documents'][docIndex] = newFile;
                setMilestones(newMilestones)
            } else {
                let newMilestones = [...milestones];
                // console.log(newPdcs, 'new pdcs')
                if (!newMilestones[index]['documents']) {
                    newMilestones[index]['documents'] = [newFile]
                } else {

                    let docs = [...newMilestones[index]['documents'], ...newFile]
                    newMilestones[index]['documents'] = docs
                }
                setMilestones(newMilestones)
                // setPdcDocument([...pdcDocument, newFile])
            }

        }
    }

    const handleOnChange = async (e, index, docIndex) => {
        uploadFileToS3(e.target.files[0], index, docIndex);
    }

    const handleDrag = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setDragging(event.type !== 'dragleave');
    }, []);

    const handleDrop = useCallback((e, index) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer || e.target;
        if (files[0]) {
            uploadFileToS3(files[0], index);
        }
        setDragging(false);
        return false;
    }, []);

    console.log({ selectedMilestone }, 'selectedMilestone')
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[900px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col !p-6'>
                <div className='relative flex items-center justify-between'>
                    <div className='flex gap-x-4 border rounded-lg p-2'>
                        <AddImageIcon />
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <div className='flex'>
                    <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>
                        Mark  Milestone Complete
                    </h1>
                </div>
                <div className='pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    {
                        milestones.map((milestone, index) => (
                            <div className='mt-3'>
                                <div className='flex gap-x-4 mb-2'>
                                    <h1 className='text-textblack text-base leading-7 font-semibold !mb-0 inter'>
                                        Milestone {milestone.count}
                                    </h1>
                                </div>
                                <div className='grid grid-cols-2 gap-3'>

                                    <InputSelect
                                        label={'Select Visibility'}
                                        options={VisibilityList}
                                        value={milestone.visiblity ? VisibilityList.find(i => i.value === milestone.visiblity) : ''}
                                        onChange={(value) => {
                                            let newMilestones = [...milestones];
                                            newMilestones[index]['visiblity'] = value.value;
                                            setMilestones(newMilestones)
                                        }}
                                    />
                                    <div>
                                        <div className='flex items-center justify-between'>
                                            <label className='m-0'>Remarks</label>
                                            <button
                                                type='button'
                                                className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                                onClick={() => {
                                                    let newMilestones = [...milestones];
                                                    newMilestones[index]['remark'] = ''
                                                    setMilestones(newMilestones)
                                                }}
                                            >Clear <CloseIcon className='h-3 w-3' /></button>
                                        </div>
                                        <textarea
                                            className="form-control mb-16"
                                            name="remarks"
                                            value={milestone.remark || ''}

                                            placeholder="Type a shot description..."
                                            onChange={(e) => {
                                                let newMilestones = [...milestones];
                                                newMilestones[index]['remark'] = e.target.value;
                                                setMilestones(newMilestones)
                                            }}
                                        >

                                        </textarea>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <label className='text-sm text-black700 !mb-2 inter'>Upload Document</label>
                                    <div className={`!mt-4 grid gap-3 ${(!milestone?.documents || milestone?.documents?.length === 0) ? 'grid-cols-1' : 'grid-cols-3'} `}>
                                        <div >
                                            <div
                                                className={`relative group border-2 border-dashed border-primary h-[154px] rounded-lg flex flex-col items-center justify-center ${dragging ? 'file-drag' : ''}`}
                                                draggable="true"
                                                onDrop={handleDrop}
                                                onDragLeave={handleDrag}
                                                onDragOver={handleDrag}
                                            >
                                                <input type="file" className='d-none' id={`upload-payment-proof`} onChange={(e) => handleOnChange(e, index)} />
                                                <div>
                                                    <UploadImgIcon />
                                                </div>
                                                <div className={`${milestone?.documents?.length > 0 ? 'flex-col' : ''} flex items-center`}>
                                                    <button
                                                        type='button'
                                                        className='!text-primary flex items-center justify-center py-1 px-2 gap-1 text-sm font-medium underline-offset-1 underline'
                                                        onClick={() => {
                                                            document.getElementById(`upload-payment-proof`).click();
                                                        }}
                                                    >
                                                        {/* <Pluswhite /> */}
                                                        Click To upload
                                                    </button>
                                                    <span className=''>or drag and drop</span> <br />
                                                </div>
                                                <span className='block'>Image size : 5 Mb or less</span>
                                            </div>
                                        </div>
                                        {milestone?.documents?.length > 0 && milestone?.documents.map((doc, docIndex) => (
                                            <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg relative'>
                                                <input type="file" className='d-none' id={`upload-payment-proof-${docIndex}`} onChange={(e) => handleOnChange(e, index, docIndex)} />
                                                <figure className='mb-0 !table !mx-auto relative group'>
                                                    <Imageplacehoder className='w-full ' />
                                                    <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                        <button
                                                            className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                            onClick={() => {
                                                                document.getElementById(`upload-payment-proof-${docIndex}`).click();
                                                            }}
                                                        ><PluswhiteIcon />Replace</button>

                                                        <button
                                                            className={`${style.delbtn} inter border-0 bg-white`}
                                                            onClick={() => {
                                                                let newFiles = [...milestones];
                                                                newFiles[index].splice(docIndex, 1);
                                                                setMilestones(newFiles);

                                                            }}
                                                        ><TrashIcon /></button>
                                                    </div>
                                                </figure>
                                                <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {doc.fileName}</p>
                                            </div>
                                        ))
                                        }
                                    </div>

                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='flex gap-3 !pt-2 items-center justify-end shadow-topshadow'>
                    <button
                        className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center'
                        onClick={closeModal}
                    >Cancel</button>
                    <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[188px] sm:basis-[188px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center hover:opacity-90 sm:px-0 !px-3 whitespace-nowrap'>Mark As Complete</button>

                </div>

            </div>

        </BasicModal >

    )
}

export default MarkMilestoneCompleteModal
