import React from 'react';
import { ReactComponent as DownIcon } from '../../assets/icons/Down.svg';

const Dropdown = ({ list, onChange, value, style }) => {

    return (
        <div className={`${style.inputwithdropbtn} btn-group position-absolute`}>
            <button type="button" className='border-0 bg-tr flex' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {value?.label || 'Choose value'} <DownIcon />
            </button>
            <div className={`${style.inputwithdropdownmenu} dropdown-menu w-auto `} >
                {
                    list.map((item, index) => (
                        <a
                            className="dropdown-item"
                            onClick={() => onChange(item)}
                            key={`${item.value}_${index}`}
                        >
                            {item.label}
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default Dropdown;