import {
    LEAD_DETAIL_ACTIONS
} from './actions';

// The initial state of the Login Reducer
export const initialState = {
    leadDetail: {},
    notesCount: 0,
    followupsCount: 0,
    siteVisitsCount: 0,
    latestFollowup: {},
    latestSiteVisit: {},
    availableStatuses: [],
    availableIntents: [],
    availableSources: [],
    // customer enums
    enum_furnishing: [],
    enum_possession: [],
    enum_facing: [],
    enum_purpose: [],
    enum_industry: [],
    enum_funding_source: [],
    enum_property_category: [],
    enum_usage_type: [],
    enum_property_type: [],
    enum_transaction_type: [],
};

export default function leadDetailReducer(state = initialState, action) {
    switch (action.type) {
        case LEAD_DETAIL_ACTIONS.SET_LEAD_DETAIL:
            return { ...state, leadDetail: { ...action.detail } };
        case LEAD_DETAIL_ACTIONS.SET_ACTIVITY_STATS:
            return {
                ...state,
                notesCount: action.stats.notesCount,
                followupsCount: action.stats.followupsCount,
                siteVisitsCount: action.stats.siteVisitsCount,
            };
        case LEAD_DETAIL_ACTIONS.SET_LATEST_ACTIVITY:
            return {
                ...state,
                latestFollowup: action.activity.followup,
                latestSiteVisit: action.activity.siteVisit,
            };
        case LEAD_DETAIL_ACTIONS.SET_MASTER_ENUMS:
            return {
                ...state,
                availableStatuses: action.statuses,
                availableIntents: action.intents,
                availableSources: action.sources,
            };
        case LEAD_DETAIL_ACTIONS.SET_CUSTOMER_ENUMS:
            return {
                ...state,
                enum_furnishing: action.enums.enum_furnishing,
                enum_possession: action.enums.enum_possession,
                enum_facing: action.enums.enum_facing,
                enum_purpose: action.enums.enum_purpose,
                enum_industry: action.enums.enum_industry,
                enum_funding_source: action.enums.enum_funding_source,
                enum_property_category: action.enums.enum_property_category,
                enum_usage_type: action.enums.enum_usage_type,
                enum_property_type: action.enums.enum_property_type,
                enum_transaction_type: action.enums.enum_transaction_type,
            };
        default:
            return state;
    }
}
