import React from 'react'
import client from '../../../assets/client.jpg'
import mantor from '../../../assets/mantor.jpg'

const MentorCard = ({ style, mentor }) => {

    return (
        <div className={style.MentorCard}>
            <figure>
                <img src={mentor.picture_url} alt="" />
            </figure>
            {mentor.name && <h2>{mentor.name}</h2>}
            {mentor.designation && <h3>{mentor.designation}</h3>}
            {mentor.short_description && <p className='mb-0'>{mentor.short_description}</p>}
            <ul>
                {mentor?.logo_1 && <li><img src={mentor?.logo_1} alt="logo_1" /></li>}
                {mentor?.logo_2 && <li><img src={mentor?.logo_2} alt="logo_2" /></li>}
                {mentor?.logo_3 && <li><img src={mentor?.logo_3} alt="logo_3" /></li>}
            </ul>
        </div>
    )
}

export default MentorCard
