import React, { useContext } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Consumer from '../helpers/context';

export default function RenderComponent({
    routes,
    baseUrl,

}) {

    return (
        <Switch>
            {routes.map((section) => {
                return (
                    <Route
                        path={`${baseUrl}/${section.url}`}
                        exact
                        key={section.name}
                    >
                        <section.component />
                    </Route>
                );
            })
            }
            {/* <Route
                path={`${baseUrl}/*`}>
                <Redirect
                    to={`${baseUrl}/${routes[0].url + "/" + routes[0].childRoutes[0].component}`}
                />
            </Route> */}
        </Switch>
    )
}