import React from 'react'
import CenterModal from './center-modal'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/success_lottie.json'
import moment from 'moment';

const FreemiumSuccessLoginModal = ({ show, closeModal, borkerDetails }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!w-[480px]  [&>*]:!border-0 [&>*]:!rounded-xl [&>*]:!overflow-hidden'>
            <div className={`bg-white rounded-xl pt-4 pb-8 !px-6 grow-0 shrink-0 shadow-shadowcard  `}>
                <Lottie
                    height={200}
                    width={200}

                    options={
                        { animationData: animationData }
                    }
                />

                <div className='!pt-4 !px-5  rounded-xl'>
                    <h2 className='md:text-xl text-base font-semibold text-textblack !mb-3'>Congratulations, on unlocking free content on Propacity Plus🥳   </h2>
                    <p className='m-0 text-sm font-medium text-black700'>Your subscription is limited and live for {moment(borkerDetails?.subscription?.expiry_date || '').format('Do MMMM YYYY')}.</p>

                </div>
                <div className='!mt-6'>
                    <button
                        className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-3 flex justify-center'
                        onClick={() => {
                            closeModal()
                        }}
                    >
                        Great!
                    </button>
                </div>
            </div>
        </CenterModal>
    )
}

export default FreemiumSuccessLoginModal