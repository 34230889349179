import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { API_METHOD, API_URLS } from "../../../../config/apiUrl";
import requests, { handleError } from "../../../../helpers/requests";
import { action_getActivityStats, action_getLatestActivity, action_getLeadDetail } from "../../actions";
import { action_getTimeline } from "../Recent Activities/actions";
import { action_canCreateSV, action_getSV, action_setCanCreateSV, action_setSV, SITE_VISIT_ACTIONS } from "./actions";

export function* svSaga() {

    // Create site visit
    function createSiteVisitCall(leadUUID, payload) {
        return requests(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.siteVisits.insert}/${leadUUID}`, null, payload);
    }

    function* createSiteVisitWatcher(action) {
        try {
            yield call(createSiteVisitCall, action.leadUUID, action.payload);
            // get site visits
            yield put(action_getSV(action.leadUUID));
            // Upate stats
            yield put(action_getActivityStats(action.leadUUID))

            // Get lead detail
            yield put(action_getLeadDetail(action.leadUUID))

            //  Get latest
            yield put(action_getLatestActivity(action.leadUUID))

            // Get Timeline
            yield put(action_getTimeline(action.leadUUID))

            // Get Timeline
            yield put(action_canCreateSV(action.leadUUID))
        } catch (error) {
            console.log(error);
        }
    }

    // Get site visits
    function getSiteVisitsCall(leadUUID) {
        return requests(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.siteVisits.getAllByLeadUUID}/${leadUUID}`, null, null);
    }

    function* getSiteVisitsWatcher(action) {
        try {
            let result = yield call(getSiteVisitsCall, action.leadUUID)
            yield put(action_setSV(result.data.data));
        } catch (error) {
            console.log(error, 'error');
        }
    }

    // Remove site visit
    function removeSiteVisitCall(siteVisitUUID) {
        return requests(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.siteVisits.removeFromLead}/${siteVisitUUID}`, null, null);
    }

    function* removeSiteVisitWatcher(action) {
        try {
            let result = yield call(removeSiteVisitCall, action.siteVisitUUID)
            yield put(action_getSV(action.leadUUID));

            //  Get latest
            yield put(action_getLatestActivity(action.leadUUID))
        } catch (error) {
            console.log(error, 'error');
        }
    }

    // Mark completed 
    function markCompletedCall(siteVisitUUID) {
        return requests(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.siteVisits.markCompletedByUUID}/${siteVisitUUID}`, null, null);
    }

    function* markCompletedWatcher(action) {
        try {
            let result = yield call(markCompletedCall, action.siteVisitUUID)
            yield put(action_getSV(action.leadUUID));
            yield put(action_canCreateSV(action.leadUUID));

            //  Get latest
            yield put(action_getLatestActivity(action.leadUUID))

            // Get Timeline
            yield put(action_getTimeline(action.leadUUID));

            // Get lead detail
            yield put(action_getLeadDetail(action.leadUUID))

        } catch (error) {
            console.log(error, 'error');
        }
    }

    // Update site visit
    function updateSiteVisitCall(siteVisitUUID, payload) {
        return requests(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.siteVisits.update}/${siteVisitUUID}`, null, payload);
    }

    function* updateSiteVisitWatcher(action) {
        try {
            console.log(action, 'action in update sitevisit watcher');
            let result = yield call(updateSiteVisitCall, action.siteVisitUUID, action.payload)
            yield put(action_getSV(action.leadUUID));

            // Get Timeline
            yield put(action_getTimeline(action.leadUUID));

            //  Get latest
            yield put(action_getLatestActivity(action.leadUUID))

        } catch (error) {
            console.log(error, 'error');
        }
    }

    // Can create followups
    function canCreateSVCall(leadUUID) {
        return requests(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.siteVisits.canCreateSV}/${leadUUID}`, null, null);
    }

    function* canCreateSVWatcher(action) {
        try {
            let result = yield call(canCreateSVCall, action.leadUUID)
            yield put(action_setCanCreateSV(result.data.data));
        } catch (error) {
            console.log(error, 'error');
        }
    }
    yield all([
        takeLatest(SITE_VISIT_ACTIONS.CREATE_SITE_VISIT, createSiteVisitWatcher),
        takeLatest(SITE_VISIT_ACTIONS.GET_SITE_VISITS, getSiteVisitsWatcher),
        takeLatest(SITE_VISIT_ACTIONS.REMOVE_SITE_VISIT, removeSiteVisitWatcher),
        takeLatest(SITE_VISIT_ACTIONS.MARK_SITE_VISIT_COMPLETED, markCompletedWatcher),
        takeLatest(SITE_VISIT_ACTIONS.UPDATE_SITE_VISIT, updateSiteVisitWatcher),
        takeLatest(SITE_VISIT_ACTIONS.GET_CAN_CREATE, canCreateSVWatcher),
    ])
}
