import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as PropacityLogo } from '../../assets/propacityplush.svg';
import MobileInput from '../../components/InputGroup/MobileInput';
import { Link, useHistory } from 'react-router-dom';
import Fillinformation from './SignupForm';
import { toast } from 'react-toastify';
import { verifyOtpForLogin } from '../../services/public/public.service';
import { Oval } from 'react-loader-spinner';
import { fetchToken } from '../../firebase';




function OTP({ setComponentName, mobile, handleResendOTP }) {

    const [countDown, setCountDown] = useState(30);
    const [otpVal, setOTPVal] = useState([]);
    const [disableResend, setDisableResend] = useState(true);
    const [otpError, setOtpError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isTokenFound, setTokenFound] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let timer = setInterval(() => {
            if (countDown <= 0) {
                clearInterval(timer);
                setDisableResend(false);
            } else {
                setCountDown(countDown - 1)
            }
        }, 1000)

        return (() => clearInterval(timer))
    }, [countDown])

    const inputfocus = (e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
            let newVal = [...otpVal]
            newVal[e.target.tabIndex] = e.target.value
            setOTPVal(newVal)
            const previousElementSibling =
                e.target.previousElementSibling;

            if (previousElementSibling) {
                previousElementSibling.focus();
            }
        }
        else {
            const nextElementSibling = e.target.nextElementSibling;
            let newVal = [...otpVal]
            newVal[e.target.tabIndex] = e.target.value
            setOTPVal(newVal)
            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const res = await verifyOtpForLogin({ otp: otpVal.join(''), mobile })
            if (res.data.status === 200) {
                toast.success(res.data.message);
                if (res.data.data.login) {
                    localStorage.setItem('authToken', res.data.data.token)
                    fetchToken(setTokenFound);
                    setTimeout(() => {
                        // history.push('/learning-center');                        
                        window.location.reload();
                        setLoading(false);
                    }, 1000)
                }
                if (res.data.data.signup) {
                    setLoading(false);
                    setComponentName('signupform')
                }
                // if(res.data.data.login && res.data.data.is_subscribed) {
                //     localStorage.setItem('authToken', res.data.data.token)
                //     window.location.reload();
                // } else if(res.data.data.signup && !res.data.data.is_subscribed) {
                //     console.log('payment done')
                //     setComponentName('paymentplan')
                // } else {
                //     setComponentName('signupform')
                // }
            }
        } catch (err) {
            console.log({ err })
            setOtpError(true);
            setLoading(false);
        }
    }
    const inputRef = useRef(null);

    useEffect(() => {
        // Focus on the input element when the component mounts
        inputRef.current.focus();
    }, []); // Empty dependency array ensures this effect runs only once after the component mounts


    return (
        <>
            <div className='bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-14 pb-8 px-6 grow-0 shrink-0 w-11/12	shadow-shadowcard'>
                <div className='w-[300px] !table mx-auto'>
                    <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>
                    <div className='text-textblack font-semibold text-xl leading-8'>Confirm OTP</div>
                    <div className='mt-2.5 text-sm text-black800 leading-5'>Enter OTP sent to <span className='font-medium text-sm text-blue-900'>{mobile}</span>
                        <a onClick={() => setComponentName('login')} className='font-medium text-sm text-blue-900 ml-2.5'>Edit no.</a>
                    </div>
                    <div className='font-inter [&>*]:!border-0 !mt-6'>
                        <label className='text-sm font-medium text-black800 !mb-2'>One-Time Password<sup className='text-blue-900 hover:text-blue-900 !text-sm !top-0 font-medium'>*</sup></label>
                        <div className='flex !gap-2'>
                            <input
                                className='w-12 h-12 !border-b-2 border-[#344054] focus:!border-blue-900 outline-0 shadow-0 text-center focus:text-blue-900 text-textblack text-lg'
                                type="text"
                                maxLength="1" tabIndex="0" onKeyUp={e => inputfocus(e)}
                                ref={inputRef}
                            />
                            <input className='w-12 h-12 !border-b-2 border-[#344054] focus:!border-blue-900 outline-0 shadow-0 text-center focus:text-blue-900 text-textblack text-lg'
                                type="text" maxLength="1" tabIndex="1" onKeyUp={e => inputfocus(e)}
                            />
                            <input className='w-12 h-12 !border-b-2 border-[#344054] focus:!border-blue-900 outline-0 shadow-0 text-center focus:text-blue-900 text-textblack text-lg'
                                type="text" maxLength="1" tabIndex="2" onKeyUp={e => inputfocus(e)}
                            />
                            <input className='w-12 h-12 !border-b-2 border-[#344054] focus:!border-blue-900 outline-0 shadow-0 text-center focus:text-blue-900 text-textblack text-lg'
                                type="text" maxLength="1" tabIndex="3" onKeyUp={e => inputfocus(e)}
                            />
                        </div>
                        {otpError && <div className="input-feedback text-red fz14 ro mt-8 ml-2">Incorrect OTP, Please Retry.</div>}
                    </div>
                    <div className='!mt-6'>
                        <button
                            className='w-100 shadow-inset-blue gradient-blue text-white text-base flex justify-center font-medium !rounded-lg !py-2'
                            onKeyDown={(event) => {
                                console.log(event.key)
                                if (event.key === 'Enter') {
                                    handleSubmit()
                                }
                            }}
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ?
                                <Oval
                                    height={30}
                                    width={30}
                                    color="#FFFFFF"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#FFFFFF"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />
                                : 'Continue'}
                        </button>
                    </div>
                    <div className='text-center !mt-6'>
                        <a
                            className={`${disableResend ? 'text-black700 cursor-not-allowed pointer-events-none' : 'text-blue-900 '} font-medium leading-5`} onClick={() => {
                                setCountDown(30);
                                setDisableResend(true);
                                handleResendOTP();
                            }}>
                            Resend OTP {countDown > 0 ? `(${countDown}s)` : ''}
                        </a>
                    </div>

                    {/* <div className='mt-6 text-black800 text-xs leading-5'>
                        Already registered? <Link to='/login' className='!text-blue-900 hover:!text-text-blue-900 cursor-pointer font-semibold'>Log in</Link>
                    </div> */}
                </div>
            </div>

        </>

    )
}

export default OTP
