import React, { useEffect, useState } from 'react'
// import RightModal from '../RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Close } from '../../../assets/icons/closeGray.svg';
// import InputSelect from '../../InputGroup/InputSelect';

import TeamCard from '../../../modules/Settings/User&Teams/Teams/TeamCard';
import RightModal from '../../Modals/RightModal';
import InputSelect from '../../InputGroup/InputSelect';
import ProjectTeamCard from '../ProjectTeamCard';
import { toast } from 'react-toastify';
import { addToProject, getAllTeams } from '../../../services/public/public.service';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const AddTeamInProject = ({ show, closeModal, uuid, getTeams, projectTeams }) => {
    const [teams, setTeams] = useState([]);
    const [addedTeams, setAddedTeams] = useState([]);

    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const mapped = res.data.data?.map(t => ({ ...t, label: t.name, value: t.uuid }))
            setTeams(mapped);
        }
    }

    useEffect(() => {
        getAll();
    }, []);

    useEffect(() => {
        if (projectTeams.length) {
            setAddedTeams(projectTeams)
        }
    }, [projectTeams]);

    const handleSubmit = async () => {
        const payload = {
            project_uuid: uuid,
            team_ids: addedTeams.map(t => t.id)
        }
        const res = await addToProject(payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getTeams();
            closeModal();
        }

    }
    return (
        <RightModal show={show} closeModal={closeModal}>
            <div className='d-flex justify-content-between !px-5 !py-4'>
                <h1 className='text-black text-2xl inter m-0'>Add Teams</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='flex h-100 overflow-auto flex-column !px-5'>
                <div className='flex flex-col !gap-y-4'>
                    <InputSelect
                        label='Select Team'
                        placeholder='Select Team'
                        value={''}
                        onChange={(value) => {
                            const projectIndex = addedTeams.findIndex(t => t.name === value.name);
                            if (projectIndex === -1) {
                                setAddedTeams([...addedTeams, value]);
                            }
                        }}
                        styles={customStyles}
                        options={teams}
                    />

                </div>
                {addedTeams.map((team, index) => (
                    <div className='mt-2'>
                        <ProjectTeamCard key={`${team.uuid}_${index}`} team={team} />
                    </div>
                ))
                }
            </div>
            <div className={`d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow`}>
                <button className='outline-btn w-36 basis-36 shrink-0 grow-0  !text-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button
                    type='button'
                    disabled={addedTeams.length === 0}
                    onClick={handleSubmit}
                    className='pr-btn w-100 !text-lg'
                >
                    Confirm
                </button>
            </div>
        </RightModal>
    )
}

export default AddTeamInProject;
