import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { API_METHOD, API_URLS } from "../../../../config/apiUrl";
import requests, { handleError } from "../../../../helpers/requests";
import { action_getActivityStats, action_getLatestActivity, action_getLeadDetail } from "../../actions";
import { action_getTimeline } from "../Recent Activities/actions";
import { action_canCreateFollowup, action_getFollowups, action_setCanCreateFollowup, action_setFollowups, FOLLOWUP_ACTIONS } from "./actions";

// Create followup
function createFollowupCall(leadUUID, payload) {
    return requests(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.followups.insert}/${leadUUID}`, null, payload);
}

function* createFollowupWatcher(action) {
    try {
        yield call(createFollowupCall, action.leadUUID, action.payload);
        // get followups
        yield put(action_getFollowups(action.leadUUID));
        // Upate stats
        yield put(action_getActivityStats(action.leadUUID))

        // Get lead detail
        yield put(action_getLeadDetail(action.leadUUID))

        // Get latest
        yield put(action_getLatestActivity(action.leadUUID))

        // Get Timeline
        yield put(action_getTimeline(action.leadUUID))

        // Can create Followup
        yield put(action_canCreateFollowup(action.leadUUID))
    } catch (error) {
        console.log(error);
    }
}

// Get followups
function getFollowupCall(leadUUID) {
    return requests(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.followups.getAllByLeadUUID}/${leadUUID}`, null, null);
}

function* getFollowupWatcher(action) {
    try {
        let result = yield call(getFollowupCall, action.leadUUID)
        yield put(action_setFollowups(result.data.data));
    } catch (error) {
        console.log(error, 'error');
    }
}

// Can create followups
function canCreateFollowupCall(leadUUID) {
    return requests(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.followups.canCreateFollowup}/${leadUUID}`, null, null);
}

function* canCreateFollowupWatcher(action) {
    try {
        let result = yield call(canCreateFollowupCall, action.leadUUID)
        yield put(action_setCanCreateFollowup(result.data.data));
    } catch (error) {
        console.log(error, 'error');
    }
}

// Remove followup
function removeFollowupCall(followupUUID) {
    return requests(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.followups.removeFromLead}/${followupUUID}`, null, null);
}

function* removeFollowupWatcher(action) {
    try {
        let result = yield call(removeFollowupCall, action.followupUUID)
        yield put(action_getFollowups(action.leadUUID));

        // Upate stats
        yield put(action_getActivityStats(action.leadUUID))

        // Get lead detail
        yield put(action_getLeadDetail(action.leadUUID))

        // Get latest
        yield put(action_getLatestActivity(action.leadUUID))

        // Get Timeline
        yield put(action_getTimeline(action.leadUUID))

        // Can create Followup
        yield put(action_canCreateFollowup(action.leadUUID))
    } catch (error) {
        console.log(error, 'error');
    }
}

// Remove followup
function updateFollowupCall(followupUUID, payload) {
    return requests(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.followups.update}/${followupUUID}`, null, payload);
}

function* updateFollowupWatcher(action) {
    try {
        let result = yield call(updateFollowupCall, action.followupUUID, action.payload)
        yield put(action_getFollowups(action.leadUUID));

        // Get Timeline
        yield put(action_getTimeline(action.leadUUID))

        // Get Timeline
        yield put(action_getLatestActivity(action.leadUUID))


    } catch (error) {
        console.log(error, 'error');
    }
}

export function* followupSaga() {
    yield all([
        takeLatest(FOLLOWUP_ACTIONS.CREATE_FOLLOWUP, createFollowupWatcher),
        takeLatest(FOLLOWUP_ACTIONS.GET_FOLLOWUPS, getFollowupWatcher),
        takeLatest(FOLLOWUP_ACTIONS.REMOVE_FOLLOWUP, removeFollowupWatcher),
        takeLatest(FOLLOWUP_ACTIONS.UPDATE_FOLLOWUP, updateFollowupWatcher),
        takeLatest(FOLLOWUP_ACTIONS.GET_CAN_CREATE, canCreateFollowupWatcher),

    ])
}
