import React, { useState } from 'react'
import CountCard from './CountCard'
import CollectionsCard from './Cards/CollectionsCard'
import { ReactComponent as PlushIcon } from '../../assets/icons/PlusPrimary.svg';
import DemandCard from './Cards/DemandCard';
import AddDemand from './Modal/AddDemand';
import ViewNotes from './Modal/ViewNotes';
import AddCollection from './Modal/AddCollection';
import ReassignLeadModal from '../Modals/ReassignLeadModal';
import { deleteLeads, reassignLeads } from '../../services/private/leads.service';
import { toast } from 'react-toastify';
import ResentCollection from './Modal/ResentCollection';
import ConfirmDeletion from '../Modals/ConfirmDeletion';
import AddDebitTransaction from './Modal/AddDebitTransaction';

const getStatsCount = (value, stats) => {
    const findStat = stats.find(item => item.label === value);
    return findStat?.count || 0
}

const Demand = ({
    allDemand,
    overAllStats,
    demandsStats,
    setDemandStatus,
    getDemandDetails,
    selectedProject,
    allowedPermission,
    currentTab
}) => {

    const [show, setShow] = useState(false);
    const [showViewNotes, setShowViewNotes] = useState(false);
    const [selectedDemand, setSelectedDemand] = useState({});
    const [showAddCollection, setShowAddCollection] = useState(false);
    const [showReassignModal, setShowReassignModal] = useState(false);
    const [showDemand, setShowDemand] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDebitTransaction, setShowDebitTransaction] = useState(false);

    const handleReassignModalSubmit = (userDetails, remarks) => {
        reassignLeads([selectedDemand.lead_uuid], userDetails.value, remarks).then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned")
                // getLeadsData();
                setSelectedDemand('');
                setShowReassignModal(false)
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleUserDeletion = (selectedValue) => {
        deleteLeads(selectedValue).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message);
                getDemandDetails();
                setShowDeleteModal(false);
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    return (
        <>
            <div className='w-100 grid  sm:grid-cols-4 grid-cols-2	 sm:!gap-x-8 gap-x-4 sm:gap-y-0 gap-y-4 '>
                <div>
                    <CountCard
                        title={overAllStats?.totalDemandRaised?.total}
                        subtext='Demand Raised'
                    // icon
                    />
                </div>
                <div>
                    <CountCard
                        title={overAllStats?.totalCollectedAmount?.total}
                        subtext='Collected'
                    // icon
                    />
                </div>
                <div>
                    <CountCard
                        title={overAllStats?.totalDueAmount?.total}
                        subtext='Amount Due'
                        icon
                    />
                </div>
                <div>
                    <CountCard
                        title={overAllStats?.totalOverDueAmount?.total}
                        subtext='Overdue'
                        icon
                    />
                </div>

            </div>
            <div className='mt-7'>
                <div className='flex justify-between items-center'>
                    <nav>
                        <div className="nav nav-tabs border-0 !mb-2 gap-x-6 tabsactive" id="nav-tab" role="tablist">
                            <a
                                className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary active"
                                id="nav-All-tab"
                                data-toggle="tab"
                                href="#nav-All"
                                role="tab"
                                aria-controls="nav-All"
                                aria-selected="true"
                                onClick={() => setDemandStatus(null)}
                            >
                                All ({getStatsCount('all', demandsStats)})
                            </a>
                            <a
                                className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary"
                                id="nav-Approved-tab"
                                data-toggle="tab"
                                href="#nav-Approved"
                                role="tab"
                                aria-controls="nav-Approved"
                                aria-selected="false"
                                onClick={() => setDemandStatus('due')}
                            >
                                Due ({getStatsCount('due', demandsStats)})
                            </a>
                            <a
                                className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary"
                                id="nav-Pending-Approval-tab"
                                data-toggle="tab"
                                href="#nav-Pending-Approval"
                                role="tab"
                                aria-controls="nav-Pending-Approval"
                                aria-selected="false"
                                onClick={() => setDemandStatus('overdue')}
                            >
                                Overdue ({getStatsCount('overdue', demandsStats)})
                            </a>
                            <a
                                className="nav-item inter !text-base font-medium !text-textblack !pb-2 !px-0 !bg-transparent !pt-0 border-0 m-0 nav-link nav-link hover:!text-primary"
                                id="nav-Rejected-tab"
                                data-toggle="tab"
                                href="#nav-Rejected"
                                role="tab"
                                aria-controls="nav-Rejected"
                                aria-selected="false"
                                onClick={() => setDemandStatus('cleared')}
                            >
                                Cleared ({getStatsCount('cleared', demandsStats)})
                            </a>

                        </div>
                    </nav>
                    {/* {allowedPermission.includes('raise_demand') && <div>
                        <button
                            className='!border !border-primary text-sm rounded font-medium inline-flex gap-x-2 items-center !text-primary hover:bg-black100 !py-1.5 !px-2'
                            onClick={() => setShow(true)}>
                            <PlushIcon />Add New Demand
                        </button>
                    </div>} */}
                </div>
                <div className="tab-content !pb-6" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-All" role="tabpanel" aria-labelledby="nav-All-tab">
                        <div className='flex flex-col gap-y-5 !mt-5'>
                            {
                                allDemand.length > 0 ? allDemand.map((item, index) => (
                                    <DemandCard
                                        item={item}
                                        setShowViewNotes={setShowViewNotes}
                                        setSelectedDemand={setSelectedDemand}
                                        setShowAddCollection={setShowAddCollection}
                                        setShowReassignModal={setShowReassignModal}
                                        allowedPermission={allowedPermission}
                                        setShowDemand={setShowDemand}
                                        selectedProject={selectedProject}
                                        currentTab={currentTab}
                                        setShowDeleteModal={setShowDeleteModal}
                                        setShowDebitTransaction={setShowDebitTransaction}
                                    />
                                )) :
                                    <div className='flex flex-col justify-center items-center h-[45vh]'>
                                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                        <h2 className="po black fz16 fw-po-medium mb-8"> No Demands Found</h2>
                                        {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                    </div>
                            }
                            {/* <DemandCard
                                setShowViewNotes={setShowViewNotes}
                                setSelectedDemand={setSelectedDemand}
                            />
                            <DemandCard
                                setShowViewNotes={setShowViewNotes}
                                setSelectedDemand={setSelectedDemand}
                            /> */}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-Approved" role="tabpanel" aria-labelledby="nav-Approved-tab">
                        {
                            allDemand.length > 0 ? allDemand.map((item, index) => (
                                <DemandCard
                                    item={item}
                                    setShowViewNotes={setShowViewNotes}
                                    setSelectedDemand={setSelectedDemand}
                                    setShowAddCollection={setShowAddCollection}
                                    setShowReassignModal={setShowReassignModal}
                                    allowedPermission={allowedPermission}
                                    setShowDemand={setShowDemand}
                                    selectedProject={selectedProject}
                                    currentTab={currentTab}
                                    setShowDeleteModal={setShowDeleteModal}
                                    setShowDebitTransaction={setShowDebitTransaction}
                                />
                            )) :
                                <div className='flex flex-col justify-center items-center h-[45vh]'>
                                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8"> No Demands Found</h2>
                                    {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                </div>
                        }
                    </div>
                    <div className="tab-pane fade" id="nav-Pending-Approval" role="tabpanel" aria-labelledby="nav-Pending-Approval-tab">
                        {
                            allDemand.length > 0 ? allDemand.map((item, index) => (
                                <DemandCard
                                    item={item}
                                    setShowViewNotes={setShowViewNotes}
                                    setSelectedDemand={setSelectedDemand}
                                    setShowAddCollection={setShowAddCollection}
                                    setShowReassignModal={setShowReassignModal}
                                    allowedPermission={allowedPermission}
                                    setShowDemand={setShowDemand}
                                    selectedProject={selectedProject}
                                    currentTab={currentTab}
                                    setShowDeleteModal={setShowDeleteModal}
                                    setShowDebitTransaction={setShowDebitTransaction}
                                />
                            )) :
                                <div className='flex flex-col justify-center items-center h-[45vh]'>
                                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8"> No Demands Found</h2>
                                    {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                </div>
                        }
                    </div>
                    <div className="tab-pane fade" id="nav-Rejected" role="tabpanel" aria-labelledby="nav-Rejected-tab">
                        {
                            allDemand.length > 0 ? allDemand.map((item, index) => (
                                <DemandCard
                                    item={item}
                                    setShowViewNotes={setShowViewNotes}
                                    setSelectedDemand={setSelectedDemand}
                                    setShowAddCollection={setShowAddCollection}
                                    setShowReassignModal={setShowReassignModal}
                                    allowedPermission={allowedPermission}
                                    setShowDemand={setShowDemand}
                                    selectedProject={selectedProject}
                                    currentTab={currentTab}
                                    setShowDeleteModal={setShowDeleteModal}
                                />
                            )) :
                                <div className='flex flex-col justify-center items-center h-[45vh]'>
                                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8"> No Demands Found</h2>
                                    {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                                </div>
                        }
                    </div>

                </div>
            </div >
            {showDemand && <AddDemand show={showDemand} closeModal={() => setShowDemand(false)} item={selectedDemand} onSuccess={getDemandDetails} />}
            {showViewNotes && <ViewNotes show={showViewNotes} closeModal={() => setShowViewNotes(false)} item={selectedDemand} />}
            {showAddCollection && <AddCollection
                show={showAddCollection}
                closeModal={() => setShowAddCollection(false)}
                item={selectedDemand}
                onSuccess={getDemandDetails}
                selectedProject={selectedProject}
            />}
            {
                showReassignModal && <ReassignLeadModal
                    setShow={setShowReassignModal}
                    show={showReassignModal}
                    handleSubmit={(userId, remarks) => handleReassignModalSubmit(userId, remarks)}
                    count={1}
                    selectedLeadName={selectedDemand.customer_name}
                />
            }
            {showDemand && <ResentCollection
                show={showDemand}
                closeModal={() => setShowDemand(false)}
                item={selectedDemand}
                onSuccess={getDemandDetails}
            />}

            {showDeleteModal && selectedDemand.lead_uuid && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedDemand.customer_name}?`}
                deleteHandler={() => handleUserDeletion(selectedDemand.lead_uuid)}
                subText={`After removal, unit ${selectedDemand?.block}, unit ${selectedDemand?.block} will be unblocked.`}
            />}
            {showDebitTransaction &&
                <AddDebitTransaction
                    show={showDebitTransaction}
                    item={selectedDemand}
                    closeModal={() => {
                        setSelectedDemand({})
                        setShowDebitTransaction(false)
                    }}
                />
            }
        </>
    )
}

export default Demand
