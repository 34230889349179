import { FOLLOWUP_ACTIONS } from './actions';

const intialState = {
    followups: [],
    canCreate: true
}

export function followupReducer(state = intialState, action) {
    switch (action.type) {
        case FOLLOWUP_ACTIONS.SET_FOLLOWUPS:
            return { ...state, followups: action.followups };
        case FOLLOWUP_ACTIONS.SET_CAN_CREATE:
            return { ...state, canCreate: action.canCreate };
        default:
            return state;
    }

}