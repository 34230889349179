import { SITE_VISIT_ACTIONS } from './actions';

const intialState = {
    siteVisits: [],
    canCreateSV: true
}

export function svReducer(state = intialState, action) {
    switch (action.type) {
        case SITE_VISIT_ACTIONS.SET_SITE_VISITS:
            return { ...state, siteVisits: action.siteVisits }
        case SITE_VISIT_ACTIONS.SET_CAN_CREATE:
            return { ...state, canCreateSV: action.canCreate };
        default:
            return state;
    }

}