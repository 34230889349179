import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import pdfimg from '../../../assets/icons/pdfview.jpg';
import BasicModal from '../../Modals/BasicModal';
import { getCollectionDetails, updateCollectionStatus } from '../../../services/private/inventory.service';
import { toINRFormat } from '../../../helpers/helpers';
import { createDatewithTime } from '../../../helpers/date';
import { toast } from 'react-toastify';
import moment from 'moment';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

const getStatusClasses = (status) => {
    switch (status) {
        // case 'pending':
        //     return 'text-yellow-dark bg-yellow-100'
        // case 'rejected':
        //     return 'text-red bg-red-light'
        case 'approved':
            return 'text-green bg-green100'
        default:
            return 'text-yellow-dark bg-yellow-100'
    }
}

const ResentCollection = ({ show, closeModal, item, onSuccess }) => {

    const [details, setDetails] = useState({});
    const [showLightModal, setShowLightModal] = useState(false)
    const [selectdImg, setSelectdImg] = useState('');

    const getDetails = async () => {
        const res = await getCollectionDetails(item.collection_uuid);
        if (res.data.status === 200) {
            setDetails(res.data.data);
        }
    }

    useEffect(() => {
        if (item?.collection_uuid)
            getDetails()
    }, [item]);

    const handleApproveStatus = async () => {
        if (!item?.collection_uuid) {
            return;
        }

        const payload = {
            approval_status: 'approved'
        }
        const res = await updateCollectionStatus(item?.collection_uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            onSuccess();
            closeModal()
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-7 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>

                        <div>
                            <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-2 inter'>
                                Collection ({item?.customer_name}) {item?.unit_name}
                            </h1>
                            {/* <p className='m-0 inter text-sm text-black700'>List of all Collections pending for approval.</p> */}
                            {!details?.is_booking && <p className='m-0 inter text-sm text-black700'>
                                ₹ {toINRFormat(details?.total_amount_paid)}  Collected for Demand ID: #{details?.unique_id} with Due Amt: {details?.demand_total_amount_due ? `₹ ${toINRFormat(details?.demand_total_amount_due)}` : ''}
                            </p>}
                            {details?.is_booking && <p className='m-0 inter text-sm text-black700'>
                                ₹ {toINRFormat(details?.total_amount_paid)} Collected for Initiating Booking
                            </p>}
                        </div>
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <div className='!px-6 !pt-5 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <div>
                        <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Amount Breakup</h2>
                    </div>
                    <div className='px-8 mt-5 space-y-2'>

                        <div className='flex justify-between text-sm font-medium text-black700'>
                            <div>Paid Amount</div>
                            <div>+ ₹ {toINRFormat(details?.paid_amount || details?.amount || '0')}</div>
                        </div>
                        <div className='flex justify-between text-sm font-medium text-black700'>
                            <div>GST Paid</div>
                            <div>+ ₹ {toINRFormat(details?.gst_paid || '0')}</div>
                        </div>
                        <div className='flex justify-between text-sm font-medium text-black700'>
                            <div>Total</div>
                            <div> ₹ {toINRFormat(details?.amount || '0')}</div>
                        </div>
                    </div>
                    <div className='flex justify-between !border-t border-b !border-grayLight px-8 !mt-5 !py-4'>
                        <div>
                            <>
                                {details.milestoneInfo?.map(milestone => {
                                    return <div className='text-xs text-black700 font-medium'>Milestone {milestone.count} - {milestone.milestone_description}</div>

                                })}
                            </>
                            {/* text-[#FBBC05] bg-[#FBBC05]/10 */}
                            <div className={`${getStatusClasses(details?.approval_status)}  text-sm font-medium !px-2.5 !py-1 rounded capitalize w-min`}>{details?.approval_status || 'Pending'}</div>
                        </div>
                        <div className='flex flex-col gap-y-1 inter'>
                            <h5 className='text-textblack text-xl font-semibold m-0'>₹ {toINRFormat(details?.amount || '0')}</h5>
                            <p className='text-xs text-black700 font-medium m-0'>{details?.amount_received_date ? moment(details?.amount_received_date).format('Do MMM YYYY') : '-'}</p>
                        </div>
                    </div>

                    {details?.transaction?.documents.length > 0 && details?.transaction?.documents.map((item, index) => (
                        <div className='!mt-5'>
                            <div>
                                <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Payment Proof</h2>
                            </div>
                            <div className='!mt-5 grid grid-cols-3 gap-x-4'>
                                <div className='!border !border-grayLight rounded-lg h-[128px] relative group cursor-pointer'
                                    onClick={() => {
                                        if (!['video/mp4', 'application/pdf'].includes(item?.file_type)) {
                                            setSelectdImg(index)
                                            setShowLightModal(true)
                                        } else {
                                            window.open(item.url, '_blank')
                                        }

                                    }}
                                >
                                    <figure className='mb-0 !table !mx-auto relative group'>
                                        {['jpg', 'jpeg', 'png', 'webp'].includes(item.extension) ?
                                            <img src={item.url} alt="" className='h-[140px]' />
                                            : <img src={pdfimg} className='h-[140px]' />}
                                    </figure>
                                    <div className='absolute left-0 right-0 top-0 bottom-0 w-full h-full flex items-center justify-center !opacity-0 group-hover:!opacity-100'>
                                        {/* <button className='!border !border-grayLight text-sm text-textblack font-medium rounded-lg shadow-boxshadow !py-1 px-2.5'>
                                            View Photo
                                        </button> */}
                                    </div>
                                </div>
                                {/* <div className='!border !border-grayLight rounded-lg h-[128px] relative group'>
                                <div className='absolute left-0 right-0 top-0 bottom-0 w-full h-full flex items-center justify-center !opacity-0 group-hover:!opacity-100'><button className='!border !border-grayLight text-sm text-textblack font-medium rounded-lg shadow-boxshadow !py-1 px-2.5'>View Photo</button></div>
                            </div>
                            <div className='!border !border-grayLight rounded-lg h-[128px] relative group'>
                                <div className='absolute left-0 right-0 top-0 bottom-0 w-full h-full flex items-center justify-center !opacity-0 group-hover:!opacity-100'><button className='!border !border-grayLight text-sm text-textblack font-medium rounded-lg shadow-boxshadow !py-1 px-2.5'>View Photo</button></div>
                            </div> */}
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-end shadow-topshadow">
                    <button
                        className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter inline-flex items-center justify-center gap-x-2"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    {details?.approval_status !== 'approved' && <button
                        className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[186px] sm:basis-[186px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter"
                        onClick={handleApproveStatus}
                    >
                        Approve Collection
                    </button>}

                </div>
                {showLightModal && <Lightbox
                    open={showLightModal}
                    close={() => setShowLightModal(false)}
                    slides={details?.transaction?.documents.length > 0 && details?.transaction?.documents?.filter(item => (!['video/mp4', 'application/pdf'].includes(item?.file_type))).map(item => ({ src: item.url }))}
                    carousel={{
                        finite: true
                    }}
                    index={selectdImg}
                    plugins={[Thumbnails]}
                // video={{
                //     controls: true,
                //     playsInline: true,
                //     autoPlay: false,
                //     loop: false,
                //     muted: true,
                //     disablePictureInPicture: false,
                //     disableRemotePlayback: false,
                //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
                //     crossOrigin: '',
                //     preload: '',
                // }}
                />}
            </div>
        </BasicModal>
    )
}

export default ResentCollection
