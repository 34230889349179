import axios from "axios";
import AmplifySdk from '../helpers/amplify';
const Amplify = AmplifySdk();
// const API_ROOT = process.env.REACT_APP_NODE_ENV === 'production'? process.env.REACT_APP_PROD_API_URL: process.env.REACT_APP_DEV_API_URL;

export default async (method, url, qs = null, payload = null, headers = null) => {
    // let userInfo;
    // if (!url.includes('public')) {
    //     userInfo = await Amplify.Auth.currentUserPoolUser();
    // }
    let requestData = {
        method: method,
        url: url,
        headers: {
            Authorization: localStorage.getItem("authToken"),
            'ngrok-skip-browser-warning': true
        }
    }

    // if (userInfo) {
    //     requestData.headers = { 'Authorization': userInfo.signInUserSession.accessToken.jwtToken }
    // }

    if (payload) requestData.data = payload;
    if (headers) requestData.headers = { ...requestData.headers, ...headers }
    if (qs) requestData.params = qs

    const res = await axios(requestData)
        .then(res => res)
        .catch((err) => {
            console.log(err, 'error in request');
            if (err.response?.status === 401 && !window.location.href.split('/').includes('login')) {
                localStorage.clear();
                window.location.reload();
            } else {
                throw err;
            }
        });

    return res;
}

export function handleError(error) {
    // if axios error 
    if (error.response) {
        alert(error.response.data);
    }
}