import React, { useState } from 'react'
import CenterModal from '../../../components/Modals/center-modal'
import PaymentPlan from '../../Signup/PaymentPlan'
import Offers from '../../Signup/Offers'
import Coupons from '../../Signup/Coupons'

const PaymentPlanModal = ({ show, closeModal, paymentFrom, redirectTo }) => {
    const [componentName, setComponentName] = useState('paymentplan');
    const [selectedPlan, setSelectedPlan] = useState('');
    const [code, setCode] = useState('');
    const [selectedOffer, setSelectedOffer] = useState({});

    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!w-[560px]  [&>*]:!border-0 [&>*]:!rounded-xl [&>*]:!overflow-hidden'>
            {/* <PaymentPlan setComponentName={setComponentName} /> */}
            {componentName === 'paymentplan' &&
                <PaymentPlan
                    setComponentName={setComponentName}
                    page='inner'
                    setSelectedPlan={setSelectedPlan}
                    selectedPlan={selectedPlan}
                    closeModal={closeModal}
                />}
            {componentName === 'offers' &&
                <Offers
                    setComponentName={setComponentName}
                    page='inner'
                    selectedPlan={selectedPlan}
                    setCode={setCode}
                    code={code}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    paymentFrom={paymentFrom}
                    closeModal={closeModal}
                    redirectTo={redirectTo}
                />}
            {componentName === 'coupons' &&
                <Coupons
                    setComponentName={setComponentName}
                    page='inner'
                    selectedPlan={selectedPlan}
                    setCode={setCode}
                    code={code}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                />}
        </CenterModal>
    )
}

export default PaymentPlanModal;