import { combineReducers } from "redux";

import facebookIntegerationReducer from "../Integerations/Facebook Lead Gen/reducer";
import leadDetailReducer from "../Lead Details/reducer";
import { tagsReducer } from "../Lead Details/components/Tags/reducer";
import { notesReducer } from "../Lead Details/components/Create Note Sidebar/reducer";
import { followupReducer } from "../Lead Details/components/Create Followup Sidebar/reducer";
import { svReducer } from "../Lead Details/components/Create Site Visit Sidebar/reducer";
import { timelineReducer } from "../Lead Details/components/Recent Activities/reducer";


export const mainReducer = combineReducers({

   leadDetailReducer,
   tagsReducer,
   notesReducer,
   followupReducer,
   svReducer,
   timelineReducer,
   fbIntegeration: facebookIntegerationReducer,

});
