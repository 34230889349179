import React, { useEffect, useState } from 'react'
import CenterModal from '../../Modals/center-modal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { getYoutubeVideoId } from '../../../helpers/helpers';




const YoutubeModal = ({ show, closeModal, modalClass, style, handleUploadYoutubeVideo }) => {
    const [url, setUrl] = useState('');

    return (
        <CenterModal show={show} onHide={closeModal} modalClass={`${style.ytbmodal}`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    YouTube Upload</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className={`${style.ytbody} pt-30 px-24`}>
                <div className={`form-group position-relative`}>
                    <label>YouTube Video Link</label>
                    <input
                        className={'form-control text-input error'}
                        type={"text"}
                        placeholder='Paste Youtube Video link Here'
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}

                    />
                    {/* {errors?.name?.message && (
                        <div className="input-feedback mt-8">
                            {errors?.name?.message}
                        </div>
                    )} */}
                </div>

            </div>

            {getYoutubeVideoId(url) && <div className='mt-24 mb-24 px-24'>
                <iframe width="100%" height="300" src={`${getYoutubeVideoId(url)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>}

            <div className={`${style.ytfooter} pt-20 mb-24 px-24 border-top border-color d-flex align-items-center justify-content-center gap-14`}>
                <button onClick={closeModal} className='outline-btn'>Cancel</button>
                <button className='pr-btn' onClick={() => {
                    const file = {
                        file_name: '',
                        type: 'youtube_video',
                        url: getYoutubeVideoId(url),
                        media_name: ''
                    }
                    handleUploadYoutubeVideo(file);
                    closeModal();
                }}>Submit</button>
            </div>

        </CenterModal >
    )
}

export default YoutubeModal
