import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import SettingsSidebar from "./SettingsSidebar/SettingsSidebar";
import "./settings.css";
import "../CSV/csv.css";
// import '../../global.css';
import {
  settingsRoutes,
  settingsBaseUrl,
} from "../../routes/settings.routes";
import RenderComponent from "../../routes/RenderComponent";
import { getAuthRolePermissions } from "../../services/private/company.service";
import Consumer from '../../helpers/context';
import { checkIsSomeItemAvailableInList } from "../../helpers/helpers";

export default function SettingsRouter() {
  const [settingURLs, setSettingURLs] = useState(settingsRoutes);


  const settingRef = useRef();

  let { allowedPermissions, isRolesModule } = useContext(Consumer);

  const getUrls = () => {
    let urls = [...settingURLs]
    setSettingURLs([...urls])
  }

  useEffect(() => {
    getUrls();
  }, [])

  const handleMouseClick = (e) => {
    if (settingRef.current && !settingRef.current.contains(e.target)) {
      const el = document.getElementById("main-container");
      if (el.classList.contains("open-sidebar")) {
        el.classList.remove("open-sidebar");
        document.getElementById('hamburger_icon').style.display = 'block';
        document.getElementById('close_icon').style.display = 'none';
      }
    }
  };

  useEffect(() => {
    // getUsersAuthRolePermissions();
    document.addEventListener("mousedown", handleMouseClick);
    const el = document.getElementById("main-container");
    el.classList.add("user-settings");

    return () => {
      document.removeEventListener("mousedown", handleMouseClick);
      el.classList.remove("user-settings");
      if (el.classList.contains("open-sidebar")) {
        el.classList.remove("open-sidebar");
      }
    };
  }, []);

  const closeSideBar = useCallback(() => {
    const el = document.getElementById("main-container");
    el.classList.remove("open-sidebar");
    document.getElementById('hamburger_icon').style.display = 'block';
    document.getElementById('close_icon').style.display = 'none';
  }, []);

  return (
    <div
      className={`main-section position-relative d-flex p-0 overflow-hidden flex-row `}
    >
      
        <div className="setting-menu d-flex flex-column" ref={settingRef}>
          <div className="bg-white sticky top-0 z-10 !pl-4 !pb-4 !pt-5">
          <h2 className="text-lg font-medium !text-black m-0">
            Settings
          </h2>
          </div>
          {/* <div className="d-md-none d-flex align-items-center justify-content-between mb-18">
            <h2 className="fz18 fw-po-medium black mb-0 pb-0">Settings</h2>
          </div> */}
          {/* Sidebar */}
          <SettingsSidebar
            settingsRoutes={settingURLs}
            closeSideBar={closeSideBar}
          // importPermission={importPermission}
          />
        </div>
        <div className="w-full flex items-start justify-start flex-column position-relative">
          <RenderComponent routes={settingURLs} baseUrl={settingsBaseUrl} />
        </div>
      
    </div>
  );
}
