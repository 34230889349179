export const GET_LEAD_TAGS = 'GET_LEAD_TAGS';
export const GET_LEAD_TAGS_SUCCESS = 'GET_LEAD_TAGS_SUCCESS';

export const TAG_ACTIONS = {
    ADD_TAG: 'ADD_TAG',
    GET_TAGS: 'GET_TAGS',
    SET_TAGS: 'SET_TAGS',
    REMOVE_TAGS: 'REMOVE_TAGS'
}

export function action_addTag(leadUUID, name) {
    return {
        type: TAG_ACTIONS.ADD_TAG,
        leadUUID,
        name
    }
}

export function action_getTags(leadUUID) {
    return {
        type: TAG_ACTIONS.GET_TAGS,
        leadUUID
    };
}

export function action_setTags(tags) {
    return {
        type: TAG_ACTIONS.SET_TAGS,
        tags
    }
}

export function action_removeTags(leadUUID, tagId) {
    return {
        type: TAG_ACTIONS.REMOVE_TAGS,
        leadUUID,
        tagId
    }
}
