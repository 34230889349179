export const LEAD_PAGE_INIT = 'LEAD_PAGE_INIT';
export const LEAD_PAGE_INIT_SUCCESS='LEAD_INIT_SUCCESS';
export const LEAD_PAGE_INIT_ERROR='LEAD_INIT_SUCCESS';
export const LEAD_HIDE_SIDE_BAR = 'LEAD_HIDE_SIDE_BAR';
export const LEAD_SHOW_SIDE_BAR = 'LEAD_SHOW_SIDE_BAR';
export const LEAD_CREAT_NEW_REQUEST = 'LEAD_CREAT_NEW_REQUEST';
export const LEAD_CREAT_ERROR = 'LEAD_CREAT_ERROR';
export const LEAD_CREAT_SUCCESS = 'LEAD_CREAT_SUCCESS';
export const LEAD_CHANGE_STATUS = 'LEAD_CHANGE_STATUS';
export const LEAD_LOAD_MORE = 'LEAD_LOAD_MORE';
export const LEAD_LOAD_MORE_SUCCESS = 'LEAD_LOAD_MORE_SUCCESS';
export const LEAD_LOAD_MORE_STOP = 'LEAD_LOAD_MORE_STOP';
export const LEAD_TABLE_SORT = 'LEAD_TABLE_SORT';
export const LEAD_TABLE_ON_PAGE_OR_LIMIT_CHANGE='LEAD_TABLE_ON_PAGE_OR_LIMIT_CHANGE'
export const LEAD_GLOBAL_SEARCH = 'LEAD_GLOBAL_SEARCH';
export const LEAD_FILTER_ON_SEARCH='LEAD_FILTER_ON_SEARCH';
export const LEAD_FILTER_ON_SEARCH_SUCCESS='LEAD_FILTER_ON_SEARCH_SUCCESS';
export const LEAD_FILTERS_SHOW = "LEAD_FILTERS_SHOW";
export const LEAD_FILTERS_HIDE = "LEAD_FILTERS_HIDE";
export const LEAD_FILTER_ON_SEARCH_CLICK = 'LEAD_FILTER_ON_SEARCH_CLICK';
export const LEAD_FILTER_ON_SUBMIT = 'LEAD_FILTER_ON_SUBMIT';
export const LEAD_FILTER_ON_REMOVE = 'LEAD_FILTER_ON_REMOVE';






export function leadFilterShow() {
    return {
        type: LEAD_FILTERS_SHOW,
    };
}

export function leadFilterOnSubmit(action){
   
    return {
        type: LEAD_FILTER_ON_SUBMIT,
        filters : action.allFilters,
        uiFilters : action.uiFilters
    };

}


export function leadFilterOnRemove(action){
   
    return {
        type: LEAD_FILTER_ON_REMOVE,
        filters : action.filters,
        uiFilters : action.uiFilters
    };

}



export function leadFilterSearchClick() {
    return {
        type: LEAD_FILTER_ON_SEARCH_CLICK,
    };
}


export function leadFilterHide() {
    return {
        type: LEAD_FILTERS_HIDE,
    };
}


export function leadPageInIt() {
    return {
        type: LEAD_PAGE_INIT,
    };
}

export function leadLoadMoreStop() {
    return {
        type: LEAD_LOAD_MORE_STOP,
    };
}


export function leadLoadMoreSuccess(action){
    return {
        type: LEAD_LOAD_MORE_SUCCESS,
        action
    };
}

export function leadPageLoadMore(skip) {
    return {
        type: LEAD_LOAD_MORE,
        skip
    };
}

export function changeLeadStatus(action) {
    return {
        type: LEAD_CHANGE_STATUS,
        action
    };
}

export function leadPageSearchOnText(action) {
   
    return {
        type: LEAD_GLOBAL_SEARCH,
        action
    };
}


export function leadPaginationOrPageChange(action) {
  
    return {
        type: LEAD_TABLE_ON_PAGE_OR_LIMIT_CHANGE,
        limit  : action.limit,
        pageNumber : action.pageNumber,
      
    };
}

export function leadPageInItSuccess(action) {
  
    return {
        type: LEAD_PAGE_INIT_SUCCESS,
        rows : action.rows,
        columns : action.columns,
        totalcount : action.totalcount 
    };
}
//
export function leadPageSort(col,dir) {

    return {
        type: LEAD_TABLE_SORT,
        columns : col,
        direction : dir
    };
}


export function leadPageInItError(action) {
    return {
        type: LEAD_PAGE_INIT_ERROR,
        action
    };
}



export function leadPageHideSideBar() {
    return {
        type: LEAD_HIDE_SIDE_BAR,
    };
}


export function leadPageShowSideBar() {
    return {
        type: LEAD_SHOW_SIDE_BAR,
    };
}

export function leadPageCreateNewLead(action) {
    return {
        type: LEAD_CREAT_NEW_REQUEST,
        action
    };
}

export function leadPageCreateNewLeadSuccess(action) {
    return {
        type: LEAD_CREAT_SUCCESS,
        rows : action.rows,
        columns : action.columns,
        totalcount : action.totalcount 
    };
 
}


export function leadPageCreateNewLeadError(action) {
    return {
        type: LEAD_CREAT_ERROR,
        action
    };
}



export function leadFilterOnSearch(action) {

    return {
        
        type: LEAD_FILTER_ON_SEARCH,
        filtertype : action.filtertype,
        value  : action.value

    };
 
}


export function leadFilterOnSearchSuccess(action){
    let obj = { };
    obj[action.col] = action.result
    return {
         type: LEAD_FILTER_ON_SEARCH_SUCCESS,
         result : { ...obj }
        

    };
    
}

