import { TIMELINE_ACTIONS } from './actions';

const intialState = {
    timeline: []
}

export function timelineReducer(state = intialState, action) {
    switch (action.type) {
        case TIMELINE_ACTIONS.SET_TIMELINE:
            return { ...state, timeline: action.timeline };
        default:
            return state;
    }

}