import React from 'react'
import Certified from '../../../assets/cr.svg'

const WhatYouLearn = ({ style, learningSection, displayCertificate }) => {

    return (
        <div className={style.WhatYouLearn}>
            {learningSection?.length > 0 && <div className='p-20'>
                <h1 className='fz20 black inter fw-po-medium mb-16'>What you will learn</h1>
                <ul className='m-0 p-0'>
                    {learningSection.map((section, index) => {
                        return section && <li key={`${index}`}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path d="M16.0003 29.3336C23.3641 29.3336 29.3336 23.3641 29.3336 16.0003C29.3336 8.63651 23.3641 2.66699 16.0003 2.66699C8.63651 2.66699 2.66699 8.63651 2.66699 16.0003C2.66699 23.3641 8.63651 29.3336 16.0003 29.3336Z" fill="white" stroke="#D5D5DC" />
                            <path d="M10 16.6663L14.6667 19.9996L22 11.333" stroke="#3D39F5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>{section}
                        </li>
                    })
                    }

                </ul>
            </div>}
            {displayCertificate && <div className={`${style.get_certified} get_certified ${learningSection?.length === 0 ? 'onlyget_certified' : ''}`}>
                <h2 className='fz20 black inter fw-po-medium mb-16'>Get Certified by Propacity</h2>
                <div className="row">
                    <div className="col-md-7">
                        <img src={Certified} alt="" className='w-100' />
                    </div>
                    <div className="col-md-5 pl-0">
                        <p>You can share your certificate once you are done with your course.</p>
                    </div>
                </div>
            </div>}
        </div >
    )
}

export default WhatYouLearn
