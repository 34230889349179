import React from 'react'
import { ReactComponent as PlushIcon } from '../../assets/icons/whitePlush.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { uploadFile } from '../../services/public/public.service';
const MediaUpload = ({
    value,
    onChange,
    handleBlur,
    index,
    error,
    type,
    placeholder,
    name,
    label,
    max,
    disable,
    required,
    sizeText,
    inputclass,
}) => {

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            onChange(response.data.data.s3_url);
        }
    }

    return (
        <div key={index || 0} className={`${inputclass} ${error ? 'invalid' : ''} `}>
            {label &&
                <div className='flex justify-between !mb-2'>
                    <label className='!text-black800 m-0 text-sm '>{label}{required && <sup>*</sup>}</label>
                    <div className='!text-black800  text-sm '>{sizeText}</div>
                </div>
            }

            {!value && <div className='!border !border-black400 !rounded-lg h-[154px] relative group'>
                <div className='absolute left-0 right-0 top-0 bottom-0 w-full h-full flex items-center justify-center overflow-hidden'>
                    <input type="file" className='d-none' id='upload-file' accept="image/*" onChange={(e) => handleUpload(e)} />
                    <button
                        type='button'
                        className='gradient-blue shadow-inset-blue text-white rounded-lg text-sm font-medium inline-flex items-center justify-center gap-px !px-2.5 !py-1'
                        onClick={() => {
                            console.log('logo', document.getElementById('upload-file'))
                            document.getElementById('upload-file').click();
                        }}
                    >
                        <PlushIcon />Upload
                    </button>
                </div>
            </div>}
            {value && <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative group'>
                <input type="file" className='d-none' id={`replace-file`} accept="image/*" onChange={(e) => handleUpload(e)} />
                <img src={value} alt="" className='w-full h-full object-contain' />
                <div className='flex flex-row gap-x-3 items-center justify-center absolute left-0 right-0 top-0 bottom-0 w-full h-full bg-black/[.3] !opacity-0 group-hover:!opacity-100 '>
                    <button
                        type='button'
                        className='gradient-blue shadow-inset-blue text-white rounded-lg text-sm font-medium inline-flex items-center justify-center gap-px !px-2.5 !py-1'
                        onClick={() => {
                            document.getElementById('replace-file').click();
                        }}
                    >
                        <PlushIcon />Replace
                    </button>

                    <button
                        className='w-8 h-8 rounded bg-white grow-0 shrink-0 basis-8 inline-flex items-center justify-center'
                        onClick={() => {
                            onChange('')

                        }}
                    >
                        <TrashIcon />
                    </button>
                </div>
            </div>}
            {error && (

                <div className="input-feedback mt-8">{error}
                </div>


            )}

        </div>
    )
}

export default MediaUpload
