import React, { useEffect, useState, useRef, useContext } from 'react'
import DatePicker from 'react-datepicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import InputSelect from '../InputGroup/InputSelect';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as TrashblackIcon } from '../../assets/icons/trashblack.svg';
import { ReactComponent as EditBlackIcon } from '../../assets/icons/EditBlack.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/Down.svg';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/reraclose.svg';
import { ReactComponent as CheckblackIcon } from '../../assets/icons/checkblack.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as RupayIcon } from '../../assets/icons/rupay.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as PercentIcon } from '../../assets/icons/percent.svg';
import { ReactComponent as AnCloseIcon } from '../../assets/icons/closeicon.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { Controller, useForm } from 'react-hook-form';
import { gender, inventory_list, maintenance_payee, possession_type, preferred_tenant, project_area, property_age, tower_height } from '../../utils/project-constant';
import Dropdown from '../InputGroup/dropdown';
import { deleteProjectSpecifications, getProjectBasicDetailsByUUID, getProjectConfigurations, getProjectSpecifications, insertProjectBasicDetail, updateBasicDetailsByUUID, uploadFile } from '../../services/public/public.service';
import ProjectHeader from './project-header';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { basicDetailsvalidationSchema } from './validations/basicDetailsValidation';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Location from './Location';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
// import ConfigurationModal from '../../modules/Settings/Projects/Modal/ConfigurationModal';
import Configuration from './Configuration';
import AmenitiesModal from './Modal/AmenitiesModal';
import { toast } from 'react-toastify';
import AreYouSureModal from '../Modals/AreYouSureModal';
import TooltipText from '../Tooltip';
import SpecificationModal from './Modal/SpecificationModal';
import PopUpModal from '../Modals/PopUpModal';
import Consumer from '../../helpers/context';
import { checkIsSomeItemAvailableInList } from '../../helpers/helpers';

// Set your Google Maps API key here
const apiKey = 'AIzaSyDRuni-c1oJBFaF8ahTxlxPmYyCmRRtclw';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',

        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const textareastyle = {
    minHeight: 'unset!important',
    important: true

};

const BasicDetails = () => {

    let error = false;

    const [logo, setLogo] = useState('');
    const [logoFileError, setLogoFileError] = useState('');
    const [isLogoMissing, setIsLogoMissing] = useState(false);
    const [thumbnail, setThumbnail] = useState('');
    const [thumbnailFileError, setThumbnailFileError] = useState('');
    const [isThumbnailMissing, setIsThumbnailMissing] = useState(false);
    const [reraNo, setReraNo] = useState('');
    const [selectedReraNumber, setSelectedReraNumber] = useState('');
    const [reraIndex, setReraIndex] = useState('');
    const [showConfigModal, setShowConfigModal] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState({});
    const [configurations, setConfigurations] = useState([]);
    const [showAmenitiesModal, setShowAmenitiesModal] = useState(false);
    const [amenities, setAmenities] = useState([]);
    const [locationError, setLocationError] = useState('');
    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [specifications, setSpecifications] = useState([]);
    const [selectedSpecification, setSelectedSpecification] = useState('');
    const [showDeleteSpecification, setShowDeleteSpecification] = useState(false);

    const scrollableRef = useRef(null);
    const [isActive, setIsActive] = useState(false);

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    let { allowedPermissions } = useContext(Consumer);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            name: '',
            developer: localStorage.getItem('company'),
            property_type: '',
            possession_type: '',
            possession_date: '',
            rera: '',
            city: '',
            state: '',
            site_address: '',
            google_address: '',
            landmark: '',
            description: '',
            transaction_type: '',
            ownerName: '',
            ownerMobile: '',
            inventory_type: '',
            comment: '',
            age: '',
            age_unit: '',
            size: '',
            size_unit: {
                label: 'Sq.Feet',
                value: 'Sq.feet'
            },
            description: '',
            latitude: '',
            longitude: '',
            comment_is_private: false
        },
        resolver: yupResolver(basicDetailsvalidationSchema)
    });

    const reraNum = watch('rera');
    const transactionType = watch('transaction_type');
    const propertyType = watch('property_type');
    const inventory = watch('inventory_type');
    const googleAddress = watch('google_address');
    const city = watch('city');
    const latitude = watch('latitude');
    const longitude = watch('longitude');
    const state = watch('state');
    const name = watch('name');
    const possessionType = watch('possession_type');

    const getConfig = async () => {
        const res = await getProjectConfigurations(uuid);
        if (res.data.status === 200) {
            setConfigurations(res.data.data);
        }
    }

    const getBasicDetails = async () => {
        const res = await getProjectBasicDetailsByUUID(uuid)
        if (res.status === 200) {
            const data = res.data.data;

            reset({
                name: data.name || '',
                developer: data.developer || '',
                property_type: searchParams.get('pt') || data.property_type,
                transaction_type: searchParams.get('tt') || data.transaction_type,
                description: data.description || '',
                possession_date: data.possession_date ? new Date(data.possession_date) : '',
                possession_type: data.possession_type ? possession_type.find(item => item.value === data.possession_type) : '',
                rera: data.rera || '',
                inventory_type: data.inventory_type ? inventory_list.find(item => item.value === data.inventory_type) : '',
                comment: data.comment || '',
                city: data.city || '',
                state: data.state || '',
                google_address: data.google_address || '',
                age: data.age || '',
                age_unit: data.age_unit ? property_age.find(item => item.value === data.age_unit) : '',
                size: data.size || '',
                size_unit: data.size_unit ? project_area.find(item => item.value === data.size_unit) : '',
                landmark: data.landmark || '',
                latitude: data.latitude || '',
                longitude: data.longitude || '',
            },
                { keepDefaultValues: true }
            )
            setThumbnail(data.thumbnail);
            setLogo(data.logo);
            setAmenities(data.amenities || []);
            setConfigurations(data.configurations)
        }
    }

    const getSpecifications = async () => {
        const res = await getProjectSpecifications(uuid);
        if (res.data.status === 200) {
            setSpecifications(res.data.data);
        }
    }

    useEffect(() => {

        const handleScroll = () => {
            const scrollTop = scrollableRef.current.scrollTop;
            if (scrollTop >= 50) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        };

        scrollableRef.current.addEventListener('scroll', handleScroll);

        return () => {
            scrollableRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!isDirty) {
            localStorage.setItem('project_state', 'create')
        }
    }, [isDirty])

    useEffect(() => {
        if (uuid) {
            getBasicDetails();
            getSpecifications();
        }
    }, [uuid]);

    useEffect(() => {
        if (uuid && name)
            localStorage.setItem('projectName', name)
    }, [uuid, name])

    const handleSelectedAmnities = (amenities) => {
        setAmenities(amenities);
    }

    const handleChangeConfiguration = (configuration) => {
        setConfigurations(configuration)
    }

    const handleOnChangeThumbnail = async (e) => {
        const file = e.target.files[0];
        // if (file.size / 1048576 > 2) {
        //     setThumbnailFileError('File too large. Please upload an image under 2MB in size');
        //     return;
        // }
        // if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
        //     setThumbnailFileError('Wrong File format Uploaded');
        //     return;
        // }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setThumbnail(response.data.data.s3_url);
            setIsThumbnailMissing(false);
            setThumbnailFileError('');
        }
    }

    useEffect(() => {
        if (searchParams.get('pt') && searchParams.get('tt')) {
            setValue('property_type', searchParams.get('pt'));
            setValue('transaction_type', searchParams.get('tt'));
        }
    }, [searchParams.get('pt'), searchParams.get('tt')]);

    const handleOnChangeLogo = async (e) => {
        const file = e.target.files[0];

        // if (file.size / 1048576 > 2) {
        //     setLogoFileError('File too large. Please upload an image under 2MB in size');
        //     return;
        // }
        // if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
        //     setLogoFileError('Wrong File format Uploaded');
        //     return;
        // }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setLogo(response.data.data.s3_url);
            setIsLogoMissing(false);
            setLogoFileError('');
        }
    }

    const handleDeleteReraNumber = (index) => {
        const reraList = [...reraNum];
        // const filteredList = reraList.filter((item) => item != number)
        reraList.splice(index, 1);
        setValue('rera', reraList);
    }

    const handleUpdateLocation = (city, address, state, lat, long) => {
        setValue('city', city);
        setValue('google_address', address);
        setValue('state', state);
        setValue('latitude', lat);
        setValue('longitude', long)
    }

    const handleChangeRera = (index) => {
        if (selectedReraNumber) {
            const list = [...reraNum];
            list[index] = selectedReraNumber;
            setValue('rera', list);
            setSelectedReraNumber('');
            setReraIndex('');
        }
    }

    const handleDeleteSpecification = async () => {
        const res = await deleteProjectSpecifications(selectedSpecification.uuid);
        if (res.data.status = 200) {
            toast.success(res.data.message);
            getSpecifications();
        }
    }
    const onFormSubmit = async (values) => {
        if (values.property_type === 'null' && values.transaction_type === 'null') {
            toast.error('Please select property type')
            return
        }
        const payload = {
            ...(values.name) && { name: values.name },
            ...(values.developer) && { developer: values.developer },
            ...(values.property_type) && { property_type: values.property_type },
            ...(values.transaction_type) && { transaction_type: values.transaction_type },
            ...(values.description) && { description: values.description },
            ...(values.inventory_type) && { inventory_type: values.inventory_type.value || null },
            ...(values.comment) && { comment: values.comment || '' },
            ...(values.city) && { city: values.city || '' },
            ...(values.state) && { state: values.state || '' },
            ...(values.google_address) && { google_address: values.google_address || '' },
            ...(values.age) && { age: values.age || null },
            ...(values.age_unit) && { age_unit: values.age_unit.value || '' },
            ...(values.size) && { size: values.size || null },
            ...(values.size_unit) && { size_unit: values.size_unit.value || '' },
            ...(values.site_address) && { site_address: values.site_address || '' },
            ...(values.landmark) && { landmark: values.landmark || '' },
            ...(values.possession_date) && { possession_date: values.possession_date },
            ...(values.possession_type) && { possession_type: values.possession_type.value },
            ...(values.rera) && { rera: values.rera },
            ...(values.latitude) && { latitude: values.latitude },
            ...(values.longitude) && { longitude: values.longitude },
            amenities: amenities || [],
            configurations: configurations || [],
            thumbnail: thumbnail,
            logo: logo,
            specifications: specifications || []
        }

        if (uuid) {
            const res = await updateBasicDetailsByUUID(uuid, payload)
            if (res.data.status === 200) {
                toast.success(res.data.message);
                history.push(`/projects/create-projects/mediaupload/${uuid}?pt=${values.property_type}&tt=${values.transaction_type}`)
            }
        } else {
            const res = await insertProjectBasicDetail(payload)
            if (res.data.status === 200) {
                toast.success(res.data.message);
                history.push(`/projects/create-projects/mediaupload/${res.data.data.uuid}?pt=${values.property_type}&tt=${values.transaction_type}`)
            }
        }

    }

    return (
        <div className={`${style.creatproject}`} >
            <div className={`scrollable-div ${isActive ? 'active' : ''}`}>
                <ProjectHeader style={style} name={name} />
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} id='basic-details' className={`${style.overflowcontent} d-flex flex-fill flex-col overflow-auto mb-3 pl-24 border-top border-color pt-20`} ref={scrollableRef}>
                <div className='w-100'>
                    <div className={`${style.basic_form} basic_form`}>
                        <div className={`${style.col_span_2} border-color d-flex align-items-center justify-content-between border-bottom `}>
                            <h1 className="fz18 black fw-po-semi inter mb-2">Project Details</h1>
                        </div>
                    </div>
                    <div className={`${style.basic_form} basic_form mt-20`}>
                        <div className={`form-group ${errors?.name?.message ? 'invalid' : ''} position-relative`}>
                            <label>
                                Project/Property Name
                                {/* <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg'>

                                                <p className='text-capitalize'>Project/Property Name</p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <InfoIcon className='ml-1' />
                                </OverlayTrigger> */}
                                <sup> *</sup></label>
                            <input
                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                type={"text"}
                                placeholder='e.g. DLF Property'
                                {...register('name')}
                            />
                            {errors?.name?.message && (
                                <div className="input-feedback mt-8">
                                    {errors?.name?.message}
                                </div>
                            )}
                        </div>
                        <div className={`form-group ${errors?.developer?.message ? 'invalid' : ''} position-relative`}>
                            <label>Developer Name <sup>*</sup></label>
                            <input
                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                type={"text"}
                                placeholder='e.g. Omaxe'
                                {...register('developer')}
                            />
                            {errors?.developer?.message && (
                                <div className="input-feedback mt-8">
                                    {errors?.developer?.message}
                                </div>
                            )}
                        </div>

                        {transactionType === 'sale' && <div>
                            <Controller
                                control={control}
                                name='inventory_type'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <>
                                        <InputSelect
                                            label='Inventory Type'
                                            placeholder='Select Inventory Type'
                                            onChange={onChange}
                                            value={value}
                                            options={inventory_list}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            styles={customStyles}
                                        />
                                    </>
                                )}
                            />
                        </div>}

                        {transactionType === 'sale' &&
                            <div>
                                <Controller
                                    control={control}
                                    name='possession_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label='Possession Type'
                                                placeholder='Select Possesion Type'
                                                onChange={onChange}
                                                value={value}
                                                options={possession_type}
                                                // error={errors?.state?.message ? 'Please select State' : ''}
                                                styles={customStyles}
                                            />
                                        </>
                                    )}
                                />
                            </div>}

                        {transactionType === 'sale' && possessionType?.value === 'under_construction' && <div>
                            <Controller
                                control={control}
                                name='possession_date'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <div className='position-relative'>

                                        <label className=''>Possession Date</label>
                                        <DatePicker
                                            placeholderText='Select Possession Date'
                                            onChange={onChange}
                                            selected={value}
                                            dateFormat="dd MMM yyyy "
                                            className={`form-control text-input position-relative ${error ? 'error' : ''}`}
                                            showIcon
                                        // error={errors?.state?.message ? 'Please select State' : ''}
                                        />
                                        <CalendarIcon className={`${style.calicon}`} />
                                    </div>
                                )}
                            />
                        </div>}
                        {['rent', 'retail_lease', 'pre_lease'].includes(transactionType) && <div className='form-group position-relative'>
                            <label className='block text-black700 text-sm mb-2 font-medium'>Property Age </label>
                            <Controller
                                control={control}
                                name='age_unit'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <>
                                        <Dropdown
                                            list={property_age}
                                            onChange={onChange}
                                            value={value}
                                            style={style}
                                        />
                                    </>
                                )}
                            />
                            <input
                                type='number'
                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                placeholder='e.g. 10'
                                {...register('age')}
                            />
                        </div>}
                    </div>

                    {/* <div className={`${style.basic_form} basic_form mt-16`}>
                        <div className={`${style.col_span_2}`}>
                            <button type='button' onClick={() => setShowConfigModal(true)} className='pr-outline-btn d-block w-100'>+ Add Configuration</button>
                        </div>
                    </div> */}


                    {checkIsSomeItemAvailableInList(allowedPermissions, ['view_project_configuration', 'create_project_configuration']) && < div className='mt-16 max-w-[916px]'>
                        <Configuration
                            configurations={configurations}
                            handleChangeConfiguration={handleChangeConfiguration}
                            getConfig={getConfig}
                            inventory={inventory}
                            allowedPermissions={allowedPermissions}
                        />
                    </div>}

                    <div className={`${style.basic_form} basic_form pr-0 mt-16`}>
                        {/* Location fields */}
                        <div className={`${style.col_span_2} mt-20 mt-sm0`}>
                            <Location
                                googleAddress={googleAddress}
                                previousCity={city}
                                latitude={latitude}
                                longitude={longitude}
                                previousState={state}
                                handleUpdateLocation={handleUpdateLocation}
                                register={register}
                                errors={errors}
                            />
                        </div>
                    </div>

                    <div className={`${style.basic_form} basic_form`}>

                        {['sale'].includes(transactionType) &&
                            <div className={`${style.groupofinput} position-relative`}>
                                <div className='position-relative form-group'>
                                    <label className='block text-black700 text-sm mb-2 font-medium'>RERA No.</label>
                                    <input
                                        type='text'
                                        className={error ? 'form-control text-input rerainput error' : 'form-control text-input rerainput'}
                                        placeholder='Enter RERA Number'
                                        value={reraNo}
                                        onChange={(e) => setReraNo(e.target.value)}
                                    // {...register('reraNumbers', { required: false })}
                                    />
                                    {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                                    <button
                                        className={`${style.addrerabtn}  ${!reraNo ? style.disable : ''} pr-btn `}
                                        type='button'
                                        onClick={() => {
                                            if (reraNo) {
                                                setValue('rera', [...reraNum, reraNo])
                                                setReraNo('')
                                            }
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>

                                {reraNum.length > 0 && <>
                                    <div className='flex flex-col gap-y-2 h-[140px] overflow-y-auto'>
                                        {
                                            [...reraNum]?.map((item, index) => (
                                                <div
                                                    className={`${style.reramaingrp}  d-flex align-items-center justify-content-between gap-x-2 `}
                                                    key={`${item}_${index}`}
                                                >
                                                    <div className={`${style.raragrp} ${style.rerablock} d-flex flex-row align-items-center`}>
                                                        <span>{index + 1}.&nbsp;</span>

                                                        {reraIndex === index ? <div className='w-100 mr-1'>
                                                            <input className={`${style.rarainput}`}
                                                                type="text"
                                                                value={selectedReraNumber}
                                                                onChange={(e) => setSelectedReraNumber(e.target.value)}
                                                            />
                                                        </div> : <span>{item}</span>}
                                                    </div>
                                                    {reraIndex === index ? <div className={`${style.editdelgrp} ${style.delsave}`}>
                                                        <button
                                                            type='button'
                                                            onClick={() => handleChangeRera(index)}
                                                            className={`${style.editbtn}`}
                                                        >
                                                            <CheckblackIcon className='h-4 w-4' />
                                                        </button>
                                                        <button
                                                            type='button'
                                                            onClick={() => setReraIndex('')}
                                                            className={`${style.delbtn}`}
                                                        >
                                                            <CloseIcon />
                                                        </button>
                                                    </div> :
                                                        <div className={`${style.editdelgrp} `}>
                                                            {/* Edit rera number */}
                                                            <TooltipText title='Edit'>
                                                                <button
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setSelectedReraNumber(item);
                                                                        setReraIndex(index)
                                                                    }}
                                                                    className={`${style.editbtn}`}
                                                                >
                                                                    <EditBlackIcon className='h-4 w-4' />
                                                                </button>
                                                            </TooltipText>

                                                            {/* Delete rera number */}
                                                            <TooltipText title='Delete'>
                                                                <button
                                                                    type='button'
                                                                    onClick={() => handleDeleteReraNumber(index)}
                                                                    className={`${style.delbtn} ${style.reradel} inter`}
                                                                >
                                                                    <TrashblackIcon className='h-4 w-4' />
                                                                </button>
                                                            </TooltipText>


                                                        </div>}
                                                </div>
                                            ))
                                        }

                                    </div>

                                </>
                                }
                            </div>}

                        {transactionType === 'sale' &&
                            <div className='form-group position-relative'>
                                <label>Project Size (area)</label>
                                <Controller
                                    control={control}
                                    name='size_unit'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <Dropdown
                                                list={project_area}
                                                onChange={onChange}
                                                value={value}
                                                style={style}
                                            />
                                        </>
                                    )}
                                />

                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"number"}
                                    placeholder='e.g. 1500'
                                    {...register('size')}
                                />

                            </div>}

                    </div>
                    {amenities.length === 0 && <div className={`${style.basic_form} basic_form mt-20`}>
                        <div className={`${style.col_span_2}`}>
                            <button type='button' onClick={() => setShowAmenitiesModal(true)} className='pr-outline-btn d-block w-100'>+ Add Amenities</button>
                        </div>
                    </div>}
                    {amenities.length > 0 && <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                        <h1 className='fz18 black fw-po-semi inter mb-0'>Amenities</h1>
                        <button type='button' className='pr-btn inter' onClick={() => setShowAmenitiesModal(true)}><PlushIcon />Add</button>
                    </div>}

                    <div>
                        {amenities.length > 0 && <div className='d-flex flex-wrap mt-20 gap-y-14 gap-x-2 mb-4'>
                            {
                                amenities.map((item, index) => (
                                    <div
                                        className={`${style.animitestags}`}
                                        key={`${item}_${index}`}

                                    >
                                        {item} <button className={`${style.closetagbtn}`} type='button' onClick={() => {
                                            const filteredList = amenities.filter(amenity => amenity !== item);
                                            setAmenities(filteredList)
                                        }}  ><AnCloseIcon /></button>
                                    </div>
                                ))
                            }

                        </div>}

                    </div>
                    {
                        specifications.length === 0 &&
                        <div className={`${style.basic_form} basic_form mt-20`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button' onClick={() => setViewModal(true)} className='pr-outline-btn d-block w-100'>+ Add Specification</button>
                            </div>
                        </div>
                    }
                    {specifications.length > 0 && <div>
                        <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>Specification</h1>
                            <button type='button' className='pr-btn inter ' onClick={() => setViewModal(true)}><PlushIcon />Add</button>
                        </div>
                        <div className='!mt-5'>
                            <div className={`${style.basic_form}`}>
                                <div className={`${style.col_span_2}`}>
                                    <div className='def-table pr-0 h-100 pb-0'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Project Specification Name</th>
                                                    <th>Project Specification Type</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    specifications.map((specification, index) => (
                                                        <tr key={`${specification.type}_${index}`}>
                                                            <td>{index + 1}</td>
                                                            <td>{specification.type}</td>
                                                            <td>{specification.value}</td>

                                                            <td className='dropdown view-more'>
                                                                <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <ThreedotsIcon />
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    <a className="dropdown-item text-capitalize"
                                                                        onClick={() => {
                                                                            setSelectedSpecification(specification)
                                                                            setViewModal(true);
                                                                        }}
                                                                    >
                                                                        Edit Specification
                                                                    </a>

                                                                    <a
                                                                        className="dropdown-item del-menu-itme text-capitalize"
                                                                        onClick={() => {
                                                                            setSelectedSpecification(specification)
                                                                            setShowDeleteSpecification(true)
                                                                        }}
                                                                    >
                                                                        Delete Specification
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    }

                    <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                        <h1 className='fz18 black fw-po-semi inter mb-0'>Other Project Info</h1>
                    </div>


                    <div className={`${style.basic_form} basic_form mt-20`}>

                        <div className={`${style.col_span_2}`}>
                            <Controller
                                control={control}
                                name='description'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <div className='form-group'>
                                        <label>Project Description</label>
                                        <textarea label='Project Description'
                                            placeholder='Add short description'
                                            onChange={onChange}
                                            value={value} className={error ? 'form-control text-input error' : 'form-control text-input'} rows="4" style={textareastyle}></textarea>

                                    </div>
                                )}
                            />
                        </div>

                        <div className={`${style.col_span_2}`}>
                            <Controller
                                control={control}
                                name='comment'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <div className='form-group'>

                                        <div className='d-flex justify-content-between align-items-center mb-8'>
                                            <label className='mb-0'>Comments</label>
                                            {/* <div className='d-inline-flex align-items-center gap-8'>
                                                <input type="checkbox" {...register('comment_is_private')} style={{ width: '16px', height: '16px' }} />
                                                 <div className={`${style.privateinfo} border border-color radius-6 gap-4 d-inline-flex align-items-center`}>
                                                    <LockIcon />
                                                    <span>Private</span>
                                                    <OverlayTrigger
                                                        placement="left"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>                                                                    
                                                                    <p className=''>If checked, This field will only be visible to you.
                                                                    </p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <InfoIcon className='cursor-pointer' />
                                                    </OverlayTrigger>

                                                </div> 
                                            </div> */}
                                        </div>
                                        <textarea label='Remarks'
                                            placeholder='Add Remarks'
                                            onChange={onChange}
                                            value={value}
                                            className={error ? 'form-control text-input error' : 'form-control text-input'} rows="4" style={textareastyle}></textarea>
                                    </div>
                                )}
                            />

                        </div>
                    </div>

                </div>

            </form >
            <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                <button type='button' onClick={() => {
                    if (localStorage.getItem('project_state') === 'create') {
                        setRedirectURL('/projects');
                        setShowCloseConfirmationModal(true);
                        return
                    }
                    history.push('/projects')
                }} className={`${style.cancelbtn}`}><LeftIcon />Cancel </button>
                <button type='submit' form='basic-details' className={`${style.savebtn}`}>Save & Next</button>
            </div>

            {
                showAmenitiesModal &&
                <AmenitiesModal
                    show={showAmenitiesModal}
                    closeModal={() => setShowAmenitiesModal(false)}
                    style={style}
                    handleSelectedAmnities={handleSelectedAmnities}
                    amenities={amenities}
                />
            }
            {
                showCloseConfirmationModal &&
                <AreYouSureModal
                    show={showCloseConfirmationModal}
                    // title='Proceed Ahead?'
                    subText={'Do you want to cancel the project creation? Any unsaved changes you made may be lost.'}
                    closeModal={() => setShowCloseConfirmationModal(false)}
                    confirmClose={() => {
                        localStorage.removeItem('project_state')
                        history.push(redirectURL)
                        setShowCloseConfirmationModal(false);
                    }}
                />
            }

            {
                viewModal && <SpecificationModal
                    show={viewModal}
                    closeModal={() => setViewModal(false)}
                    style={style}
                    setSpecifications={setSpecifications}
                    selectedSpecification={selectedSpecification}
                    getSpecifications={getSpecifications}
                />
            }

            {
                showDeleteSpecification ? <PopUpModal
                    setShow={setShowDeleteSpecification}
                    show={showDeleteSpecification}
                    heading={<>
                        Delete {selectedSpecification.type}?
                    </>}
                    body={<div className=' p-3'>
                        <div className="mb-4">
                            Are you sure you want to delete project specification?
                        </div>
                        <div className="justify-content-end d-flex">
                            <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowDeleteSpecification(false)}>Cancel</button>
                            <button className="fw-po-medium del-btn px-16" onClick={handleDeleteSpecification}>Confirm Delete</button>

                        </div>
                    </div>}
                /> : null
            }
        </div >
    )
}


export default BasicDetails;
