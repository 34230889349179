import React, { useState } from 'react'
import { ReactComponent as HeaderMenu } from '../../../assets/icons/HeaderMenu.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/NoteIcon.svg';
import { ReactComponent as InfoRedIcon } from '../../../assets/icons/InfoRed.svg';
import { Link } from 'react-router-dom';
import ViewNotes from '../Modal/ViewNotes';
import { toINRFormat } from '../../../helpers/helpers';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DemandCard = ({
    item,
    setShowViewNotes,
    setSelectedDemand,
    setShowAddCollection,
    setShowReassignModal,
    allowedPermission,
    setShowDemand,
    selectedProject,
    currentTab,
    setShowDeleteModal,
    setShowDebitTransaction
}) => {

    return (
        <>
            <div className='border border-grayLight !p-4 flex flex-col shadow-boxshadow rounded-lg bg-white'>
                <div className='flex flex-row !border-b !border-grayLight !pb-3'>
                    <div className='w-4/12'>
                        <Link to={`/collection-manager/detail/${item?.booking_uuid}?project=${selectedProject?.label}&tab=${currentTab}`}
                            className='text-xl inter text-textblack mb-0 font-semibold !leading-7'>
                            <span className='text-black700 mr-1'>
                                #{item?.demand_unique_id}
                            </span>
                            {item?.customer_name}
                        </Link>
                        <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium !mt-2'>
                            <div>{item?.customer_mobile}</div>
                            <div className='w-2 h-2 rounded-full bg-black700'></div>
                            <div>{item?.unit_name} {item?.plot_area && `, ${item.plot_area} ${item.plot_area_unit}`} {item?.block && `, B-${item?.block}`}, {item?.floor_no && `, F-${item?.floor_no}`}</div>
                        </div>
                        <div className='!mt-2 flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium '>
                            {/* <div>D20392</div> */}
                            {/* <div className='w-2 h-2 rounded-full bg-black700'></div> */}
                            {item?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
                                <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                                    B
                                </span>{item?.channel_partner_name}
                            </div>}
                            {!item?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
                                <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                                    S
                                </span>{item?.assigned_user_name}
                            </div>}
                            <div className='w-2 h-2 rounded-full bg-black700'></div>
                            <div>{item?.payment_plan || '-'}</div>

                        </div>
                    </div>
                    <div className='w-8/12'>
                        <div className='flex gap-x-6 items-center justify-end'>
                            {/* {item?.approval_status === 'pending' && <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-[#FBBC05] font-semibold m-0 leading-6 text-right'>Pending</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>
                                    Status
                                </div>
                            </div>}
                            {item?.approval_status === 'approved' && <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-green font-semibold m-0 leading-6 text-right'>Approved</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                    Status
                                </div>
                            </div>}
                            {item?.approval_status === 'verified' && <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-green font-semibold m-0 leading-6 text-right'>Verified</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                    Status
                                </div>
                            </div>}
                            {item?.approval_status === 'rejected' && <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-red font-semibold m-0 leading-6 text-right inline-flex items-center gap-x-1'><InfoRedIcon />Rejected</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium justify-end'>

                                    Status
                                </div>
                            </div>} */}
                            {/* <div className='flex flex-col gap-y-2 text-right'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 uppercase'>₹ {toINRFormat(item?.gst_amount || '-')}</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                    <div>GST Amt.</div>

                                </div>
                            </div> */}
                            <div className='flex flex-col gap-y-2 text-right'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 uppercase'>{item?.milestone_no?.join(', ') || '-'}</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                    <div>Milestone</div>

                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2 text-right'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.due_date ? moment(item?.due_date).format('Do MMM YYYY') : '-'}</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                    <div>Due Date </div>

                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2 text-right'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>₹ {toINRFormat(item?.total_amount_due || '-')}</h3>
                                <div className='flex flex-row inter text-sm text-black700 gap-x-3 items-center font-medium'>
                                    <div>Demand Raised</div>
                                </div>
                            </div>
                            <div>
                                <div className="dropdown view-more ">
                                    <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <HeaderMenu />
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                        <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                        {/* <a className='dropdown-item'>Bulk Action</a> */}
                                        <a
                                            className='dropdown-item'
                                            onClick={() => {
                                                setSelectedDemand(item)
                                                setShowDebitTransaction(true)
                                            }}
                                        >Add Debit Trans.</a>
                                        {allowedPermission.includes('add_collection') && <a
                                            className='dropdown-item'
                                            onClick={() => {
                                                setSelectedDemand(item);
                                                setShowAddCollection(true);
                                            }}
                                        >Add Collections</a>}
                                        {/* <a className='dropdown-item'>View Customer Details</a> */}
                                        {/* <a className='dropdown-item'>Print Booking</a> */}
                                        {/* <a className='dropdown-item'>View/Add PDC</a> */}
                                        {allowedPermission.includes('edit_demand') && <a className='dropdown-item' onClick={() => {
                                            setSelectedDemand(item);
                                            setShowDemand(true);
                                        }}>Edit Demand</a>}
                                        {allowedPermission.includes('re_assign_booking_single') && <a
                                            className='dropdown-item'
                                            onClick={() => {
                                                setSelectedDemand(item)
                                                setShowReassignModal(true)
                                            }}
                                        >Re-assign Booking</a>}
                                        {allowedPermission.includes('delete_booking') && <a className="dropdown-item !text-red hover:!bg-red hover:!text-white"
                                            onClick={() => {
                                                setSelectedDemand(item);
                                                setShowDeleteModal(true);

                                            }}
                                        >Delete Booking</a>}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-x-3 !mt-3 inter'>
                    <div className='w-[140px] h-[104px] grow-0 shrink-0 basis-[140px] !border !border-grayLight shadow-boxshadow rounded-lg !py-2 !px-3 flex flex-col'>
                        <h2 className='flex items-center text-sm text-black700 font-medium leading-5 border-b border-grayLight !pb-2 m-0'><span className='w-4'><NoteIcon /></span> Note</h2>
                        <div className='flex w-100 h-100 justify-center items-center'>
                            <button className='text-textblack font-semibold text-base inter hover:!text-primary cursor-pointer'
                                onClick={() => {
                                    setShowViewNotes(true);
                                    setSelectedDemand(item)
                                }}
                            >View Note</button>
                        </div>
                    </div>
                    <div className='!border !border-grayLight shadow-boxshadow rounded-lg !py-2 !px-3 flex flex-col inter w-100'>
                        <div className='flex flex-row justify-between items-center !pb-2 border-b border-grayLight'>
                            <h6 className='text-sm font-medium leading-5 inter text-black700 m-0'>Demand Raise Details</h6>
                            <span
                                className='text-sm font-medium leading-5 inter !text-primary m-0 cursor-pointer'
                                onClick={() => {
                                    setSelectedDemand(item);
                                    setShowDemand(true);
                                }}
                            >View Details</span>

                        </div>
                        <div className='flex gap-x-14 !pt-2'>


                            <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>#{item?.demand_unique_id}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Demand ID
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_raised_date ? moment(item?.demand_raised_date).format('Do MMM YYYY') : '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Raise Date
                                </div>
                            </div>
                            {/* <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_amount ? `₹ ${toINRFormat(item?.demand_amount)}` : '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Demand Raised
                                </div>
                            </div> */}
                            {/* <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>23 Jun 23</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Raise Date
                                </div>
                            </div> */}
                            {/* <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.demand_milestone_no ? item?.demand_milestone_no?.join(', ') : '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Milestone
                                </div>
                            </div> */}
                            <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.due_amount ? `₹ ${toINRFormat(item?.due_amount)}` : '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Due Amt.
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.past_due ? `₹ ${toINRFormat(item?.past_due)}` : '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Past Due
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.interest_due ? `₹ ${toINRFormat(item?.interest_due)}` : '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Int. Due
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.total_amount_due ? `₹ ${toINRFormat(item?.total_amount_due)}` : '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Total Due Amt.
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <h3 className='inter text-base !text-black font-semibold m-0 leading-6 '>{item?.added_by || '-'}</h3>
                                <div className='inter text-xs text-black700 gap-x-3 items-center font-medium leading-4'>
                                    Added By
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default DemandCard
