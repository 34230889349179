
export const property_type = [
    {
        label: 'Residential',
        value: 'residential'
    },
    {
        label: 'Commercial',
        value: 'commercial'
    }
]

export const transaction_type = [
    {
        label: 'Rent',
        value: 'rent'
    },
    {
        label: 'Sale',
        value: 'sale'
    },
    {
        label: 'Retail-lease',
        value: 'retail-lease'
    },
    {
        label: 'Pre-lease',
        value: 'pre-lease'
    }
]

export const possession_type = [
    {
        label: 'Under Construction',
        value: 'under_construction'
    },
    {
        label: 'Ready to move in',
        value: 'ready_to_move_in'
    },
    // {
    //     label: 'In future',
    //     value: 'in_future'
    // }
]

export const project_area = [
    {
        label: 'Acres',
        value: 'acres'
    },
    {
        label: 'Sq.Feet',
        value: 'sq.feet'
    },
    {
        label: 'Sq.Yard',
        value: 'sq.yard'
    }
]

export const property_age = [
    {
        label: 'Month',
        value: 'month'
    },
    {
        label: 'Years',
        value: 'years'
    }
]

export const filter_property_age = [
    {
        label: '1-3 Months',
        value: '1-3 months'
    },
    {
        label: '6-12 Months',
        value: '6-12 months'
    },
    {
        label: '1-2 Years',
        value: '1-2 years'
    },
    {
        label: '3-5 Years',
        value: '3-5 years'
    },
    {
        label: '5-10 Years',
        value: '5-10 years'
    },
    {
        label: ' >10 Years',
        value: '>10 years'
    }
]

export const towerHeight = [
    {
        label: 'Feet',
        value: 'feet'
    },
    {
        label: 'Meters',
        value: 'meters'
    },
    {
        label: 'Floors',
        value: 'floors'
    }
]


export const unit_type = [
    {
        label: 'Builder Floor',
        value: 'builder_floor'
    },
    {
        label: 'Flat',
        value: 'flat'
    },
    {
        label: 'PG/Coliving',
        value: 'pg_coliving'
    },
    {
        label: 'Duplex',
        value: 'duplex'
    },
    {
        label: 'Villa',
        value: 'villa'
    },
    {
        label: 'Plot',
        value: 'plot'
    },
    {
        label: 'Farm House',
        value: 'farm_house'
    },
    {
        label: 'Row Housing',
        value: 'row_housing'
    },
    {
        label: 'Studio',
        value: 'studio'
    },
    {
        label: 'Apartment',
        value: 'apartment'
    }
]

export const carpet_area = [
    {
        label: 'Sq.Feet',
        value: 'sq.feet'
    },
    {
        label: 'Sq.Meters',
        value: 'sq.meters'
    }
]

export const plot_area = [
    {
        label: 'Sq.Feet',
        value: 'sq.feet'
    },
    {
        label: 'Sq.Yard',
        value: 'sq.yard'
    },
    {
        label: 'Sq.Meters',
        value: 'sq.meters'
    },
    {
        label: 'Acres',
        value: 'acres'
    },
    {
        label: 'Ground',
        value: 'ground'
    },
    {
        label: 'Cent',
        value: 'cent'
    },
    {
        label: 'Hectares',
        value: 'hectares'
    },
    {
        label: 'Biga',
        value: 'biga'
    }
]

export const usages_status = [
    {
        label: 'New',
        value: 'new'
    },
    {
        label: 'Used',
        value: 'used'
    }
]

export const facing = [
    {
        label: 'North',
        value: 'north'
    },
    {
        label: 'South',
        value: 'south'
    },
    {
        label: 'East',
        value: 'east'
    },
    {
        label: 'West',
        value: 'west'
    },
    {
        label: 'North-East',
        value: 'north_east'
    },
    {
        label: 'North-West',
        value: 'north_west'
    },
    {
        label: 'South-East',
        value: 'south_east'
    },
    {
        label: 'South-West',
        value: 'south_west'
    },

]

export const bhk_type = [
    {
        label: '1 RK',
        value: '1 RK'
    },
    {
        label: '1 BHK',
        value: '1 BHK'
    },
    {
        label: '2 BHK',
        value: '2 BHK'
    },
    {
        label: '3 BHK',
        value: '3 BHK'
    },
    {
        label: '4 BHK',
        value: '4 BHK'
    },
    {
        label: '4+ BHK',
        value: '4+ BHK'
    },

]

export const sharing = [
    {
        label: 'Private',
        value: 'private'
    },
    {
        label: 'Double',
        value: 'double'
    },
    {
        label: 'Triple',
        value: 'triple'
    },
    {
        label: '3+',
        value: '3+'
    }
]

export const preferred_tenant = [
    {
        label: 'Family',
        value: 'family'
    },
    {
        label: 'Corporate',
        value: 'corporate'
    },
    {
        label: 'Students',
        value: 'students'
    },
    {
        label: 'Government',
        value: 'government'
    },
    {
        label: 'Single(Male)',
        value: 'single-male'
    },
    {
        label: 'Single(Female)',
        value: 'single-female'
    }
]

export const maintenance_payee = [
    {
        label: 'Owner',
        value: 'owner'
    },
    {
        label: 'Tenant',
        value: 'tenant'
    }
]

export const gender = [
    {
        label: 'Male',
        value: 'male'
    },
    {
        label: 'Female',
        value: 'female'
    },
    {
        label: 'Other',
        value: 'other'
    }
]

export const commercial_unit = [
    {
        label: 'Coworking',
        value: 'coworking'
    },
    {
        label: 'Commercial Flat',
        value: 'commercial_flat'
    },
    {
        label: 'SCOs',
        value: 'sCOs'
    },
    {
        label: 'Retail Shop',
        value: 'retail_shop'
    },
    {
        label: 'Commercial Plot',
        value: 'commercial_plot'
    },
    {
        label: 'Virtual Space',
        value: 'virtual_space'
    },
    {
        label: 'Farm Land',
        value: 'farm_land'
    },
    {
        label: 'Showroom',
        value: 'showroom'
    },
    {
        label: 'Factory',
        value: 'factory'
    },
    {
        label: 'Cold Storage',
        value: 'cold_storage'
    },
    {
        label: 'Hospital',
        value: 'hospital'
    },
    {
        label: 'Building',
        value: 'building'
    },
    {
        label: 'Warehouse',
        value: 'warehouse'
    },
    {
        label: 'Cinema',
        value: 'cinema'
    },
    {
        label: 'Food Court',
        value: 'food_court'
    },
    {
        label: 'Hotel',
        value: 'hotel'
    },
    {
        label: 'Restaurant',
        value: 'restaurant'
    },
    {
        label: 'Industrial Plot',
        value: 'industrial_plot'
    },
    {
        label: 'Guest House',
        value: 'guest_house'
    },
]

export const furnished_unit = [
    {
        label: 'Fully Furnished',
        value: 'fully_furnished'
    },
    {
        label: 'Semi Furnished',
        value: 'semi_Furnished'
    },
    {
        label: 'Un-Furnished',
        value: 'un_furnished'
    }
]

export const payment_play = [
    {
        label: 'Down payment plan',
        value: 'down_payment_plan'
    },
    {
        label: 'Down payment plan',
        value: 'down_payment_plan'
    },
    {
        label: 'Time Linked Plan',
        value: 'time_linked_plan'
    },
    {
        label: 'Flexi Payment Plan',
        value: 'flexi_payment_plan'
    },
    {
        label: 'Possession_linked_plan',
        value: 'possession_linked_plan'
    },
    {
        label: 'Subvention plan',
        value: 'subvention_plan'
    }
]

export const tenant_background = [
    {
        label: 'Single',
        value: 'single'
    },
    {
        label: 'Family',
        value: 'family'
    },
    {
        label: 'Corporate',
        value: 'corporate'
    },
    {
        label: 'Student',
        value: 'student'
    }
]

export const inventory_list = [
    {
        label: 'New Launch',
        value: 'new_launch'
    },
    {
        label: 'Sustenance',
        value: 'sustenance'
    },
    {
        label: 'Resale',
        value: 'resale'
    }
]

export const heightMeasurment = [
    {
        label: 'Feet',
        value: 'feet'
    },
    {
        label: 'Meter',
        value: 'meter'
    }]

export const specificationsList = [
    {
        label: 'RCC Structure',
        value: 'RCC Structure'
    },
    {
        label: 'Brick Work',
        value: 'Brick Work'
    },
    {
        label: 'Plaster',
        value: 'Plaster'
    },
    {
        label: 'Flooring',
        value: 'Flooring'
    },
    {
        label: 'Door and Windows(External)',
        value: 'Door and Windows(External)'
    }
    , {
        label: 'Windows',
        value: 'Windows'
    },
    {
        label: 'Elevators',
        value: 'Elevators'
    },
    {
        label: 'Kitchen',
        value: 'Kitchen'
    },
    {
        label: 'Master Bedroom',
        value: 'Master Bedroom'
    },
    {
        label: 'Bathrooms',
        value: 'Bathrooms'
    },
    {
        label: 'Electrical Fixtures & Fittings',
        value: 'Electrical Fixtures & Fittings'
    },
    {
        label: 'Painting',
        value: 'Painting'
    },
    {
        label: 'Security',
        value: 'Security'
    },
    {
        label: 'Eco Friendly Features',
        value: 'Eco Friendly Features'
    },
    {
        label: 'Living Room',
        value: 'Living Room'
    },
    {
        label: 'Other Bedrooms',
        value: 'Other Bedrooms'
    },
    {
        label: 'Stilt Parking',
        value: 'Stilt Parking'
    },
    {
        label: 'Air Conditioning',
        value: 'Air Conditioning'
    },
    {
        label: 'Lifts',
        value: 'Lifts'
    },
    {
        label: 'Lobby',
        value: 'Lobby'
    },
    {
        label: 'Door and Windows(Internal)',
        value: 'Door and Windows(Internal)'
    },
    {
        label: 'Power',
        value: 'Power'
    },
    {
        label: 'Balconies',
        value: 'Balconies'
    },
    {
        label: 'Terrace',
        value: 'Terrace'
    },
    {
        label: 'Servant Room',
        value: 'Servant Room'
    },
    {
        label: 'Private Apartment Lift Lobby',
        value: 'Private Apartment Lift Lobby'
    },
    {
        label: 'Wardrobes',
        value: 'Wardrobes'
    },
    {
        label: 'Automation / Others',
        value: 'Automation / Others'
    },
    {
        label: 'Doors',
        value: 'Doors'
    },
    {
        label: 'Windows',
        value: 'Windows'
    },
    {
        label: 'Utility Room',
        value: 'Utility Room'
    },
    {
        label: 'Living / Dining Room / Foyer',
        value: 'Living / Dining Room / Foyer'
    },
    {
        label: 'Foyer',
        value: 'Foyer'
    },
    {
        label: 'Dining Room',
        value: 'Dining Room'
    },
    {
        label: 'Terrace & Gardens',
        value: 'Terrace & Gardens'
    },
    {
        label: 'Water Storage',
        value: 'Water Storage'
    },
    {
        label: 'Fire Fighting System',
        value: 'Fire Fighting System'
    },
    {
        label: 'Energy Conversation',
        value: 'Energy Conversation'
    },
    {
        label: 'Elevation & Entrance Lobbies',
        value: 'Elevation & Entrance Lobbies'
    },
    {
        label: 'Provisions',
        value: 'Provisions'
    },
    {
        label: 'Window / External Glazing',
        value: 'Window / External Glazing'
    },
    {
        label: 'Utility',
        value: 'Utility'
    },
    {
        label: 'Walls',
        value: 'Walls'
    },
    {
        label: 'Fittings & Fixtures',
        value: 'Fittings & Fixtures'
    },
    {
        label: 'Other Specifications',
        value: 'Other Specifications'
    }
]

export const AmenitiesType = [
    {
        label: 'Floor Rise',
        value: 'Floor Rise'
    },
    {
        label: 'Park Facing',
        value: 'Park Facing'
    },
    {
        label: 'Lake Facing',
        value: 'Lake Facing'
    },
    {
        label: 'Swimming Pool',
        value: 'Swimming Pool'
    },
    {
        label: 'Sea Facing',
        value: 'Sea Facing'
    },
    {
        label: 'Street Facing',
        value: 'Street Facing'
    },
    {
        label: 'Clubhouse Facing',
        value: 'Clubhouse Facing'
    },
    {
        label: '12m Road',
        value: '12m Road'
    },
    {
        label: '18m Road',
        value: '9m Road'
    },
    {
        label: 'Monument Facing',
        value: 'Monument Facing'
    },
    {
        label: 'Metro Facing',
        value: 'Metro Facing'
    },
    {
        label: 'Skyline',
        value: 'Skyline'
    },
    {
        label: 'Corner',
        value: 'Corner'
    },
]