import React, { useEffect, useState } from 'react';
import InputText from '../../../components/InputGroup/InputText';
import MediaUpload from '../../../components/InputGroup/MediaUpload';
import { getCities } from '../../../services/private/csv.service';
import MobileInput from '../../../components/InputGroup/MobileInput';
import AsyncSelect from 'react-select/async';
import { getNewBrokerDetails, intiateDeleteRequest, updateProfile } from '../../../services/public/public.service';
import PaymentPlanModal from '../Modal/PaymentPlanModal';
import moment from 'moment';
import { toast } from 'react-toastify';
import AppDownloadModal from '../../Signup/AppDownloadModal';
import DeleteModal from './Modal/DeleteModal';
import ConfirmDeleteModal from './Modal/ConfirmDeleteModal';
import DeleteSuccess from './Modal/DeleteSuccess';
import { ReactComponent as CrownIcon } from '../../../assets/crown.svg';

const PersonalDetails = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [city, setCity] = useState('');
    const [logo, setLogo] = useState('');
    const [landLine, setLandLine] = useState('');
    const [error, setError] = useState(false);
    const [referral, setReferral] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [subscription, setSubscription] = useState({});
    const [appDownloadModal, setAppDownloadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState('')

    const getPersonalData = async () => {
        const res = await getNewBrokerDetails();
        if (res.data.status === 200) {
            setName(res.data.data.name);
            setEmail(res.data.data.email);
            setCity(res.data.data.operational_cities ? res.data.data.operational_cities.map(c => ({ label: c, value: c })) : []);
            setCompanyName(res.data.data.company_name);
            setLogo(res.data.data.logo);
            setMobile(res.data.data.mobile);
            setReferral(res.data.data.referral || {});
            setIsSubscribed(res.data.data.is_subscribed);
            setSubscription(res.data.data.subscription);
            setSubscriptionType(res.data.data.subscription_type)
        }
    }

    useEffect(() => {
        getPersonalData();
    }, [])

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {

                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleSubmit = async () => {
        const payload = {
            name: name,
            // mobile: mobile,
            email: email,
            company_name: companyName,
            operational_cities: city.map(c => c.value),
            logo: logo,
            // landLine: landLine
        }

        const res = await updateProfile(payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
        }
    }

    const handleDelete = async () => {
        const res = await intiateDeleteRequest();
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setShowConfirmDeleteModal(false);
            setShowDeleteSuccessModal(true);
        }
    }

    return (
        <div className='w-100 flex flex-col h-full '>
            <div className='!py-6 !ml-5 !pr-6 max-w-100 !border-b !border-black400 flex justify-between sm:flex-row flex-col items-start sm:gap-0 !gap-4'>
                <div>
                    <h2 className='mb-1 text-black800 text-2xl font-medium leading-7'>Personal Details</h2>
                    {/* <p className='m-0 text-xs font-medium leading-4 text-black800'>Your Code : {referral?.code}</p> */}
                </div>

                {isSubscribed && subscriptionType.toLowerCase() !== 'freemium' && <div className='gradient-blue shadow-inset-blue text-whtie !p-5 sm:max-w-[344px] grow-0 shrink-0 sm:basis-[343px] w-full rounded-xl'>
                    <h3 className='text-lg font-bold leading-5 m-0 text-white'>Premium Subscription</h3>
                    <p className='text-sm m-0 text-white'>Valid till : {moment(subscription?.expiry_date || '').format('Do MMMM YYYY')}</p>
                </div>}
                {!isSubscribed && subscriptionType.toLowerCase() !== 'freemium' && <div
                    className='gradient-black shadow-inset-blue text-whtie !p-5 sm:max-w-[344px] grow-0 shrink-0 sm:basis-[343px] w-full rounded-xl cursor-pointer'
                    onClick={() => setShowPaymentModal(true)}
                >
                    <h3 className='text-lg font-bold leading-5 m-0 text-white'>Upgrade to Premium</h3>
                    <p className='text-sm m-0 text-white'>Premium gets you unlimited access</p>
                </div>}
            </div>

            <div className='!px-6 !py-5 h-full overflow-auto relative'>
                <div className='flex justify-between'>
                    <div className='max-w-[718px] w-[718px] grid sm:grid-cols-2 grid-cols-1 [&>*]:!m-0 !gap-x-8 gap-y-6'>
                        <InputText
                            label='Name'
                            placeholder='olivia@untitledui.com'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <InputText
                            label='Company Name (optional)'
                            placeholder='Enter company name'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                        <InputText
                            label='Mobile'
                            value={mobile}
                            // onChange={(e) => setMobile(e.phone)}
                            // error={(error && !mobile) ? 'Please enter mobile' : ''}
                            disable={true}
                        />
                        <div className={`form-group font-medium ${(error && city.length === 0) ? 'invalid' : ''}  position-relative`}>
                            <label htmlFor="">Operational City <sup>*</sup></label>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                placeholder='Search and Select'
                                isMulti={true}
                                value={city}
                                // styles={customStyles}
                                onChange={(e) => {
                                    setCity(e)
                                }}
                            />
                            {(error && city.length === 0) && (
                                <>
                                    <div className="input-feedback mt-8"> Please select operational city
                                    </div>
                                </>)}

                        </div>
                        <InputText
                            label='Email'
                            placeholder='e.g. olivia@untitled.com'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {/* <InputText
                        label='Landline'
                        placeholder='+91-9999999990'
                        value={landLine}
                        onChange={(e) => setLandLine(e.target.value)}
                    /> */}
                        <MediaUpload
                            label='Broker Logo'
                            sizeText='PNG, 2MB'
                            value={logo}
                            onChange={(url) => setLogo(url)}

                        />
                        {/* <div></div> */}
                        <div className=''>
                            <button className='gradient-blue shadow-inset-blue w-full h-12 inline-flex items-center justify-center text-white !rounded-lg font-semibold' onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                    {subscriptionType.toLowerCase() === 'freemium' && <div>
                        <div className='gradient-dark-grey text-whtie !p-5 sm:max-w-[344px] grow-0 shrink-0 sm:basis-[343px] w-full rounded-xl'>
                            <h3 className='text-lg font-bold leading-5 m-0 text-white'>Limited Access</h3>
                            <p className='!mt-3 text-xs text-white'>Unlock premium to get unlimited access.</p>
                            <p className='text-sm font-semibold text-white !mt-4'>Valid till : {moment(subscription?.expiry_date || '').format('Do MMMM YYYY')}</p>
                            <button
                                className='w-full shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2 flex justify-center items-center'
                                onClick={() => {
                                    setShowPaymentModal(true)
                                }}
                            >
                                Get   <span className='mx-1'>
                                    <CrownIcon />
                                </span>
                                Premium
                            </button>
                        </div>
                    </div>}
                </div>
                <div className='!py-6 !ml-5 !mt-16 !pr-6 max-w-100 !border-b !border-black400 flex justify-between sm:flex-row flex-col items-start sm:gap-0 !gap-4'>
                    <div>
                        <h2 className='mb-1 text-black800 text-2xl font-medium leading-7'>Delete Account</h2>
                        {/* <p className='m-0 text-xs font-medium leading-4 text-black800'>Your Code : {referral?.code}</p> */}
                    </div>
                </div>
                <div className='!ml-5 !mt-4'>
                    <button className='bg-red text-white rounded-lg py-2 !px-5' onClick={() => setShowDeleteModal(true)}>Delete Account</button>
                </div>
            </div>
            <div className='md:flex w-full hidden items-center justify-center md:flex-row flex-col gap-3 z-10 bg-textblack text-white !py-2'>
                <p className='m-0 text-sm font-medium '>Kickstart your Real Estate journey today!</p>
                <button onClick={() => setAppDownloadModal(true)} className='text-sm shadow-white-btn-shadow bg-whtie font-medium !rounded-lg !border !border-black400 bg-white !py-2 text-textblack px-2.5'>Download Mobile App Now</button>
            </div>
            {appDownloadModal && <AppDownloadModal show={appDownloadModal} closeModal={() => setAppDownloadModal(false)} />}
            {showPaymentModal && <PaymentPlanModal show={showPaymentModal} closeModal={() => setShowPaymentModal(false)} paymentFrom={'modal'} redirectTo={'/settings/my-profile'} />}
            {showDeleteModal && <DeleteModal show={showDeleteModal} closeModal={() => setShowDeleteModal(false)} handleSubmit={() => {
                setShowDeleteModal(false)
                setShowConfirmDeleteModal(true)
            }} />}
            {
                showConfirmDeleteModal && <ConfirmDeleteModal show={showConfirmDeleteModal} closeModal={() => setShowConfirmDeleteModal(false)} handleSubmit={handleDelete} />
            }
            {
                deleteSuccessModal && <DeleteSuccess show={deleteSuccessModal} closeModal={() => setShowDeleteSuccessModal(false)} />
            }
        </div>
    )
}

export default PersonalDetails