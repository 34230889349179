import React from 'react'
import CenterModal from '../../../components/Modals/center-modal';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg'

const SuccessBookingModal = ({ show, closeModal }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!max-w-[450px]  [&>*]:!border-0 [&>*]:!rounded-lg [&>*]:!overflow-hidden' backDropClassName={''}>
            <div className=' relative !p-5'>
                <div>
                    <span>
                        <SuccessIcon />
                    </span>
                    <button onClick={closeModal} className='absolute right-5 top-5'><CloseIcon /></button>
                </div>

                <div className='!mt-4'>
                    <h4 className='text-xl font-semibold m-0'>Call Scheduled! </h4>
                    <div className='text-black700 text-sm font-medium !mb-3'>Your call request is successful. Our representative will call you shortly.</div>
                    <div className='!mt-5'>
                        <button
                            className='w-full bg-primary text-white rounded-lg flex items-center justify-center !py-3'
                            onClick={closeModal}
                        >
                            Got it
                        </button>
                    </div>
                </div>
            </div>

        </CenterModal>
    )
}

export default SuccessBookingModal;