import React from 'react'
import CenterModal from '../../../components/Modals/center-modal'
import { ReactComponent as SucessIocn } from '../../../assets/sucess.svg';


const CouponAppliedModal = ({ show, closeModal, code, discount }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!w-[265px]  [&>*]:!border-0 [&>*]:!rounded-lg [&>*]:!overflow-hidden' backDropClassName={'!z-[1050] !opacity-20'}>
            <div className='!pt-4'>
                <figure className='!table mx-auto !mb-2'><SucessIocn /></figure>
                <h2 className='text-center text-textblack text-xl font-bold !mb-2 '>You saved ₹{discount}</h2>
                <p className='text-black700 text-sm font-medium leading-5 text-center !mb-3'>{code} is applied</p>
                <div className='!border-t !border-grayLight !py-3 flex justify-center'>
                    <button onClick={closeModal} className='border-0 text-blue-900 text-sm font-medium text-center'>Yay! got it</button>
                </div>
            </div>
        </CenterModal>
    )
}

export default CouponAppliedModal