import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/Down.svg';
import { propertyTypeEnum, transactionTypeEnum } from '../../helpers/filtersEnum';

const MultiLevelDropdown = ({ handleChangeProjectType, propertyType, transactionType, style, MenuClassname, childPosition }) => {


    const [show, setShow] = useState(false);
    const [showCom, setShowCom] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const dropdownRef = useRef();

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setShowAll(false);
            setShowCom(false);
            setShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, []);

    const handleChangeOptions = (property, transaction) => {
        setShowAll(false);
        setShowCom(false);
        setShow(false);
        handleChangeProjectType(property, transaction);
    }

    return (
        <div ref={dropdownRef} className={`${MenuClassname} position-relative d-inline-block`}
            onMouseEnter={() => {
                setShow(false);
                setShowCom(false);
                setShowAll(true);
            }}
        // onMouseLeave={() => {
        //     setShow(false);
        //     setShowCom(false);
        //     setShowAll(false);
        // }}
        >
            {!propertyType && !transactionType && <button
                // onMouseEnter={() => {
                //     setShow(false);
                //     setShowCom(false);
                //     setShowAll(!showAll);
                // }}
                // onMouseLeave={() => {
                //     setShow(false);
                //     setShowCom(false);
                //     setShowAll(!showAll);
                // }}
                className="pr-btn px-16 border-0 lh-20 flex items-center"
            >
                <PluswhiteIcon />
                New Project
            </button>}
            {
                propertyType && transactionType && <button
                    // onMouseEnter={() => {
                    //     setShow(false);
                    //     setShowCom(false);
                    //     setShowAll(!showAll);
                    // }}
                    // onMouseLeave={() => {
                    //     setShow(false);
                    //     setShowCom(false);
                    //     setShowAll(!showAll);
                    // }}
                    className={`${style.create_project_dropdownbtn} flex`}
                >
                    {(propertyTypeEnum[propertyType] && transactionTypeEnum[transactionType]) ? `${propertyTypeEnum[propertyType]} - ${transactionTypeEnum[transactionType]}` : 'Select Property Type'} <DownIcon />
                </button>
            }
            {
                showAll && <div className={`${style.mainMenu}`}>
                    <ul >
                        <li className='position-relative'>
                            <>
                                <button
                                    onMouseEnter={() => {
                                        setShow(true);
                                        setShowCom(false);
                                    }}
                                >
                                    <span>Residential</span> <DownIcon className={`${show ? 'rotateIcon' : ''}`} />
                                </button>
                                {show && <ul className={`${style.childmenu} ${childPosition === 'right' ? 'position_right' : 'position_left'}`} >
                                    <li
                                        onClick={() => handleChangeOptions('residential', 'sale')}
                                    >
                                        Sale
                                    </li>
                                    <li

                                        onClick={() => handleChangeOptions('residential', 'rent')}
                                    >
                                        Rent

                                    </li>
                                </ul>}
                            </>
                        </li>
                        <li className='relative'>
                            <>
                                <button
                                    onMouseEnter={() => {
                                        setShowCom(!showCom);
                                        setShow(false);
                                    }}
                                >
                                    <span>Commercial</span> <DownIcon className={`${showCom ? 'rotateIcon' : ''}`} />
                                </button>
                                {showCom && <ul className={`${style.childmenu} ${childPosition === 'right' ? 'position_right' : 'position_left'}`}>
                                    <li

                                        onClick={() => handleChangeOptions('commercial', 'sale')}
                                    >
                                        Sale

                                    </li>
                                    <li
                                        onClick={() => handleChangeOptions('commercial', 'rent')}
                                    >
                                        Rent
                                    </li>
                                    <li
                                        onClick={() => handleChangeOptions('commercial', 'retail_lease')}
                                    >
                                        Retail-Lease
                                    </li>
                                    <li
                                        onClick={() => handleChangeOptions('commercial', 'pre_lease')}
                                    >
                                        Pre-Lease
                                    </li>
                                </ul>}
                            </>
                        </li>
                    </ul>
                </div>
            }
        </div >
    )
}

export default MultiLevelDropdown