import React from 'react'
import { ReactComponent as PDFICon } from '../../../assets/FilePdf.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';

const Subscription = () => {
    return (
        <div className='w-100 flex flex-col h-full'>
            <div className='!pt-6 !pb-4 !ml-5 !pr-6 max-w-100 !border-b !border-black400 flex justify-between sm:flex-row flex-col items-start sm:gap-0 !gap-4'>
                <div>
                    <h2 className='mb-0 text-black800 text-2xl font-medium leading-7'>Subscription Invoices</h2>

                </div>



            </div>

            <div className='!px-6 !py-5 h-full overflow-auto w-full'>

                <div className='inline-flex items-start flex-col w-full'>
                    <div className='!border !border-black400 shadow-shadowxs !py-3 !px-4 sm:w-[344px] w-full rounded-xl inline-flex gap-1 flex-col items-start'>
                        <div><PDFICon /></div>
                        <span className='font-semibold text-base'>Purchase_Invoice.pdf </span>

                    </div>
                    <div className='sm:w-[344px] grow-0 shrink-0 sm:basis-[344px] flex !gap-4 !mt-6 sm:flex flex-col sm:!mx-0 !mx-auto'>
                        <button className='h-12 inline-flex items-center justify-center w-full gradient-blue shadow-inset-blue rounded-xl text-white !px-5 font-medium text-base whitespace-nowrap'>View invoice</button>
                        <button className='h-12 inline-flex items-center justify-center w-full rounded-xl !border !border-black400 text-textblack !px-5 whitespace-nowrap gap-x-1'><DownloadIcon />Download invoice</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Subscription