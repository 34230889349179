import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Countdown from 'react-countdown'
import { checkUserEnrolled, enrollUser } from '../../services/private/user.service'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Carousel from 'react-bootstrap/Carousel';



const EnrollmentCard = ({ workshop, uuid, createDuration, zoomLinkStatus, mentors }) => {
    let date = String(new Date(workshop.start_date)).split(" ")
    let now = new Date()
    let start_time = new Date(workshop.start_time).getTime()
    const [enrolled, setEnrolled] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedMentorImage, setSelectedMentorImage] = useState(0);

    const createStartTime = () => {
        const date = moment(workshop.start_date).format('YYYY-MM-DD');
        const time = moment(workshop.start_time).format('HH:MM');
        const startDate = moment(date + " " + time, 'YYYY-MM-DD HH:MM').toISOString();

        return new Date(startDate);
    }

    // for count down
    let startTime = createStartTime();

    const check = async () => {
        await checkUserEnrolled(uuid)
            .then((res) => {
                setEnrolled(res.data.data.enrolled)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        createStartTime();
        check();
    }, [show])


    useEffect(() => {
        const interval = setInterval(() => {
            if (mentors.length > 0) {
                if (selectedMentorImage >= mentors.length) {
                    setSelectedMentorImage(0);
                }
                else {
                    setSelectedMentorImage(selectedMentorImage + 1);
                }
            }
        }, 3000);
        return () => clearInterval(interval);
    }, []);


    const enrollUserToWorkshop = async (workshopUuid) => {
        await enrollUser(workshopUuid)
            .then((res) => {
                handleShow()
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (<>
        <div className="sticky-card">
            <div className="card-header">
                <div className="left">
                    <h3>{workshop.name}</h3>
                    <p className='mb-0'>{mentors && mentors.length > 0 && mentors.map((mentor, index) => {
                        return (<React.Fragment key={`${index}_${mentor.name}`}>
                            {mentor.name + ((mentors.length - 1) == index ? '' : ', ')}
                        </React.Fragment>)
                    })}</p>
                </div>
                <div className="right">
                    {mentors.length > 0 && <Carousel indicators={false} controls={false}>
                        {mentors && mentors.length > 0 && mentors.map((mentor, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={mentor.picture_url}
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>}
                    {/* <figure>
                        <img src={mentors && mentors.length && mentors[selectedMentorImage].picture_url} alt="Mantor Image" />
                    </figure> */}
                </div>
            </div>
            <div className="date">
                <div className="row">
                    <div className="col-md-7 col-7 pr-0">
                        <div className="d-des">
                            {moment(workshop.start_time).format('h:mm a') + " on " + moment(workshop.start_date).format("Do MMM YYYY")}
                        </div>
                    </div>
                    <div className="col-md-5 col-5">
                        <div className="d-des d-table ml-auto">{
                            createDuration
                        } </div>
                    </div>
                </div>
            </div>
            {
                <>
                    <div className="card-body">
                        <div className="">
                            {
                                // !enrolled ?
                                //     <button
                                //         type="button"
                                //         className='pr-btn py-12'
                                //         style={{ width: '100%' }}
                                //         data-toggle="modal"
                                //         data-target="#exampleModalCenter"
                                //         onClick={() => enrollUserToWorkshop(uuid)}
                                //         disabled={moment(startTime) < moment()}
                                //     >
                                //         Enroll Now
                                //     </button>
                                //     : startTime && (moment(startTime).diff(now, "minutes") <= 15)
                                //         ? <button onClick={() => { window.open(`${workshop && workshop.zoom_link}`) }} className='pr-btn py-12' style={{ width: '100%' }}>Zoom Link</button>

                                //         : <div className='hover-btn-elememtns'>
                                //             <button type="button" className='pr-btn py-12' style={{ width: '100%' }} disabled>Already Enrolled</button>
                                //             <div className='ele-hover'>
                                //                 <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                //                     <path d="M10.084 7.33398V11.9173C10.084 12.4236 10.4944 12.834 11.0007 12.834C11.5069 12.834 11.9173 12.4236 11.9173 11.9173V7.33398C11.9173 6.82772 11.5069 6.41732 11.0007 6.41732C10.4944 6.41732 10.084 6.82772 10.084 7.33398Z" fill="#92929D" />
                                //                     <path d="M11.9173 14.6673C11.9173 15.1736 11.5069 15.584 11.0007 15.584C10.4944 15.584 10.084 15.1736 10.084 14.6673C10.084 14.1611 10.4944 13.7507 11.0007 13.7507C11.5069 13.7507 11.9173 14.1611 11.9173 14.6673Z" fill="#92929D" />
                                //                     <path fillRule="evenodd" clipRule="evenodd" d="M20.1673 11.0007C20.1673 16.0633 16.0633 20.1673 11.0007 20.1673C5.93804 20.1673 1.83398 16.0633 1.83398 11.0007C1.83398 5.93804 5.93804 1.83398 11.0007 1.83398C16.0633 1.83398 20.1673 5.93804 20.1673 11.0007ZM3.66732 11.0007C3.66732 15.0507 6.95056 18.334 11.0007 18.334C15.0507 18.334 18.334 15.0507 18.334 11.0007C18.334 6.95056 15.0507 3.66732 11.0007 3.66732C6.95056 3.66732 3.66732 6.95056 3.66732 11.0007Z" fill="#92929D" />
                                //                 </svg></span>
                                //                 <p>Zoom link will be available and sent to you 2 hours before the workshop. </p>
                                //             </div>
                                //         </div>
                                workshop?.zoom_link && <button onClick={() => { window.open(`${workshop && workshop.zoom_link}`) }} className='pr-btn py-12' style={{ width: '100%' }}>Zoom Link</button>

                            }
                        </div>
                        <div className="line"></div>
                        <div className='d-flex align-items-center justify-content-around'>
                            {
                                start_time && startTime &&
                                <>
                                    {/* 
                                    start_time -> Only time component of workshop start data and time 
                                    startTime -> both date and time component of workshop start date and time
                                */}
                                    {
                                        // current time is less than workshop start date and time then start countdown timer
                                        moment(moment()).isBefore(startTime) ?
                                            <>
                                                <Countdown
                                                    date={startTime}
                                                    renderer={
                                                        ({ days, hours, minutes, seconds, completed }) => {
                                                            if (completed) {
                                                                return <>
                                                                    <div className='timer-box'>
                                                                        <span>00</span>
                                                                        <span>Days</span>
                                                                    </div>
                                                                    <div className='timer-box'>
                                                                        <span>00</span>
                                                                        <span>Hours</span>
                                                                    </div>
                                                                    <div className='timer-box'>
                                                                        <span>00</span>
                                                                        <span>Mins</span>
                                                                    </div>
                                                                    <div className='timer-box'>
                                                                        <span>00</span>
                                                                        <span>Sec</span>
                                                                    </div>
                                                                </>
                                                            } else {
                                                                // Timer running here
                                                                return <>
                                                                    <div className='timer-box'>
                                                                        <span>{days}</span>
                                                                        <span>Days</span>
                                                                    </div>
                                                                    <div className='timer-box'>
                                                                        <span>{hours}</span>
                                                                        <span>Hours</span>
                                                                    </div>
                                                                    <div className='timer-box'>
                                                                        <span>{minutes}</span>
                                                                        <span>Mins</span>
                                                                    </div>
                                                                    <div className='timer-box'>
                                                                        <span>{seconds}</span>
                                                                        <span>Sec</span>
                                                                    </div>
                                                                </>
                                                            }
                                                        }
                                                    }
                                                />
                                            </>
                                            :
                                            <>
                                                <div className='timer-box'>
                                                    <span>00</span>
                                                    <span>Days</span>
                                                </div>
                                                <div className='timer-box'>
                                                    <span>00</span>
                                                    <span>Hours</span>
                                                </div>
                                                <div className='timer-box'>
                                                    <span>00</span>
                                                    <span>Mins</span>
                                                </div>
                                                <div className='timer-box'>
                                                    <span>00</span>
                                                    <span>Sec</span>
                                                </div>
                                            </>
                                    }

                                </>
                            }
                        </div>
                    </div>
                </>
            }
            <div className="card-body">
                <ul>
                    {
                        workshop.detail_card_points && workshop.detail_card_points.map((point, index) => {
                            return <React.Fragment key={index}>
                                {
                                    point !== '' && point !== null && point !== undefined && <li >
                                        <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 28.8333C23.0877 28.8333 28.8333 23.0876 28.8333 16C28.8333 8.91235 23.0877 3.16669 16 3.16669C8.91241 3.16669 3.16675 8.91235 3.16675 16C3.16675 23.0876 8.91241 28.8333 16 28.8333Z" fill="#0062ff" stroke="#0062ff"></path>
                                            <path d="M10 16.6667L14.6667 20L22 11.3334" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                        {point}
                                    </li>
                                }
                            </React.Fragment>
                        })
                    }
                </ul>
            </div>
        </div>
        <Modal show={show} centered className='enrol-mdals'>
            <Modal.Body>
                <Button onClick={handleClose} className='d-table mx-auto close' >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725"></path></svg>
                </Button>
                <figure className='d-table mx-auto mb-12'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
                        <path d="M32.5 57.6666C46.6752 57.6666 58.1666 46.1753 58.1666 32C58.1666 17.8247 46.6752 6.33337 32.5 6.33337C18.3247 6.33337 6.83337 17.8247 6.83337 32C6.83337 46.1753 18.3247 57.6666 32.5 57.6666Z" fill="#1055F4" stroke="white" strokeWidth="2" />
                        <path d="M20.5 33.3333L29 40.2053L43.5 22.7053" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </figure>
                <h1 className='text-center d-table mx-auto black fz28 fw-po-medium mb-14'>Successfully enrolled </h1>
                <p className='text-center d-table mx-auto fz16 black-dark-700 mb-42'>Zoom link will be available and sent to you 2 hours before the workshop.</p>
                <a onClick={handleClose} className='d-table mx-auto pr-btn px-24 py-10 fw-po-mdeium text-white'>
                    Back to workshop
                </a>
            </Modal.Body>
        </Modal>

    </>
    )
}

export default EnrollmentCard;