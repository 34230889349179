import React, { useMemo } from 'react'

const ProgressBar = ({ totalDuration, watchedDuration }) => {


    const progressWidth = useMemo(() => {
        if (totalDuration > 1000 && watchedDuration > 1000) {
            // const total = totalDuration / 1000;
            // const watched = watchedDuration / 1000;            
            return parseInt((watchedDuration * 100) / totalDuration)
        }
    }, [watchedDuration, totalDuration]);

    // console.log(progressWidth, 'progress width')
    return (

        <div className='w-full h-2 bg-black400 rounded-full' >
            <div className='w-0 bg-primary h-full rounded-full' style={{ width: `${progressWidth}%` }}>

            </div>
        </div>

    )
}

export default ProgressBar