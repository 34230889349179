import React, {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { Switch, Router, useLocation, useParams } from "react-router-dom";
import AppContext from "../../helpers/context";
import { Redirect } from "react-router-dom";
import history from "../../config/history";
import PrivateRoute from "../../helpers/privateRoutes"; // Private Routes, Will only accessible after Login
import AuthRoute from "../../helpers/authRoutes"; // Auth Routes, Will only accessible before login.
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "../../components/SideBar/SideBar";
import Spinner from "../../components/Spinner/Spinner";
import LeadsManager from "../LeadsManager/LeadsManager";
import SettingsRouter from "../Settings/Settings.index";
import LeadDetailIndex from "../LeadDetails/LeadDetail.index";
import LearningCenter from "../LearningCenter/LearningCenter";
import CourseDetails from "../../components/learning-center/CourseDetails";
import WorkshopsDetails from "../WorkshopsDetails/WorkshopsDetails";
import PopUpModal from "../../components/Modals/PopUpModal";
import ShortsDetails from "../../components/short-details";
import Analytics from '../Analytics';

import ProjectLaunchpad from "../ProjectLaunchpad";
import CreateProjects from "../Settings/Projects/CreateProjects";
import BasicDetails from "../../components/Projects/BasicDetails";
import ProjectDetail from "../ProjectLaunchpad/ProjectDetail";
import Inventory from "../Inventory";
import CollectionManager from "../Collection-Manager";
import CollectionDetail from "../Collection-Manager/CollectionDetail";
import Projects from "../Settings/Projects/Projects";
import TeamInfo from "../../components/Projects/TeamInfo";
import PaymentPlan from "../../components/Projects/PaymentPlan";
import PricingModule from "../../components/Projects/PricingModule";
import AdditionalInfo from "../../components/Projects/AdditionalInfo";
import MediaUpload from "../../components/Projects/MediaUpload";
import Team from "../../components/Projects/Team";
import ConstructionUpdate from "../../components/Projects/ConstructionUpdate";
import Login from "../Login";
import Signup from "../Signup";
import NewProjectPage from "../Projects";
import { fetchToken, onMessageListener } from "../../firebase";
import Subscription from "../Subscription";
import PaymentSuccess from "../Settings/Payment Success";
import Dashboard from "../Dashboard";
import CheckForPayment from "../Signup/CheckForPayment";

// import Signup from "../Signup-Form/Signup";

const Lead = lazy(() => import("../Lead"));
// const forgotPassWord = lazy(() => import('../Forgot Password'));
const home = lazy(() => import("../Home"));
const leaddetails = lazy(() => import("../Lead Details"));
const csv = lazy(() => import("../CSV"));

const Erropage = lazy(() => import("../Erropage"));

// const Settings = lazy(() => import('../Settings/settings'));
const apiKey = "AIzaSyDRuni-c1oJBFaF8ahTxlxPmYyCmRRtclw";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";

function loadAsyncScript() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src: `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const routePathName = ['payment-success', 'signin', 'landing-page']
// Root routes
const AllRoutes = () => {

  const [showNetworkModal, setShowNetworkModal] = useState(false);
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);
  // const params = useParams();

  onMessageListener().then(payload => {
    // setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  console.log(notification, 'notification')
  let pathName = window.location.pathname

  const toggleSidebar = useCallback(() => {
    const el = document.getElementById("main-container");
    if (el.classList.contains("open-sidebar")) {
      el.classList.remove("open-sidebar");
      document.getElementById('hamburger_icon').style.display = 'block';
      document.getElementById('close_icon').style.display = 'none';
    } else {
      el.classList.add("open-sidebar");
      document.getElementById('hamburger_icon').style.display = 'none';
      document.getElementById('close_icon').style.display = 'block';
    }
  }, []);

  useEffect(() => {
    // messaging.requestPermission()
    //   .then(() => {
    //     return messaging.getToken();
    //   })
    //   .then(token => {
    //     console.log('Token:', token);
    //   })
    //   .catch(error => {
    //     console.error('Error:', error);
    //   });
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    loadAsyncScript();
    window.addEventListener("online", () => setShowNetworkModal(false));
    window.addEventListener("offline", () => setShowNetworkModal(true));

    return () => {
      window.removeEventListener("online", () => { });
      window.removeEventListener("offline", () => { });
    };
  }, []);

  const Routes = (props) => {
    // const { authorized } = useContext(AppContext)
    const authorized = (pathName.split('/')[1] !== 'landing-page' && localStorage.getItem("authToken")) ? true : false;
    return (
      <Router history={history}>
        {authorized ? (
          <NavBar {...props} toggleSidebar={toggleSidebar} />
        ) : (
          <></>
        )}
        <div className={` ${(routePathName.includes(pathName.split('/')[1])) ? 'h-full flex' : 'main-div show-loader h-full '}`} id="main-container">

          {authorized ? (
            <SideBar {...props} toggleSidebar={toggleSidebar} />
          ) : (
            <></>
          )}
          <Spinner />

          <Suspense fallback={<Spinner requesting={true}></Spinner>}>
            <Switch>
              <Redirect exact={true} from="/" to="signin" />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/activity"
                component={home}
              />
              {/* <AuthRoute
                exact={true}
                authorized={authorized}
                path="/login"
                component={Login}
              /> */}

              <AuthRoute
                exact={true}
                authorized={authorized}
                path="/subscription/:uuid"
                component={Subscription}
              />

              <AuthRoute
                exact={true}
                authorized={authorized}
                path="/signin"
                component={Signup}
              />
              <AuthRoute
                exact={true}
                authorized={authorized}
                path="/landing-page/:uuid"
                component={CheckForPayment}
              />
              <AuthRoute
                exact={true}
                authorized={authorized}
                path="/payment-success"
                component={PaymentSuccess}
              />

              {/* <PrivateRoute
                authorized={authorized}
                path="/leads/:id"
                component={LeadDetailIndex}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/leads"
                component={LeadsManager}
              /> */}
              {/* <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/leads-manager"
                component={Lead}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/csv"
                component={csv}
              /> */}
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/learning-center"
                component={LearningCenter}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/inventory"
                component={Inventory}
              />

              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/learning-center/course-detail/:id"
                component={CourseDetails}
              />

              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/collection-manager"
                component={CollectionManager}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/collection-manager/detail/:uuid"
                component={CollectionDetail}
              />

              {/* <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/project-launchpad"
                component={ProjectLaunchpad}
              /> */}
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/project-launchpad/details/:uuid"
                component={ProjectDetail}
              />


              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/workshops-details/:id"
                component={WorkshopsDetails}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/shorts-details/:id"
                component={ShortsDetails}
              />
              <PrivateRoute
                authorized={authorized}
                path="/settings/:section"
                component={SettingsRouter}
              />
              <PrivateRoute
                authorized={authorized}
                path="/leaddetail/:id"
                component={leaddetails}
              />
              {/* <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects"
                component={Projects}
              /> */}
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects"
                component={NewProjectPage}
              />

              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/basic-details"
                component={BasicDetails}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/basic-details/:uuid"
                component={BasicDetails}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/mediaupload/:uuid"
                component={MediaUpload}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/additional-info/:uuid"
                component={AdditionalInfo}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/payment-plan/:uuid"
                component={PaymentPlan}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/construction-updates/:uuid"
                component={ConstructionUpdate}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/pricing/:uuid"
                component={PricingModule}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/team-info/:uuid"
                component={TeamInfo}
              />
              <PrivateRoute
                exact={true}
                authorized={authorized}
                path="/projects/create-projects/team/:uuid"
                component={Team}
              />
              <AuthRoute
                authorized={authorized}
                path="/404"
                component={Erropage}
              />
              <Redirect from="*" to="/404" />
            </Switch>
          </Suspense>
        </div>
      </Router>
    );
  };
  return <Routes />;
};

// function mapStateToProps(state){
//             return state
// }

export default AllRoutes;
