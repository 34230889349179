import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { API_METHOD, API_URLS } from "../../../../config/apiUrl";
import requests, { handleError } from "../../../../helpers/requests";
import { action_getActivityStats } from "../../actions";
import { action_getTimeline } from "../Recent Activities/actions";
import { action_getNotes, action_setNotes, NOTES_ACTIONS } from "./actions";

// Create note
function createNoteCall(leadUUID, description) {
    return requests(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.notes.insert}/${leadUUID}`, null, { description });
}

function* createNoteWatcher(action) {
    try {
        yield call(createNoteCall, action.leadUUID, action.description);
        // get notes
        yield put(action_getNotes(action.leadUUID));
        // Upate stats
        yield put(action_getActivityStats(action.leadUUID))

        // Get Timeline
        yield put(action_getTimeline(action.leadUUID))
    } catch (error) {
        console.log(error);
    }
}

// Get notes
function getNotesCall(leadUUID) {
    return requests(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.notes.getAllByLeadUUID}/${leadUUID}`, null, null);
}

function* getNotesWatcher(action) {
    try {
        let result = yield call(getNotesCall, action.leadUUID)
        yield put(action_setNotes(result.data.data));
    } catch (error) {
        console.log(error, 'error');
    }
}

// Remove note
function removeNoteCall(noteUUID) {
    return requests(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.notes.removeFromLead}/${noteUUID}`, null, null);
}

function* removeNoteWatcher(action) {
    try {
        let result = yield call(removeNoteCall, action.noteUUID)

        // get notes
        yield put(action_getNotes(action.leadUUID));
        // Upate stats
        yield put(action_getActivityStats(action.leadUUID))

        // Get Timeline
        yield put(action_getTimeline(action.leadUUID))

    } catch (error) {
        console.log(error, 'error');
    }
}

// Remove note
function updateNoteCall(noteUUID, description) {
    return requests(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.notes.update}/${noteUUID}`, null, { description });
}

function* updateNoteWatcher(action) {
    try {
        let result = yield call(updateNoteCall, action.noteUUID, action.description)
        yield put(action_getNotes(action.leadUUID));

        // Get Timeline
        yield put(action_getTimeline(action.leadUUID))
    } catch (error) {
        console.log(error, 'error');
    }
}

export function* notesSaga() {
    yield all([
        takeLatest(NOTES_ACTIONS.CREATE_NOTE, createNoteWatcher),
        takeLatest(NOTES_ACTIONS.GET_NOTES, getNotesWatcher),
        takeLatest(NOTES_ACTIONS.REMOVE_NOTE, removeNoteWatcher),
        takeLatest(NOTES_ACTIONS.UPDATE_NOTE, updateNoteWatcher),
    ]);
}
