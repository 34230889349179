import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as PropacityLogo } from '../../assets/propacityplush.svg';
import backgroundImage from '../../assets/Background.png';
import MobileInput from '../../components/InputGroup/MobileInput';
import InputText from '../../components/InputGroup/InputText';
import { toast } from 'react-toastify';
import { checkForPaymentAPI, createWorkshopPaymentOrder, getWorkshopByUuid } from '../../services/public/public.service';
import { Oval } from 'react-loader-spinner';
import UserFoundModal from './Modal/UserFoundModa';
import { useParams, useHistory } from 'react-router-dom';
import useRazorpay from "react-razorpay";
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../assets/icons/plus_logo.svg'
import TooltipText from '../../components/Tooltip';
import Consumer from '../../helpers/context';
import PaymentPlanModal from '../Settings/Modal/PaymentPlanModal';

const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const CheckForPayment = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [showUserFoundModal, setShowUserFoundModal] = useState(false);
    const [workshopDetail, setWorkshopDetail] = useState({});
    const [mentors, setMentors] = useState([]);
    const [redirectURL, setRedirectURL] = useState('https://plus.propacity.in/');
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const { uuid } = useParams();
    const history = useHistory()

    const { borkerDetails } = useContext(Consumer);

    const [Razorpay] = useRazorpay();

    const workshopDetails = async () => {
        const res = await getWorkshopByUuid(uuid);
        if (res.data.status === 200) {
            setWorkshopDetail(res.data.data.workshop);
            setMentors(res.data.data?.mentors);
        }

    }

    useEffect(() => {

        if (Object.keys(borkerDetails).length > 0 && Object.keys(workshopDetail).length > 0) {
            if ((borkerDetails.subscription_type === 'premium' && borkerDetails.is_subscribed) ||
                borkerDetails.subscription?.workshops?.includes(workshopDetail.id)) {
                setShowUserFoundModal(true);
                setRedirectURL('/learning-center?tab=workshops')
            } else {
                setShowPaymentModal(true)
            }
        }
    }, [borkerDetails, workshopDetail])

    useEffect(() => {
        if (uuid) {
            workshopDetails()
        }
    }, [uuid]);

    const handleSubmit = async () => {

        if (!mobile) {
            setError(true);
            return;
        } else {
            setError(false);
        }

        if (!EmailRegex.test(email)) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false);
        }

        setLoading(true);
        const payload = {
            name: name,
            email: email,
            mobile: mobile,
        }
        try {
            const res = await createWorkshopPaymentOrder(uuid, payload);
            if (res.data.status === 200) {
                setLoading(false);
                const options = {
                    key: res.data.data.key,
                    // amount: totalAmount, // Amount in paise
                    currency: 'INR',
                    name: 'Propacity',
                    description: 'Test Transaction',
                    // image: 'https://your-company-logo.png',
                    order_id: res.data.data.order,
                    handler: (response) => {
                        if (response?.razorpay_payment_id) {
                            localStorage.setItem('freemium_user', true);
                            toast.success('Payment success.')
                            const link = document.createElement("a");
                            link.href = `https://plus.propacity.in`;
                            link.click();

                        }
                    },
                    prefill: {
                        name: name,
                        email: email,
                        contact: mobile
                    }
                };

                const razorpay = new Razorpay(options);
                razorpay.open();
            }
        } catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message)
        }
    }

    const handleCheckForUser = async () => {

        if (!mobile) {
            setError(true);
            return;
        } else {
            setError(false);
        }

        setLoading(true);
        const payload = {
            mobile: mobile,
            email: '',
            name: '',
        }

        try {
            const res = await checkForPaymentAPI(uuid, payload);
            if (res.data.status === 200) {
                if (!res.data?.data?.is_proceed) {
                    setShowUserFoundModal(true)
                } else {
                    setNewUser(true)
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message)
        }
    }

    return (
        <div className={`md:items-center relative items-start flex flex-fill justify-center overflow-y-auto overflow-x-hidden xl:!py-0`} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='absolute top-4 left-6'>
                <Logo />
            </div>
            <div className='bg-white rounded-xl md:w-max-[731px] md:basis-[731px] !p-6 grow-0 shrink-0 w-11/12 md:!mt-0 !mt-20 md:!mb-0 !mb-10 shadow-shadowcard '>
                <div className='grid grid-cols-1  sm:grid-cols-2 !gap-8 sm-!gap-6'>
                    <div className=''>
                        <div className='learning-block'>
                            <div className="card !border-0 d-flex flex-fill">
                                <figure className={`position-relative  rounded-lg mb-0`}>
                                    <img src={workshopDetail.card_thumbnail} className='rounded-lg' />
                                </figure>
                                {workshopDetail.name?.length >
                                    70 ?
                                    <OverlayTrigger className=''
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                                <div className='info-msg'>
                                                    {/* <h4>Total Leads</h4> */}
                                                    <p className='d-flex fz18 w-auto inter'>{workshopDetail.name}</p>
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <h1 className='fz16 black fw-po-medium mt-8 mb-0 inter'>{workshopDetail.name}</h1>
                                    </OverlayTrigger>
                                    :
                                    <h1 className='fz16 black fw-po-medium mt-8 mb-0 inter'>{workshopDetail.name}</h1>}

                                {/* <OverlayTrigger className=''
                                    placement="auto"
                                    overlay={
                                        <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                            <div className='info-msg'>
                                                <p className='d-flex fz18 w-auto inter'>By {mentors.map(i => i.name).join(', ')}</p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                </OverlayTrigger> */}
                                <TooltipText title={mentors.map(i => i.name).join(', ')}>
                                    <p className='fz14 black-dark-700 worksans mb-0 mt-8 mentor inter'>{mentors.map(i => i.name).join(', ')}</p>
                                </TooltipText>


                                <div className="video-time-date mt-8 d-flex align-items-start inter fw-po-medium text-sm black">
                                    <>
                                        <div className='flex items-center justify-center'>
                                            {moment(workshopDetail.start_time).format("h:mm a")}
                                            <span className='!mt-0'></span>
                                            {moment(workshopDetail.start_date).format(" Do MMM YYYY")}
                                        </div>
                                    </>
                                </div>
                                <div className='!mt-5 border rounded-lg border-grey-light w-full p-2 bg-opacity-20 bg-grey-light'>
                                    <div className='flex justify-between items-center font-semibold'>
                                        <span>Price</span>
                                        <span>₹ {workshopDetail?.amount}</span>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    <div className=''>
                        <label className='text-textblack font-semibold text-lg m-0'>Continue Purchase</label>
                        {!newUser && <div className='!space-y-5 [&>*]:!m-0 !mt-6 w-full'>
                            <label className='text-sm font-medium text-black800 !mb-2'> Enter Mobile<sup className='text-blue-900 !text-sm !top-0 font-medium'>*</sup></label>
                            <MobileInput
                                // label='Mobile'
                                value={mobile}
                                onChange={(e) => setMobile(e.phone)}
                                error={(error && !mobile) ? 'Please enter mobile' : ''}
                                inputclass='!h-10 !w-full'
                                required={true}
                            />

                            <div className='!mt-6'>
                                {!loading && <button
                                    type='button'
                                    onClick={handleCheckForUser}
                                    disabled={loading}
                                    className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2 !mt-1 inline-flex items-center gap-x-2.5 justify-center'
                                >
                                    Buy Now ₹ {workshopDetail?.amount}
                                </button>}
                                {
                                    loading && <button
                                        disabled={true}
                                        className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2 !mt-1 inline-flex items-center gap-x-2.5 justify-center'
                                    >
                                        <Oval
                                            height={30}
                                            width={30}
                                            color="#FFFFFF"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#FFFFFF"
                                            strokeWidth={4}
                                            strokeWidthSecondary={4}
                                        />
                                    </button>
                                }
                            </div>
                        </div>}
                        {newUser && <div className='!space-y-5 [&>*]:!m-0 !mt-6'>
                            <InputText
                                label='Name'
                                placeholder='Rahul'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required={true}
                                error={(error && !name) ? 'Please enter name' : ''}
                            />

                            <InputText
                                label='Email'
                                placeholder='olivia@untitledui.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required={true}
                                error={(error && !email) ? 'Please enter email' : emailError ? 'Please enter a valid email.' : ''}
                            />
                            <MobileInput
                                label='Mobile'
                                value={mobile}
                                onChange={(e) => setMobile(e.phone)}
                                error={(error && !mobile) ? 'Please enter mobile' : ''}
                                inputclass='!h-10 !w-full'
                                required={true}
                                disable={true}
                            />
                            <div className='!mt-6'>
                                {!loading && <button
                                    type='button'
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2 !mt-1 inline-flex items-center gap-x-2.5 justify-center'
                                >
                                    Buy Now ₹ {workshopDetail?.amount}
                                </button>}
                                {
                                    loading && <button
                                        disabled={true}
                                        className='w-100 shadow-inset-blue gradient-blue text-white text-base font-medium !rounded-lg !py-2 !mt-1 inline-flex items-center gap-x-2.5 justify-center'
                                    >
                                        <Oval
                                            height={30}
                                            width={30}
                                            color="#FFFFFF"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#FFFFFF"
                                            strokeWidth={4}
                                            strokeWidthSecondary={4}
                                        />
                                    </button>
                                }
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {showUserFoundModal && <UserFoundModal
                show={showUserFoundModal}
                closeModal={() => setShowUserFoundModal(false)}
                redirectURL={redirectURL}
            />}
            {showPaymentModal && <PaymentPlanModal
                show={showPaymentModal}
                closeModal={() => setShowPaymentModal(false)}
                paymentFrom={'modal'}
                redirectTo={'/learning-center?tab=workshops'}
            />}
        </div>
    )
}

export default CheckForPayment
