import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';

export async function uploadCSV(formData) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.csv.uploadCSV}`, null, formData, null)
}

export async function getUploadedReport(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.csv.getReport}/${uuid}`, null, null, null)
}

export async function getCsvValues(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.csv.getCsvValues}/${uuid}`, null, null, null)
}

export async function getCities(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.csv.getAllCities}?searchText=${searchText}`, null, null, null)
}

export async function updateMappedData(uuid, mappedData) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.csv.insertMappingData}/${uuid}`, null, mappedData, null)
}

export async function getAllJobs() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.csv.getAllJobs}`, null, null, null)
}

export async function saveConfig(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.csv.saveConfig}/${uuid}`, null, payload, null)
}

export async function processCSV(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.csv.processCSV}/${uuid}`, null, null, null)
}

export async function deleteCSVUpload(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.csv.deleteCSV}/${uuid}`, null, null, null)
}

export async function createExport(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.csv.createExport}`, null, payload, null)
}

export async function getExportLeadsCount(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.csv.getExportLeadsCount}`, null, payload, null)
}

export async function getAllExportJobs() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.csv.getExportJobs}`, null, null, null)
}