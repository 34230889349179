import React, { useEffect, useState } from 'react'

import BasicModal from '../../Modals/BasicModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DotPointsIcon } from '../../../assets/icons/dotpoints.svg'
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import DateTimePicker from '../../InputGroup/DateTime';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as ThreedotsIcon } from '../../../assets/icons/threedots.svg';
import { Link, useParams } from 'react-router-dom';
import { createDatewithTime } from '../../../helpers/date';
import { getPaymentMilestone, insertPaymentMilestone, insertPaymentPlan, updatePaymentPlanByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import style from '../../../modules/Settings/Projects/style.module.css';

import AddMilestoneModal from './AddMilestoneModal';
import { toINRFormat } from '../../../helpers/helpers';

const AddPaymentPlanModal = ({
    show,
    closeModal,
    plansMilestone,
    selectedPaymentPlan,
    getPaymentPlans,
    editMilestone,
    getProjectsMilstone
}) => {
    const [paymentPlan, setPaymentPlans] = useState({
        name: '',
        description: '',
        remarks: '',
        validity: '',
        milestones: []
    });
    const [milestones, setMilestones] = useState({});
    const [selectedIndex, setSelectedIndex] = useState('');
    const [descriptionList, setDescriptionList] = useState([]);
    const [showAddMilestone, setShowAddMilestone] = useState(false);

    // const [selectedPaymentPlan, setSelectedPaymentPlan] = useState('');
    const { uuid } = useParams();

    useEffect(() => {
        if (selectedPaymentPlan.uuid && plansMilestone) {
            setPaymentPlans({ ...selectedPaymentPlan, milestones: plansMilestone })
        }
        if (editMilestone.uuid) {
            setMilestones({ ...editMilestone })
        }
    }, [selectedPaymentPlan, plansMilestone, editMilestone])

    const getDescriptionList = async () => {
        const res = await getPaymentMilestone();
        if (res.data.status === 200) {
            const mappedList = res.data.data.map(item => ({ ...item, label: item.description, value: item.id }))
            setDescriptionList([...mappedList])
        }
    }
    const handleCreateMilestoneDescription = async (value) => {
        await insertPaymentMilestone()
    }
    useEffect(() => {
        getDescriptionList()
    }, []);

    const handleAddMilestone = (milestones) => {
        if (selectedIndex === '') {
            let paymentMilestones = paymentPlan.milestones;
            paymentMilestones.push(milestones)
            setPaymentPlans({ ...paymentPlan, milestones: paymentMilestones })

        } else {
            let paymentMilestones = [...paymentPlan.milestones];
            paymentMilestones[selectedIndex] = milestones;
            setPaymentPlans({ ...paymentPlan, milestones: paymentMilestones });
            setSelectedIndex('')
            setMilestones({})
        }
    }
    const handleSubmmit = async () => {
        const payload = paymentPlan
        let res = {}
        if (selectedPaymentPlan?.uuid) {
            res = await updatePaymentPlanByUUID(selectedPaymentPlan.uuid, payload)
        } else {
            res = await insertPaymentPlan(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getPaymentPlans();
            getProjectsMilstone();
            closeModal();
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[900px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-7 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>
                        <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter flex items-center'>
                            <span className='border border-grayLight p-1 mr-3 rounded-lg'>
                                <DotPointsIcon />
                            </span>
                            Payment Plan/Milestones
                        </h1>
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                {/* <div className='!px-6 !mt-5 !pb-4 !border-b !border-grayLight'>
                    <div class="flex items-center gap-x-6 nav-payment-milestone navpaymentmodal">
                        <a href='#paymentsplan' class="p-0 text-base !text-black700 relative active">Payment Plan</a>
                        <a href='#milestone' class=" p-0 text-base !text-black700 relative">Milestone (1)</a>

                    </div> 

                </div> */}

                <div className='!pt-5 md:!px-6 !px-4 h-full overflow-y-auto overflow-x-hidden'>
                    <div id='paymentsplan'>
                        <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter !border-b !border-[#E2E2EA] !pb-4" >
                            Payment Plan
                        </h5>

                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !mt-5'>
                            <InputText
                                label="Payment Plan Name"
                                placeholder="Enter Payment Plan Name"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                                value={paymentPlan.name}
                                onChange={(e) => setPaymentPlans({ ...paymentPlan, [e.target.name]: e.target.value })}

                            />
                            <DateTimePicker
                                label="Validity"
                                placeholder="Enter Validity"
                                type="text"
                                name="validity"
                                inputclass='m-0 inter'
                                value={paymentPlan.validity}
                                showTime={false}
                                onValueChange={(date) => setPaymentPlans({ ...paymentPlan, validity: date })}
                            />
                            <div className='col-span-2'>
                                <div className='flex items-center justify-between'>
                                    <label className='m-0'>Payment Plan Description</label>
                                    <button
                                        type='button'
                                        className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                        onClick={() => {
                                            setPaymentPlans({ ...paymentPlan, ['description']: '' })
                                        }}
                                    >Clear <CloseIcon className='h-3 w-3' /></button>
                                </div>
                                <textarea
                                    className="form-control mb-16"
                                    name="description"
                                    value={paymentPlan.description}

                                    placeholder="Enter Payment Plan Description"
                                    onChange={(e) => setPaymentPlans({ ...paymentPlan, [e.target.name]: e.target.value })}
                                >

                                </textarea>


                            </div>

                        </div>
                    </div>
                    <div className='!mt-6' id='milestone'>
                        {/* <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter !border-b !border-[#E2E2EA] !pb-4" >
                            Milestone
                        </h5> */}

                        {/* <div className='!mt-5 !mb-5 flex justify-end'>
                            <button
                                className='bg-blueHighlight !text-primary text-sm font-medium inter flex items-center gap-1 !px-4 !py-2 rounded-lg float-right'
                                onClick={() => {
                                    setShowAddMilestone(true)
                                }}
                            ><PlusPrimary />
                                Add Milestone
                            </button>
                        </div> */}
                        {/* {
                            paymentPlan.milestones.length === 0 &&
                            <div className={`${style.basic_form} basic_form mt-20`}>
                                <div className={`${style.col_span_2}`}>
                                    <button type='button' onClick={() => setShowAddMilestone(true)} className='pr-outline-btn d-block w-100'>+ Add Milestone</button>
                                </div>
                            </div>
                        } */}
                        {paymentPlan?.milestones.length > 0 && <div className='w-100 !mt-5'>
                            <div className={` border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between`}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Milestone ({paymentPlan.milestones?.length})</h1>
                                <button type='button' className='inter border border-primary bg-blueHighlight text-primary rounded-lg px-2 ' onClick={() => setShowAddMilestone(true)}>+ Add Milestone</button>
                            </div>
                            <div className='!mt-5'>
                                {
                                    paymentPlan?.milestones.length > 0 ? <div className='!relative z-0 def-table !pr-0 w-100 max-h-full !h-full min-h-full !pb-5' >
                                        <table className='table mb-0'>
                                            <thead className='!z-10'>
                                                <tr>
                                                    <th className='!relative !w-auto !min-w-fit	!max-w-fit '>Milestone</th>
                                                    <th>Milestone Description</th>
                                                    <th>Total Payment</th>
                                                    <th>Collection Due Date</th>
                                                    {/* <th>Tentative Due Date</th> */}
                                                    <th className='!sticky right-[-10px]'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paymentPlan?.milestones.map((milestone, index) => (
                                                    <tr key={String(index)}>
                                                        <td className='!relative'>{index + 1}</td>
                                                        <td>{milestone.description || '-'}</td>
                                                        <td>
                                                            {(milestone.payment_percentage || milestone.payment_amount) ?
                                                                (milestone.payment_percentage ? `${milestone.payment_percentage}%` : `₹ ${toINRFormat(milestone.payment_amount)}`) : '-'
                                                            }
                                                        </td>
                                                        <td>{milestone.due_date ? createDatewithTime(new Date(milestone.due_date)) : '-'}</td>
                                                        {/* <td>{milestone.tentative_due_date ? createDatewithTime(new Date(milestone.tentative_due_date)) : '-'}</td> */}
                                                        <td className='dropdown view-more !sticky !right-0'>
                                                            <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <ThreedotsIcon />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                <a className="dropdown-item text-capitalize"
                                                                    onClick={() => {
                                                                        setSelectedIndex(index);
                                                                        setMilestones({ ...milestone })
                                                                        setShowAddMilestone(true);
                                                                    }}
                                                                >Edit</a>
                                                                <a className="dropdown-item del-menu-itme text-capitalize"
                                                                    onClick={() => {
                                                                        let paymentMilestones = [...paymentPlan.milestones]
                                                                        paymentMilestones.splice(index, 1);
                                                                        setPaymentPlans({ paymentPlan, milestones: paymentMilestones })
                                                                    }}
                                                                >Delete</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                        :
                                        <>
                                            <div className='def-table !pr-0 w-100 max-h-[230px] !h-min !pb-5' >
                                                <table className='table mb-0'>
                                                    <thead className='!relative !z-0'>
                                                        <tr>
                                                            <th className='!relative !w-auto !min-w-fit	!max-w-fit '>Milestone</th>
                                                            <th>Milestone Description</th>
                                                            <th>Total Payment</th>
                                                            <th>Collection Due Date</th>
                                                            <th>Tentative Due Date</th>
                                                            <th className='!sticky right-[-10px]'></th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div className='d-flex justify-content-center flex-column text-center !mt-6 !mb-6'>
                                                <figure className='self-center'><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize"> No Milestone</h2>
                                                <p className='text-center black-dark-600 fz14 mb-2 text-capitalize'>Please add new Milestone.</p>
                                                <div>
                                                    <button type='button' className='pr-btn inter ' onClick={() => setShowAddMilestone(true)}>+ Add Milestone</button>
                                                </div>
                                            </div></>
                                }
                            </div>
                        </div>}
                        {paymentPlan?.milestones.length === 0 && <div className='border border-primary bg-blueHighlight py-2 text-center rounded-lg'>
                            <button type='button' className='inter  text-primary' onClick={() => setShowAddMilestone(true)}>+ Add Milestone</button>
                        </div>}
                    </div>
                </div>
            </div>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button
                    className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center'
                    onClick={closeModal}
                >
                    Cancel
                </button>
                <button
                    className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center'
                    disabled={paymentPlan.milestones.length === 0}
                    onClick={handleSubmmit}
                >
                    Submit
                </button>
            </div>
            {showAddMilestone && <AddMilestoneModal
                show={showAddMilestone}
                descriptionListData={descriptionList}
                handleAddMilestone={handleAddMilestone}
                selectedMilestone={milestones}
                setDescriptionListData={setDescriptionList}
                closeModal={() => {
                    setMilestones({})
                    setShowAddMilestone(false)
                }
                } />}
        </BasicModal >
    )
}

export default AddPaymentPlanModal
