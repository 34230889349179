export const NOTES_ACTIONS = {
    CREATE_NOTE: 'CREATE_NOTE',
    GET_NOTES: 'GET_NOTES',
    SET_NOTES: 'SET_NOTES',
    UPDATE_NOTE: 'UPDATE_NOTE',
    REMOVE_NOTE: 'REMOVE_NOTE'
}

export function action_getNotes(leadUUID) {
    return {
        type: NOTES_ACTIONS.GET_NOTES,
        leadUUID
    }
}

export function action_setNotes(notes) {
    return {
        type: NOTES_ACTIONS.SET_NOTES,
        notes
    }
}

export function action_createNote(leadUUID, description) {
    return {
        type: NOTES_ACTIONS.CREATE_NOTE,
        leadUUID,
        description
    }
}

export function action_updateNote(noteUUID, description, leadUUID) {
    return {
        type: NOTES_ACTIONS.UPDATE_NOTE,
        noteUUID,
        description,
        leadUUID
    }
}

export function action_removeNote(noteUUID, leadUUID) {
    return {
        type: NOTES_ACTIONS.REMOVE_NOTE,
        noteUUID,
        leadUUID
    }
}