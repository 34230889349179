import React from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Searchnofound } from '../../../assets/icons/searchicon.svg';
import RightModal from '../../Modals/RightModal';
import SearchInput from '../../InputGroup/SearchInput';
import { useEffect } from 'react';
import { getAmenities } from '../../../services/public/public.service';
import { useState } from 'react';


const AmenitiesModal = ({ show, closeModal, style, handleSelectedAmnities, amenities }) => {

    const [amenitiesList, setAmenitiesList] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [searchText, setSeachText] = useState('');
    const [allAmenitiesList, setAllAmenitiesList] = useState([]);

    const getAllAmenities = async () => {
        const res = await getAmenities();
        if (res.data.status === 200) {
            setAmenitiesList(res.data.data);
            setAllAmenitiesList(res.data.data);
        }
    }

    useEffect(() => {
        getAllAmenities();
    }, []);

    useEffect(() => {
        const filteredList = allAmenitiesList.filter(item => item.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1);
        setAmenitiesList(filteredList)
    }, [searchText]);

    useEffect(() => {
        setSelectedAmenities(amenities)
    }, [amenities]);

    return (
        <RightModal show={show} closeModal=
            {() => {

                closeModal();
            }}
            modalClass={`${style.configurationModal}  configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Amenities</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='px-20 py-16'>
                <SearchInput
                    searchText={searchText}
                    onChange={(e) => setSeachText(e.target.value)}
                    placeholder='Search For Amenities'
                    handelClear={() => setSeachText('')}
                />
            </div>
            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>

                {
                    amenitiesList.map((item, index) => (

                        <label
                            className={`${style.animiteslist} d-flex align-items-center justify-content-between border border-color radius-8`}
                            key={`${item.name}_${index}`}
                        >
                            <span>{item.name}</span>
                            <label htmlFor=""></label>
                            <input
                                type="checkbox"
                                className={`${style.circlecheckbox}`}
                                onChange={() => {
                                    const itemIndex = selectedAmenities.findIndex(amenity => amenity === item.name);
                                    if (itemIndex === -1) {
                                        setSelectedAmenities([...selectedAmenities, item.name])
                                    } else {
                                        const filteredList = selectedAmenities.filter(amenity => amenity != item.name);
                                        setSelectedAmenities(filteredList)
                                    }
                                }}
                                checked={selectedAmenities.includes(item.name)}
                            // value={selectedAmenities.includes(item.name)}
                            />
                        </label>
                    ))
                }

                {amenitiesList.length === 0 && searchText &&
                    <div className='mt-64 d-felx flex-column align-items-center justify-content-cetner gap-12 text-center'>
                        <figure className='d-table mx-auto'><Searchnofound /></figure>
                        <p className='inter fz16 text-black fw-po-medium'>Didn’t find any matches </p>
                    </div>
                }

            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    setSelectedAmenities([])
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={() => {
                        handleSelectedAmnities(selectedAmenities);
                        closeModal();
                    }} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default AmenitiesModal;
