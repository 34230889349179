import React from 'react'
import { Modal } from 'react-bootstrap'


const SmallCenterModal = ({ show, closeModal, children, dialogClassName, size }) => {
    return (
        <Modal show={show} size={size || 'md'} onHide={closeModal} backdrop="static" centered className={dialogClassName}>
            {children}
        </Modal>
    )
}

export default SmallCenterModal