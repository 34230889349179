import React, { useState } from 'react'


const Faqs = ({ workshop }) => {

    const [clicked, setClicked] = useState(false);

    const toggle = (index) => {
        if (clicked === index) {
            setClicked(null)
        }
        setClicked(index);
    }

    return (
        <div className="faq">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-11">
                        <h1 className="d-block text-center h-font">FAQs</h1>
                        <div className="accordion" id="accordionExample">
                            {
                                workshop.faq_questions && workshop.faq_questions.map((faq, index) => {
                                    return <React.Fragment key={index}>
                                        {
                                            faq.question !== '' && faq.question !== null && faq.question !== undefined && faq.answer !== '' && faq.answer !== null && faq.answer !== undefined && <div className="card" >
                                                <div className="card-header" id="headingnine" data-toggle="collapse" data-target={"#collapsenine" + index} aria-expanded="false" aria-controls="collapsenine" key={index}>
                                                    {faq.question}

                                                    <img src={`${process.env.PUBLIC_URL}/plush.png`} className="pl" />
                                                    <img src={`${process.env.PUBLIC_URL}/minus.png`} className="close" />
                                                </div>

                                                <div id={"collapsenine" + index} className="collapse" aria-labelledby="headingnine" data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        {faq.answer}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqs;