import { NOTES_ACTIONS } from './actions';

const intialState = {
    notes: []
}

export function notesReducer(state = intialState, action) {
    switch (action.type) {
        case NOTES_ACTIONS.SET_NOTES:
            return { ...state, notes: action.notes };
        default:
            return state
    }

}