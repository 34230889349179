import React from 'react'
import BasicModal from '../../Modals/BasicModal';

const ViewCollection = ({ show, closeModal }) => {
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4'>
            View Collections
        </BasicModal>
    )
}

export default ViewCollection;