import React from 'react'
import MentorCard from './MentorCard'
import OwlCarousel from 'react-owl-carousel';
import leftsvg from './mentorleft.svg'
import rightsvg from './mentorright.svg'
import leftsvghover from './mentorlefthover.svg'
import rightsvghover from './mentorrighthover.svg'

const Mentor = ({ style, mentors }) => {

    return (
        <div className={`${style.mentor} mentor overflow-hidden`}>
            <div className="d-flex justify-content-center">
                <div className={style.mentorOwl}>
                    <h1 className='fz28 black fw-po-medium inter text-md-center mb-24'>Mentors</h1>

                    {mentors.length > 3 ?
                        <OwlCarousel
                            className='owl-theme'
                            dots={false}
                            loop={true}
                            nav={true}
                            navText={[`<img src=${leftsvg} /> <img src=${leftsvghover} />`, `<img src=${rightsvg} /> <img src=${rightsvghover} />`]}
                            margin={32}
                            responsive={{
                                0: {
                                    items: 1,
                                    autoWidth: true,
                                    margin: 24,

                                },
                                600: {
                                    items: 3,
                                    autoWidth: true
                                },
                                900: {
                                    items: 3,
                                    autoWidth: true
                                },
                                1000: {
                                    items: 3,
                                    autoWidth: true
                                },
                                1200: {
                                    items: 3,
                                    autoWidth: true

                                }
                            }
                            }>
                            {
                                mentors.map(mentor => (
                                    <div className='item' style={{ width: '298px' }} key={`${mentor.id}_${mentor.name}`}>
                                        <MentorCard style={style} mentor={mentor} />
                                    </div>
                                ))
                            }
                        </OwlCarousel>

                        : <div className='d-flex flex-md-row flex-column align-item-md-start align-items-start justify-content-center onlytreementor'>
                            {
                                mentors.map(mentor => (
                                    <div className='item' key={`${mentor.id}_${mentor.name}`}>
                                        <MentorCard style={style} mentor={mentor} />
                                    </div>
                                ))
                            }
                        </div>


                    }
                </div>
            </div>
        </div >
    )
}

export default Mentor
