import React, { useEffect, useState } from 'react';
import { ReactComponent as DownICon } from '../../assets/icons/Down.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/GrayMenu.svg';
import InputText from '../InputGroup/InputText';
import InputSelect from '../InputGroup/InputSelect';
import SearchInput from '../InputGroup/SearchInput';
import { getUnits, updateBookingUnit } from '../../services/private/inventory.service';
import { getConfigString, getPriceFromConfig, toINRFormat } from '../../helpers/helpers';
import { toast } from 'react-toastify';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const getStatusClass = (status) => {
    switch (status) {
        case 'Available':
            return 'text-green bg-green100'
        case 'Unavailable':
            return 'text-black700 bg-black200'
        case 'Blocked':
            return 'text-primary bg-blueHighlight'
        case 'Pending confirmation':
            return 'text-primary bg-blueHighlight'
        case 'Booked':
            return 'text-white bg-primary'
    }
}

const UnitDetail = ({
    setSteps,
    selectedProject,
    bookingId,
    data,
    setUnit,
    selectedBooking,
    basePriceData,
    basePriceConfigMap,
    selectedEoiDetails
}) => {

    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState({});
    const [isHidden, setIsHidden] = useState(true);
    const [unitDetails, setUnitDetails] = useState([]);
    const [arrangedUnit, setArrangedUnit] = useState({});
    const [showAvailable, setShowAvailable] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState('');
    const [availableCount, setAvailableCount] = useState({});
    const [searchText, setSearchText] = useState('');

    const getUnitDetails = async () => {
        const payload = {
            query: {
                searchText
            }
        }
        const res = await getUnits(selectedProject.uuid, 500, 1, payload);
        if (res.data.status === 200) {
            let arrangedData = {}
            // let availCount = {}            
            res.data.data.units.forEach(item => {
                if (arrangedData[item.floor_no || item.block]) {
                    arrangedData[item.floor_no || item.block].push(item)
                } else {
                    arrangedData[item.floor_no || item.block] = [item]
                }

            })
            let filteredData = {}
            Object.keys(arrangedData).forEach(item => {
                const availableUnit = arrangedData[item].filter(unit => unit.status === 'Available');
                if (availableUnit.length > 0)
                    filteredData[item] = availableUnit;
            });
            setArrangedUnit(filteredData);
            setUnitDetails(arrangedData);
        }
    }


    useEffect(() => {
        if (selectedProject?.uuid) {
            getUnitDetails();
        }

    }, [selectedProject, searchText]);

    useEffect(() => {
        if ((selectedBooking && Object.keys(selectedBooking).length > 0) && (selectedBooking?.booking?.approval_status &&
            !(selectedBooking?.booking?.approval_status === 'rejected' || selectedBooking?.booking?.approval_status === 'cancelled'))) {
            const { bookingUnit } = selectedBooking;

            if (bookingUnit) {
                setSelectedUnit(bookingUnit);
                let filteredData = {};
                Object.keys(unitDetails).forEach(item => {
                    const availableUnit = unitDetails[item].filter(unit => unit.uuid === bookingUnit?.uuid);
                    if (availableUnit.length > 0)
                        filteredData[item] = availableUnit;
                })
                if (Object.keys(filteredData).length === 0 && bookingUnit) {
                    filteredData[bookingUnit?.floor_no || bookingUnit?.block] = [bookingUnit]
                }
                setArrangedUnit(filteredData)
            } else if (selectedEoiDetails) {
                const { bookingUnit } = selectedEoiDetails;
                setSelectedUnit(bookingUnit)
                let filteredData = {};
                Object.keys(unitDetails).forEach(item => {
                    const availableUnit = unitDetails[item].filter(unit => unit.uuid === bookingUnit?.uuid);
                    if (availableUnit.length > 0)
                        filteredData[item] = availableUnit;
                })
                if (Object.keys(filteredData).length === 0 && bookingUnit) {
                    filteredData[bookingUnit?.floor_no || bookingUnit?.block] = [bookingUnit]
                }
                setArrangedUnit(filteredData)
            }
        }
    }, [selectedBooking, unitDetails, selectedEoiDetails, data])

    // console.log({ arrangedUnit }, { selectedUnit }, unitDetails);
    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };
    const spanClassName = isSpanClassAdded ? 'rotate-180' : '';

    const toggleVisibility = (value, index) => {
        setIsHidden(value);
        setSelectedIndex(!value ? index : '')
    };

    const divClassName = isHidden ? 'hidden' : '';

    const handleShowAvailable = (value) => {
        setShowAvailable(value);

        if (value) {
            let filteredData = {}
            Object.keys(unitDetails).forEach(item => {
                const availableUnit = unitDetails[item].filter(unit => unit.status === 'Available');
                filteredData[item] = availableUnit;
            })
            setArrangedUnit(filteredData);
        } else {
            setArrangedUnit({ ...unitDetails });
        }
    }

    const handleSubmit = async () => {
        if (Object.keys(selectedUnit).length === 0) {
            toast.error('Please select unit');
            return;
        }

        const payload = {
            unit: selectedUnit.uuid,
            status: "Blocked"
        }
        const res = await updateBookingUnit(bookingId, payload);
        if (res.data.status === 200) {
            setUnit(selectedUnit);
            setSteps('Pricing');
        }
    }

    return (
        <>
            <div className='!pt-5 sm:!px-6 px-3 pb-8 h-full overflow-auto'>
                <div className='flex md:items-center md:flex-row flex-col md:gap-0 !gap-4'>
                    <div className='md:w-4/12'>
                        <h2 className='m-0 text-textblack text-lg font-semibold inter'>All Units ({selectedProject?.name})</h2>
                    </div>
                    {!(selectedBooking?.bookingUnit && Object.keys(selectedBooking?.bookingUnit).length > 0) && <div className='md:w-8/12 flex md:items-center items-start  gap-x-3 sm:flex-row flex-col sm:gap-y-0 gap-y-3'>
                        <div className='!border border-grayLight rounded-lg  py-2.5 !px-4 !bg-white inline-flex items-center sm:w-[218px] sm:basis-[218px] sm:grow-0 sm:shrink-0 w-full justify-between'>
                            <h4 className='mb-0 text-xs font-semibold text-black700 inter'>Show all Available</h4>
                            <label className="relative inline-flex items-center cursor-pointer m-0">
                                <input
                                    type="checkbox"
                                    value={showAvailable}
                                    className="sr-only peer"
                                    checked={showAvailable}
                                    onChange={() => handleShowAvailable(!showAvailable)}
                                    disabled={selectedBooking?.bookingUnit && Object.keys(selectedBooking?.bookingUnit).length > 0}
                                />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:!bg-primary"></div>
                            </label>

                        </div>
                        <div className='w-full'>
                            <SearchInput
                                placeholder={'Search Unit No'}
                                searchText={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                handelClear={() => setSearchText('')}
                                disabled={selectedBooking?.bookingUnit && Object.keys(selectedBooking?.bookingUnit).length > 0}
                            /></div>
                    </div>}
                </div>

                {
                    Object.keys(arrangedUnit).length > 0 && Object.keys(arrangedUnit).map((floor, index) => (
                        <div className='!mt-4 ' key={`${floor}_${index}`}>
                            <div id="accordion" className='flex flex-col !gap-4'>
                                <div className="!border border-grayLight rounded-xl">
                                    <div className="d-flex justify-between  !p-5" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={toggleSpanClass}>
                                        <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] items-center inter">
                                            Block {floor}
                                        </h5>
                                        <div className='flex items-center gap-x-3 '>
                                            {/* <p className='m-0 text-black700 text-sm inter'>12/24 Available</p> */}
                                            <span className={spanClassName}><DownICon /></span>
                                        </div>
                                    </div>

                                    {arrangedUnit[floor] && arrangedUnit[floor].map((unit, unitIndex) => (
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion" key={`${unit.uuid}_${unitIndex}`}>
                                            <div className='!px-4 border border-grayLight !mx-4 rounded-lg !mb-4'>
                                                <div className=' flex sm:items-center justify-between !pt-4 !pb-4 sm:flex-row flex-col'>
                                                    <div className='md:w-2/4'>
                                                        <h3 className='inter text-base font-semibold text-black700 m-0'>{unit?.name}</h3>
                                                        {/* <div className='sm:hidden bg-green100 text-xs inter font-semibold inter leading-4 !py-2 px-2.5 !text-green rounded-md !mt-2 text-center'>Available</div> */}
                                                    </div>
                                                    <div className='md:w-2/4 flex items-center sm:justify-end justify-between gap-x-3 sm:!mt-0 !mt-4'>
                                                        <div className={`text-xs text-center rounded-lg p-1 capitalize ${getStatusClass(unit.status)}`}>
                                                            {unit.status === 'Pending confirmation' ? `${unit.status} (${unit.booking_stage})` : unit.status}
                                                        </div>
                                                        {unit.status === 'Available' && <div className='flex !border border-grayLight sm:gap-3 gap-1 items-center !py-1 !px-2.5 rounded'>
                                                            <p className='m-0 text-textblack inter sm:text-sm text-xs font-medium'>Block Unit</p>
                                                            <input
                                                                type="radio"
                                                                name="unit"
                                                                id=""
                                                                checked={unit.uuid === selectedUnit?.uuid}
                                                                onChange={() => setSelectedUnit(unit)}
                                                                className='sm:w-[22px] sm:h-[22px] w-5 h-5'
                                                            />
                                                        </div>}
                                                        {/* <button><MenuIcon /></button> */}
                                                    </div>
                                                </div>
                                                <div className='!border-t !border-grayLight flex sm:items-center justify-between sm:flex-row flex-col sm:gap-y-0 gap-y-4 m-0 text-textblack inter text-sm font-medium !pt-3 !pb-4'>
                                                    <div className='inline-flex !gap-x-6 sm:flex-row flex-col sm:gap-y-0 !gap-4'>
                                                        <div>
                                                            <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{unit?.name || '-'}</h4>
                                                            <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Unit No.</p>
                                                        </div>
                                                        <div>
                                                            <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{unit?.block}</h4>
                                                            <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>{unit?.configuration?.unit_type === 'Plot' ? 'Block' : 'Tower'} </p>
                                                        </div>
                                                        {/* {unit.configuration.unit_type !== 'Plot' && <div>
                                                            <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{unit?.floor_no}</h4>
                                                            <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Floor</p>
                                                        </div>} */}
                                                        <div>
                                                            <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{getConfigString(unit?.configuration)} </h4>
                                                            <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Area</p>
                                                        </div>
                                                        <div>
                                                            <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>₹ {toINRFormat(getPriceFromConfig(unit?.configuration, basePriceConfigMap[unit?.configuration.id]))}</h4>
                                                            <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Price</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => toggleVisibility(false, unitIndex)} className='text-sm inter !text-primary mb-0 leading-5 font-medium text-right'>View Unit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${(selectedIndex === unitIndex && !isHidden) ? '' : 'hidden'} !border !border-primary !px-4 !py-4 rounded-xl bg-[#F9FAFD] !mx-4 mb-4 `} >
                                                <div className=' flex items-center !pb-4'>
                                                    <div className='w-2/4'>
                                                        <h3 className='inter text-base font-semibold text-black700 m-0'>{unit.name || '-'}</h3>
                                                    </div>
                                                    <div className='w-2/4 flex items-center justify-end gap-x-3'>
                                                        <div className='bg-green100 text-xs inter font-semibold inter leading-4 !py-2 px-2.5 !text-green rounded-md'>Available</div>
                                                        {/* <div className='flex !border border-grayLight sm:gap-3 gap-1 items-center !py-1 !px-2.5 rounded'>
                                                            <p className='m-0 text-textblack inter sm:text-sm text-xs font-medium whitespace-nowrap'>Block Unit</p>
                                                            <input type="radio" name="" id="" className='sm:w-[22px] sm:h-[22px] w-5 h-5' />
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className='!mt-4'>
                                                    {/* <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                                        <InputText
                                                            label="Unit Number"
                                                            placeholder="A601"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            value={unit?.name}
                                                            disable={true}
                                                        />
                                                        <InputSelect
                                                            label='Configuration'
                                                            placeholder='2BHK (1273 sq.ft) '
                                                            inputclass='m-0 inter'
                                                            isMulti={false}
                                                            styles={customStyles}
                                                            value={{
                                                                label: `${getConfigString(unit?.configuration)}`,
                                                                value: `${getConfigString(unit?.configuration)}`,
                                                            }}
                                                            disable={true}
                                                        />
                                                        <InputText
                                                            label="Building/Phase"
                                                            placeholder="1"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            disable={true}
                                                        />
                                                        <InputText
                                                            label="Block/Wing"
                                                            placeholder="A"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            value={unit?.block}
                                                            disable={true}
                                                        />
                                                        <InputText
                                                            label="Floor"
                                                            placeholder="6"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            value={unit?.floor_no}
                                                            disable={true}

                                                        />
                                                        <InputText
                                                            label="Unit Type"
                                                            placeholder="2BHK"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            value={unit?.configuration.unit_type}
                                                            disable={true}
                                                        />
                                                        <InputText
                                                            label="No. of Units per Floor"
                                                            placeholder="6"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            disable={true}
                                                        />
                                                        <InputSelect
                                                            label='Furnishing'
                                                            placeholder='Semi Furnished'
                                                            inputclass='m-0 inter'
                                                            isMulti={false}
                                                            styles={customStyles}
                                                            value={{
                                                                label: unit?.furnishing,
                                                                value: unit?.furnishing
                                                            }}
                                                            disable={true}
                                                        />
                                                        <InputSelect
                                                            label='Balcony'
                                                            placeholder='2'
                                                            inputclass='m-0 inter'
                                                            isMulti={false}
                                                            styles={customStyles}
                                                            disable={true}
                                                        />
                                                        <InputSelect
                                                            label='Car Parking'
                                                            placeholder='1'
                                                            inputclass='m-0 inter'
                                                            isMulti={false}
                                                            styles={customStyles}
                                                            disable={true}
                                                            value={{
                                                                label: unit?.car_parking,
                                                                value: unit?.car_parking
                                                            }}
                                                        />
                                                        <InputSelect
                                                            label='Facing'
                                                            placeholder='North East'
                                                            inputclass='m-0 inter'
                                                            isMulti={false}
                                                            styles={customStyles}
                                                            disable={true}
                                                            value={{
                                                                label: unit?.facing,
                                                                value: unit?.facing
                                                            }}
                                                        />
                                                        <InputSelect
                                                            label='View'
                                                            placeholder='Pool View'
                                                            inputclass='m-0 inter'
                                                            isMulti={false}
                                                            styles={customStyles}
                                                            disable={true}
                                                        />
                                                    </div> */}
                                                    <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                                                        {/* <div>
                                <p className='!mb-1 text-sm text-black700'>Project</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>Godrej Avenues</p>
                            </div> */}
                                                        {unit?.name && <div>
                                                            <p className='!mb-1 text-sm text-black700'>Unit Number</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.name || '-'}</p>
                                                        </div>}
                                                        {unit?.configuration && <div>
                                                            <p className='!mb-1 text-sm text-black700'>Configuration</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'>{getConfigString(unit?.configuration)}</p>
                                                        </div>}
                                                        {unit?.block && <div>
                                                            <p className='!mb-1 text-sm text-black700'>Block/Wing</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.block || '-'}</p>
                                                        </div>}
                                                        {unit?.floor_no && <div>
                                                            <p className='!mb-1 text-sm text-black700'>Floor</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.floor_no || '-'} </p>
                                                        </div>}
                                                        {unit?.configuration?.unit_type && <div>
                                                            <p className='!mb-1 text-sm text-black700'>Unit Type</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'> {unit?.configuration?.unit_type || '-'}</p>
                                                        </div>}
                                                        {/* <div>
                                                            <p className='!mb-1 text-sm text-black700'>No. of Units per Floor</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.floor_no || '-'}</p>
                                                        </div> */}
                                                        <div>
                                                            <p className='!mb-1 text-sm text-black700'>Furnishing</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.furnishing || '-'}</p>
                                                        </div>
                                                        {unit?.car_parking && <div>
                                                            <p className='!mb-1 text-sm text-black700'>Car Parking</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.car_parking || '-'}</p>
                                                        </div>}
                                                        {basePriceConfigMap && <div>
                                                            <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>₹ {toINRFormat(basePriceConfigMap[unit?.configuration.id])}</h4>
                                                            <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Unit Rate Price</p>
                                                        </div>}
                                                        {/* {unit?.facing && <div>
                                <p className='!mb-1 text-sm text-black700'>Facing</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.facing}</p>
                            </div>}
                            {bookingUnit?.plc_charge3 && <div>
                                <p className='!mb-1 text-sm text-black700'>PLC Charges 3</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {bookingUnit?.plc_charge3}</p>
                            </div>}
                            {bookingUnit?.plc_charge4 && <div>
                                <p className='!mb-1 text-sm text-black700'>PLC Charges 4</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {bookingUnit?.plc_charge4}</p>
                            </div>}
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Unit Number</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.name}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Facing</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.facing}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>All Pricing Charges</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {bookingUnit ? getUnitTotalPrice(bookingUnit) : 0}</p>
                            </div> */}
                                                    </div>
                                                </div>

                                                <div className='mt-4 flex justify-end'>
                                                    <button onClick={() => toggleVisibility(true, unitIndex)} className='text-sm font-medium !text-primary inter'>Hide Unit</button>
                                                </div>

                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>


                            </div>
                        </div>
                    ))
                }


            </div>
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-end shadow-topshadow sm:flex-row flex-col'>
                {/* <div>
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ 64,00,000</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For $Unit_Name</p>
                </div> */}
                <div className='flex gap-3 items-center justify-end'>
                    <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter' onClick={() => setSteps('basic')}>Previous</button>
                    <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter' onClick={handleSubmit}>Next</button>
                </div>
            </div>
        </>
    )
}

export default UnitDetail
