import React, { useEffect, useState } from 'react'
import { ReactComponent as PropacityLogo } from '../../assets/propacityplush.svg';
import { ReactComponent as Offericon } from '../../assets/buz.svg';
import { ReactComponent as Propacityfav } from '../../assets/propacityfav.svg';
import { ReactComponent as OrferIcon } from '../../assets/offericon.svg';
import { ReactComponent as LeftIcon } from '../../assets/ic_Down.svg';
import { applyCoupon, applyCouponSubscription, getActiveCoupons } from '../../services/public/public.service';
import { API_URLS } from '../../config/apiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

const calculateVal = (amount, percente) => {
    return (amount * (percente / 100))
}

const Coupons = ({ setComponentName, page, selectedPlan, setCode, code, setSelectedOffer, uuid }) => {

    const [offers, setOffers] = useState([]);

    const getCupons = async () => {
        const res = await getActiveCoupons();
        if (res.data.status === 200) {
            let planAmount = selectedPlan?.base_amount ? selectedPlan?.base_amount : selectedPlan?.amount
            let planOffers = res.data.data.filter(offer => offer.code).map(offer => ({ ...offer, saved_amount: calculateVal(planAmount, offer?.discount_percentage) }))
            setOffers(planOffers);
        }
    }

    const getSubscriptionCupons = async () => {
        const res = await axios.get(`${API_URLS.baseurl}${API_URLS.company.getActiveCoupons}/${uuid}`)
        if (res.data.status === 200) {
            let planAmount = selectedPlan?.base_amount ? selectedPlan?.base_amount : selectedPlan?.amount
            let planOffers = res.data.data.filter(offer => offer.code).map(offer => ({ ...offer, saved_amount: calculateVal(planAmount, offer?.discount_percentage) }))
            setOffers(planOffers);
        }
    }

    useEffect(() => {
        if (!uuid) {
            getCupons();
        } else {
            getSubscriptionCupons();
        }
    }, []);

    const handleSubmitWithUuid = async (code) => {
        const payload = {
            subscription_id: selectedPlan.uuid,
            code: code
        }
        try {
            const res = await applyCouponSubscription(uuid, payload);
            if (res.data.status === 200) {
                setCode(code);
                setComponentName('offers')
            }
        } catch (err) {
            setCode('');
            toast.error(err.response.data.message);
        }
    }

    const handleSubmit = async (code) => {
        const payload = {
            subscription_id: selectedPlan.uuid,
            code: code
        }
        try {
            const res = await applyCoupon(payload);
            if (res.data.status === 200) {
                setCode(code);
                setComponentName('offers')
            }
        } catch (err) {
            setCode('');
            toast.error(err.response.data.message);
        }
    }

    return (
        <div className={`bg-white rounded-xl md:max-w-[560px] md:basis-[560px] pt-14 pb-8 !px-6 grow-0 shrink-0 ${page === 'inner' ? 'w-full' : 'w-11/12 shadow-shadowcard'} mt-10 `}>
            <div className=''>
                <div className='!mx-auto !table !mb-6'><PropacityLogo /></div>
                <div>
                    <div className='!mb-4 font-semibold text-textblack inline-flex !gap-4 cursor-pointer'>
                        <button onClick={() => setComponentName('offers')}><LeftIcon /></button>
                        Coupons for you
                    </div>
                    <div className='space-y-4'>
                        <div className='flex justify-between items-center rounded-xl !border-black400 !border !py-3 !px-4' onClick={() => { }}>
                            <input
                                type="text"
                                placeholder='Have a coupon code/ referral? '
                                className='w-full !outline-0 text-black700 !text-base placeholder:!text-base'
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <button
                                className='text-blue-900 font-semibold !px-2'
                                onClick={() => {
                                    if (uuid) {
                                        handleSubmitWithUuid(code)
                                    } else {
                                        handleSubmit(code)
                                    }
                                }}
                            >Apply</button>
                        </div>
                        <div className='flex justify-between items-center rounded-xl !border-black400 !border !py-3 !px-4 text-xs' onClick={() => { }}>
                            <div className='inline-flex items-center !gap-2 text-black800'>
                                <span><Offericon /></span>
                                Enjoy a 20% discount by using your exclusive referral code at checkout!
                            </div>
                        </div>
                    </div>
                </div>
                {offers?.length > 0 && <div className='!mt-6'>
                    <div className='!mb-4 font-medium text-black700 tracking-[2px] text-xs uppercase'>Promotional Offer</div>
                    {
                        offers.map((offer, index) => (
                            <div className='rounded-xl !p-4 shadow-boxshadow !border border-black400 space-y-4 mt-2' key={offer.code}>
                                <div className='flex flex-col'>
                                    <div className='flex items-start !gap-2 text-black800'>
                                        <div><OrferIcon /></div>
                                        <div>
                                            <div className='text-textblack font-bold'>{offer?.title}</div>
                                            <div className='text-xs text-black700 font-medium !mt-2'>Save ₹ {offer.saved_amount} with <span className='text-blue-800'>({offer.code})</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='!border !border-t-black400 !my-3 '></div>
                                <div>
                                    <button
                                        className='bg-blue-900/[0.1] flex text-center justify-center w-100 !py-2 !rounded-lg text-blue-900 text-sm font-medium'
                                        onClick={() => {
                                            setSelectedOffer(offer)
                                            handleSubmit(offer?.code)
                                        }}
                                    >
                                        TAP TO APPLY
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>}
            </div>
        </div>
    )
}

export default Coupons
