import React, { useState } from 'react'
import PaymentPlan from '../Signup/PaymentPlan';
import Offers from '../Signup/Offers';
import Coupons from '../Signup/Coupons';
import backgroundImage from '../../assets/background.jpg';
import { useParams, useLocation } from 'react-router';

const Subscription = () => {
    const [componentName, setComponentName] = useState('paymentplan')
    const [selectedPlan, setSelectedPlan] = useState('');
    const [code, setCode] = useState('');
    const [selectedOffer, setSelectedOffer] = useState({});

    const { uuid } = useParams();
    const location = useLocation();
    const params = location.search.split('=');

    return (
        <div className='flex flex-fill items-center justify-center h-screen overflow-y-auto overflow-x-hidden !py-20' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            {componentName === 'paymentplan' &&
                <PaymentPlan
                    setComponentName={setComponentName}
                    page='inner'
                    setSelectedPlan={setSelectedPlan}
                    selectedPlan={selectedPlan}
                    uuid={uuid}
                />}
            {componentName === 'offers' &&
                <Offers
                    setComponentName={setComponentName}
                    page='inner'
                    selectedPlan={selectedPlan}
                    setCode={setCode}
                    code={code}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    uuid={uuid}
                    referralCode={params.length > 0 ? params[1] : ''}
                />}
            {componentName === 'coupons' && <Coupons
                setComponentName={setComponentName}
                page='inner'
                selectedPlan={selectedPlan}
                setCode={setCode}
                code={code}
                selectedOffer={selectedOffer}
                setSelectedOffer={setSelectedOffer}
                uuid={uuid}
            />}


        </div>
    )
}

export default Subscription;