import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import { insertPricingBasePrice, updatePricingBasePriceByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { carpet_area, plot_area, unit_type } from '../../../utils/project-constant';
import InputRupees from '../../InputGroup/InputRupees';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


const AddBasePriceModal = ({ show, closeModal, modalClass, uuid, configurations, getBasePriceData, selectedPrice }) => {
    const [unitTypeAvailble, setUnitTypeAvailable] = useState();
    const [unitConfigurationAvailable, setUnitConfigurationAvailble] = useState();
    const [rateValue, setRateValue] = useState('');
    const [rateUnit, setRateUnit] = useState('');

    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            const unitType = unit_type.filter(item => selectedPrice.unit_type === item.value);
            const unitConfig = configurations.find(item => selectedPrice.config === item.value);
            setUnitTypeAvailable(unitType);
            setUnitConfigurationAvailble(unitConfig);
            setRateValue(selectedPrice?.rate);
            setRateUnit(carpet_area.find(item => item.label === selectedPrice?.rate_unit));
        }
    }, [selectedPrice]);

    const handleSubmit = async () => {
        const payload = {
            unit_type: unitTypeAvailble.value,
            unit_configuration: unitConfigurationAvailable.value,
            rate: rateValue || null,
            rate_unit: rateUnit.label
        }
        let res = {};
        if (selectedPrice?.uuid) {
            res = await updatePricingBasePriceByUUID(selectedPrice.uuid, payload)
        } else {
            res = await insertPricingBasePrice(uuid, payload);
        }

        if (res.data.status === 200) {
            toast.success(res.data.message);
            closeModal();
            getBasePriceData();
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add Base</h1>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>
            <form action="" className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>

                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Unit Type Applicability'
                    // isMulti={true}
                    options={unit_type}
                    value={unitTypeAvailble}
                    onChange={(value) => setUnitTypeAvailable(value)}
                />
                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Unit Configuration Applicability'
                    value={unitConfigurationAvailable}
                    options={configurations}
                    onChange={(value) => setUnitConfigurationAvailble(value)}
                // isMulti={true}
                />
                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Rate
                    </label>
                    <div className='flex gap-x-5 [&>*]:w-6/12'>
                        <InputRupees
                            inputclass='!mt-0'
                            placeholder='Enter Rate'
                            styles={customStyles}
                            value={rateValue}
                            onChange={(value) => setRateValue(value)}
                            rupayclass='top-4 left-3'
                            handleBlur={() => { }}

                        />
                        <InputSelect
                            inputclass='!mt-0'
                            styles={customStyles}
                            value={rateUnit}
                            onChange={(value) => setRateUnit(value)}
                            options={plot_area}
                            handleBlur={() => { }}
                        />
                    </div>

                </div>


            </form>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={closeModal}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddBasePriceModal
