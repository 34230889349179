import moment from 'moment'
import React from 'react'
import { toINRFormat } from '../../../helpers/helpers'

const CustomerDetail = ({ bookingDetails }) => {
    const { customer, coapplicant } = bookingDetails
    return (
        <div className='bg-white !mr-2.5 !border !border-grayLight shadow-boxshadow flex flex-col gap-y-8 !px-5 !py-5 rounded-lg'>
            <div>
                <h1 className='text-xl text-textblack font-semibold leading-7 !mb-4'>Customer details</h1>

                <div className='flex flex-wrap gap-y-4'>
                    {customer?.customer_name && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Name</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_name || '-'} </h3>
                        {/* <p className='inte text-sm text-black700 leading-5 m-0'>Profession</p> */}
                    </div>}
                    {customer?.customer_mother_name && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Mother Name</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_mother_name || '-'}</h3>
                    </div>}

                    {customer?.customer_mobile && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Mobile Number</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_mobile || '-'}</h3>
                    </div>}
                    {customer?.customer_company_name && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Company Name</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_company_name || '-'}</h3>
                    </div>}
                    {customer?.customer_religion && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Religion</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_religion || '-'}</h3>
                    </div>}
                    {customer?.customer_email && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Email</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_email || '-'}</h3>
                    </div>}
                    {customer?.customer_designation && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Designation</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_designation || '-'}</h3>
                    </div>}
                    {customer?.customer_nationality && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Nationality</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_nationality || '-'}</h3>
                    </div>}
                    {/* <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>25</h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Age</p>
                    </div> */}
                    {customer?.customer_income && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Annual Income</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{`₹ ${toINRFormat(customer?.customer_income)}`}</h3>
                    </div>}

                    {customer?.customer_dob && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Date Of Birth</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_dob ? moment(customer?.customer_dob).format('Do MMM YYYY') : '-'}</h3>
                    </div>}
                    {customer?.customer_father_name && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Father Name</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_father_name || '-'}</h3>
                    </div>}
                    {customer?.customer_aadhaar_no && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Aadhar Number</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_aadhaar_no || '-'}</h3>
                    </div>}
                    {customer?.customer_permanent_address && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Permanent Address</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_permanent_address || '-'} </h3>
                    </div>}
                    {customer?.customer_pan_no && <div className='w-4/12'>
                        <p className='inte text-sm text-black700 leading-5 m-0'>PAN Number</p>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{customer?.customer_pan_no || '-'}</h3>
                    </div>}
                </div>
            </div>
            <div>
                {
                    coapplicant.map((item, index) => (
                        <>
                            <h1 className='text-xl text-textblack font-semibold leading-7 !mb-4'>Co-applicant {index + 1}</h1>
                            <div className='flex flex-wrap gap-y-4'>
                                {item?.name && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Customer Name</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1 capitalize'>{item?.name || '-'} </h3>
                                </div>}
                                {item?.profession && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Profession</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1 capitalize'>{item?.profession || '-'} </h3>
                                </div>}
                                {item?.mother_name && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Mother Name</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{item?.mother_name || '-'} </h3>
                                </div>}
                                {item?.mobile && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Mobile Number</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{item?.mobile || '-'} </h3>
                                </div>}
                                {item?.company_name && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Company Name</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1 capitalize'>{item?.company_name || '-'} </h3>
                                </div>}
                                {item?.religion && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Religion</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{item?.religion || '-'} </h3>
                                </div>}
                                {item?.email && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Email</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>{item?.email || '-'} </h3>
                                </div>}
                                {item?.designation && <div className='w-4/12'>
                                    <p className='inte text-sm text-black700 leading-5 m-0'>Designation</p>
                                    <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1 capitalize'>{item?.designation || '-'} </h3>
                                </div>}
                            </div>
                        </>
                    ))
                }
            </div>
            {/* {<div>
                <h1 className='text-xl text-textblack font-semibold leading-7 !mb-4'>Co-applicant 2</h1>
                <div className='flex flex-wrap gap-y-4'>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Keshav Raj </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Customer Name</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Real Estate </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Profession</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Manjula I </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Mother Name</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>+91 8248961122 </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Mobile Number</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Propacity Proptech PVT LTD </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Company Name</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Hindu </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Religion</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Keshavraj6302@gmail.com </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Email</p>
                    </div>
                    <div className='w-4/12'>
                        <h3 className='text-base inter text-textblack font-semibold leading-6 !mb-1'>Product Designer </h3>
                        <p className='inte text-sm text-black700 leading-5 m-0'>Designation</p>
                    </div>
                </div>
            </div>} */}
        </div>

    )
}

export default CustomerDetail
