import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';

import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import DateTimePicker from '../../InputGroup/DateTime';
import InputTextarea from '../../InputGroup/InputTextarea';
import { ReactComponent as Uploadicon } from '../../../assets/icons/uploadicon.svg';
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as FilePdf } from '../../../assets/icons/FilePdf.svg';
import { ReactComponent as DownICon } from '../../../assets/icons/Down.svg';
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import pdfimg from '../../../assets/icons/pdfview.jpg';
import { getUnits, insertEOIDone } from '../../../services/private/inventory.service';
import { getProjectsForFilter } from '../../../services/private/leads.service';
import Consumer from '../../../helpers/context'
import DatePicker from 'react-datepicker';
import { ReligionList, SalutationList, CountryList, PaymentModeList, customerDocList, coapplicantDocs, CustomerDocumentList, RelationList, PaidBy, BankList } from '../../../helpers/enums';
import { validationSchema } from './BasicDetailValidation';
import MobileInput from '../../InputGroup/MobileInput';
import { uploadFile } from '../../../services/public/public.service';
import style from '../../../modules/Settings//Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import InputRupees from '../../InputGroup/InputRupees';
import moment from 'moment';
import { toast } from 'react-toastify';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const Purpose = [
    {
        value: 'End use',
        label: 'End use'
    },
    {
        value: 'Investment',
        label: 'Investment'
    }
]

let customerDoc = [...customerDocList, {
    fileName: 'EOI Form',
    type: '',
    url: '',
    size: '',
    is_private: false,
    extension: '',
    file_type: '',
    category: 'EOI Form',
    showCategory: false
},]

const EOIBasicDetail = ({ setSteps, setEOIId, setSelectedProject, lead, data, closeModal, selectedBooking, selectedPaymentPricing, userEmail }) => {
    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);
    const [coapplicant, setCoapplicant] = useState([{}]);
    const [paymentProof, setPaymentProof] = useState([{}]);
    const [projectList, setProjectList] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const [sameAsCustomerDetails, setSameAsCustomerDetails] = useState(false);
    const [sameAsCommunication, setSameAsCommunication] = useState(false);
    const [pdcs, setPdcs] = useState([{}]);
    const [customerDocuments, setCustomerDocuments] = useState([]);
    const [paymentDocs, setPaymentDocs] = useState({});
    const [coApplicantUploadedDocs, setCoapplicantDocs] = useState({});
    const [pdcsDoc, setPdcsDoc] = useState({});
    const [sameAsCustomerCommunication, setSameAsCustomerCommunication] = useState(false);
    const [needCoapplicant, setNeedCoapplicant] = useState(false);
    const [needBuyer, setNeedBuyer] = useState(false);
    const [isProjectUnitAvailable, setIsProjectUnitAvailable] = useState(false);

    let { companyConfig, allowedPermissions } = useContext(Consumer);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            customer_salutation: {
                label: 'MR',
                value: 'MR'
            },
            customer_dob: '',
            customer_father_name: '',
            customer_mother_name: '',
            customer_nationality: {
                label: 'Indian',
                value: 'indian'
            },
            customer_religion: '',
            customer_email: '',
            customer_mobile: '',
            customer_alternate_mobile: '',
            customer_profession: '',
            customer_communication_address: '',
            customer_permanent_address: '',
            customer_landmark: '',
            project: '',
            lead_source: '',
            scheduleDate: '',
            followupScheduleDate: '',
            validity: '',
            customer_company_name: '',
            customer_income: '',
            customer_designation: '',
            annual_income: '',
            customer_purpose: '',
            customer_office_mobile: '',
            customer_office_address: '',
            buyer_name: '',
            buyer_email: '',
            buyer_mobile: '',
            buyer_landmark: '',
            buyer_permanent_address: '',
            buyer_communication_address: '',
            buyer_dob: '',
            account_no: '',
            bank_name: '',
            ifsc_code: '',
            branch: '',
            swift_code: '',
            nre_account_no: '',
            nro_account_no: '',
            fcnr_account_no: '',
            cheque_no: '',
            cheque_date: '',
            document_id: '',
            cheque_amount: '',
            remarks: '',

        },
        resolver: yupResolver(validationSchema)
    });

    const name = watch('customer_name');
    const mobile = watch('customer_mobile');
    const email = watch('customer_email');
    const landmark = watch('customer_landmark');
    const communicationAddress = watch('customer_communication_address');
    const permanentAddress = watch('customer_permanent_address');
    const buyerCommunicationAddress = watch('buyer_communication_address');
    const project = watch('project');

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ ...item, label: item.name, value: item.id }))
            setProjectList(mapped);
        }
    }

    const getUnitDetails = async () => {
        const selectedProj = projectList.find(item => item.value === parseInt(project))
        const payload = {
            query: {
                searchText: ''
            }
        }
        const res = await getUnits(selectedProj?.uuid, 500, 1, payload);
        if (res.data.status === 200) {
            // console.log(res.data.data.units, 'units');
            if (res.data.data.units?.length > 0) {
                setIsProjectUnitAvailable(true)
            } else {
                toast.error('No Units available for this project.')
            }
        }
    }

    useEffect(() => {
        if (project) {
            getUnitDetails()
        }
    }, [project])

    useEffect(() => {
        if (selectedPaymentPricing) {
            const validity = selectedPaymentPricing?.validity || 30
            const validityUnit = selectedPaymentPricing?.validity_unit || 'days'
            const validityDate = new Date(moment().add(validity, validityUnit)).toISOString();
            setValue('validity', validityDate)
        }
    }, [selectedPaymentPricing]);

    // Prefill Customer previous details on edit EOI
    useEffect(() => {
        if (selectedBooking && Object.keys(selectedBooking).length > 0) {
            const { customer, coapplicant, customerBankDetails, booking, project, followup, customerDocuments, transactions } = selectedBooking;
            reset({
                customer_name: customer?.customer_name,
                customer_dob: customer?.customer_dob,
                customer_father_name: customer?.customer_father_name,
                customer_mother_name: customer?.customer_mother_name,
                customer_religion: customer?.customer_religion ? ReligionList.find(item => item.value === customer.customer_religion) : '',
                customer_email: customer?.customer_email,
                customer_mobile: customer?.customer_mobile || '',
                customer_alternate_mobile: customer?.customer_alternate_mobile || '',
                customer_profession: customer?.customer_profession,
                customer_communication_address: customer?.customer_communication_address,
                customer_permanent_address: customer?.customer_permanent_address,
                customer_landmark: customer?.customer_landmark,
                project: project?.id,
                lead_source: customer?.lead_source,
                scheduleDate: customer?.scheduleDate,
                customer_company_name: customer?.customer_company_name,
                customer_income: customer?.customer_income,
                customer_designation: customer?.customer_designation,
                annual_income: customer?.annual_income,
                customer_purpose: customer?.customer_purpose,
                customer_office_mobile: customer?.customer_office_mobile || '',
                customer_office_address: customer?.customer_office_address,
                buyer_name: customer?.buyer_name,
                buyer_email: customer?.buyer_email,
                buyer_mobile: customer?.buyer_mobile,
                buyer_landmark: customer?.buyer_landmark,
                buyer_permanent_address: customer?.buyer_permanent_address,
                buyer_dob: customer?.buyer_dob,
                buyer_communication_address: customer?.buyer_communication_address,
                account_no: customerBankDetails?.account_no,
                bank_name: customerBankDetails?.bank_name,
                ifsc_code: customerBankDetails?.ifsc_code,
                branch: customerBankDetails?.branch,
                swift_code: customerBankDetails?.swift_code,
                nre_account_no: customerBankDetails?.nre_account_no,
                nro_account_no: customerBankDetails?.nro_account_no,
                fcnr_account_no: customerBankDetails?.fcnr_account_no,
                cheque_no: customerBankDetails?.cheque_no,
                cheque_date: customerBankDetails?.cheque_date,
                document_id: customerBankDetails?.document_id,
                cheque_amount: customerBankDetails?.cheque_amount,
                customer_aadhaar_no: customer?.customer_aadhaar_no,
                customer_pan_no: customer?.customer_pan_no,
                customer_passport_no: customer?.customer_passport_no,
                scheduleDate: booking?.schedule_date,
                followupScheduleDate: followup?.schedule_date,
                validity: booking?.validity,
                remarks: customer?.remarks
            })
            setCoapplicant(coapplicant?.length > 0 ? coapplicant : [{}]);
            setNeedCoapplicant(coapplicant?.length > 0 ? true : false);
            setCustomerDocuments(customerDocuments ? customerDocuments : []);
            // setPaymentProof(transactions.length > 0 ? transactions : [{}])
            // transactions?.forEach((item, index) => {
            //     let payment = { ...paymentDocs };
            //     payment[index] = item.documents;
            //     setPaymentDocs(payment);
            // })
            coapplicant?.forEach((item, index) => {
                let copplicant = { ...coApplicantUploadedDocs };
                copplicant[index] = item.documents;
                setCoapplicantDocs(copplicant);
            })
            // console.log({ customerDocuments });
            customerDocuments?.forEach(item => {
                customerDoc.forEach((doc, index) => {
                    // if (doc.fileName === item.fileName) {
                    //     customerDoc[index] = item
                    // }
                    if (doc.fileName === item?.meta) {
                        customerDoc[index] = {
                            fileName: item.meta,
                            type: item.file_type,
                            url: item.url,
                            size: item.size,
                            is_private: false,
                            extension: item.extension,
                            file_type: item.file_type,
                            category: item.category,
                            showCategory: false
                        }
                    }
                    if (item?.docType === 'other') {
                        customerDoc.push(item);
                    }
                })
            })
        }
    }, [selectedBooking])

    useEffect(() => {
        if (project) {
            setSelectedProject(projectList.find(item => item.value === parseInt(project)))
        }
    }, [project]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            if (errors?.scheduleDate) {
                toast.error(errors?.scheduleDate?.message)
            }
            if (errors?.customer_name) {
                toast.error(errors?.customer_name?.message)
            }
            if (errors?.customer_mobile) {
                toast.error(errors?.customer_mobile?.message)
            }
            if (errors?.project) {
                toast.error(errors?.project?.message)
            }
            if (errors?.validity) {
                toast.error(errors?.validity?.message)
            }
            if (errors?.lead_source) {
                toast.error(errors?.lead_source?.message)
            }
            if (errors?.followupScheduleDate) {
                toast.error(errors?.followupScheduleDate?.message)
            }
        }
    }, [errors]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            reset({
                customer_name: data.name,
                customer_email: data.email || userEmail,
                customer_mobile: data.mobile,
                customer_alternate_mobile: data.alternate_mobile,
                lead_source: data.source,
                // project: projectList.length > 0 ? projectList.find(p => p.name === data.project[0]).id : ''
            },
                { keepDefaultValues: true }
            )
        }
    }, [data, userEmail, projectList]);

    useEffect(() => {
        customerDoc.map(item => item.url = '')
        getProjects();
    }, []);

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setSourceList(companyConfig.Source)
            // setProjectList(companyConfig.Project)
        }
    }, [companyConfig]);

    const handleCustomerDocumentUpload = async (e, fileName, index, state, setState) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: fileName,
                meta: fileName,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: customerDoc[index].category || ''
            }
            const stateIndex = state.findIndex(f => f.fileName === fileName)
            if (stateIndex > -1) {
                let docState = [...state];
                docState[stateIndex] = newFile;
                setState(docState);
            } else {
                setState([...state, newFile])
            }

            customerDoc[index] = newFile
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }

    const handleUploadOtherDocs = async (e, state, setState) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: '',
                docType: 'other',
                showCategory: true
            }
            setState([...state, newFile])
            customerDoc.push(newFile)
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }

    const uploadFileToS3 = async (file, fileIndex, docs, setDocs) => {
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }
            let newDocs = { ...docs };
            if (!newDocs[fileIndex]) {
                newDocs[fileIndex] = [];
                newDocs[fileIndex].push(newFile);
            } else {
                newDocs[fileIndex].push(newFile);
            }
            setDocs(newDocs);

        }
    }

    const handleOnChange = async (e, fileIndex, docs, setDocs) => {
        const files = [...e.target.files];
        files.forEach((file, index) => {
            uploadFileToS3(file, fileIndex, docs, setDocs);
        });
    }

    const handleChangePDC = async (e, fileIndex, docs, setDocs) => {
        uploadFileToS3(e.target.files[0], fileIndex, docs, setDocs);
    }
    // const handleUploadCoapplicantOtherDocs = async (e, fileName, fileIndex, index, state, setState) => {
    //     const file = e.target.files[0];
    //     const formData = new FormData();
    //     formData.append("file", file)
    //     const response = await uploadFile(formData);
    //     if (response.status === 200) {
    //         const newFile = {
    //             name: file.name,
    //             fileName: file.name,
    //             type: file.type,
    //             url: response.data.data.s3_url,
    //             size: file.size,
    //             is_private: false,
    //             extension: file.name.split('.').pop(),
    //             file_type: file.type,
    //             category: ''
    //         }
    //         let newDocs = { ...state };
    //         if (!newDocs[fileIndex]) {
    //             newDocs[fileIndex] = [];
    //             newDocs[fileIndex].push(newFile);
    //         } else {
    //             newDocs[fileIndex].push(newFile);
    //         }
    //         setState(newDocs)
    //         coapplicantDocs.push = newFile
    //         // if (!(selectedIndex >= 0)) {
    //         //     updateMediaFile(newFile);
    //         // }

    //     }
    // }

    const handleCoapplicantDocumentUpload = async (e, fileName, fileIndex, index, state, setState, docType) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: fileName || file.name,
                ...(!docType) && { meta: fileName },
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: index >= 0 ? coapplicantDocs[index].category : '',
                docType: docType
            }
            let newDocs = { ...state };
            if (!newDocs[fileIndex]) {
                newDocs[fileIndex] = [];
                newDocs[fileIndex].push(newFile);
            } else {
                newDocs[fileIndex].push(newFile);
            }
            setState(newDocs)
            if (index >= 0) {
                coapplicantDocs[index] = newFile
            } else {
                newFile.docType = 'other'
                coapplicantDocs.push(newFile)
            }
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }

    const handleChangeCustomerDetail = () => {
        setSameAsCustomerDetails(!sameAsCustomerDetails);
        if (!sameAsCustomerDetails) {
            setValue('buyer_name', name)
            setValue('buyer_email', email)
            setValue('buyer_mobile', mobile)
            setValue('buyer_landmark', landmark)
            setValue('buyer_permanent_address', permanentAddress)
            setValue('buyer_communication_address', communicationAddress)
        } else {
            setValue('buyer_name', null)
            setValue('buyer_email', null)
            setValue('buyer_mobile', null)
            setValue('buyer_landmark', null)
            setValue('buyer_permanent_address', null)
            setValue('buyer_communication_address', null)
        }
    }


    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };
    const spanClassName = isSpanClassAdded ? 'rotate-180' : '';

    const onFormSubmit = async (values) => {
        const selectedProj = projectList.find(item => item.value === parseInt(values.project))
        // let transaction = paymentProof.filter(item => Object.keys(item).length > 0);
        let coapplicantData = coapplicant.filter(item => Object.keys(item).length > 0)

        // transaction.forEach((item, index) => {
        //     item.documents = paymentDocs[index]
        // })
        coapplicantData.forEach((item, index) => {
            item.documents = coApplicantUploadedDocs[index]
        })
        const payload = {
            scheduleDate: new Date(values.scheduleDate),
            projectId: values.project,
            validity: new Date(values.validity),
            followupScheduleDate: new Date(values.followupScheduleDate),

            customer: {
                lead_source: values.lead_source || null,
                customer_salutation: values.customer_salutation.value || null,
                customer_name: values.customer_name || null,
                customer_email: values.customer_email || null,
                customer_mobile: values.customer_mobile || null,
                customer_alternate_mobile: values.customer_alternate_mobile || null,
                customer_communication_address: values.customer_communication_address || null,
                customer_permanent_address: values.customer_permanent_address || null,
                customer_landmark: values.customer_landmark || null,
                customer_nationality: values.customer_nationality.value || null,
                customer_religion: values.customer_religion.value || null,
                customer_dob: values.customer_dob || null,
                customer_father_name: values.customer_father_name || null,
                customer_mother_name: values.customer_mother_name || null,
                customer_profession: values.customer_profession || null,
                customer_company_name: values.customer_company_name || null,
                customer_income: values.customer_income || null,
                customer_designation: values.customer_designation || null,
                customer_office_address: values.customer_office_address || null,
                customer_office_mobile: values.customer_office_mobile || null,
                customer_purpose: values.customer_purpose || null,
                buyer_salutation: values.buyer_salutation?.value || null,
                buyer_name: values.buyer_name || null,
                buyer_email: values.buyer_email || null,
                buyer_mobile: values.buyer_mobile || null,
                buyer_communication_address: values.buyer_communication_address || null,
                buyer_permanent_address: values.buyer_permanent_address || null,
                buyer_landmark: values.buyer_landmark || null,
                buyer_dob: values.buyer_dob || null,
                buyer_relation: values.buyer_relation?.value || null,
                documents: customerDocuments,
                remarks: values.remarks
            },
            customerBankDetails: {
                account_no: values.account_no || null,
                bank_name: values.bank_name || null,
                ifsc_code: values.ifsc_code || null,
                branch: values.branch || null,
                swift_code: values.swift_code || null,
                nre_account_no: values.nre_account_no || null,
                nro_account_no: values.nro_account_no || null,
                fcnr_account_no: values.fcnr_account_no || null,
            },
            coapplicant: coapplicantData.length > 0 ? coapplicantData : null,
            // transaction: transaction.length > 0 ? transaction : null
        }
        const res = await insertEOIDone(lead.uuid || lead.id, payload)
        if (res.data.status === 200) {
            setSelectedProject(selectedProj);
            setEOIId(res.data.data.id);
            if (isProjectUnitAvailable) {
                setSteps('EOIUnitDetail');
            } else {
                toast.error('No Units available for this project.')
            }
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onFormSubmit)} id='eoi-unit' className='!pt-5 md:!px-6 !px-4 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                <div id="accordion">
                    <div className="">
                        <div
                            className="flex items-center justify-between cursor-pointer"
                            id="headingOne"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            onClick={toggleSpanClass}
                        >
                            <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                Customer Details
                            </h5>
                            <span className={`${isSpanClassAdded ? '' : 'rotate-180'}`}><DownICon /></span>
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !mt-3'>
                                <div>
                                    <Controller
                                        control={control}
                                        name='project'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Project'
                                                    placeholder='Select Project'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    value={value ? projectList.find(item => item.value === value) : ''}
                                                    onChange={(value) => onChange(value.value)}
                                                    options={projectList}
                                                    error={errors?.project ? errors?.project.message : ''}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='scheduleDate'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <div className={`form-group ${errors?.scheduleDate ? 'invalid' : ''}  position-relative`}>
                                                {/* <DateTimePicker
                                                    label='Token Date'
                                                    inputclass='mt-0'
                                                    value={value}
                                                    onValueChange={onChange}
                                                    error={errors?.scheduleDate ? errors?.scheduleDate.message : ''}
                                                    required={true}
                                                /> */}
                                                <label>Token Date <sup>*</sup></label>
                                                <DatePicker
                                                    showTimeSelect={false}
                                                    selected={value ? new Date(value) : ''}
                                                    timeFormat="h:mm a"
                                                    placeholderText={'Select Date'}
                                                    minDate={allowedPermissions.includes('past_date_booking_eoi') ? '' : new Date(moment().subtract(5, 'd'))}
                                                    onChange={onChange}
                                                    dateFormat={"dd MMM yyyy "}
                                                    className={`form-control text-input ${errors?.scheduleDate ? 'error' : ''}`}
                                                    maxDate={new Date()}
                                                />
                                                {errors?.scheduleDate && (
                                                    <>
                                                        <div className="input-feedback mt-8">{errors?.scheduleDate.message}
                                                        </div>
                                                    </>

                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='validity'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <div className={`form-group ${errors?.validity ? 'invalid' : ''}  position-relative`}>
                                                {/* <DateTimePicker
                                                    inputclass='mt-0'
                                                    value={value}
                                                    onValueChange={onChange}
                                                    showTime={false}
                                                    error={errors?.validity ? errors?.validity.message : ''}
                                                    required={true}
                                                /> */}
                                                <label>Validity Date <sup>*</sup></label>
                                                <DatePicker
                                                    showTimeSelect={false}
                                                    selected={value ? new Date(value) : ''}
                                                    timeFormat="h:mm a"
                                                    placeholderText={'Select Date and Time'}
                                                    minDate={new Date()}
                                                    onChange={onChange}
                                                    dateFormat={"dd MMM yyyy "}
                                                    className={`form-control text-input ${errors?.validity ? 'error' : ''}`}
                                                // maxDate={completed ? new Date() : ''}
                                                />
                                                {errors?.validity && (
                                                    <>
                                                        <div className="input-feedback mt-8">{errors?.validity.message}
                                                        </div>
                                                    </>

                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='followupScheduleDate'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <DateTimePicker
                                                    label='Followup Date'
                                                    inputclass='mt-0'
                                                    value={value}
                                                    onValueChange={onChange}
                                                    error={errors?.followupScheduleDate ? errors?.followupScheduleDate.message : ''}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='remarks'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <InputTextarea
                                                type="text"
                                                label="Followup Comments"
                                                placeholder="Enter your comments..."
                                                name="name"
                                                inputclass='m-0 inter'
                                                row='2'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <label className='text-sm text-black700 !mb-2'>Name<sup>*</sup></label>
                                    <div className='d-flex gap-2'>
                                        <div>
                                            <Controller
                                                control={control}
                                                name='customer_salutation'
                                                render={({
                                                    field: { onChange, onBlur, value, ref },
                                                }) => (
                                                    <>
                                                        <InputSelect
                                                            placeholder='MR'
                                                            isMulti={false}
                                                            inputclass='w-[90px] basis-[90px] shrink-0	'
                                                            styles={customStyles}
                                                            options={SalutationList}
                                                            onChange={onChange}
                                                            value={value}
                                                        />

                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Controller
                                                control={control}
                                                name='customer_name'
                                                render={({
                                                    field: { onChange, onBlur, value, ref },
                                                }) => (
                                                    <>
                                                        <InputText
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            onChange={onChange}
                                                            value={value}
                                                            error={errors?.customer_name ? errors?.customer_name.message : ''}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_email'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Customer Email Id"
                                                    name="name"
                                                    label="Email"
                                                    inputclass='m-0 inter'
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_mobile'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <MobileInput
                                                    type="text"
                                                    label="Mobile"
                                                    placeholder="Enter Customer Mobile Number"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e.phone)
                                                    }}
                                                    error={errors?.mobile ? errors?.mobile.message : ''}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_alternate_mobile'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <MobileInput
                                                    type="text"
                                                    label="Alternate Mobile No."
                                                    placeholder="Enter Alternate Mobile No."
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e.phone)
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='lead_source'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Source'
                                                    placeholder='Select Source'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    value={value ? sourceList.find(item => item.value === value) : ''}
                                                    onChange={(value) => onChange(value.value)}
                                                    options={sourceList}
                                                    error={errors?.lead_source ? errors?.lead_source.message : ''}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_communication_address'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputTextarea
                                                    type="text"
                                                    label="Communication Address"
                                                    placeholder="Enter Communication Address"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    row='2'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <div className='!mb-2 flex items-center justify-between leading-[22px] flex-wrap'>
                                        <span className='text-sm text-black700 inter'>Permanent Address</span>
                                        <div className='flex items-center text-sm text-black700 inter'>
                                            <input type="checkbox"
                                                id='customer_address'
                                                value={sameAsCustomerCommunication}
                                                onChange={() => {
                                                    setSameAsCustomerCommunication(!sameAsCustomerCommunication)
                                                    if (!sameAsCustomerCommunication) {
                                                        setValue('customer_permanent_address', communicationAddress)
                                                    } else {
                                                        setValue('customer_permanent_address', '')
                                                    }
                                                }}
                                                className='!mr-2 ' />
                                            <label htmlFor='customer_address' className='!p-1 !border !border-grayLight rounded-md inter m-0'>Same as Communication</label>
                                        </div>
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='customer_permanent_address'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputTextarea
                                                        type="text"
                                                        // label="Permanent Address"
                                                        placeholder="Enter Permanent Address"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        row='2'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_landmark'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Nearby Landmark"
                                                    placeholder="Enter Nearby Landmark"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_nationality'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Nationality'
                                                    placeholder='Select Nationality'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    onChange={onChange}
                                                    value={value}
                                                    options={CountryList}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_religion'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Religion'
                                                    placeholder='Select Religion'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    options={ReligionList}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_dob'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                {/* <DateTimePicker
                                                    label='Date of Birth'
                                                    inputclass='mt-0'
                                                    value={value}
                                                    onValueChange={onChange}
                                                /> */}
                                                <div className='form-group'>
                                                    <label>Date of Birth</label>
                                                    <DatePicker
                                                        selected={value ? new Date(value) : ''}
                                                        placeholderText={'Select Date'}
                                                        onChange={onChange}
                                                        dateFormat="dd MMM yyyy"
                                                        className={`form-control text-input`}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_father_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Father Name"
                                                    placeholder="Enter Father Name"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_mother_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Mother Name"
                                                    placeholder="Enter Mother Name"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_profession'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Profession"
                                                    placeholder="Enter Profession"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_company_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Company Name"
                                                    placeholder="Enter Company Name"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_income'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputRupees
                                                    type="text"
                                                    label="Annual Income"
                                                    placeholder="Enter Income"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                    handleBlur={() => { }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_designation'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Designation"
                                                    placeholder="Enter Designation"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                {/* <div>
                                    <Controller
                                        control={control}
                                        name='annual_income'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Annual Income"
                                                    placeholder="Enter Annual Income"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div> */}
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_purpose'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    type="text"
                                                    label="Purchase Purpose"
                                                    placeholder="Enter Purchase Purpose"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value ? Purpose.find(item => item.value === value) : ''}
                                                    options={Purpose}
                                                    onChange={(value) => { onChange(value.value) }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_office_mobile'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <MobileInput
                                                    type="text"
                                                    label="Office Contact No."
                                                    placeholder="Enter Office Contact No."
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e.phone)
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                {/* <div>
                                    <Controller
                                        control={control}
                                        name='customer_office_address'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputTextarea
                                                    type="text"
                                                    label="Office Address"
                                                    placeholder="Enter Office Address"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    row='2'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className='!mt-4'>
                        <div className='flex items-center !my-3'>
                            <h2 className='text-textblack text-lg font-semibold inter mr-4'>Do you want to have separate buyer details?</h2>
                            <div className='flex'>
                                <div className='mr-4 cursor-pointer'>
                                    <input
                                        type="radio"
                                        id='buyer_yes'
                                        name='buyer'
                                        className='mr-2'
                                        value='yes'
                                        checked={needBuyer}
                                        onChange={() => setNeedBuyer(true)}
                                    />
                                    <label className='cursor-pointer font-semibold' htmlFor="buyer_yes">Yes</label>
                                </div>
                                <div >
                                    <input
                                        type="radio"
                                        id='buyer_no'
                                        name='buyer'
                                        value='no'
                                        className='mr-2'
                                        checked={!needBuyer}
                                        onChange={() => setNeedBuyer(false)}
                                    />
                                    <label className='cursor-pointer font-semibold' htmlFor="buyer_no">No</label>
                                </div>
                            </div>
                        </div>
                        {needBuyer && <>
                            <div
                                className="flex items-center justify-between cursor-pointer"
                                id="headingtwo"
                                data-toggle="collapse"
                                data-target="#collapsetwo"
                                aria-expanded="false"
                                aria-controls="collapsetwo"
                                onClick={() => {
                                    console.log(document.getElementById('buyerName'), ' document.getElementById(')
                                    // document.getElementById('buyerName').focus();
                                    window.setTimeout(function () {
                                        document.getElementById('buyerName').focus();
                                    }, 0);
                                    // toggleSpanClass()
                                }}
                            >
                                <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                    Buyer Details
                                </h5>

                                <span
                                // className={`${isSpanClassAdded ? 'rotate-180' : ''}`}
                                ><DownICon /></span>

                            </div>

                            <div id="collapsetwo" className="collapse " aria-labelledby="headingtwo" data-parent="#accordion">
                                <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !mt-4'>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_name'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label="Name"
                                                        placeholder="Enter Buyer Name"
                                                        type="text"
                                                        name="buyerName"
                                                        id="buyerName"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_email'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label="Email"
                                                        placeholder="Enter Buyer Email Id"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_mobile'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <MobileInput
                                                        label="Mobile"
                                                        placeholder="Enter Buyer Mobile Number"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e.phone)
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>

                                        <Controller
                                            control={control}
                                            name='buyer_communication_address'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label='Communication Address'
                                                        placeholder="Enter Communication Address"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div className='!mb-2 flex items-center justify-between leading-[22px] flex-wrap'>
                                            <span className='text-sm text-black700 inter'>Permanent Address</span>
                                            <div className='flex items-center text-sm text-black700 inter'>
                                                <input type="checkbox"
                                                    id='buyer_address'
                                                    value={sameAsCommunication}
                                                    onChange={() => {
                                                        setSameAsCommunication(!sameAsCommunication)
                                                        if (!sameAsCommunication) {
                                                            setValue('buyer_permanent_address', buyerCommunicationAddress)
                                                        } else {
                                                            setValue('buyer_permanent_address', '')
                                                        }
                                                    }} className='!mr-2 ' />
                                                <label htmlFor='buyer_address' className='!p-1 !border !border-grayLight rounded-md inter m-0'>Same as Communication</label>
                                            </div>
                                        </div>
                                        <div>
                                            <Controller
                                                control={control}
                                                name='buyer_permanent_address'
                                                render={({
                                                    field: { onChange, onBlur, value, ref },
                                                }) => (
                                                    <>
                                                        <InputText
                                                            // label="Permanent Address"
                                                            placeholder="Enter Permanent Address"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_landmark'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label="Nearby Landmark"
                                                        placeholder="Enter Nearby Landmark"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_dob'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    {/* <DateTimePicker
                                                    label='Date of Birth'
                                                    inputclass='mt-0'
                                                    value={value}
                                                    onValueChange={onChange}
                                                /> */}
                                                    <div className='form-group'>
                                                        <label>Date of Birth</label>
                                                        <DatePicker
                                                            selected={value ? new Date(value) : ''}
                                                            placeholderText={'Select Date'}
                                                            onChange={onChange}
                                                            dateFormat="dd MMM yyyy"
                                                            className={`form-control text-input`}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            maxDate={new Date()}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_relation'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputSelect
                                                        label="Relation"
                                                        placeholder="Select Relation"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        options={RelationList}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}
                        {/* Customer Bank Details */}
                        <div className='!mt-4'>
                            <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                Customer Bank Details
                            </h5>
                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                <div>
                                    <Controller
                                        control={control}
                                        name='account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="Account No."
                                                    placeholder="Enter Account No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='bank_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label="Bank Name"
                                                    placeholder="Enter Bank Name"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value ? BankList.find(item => item.value === value) : ''}
                                                    onChange={(value) => { onChange(value.value) }}
                                                    options={BankList}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='ifsc_code'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="IFSC Code"
                                                    placeholder="Enter IFSC Code"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='branch'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="Bank Branch"
                                                    placeholder="Enter Bank Branch"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='swift_code'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="Swift Code"
                                                    placeholder="Enter Swift Code"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='nre_account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="NRE A/C No."
                                                    placeholder="Enter NRE A/C No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='nro_account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="NRO A/C No."
                                                    placeholder="Enter NRO A/C No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='fcnr_account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="FCNR A/C No."
                                                    placeholder="Enter FCNR A/C No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Post Date cheques */}
                        {/* <div className="!mt-3">
                            <button
                                type='button'
                                className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'
                                onClick={() => setPdcs([...pdcs, {}])}
                            >
                                <PlusPrimary /> Post Date cheques
                            </button>
                        </div> */}

                        {/* Customer Document */}
                        <div className='!mt-4'>
                            <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                Customer Document
                            </h5>
                            <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                                <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                    onClick={() => {
                                        document.getElementById(`upload-customer-doc`).click();
                                    }}>
                                    <input type="file" className='d-none' id={`upload-customer-doc`} accept="application/pdf, image/*" onChange={(e) => handleUploadOtherDocs(e, customerDocuments, setCustomerDocuments)} />
                                    <figure className='!table !mx-auto'>
                                        <Uploadicon className='h-[64px] mt-2' />
                                    </figure>
                                    <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload More Documents </p>
                                    <p className='text-center !text-black text-sm font-medium inter'>More Documents </p>
                                </div>
                                {
                                    customerDoc.map((doc, index) => (
                                        <>
                                            <input type="file" className='d-none' id={`upload-customer-${index}`} accept="application/pdf, image/*" onChange={(e) => handleCustomerDocumentUpload(e, doc.fileName, index, customerDocuments, setCustomerDocuments)} />
                                            {!doc?.url ? <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-min-[204px] cursor-pointer'
                                                onClick={() => {
                                                    document.getElementById(`upload-customer-${index}`).click();
                                                }}>
                                                <figure className='!table !mx-auto'>
                                                    <Uploadicon className='h-[64px] mt-2' />
                                                </figure>
                                                <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload {doc.fileName} </p>
                                                <p className='text-center !text-black text-sm font-medium inter'> {doc.fileName} </p>
                                            </div> :
                                                <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-min-[204px] relative'>
                                                    <figure className='mb-0 !table !mx-auto relative group'>

                                                        {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                            <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                            : <img src={pdfimg} />}
                                                        <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                            <button
                                                                type='button'
                                                                className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                                onClick={() => {
                                                                    document.getElementById(`upload-customer-${index}`).click();
                                                                }}
                                                            ><PluswhiteIcon />Replace</button>
                                                            <button
                                                                type='button'
                                                                className={`${style.delbtn} inter border-0 bg-white`}
                                                                onClick={() => {
                                                                    let newFiles = [...customerDocuments];
                                                                    newFiles.splice(index, 1);
                                                                    setCustomerDocuments(newFiles);
                                                                    if (customerDoc[index]?.docType === 'other') {
                                                                        customerDoc.splice(index, 1);
                                                                    } else {
                                                                        customerDoc[index].url = ''
                                                                        customerDoc[index].fileName = ''
                                                                        customerDoc[index].type = ''
                                                                        customerDoc[index].size = ''
                                                                        customerDoc[index].extension = ''
                                                                        customerDoc[index].file_type = ''
                                                                    }
                                                                }}
                                                            ><TrashIcon /></button>
                                                        </div>
                                                    </figure>
                                                    <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'>
                                                        {/* <FilePdf />  */}
                                                        {doc.category}
                                                    </p>
                                                    {doc.showCategory && <InputSelect
                                                        // label='Source'
                                                        placeholder='Select Category'
                                                        inputclass='m-0'
                                                        styles={customStyles}
                                                        value={doc.category ? CustomerDocumentList.find(item => item.value === doc.category) : ''}
                                                        options={CustomerDocumentList}
                                                        onChange={(value) => {
                                                            let customers = [...customerDocuments]
                                                            customers.category = value.value
                                                            setCustomerDocuments(customers)
                                                            customerDoc[index].category = value.value
                                                        }}
                                                    />}
                                                    {doc.fileName === 'Aadhar Front' && <div className='mt-2'>
                                                        <Controller
                                                            control={control}
                                                            name='customer_aadhaar_no'
                                                            render={({
                                                                field: { onChange, onBlur, value, ref },
                                                            }) => (
                                                                <>
                                                                    <InputText
                                                                        type="text"
                                                                        placeholder="Enter Aadhaar Number"
                                                                        inputclass='m-0 inter'
                                                                        onChange={(e) => {
                                                                            onChange(e.target.value
                                                                                .replace(/^0+/g, '')
                                                                                .replace(/[^0-9]/g, ''))
                                                                        }}
                                                                        value={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>}
                                                    {doc.fileName === 'PAN' && <div>
                                                        <Controller
                                                            control={control}
                                                            name='customer_pan_no'
                                                            render={({
                                                                field: { onChange, onBlur, value, ref },
                                                            }) => (
                                                                <>
                                                                    <InputText
                                                                        type="text"
                                                                        placeholder="Enter PAN Number"
                                                                        inputclass='m-0 inter'
                                                                        onChange={onChange}
                                                                        value={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>}
                                                    {doc.fileName === 'Booking Form' && <div>
                                                        <Controller
                                                            control={control}
                                                            name='customer_booking_form_no'
                                                            render={({
                                                                field: { onChange, onBlur, value, ref },
                                                            }) => (
                                                                <>
                                                                    <InputText
                                                                        type="text"
                                                                        placeholder="Enter Booking Number"
                                                                        inputclass='m-0 inter'
                                                                        onChange={onChange}
                                                                        value={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>}
                                                    {doc.fileName === 'Passport' && <div>
                                                        <Controller
                                                            control={control}
                                                            name='customer_passport_no'
                                                            render={({
                                                                field: { onChange, onBlur, value, ref },
                                                            }) => (
                                                                <>
                                                                    <InputText
                                                                        type="text"
                                                                        placeholder="Enter Passport Number"
                                                                        inputclass='m-0 inter'
                                                                        onChange={onChange}
                                                                        value={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>}

                                                </div>}
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                        {/* Co applicant  */}
                        <div className='flex items-center !my-3'>
                            <h2 className='text-textblack text-lg font-semibold inter mr-4'>Do you want to add co-applicants?</h2>
                            <div className='flex'>
                                <div className='mr-4 cursor-pointer'>
                                    <input
                                        type="radio"
                                        id='applicant_yes'
                                        name='coapplicant'
                                        className='mr-2'
                                        value='yes'
                                        checked={needCoapplicant}
                                        onChange={() => setNeedCoapplicant(true)}
                                    />
                                    <label className='cursor-pointer font-semibold' htmlFor="applicant_yes">Yes</label>
                                </div>
                                <div >
                                    <input
                                        type="radio"
                                        id='applicant_no'
                                        name='coapplicant'
                                        value='no'
                                        className='mr-2'
                                        checked={!needCoapplicant}
                                        onChange={() => setNeedCoapplicant(false)}
                                    />
                                    <label className='cursor-pointer font-semibold' htmlFor="applicant_no">No</label>
                                </div>
                            </div>
                        </div>
                        {needCoapplicant && <>
                            {
                                coapplicant.map((applicant, index) => (
                                    <React.Fragment key={String(index)}>
                                        <div className='!mt-4' >
                                            <div className='!mb-3 flex justify-between'>
                                                <h5 className=" text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                                    Co-applicant {index + 1}
                                                </h5>
                                                {coapplicant.length > 1 &&
                                                    <button className='border-0 bg-tr ' onClick={() => {
                                                        const applicants = [...coapplicant];
                                                        applicants.splice(index, 1)
                                                        setCoapplicant(applicants);
                                                    }}
                                                    >
                                                        <CloseIcon />
                                                    </button>}
                                            </div>

                                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                                <div>
                                                    <label className='text-sm text-black700 !mb-2'>Name<sup>*</sup></label>
                                                    <div className='d-flex gap-2'>
                                                        <div>
                                                            <InputSelect
                                                                placeholder='MR'
                                                                isMulti={false}
                                                                inputclass='w-[90px] basis-[90px] shrink-0	'
                                                                styles={customStyles}
                                                                options={SalutationList}
                                                                value={applicant.salutation ? SalutationList.find(i => i.value === applicant.salutation) : ''}
                                                                onChange={(value) => {
                                                                    let co_applicant = [...coapplicant];
                                                                    co_applicant[index]['salutation'] = value.value;
                                                                    setCoapplicant(co_applicant)
                                                                }}
                                                            />


                                                        </div>
                                                        <div>

                                                            <InputText
                                                                type="text"
                                                                placeholder="Enter Name"
                                                                name="name"
                                                                value={applicant?.name}
                                                                inputclass='m-0 inter'
                                                                onChange={(e) => {
                                                                    let co_applicant = [...coapplicant];
                                                                    co_applicant[index][e.target.name] = e.target.value;
                                                                    setCoapplicant(co_applicant)
                                                                }}
                                                            />


                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <InputText
                                                    label="Name"
                                                    placeholder="Enter Name"
                                                    type="text"
                                                    name='name'
                                                    
                                                /> */}
                                                <InputText
                                                    label="Email"
                                                    placeholder="Enter Email"
                                                    type="text"
                                                    name='email'
                                                    value={applicant.email}
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                                <MobileInput
                                                    label="Mobile"
                                                    placeholder="Enter Mobile Number"
                                                    type="text"
                                                    name='mobile'
                                                    value={applicant.mobile}
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index]['mobile'] = e.phone;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                                <MobileInput
                                                    label="Alternate Mobile No."
                                                    placeholder="Enter Mobile Number No."
                                                    type="text"
                                                    inputclass='m-0 inter'
                                                    name='alternate_mobile'
                                                    value={applicant?.alternate_mobile}
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index]['alternate_mobile'] = e.phone;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />

                                                <InputText
                                                    label='Communication Address'
                                                    placeholder="Enter Communication Address"
                                                    type="text"
                                                    inputclass='m-0 inter'
                                                    name='communication_address'
                                                    value={applicant?.communication_address}
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                                <div>
                                                    <div className='!mb-2 flex items-center justify-between leading-[22px] flex-wrap'>
                                                        <span className='text-sm text-black700 inter'>Permanent Address</span>
                                                        <div className='flex items-center text-sm text-black700 inter'>
                                                            <input
                                                                type="checkbox"
                                                                className='!mr-2 '
                                                                id={`applicant_${index}`}
                                                                checked={applicant?.sameAsCommunication}
                                                                onChange={() => {
                                                                    let co_applicant = [...coapplicant];
                                                                    co_applicant[index]['sameAsCommunication'] = !co_applicant[index]['sameAsCommunication'];
                                                                    if (co_applicant[index]['sameAsCommunication']) {
                                                                        co_applicant[index].permanent_address = co_applicant[index].communication_address
                                                                    } else {
                                                                        co_applicant[index].permanent_address = ''
                                                                    }
                                                                    setCoapplicant(co_applicant)
                                                                }}
                                                            />
                                                            <label htmlFor={`application_${index}`} className='!p-1 !border !border-grayLight rounded-md inter m-0'>
                                                                Same as Communication
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <InputText
                                                        // label="Permanent Address"
                                                        placeholder="Enter Permanent Address"
                                                        type="text"
                                                        name='permanent_address'
                                                        inputclass='m-0 inter'
                                                        value={applicant?.permanent_address}
                                                        onChange={(e) => {
                                                            let co_applicant = [...coapplicant];
                                                            co_applicant[index][e.target.name] = e.target.value;
                                                            setCoapplicant(co_applicant)
                                                        }}
                                                    />
                                                </div>
                                                <InputText
                                                    label="Nearby Landmark"
                                                    placeholder="Enter Nearby Landmark"
                                                    type="text"
                                                    name='landmark'
                                                    inputclass='m-0 inter'
                                                    value={applicant?.landmark}
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                                <InputSelect
                                                    label='Nationality'
                                                    placeholder='Select Nationality'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    options={CountryList}
                                                    value={applicant?.nationality ? CountryList.find(item => item.value === applicant.nationality) : {
                                                        label: "Indian",
                                                        value: "indian"
                                                    }}
                                                    onChange={(value) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index]['nationality'] = value.value || 'indian';
                                                        setCoapplicant(co_applicant)
                                                    }}

                                                />
                                                <InputSelect
                                                    label='Religion'
                                                    placeholder='Select Religion'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    options={ReligionList}
                                                    value={applicant?.religion ? ReligionList.find(item => item.value === applicant.religion) : ''}
                                                    onChange={(value) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index]['religion'] = value.value;
                                                        setCoapplicant(co_applicant)
                                                    }}

                                                />
                                                <div className='form-group m-0'>
                                                    <label>Date of Birth</label>
                                                    <DatePicker
                                                        selected={applicant?.dob ? new Date(applicant?.dob) : ''}
                                                        placeholderText={'Select Date'}
                                                        onChange={(date) => {
                                                            let co_applicant = [...coapplicant];
                                                            co_applicant[index]['dob'] = date;
                                                            setCoapplicant(co_applicant)
                                                        }}
                                                        dateFormat="dd MMM yyyy"
                                                        className={`form-control text-input`}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                                <InputText
                                                    label="Father Name"
                                                    placeholder="Enter Father Name"
                                                    type="text"
                                                    name='father_name'
                                                    value={applicant?.father_name}
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                                <InputText
                                                    label="Mother Name"
                                                    placeholder="Enter Mother Name"
                                                    type="text"
                                                    name='mother_name'
                                                    value={applicant?.mother_name}
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />



                                                {/* <DateTimePicker
                                            label="Date of Birth"
                                            placeholder="Enter Age"
                                            inputclass='m-0 inter'
                                            value={applicant?.dob}
                                            onValueChange={(date) => {
                                                let co_applicant = [...coapplicant];
                                                co_applicant[index]['dob'] = date;
                                                setCoapplicant(co_applicant)
                                            }}

                                        /> */}

                                                <InputText
                                                    label="Profession"
                                                    placeholder="Enter Profession"
                                                    type="text"
                                                    inputclass='m-0 inter'
                                                    name='profession'
                                                    value={applicant?.profession}
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                                <InputText
                                                    label="Company Name"
                                                    placeholder="Enter Company Name"
                                                    type="text"
                                                    inputclass='m-0 inter'
                                                    name='company_name'
                                                    value={applicant?.company_name}
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                                <InputRupees
                                                    label="Annual Income"
                                                    placeholder="Enter Income"
                                                    type="text"
                                                    inputclass='m-0 inter'
                                                    name='income'
                                                    value={applicant?.income}
                                                    onChange={(value) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index]['income'] = value;

                                                        setCoapplicant(co_applicant)
                                                    }}
                                                    handleBlur={() => { }}
                                                />
                                                <InputText
                                                    label="Designation"
                                                    placeholder="Enter Designation"
                                                    type="text"
                                                    inputclass='m-0 inter'
                                                    name='designation'
                                                    value={applicant?.designation}
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />


                                            </div>
                                        </div>
                                        {/* Co-applicant document */}
                                        <div className='!mt-4'>
                                            <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                                Co-applicant Document
                                            </h5>
                                            <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6	gap-y-4'>
                                                <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                                    onClick={() => {
                                                        document.getElementById(`upload-copplicant-doc`).click();
                                                    }}>
                                                    <input type="file" className='d-none' id={`upload-copplicant-doc`} accept="application/pdf, image/*" onChange={(e) => handleCoapplicantDocumentUpload(e, '', index, '', coApplicantUploadedDocs, setCoapplicantDocs, 'other')} />
                                                    <figure className='!table !mx-auto'>
                                                        <Uploadicon className='h-[64px] mt-2' />
                                                    </figure>
                                                    <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload More Documents </p>
                                                    <p className='text-center !text-black text-sm font-medium inter'>More Documents </p>
                                                </div>
                                                {
                                                    coapplicantDocs.map((doc, docIndex) => (
                                                        <>
                                                            <input type="file" className='d-none' id={`upload-coapplicant-${docIndex}`} accept="application/pdf, image/*" onChange={(e) => handleCoapplicantDocumentUpload(e, doc.fileName, index, docIndex, coApplicantUploadedDocs, setCoapplicantDocs, '')} />
                                                            {!doc?.url ? <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                                                onClick={() => {
                                                                    document.getElementById(`upload-coapplicant-${docIndex}`).click();
                                                                }}>
                                                                <figure className='!table !mx-auto'>
                                                                    <Uploadicon className='h-[64px] mt-2' />
                                                                </figure>
                                                                <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload {doc.fileName} </p>
                                                                <p className='text-center !text-black text-sm font-medium inter'> {doc.fileName} </p>
                                                            </div> :
                                                                <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                                                    <figure className='mb-0 !table !mx-auto relative group'>
                                                                        {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                                            <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                                            : <img src={pdfimg} />}
                                                                        <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                                            <button
                                                                                type='button'
                                                                                className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                                                onClick={() => {
                                                                                    document.getElementById(`upload-coapplicant-${docIndex}`).click();
                                                                                }}
                                                                            ><PluswhiteIcon />Replace</button>

                                                                            <button
                                                                                type='button'
                                                                                className={`${style.delbtn} inter border-0 bg-white`}
                                                                                onClick={() => {
                                                                                    let newFiles = [...coApplicantUploadedDocs];
                                                                                    delete newFiles[index]
                                                                                    setCoapplicantDocs(newFiles);
                                                                                    if (coapplicantDocs[docIndex]?.docType === 'other') {
                                                                                        coapplicantDocs.splice(docIndex, 1)
                                                                                    } else {
                                                                                        coapplicantDocs[docIndex].url = ''
                                                                                        coapplicantDocs[docIndex].fileName = ''
                                                                                        coapplicantDocs[docIndex].type = ''
                                                                                        coapplicantDocs[docIndex].size = ''
                                                                                        coapplicantDocs[docIndex].extension = ''
                                                                                        coapplicantDocs[docIndex].file_type = ''
                                                                                    }
                                                                                }}
                                                                            ><TrashIcon /></button>
                                                                        </div>
                                                                    </figure>
                                                                    <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'>
                                                                        {/* <FilePdf />  */}
                                                                        {doc.category}</p>
                                                                    {!doc.category && <InputSelect
                                                                        // label='Source'
                                                                        placeholder='Select Category'
                                                                        inputclass='m-0'
                                                                        styles={customStyles}
                                                                        value={doc.category ? CustomerDocumentList.find(item => item.value === doc.category) : ''}
                                                                        options={CustomerDocumentList}
                                                                        onChange={(value) => {
                                                                            let newFiles = [...coApplicantUploadedDocs]
                                                                            newFiles.category = value.value
                                                                            setCoapplicantDocs(newFiles);
                                                                            customerDoc[index].category = value.value
                                                                        }}
                                                                    />}
                                                                    {doc.fileName === 'Aadhar Front' && <div>
                                                                        <InputText
                                                                            type="text"
                                                                            placeholder="Enter Aadhaar Number"
                                                                            inputclass='m-0 inter'
                                                                            onChange={(e) => {
                                                                                let co_applicant = [...coapplicant];
                                                                                co_applicant[index]['aadhaar_no'] = e.target.value
                                                                                    .replace(/^0+/g, '')
                                                                                    .replace(/[^0-9]/g, '');
                                                                                setCoapplicant(co_applicant)
                                                                            }}
                                                                            value={coapplicant[index]['aadhaar_no']}
                                                                        />
                                                                    </div>}
                                                                    {doc.fileName === 'PAN' && <div>
                                                                        <InputText
                                                                            type="text"
                                                                            placeholder="Enter PAN Number"
                                                                            inputclass='m-0 inter'
                                                                            onChange={(e) => {
                                                                                let co_applicant = [...coapplicant];
                                                                                co_applicant[index]['pan_no'] = e.target.value;
                                                                                setCoapplicant(co_applicant)
                                                                            }}
                                                                            value={coapplicant[index]['pan_no']}
                                                                        />
                                                                    </div>}
                                                                </div>}
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                            }

                            <div className="!mt-3">
                                <button
                                    type='button'
                                    className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'
                                    onClick={() => setCoapplicant([...coapplicant, {}])}
                                >
                                    <PlusPrimary /> Add Co-Applicant
                                </button>
                            </div>
                        </>}
                        {/* Payment Proof */}
                        {/* {
                            paymentProof.map((proof, index) => (
                                <div className='!mt-4' key={String(index)}>
                                    <div className='!mb-3 flex justify-between'>
                                        <h5 className="text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                                            Payment Proof {index + 1}
                                        </h5>
                                        {paymentProof.length > 1 &&
                                            <button className='border-0 bg-tr ' onClick={() => {
                                                const paymens = [...paymentProof];
                                                paymens.splice(index, 1)
                                                setPaymentProof(paymens);
                                            }}
                                            >
                                                <CloseIcon />
                                            </button>}
                                    </div>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        <InputSelect
                                            label="Paid By"
                                            placeholder="Enter Paid By"
                                            type="text"
                                            name='paid_by'
                                            value={proof?.paid_by ? PaidBy.find(item => item.value === proof?.paid_by) : ''}
                                            inputclass='m-0 inter'
                                            onChange={(value) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index]['paid_by'] = value.value;
                                                setPaymentProof(payProofs)
                                            }}
                                            options={PaidBy}
                                        />
                                        <InputSelect
                                            label='Mode Of Payment'
                                            placeholder='Select Mode Of Payment'
                                            inputclass='m-0 inter'
                                            isMulti={false}
                                            value={proof?.mode ? PaymentModeList.find(item => item.value === proof?.mode) : ''}
                                            options={PaymentModeList}
                                            styles={customStyles}
                                            onChange={(value) => {
                                                let proofs = [...paymentProof];
                                                proofs[index]['mode'] = value.value;
                                                setPaymentProof(proofs)
                                            }}
                                        />
                                        <InputRupees
                                            label="Amount Paid"
                                            placeholder="Enter Amount"
                                            type="text"
                                            name='amount'
                                            value={proof?.amount}
                                            inputclass='m-0 inter'
                                            onChange={(value) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index]['amount'] = value
                                                setPaymentProof(payProofs)
                                            }}
                                            showRupees={true}
                                        />
                                        {proof?.mode === 'cheque' && <InputText
                                            label="Cheque No."
                                            placeholder="Enter Cheque No."
                                            type="text"
                                            name='check_no'
                                            value={proof?.check_no}
                                            inputclass='m-0 inter'
                                            onChange={(e) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index][e.target.name] = e.target.value;
                                                setPaymentProof(payProofs)
                                            }}
                                        />}
                                        {proof?.mode === 'cheque' && <DateTimePicker
                                            label='Cheque Date'
                                            inputclass='mt-0'
                                            placeholder='Select Date'
                                            value={proof?.cheque_date}
                                            showTime={false}
                                            onValueChange={(date) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index]['cheque_date'] = date;
                                                setPaymentProof(payProofs)
                                            }}
                                        />}
                                        <InputSelect
                                            label="Bank Name"
                                            placeholder="Enter Bank Name"
                                            type="text"
                                            name='bank_name'
                                            value={proof?.bank_name ? BankList.find(item => item.value === proof?.bank_name) : ''}
                                            inputclass='m-0 inter'
                                            onChange={(value) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index]['bank_name'] = value.value;
                                                setPaymentProof(payProofs)
                                            }}
                                            options={BankList}
                                        />
                                        <InputText
                                            label="Transaction Id"
                                            placeholder="Enter Transaction Id"
                                            type="text"
                                            name='transaction_id'
                                            value={proof?.transaction_id}
                                            inputclass='m-0 inter'
                                            onChange={(e) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index][e.target.name] = e.target.value;
                                                setPaymentProof(payProofs)
                                            }}
                                        />
                                        <DateTimePicker
                                            label='Transaction Date'
                                            inputclass='mt-0'
                                            placeholder='Select Date'
                                            value={proof?.transaction_date}
                                            onValueChange={(date) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index]['transaction_date'] = date;
                                                setPaymentProof(payProofs)
                                            }}
                                            showTime={false}

                                        />

                                        <DateTimePicker
                                            label='Amount Received Date'
                                            inputclass='mt-0'
                                            placeholder='Select Date'
                                            value={proof?.amount_received_date}
                                            onValueChange={(date) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index]['amount_received_date'] = date;
                                                setPaymentProof(payProofs)
                                            }}
                                            showTime={false}
                                        />

                                        {proof?.mode !== 'upi' && <InputText
                                            label="Branch Name"
                                            placeholder="Enter Branch Name"
                                            type="text"
                                            name='bank_branch'
                                            value={proof?.bank_branch}
                                            inputclass='m-0 inter'
                                            onChange={(e) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index][e.target.name] = e.target.value;
                                                setPaymentProof(payProofs)
                                            }}
                                        />}
                                        {proof?.mode !== 'upi' && <InputText
                                            label="IFSC Code"
                                            placeholder="Enter IFSC Code"
                                            type="text"
                                            name='ifsc_code'
                                            value={proof?.ifsc_code}
                                            inputclass='m-0 inter'
                                            onChange={(e) => {
                                                let payProofs = [...paymentProof];
                                                payProofs[index][e.target.name] = e.target.value;
                                                setPaymentProof(payProofs)
                                            }}
                                        />}

                                        <div>
                                            <label className='text-sm text-black700 !mb-2 inter'>Payment Proof</label>
                                            <div className='relative group border border-grayLight h-[154px] rounded-lg flex items-center justify-center overflow-hidden cursor-pointer'>
                                                <input type="file" className='d-none' id={`upload-payment-proof-${index}`} accept="application/pdf, image/*" multiple onChange={(e) => handleOnChange(e, index, paymentDocs, setPaymentDocs)} />
                                                <button
                                                    type='button'
                                                    className='!bg-primary flex items-center justify-center py-1 px-2 text-white gap-1 rounded-lg text-sm font-medium'
                                                    onClick={() => {
                                                        document.getElementById(`upload-payment-proof-${index}`).click();
                                                    }}
                                                >
                                                    <Pluswhite /> Upload
                                                </button>

                                                {/* <div className="group-hover:flex absolute left-0 right-0 top-0 bottom-0 w-100 h-100 bg-black bg-opacity-30 hidden items-center justify-center">
                                                        <div className='flex items-end gap-x-1 relative '>
                                                            <button type='button' className='py-1 px-2.5 text-white text-xs font-medium  !bg-primary rounded-md  tracking-[0.5px] inline-flex items-center inter'><Pluswhite /> Replace</button>
                                                            <button type='button' className='trashbtn w-8 h-8 bg-white rounded-md inline-flex items-center justify-center'><TrashIcon /></button>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='!mt-4'>

                                        <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                                            {paymentDocs[index]?.length > 0 && paymentDocs[index].map((doc, index) => (
                                                <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                                    <figure className='mb-0 !table !mx-auto relative group'>
                                                        {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                            <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                            : <img src={pdfimg} />}
                                                        <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                            <button
                                                                className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                                onClick={() => {
                                                                    document.getElementById(`upload-payment-proof${index}`).click();
                                                                }}
                                                            ><PluswhiteIcon />Replace</button>

                                                            <button
                                                                className={`${style.delbtn} inter border-0 bg-white`}
                                                                onClick={() => {
                                                                    let newFiles = { ...paymentDocs };
                                                                    delete newFiles[index]
                                                                    setPaymentDocs(newFiles);

                                                                }}
                                                            ><TrashIcon /></button>
                                                        </div>
                                                    </figure>
                                                    <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {doc.fileName}</p>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <div className="!mt-3">
                            <button
                                type='button'
                                className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'
                                onClick={() => {
                                    setPaymentProof([...paymentProof, {}])
                                }}
                            >
                                <PlusPrimary /> Add Payment Proof</button>
                            </div> */}
                    </div>

                </div>
            </form >
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button type='button' className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center' onClick={closeModal}>Cancel</button>
                <button type='submit' form='eoi-unit' className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center'>Next</button>
            </div>
        </>
    )
}

export default EOIBasicDetail
