import React from 'react'

import { ReactComponent as BuildingIcon } from '../../assets/icons/Building.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import TooltipText from '../Tooltip';

const ProjectTeamCard = ({ team }) => {

    return (
        <div className='!border border-grayLight rounded-lg shadow-boxshadow !p-4'>
            {/* <Link to='/settings/user-roles/teamDetails' className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link> */}
            <div className='flex flex-row justify-between gap-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>{team.name}</div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0: '>
                </div>
            </div>

            <div className='mt-2 flex gap-2 !flex-wrap'>
                <div className='cursor-default p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack  leading-4'>Lead: {team.leader_name}</div>
                <div
                    className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'
                >
                    {/* <div><BuildingIcon /></div> */}
                    Users: <span>{team?.users?.length}</span>
                </div>
                {team?.projects?.length && <TooltipText title={team.projects.join(', ')}>
                    <div className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'>
                        <div><BuildingIcon /></div>
                        {team?.projects[0]} {team.projects.length > 1 ? `+${team.projects.length - 1}` : ''}
                    </div>
                </TooltipText>}
            </div>
        </div>
    )
}

export default ProjectTeamCard;
