import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/NoteIcon.svg';

import { ReactComponent as EditBlackIcon } from '../../../assets/icons/editgray.svg';
import BasicModal from '../../Modals/BasicModal';
import InputTextarea from '../../InputGroup/InputTextarea';
import moment from 'moment';
import { createNoteCall, getAllNotesForLead, updateNoteCall } from '../../../services/private/leads.service';
import { toast } from 'react-toastify';

const ViewNotes = ({ show, closeModal, item }) => {

    const [notesList, setNotesList] = useState([]);
    const [notes, setNotes] = useState('');
    const [selectedIndex, setSelectedIndex] = useState('');
    const [selectedNote, setSelectedNote] = useState({})

    const getAllNotes = () => {
        getAllNotesForLead(item?.lead_uuid).then(res => {
            if (res.status === 200) {
                setNotesList(res.data.data);
            }
        })
    }


    useEffect(() => {
        if (item?.lead_uuid)
            getAllNotes()
    }, [item])

    const handleUpdateNote = async () => {
        if (!selectedNote.description) {
            return false;
        }

        const res = await updateNoteCall(selectedNote.uuid, selectedNote.description)
        if (res.data.status === 200) {
            toast.success("Note Updated");
            getAllNotes();
            setSelectedIndex('');
            setSelectedNote({})
        }

    }

    const handleSubmit = async () => {

        if (!notes) {
            return;
        }
        const res = await createNoteCall(item.lead_uuid, notes);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setNotes('');
            getAllNotes()
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !pt-7 !pb-4 relative flex items-center justify-between'>
                    <div className='flex gap-x-4'>
                        <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter flex items-center gap-x-1'>
                            <span className='border p-2 rounded-lg border-grey-light mr-2.5'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9 7H15" stroke="#92929D" strokeWidth="1.8" strokeLinecap="round" />
                                    <path d="M9 11H15" stroke="#92929D" strokeWidth="1.8" strokeLinecap="round" />
                                    <path d="M9 15H10" stroke="#92929D" strokeWidth="1.8" strokeLinecap="round" />
                                    <path d="M5 18C5 19.6569 6.34315 21 8 21H13.1716C13.702 21 14.2107 20.7893 14.5858 20.4142L18.4142 16.5858C18.7893 16.2107 19 15.702 19 15.1716V6C19 4.34315 17.6569 3 16 3H8C6.34315 3 5 4.34315 5 6V18Z" stroke="#92929D" strokeWidth="1.8" />
                                    <path d="M13 21V19C13 16.7909 14.7909 15 17 15H19" stroke="#92929D" strokeWidth="1.8" />
                                </svg>
                            </span>
                            Notes
                        </h1>
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>

                <div className='!px-6 pb-8 h-full overflow-y-auto overflow-x-hidden' >
                    {notesList.length > 0 && notesList.map((note, index) => (
                        <>
                            <div className='!pt-3 !pb-3 !pr-5 flex flex-row items-center gap-x-6 !border-b border-grayLight' key={note.updated_on}>
                                <div className='w-100'>
                                    <p className='!mb-1 inter text-sm text-black700 font-medium'>{note.description}</p>
                                    <p className='!mb-0 text-xs inter text-black700 font-medium'>{note.name} • {moment(note.updated_on).format("hh:mm a, Do MMM YYYY")}</p>
                                </div>
                                <div className='w-6 h-6 basis-6 shrink-0 grow-0 edit-note-pencil  '>
                                    <button className='w-6 h-6 flex items-center justify-center rounded-full bg-blue-light' onClick={() => {
                                        setSelectedIndex(index)
                                        setSelectedNote(note);
                                        const notesCopy = [...notesList];
                                        notesCopy.splice(index, 1);
                                        setNotesList(notesCopy);
                                    }}>
                                        <EditBlackIcon className='fill-primary' />
                                    </button>
                                </div>
                            </div>
                            {/* {notesList.length > 0 && <div className=' !pt-3 border-b !pb-3'></div>} */}
                        </>
                    ))
                    }
                    {(Object.keys(selectedNote).length > 0) && <div className='!border-t border-grayLight !pt-3 border-b !pb-3'>
                        <InputTextarea
                            value={selectedNote.description}
                            onChange={(e) => setSelectedNote({ ...selectedNote, description: e.target.value })}
                        />
                        <div className='flex justify-end !mt-3 gap-x-3'>
                            <button
                                className='text-base inter font-medium w-[90px] text-center rounded-lg !py-2 leading-6 bg-[#F1F1F5] text-textblack'
                                onClick={() => {
                                    if (selectedNote) {
                                        let notes = [...notesList];
                                        notes.push(selectedNote)
                                        setNotesList(notes);

                                    }
                                    // setSelectedIndex('')
                                    setSelectedNote({});
                                }}
                            >Cancel</button>
                            <button
                                className='text-base inter font-medium w-[90px] text-center rounded-lg !py-2 leading-6 bg-blueHighlight !text-primary'
                                onClick={handleUpdateNote}
                            >Save</button>
                        </div>
                    </div>}
                    {
                        notesList.length > 0 &&
                        <div className='!mt-5'>
                            <InputTextarea
                                placeholder='Enter Note'
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                row={4}
                            />
                        </div>
                    }
                    {
                        notesList.length === 0 &&
                        <div className='!mt-5'>
                            <InputTextarea
                                placeholder='Enter Note'
                                row={10}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </div>
                    }
                </div>

                <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-end">
                    <button
                        className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inline-flex items-center justify-center gap-x-2"
                        onClick={closeModal}
                    >
                        Cancel</button>
                    <button
                        className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
            </div>
        </BasicModal>
    )
}

export default ViewNotes
