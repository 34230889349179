import React from 'react'
import CenterModal from '../../../../components/Modals/center-modal'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/closewhite.svg';
import ReferCard from '../Card/ReferCard';
import CopyRefer from '../Card/CopyRefer';
import ReferCardTwo from '../Card/ReferCardTwo';
import ReferCardThree from '../Card/ReferCardThree';
import OwlCarousel from 'react-owl-carousel';

const ReferModal = ({ show, closeModal, referLink, message }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!w-[450px] [&>*]:!border-0 [&>*]:!rounded-xl [&>*]:!overflow-hidden'>
            <div className='relative'>
                <button onClick={closeModal} className='border-0 bg-tr absolute right-4 top-4 z-10'><CloseIcon /></button>
                <OwlCarousel className='owl-theme referslider'
                    dots={true}
                    loop={true}
                    nav={false}
                    autoplay={true}
                    autoplayTimeout={4000}
                    margin={8}
                    smartSpeed={1000}
                    responsive={{
                        0: {
                            items: 1,
                            margin: 0,
                        },

                    }
                    }>

                    <div>
                        <ReferCard
                            classname=' h-[378px]'
                        />
                    </div>
                    <div>
                        <ReferCardTwo
                            classname='h-[378px]'
                        />
                    </div>
                    <div>
                        <ReferCardThree
                            classname='h-[378px]'
                        />
                    </div>
                </OwlCarousel>
                <CopyRefer referLink={referLink} message={message} />
            </div>

        </CenterModal>
    )
}

export default ReferModal
