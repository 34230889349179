import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function PopUpModal({
    show,
    setShow,
    heading,
    body
}) {
    return (
        <Modal
            className="del-modal modal-background"
            show={show} onHide={() => setShow(false)}
            centered
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
        </Modal>
    )
}