import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import './SidebarOptions.css';

export default function SidebarOptions({
    title,
    count,
    logo,
    clickHandler
}) {
    return <div className="siderbar-list p-0">
        <div className="row itmes-center" onClick={(e) => clickHandler()}>
            <div className="col-10 flex items-center">
                <h2 className="flex mb-0 fz16 po black po fw-regular">
                    <Image className="mr-2" src={logo} />
                    {title}
                    {count ? <span >{count}</span> : ''}
                </h2>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-end pr-0">
                <button className="border-0 bg-tr pr-hover" ><span className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z" fill="#92929D"></path></svg></span></button>
            </div>
        </div>
    </div>
}
