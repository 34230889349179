import React from 'react'
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import CenterModal from '../../../../components/Modals/center-modal';
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg'


const DeleteSuccess = ({ show, closeModal, handleSubmit }) => {
    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!max-w-[450px]  [&>*]:!border-0 [&>*]:!rounded-lg [&>*]:!overflow-hidden' backDropClassName={''}>
            <div className=' relative !p-5'>
                <div>
                    <span>
                        <SuccessIcon />
                    </span>
                    <button onClick={closeModal} className='absolute right-5 top-5'><CloseIcon /></button>
                </div>

                <div className='!mt-4'>
                    <h4 className='text-xl font-semibold m-0'>Request Submitted! </h4>
                    <div className='text-black700 text-sm font-medium !mb-3'>Your account info associated with Propacity will be deleted in 24 hours.</div>
                    <div className='!mt-5'>
                        <button
                            className='w-full bg-primary text-white rounded-lg flex items-center justify-center !py-3'
                            onClick={closeModal}
                        >
                            {/* <span className='mr-2.5'><CallRingIcon /></span> */}
                            Got it
                        </button>
                    </div>
                </div>
            </div>

        </CenterModal>
    )
}

export default DeleteSuccess;