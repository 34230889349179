import React, { useState } from 'react'
import SessionCard from './SessionCard'
import WhatYouLearn from './WhatYouLearn'

const CourseOutline = ({ style, learningSection, displayCertificate, courseContent, count, courseId }) => {
    const [viewMore, setviewMore] = useState(false);

    return (
        <div className={`${style.CourseOutline} CourseOutline`} id='CourseOutline'>
            <div className='row d-flex flex-wrap'>
                <div className='col-xl-8 order-xl-1 order-2'>
                    <div className={style.CourseOutlineTitle}>
                        <div className="row d-flex align-items-center">
                            <div className="col-md-4">
                                <h2 className='fz28 inter fw-po-medium black mb-0'>Course Outline</h2>
                            </div>
                            <div className="col-md-8">
                                <div className={`${style.liveRecordGuidTxt} d-flex align-items-cente justify-content-md-end fz14 black inter mt-md-0 mt-2 flex-sm-nowrap flex-wrap`}>
                                    {count.live > 0 && <div className='d-flex flex-row align-items-center inter'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <rect x="6.91016" y="9.28711" width="18.179" height="13.1883" rx="1.98457" stroke="#92929D" strokeWidth="1.5" />
                                        <circle cx="15.9996" cy="14.5738" r="2.85803" stroke="#92929D" strokeWidth="1.5" />
                                        <path d="M14.5747 16.9823C13.7763 17.2024 13.0433 17.6008 12.4349 18.1451C11.8264 18.6894 11.3598 19.3644 11.0726 20.1155C11.0726 20.1155 10.7066 20.9477 10.6094 21.8551" stroke="#92929D" strokeWidth="1.5" />
                                        <path d="M17.3394 16.9823C18.1377 17.2024 18.8708 17.6008 19.4792 18.1451C20.0876 18.6894 20.5543 19.3644 20.8415 20.1155C20.8415 20.1155 21.2075 20.9477 21.3047 21.8551" stroke="#92929D" strokeWidth="1.5" />
                                    </svg>
                                        {count.live} Live sessions</div>}
                                    {count.recording > 0 && <div className='d-flex flex-row align-items-center inter'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <g clipPath="url(#clip0_2383_5806)">
                                                <circle cx="16" cy="16" r="8.25" stroke="#92929D" strokeWidth="1.5" />
                                                <path d="M15.1105 12.429C14.4271 12.0189 13.875 12.3296 13.875 13.1216V18.8787C13.875 19.6714 14.4281 19.9808 15.1105 19.5713L19.8268 16.7415C20.5104 16.3314 20.5093 15.6683 19.8268 15.2588L15.1105 12.429Z" fill="#92929D" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2383_5806">
                                                    <rect width="18" height="18" fill="white" transform="translate(7 7)" />
                                                </clipPath>
                                            </defs>
                                        </svg>{count.recording} Recordings</div>}
                                    {count.guide > 0 && <div className='d-flex flex-row align-items-center inter'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.0003 7.66699H21.0003C22.8413 7.66699 24.3337 9.15938 24.3337 11.0003V21.0003C24.3337 22.8413 22.8413 24.3337 21.0003 24.3337H11.0003C9.15938 24.3337 7.66699 22.8413 7.66699 21.0003V11.0003C7.66699 9.15938 9.15938 7.66699 11.0003 7.66699ZM11.0003 9.33366C10.0799 9.33366 9.33366 10.0799 9.33366 11.0003V21.0003C9.33366 21.9208 10.0799 22.667 11.0003 22.667H21.0003C21.9208 22.667 22.667 21.9208 22.667 21.0003V11.0003C22.667 10.0799 21.9208 9.33366 21.0003 9.33366H11.0003ZM11.8337 12.667C11.3734 12.667 11.0003 12.2939 11.0003 11.8337C11.0003 11.3734 11.3734 11.0003 11.8337 11.0003H20.167C20.6272 11.0003 21.0003 11.3734 21.0003 11.8337C21.0003 12.2939 20.6272 12.667 20.167 12.667H11.8337ZM11.8337 16.8337C11.3734 16.8337 11.0003 16.4606 11.0003 16.0003C11.0003 15.5401 11.3734 15.167 11.8337 15.167H20.167C20.6272 15.167 21.0003 15.5401 21.0003 16.0003C21.0003 16.4606 20.6272 16.8337 20.167 16.8337H11.8337ZM11.8337 21.0003C11.3734 21.0003 11.0003 20.6272 11.0003 20.167C11.0003 19.7068 11.3734 19.3337 11.8337 19.3337H16.0003C16.4606 19.3337 16.8337 19.7068 16.8337 20.167C16.8337 20.6272 16.4606 21.0003 16.0003 21.0003H11.8337Z" fill="#92929D" />
                                    </svg>{count.guide} Guides</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.live_Recording_guide_block}`}>
                        {!viewMore && courseContent.map((course, index) => {
                            return (
                                index < 5 && <SessionCard style={style} course={course} index={index} courseId={courseId} key={`${course.uuid}_${index}`} />
                            )
                        })}

                        {viewMore && courseContent.map((course, index) => (
                            <SessionCard style={style} course={course} index={index} courseId={courseId} key={`${course.uuid}_${index}`} />
                        ))}

                    </div>
                    {courseContent.length > 5 && <button className={`${style.viewCourseCardBtn} ${!viewMore ? '' : 'view-less'}`} onClick={() => setviewMore(!viewMore)}>
                        {!viewMore ? 'View More' : 'View Less'}
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11 12.4536L14.9352 8.51849C15.2931 8.1605 15.8735 8.1605 16.2315 8.51849C16.5895 8.87647 16.5895 9.45687 16.2315 9.81485L11.6482 14.3982C11.2902 14.7562 10.7098 14.7562 10.3518 14.3982L5.76849 9.81485C5.4105 9.45687 5.4105 8.87647 5.76849 8.51849C6.12647 8.1605 6.70687 8.1605 7.06485 8.51849L11 12.4536Z" fill="#696974" />
                        </svg></button>}
                </div>
                {(learningSection?.length > 0 || displayCertificate) && <div className='col-xl-4 order-xl-2 order-1'>
                    <WhatYouLearn style={style} learningSection={learningSection} displayCertificate={displayCertificate} />
                </div>}
            </div>
        </div>
    )
}

export default CourseOutline
