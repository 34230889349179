import React from 'react'
import mentorPic from '../../../assets/profile.png'
const LiveMentorProfile = ({ style, mentor }) => {
    return (
        <div className={style.mentorProfile}>
            <figure className='mb-0'><img src={mentor.picture_url} alt="" /></figure>
            <div className={style.mentorProfileDescription}>
                <h2 className='inter fz16 black fw-po-medium mb-0'>{mentor.name}</h2>
                <p className='inter fz14 black-dark-700 mb-0'>{mentor.designation}</p>
            </div>
        </div>
    )
}

export default LiveMentorProfile
