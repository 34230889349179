import React from 'react'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as PrintIcon } from '../../../assets/icons/printIcon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/editgray.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import BasicModal from '../../../components/Modals/BasicModal';

const UnitDetails = ({ show, closeModal, handleViewMore, selectedUnit, selectedProject }) => {

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-3 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>
                        <div>
                            <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>Unit 1101 | 1</h1>
                            {/* <p className='m-0 inter text-sm text-black700'>For Ankit</p> */}
                        </div>
                    </div>
                    <div className="flex gap-x-4">
                        {/* <button className='!border !border-grayLight hover:!border-primary bg-white rounded-lg hover:!bg-blue100 text-base font-medium inline-flex items-center gap-x-2 text-black700 hover:!text-primary w-[150px] h-11 justify-center pr-hover'>Edit unit <EditIcon /></button> */}
                        <button onClick={closeModal}><CloseIcon /></button>
                    </div>
                </div>

                <div className='!px-6 !pt-5 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <p className='m-0 text-xl font-semibold'>Unit Details</p>
                    <div className='grid grid-cols-3 gap-y-4 mt-3'>
                        <div>
                            <p className='m-0 text-sm text-black700 inter'>Project</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedProject?.name || '-'}</p>
                        </div>
                        <div>
                            <p className='m-0 text-sm text-black700 inter'>Block</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.block ? selectedUnit?.block : '-'}</p>
                        </div>
                        {selectedUnit?.configuration.unit_type !== 'Plot' && <div>
                            <p className='m-0 text-sm text-black700 inter'>Carpet Area</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.configuration.carpet_area ? `${selectedUnit?.configuration.carpet_area} ${selectedUnit?.configuration.carpet_area_unit}` : '-'}</p>
                        </div>}
                        {selectedUnit?.configuration.unit_type === 'Plot' && <div>
                            <p className='m-0 text-sm text-black700 inter'>Plot Area</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.configuration.plot_area ? `${selectedUnit?.configuration.plot_area} ${selectedUnit?.configuration.plot_area_unit}` : '-'}</p>
                        </div>}
                        {selectedUnit?.configuration.unit_type !== 'Plot' && <div>
                            <p className='m-0 text-sm text-black700 inter'>Balcony Area</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.configuration.balcony_area ? `${selectedUnit?.configuration.balcony_area} ${selectedUnit?.configuration.balcony_area_unit}` : '-'}</p>
                        </div>}

                        {selectedUnit?.configuration.unit_type !== 'Plot' && <div>
                            <p className='m-0 text-sm text-black700 inter'>Super Built Up Area</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.configuration.super_area ? `${selectedUnit?.configuration.super_area} ${selectedUnit?.configuration.super_area_unit}` : '-'} </p>
                        </div>}
                        <div>
                            <p className='m-0 text-sm text-black700 inter'>Base Price</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'> {selectedUnit?.base_price ? `₹ ${selectedUnit?.base_price}` : '-'}</p>
                        </div>
                        {selectedUnit?.floor_no && <div>
                            <p className='m-0 text-sm text-black700 inter'>Floor Number</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.floor_no || '-'}</p>
                        </div>}
                        <div>
                            <p className='m-0 text-sm text-black700 inter'>Furnishing</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.furnishing || '-'}</p>
                        </div>
                        {selectedUnit?.plc_charge1 && <div>
                            <p className='m-0 text-sm text-black700 inter'>PLC Charges 1</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>₹ {selectedUnit?.plc_charge1}</p>
                        </div>}
                        {selectedUnit?.plc_charge2 && <div>
                            <p className='m-0 text-sm text-black700 inter'>PLC Charges 2</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>₹ {selectedUnit?.plc_charge2}</p>
                        </div>}
                        {selectedUnit?.plc_charge3 && <div>
                            <p className='m-0 text-sm text-black700 inter'>PLC Charges 3</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>₹ {selectedUnit?.plc_charge3}</p>
                        </div>}
                        {selectedUnit?.plc_charge4 && <div>
                            <p className='m-0 text-sm text-black700 inter'>PLC Charges 4</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>₹ {selectedUnit?.plc_charge4}</p>
                        </div>}
                        <div>
                            <p className='m-0 text-sm text-black700 inter'>Unit Number</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.name || '-'}</p>
                        </div>
                        <div>
                            <p className='m-0 text-sm text-black700 inter'>Facing</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>{selectedUnit?.facing || '-'}</p>
                        </div>
                        {/* <div>
                            <p className='m-0 text-sm text-black700 inter'>All Pricing Charges</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>₹ {toINRFormat(bookingPricing?.total_price || '-')}</p>
                        </div> */}
                    </div>
                    {/* <div className='!mt-10'>
                        <h2 class="inter text-textblack text-xl !mb-4 font-semibold">Media</h2>
                        <div className='grid sm:grid-cols-3 gap-x-6'>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>

                        </div>
                    </div> */}
                </div>

                {/* <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-end shadow-topshadow'>
                    <button className='border border-grayLight py-2.5 px-3 mr-3 rounded-lg flex items-center'>
                        Print Details
                        <PrintIcon className='ml-1' />
                    </button>
                </div> */}
            </div>
        </BasicModal>
    )
}

export default UnitDetails;