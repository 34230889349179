
import React from 'react';
import Select from 'react-select';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '40px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


export default function InputSelect({
    index,
    name,
    error,
    isMulti,
    value,
    options,
    placeholder,
    menuPosition,
    menuPlacement,
    maxMenuHeight,
    onChange,
    onBlur,
    label,
    disable,
    handleSearch,
    styles,
    required,
    inputclass,
    isClearable = false,
}) {

    return (
        <div key={index} className={`form-group font-medium ${inputclass} ${error ? 'invalid' : ''} position-relative`}>
            {label && <label htmlFor={name}>
                {label} {required && <sup>*</sup>}
            </label>}
            <Select
                maxMenuHeight={150}
                styles={customStyles}
                MenuPlacement={menuPlacement}
                menuPosition={menuPosition}
                isMulti={isMulti}
                name={name}
                value={value}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                onBlur={onBlur}
                isDisabled={disable}
                isClearable={isClearable}

            />

            {error && (
                <div className="input-feedback mt-8">
                    {error}
                </div>
            )}
        </div>
    )
}