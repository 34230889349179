import {
    FB_LEAD_GEN_IN_IT , FB_LEAD_GEN_IN_IT_SUCCESS ,    FB_LEAD_GEN_AUTHENTICATE ,FB_LEAD_GEN_AUTHENTICATE_SUCCESS, FB_LEAD_GEN_IN_IT_ERROR,FB_LEAD_GEN_AUTHENTICATE_ERROR
} from './action';


// The initial state of the Login Reducer

export const initialState = {
        loggedIn : false ,
        requesting : false
        
  };

export default function(state = initialState,actions){
    switch(actions.type) {
        case FB_LEAD_GEN_IN_IT:
            return {...state, errors:{ }  , requesting : true };

        case FB_LEAD_GEN_IN_IT_SUCCESS:
            return {...state, errors:{ }  , requesting : false , loggedIn : true };  
        
        case FB_LEAD_GEN_IN_IT_ERROR:
            return {...state, errors:{ ...actions.errors }  , requesting : false };               

        case FB_LEAD_GEN_AUTHENTICATE:
            return {...state, requesting: true };    
        
        case FB_LEAD_GEN_AUTHENTICATE_SUCCESS:
            return {...state, requesting: false  };    
            
        case FB_LEAD_GEN_AUTHENTICATE_ERROR:
            return {...state, requesting: true  , errors:{ ...actions.errors } };        

        default:        
            return state;
    }
}