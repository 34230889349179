import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { API_METHOD, API_URLS } from "../../../../config/apiUrl";
import { action_setTimeline, TIMELINE_ACTIONS } from "./actions";
import requests, { handleError } from "../../../../helpers/requests";

// Get Timeline
function getTimelineCall(leadUUID) {
    return requests(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getTimeline}/${leadUUID}`, null, null);
}

function* getTimelineWatcher(action) {
    try {
        let result = yield call(getTimelineCall, action.leadUUID)
        yield put(action_setTimeline(result.data.data));
    } catch (error) {
        console.log(error, 'error');
    }
}

export function* timelineSaga() {
    yield all([
        takeLatest(TIMELINE_ACTIONS.GET_TIMELINE, getTimelineWatcher),
    ])
}
