import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllProjects } from '../../../services/private/company.service';
import { canCreateSiteVisit, getLatestActivityByUuid, getSiteVisitsCall } from '../../../services/private/leads.service';
import CreateForm from './CreateForm';
import ShowSiteVisit from './ShowSiteVisit';

export default function CreateSiteVist({
    showCreateSiteVisit,
    toggleCreateSiteVist,
    siteVisitCount,
    reloadSideBarCount,
    intentOptions,
    leadInfoData,
    userProfile,
    leadAccess,
    allowedPermissions
}) {

    const [siteVisit, setSiteVisit] = useState([]);
    const [createSiteVisit, setCreateSiteVisite] = useState(false)
    const [latestActivity, setLatestActivity] = useState({})
    const [projectList, setProjectList] = useState([]);

    const params = useParams();

    const reloadSiteVists = () => {
        reloadSideBarCount()
        getAllSiteVisits()
    }

    const getAllSiteVisits = () => {
        canCreateSiteVisit(params.id).then(res => {
            if (res.status === 200) {
                setCreateSiteVisite(res.data.data)
            }
        })
        getSiteVisitsCall(params.id).then(res => {
            if (res.status === 200) {
                setSiteVisit(res.data.data)
            }
        })
        getLatestActivityByUuid(params.id).then(res => {
            setLatestActivity(res)
        });
    }

    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjectList(projects);
            }
        })
    }

    useEffect(() => {
        getAllSiteVisits()
        getAllProjectsByCompanyId()
    }, [])

    return (
        <>
            <div className={`slide-sidebar SiteVisits position-absolute ${showCreateSiteVisit ? "active" : "d-active"}`}>
                <div className="border-0 sidebar-title">
                    <div className="row align-itmes-center">
                        <div className="col-12 ">
                            <h2 className="mb-0 fz16 po black po pt-10 flex items-center">
                                <button className="border-0 bg-tr p-0 bck-btn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={toggleCreateSiteVist}>
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#44444F" />
                                </svg></button>
                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.5 15C14.7091 15 16.5 13.2091 16.5 11C16.5 8.79086 14.7091 7 12.5 7C10.2909 7 8.5 8.79086 8.5 11C8.5 13.2091 10.2909 15 12.5 15ZM12.5 13C11.3954 13 10.5 12.1046 10.5 11C10.5 9.89543 11.3954 9 12.5 9C13.6046 9 14.5 9.89543 14.5 11C14.5 12.1046 13.6046 13 12.5 13Z" fill="#FC5A5A"></path><path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.8701C3.5 5.96756 7.53307 2 12.5 2C17.4669 2 21.5 5.96756 21.5 10.8701C21.5 13.2916 20.5094 15.5597 18.795 17.2093L13.2013 22.7128C12.8122 23.0957 12.1879 23.0957 11.7987 22.7129L6.19735 17.2024C4.48444 15.5459 3.5 13.2842 3.5 10.8701ZM17.4003 15.7759C18.7357 14.4909 19.5 12.7409 19.5 10.8701C19.5 7.07953 16.3696 4 12.5 4C8.63037 4 5.5 7.07953 5.5 10.8701C5.5 12.7352 6.25964 14.4804 7.59383 15.7707L12.5 20.5972L17.4003 15.7759Z" fill="#FC5A5A"></path></svg>
                                Site Visits {siteVisitCount ? <span>{siteVisitCount}</span> : ''}</h2>
                        </div>

                    </div>
                </div>
                {/* <form> */}
                {createSiteVisit && <CreateForm
                    reloadSiteVists={reloadSiteVists}
                    projectsOptions={projectList}
                    intentOptions={intentOptions}
                    leadInfoData={leadInfoData}
                    leadAccess={leadAccess}
                    userProfile={userProfile}
                    allowedPermissions={allowedPermissions}
                />}

                {/* Recent Site Visits */}
                <div className="resent-notes mt-30 ">
                    <h1 className="black fz16 po mb-24">Recent Site Visits</h1>
                </div>
                {
                    siteVisit.length > 0 ? siteVisit.map((data, index) => {
                        return (
                            <ShowSiteVisit
                                key={String(index)}
                                siteVisit={data}
                                latestActivity={latestActivity}
                                reloadSiteVists={reloadSiteVists}
                                projectsOptions={projectList}
                                intentOptions={intentOptions}
                                leadInfoData={leadInfoData}
                                leadAccess={leadAccess}
                                userProfile={userProfile}
                                allowedPermissions={allowedPermissions}
                            />
                        )
                    })
                        :
                        <div className="no-result-find d-flex flex-column align-items-center justify-content-center">
                            <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none">
                                <circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF" />
                                <path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF" />
                                <circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974" />
                                <circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE" />
                                <circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974" />
                                <path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE" />
                            </svg></figure>
                            <h2 className='po black fz16 fw-po-medium mb-8'>No Site Visits</h2>
                            <p className='text-center black-dark-600 fz14 mb-0'>You have no Site Visits scheduled. <span className='d-md-block'>Please create to view here.</span></p>
                        </div>
                }

            </div>
            {/* Site visit complete modal */}
            <div className="modal fade sucessmodal" id="siteVisitCompleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title" id="exampleModalLongTitle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.2574 13.4741L20.2147 3.38089C20.5566 2.94718 21.1854 2.87277 21.6191 3.2147C22.0528 3.55663 22.1272 4.1854 21.7853 4.61911L13.1131 15.6191C12.736 16.0975 12.0225 16.1304 11.6029 15.6888L7.27509 11.1344C6.89465 10.734 6.9108 10.1011 7.31116 9.72062C7.71152 9.34019 8.34448 9.35633 8.72491 9.75669L12.2574 13.4741ZM20 11.1C20 10.5477 20.4477 10.1 21 10.1C21.5523 10.1 22 10.5477 22 11.1V18.3C22 20.3435 20.3435 22 18.3 22H5.7C3.65655 22 2 20.3435 2 18.3V5.7C2 3.65655 3.65655 2 5.7 2H16.4C16.9523 2 17.4 2.44772 17.4 3C17.4 3.55228 16.9523 4 16.4 4H5.7C4.76112 4 4 4.76112 4 5.7V18.3C4 19.2389 4.76112 20 5.7 20H18.3C19.2389 20 20 19.2389 20 18.3V11.1Z" fill="#82C43C" />
                            </svg>Success</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#44444F" />
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body border-0">
                            <p>Site Visit successfully completed with </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="" data-dismiss="modal">Not Now</button>
                            <button type="button" className="">Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            <div className="modal fade deletemodal" id="deleteSiteVisitModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h1>Delete Note?</h1>
                            <p>Are you sure you want to delete this Site Visit?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="" data-dismiss="modal">Cancel</button>
                            <button type="button" className="" onClick={e => this.deleteSiteVisit(this.state.activeSiteVisitUUID)} data-dismiss="modal">Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}