

import Amplify from 'aws-amplify';
import  AWSConfig from '../aws-exports';

Amplify.configure({ Auth: { region:  AWSConfig.aws_project_region, userPoolId: AWSConfig.aws_user_pools_id,  userPoolWebClientId: AWSConfig.aws_user_pools_web_client_id } });



export default ( ) => { 
    return   Amplify
}