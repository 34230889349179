import * as yup from 'yup';

export const basicDetailsvalidationSchema = yup
    .object()
    .shape({
        name: yup.string().required('Please enter Project/Property Name'),
        transaction_type: yup.string(),
        developer: yup.string().required('Please enter Developer Name'),
        google_address: yup.string().required('Please enter Full Address')
        // possessionType: yup.string().required('Please enter Event Name'),
        // posessionDate: yup.string().required('Please enter Event Name'),
        // size: yup.string().required('Please enter Event Name'),
        // units: yup.string().required('Please enter Event Name'),
        // propertyAge: yup.string().required('Please enter Event Name'),
        // allotmentDate: yup.string().required('Please enter Event Name'),
        // towers: yup.string().required('Please enter Event Name'),
        // reraNumbers: yup.string().required('Please enter Event Name'),
        // towerHeight: yup.string().required('Please enter Event Name'),
        // securtyDeposite: yup.string().required('Please enter Event Name'),
        // leaseTenure: yup.string().required('Please enter Event Name'),
        // availableDate: yup.string().required('Please enter Event Name'),
        // maintenancePayee: yup.string().required('Please enter Event Name'),
        // maintenanceCost: yup.string().required('Please enter Event Name'),
        // rentEscalation: yup.string().required('Please enter Event Name'),
        // rentEscalationPeriod: yup.string().required('Please enter Event Name'),
        // preferredTenant: yup.string().required('Please enter Event Name'),
        // gender: yup.string().required('Please enter Event Name'),
        // assuredRental: yup.string().required('Please enter Event Name'),
        // leaseGuarantee: yup.string().required('Please enter Event Name'),
        // securityDepositPeriod: yup.string().required('Please enter Event Name'),
        // monthlyRent: yup.string().required('Please enter Event Name'),
        // lockInPeriod: yup.string().required('Please enter Event Name'),
        // lockinEndDate: yup.string().required('Please enter Event Name'),
        // propertyTaxPayee: yup.string().required('Please enter Event Name'),
        // tenantBackground: yup.string().required('Please enter Event Name'),
    })        