import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getDisplayDateTime, isDue } from '../../../helpers/helpers';
import { updateFollowupCall } from '../../../services/private/leads.service';
import DateTimePicker from '../../InputGroup/DateTime';
import InputSelect from '../../InputGroup/InputSelect';

export default function ShowFollowup({
    followup,
    reloadFollowupsData,
    leadInfoData,
    intentOptions,
    leadAccess,
    userProfile
}) {

    const [editFollowups, setEditFollowup] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(followup.schedule_date);
    const [remarks, setRemarks] = useState(followup.remarks);
    const [error, setError] = useState(false);
    const [selectedIntent, setSelectedIntent] = useState(leadInfoData.intent ? {
        label: leadInfoData.intent?.display_name,
        value: leadInfoData.intent?.id
    } : {})
    const [loading, setLoading] = useState(false)

    const isOverDue = (followup) => {
        return !followup.is_completed && isDue(followup.schedule_date);
    }

    const updateFollowup = async () => {
        if (!scheduleDate || Object.keys(selectedIntent).length === 0) {
            setError(true)
            return false
        } else {
            setError(false)
        }

        setLoading(true);
        await updateFollowupCall(followup.uuid, {
            scheduleDate,
            remarks,
            intentId: selectedIntent?.value
        }).then(res => {
            if (res.status === 200) {
                toast.success("Followup updated")
                reloadFollowupsData()
                setEditFollowup(false);
                window.location.reload();
            }
        }).catch(err => {
            console.log(err, 'err')
        })
        setLoading(false);
    }

    const getReminderMessage = () => {
        // Remind 10 minutes before
        if (scheduleDate) {
            return <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-16'>We will remind you <span className='black fw-po-medium'>15 mins</span>  prior to <span className='black fw-po-medium'>{moment(scheduleDate).format('h:mm a, Do MMMM YYYY')}</span>.</p>
            // <p className="text-center px-16 fz14 mt-16 black-dark-700 mb-0">
            //     We will remind you to followup with <span className='black fw-po-medium'>{ }</span> at <span className='black fw-po-medium'>{moment(scheduleDate).subtract(15, 'minutes').format('LT')}</span> on <span className='black fw-po-medium'>{moment(scheduleDate).format('LL')}</span>.
            // </p>
        }
    }


    return (
        <>

            <div className={`${editFollowups ? 'active' : ''} card-activity  mb-20 py-12 px-12`}>
                {
                    editFollowups ?
                        <div className={`edit-follow w-100 px-2.5 `}>
                            <div className="part-one">
                                <div className="row align-items-center mb-8">
                                    <div className="col-12">
                                        <h2 className='black-dark-700 fz16 mb-0 fw-po-medium po flex items-center'>
                                            <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M15.7 4V3C15.7 2.44772 16.1477 2 16.7 2C17.2523 2 17.7 2.44772 17.7 3V4.00491C19.8162 4.10912 21.5 5.85793 21.5 8V17C21.5 19.2091 19.7091 21 17.5 21H7.5C5.29086 21 3.5 19.2091 3.5 17V8C3.5 5.79086 5.29086 4 7.5 4H7.7V3C7.7 2.44772 8.14772 2 8.7 2C9.25228 2 9.7 2.44772 9.7 3V4H11.7V3C11.7 2.44772 12.1477 2 12.7 2C13.2523 2 13.7 2.44772 13.7 3V4H15.7ZM15.7 6H13.7C13.7 6.55228 13.2523 7 12.7 7C12.1477 7 11.7 6.55228 11.7 6H9.7C9.7 6.55228 9.25229 7 8.7 7C8.14772 7 7.7 6.55228 7.7 6H7.5C6.39543 6 5.5 6.89543 5.5 8V17C5.5 18.1046 6.39543 19 7.5 19H17.5C18.6046 19 19.5 18.1046 19.5 17V8C19.5 6.96291 18.7106 6.11019 17.7 6.00987C17.6947 6.55761 17.249 7 16.7 7C16.1477 7 15.7 6.55228 15.7 6ZM9.5 12C8.94772 12 8.5 11.5523 8.5 11C8.5 10.4477 8.94772 10 9.5 10H16.5C17.0523 10 17.5 10.4477 17.5 11C17.5 11.5523 17.0523 12 16.5 12H9.5ZM9.5 16C8.94772 16 8.5 15.5523 8.5 15C8.5 14.4477 8.94772 14 9.5 14H14.5C15.0523 14 15.5 14.4477 15.5 15C15.5 15.5523 15.0523 16 14.5 16H9.5Z" fill="#3DD598"></path>
                                            </svg>
                                            Edit Followup</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <DateTimePicker
                                            onValueChange={(e) => setScheduleDate(e)}
                                            label="Select Followup Date & Time"
                                            name="scheduleDate"
                                            value={scheduleDate}
                                            error={error && !scheduleDate && ' Please Select date.'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mb-16 mt-8'>
                                <InputSelect
                                    index={2}
                                    label="Intent"
                                    name="Intent"
                                    value={selectedIntent}
                                    options={intentOptions}
                                    placeholder="Select Intent"
                                    onChange={(e) => { setSelectedIntent(e) }}
                                    error={error && (Object.keys(selectedIntent)?.length === 0) && 'Intent is required'}
                                />
                            </div>
                            <div className="part-tow py-12 mt-8">
                                <div className="row align-items-center mb-16">
                                    <div className="col-12">
                                        <h2 className='black-dark-700 fz16 mb-0 fw-po-medium po flex items-center'>
                                            <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7 1H15.1482C15.7514 1 16.3224 1.27227 16.7022 1.74098L20.554 6.49524C20.8425 6.8514 21 7.29587 21 7.75425V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5C3 2.79086 4.79086 1 7 1ZM15.625 3.58854V6.4H17.9028L15.625 3.58854ZM7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.4H15.625C14.5204 8.4 13.625 7.50457 13.625 6.4V3H7Z" fill="#FF974A" />
                                            </svg>
                                            Comments</h2>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="edit-textarea">
                                            <textarea name="" id="" cols="30" rows="2" className='form-control border-0' placeholder="Enter comments" value={remarks} onChange={(e) => setRemarks(e.target.value)}></textarea>
                                            {getReminderMessage()}
                                        </div>
                                    </div>
                                </div>
                                <div className="save-cancel-btn d-flex aling-items-center justify-content-end">
                                    <button className="cancel-btn outline-btn" onClick={() => setEditFollowup(false)}>Cancel</button>
                                    <button className="save-btn pr-btn" onClick={updateFollowup} disabled={loading}>Save</button>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="block-heading d-flex align-items-center mb4">
                                <div className="hading-icon">
                                    <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="4 0 25 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M15.7 4V3C15.7 2.44772 16.1477 2 16.7 2C17.2523 2 17.7 2.44772 17.7 3V4.00491C19.8162 4.10912 21.5 5.85793 21.5 8V17C21.5 19.2091 19.7091 21 17.5 21H7.5C5.29086 21 3.5 19.2091 3.5 17V8C3.5 5.79086 5.29086 4 7.5 4H7.7V3C7.7 2.44772 8.14772 2 8.7 2C9.25228 2 9.7 2.44772 9.7 3V4H11.7V3C11.7 2.44772 12.1477 2 12.7 2C13.2523 2 13.7 2.44772 13.7 3V4H15.7ZM15.7 6H13.7C13.7 6.55228 13.2523 7 12.7 7C12.1477 7 11.7 6.55228 11.7 6H9.7C9.7 6.55228 9.25229 7 8.7 7C8.14772 7 7.7 6.55228 7.7 6H7.5C6.39543 6 5.5 6.89543 5.5 8V17C5.5 18.1046 6.39543 19 7.5 19H17.5C18.6046 19 19.5 18.1046 19.5 17V8C19.5 6.96291 18.7106 6.11019 17.7 6.00987C17.6947 6.55761 17.249 7 16.7 7C16.1477 7 15.7 6.55228 15.7 6ZM9.5 12C8.94772 12 8.5 11.5523 8.5 11C8.5 10.4477 8.94772 10 9.5 10H16.5C17.0523 10 17.5 10.4477 17.5 11C17.5 11.5523 17.0523 12 16.5 12H9.5ZM9.5 16C8.94772 16 8.5 15.5523 8.5 15C8.5 14.4477 8.94772 14 9.5 14H14.5C15.0523 14 15.5 14.4477 15.5 15C15.5 15.5523 15.0523 16 14.5 16H9.5Z" fill="#3DD598"></path></svg>
                                </div>
                                <div className="heading-text">
                                    <h2 className={`black fz16 mb-0 fw-po-medium po d-flex ${isOverDue(followup) ? 'overdue' : ''}`}>
                                        {`Due: ${moment(followup.schedule_date).format("hh:mm a, Do MMMM YYYY")}`}
                                    </h2>
                                </div>
                                {!followup.is_completed &&
                                    <div className='edit-delte d-flex flex-row align-items-center justify-content-end ml-auto'>
                                        <button
                                            className="bg-tr border-0 pr-hover p-0"
                                            onClick={() => setEditFollowup(true)}
                                            disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view')}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M14.8841 6.33009L15.8131 5.40113L13.7655 3.35357L12.8365 4.28254L14.8841 6.33009ZM13.7118 7.5024L11.6642 5.45485L4.00467 13.1144L3.53341 15.6332L6.05222 15.162L13.7118 7.5024ZM17.0349 4.27833C17.655 4.89843 17.655 5.90382 17.0349 6.52393L7.04308 16.5157C6.92487 16.6339 6.77369 16.7136 6.60937 16.7444L2.64842 17.4855C2.07565 17.5926 1.574 17.091 1.68116 16.5182L2.42224 12.5573C2.45299 12.3929 2.53269 12.2418 2.6509 12.1236L12.6427 2.13175C13.2628 1.51165 14.2682 1.51165 14.8883 2.13175L17.0349 4.27833ZM16.6749 17.5H9.99171C8.8916 17.5 8.8916 15.8333 9.99171 15.8333H16.6749C17.775 15.8333 17.775 17.5 16.6749 17.5Z" fill="#92929D" />
                                            </svg>
                                        </button>

                                    </div>
                                }
                            </div>
                            <div className="block-content">
                                {followup.remarks && <p className='fz14 black-dark-700 mb-0 ro fw-regular'>Notes: {followup.remarks} </p>}
                                {followup.schedule_date && <p className='fz14 black-dark-700 mb-0 ro fw-regular'>Reminder: We will remind you to followup
                                    at {moment(followup.schedule_date).subtract(15, 'minutes').format("hh:mm a, Do MMMM YYYY")}
                                </p>}
                                {/* <div className={`fz12 mb-0 black-dark-700 ${isOverDue(followup) ? 'overdue' : ''}`}>{getDisplayDateTime(followup.schedule_date)} </div> */}
                            </div>
                        </>
                }
            </div>
        </>
    )
}