import React from 'react'
import { useState, useEffect } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',

};

const PdfViewModal = ({ show, handleClose, pdfItem }) => {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfWidth, setPdfWidth] = useState(800);
    const [pageFullWidth, setPageFullWidth] = useState(false);


    const handlePageFullWidth = () => {
        setPdfWidth(window.innerWidth)
        setPageFullWidth(true)
    }
    const handlePageDefaultWidth = () => {
        if (window.innerWidth <= 600) {
            setPdfWidth(window.innerWidth - 15)
        } else {
            setPdfWidth(800)
        }
        setPageFullWidth(false)
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const handleCloseModal = () => {
        setPageNumber(1)
        if (window.innerWidth <= 600) {
            setPdfWidth(window.innerWidth - 15)
        } else {
            setPdfWidth(800)
        }

        setNumPages(null)
        handleClose()


    }
    useEffect(() => {
        if (window.innerWidth <= 600) {
            setPdfWidth(window.innerWidth - 15)
        }
        document.body.classList.add('guidemodal-open');
        return () => {
            document.body.classList.remove('guidemodal-open');

        }

    }, []);

    return (
        <Modal show={show} onHide={handleCloseModal} centered className='pdfview'>
            <div className='pdfmodal-header'>
                <div className="row w-100 d-flex align-items-center">
                    <div className="col-md-6 col-8">
                        <div className='d-flex align-items-center'>
                            <button className='border-0 bg-tr back-btn' onClick={handleCloseModal}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <rect width="32" height="32" rx="4" fill="#40404A" />
                                <path d="M24 15.7254C24 15.3457 23.7178 15.0319 23.3518 14.9822L23.25 14.9754L10.066 14.976L14.829 10.2325C15.1225 9.94021 15.1235 9.46534 14.8313 9.17181C14.5656 8.90497 14.149 8.87987 13.8549 9.09709L13.7706 9.16953L7.72061 15.1935C7.68192 15.2321 7.64832 15.2737 7.61979 15.3178C7.61174 15.331 7.60354 15.3446 7.59576 15.3584C7.58861 15.3703 7.58215 15.3826 7.57606 15.395C7.56759 15.4131 7.55932 15.4319 7.55181 15.4509C7.5457 15.4657 7.54061 15.48 7.53596 15.4944C7.53043 15.5123 7.52506 15.5314 7.52045 15.5507C7.51701 15.5642 7.51429 15.5773 7.51192 15.5903C7.50859 15.6097 7.50575 15.6299 7.50372 15.6502C7.50197 15.6657 7.50092 15.6811 7.50034 15.6965C7.50019 15.7058 7.5 15.7156 7.5 15.7254L7.50038 15.7545C7.50095 15.7692 7.50196 15.7839 7.50339 15.7986L7.5 15.7254C7.5 15.7727 7.50438 15.819 7.51277 15.8639C7.51471 15.8747 7.51703 15.8857 7.51959 15.8966C7.52492 15.9192 7.53108 15.9409 7.53817 15.9623C7.54165 15.9728 7.5457 15.9842 7.55003 15.9954C7.5588 16.018 7.56833 16.0395 7.5788 16.0604C7.58367 16.0703 7.58912 16.0806 7.59484 16.0908C7.60421 16.1075 7.61386 16.1233 7.62407 16.1387C7.63128 16.1496 7.63925 16.161 7.64758 16.1722L7.65407 16.1808C7.67428 16.2072 7.6962 16.2323 7.71967 16.2557L7.72057 16.2564L13.7706 22.2814C14.0641 22.5737 14.5389 22.5727 14.8312 22.2792C15.0969 22.0124 15.1203 21.5957 14.9018 21.3025L14.829 21.2186L10.068 16.476L23.25 16.4754C23.6642 16.4754 24 16.1396 24 15.7254Z" fill="white" />
                            </svg>
                            </button>
                            <h2 className='fz16 text-whtie ml-8 mb-0 align-items-center three-dots'>
                                {pdfItem?.name}</h2>
                        </div>
                    </div>
                    <div className="col-md-6 col-4">
                        <div className='d-flex aling-itmes-center justify-content-end'>
                            <a href={pdfItem?.file_url} target='_blank' className='fz16 text-white dwnl-btn flex items-center'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.575C11.8667 15.575 11.7417 15.554 11.625 15.512C11.5083 15.4707 11.4 15.4 11.3 15.3L7.7 11.7C7.51667 11.5167 7.425 11.2833 7.425 11C7.425 10.7167 7.51667 10.4833 7.7 10.3C7.88333 10.1167 8.12067 10.0207 8.412 10.012C8.704 10.004 8.94167 10.0917 9.125 10.275L11 12.15V5C11 4.71667 11.096 4.479 11.288 4.287C11.4793 4.09567 11.7167 4 12 4C12.2833 4 12.521 4.09567 12.713 4.287C12.9043 4.479 13 4.71667 13 5V12.15L14.875 10.275C15.0583 10.0917 15.296 10.004 15.588 10.012C15.8793 10.0207 16.1167 10.1167 16.3 10.3C16.4833 10.4833 16.575 10.7167 16.575 11C16.575 11.2833 16.4833 11.5167 16.3 11.7L12.7 15.3C12.6 15.4 12.4917 15.4707 12.375 15.512C12.2583 15.554 12.1333 15.575 12 15.575ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V16C4 15.7167 4.09567 15.479 4.287 15.287C4.479 15.0957 4.71667 15 5 15C5.28333 15 5.521 15.0957 5.713 15.287C5.90433 15.479 6 15.7167 6 16V18H18V16C18 15.7167 18.096 15.479 18.288 15.287C18.4793 15.0957 18.7167 15 19 15C19.2833 15 19.5207 15.0957 19.712 15.287C19.904 15.479 20 15.7167 20 16V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6Z" fill="white" />
                            </svg><span className='d-md-inline d-none ml-12'>Download</span></a>
                            <a className='border-0 bg-tr ml-4 p-0 close' onClick={handleCloseModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <rect width="32" height="32" rx="4" fill="#40404A" />
                                    <path d="M22 10L10 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 10L22 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* PDF view for Desktop  */}
            {window.innerWidth > 600 && <div>
                <Document file={pdfItem?.file_url} onLoadSuccess={onDocumentLoadSuccess} loading={'Document loading...'}>
                    <Page pageNumber={pageNumber} width={pdfWidth} />
                </Document>
            </div>}

            {/* PDF view for Mobile  */}
            {window.innerWidth <= 600 && <div>
                <Document file={pdfItem?.file_url} onLoadSuccess={onDocumentLoadSuccess} loading={'Document loading...'} >
                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map(page => <Page pageNumber={page} width={pdfWidth} pageBreak={pageNumber > 1 ? '10' : 'none'} />)}
                </Document>
            </div>}


            <div >

                <button
                    type="button"
                    className='prev'
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.54636 6L6.48151 2.06485C6.8395 1.70687 6.8395 1.12647 6.48151 0.768485C6.12353 0.410504 5.54313 0.410504 5.18515 0.768485L0.601819 5.35182C0.243838 5.7098 0.243838 6.2902 0.601819 6.64818L5.18515 11.2315C5.54313 11.5895 6.12353 11.5895 6.48151 11.2315C6.8395 10.8735 6.8395 10.2931 6.48151 9.93515L2.54636 6Z" fill="white" />
                    </svg>
                </button>
                <button
                    className='next'
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.45364 6L0.518485 2.06485C0.160505 1.70687 0.160505 1.12647 0.518485 0.768485C0.876466 0.410504 1.45687 0.410504 1.81485 0.768485L6.39818 5.35182C6.75616 5.7098 6.75616 6.2902 6.39818 6.64818L1.81485 11.2315C1.45687 11.5895 0.876466 11.5895 0.518485 11.2315C0.160505 10.8735 0.160505 10.2931 0.518485 9.93515L4.45364 6Z" fill="white" />
                    </svg>
                </button>
                <div className='bottom-preview-group d-flex align-items-center justify-content-center'>
                    <div className='d-flex align-items-center justify-content-center flex-row  bottom-preview'>
                        <p className='mb-0'>
                            <span className='mr4'>Page</span> {pageNumber || (numPages ? 1 : '--')} <span>/</span> {numPages || '--'}
                        </p>
                        <div className='d-flex align-items-center jutify-content-center flex-row plush-minus'>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip' >
                                        <div className='info-msg'>
                                            {/* <h4>Total Leads</h4> */}
                                            <p className='d-flex fz18'>Zoom out</p>
                                        </div>
                                    </Tooltip>
                                }
                            >

                                <button onClick={() => setPdfWidth(pdfWidth - 50)} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <rect width="32" height="32" rx="4" fill="#575761" />
                                        <path d="M10 15H22.5V17.5H10V15Z" fill="white" />
                                    </svg>
                                </button>
                            </OverlayTrigger>

                            {!pageFullWidth &&
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip" className='sidebar-tooltip' >
                                            <div className='info-msg'>
                                                {/* <h4>Total Leads</h4> */}
                                                <p className='d-flex fz18'>Fit to width</p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <button onClick={handlePageFullWidth}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <rect width="32" height="32" rx="4" fill="#575761" />
                                            <path d="M25.7958 24.2044L21.3436 19.7504C22.6785 18.0108 23.3018 15.8286 23.0869 13.6464C22.872 11.4643 21.8352 9.44552 20.1866 7.99972C18.5381 6.55393 16.4013 5.78935 14.2097 5.86107C12.0182 5.9328 9.93595 6.83546 8.38546 8.38595C6.83497 9.93644 5.93231 12.0187 5.86058 14.2102C5.78886 16.4018 6.55344 18.5385 7.99924 20.1871C9.44503 21.8356 11.4638 22.8725 13.6459 23.0874C15.8281 23.3022 18.0103 22.679 19.7499 21.3441L24.2058 25.801C24.3105 25.9056 24.4347 25.9886 24.5714 26.0453C24.7081 26.1019 24.8547 26.1311 25.0027 26.1311C25.1507 26.1311 25.2972 26.1019 25.434 26.0453C25.5707 25.9886 25.6949 25.9056 25.7996 25.801C25.9042 25.6963 25.9872 25.5721 26.0439 25.4354C26.1005 25.2986 26.1296 25.1521 26.1296 25.0041C26.1296 24.8561 26.1005 24.7096 26.0439 24.5728C25.9872 24.4361 25.9042 24.3119 25.7996 24.2072L25.7958 24.2044ZM8.12487 14.5004C8.12487 13.2395 8.49876 12.007 9.19925 10.9586C9.89975 9.91024 10.8954 9.09314 12.0603 8.61063C13.2251 8.12812 14.5069 8.00187 15.7436 8.24785C16.9802 8.49383 18.1161 9.10099 19.0077 9.99255C19.8992 10.8841 20.5064 12.02 20.7524 13.2567C20.9984 14.4933 20.8721 15.7751 20.3896 16.94C19.9071 18.1048 19.09 19.1005 18.0416 19.801C16.9933 20.5015 15.7607 20.8754 14.4999 20.8754C12.8097 20.8736 11.1892 20.2014 9.99399 19.0062C8.79882 17.8111 8.12661 16.1906 8.12487 14.5004Z" fill="#FEFFFF" />
                                            <path d="M15.3 13.7V10.5H13.7V13.7H10.5V15.3H13.7V18.5H15.3V15.3H18.5V13.7H15.3Z" fill="white" />
                                        </svg>
                                    </button>
                                </OverlayTrigger>
                            }
                            {pageFullWidth && <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip' >
                                        <div className='info-msg'>
                                            {/* <h4>Total Leads</h4> */}
                                            <p className='d-flex fz18'>Reset zoom</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <button onClick={handlePageDefaultWidth}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <rect width="32" height="32" rx="4" fill="#575761" />
                                        <path d="M25.7958 24.2044L21.3436 19.7504C22.6785 18.0108 23.3018 15.8286 23.0869 13.6464C22.872 11.4643 21.8352 9.44552 20.1866 7.99972C18.5381 6.55393 16.4013 5.78935 14.2097 5.86107C12.0182 5.9328 9.93595 6.83546 8.38546 8.38595C6.83497 9.93644 5.93231 12.0187 5.86058 14.2102C5.78886 16.4018 6.55344 18.5385 7.99924 20.1871C9.44503 21.8356 11.4638 22.8725 13.6459 23.0874C15.8281 23.3022 18.0103 22.679 19.7499 21.3441L24.2058 25.801C24.3105 25.9056 24.4347 25.9886 24.5714 26.0453C24.7081 26.1019 24.8547 26.1311 25.0027 26.1311C25.1507 26.1311 25.2972 26.1019 25.434 26.0453C25.5707 25.9886 25.6949 25.9056 25.7996 25.801C25.9042 25.6963 25.9872 25.5721 26.0439 25.4354C26.1005 25.2986 26.1296 25.1521 26.1296 25.0041C26.1296 24.8561 26.1005 24.7096 26.0439 24.5728C25.9872 24.4361 25.9042 24.3119 25.7996 24.2072L25.7958 24.2044ZM8.12487 14.5004C8.12487 13.2395 8.49876 12.007 9.19925 10.9586C9.89975 9.91024 10.8954 9.09314 12.0603 8.61063C13.2251 8.12812 14.5069 8.00187 15.7436 8.24785C16.9802 8.49383 18.1161 9.10099 19.0077 9.99255C19.8992 10.8841 20.5064 12.02 20.7524 13.2567C20.9984 14.4933 20.8721 15.7751 20.3896 16.94C19.9071 18.1048 19.09 19.1005 18.0416 19.801C16.9933 20.5015 15.7607 20.8754 14.4999 20.8754C12.8097 20.8736 11.1892 20.2014 9.99399 19.0062C8.79882 17.8111 8.12661 16.1906 8.12487 14.5004Z" fill="#FEFFFF" />
                                        <path d="M10 13.5H19V15H10V13.5Z" fill="white" />
                                    </svg>
                                </button>
                            </OverlayTrigger>
                            }


                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip' >
                                        <div className='info-msg'>
                                            {/* <h4>Total Leads</h4> */}
                                            <p className='d-flex fz18'>Zoom in</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <button onClick={() => setPdfWidth(pdfWidth + 50)} className='' >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <rect width="32" height="32" rx="4" fill="#575761" />
                                        <path d="M17.5 15V10H15V15H10V17.5H15V22.5H17.5V17.5H22.5V15H17.5Z" fill="white" />
                                    </svg>

                                </button>
                            </OverlayTrigger>

                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default PdfViewModal
