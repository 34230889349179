import React from 'react'
import CenterModal from '../../components/Modals/center-modal'
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as QrCode } from '../../assets/qr.svg';
import { ReactComponent as AppleIcon } from '../../assets/apple.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google.svg';
import QrcodeImg from '../../assets/qr.jpeg';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const AppDownloadModal = ({ show, closeModal }) => {

    return (
        <CenterModal show={show} closeModal={closeModal} modalClass='!max-w-[450px]  [&>*]:!border-0 [&>*]:!rounded-lg [&>*]:!overflow-hidden' backDropClassName={''}>
            <div className=' relative'>
                <button onClick={closeModal} className='absolute right-5 top-5'><CloseIcon /></button>

                <div className='!flex items-center justify-center !mt-6 '>
                    <img src={QrcodeImg} alt="" className='h-[350px] !table mx-auto' />
                </div>
                <div className='!p-3 !border border-black400 !rounded-lg !m-6'>
                    <div className='text-gray800 text-sm font-medium !mb-3'>Scan this code to download the Mobile app</div>
                    <div className='flex flex-row gap-2 justify-start'>
                        {/* <Link to=''><AppleIcon /></Link> */}
                        <a href='https://play.google.com/store/apps/details?id=com.dev_propacity.propacity_broker'><GoogleIcon /></a>

                    </div>
                </div>

            </div>
        </CenterModal>
    )
}

export default AppDownloadModal
