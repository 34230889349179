import React from 'react'
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert.svg'
import { Modal } from 'react-bootstrap';

const ActionModalOfprojectRouting = ({ show, closeModal, subHeadingText, btnText, handleSubmit }) => {
    return (
        <Modal show={show} onHide={closeModal} backdrop="static" centered className='w-[400px]'>
            <div className='p-4'>
                <div className='mb-4 h-12 w-12 bg-[#FEE4E2] rounded-full flex items-center justify-center'>
                    <AlertIcon />
                </div>
                <h6 className='mb-2.5'>
                    Caution!
                </h6>
                <div className='mb-2.5'>
                    {subHeadingText}
                </div>

                <div className='grid grid-cols-3 gap-3 mt-3'>
                    <div></div>
                    <button className='border rounded-lg py-2.5' onClick={closeModal}>Cancel</button>
                    <button className='bg-primary text-white rounded-lg py-2.5' onClick={handleSubmit}>{btnText}</button>
                </div>
            </div>

        </Modal>
    )
}

export default ActionModalOfprojectRouting;