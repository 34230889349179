import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import { AmenitiesType, carpet_area, unit_type } from '../../../utils/project-constant';
import { insertPricingAmenityCharges, updatePricingAmenityChargesByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import InputRupees from '../../InputGroup/InputRupees';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const chargeIntervalList = [
    {
        label: 'One Time',
        value: 'One Time',
    },
    {
        label: 'Monthly',
        value: 'Monthly',
    },
    {
        label: 'Yearly',
        value: 'Yearly',
    }
]


const AddAmenityModal = ({ show, closeModal, modalClass, configurations, uuid, getAminitiesData, selectedPrice }) => {
    const [amenityCharges, setAmenityCharges] = useState('');
    const [absoluteAmount, setAbsoluteAmount] = useState();
    const [selectedUnit, setSelectedUnit] = useState('');
    const [selectedUnitValue, setSelectedUnitValue] = useState('');
    const [gstPercentage, setGstPercentage] = useState();
    const [unitTypeAvailble, setUnitTypeAvailable] = useState();
    const [unitConfigurationAvailable, setUnitConfigurationAvailble] = useState();
    const [tokenSelection, setTokenSelection] = useState('absolute');
    const [includePrice, setIncludePrice] = useState(true);
    const [chargeInterVal, setChargeInterval] = useState('');


    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            console.log({ selectedPrice });
            const unitType = unit_type.filter(item => selectedPrice.unit_type.includes(item.label));
            const unitConfig = configurations.filter(item => selectedPrice.config.includes(item.value));
            setUnitTypeAvailable(unitType);
            setUnitConfigurationAvailble(unitConfig);
            setSelectedUnitValue(selectedPrice?.rate);
            setSelectedUnit(carpet_area.find(item => item.value === selectedPrice?.rate_unit));
            setIncludePrice(selectedPrice.include_in_price);
            setAmenityCharges(AmenitiesType.find(item => item.value === selectedPrice.charge_type));
            setAbsoluteAmount(selectedPrice.payment_amount);
            setGstPercentage(selectedPrice.gst_percentage);
            setChargeInterval(chargeIntervalList.find(item => item.value === selectedPrice.interval));

            if (selectedPrice.payment_amount) {
                setTokenSelection('absolute')
            }
            if (selectedPrice.rate) {
                setTokenSelection('perUnit')
            }
        }
    }, [selectedPrice])

    const handleSubmit = async () => {
        console.log(selectedUnit, selectedPrice)
        const payload = {
            charge_type: amenityCharges.value,
            payment_amount: absoluteAmount || null,
            rate: selectedUnitValue,
            rate_unit: selectedUnit.value,
            include_in_price: includePrice,
            unit_type: unitTypeAvailble.map(item => item.label),
            unit_configuration: unitConfigurationAvailable.map(item => item.value),
            // payment_percentage: percentage || null,
            payment_amount: absoluteAmount || null,
            gst_percentage: gstPercentage || null,
            interval: chargeInterVal.value
        }

        let res = {};
        if (selectedPrice?.uuid) {
            res = await updatePricingAmenityChargesByUUID(selectedPrice?.uuid, payload)
        } else {
            res = await insertPricingAmenityCharges(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            handleClose();
            getAminitiesData();
        }
    }
    const handleClose = () => {
        closeModal();
        setAmenityCharges('');
        setAbsoluteAmount('');
        setSelectedUnit('');
        setSelectedUnitValue('');
        setUnitTypeAvailable('');
        setUnitConfigurationAvailble('');
        setTokenSelection('');
        setIncludePrice(true);
        setChargeInterval('');
    }

    return (
        <RightModal show={show} closeModal={handleClose} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add Amenity</h1>
                <button className='border-0 bg-tr ' onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>
                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Amenity Charge Type'
                    value={amenityCharges}
                    onChange={(value) => setAmenityCharges(value)}
                    options={AmenitiesType}
                />
                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Charge Applied'
                    value={chargeInterVal}
                    onChange={(value) => setChargeInterval(value)}
                    options={chargeIntervalList}
                />

                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Amenity Charge Value Type
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='absolute' checked={tokenSelection === 'absolute'} onChange={() => setTokenSelection('absolute')} />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='perUnit' checked={tokenSelection === 'perUnit'} onChange={() => setTokenSelection('perUnit')} />
                            Per Unit
                        </label>
                    </div>
                    {tokenSelection === 'perUnit' &&
                        <div className='flex gap-x-5 [&>*]:w-6/12'>
                            <InputText
                                inputclass='!mt-0'
                                styles={customStyles}
                                value={selectedUnitValue}
                                onChange={(e) => setSelectedUnitValue(e.target.value)}

                            />
                            <InputSelect
                                inputclass='!mt-0'
                                styles={customStyles}
                                value={selectedUnit}
                                onChange={(value) => setSelectedUnit(value)}
                                options={carpet_area}

                            />
                        </div>
                    }
                    {tokenSelection === 'absolute' &&
                        <InputRupees
                            placeholder='Enter Absolute'
                            value={absoluteAmount}
                            onChange={(value) => setAbsoluteAmount(value)}
                            rupayclass='top-4 left-3'
                            handleBlur={() => { }}
                        />}
                </div>
                {/* <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Amenity Charge Value Type
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" checked />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" />
                            Per Unit
                        </label>
                    </div>
                    <InputText
                        placeholder='Enter value' />
                </div>  */}
                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Include in price
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" checked={includePrice} onChange={() => setIncludePrice(true)} />
                            Yes
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" checked={!includePrice} onChange={() => setIncludePrice(false)} />
                            No
                        </label>
                    </div>

                </div>

                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Unit Type Applicability'
                    options={unit_type}
                    value={unitTypeAvailble}
                    onChange={(value) => setUnitTypeAvailable(value)}
                    isMulti={true}
                />
                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Unit Configuration Applicability'
                    options={configurations}
                    value={unitConfigurationAvailable}
                    onChange={(value) => setUnitConfigurationAvailble(value)}
                    isMulti={true}
                />
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={handleClose}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddAmenityModal
