import firebase from 'firebase/compat/app';
import { v4 as uuidv4 } from 'uuid';
import 'firebase/compat/messaging';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { insertNotificationDevice } from './services/public/public.service';

// const firebaseConfig = {
//     apiKey: "AIzaSyATKwBvFVRmGPxyT2q-n31yd0ip-J3itUE",
//     authDomain: "propacity-71241.firebaseapp.com",
//     projectId: "propacity-71241",
//     storageBucket: "propacity-71241.appspot.com",
//     messagingSenderId: "604274112476",
//     appId: "1:604274112476:web:dd2a35b4109fef3f52c119"
// };
const firebaseConfig = {
    apiKey: "AIzaSyBI05evgpIIMVg4eetPsDuefQYtRNxU2n8",
    authDomain: "propacity-for-brokers.firebaseapp.com",
    projectId: "propacity-for-brokers",
    storageBucket: "propacity-for-brokers.appspot.com",
    messagingSenderId: "574302838332",
    appId: "1:574302838332:web:d1528c52b003e1c752ed39",
    measurementId: "G-0255FB0B5M"
};

firebase.initializeApp(firebaseConfig);

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// export const messaging = firebase.messaging();
export const fetchToken = async (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BP45Ot81ya2vG8P-d0VIUEna8g9AoEyRy55CeCN0xVZRbwDehtDb8FrxhX10Z8vgtVI5iEmzxwJk1VuyJ_0FNwQ' })
        .then(async (currentToken) => {
            if (currentToken) {
                let deviceId = localStorage.getItem('deviceId');
                let authToken = localStorage.getItem('authToken');

                if (!deviceId) {
                    deviceId = uuidv4();
                    localStorage.setItem('deviceId', deviceId);
                }
                const payload = {
                    firebaseToken: currentToken,
                    device_id: deviceId
                }
                if (authToken) {
                    await insertNotificationDevice(payload)
                }
                // console.log('current token for client: ', currentToken);
                setTokenFound(true);
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(false);
                // shows on the UI that permission is required 
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
        });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });