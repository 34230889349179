import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ProjectHeader from './project-header'
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as HeaderMenu } from '../../assets/icons/HeaderMenu.svg';
import InputText from '../InputGroup/InputText';
import InputSelect from '../InputGroup/InputSelect';
import AddEOITokenModal from './Modal/AddEOITokenModal';
import AddBasePriceModal from './Modal/AddBasePriceModal';
import AddAmenityModal from './Modal/AddAmenityModal';
import AddStampDutyModal from './Modal/AddStampDutyModal';
import AddRegistrationModal from './Modal/AddRegistrationModal';
import AddLeagalChargesModal from './Modal/AddLeagalChargesModal';
import AddEdcCharges from './Modal/AddEdcCharges';
import AddIdcCharges from './Modal/AddIdcCharges';
import AddIfdcCharges from './Modal/AddIfdcCharges';
import AddGstChargesModal from './Modal/AddGstChargesModal';
import {
    changeAmenityPriceInclusion,
    changeEDCChargeInclusion,
    changeGSTInclusion,
    changeIDCChargeInclusion,
    changeIFDCChargeInclusion,
    changeLegalChargeInclusion,
    changePLCChargeInclusion,
    changeRegisterationChargeInclusion,
    changeStampDutyInclusion,
    getPricingAmenityCharges,
    getPricingAmenityChargesByUUID,
    getPricingBasePrice,
    getPricingBasePriceByUUID,
    getPricingBooking,
    getPricingBookingByUUID,
    getPricingDelayedInterest,
    getPricingEDCCharges,
    getPricingEDCChargesByUUID,
    getPricingEOI,
    getPricingEOIByUUID,
    getPricingGSTCharges,
    getPricingIDCCharges,
    getPricingIDCChargesByUUID,
    getPricingIFDCCharges,
    getPricingIFDCChargesByUUID,
    getPricingLegalCharges,
    getPricingPLCCharges,
    getPricingPLCChargesByUUID,
    getPricingRegisterationCharges,
    getPricingStampDuty,
    getProjectConfigurations,
    getStates,
    insertPricingDelayedInterest,
    updatePricingDelayedInterestByUUID
} from '../../services/public/public.service';
import { toast } from 'react-toastify';
import { addPercentage, checkIsSomeItemAvailableInList, toINRFormat } from '../../helpers/helpers';
import Consumer from '../../helpers/context';
import { carpet_area, unit_type } from '../../utils/project-constant';
import AddBookingPriceModal from './Modal/AddBookingPriceModal';
import AddPlcChargesModal from './Modal/AddPlcChargesModal';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


const getConfigString = (config) => {
    let result = [];
    if (!Array.isArray(config)) {
        config = [config]; // Convert to an array if it's not already
    }
    for (const item of config) {
        const unitType = item.unit_type.charAt(0).toUpperCase() + item.unit_type.slice(1); // Capitalize the unit_type
        let areaKey = null;
        let areaKeyUnit = null;

        if (item.carpet_area !== null) {
            areaKey = item.carpet_area;
            areaKeyUnit = item.carpet_area_unit;
        } else if (item.super_area !== null) {
            areaKey = item.super_area;
            areaKeyUnit = item.super_area_unit;
        } else if (item.built_up_area !== null) {
            areaKey = item.built_up_area;
            areaKeyUnit = item.built_up_area_unit;
        } else if (item.plot_area !== null) {
            areaKey = item.plot_area;
            areaKeyUnit = item.plot_area_unit;
        }

        if (unitType && areaKey !== null && areaKeyUnit) {
            result.push(`${unitType} - ${item.unit_configuration ? item.unit_configuration + ' ' : ''}${areaKey} ${areaKeyUnit}`);
        }
    }

    return result.join(', ');
}

const configLabel = (item) => {
    let label = item.unit_configuration
    if (item.unit_type === 'Plot') {
        label = `${item.unit_type} (${item.plot_area} ${item.plot_area_unit})`
    } else {
        if (item.carpet_area) {
            label = `${item.unit_type} ${label} (${item.carpet_area} ${item.carpet_area_unit})`
        } else if (item.super_area) {
            label = `${item.unit_type} ${label}  (${item.super_area} ${item.super_area_unit})`
        } else if (item.built_up_area) {
            label = `${item.unit_type} ${label}  (${item.built_up_area} ${item.built_up_area_unit})`
        }
    }

    return label;
}

const calculateTotalPrice = (config, rate) => {
    if (config.plot_area !== null) {
        return Math.round(rate * config.plot_area);
    } else if (config.built_up_area !== null) {
        return Math.round(rate * config.built_up_area);
    } else if (config.super_area !== null) {
        return Math.round(rate * config.super_area);
    } else if (config.carpet_area !== null) {
        return Math.round(rate * config.carpet_area);
    } else {
        return 0; // Return 0 if no valid area field is found
    }
}

const getUnitType = (type) => {
    const unitType = unit_type.find(item => item.value === type);
    return unitType?.label
}

const PricingModule = () => {

    const [showEoiModal, setshowEoiModal] = useState(false);
    const [showBookingModal, setshowBookingModal] = useState(false);
    const [showPriceMdoal, setShowPriceMdoal] = useState(false);
    const [showAmenityModal, setShowAmenityModal] = useState(false);
    const [showStampDutyModal, setShowStampDutyModal] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [showLeagalChargesModal, setShowLeagalChargesModal] = useState(false);
    const [showAddEdcCharges, setShowAddEdcCharges] = useState(false);
    const [showAddPlcCharges, setShowAddPlcCharges] = useState(false);
    const [showAddIdcCharges, setShowAddIdcCharges] = useState(false);
    const [showAddIfdcCharges, setShowAddIfdcCharges] = useState(false);
    const [showAddGstChargesModal, setShowAddGstChargesModal] = useState(false);

    const [eoiTokenData, seteoiTokenData] = useState([]);
    const [bookingPricingData, setBookingPricingData] = useState([]);
    const [basePriceData, setBasePriceData] = useState([]);
    const [aminitiesData, setAminitiesData] = useState([]);
    const [stampDutyData, setStampDutyData] = useState([]);
    const [registrationChargesData, setRegistrationChargesData] = useState([]);
    const [legelChargesData, setLegelChargesData] = useState([]);
    const [edcChargesData, SetEdcChargesData] = useState([]);
    const [plcChargesData, setPlcChargesData] = useState([]);
    const [idcChargesData, setIdcChargesData] = useState([]);
    const [ifdcCharges, setIfdcCharges] = useState([]);
    const [gstChargesData, setGSTChargesData] = useState([]);
    const [interesRate, setInterestRate] = useState('');
    const [gracePerioid, setGracePerioid] = useState('');
    const [gracePerioidUnit, setGracePerioidUnit] = useState('');
    const [interestCalculatedFrom, setInterestCalculatedFrom] = useState('due_date');
    const [selectedPrice, setSelectedPrice] = useState({});
    const [statesList, setStatesList] = useState([])
    const [pricingDelayUUID, setPricingDelayUUID] = useState('');

    const [configurations, setConfigurations] = useState([]);

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const property = searchParams.get('pt');
    const transaction = searchParams.get('tt');

    let { userProfile, allowedPermissions, isRolesModule } = useContext(Consumer);

    const getAllStates = async () => {
        getStates().then((res) => {
            if (res.status === 200) {
                const states = res.data.data.map((data) => {
                    return {
                        label: data.name,
                        value: data.name,
                    };
                });
                setStatesList(states);
            }
        });
    };

    useEffect(() => {
        localStorage.setItem('project_state', 'create');
        getAllStates();
    }, []);


    const getEoiTokenData = async () => {
        const res = await getPricingEOI(uuid);
        if (res.data.status === 200) {
            seteoiTokenData(res.data.data);
        }
    }

    const getBookingPricing = async () => {
        const res = await getPricingBooking(uuid);
        if (res.data.status === 200) {
            console.log(res.data.data, 'booking price')
            setBookingPricingData(res.data.data);
        }
    }

    const getBasePriceData = async () => {
        const res = await getPricingBasePrice(uuid);
        if (res.data.status === 200) {
            setBasePriceData(res.data.data);
        }
    }

    const getAminitiesData = async () => {
        const res = await getPricingAmenityCharges(uuid);
        if (res.data.status === 200) {
            setAminitiesData(res.data.data);
        }
    }

    const getStampDutyData = async () => {
        const res = await getPricingStampDuty(uuid);
        if (res.data.status === 200) {
            setStampDutyData(res.data.data);
        }
    }

    const getRegistrantionData = async () => {
        const res = await getPricingRegisterationCharges(uuid);
        if (res.data.status === 200) {
            setRegistrationChargesData(res.data.data);
        }
    }

    const getLegelChargesData = async () => {
        const res = await getPricingLegalCharges(uuid);
        if (res.data.status === 200) {
            setLegelChargesData(res.data.data);
        }
    }

    const getEDCChargedData = async () => {
        const res = await getPricingEDCCharges(uuid);
        if (res.data.status === 200) {
            SetEdcChargesData(res.data.data);
        }
    }

    const getPLCChargedData = async () => {
        const res = await getPricingPLCCharges(uuid);
        if (res.data.status === 200) {
            setPlcChargesData(res.data.data);
        }
    }

    const getIdcChargeData = async () => {
        const res = await getPricingIDCCharges(uuid);
        if (res.data.status === 200) {
            setIdcChargesData(res.data.data);
        }
    }

    const getIfdcChargeData = async () => {
        const res = await getPricingIFDCCharges(uuid);
        if (res.data.status === 200) {
            setIfdcCharges(res.data.data);
        }
    }

    const getGSTChargeData = async () => {
        const res = await getPricingGSTCharges(uuid);
        if (res.data.status === 200) {
            setGSTChargesData(res.data.data);
        }
    }

    const getEOIPricing = async (data) => {
        const res = await getPricingEOIByUUID(data.uuid);
        if (res.data.status === 200) {
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setshowEoiModal(true)
        }
    }

    const getBookingPriceByUuid = async (data) => {
        const res = await getPricingBookingByUUID(data.uuid);
        if (res.data.status === 200) {
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setshowBookingModal(true)
        }
    }

    const getBasePricing = async (data) => {
        const res = await getPricingBasePriceByUUID(data.uuid);
        if (res.data.status === 200) {
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setShowPriceMdoal(true)
        }
    }

    const getAmenitiesPricing = async (data) => {
        const res = await getPricingAmenityChargesByUUID(data.uuid);
        if (res.data.status === 200) {
            console.log(res.data.data);
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setShowAmenityModal(true);
        }
    }

    const getEDCPricing = async (data) => {
        const res = await getPricingEDCChargesByUUID(data.uuid);
        if (res.data.status === 200) {
            console.log(res.data.data);
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setShowAddEdcCharges(true);
        }
    }
    const getIDCPricing = async (data) => {
        const res = await getPricingIDCChargesByUUID(data.uuid);
        if (res.data.status === 200) {
            console.log(res.data.data);
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setShowAddIdcCharges(true);
        }
    }
    const getIFDCPricing = async (data) => {
        const res = await getPricingIFDCChargesByUUID(data.uuid);
        if (res.data.status === 200) {
            console.log(res.data.data);
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setShowAddIfdcCharges(true);
        }
    }

    const getPLCPricing = async (data) => {
        const res = await getPricingPLCChargesByUUID(data.uuid);
        if (res.data.status === 200) {
            console.log(res.data.data);
            setSelectedPrice({ ...data, config: res.data.data.unit_configuration })
            setShowAddPlcCharges(true);
        }
    }
    const getPricingDeley = async () => {
        const res = await getPricingDelayedInterest(uuid);
        if (res.data.status === 200) {
            console.log(res.data.data, 'pricng')
            setGracePerioidUnit(
                {
                    label: res.data.data[0]?.grace_period_unit,
                    value: res.data.data[0]?.grace_period_unit
                })
            setGracePerioid(res.data.data[0]?.grace_period);
            setInterestCalculatedFrom(res.data.data[0]?.calculated_from || 'due_date')
            setInterestRate(res.data.data[0]?.payment_percentage);
            setPricingDelayUUID(res.data.data.uuid);
        }
    }

    const getConfig = async () => {
        const res = await getProjectConfigurations(uuid);
        if (res.data.status === 200) {
            const mappedConfig = res.data.data.map(item => ({ label: `${configLabel(item)}`, value: item.id }))
            setConfigurations(mappedConfig);
        }
    }

    useEffect(() => {
        if (uuid) {
            getConfig();
            getEoiTokenData();
            getBookingPricing();
            getBasePriceData();
            getAminitiesData();
            getStampDutyData();
            getRegistrantionData();
            getLegelChargesData();
            getEDCChargedData();
            getPLCChargedData();
            getIdcChargeData();
            getIfdcChargeData();
            getGSTChargeData();
            getPricingDeley();
        }
    }, [uuid])

    const handleChangeAmenityPriceInclusion = async (uuid, value) => {
        const res = await changeAmenityPriceInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAminitiesData();
        }
    }

    const handleChangeStampDutyInclusion = async (uuid, value) => {
        const res = await changeStampDutyInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getStampDutyData();
        }
    }

    const handleChangeRegisterationChargeInclusion = async (uuid, value) => {
        const res = await changeRegisterationChargeInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getRegistrantionData();
        }
    }

    const handlechangeEDCChargeInclusion = async (uuid, value) => {
        const res = await changeEDCChargeInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getEDCChargedData();
        }
    }

    const handleChangeIDCChargeInclusion = async (uuid, value) => {
        const res = await changeIDCChargeInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getIdcChargeData();
        }
    }

    const handleChangeIFDCChargeInclusion = async (uuid, value) => {
        const res = await changeIFDCChargeInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getIfdcChargeData();
        }
    }

    const handleChangeLegalChargeInclusion = async (uuid, value) => {
        const res = await changeLegalChargeInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getLegelChargesData();
        }
    }
    const handleChangePLCChargeInclusion = async (uuid, value) => {
        const res = await changePLCChargeInclusion(uuid, { value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getPLCChargedData();
        }
    }


    const handleSubmit = async () => {
        const payload = {
            payment_percentage: interesRate,
            payment_amount: null,
            grace_period: gracePerioid,
            grace_period_unit: gracePerioidUnit.value,
            calculatedFrom: interestCalculatedFrom
        }
        let res = {}
        if (pricingDelayUUID) {
            res = await updatePricingDelayedInterestByUUID(pricingDelayUUID, payload)
        } else {
            res = await insertPricingDelayedInterest(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message)
            if (checkIsSomeItemAvailableInList(allowedPermissions, ['edit_project_routing', 'select_project_lead'])) {
                history.push(`/projects/create-projects/construction-updates/${uuid}?pt=${property}&tt=${transaction}`);
            } else {
                history.push(`/projects/project-manager`);
            }
        }

    }

    return (
        <div className={`${style.creatproject}`}>
            <ProjectHeader style={style} />
            <div className={`${style.creatproject} overflow-auto`}>
                <div className='d-flex flex-fill flex-column border-top border-color h-full overflow-auto !pt-5 sm:!pl-6 sm:!pr-6 !pl-4 !pr-4 !pb-10'>

                    <div className='!border !border-primary !bg-[#F9FAFD] rounded-xl !p-4 inter !mb-4'>
                        <h1 className='m-0 text-lg text-textblack font-semibold !border-b border-grayLight !pb-3'>Note</h1>

                        <ul className='!mt-4 flex flex-col gap-y-3 !list-decimal !ml-4 mb-0 pb-0'>
                            <li className='text-textblack text-sm inter font-medium'>Base Price = Rate Multiplied by Total Saleable Area (Carpet Area) (5000sqft * 10/sqft = Rs. 50000)</li>
                            <li className='text-textblack text-sm inter font-medium'>Total Price = Base Price + Extra Charges (Infrastructure + Amenities) (Agreement Amt.)</li>
                            <li className='text-textblack text-sm inter font-medium'>Agreement Price = The Price of the Unit agreed upon EOI submission</li>
                            <li className='text-textblack text-sm inter font-medium'>Sub Total = Total Price + GST</li>
                            <li className='text-textblack text-sm inter font-medium'>Final Price = Total Price + Stamp Duty + GST + Registration (Unit Price (i) )</li>
                            <li className='text-textblack text-sm inter font-medium'>Total Due Amt = Final Price + Delayed Interest Payment (Grand Total)</li>
                        </ul>

                    </div>
                    <div className='flex flex-col gap-y-9'>
                        {/* EOI  */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>EOI  Token</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && eoiTokenData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setshowEoiModal(true)}><PlushIcon />Add</button>}
                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && eoiTokenData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setshowEoiModal(true)}>+ Add EOI Token</button>
                                </div>}
                            </div>
                            {eoiTokenData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>{userProfile?.company_id === 581 ? 'Token' : 'EOI'} Amt.</th>
                                                <th>GST%</th>
                                                <th>Total EOI</th>
                                                {/* <th>Unit Type Applicability</th> */}
                                                <th>Unit Config. Applicability</th>
                                                {allowedPermissions.includes('show_eoi_pricing') && <th>Validity</th>}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                eoiTokenData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.payment_percentage ? data.payment_percentage + '%' : parseInt(data.payment_amount) ? '₹ ' + toINRFormat(data.payment_amount) : '-'}</td>
                                                        <td>
                                                            {/* ₹ 74,000 */}
                                                            {data.gst_percentage ? data.gst_percentage + '%' : '-'}
                                                        </td>
                                                        <td>{(data.payment_amount) ?
                                                            '₹ ' + (toINRFormat(addPercentage(parseFloat(data.payment_amount), parseFloat(data.gst_percentage || '0.0'))))
                                                            : '-'
                                                        }</td>

                                                        {/* <td>{data.unit_type ? data.unit_type.join(', ') : '-'}</td> */}
                                                        <td>{data.config ? getConfigString(data.config) : '-'}</td>
                                                        {allowedPermissions.includes('show_eoi_pricing') && <td>{data.validity ? `${data.validity} ${data.validity_unit}` : '-'}</td>}
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                                                <a className='dropdown-item' onClick={() => {
                                                                    getEOIPricing(data)
                                                                }}>Edit</a>
                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* Booking Pricing  */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>Booking Pricing</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && bookingPricingData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setshowBookingModal(true)}><PlushIcon />Add</button>}
                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && bookingPricingData.length === 0 && <div className='block !mt-4'>
                                    <button
                                        className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                                        onClick={() => setshowBookingModal(true)}
                                    >
                                        + Add Booking Price
                                    </button>
                                </div>}
                            </div>
                            {bookingPricingData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Booking Amt.</th>
                                                <th>GST%</th>
                                                <th>Total EOI</th>
                                                <th>Unit Type Applicability</th>
                                                <th>Unit Config. Applicability</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bookingPricingData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.payment_percentage ? data.payment_percentage + '%' : parseInt(data.payment_amount) ? '₹ ' + toINRFormat(data.payment_amount) : '-'}</td>
                                                        <td>
                                                            {/* ₹ 74,000 */}
                                                            {data.gst_percentage ? data.gst_percentage + '%' : '-'}
                                                        </td>
                                                        <td>{(data.payment_amount) ?
                                                            '₹ ' + (toINRFormat(addPercentage(parseFloat(data.payment_amount), parseFloat(data.gst_percentage || '0.0'))))
                                                            : '-'
                                                        }</td>
                                                        <td>{data.unit_type ? data.unit_type.join(', ') : '-'}</td>
                                                        <td>{data.config ? getConfigString(data.config) : '-'}</td>

                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                                                <a className='dropdown-item' onClick={() => {
                                                                    getBookingPriceByUuid(data)
                                                                }}>Edit</a>
                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* Base Price */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>Base Price (Rate*Saleable area)</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && basePriceData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowPriceMdoal(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && basePriceData.length === 0 && <div className='block !mt-4'>
                                    <button
                                        className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                                        onClick={() => setShowPriceMdoal(true)}
                                    >
                                        + Add Base Prices
                                    </button>
                                </div>}
                            </div>
                            {basePriceData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                {/* <th>Unit Type</th> */}
                                                <th>Unit Configuraion</th>
                                                <th>Rate</th>
                                                <th>Base Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                basePriceData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        {/* <td>{data.unit_type ? getUnitType(data.unit_type) : '-'}</td> */}
                                                        {/* <td>{data.config[0].unit_configuration && data.config[0].carpet_area ?
                                                            configLabel(data.config[0]) : '-'}</td> */}
                                                        <td>{getConfigString(data.config)}</td>
                                                        <td>{data.rate ? `₹ ${toINRFormat(parseFloat(data.rate))}` : '-'}</td>
                                                        <td> {data.rate ? `₹ ${toINRFormat(calculateTotalPrice(data.config[0], data.rate))}` : '-'}</td>
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a className='dropdown-item'
                                                                    onClick={() => {
                                                                        getBasePricing(data)
                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* PLC Charge */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>PLC Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && plcChargesData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowAddPlcCharges(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && plcChargesData.length === 0 && <div className='block !mt-4'>
                                    <button
                                        className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                                        onClick={() => setShowAddPlcCharges(true)}
                                    >
                                        + Add PLC Charges
                                    </button>
                                </div>}
                            </div>
                            {plcChargesData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>

                                                <th>S.No</th>
                                                <th>Charge Type</th>
                                                <th>PLC Charges</th>
                                                {/* <th>Unit Type Applicability</th> */}
                                                <th>Unit Config. Applicability</th>
                                                <th>Include in Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                plcChargesData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.charge_type}</td>
                                                        <td>{data.payment_percentage ? data.payment_percentage + '%' : parseInt(data.payment_amount) ? '₹ ' + toINRFormat(data.payment_amount) : '-'}</td>
                                                        {/* <td>{data.unit_type ? getUnitType(data.unit_type) : '-'}</td> */}
                                                        <td>{data.config ? getConfigString(data.config) : '-'}</td>
                                                        <td>
                                                            <button
                                                                type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                data-toggle="button"
                                                                aria-pressed="true"
                                                                autoComplete="off"
                                                                value="true"
                                                                onClick={() => handleChangePLCChargeInclusion(data.uuid, !data.include_in_price)}
                                                            >
                                                                <div className="handle">
                                                                </div>
                                                                <span className="on ">{
                                                                    data.include_in_price ? 'ON' : 'OFF'
                                                                }</span>
                                                            </button>
                                                        </td>
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        getPLCPricing(data)
                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>

                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* Amenities */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>Amenities Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && aminitiesData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowAmenityModal(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && aminitiesData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowAmenityModal(true)}>+ Add Amenities</button>
                                </div>}
                            </div>
                            {aminitiesData.length > 0 && <div className='!mt-4'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Amenity Type</th>
                                                <th>Amenity Charge</th>
                                                <th>Include in Price</th>
                                                <th>Charge Applied</th>
                                                <th>Unit Type Applicability</th>
                                                <th>Unit Config. Applicability</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                aminitiesData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.charge_type}</td>
                                                        <td>{data.payment_amount ? `₹ ${toINRFormat(data.payment_amount)}` : '-'}</td>
                                                        <td>
                                                            <button
                                                                type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                data-toggle="button"
                                                                aria-pressed="true"
                                                                autoComplete="off"
                                                                value="true"
                                                                onClick={() => handleChangeAmenityPriceInclusion(data.uuid, !data.include_in_price)}
                                                            >
                                                                <div className="handle">
                                                                </div>
                                                                <span className="on ">{
                                                                    data.include_in_price ? 'ON' : 'OFF'
                                                                }</span>
                                                            </button>
                                                        </td>
                                                        <td>{data.interval || '-'}</td>
                                                        <td>{data.unit_type ? data.unit_type.join(', ') : '-'}</td>
                                                        <td>{data.config ? getConfigString(data.config) : '-'}</td>
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a className='dropdown-item'
                                                                    onClick={() => {
                                                                        getAmenitiesPricing(data)
                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* Stamp duty */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>Stamp Duty</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && allowedPermissions.includes('edit_pricing_module') && stampDutyData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowStampDutyModal(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && allowedPermissions.includes('edit_pricing_module') && stampDutyData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowStampDutyModal(true)}>+ Add Stamp Duty</button>
                                </div>}
                            </div>
                            {stampDutyData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>State</th>
                                                <th>Stamp Duty Charge (%)</th>
                                                <th>Category</th>
                                                <th>Description</th>
                                                <th>Include in Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                stampDutyData.map((data, index) => (

                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.state}</td>
                                                        <td>{data.payment_percentage ? `${data.payment_percentage} %` : '-'}</td>
                                                        <td>{data.category || '-'}</td>
                                                        <td><div className='truncate max-w-[200px]'>{data.description || '-'}</div></td>
                                                        <td>
                                                            <button
                                                                type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                data-toggle="button"
                                                                aria-pressed="true"
                                                                autoComplete="off"
                                                                value="true"
                                                                onClick={() => handleChangeStampDutyInclusion(data.uuid, !data.include_in_price)}
                                                            >
                                                                <div className="handle">
                                                                </div>
                                                                <span className="on ">{
                                                                    data.include_in_price ? 'ON' : 'OFF'
                                                                }</span>
                                                            </button>
                                                        </td>
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        setSelectedPrice(data);
                                                                        setShowStampDutyModal(true);
                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}

                        </div>
                        {/* Registration Charge */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>Registration Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && registrationChargesData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowRegistrationModal(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && registrationChargesData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowRegistrationModal(true)}>+ Add Registration Charges</button>
                                </div>}
                                {registrationChargesData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                    <div className="def-table !h-auto p-0 max-h-[365px]">
                                        <table className='table mb-0'>
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>State</th>
                                                    <th>Registration Charge</th>
                                                    <th>Description</th>
                                                    <th>Include in Price</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    registrationChargesData.map((data, index) => (
                                                        <tr key={`${data.uuid}_${index}`}>

                                                            <td>{index + 1}</td>
                                                            <td>{data.state}</td>
                                                            <td>{data.payment_amount ? `₹ ${toINRFormat(data.payment_amount)}` : data.payment_percentage ? `${data.payment_percentage} %` : '-'} </td>
                                                            <td>
                                                                <div className='truncate max-w-[200px]'>{data.description || '-'}</div>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                    data-toggle="button"
                                                                    aria-pressed="true"
                                                                    autoComplete="off"
                                                                    value="true"
                                                                    onClick={() => handleChangeRegisterationChargeInclusion(data.uuid, !data.include_in_price)}
                                                                >
                                                                    <div className="handle">
                                                                    </div>
                                                                    <span className="on ">{
                                                                        data.include_in_price ? 'ON' : 'OFF'
                                                                    }</span>
                                                                </button>
                                                            </td>
                                                            {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0 '>
                                                                <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <HeaderMenu />
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                    <a
                                                                        className='dropdown-item'
                                                                        onClick={() => {
                                                                            setSelectedPrice(data);
                                                                            setShowRegistrationModal(true);
                                                                        }}
                                                                    >Edit</a>

                                                                    {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        {/* Legel Charge */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>Legal Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && legelChargesData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowLeagalChargesModal(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && legelChargesData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowLeagalChargesModal(true)}>+ Add Legal Charges</button>
                                </div>}
                            </div>
                            {legelChargesData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>

                                                <th>S.No</th>
                                                <th>Legal Charge</th>
                                                <th>Description</th>
                                                <th>Include in Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {legelChargesData.map((data, index) => (
                                                <tr key={`${data.uuid}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.payment_percentage ? data.payment_percentage + '%' : parseInt(data.payment_amount) ? '₹ ' + toINRFormat(data.payment_amount) : '-'}</td>
                                                    <td><div className='truncate max-w-[200px]'>{data.description || '-'}</div></td>
                                                    <td>
                                                        <button
                                                            type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                            data-toggle="button"
                                                            aria-pressed="true"
                                                            autoComplete="off"
                                                            value="true"
                                                            onClick={() => handleChangeLegalChargeInclusion(data.uuid, !data.include_in_price)}
                                                        >
                                                            <div className="handle">
                                                            </div>
                                                            <span className="on ">{
                                                                data.include_in_price ? 'ON' : 'OFF'
                                                            }</span>
                                                        </button>
                                                    </td>
                                                    {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                        <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <HeaderMenu />
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                            <a
                                                                className='dropdown-item'
                                                                onClick={() => {
                                                                    setSelectedPrice(data);
                                                                    setShowLeagalChargesModal(true);
                                                                }}
                                                            >Edit</a>

                                                            {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                        </div>
                                                    </td>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* EDC Charge */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>EDC Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && edcChargesData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowAddEdcCharges(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && edcChargesData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowAddEdcCharges(true)}>+ Add EDC Charges</button>
                                </div>}
                            </div>
                            {edcChargesData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>

                                                <th>S.No</th>
                                                <th>EDC Charges</th>
                                                <th>Unit Type Applicability</th>
                                                <th>Unit Config. Applicability</th>
                                                <th>Include in Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                edcChargesData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.payment_percentage ? data.payment_percentage + '%' : parseInt(data.payment_amount) ? '₹ ' + toINRFormat(data.payment_amount) : '-'}</td>
                                                        <td>{data.unit_type ? getUnitType(data.unit_type) : '-'}</td>
                                                        <td>{data.config ? getConfigString(data.config) : '-'}</td>
                                                        <td>
                                                            <button
                                                                type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                data-toggle="button"
                                                                aria-pressed="true"
                                                                autoComplete="off"
                                                                value="true"
                                                                onClick={() => handlechangeEDCChargeInclusion(data.uuid, !data.include_in_price)}
                                                            >
                                                                <div className="handle">
                                                                </div>
                                                                <span className="on ">{
                                                                    data.include_in_price ? 'ON' : 'OFF'
                                                                }</span>
                                                            </button>
                                                        </td>
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        getEDCPricing(data)
                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>

                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* IDC */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>IDC Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && idcChargesData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowAddIdcCharges(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && idcChargesData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowAddIdcCharges(true)}>+ Add IDC Charges</button>
                                </div>}
                            </div>
                            {idcChargesData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>

                                                <th>S.No</th>
                                                <th>IDC Charges</th>
                                                <th>Unit Type Applicability</th>
                                                <th>Unit Config. Applicability</th>
                                                <th>Include in Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                idcChargesData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.payment_percentage ? data.payment_percentage + '%' : parseInt(data.payment_amount) ? '₹ ' + toINRFormat(data.payment_amount) : '-'}</td>
                                                        <td>{data.unit_type ? getUnitType(data.unit_type) : '-'}</td>
                                                        <td>{data.config ? getConfigString(data.config) : '-'}</td>
                                                        <td>
                                                            <button
                                                                type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                data-toggle="button"
                                                                aria-pressed="true"
                                                                autoComplete="off"
                                                                value="true"
                                                                onClick={() => handleChangeIDCChargeInclusion(data.uuid, !data.include_in_price)}
                                                            >
                                                                <div className="handle">
                                                                </div>
                                                                <span className="on ">{
                                                                    data.include_in_price ? 'ON' : 'OFF'
                                                                }</span>
                                                            </button>
                                                        </td>
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        getIDCPricing(data);
                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* IFDC */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>IFDC Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && ifdcCharges.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowAddIfdcCharges(true)}><PlushIcon />Add</button>}

                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && ifdcCharges.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowAddIfdcCharges(true)}>+ Add IFDC Charges</button>
                                </div>}
                            </div>
                            {ifdcCharges.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>IFDC Charges</th>
                                                <th>Unit Type Applicability</th>
                                                <th>Unit Config. Applicability</th>
                                                <th>Include in Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ifdcCharges.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.payment_percentage ? data.payment_percentage + '%' : parseInt(data.payment_amount) ? '₹ ' + toINRFormat(data.payment_amount) : '-'}</td>
                                                        <td>{data.unit_type ? getUnitType(data.unit_type) : '-'}</td>
                                                        <td>{data.config ? getConfigString(data.config) : '-'}</td>
                                                        <td>
                                                            <button
                                                                type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                data-toggle="button"
                                                                aria-pressed="true"
                                                                autoComplete="off"
                                                                value="true"
                                                                onClick={() => handleChangeIFDCChargeInclusion(data.uuid, !data.include_in_price)}
                                                            >
                                                                <div className="handle">
                                                                </div>
                                                                <span className="on ">{
                                                                    data.include_in_price ? 'ON' : 'OFF'
                                                                }</span>
                                                            </button>
                                                        </td>
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        getIFDCPricing(data);

                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        {/* GST */}
                        <div>
                            <div className='max-w-[915px]'>
                                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                    <h1 className='text-textblack text-lg inter font-semibold m-0'>GST Charges</h1>
                                    {allowedPermissions.includes('edit_pricing_module') && gstChargesData.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowAddGstChargesModal(true)}><PlushIcon />Add</button>}
                                </div>
                                {allowedPermissions.includes('edit_pricing_module') && gstChargesData.length === 0 && <div className='block !mt-4'>
                                    <button className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]' onClick={() => setShowAddGstChargesModal(true)}>+ Add GST Charges</button>
                                </div>}
                            </div>
                            {gstChargesData.length > 0 && <div className='!mt-4 max-w-[915px]'>
                                <div className="def-table !h-auto p-0 max-h-[365px]">
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>State</th>
                                                <th>GST Charge (%)</th>
                                                <th>Description</th>
                                                {/* <th>Include in Price</th> */}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                gstChargesData.map((data, index) => (
                                                    <tr key={`${data.uuid}_${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.state || '-'}</td>
                                                        <td>
                                                            {data.payment_percentage ? `${data.payment_percentage} %` : '-'}</td>
                                                        <td><div className='truncate max-w-[200px]'>{data.description || '-'}</div></td>
                                                        {/* <td>
                                                            <button
                                                                type="button" className={`btn btn-lg btn-toggle ${data.include_in_price ? 'active' : ''} mr-2`}
                                                                data-toggle="button"
                                                                aria-pressed="true"
                                                                autoComplete="off"
                                                                value="true"
                                                                onClick={() => handleChangeGSTInclusion(data.uuid, !data.include_in_price)}
                                                            >
                                                                <div className="handle"
                                                                >
                                                                </div>
                                                                <span className="on ">{
                                                                    data.include_in_price ? 'ON' : 'OFF'
                                                                }</span>
                                                            </button>
                                                        </td> */}
                                                        {allowedPermissions.includes('edit_pricing_module') && <td className='dropdown view-more !sticky right-0'>
                                                            <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <HeaderMenu />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">

                                                                <a
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        setSelectedPrice(data);
                                                                        setShowAddGstChargesModal(true);
                                                                    }}
                                                                >Edit</a>

                                                                {/* <a className="dropdown-item !text-red hover:!bg-red hover:!text-white">Delete</a> */}
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>

                        <div className='max-w-[915px]'>
                            <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                                <h1 className='text-textblack text-lg inter font-semibold m-0'>Delayed Payment Interest</h1>
                            </div>
                            <div className='grid sm:grid-cols-2 gap-x-9 gap-y-4 !mt-4'>
                                <InputText
                                    label='Annual Interest Rate %'
                                    placeholder='e.g. 8 %'
                                    value={interesRate}
                                    onChange={(e) => setInterestRate(e.target.value)}
                                />
                                <div>
                                    <label className='text-sm text-black700'>Grace Period</label>
                                    <div className='grid grid-cols-2 gap-x-2'>

                                        <InputText
                                            placeholder='e.g. 20'
                                            value={gracePerioid}
                                            onChange={(e) => setGracePerioid(e.target.value)}
                                        />
                                        <InputSelect
                                            inputclass='!mt-0'
                                            styles={customStyles}
                                            value={gracePerioidUnit}
                                            placeholder='e.g. days'
                                            onChange={(value) => setGracePerioidUnit(value)}
                                            options={[
                                                {
                                                    label: 'Days',
                                                    value: 'Days'
                                                },
                                                {
                                                    label: 'Months',
                                                    value: 'Months'
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className='text-sm text-black700'>Interest Amt Calculated from </label>

                                    <div className='flex gap-x-5'>
                                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700'>
                                            <input type="radio" name="calculate" id="" value='due_date' checked={interestCalculatedFrom === 'due_date'} onChange={() => setInterestCalculatedFrom('due_date')} />
                                            Due Date
                                        </label>
                                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700'>
                                            <input type="radio" name="calculate" id="" value="grace_period_completion" checked={interestCalculatedFrom === 'grace_period_completion'} onChange={() => setInterestCalculatedFrom('grace_period_completion')} />
                                            Grace Period Completion
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                    <button
                        className={`${style.cancelbtn}`}
                        onClick={() => {
                            if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_payment_plans', 'create_payment_plan'])) {
                                history.push(`/projects/create-projects/payment-plan/${uuid}?pt=${property}&tt=${transaction}`);
                            } else {
                                history.push(`/projects/create-projects/additional-info/${uuid}?pt=${property}&tt=${transaction}`)
                            }
                        }
                        }
                    ><LeftIcon />Previous </button>
                    <button className={`${style.savebtn}`} onClick={handleSubmit}>Save & Next</button>
                </div>
            </div>

            {showEoiModal &&
                <AddEOITokenModal
                    show={showEoiModal}
                    uuid={uuid}
                    closeModal={() => {
                        setSelectedPrice({});
                        setshowEoiModal(false)
                    }}
                    configurations={configurations}
                    selectedPrice={selectedPrice}
                    getEoiTokenData={getEoiTokenData}
                    allowedPermissions={allowedPermissions}
                />}

            {showBookingModal &&
                <AddBookingPriceModal
                    show={showBookingModal}
                    uuid={uuid}
                    closeModal={() => {
                        setSelectedPrice({});
                        setshowBookingModal(false)
                    }}
                    configurations={configurations}
                    selectedPrice={selectedPrice}
                    getBookingPricing={getBookingPricing}
                />}

            {showPriceMdoal &&
                <AddBasePriceModal
                    show={showPriceMdoal}
                    uuid={uuid}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowPriceMdoal(false)
                    }}
                    configurations={configurations}
                    selectedPrice={selectedPrice}
                    getBasePriceData={getBasePriceData}
                />}
            {showAmenityModal &&
                <AddAmenityModal
                    show={showAmenityModal}
                    uuid={uuid}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowAmenityModal(false)
                    }}
                    configurations={configurations}
                    selectedPrice={selectedPrice}
                    getAminitiesData={getAminitiesData}
                />}
            {showStampDutyModal &&
                <AddStampDutyModal
                    show={showStampDutyModal}
                    uuid={uuid}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowStampDutyModal(false)
                    }}
                    configurations={configurations}
                    selectedPrice={selectedPrice}
                    getStampDutyData={getStampDutyData}
                    statesList={statesList}
                />}
            {showRegistrationModal &&
                <AddRegistrationModal
                    show={showRegistrationModal}
                    uuid={uuid}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowRegistrationModal(false)
                    }}
                    configurations={configurations}
                    selectedPrice={selectedPrice}
                    statesList={statesList}
                    getRegistrantionData={getRegistrantionData}
                />}
            {showLeagalChargesModal &&
                <AddLeagalChargesModal
                    show={showLeagalChargesModal}
                    configurations={configurations}
                    uuid={uuid}
                    getLegelChargesData={getLegelChargesData}
                    selectedPrice={selectedPrice}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowLeagalChargesModal(false)
                    }}
                />}
            {showAddEdcCharges &&
                <AddEdcCharges
                    show={showAddEdcCharges}
                    configurations={configurations}
                    uuid={uuid}
                    getEDCChargedData={getEDCChargedData}
                    selectedPrice={selectedPrice}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowAddEdcCharges(false)
                    }} />}
            {showAddPlcCharges &&
                <AddPlcChargesModal
                    show={showAddPlcCharges}
                    configurations={configurations}
                    uuid={uuid}
                    getEDCChargedData={getEDCChargedData}
                    selectedPrice={selectedPrice}
                    getPLCChargedData={getPLCChargedData}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowAddPlcCharges(false)
                    }} />}
            {showAddIdcCharges &&
                <AddIdcCharges
                    show={showAddIdcCharges}
                    configurations={configurations}
                    uuid={uuid}
                    getIdcChargeData={getIdcChargeData}
                    selectedPrice={selectedPrice}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowAddIdcCharges(false)
                    }} />}
            {showAddIfdcCharges &&
                <AddIfdcCharges
                    show={showAddIfdcCharges}
                    configurations={configurations}
                    uuid={uuid}
                    getIfdcChargeData={getIfdcChargeData}
                    selectedPrice={selectedPrice}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowAddIfdcCharges(false)
                    }} />}
            {showAddGstChargesModal &&
                <AddGstChargesModal
                    show={showAddGstChargesModal}
                    configurations={configurations}
                    uuid={uuid}
                    getGSTChargeData={getGSTChargeData}
                    selectedPrice={selectedPrice}
                    statesList={statesList}
                    closeModal={() => {
                        setSelectedPrice({});
                        setShowAddGstChargesModal(false)
                    }} />}
        </div >
    )

}

export default PricingModule
