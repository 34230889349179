import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';


// export async function getUsers() {
//     return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUsers}`);
// }

export async function addUsers() {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addUsers}`)
}

export async function enrollUser(workshopUuid) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.enroll.enrollUser}${workshopUuid}`)
}

export async function checkUserEnrolled(workshopUuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.enroll.enrolledUser}${workshopUuid}`)
}