import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { API_METHOD, API_URLS } from "../../../../config/apiUrl";
import requests, { handleError } from "../../../../helpers/requests";
import { action_addTag, action_getTags, action_setTags, TAG_ACTIONS } from "./action";

// Get Tags
function getTagsCall(leadUUID) {
    return requests(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.tags.getTagsByLeadUUID}/${leadUUID}`)
}

function* getTagsWatcher(action) {
    try {
        let result = yield call(getTagsCall, action.leadUUID);
        yield put(action_setTags(result.data.data));

    } catch (error) {
        console.log(error, 'error');
    }
}

// Remove Tag
function removeTag(leadUUID, tagId) {
    let payload = {
        tagId: tagId
    }
    return requests(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.tags.removeTagFromLead}/${leadUUID}`, null, payload)
}

function* removeTagsWatcher(action) {
    try {
        let result = yield call(removeTag, action.leadUUID, action.tagId);
        // Get tags
        yield put(action_getTags(action.leadUUID));

    } catch (error) {
        console.log(error, 'error during tag removal');
    }
}

// Add Tag
function addTag(leadUUID, name) {
    return requests(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.tags.addTagToLead}/${leadUUID}`, null, { name });
}

function* addTagWatcher(action) {
    try {
        yield call(addTag, action.leadUUID, action.name)
        yield put(action_getTags(action.leadUUID));

    } catch (error) {
        handleError(error);
    }
}

export function* tagsSaga() {
    yield all([
        takeLatest(TAG_ACTIONS.ADD_TAG, addTagWatcher),
        takeLatest(TAG_ACTIONS.GET_TAGS, getTagsWatcher),
        takeLatest(TAG_ACTIONS.REMOVE_TAGS, removeTagsWatcher)
    ])
}