import moment from 'moment'
import React, { useEffect } from 'react'
import liveIcon from '../../../assets/live.svg'
import LiveMentorProfile from './LiveMentorProfile'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import leftsvg from './mentorleft.svg'
import rightsvg from './mentorright.svg'
import leftsvghover from './mentorlefthover.svg'
import rightsvghover from './mentorrighthover.svg'
import { toast } from 'react-toastify';

const LiveSection = ({ style, mentors, liveSession, courseDetail }) => {

    return (
        <div className='col-xl-4'>
            <div className={`${style.live_section}`}>
                <div className={`${style.live_header}`}>
                    {
                        !liveSession && <>
                            <h1 className='inter fz20 fw-po-semi black mb-8'>
                                {courseDetail?.session_date && moment(courseDetail?.start_time) <= moment() &&
                                    <> Course Started on {moment(courseDetail?.session_date).format('Do MMM')}</>
                                }
                                {courseDetail?.session_date && moment(courseDetail?.start_time) > moment() &&
                                    <> Course Starts at {`${moment(courseDetail?.start_time).format('h:mm a')}`}, {moment(courseDetail?.session_date).format('Do MMM')}</>
                                }
                            </h1>
                        </>
                    }
                    {liveSession && <>
                        <h3 className='inter mb-10 black-dark-700 fz14 fw-po-medium'>Live Session</h3>
                        <h1 className='inter fz20 fw-po-semi black mb-8'>{liveSession?.name}</h1>
                        <p className='inter mb-0 black-dark-700 fz14 fw-po-semi'>
                            <span className="">Live session at {moment(liveSession?.session_start_time).format('h:mm a')}, {moment(liveSession?.session_date).format('Do MMM')}</span>
                        </p>
                    </>
                    }
                    <div className={`${style.live_header_footer} d-flex flex-xl-nowrap flex-wrap align-items-center mt-16`}>
                        <a onClick={() => document.getElementById('CourseOutline').scrollIntoView()} className={style.learnmorebtn}>{liveSession ? 'Learn More' : ' View Course Outline'}</a>
                        {liveSession && <>
                            {(moment(liveSession.session_start_time) <= moment() && moment(liveSession.session_end_time) > moment()) &&
                                <button className={style.livebtn} onClick={() => {
                                    if (!liveSession.zoom_link) {
                                        toast.warning('Zoom link not available at the moment, please try again in few minutes.')
                                        return;
                                    }
                                    window.open(liveSession.zoom_link, '_blank')
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect x="1.59221" y="4.309" width="20.8148" height="15.1111" rx="2.11111" stroke="white" strokeWidth="1.75309" />
                                        <circle cx="12.0004" cy="10.3705" r="3.25926" stroke="white" strokeWidth="1.72839" />
                                        <path d="M10.3714 13.1231C9.45896 13.3746 8.62118 13.8299 7.92585 14.4519C7.23051 15.074 6.69714 15.8455 6.36893 16.7039C6.36893 16.7039 5.95067 17.655 5.83956 18.692" stroke="white" strokeWidth="1.72839" />
                                        <path d="M13.531 13.1231C14.4434 13.3746 15.2812 13.8299 15.9765 14.4519C16.6718 15.074 17.2052 15.8455 17.5334 16.7039C17.5334 16.7039 17.9517 17.655 18.0628 18.692" stroke="white" strokeWidth="1.72839" />
                                    </svg> Join now
                                </button>}

                            {(moment(liveSession?.session_start_time).diff(moment(), 'hours') < 24) && (moment(liveSession.session_start_time) > moment()) &&
                                <button className={`${style.livebtn} 'cursor-not-allowed'`} disabled>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect x="1.59221" y="4.309" width="20.8148" height="15.1111" rx="2.11111" stroke="white" strokeWidth="1.75309" />
                                        <circle cx="12.0004" cy="10.3705" r="3.25926" stroke="white" strokeWidth="1.72839" />
                                        <path d="M10.3714 13.1231C9.45896 13.3746 8.62118 13.8299 7.92585 14.4519C7.23051 15.074 6.69714 15.8455 6.36893 16.7039C6.36893 16.7039 5.95067 17.655 5.83956 18.692" stroke="white" strokeWidth="1.72839" />
                                        <path d="M13.531 13.1231C14.4434 13.3746 15.2812 13.8299 15.9765 14.4519C16.6718 15.074 17.2052 15.8455 17.5334 16.7039C17.5334 16.7039 17.9517 17.655 18.0628 18.692" stroke="white" strokeWidth="1.72839" />
                                    </svg> Live at {moment(liveSession?.session_start_time).format('h:mm a')}
                                </button>}
                        </>}
                    </div>
                </div>

                {mentors.length > 4 ? <div className={`${style.live_body}`}>
                    <h3 className='black-dark-700 fz20 fw-po-medium inter mb-16'>Mentors</h3>
                    <div className={style.live_mentro_group}>
                        <OwlCarousel
                            className='owl-theme owl-mentors vertical-carousel'
                            dots={false}
                            res='true'
                            loop={true}
                            autoplay={false}
                            nav={true}
                            mouseDrag={false}
                            touchDrag={false}
                            slideBy={1}
                            navText={[`<img src=${leftsvg} /> <img src=${leftsvghover} />`, `<img src=${rightsvg} /> <img src=${rightsvghover} />`]}
                            responsive={{
                                0: {
                                    items: 4,

                                },
                                600: {
                                    items: 4,


                                },
                                900: {
                                    items: 4,

                                },
                                1000: {
                                    items: 4,

                                },
                                1200: {
                                    items: 4,

                                }
                            }
                            }
                        >
                            {
                                mentors.map((mentor, index) => (
                                    <div key={`${mentor.name}_${mentor.id}_${index}`}>
                                        <LiveMentorProfile style={style} mentor={mentor} />
                                    </div>
                                ))
                            }
                        </OwlCarousel>
                    </div>
                </div>
                    :
                    <>
                        {
                            mentors?.length > 0 && <div className={`${style.live_body} lessthanfourmantor`}>
                                {
                                    mentors.map((mentor, index) => (
                                        <div key={`${mentor.name}_${mentor.id}_${index}`}>
                                            <LiveMentorProfile style={style} mentor={mentor} />

                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </>
                }
            </div >
        </div >
    )
}

export default LiveSection
