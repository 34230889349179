import React, { Component } from 'react';
import { ACTIVITY_VIEWS } from '../Detail Sidebar/ACTIVITY_VIEWS';

export default class BookingDone extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <>
            {/* Booking done */}
            <div className="site-visit-done">
                <h2 className="fz16 po black po fw-po-medium mb-24">
                    <svg className='mr-12' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <rect width="32" height="32" rx="4" fill="#FAFAFB" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.2574 17.4741L24.2147 7.38089C24.5566 6.94718 25.1854 6.87277 25.6191 7.2147C26.0528 7.55663 26.1272 8.1854 25.7853 8.61911L17.1131 19.6191C16.736 20.0975 16.0225 20.1304 15.6029 19.6888L11.2751 15.1344C10.8947 14.734 10.9108 14.1011 11.3112 13.7206C11.7115 13.3402 12.3445 13.3563 12.7249 13.7567L16.2574 17.4741ZM24 15.1C24 14.5477 24.4477 14.1 25 14.1C25.5523 14.1 26 14.5477 26 15.1V22.3C26 24.3435 24.3435 26 22.3 26H9.7C7.65655 26 6 24.3435 6 22.3V9.7C6 7.65655 7.65655 6 9.7 6H20.4C20.9523 6 21.4 6.44772 21.4 7C21.4 7.55228 20.9523 8 20.4 8H9.7C8.76112 8 8 8.76112 8 9.7V22.3C8 23.2389 8.76112 24 9.7 24H22.3C23.2389 24 24 23.2389 24 22.3V15.1Z" fill="#82C43C" />
                    </svg>Booking Done</h2>
                <form action="">
                    <div className="form-group">
                        <select name="" className='form-control' id="">
                            <option value="">1</option>
                            <option value="">2</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <select name="" className='form-control' id="">
                            <option value="">1</option>
                            <option value="">2</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className='black-dark-700 mb-8 fz14'>Comments (optional)</label>
                        <textarea name="" className='form-control' placeholder='Enter any Site Visit comments...'></textarea>
                    </div>
                    <div className="form-inline cancle-ok-btn">
                        <button className='outline-btn' onClick={(e) => this.props.changeActivityView(ACTIVITY_VIEWS.NEW_ACTIVITY_OPTIONS)}>Cancel</button>
                        <button className='pr-btn'>Confirm</button>
                    </div>
                </form>
            </div>
        </>
    }
}