/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id":"ap-south-1_DSXbbk8Qw",
    "aws_user_pools_web_client_id": "cr0isv6flcjn7rk2i47jacj87",
    "facebook_app_id":"246993257490836"
};


export default awsmobile;
