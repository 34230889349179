import React, { useContext, useState } from 'react'
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import InputText from '../InputGroup/InputText';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as PlusPrimaryIcon } from '../../assets/icons/PlusPrimary.svg';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { getMilestoneDetail, getPaymentPlanMilestones, getPaymentPlanMilestonesByProjectId, getProjectBankDetails, getProjectBasicDetailsByUUID, getProjectPaymentPlans, updateOfferesByUUID } from '../../services/public/public.service';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import ProjectHeader from './project-header';
import AddPaymentPlanModal from './Modal/AddPaymentPlanModal';
import UpdateMilestoneModal from './Modal/UpdateMilestoneModal';
import { createDatewithTime } from '../../helpers/date';
import { checkIsSomeItemAvailableInList, toINRFormat } from '../../helpers/helpers';
import Consumer from '../../helpers/context';
import moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap';
import MarkMilestoneCompleteModal from './Modal/MarkMilestoneCompleteModal';

const PaymentPlan = () => {
    const [show, setShow] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentPlans, setPaymentPlans] = useState([]);
    const [allMilestone, setAllMilestones] = useState([])
    const [plansMilestone, setPlansMilestone] = useState([]);
    const [selectedMilestone, setSelectedMilestone] = useState([]);
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState({});
    const [editMilestone, setEditMilestone] = useState({});
    const [selectedAll, setSelectAll] = useState(false);
    const [selectMilestoneCheck, setSelectMilestoneCheck] = useState(false)
    const [key, setKey] = useState('home');
    const [markAsCompleteDisable, setMarkAsCompleteDisable] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedTab, setSelectedTab] = useState('All Plans');
    const [selectedPlansMilestone, setSelectedPlansMilestone] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [markMultipleComplete, setMarkMultiPleComplete] = useState(false);
    const [selectAllMilestone, setSelectAllMilestone] = useState(false);
    const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const property = searchParams.get('pt');
    const transaction = searchParams.get('tt');
    let { allowedPermissions, isRolesModule } = useContext(Consumer);

    const getPaymentPlans = async () => {
        const res = await getProjectPaymentPlans(uuid);
        if (res.data.status === 200) {
            setPaymentPlans(res.data.data)
            handlePaymentPlanMilestone(res.data.data[0], false)
        }
    }

    const getProjectsMilstone = async () => {
        const res = await getPaymentPlanMilestonesByProjectId(uuid)
        if (res.data.status === 200) {
            setAllMilestones(res.data.data);
        }
    }
    // const getBasicDetails = async () => {
    //     const res = await getProjectBasicDetailsByUUID(uuid)
    //     if (res.status === 200) {
    //         const data = res.data.data;
    //         setOffers(data?.offers || ['', ''])
    //     }
    // }

    useEffect(() => {
        // getBankDetails();
        localStorage.setItem('project_state', 'create')
        if (uuid) {
            getPaymentPlans();
            getProjectsMilstone();
        }

        // getBasicDetails()
    }, [uuid]);

    const handlePaymentPlanMilestone = async (plan, isEdit) => {
        setSelectedPlan(plan)
        const res = await getPaymentPlanMilestones(plan.uuid);
        if (res.data.status === 200) {
            setPlansMilestone(res.data.data)
            setSelectedPlansMilestone(res.data.data)
            if (isEdit) {
                setShow(true);
            }
        }
    }

    // All mmilestone selection
    const handleSelectMilestone = (value, milestone) => {
        let milestones = [...allMilestone];
        const index = milestones.findIndex(item => item.uuid === milestone.uuid);
        if (!milestones[index]?.isSelected) {
            milestones[index].isSelected = true;
            setAllMilestones(milestones);
        } else {
            milestones[index].isSelected = false;
            setAllMilestones(milestones);
        }
    }

    const handleSelectAllMilestone = (value) => {
        setSelectAll(value)
        if (value) {
            const allSelectedMilestone = allMilestone.map(item => ({ ...item, isSelected: true }))
            setAllMilestones(allSelectedMilestone);
        } else {
            const allSelectedMilestone = allMilestone.map(item => ({ ...item, isSelected: false }))
            setAllMilestones(allSelectedMilestone);
        }
    }

    // payment milestone selection
    const handleSelectPaymentMilestone = (value, milestone) => {
        let milestones = [...plansMilestone];
        const index = milestones.findIndex(item => item.uuid === milestone.uuid);
        if (!milestones[index]?.isSelected) {
            milestones[index].isSelected = true;
            setPlansMilestone(milestones);
        } else {
            milestones[index].isSelected = false;
            setPlansMilestone(milestones);
        }
    }

    const handleSelectPlanMilestone = (value) => {
        setSelectAllMilestone(value)
        if (value) {
            const allSelectedMilestone = plansMilestone.map(item => ({ ...item, isSelected: true }))
            setPlansMilestone(allSelectedMilestone);
        } else {
            const allSelectedMilestone = plansMilestone.map(item => ({ ...item, isSelected: false }))
            setPlansMilestone(allSelectedMilestone);
        }
    }

    useEffect(() => {
        let isMilestoneSelected = allMilestone.some(item => item.isSelected);
        setMarkAsCompleteDisable(isMilestoneSelected);
    }, [allMilestone]);

    const handleMilsestone = async (milestone) => {
        const res = await getMilestoneDetail(milestone.uuid);
        if (res.data.status === 200) {
            setSelectedPaymentPlan(res.data.data.paymentPlan);
            setEditMilestone(res.data.data.milestone);
            setShow(true);
        }
    }

    const handleSubmit = async () => {
        // if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_pricing_module', 'edit_pricing_module'])) {
        //     history.push(`/projects/create-projects/pricing/${uuid}?pt=${property}&tt=${transaction}`);
        // } else if (checkIsSomeItemAvailableInList(allowedPermissions, ['edit_project_routing', 'select_project_lead'])) {
        //     history.push(`/projects/create-projects/team-info/${uuid}?pt=${property}&tt=${transaction}`);
        // } else {
        //     history.push(`/projects/project-manager`);
        // }
        history.push(`/projects/create-projects/pricing/${uuid}?pt=${property}&tt=${transaction}`)
    }

    return (
        <div className={`${style.creatproject} overflow-hidden`}>
            <ProjectHeader style={style} />
            <div className={`${style.creatproject} overflow-hidden `}>
                <div className='d-flex flex-fill flex-column border-top border-color h-full overflow-hidden'>
                    <div className={`gap-x-6 !pt-5 px-24 d-flex !border-b !border-grayLight align-items-center justify-content-between pl-sm-12 pr-sm-12`}>
                        <div className='w-100 overflow-x-auto no-scrollbar overflow-y-hidden pb-[25px]'>

                            <ul className="nav gap-x-6 nav-payment-milestone flex-nowrap " id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link p-0 text-base text-black700 relative hover:!text-primary ${selectedTab === 'All Plans' ? 'active' : ''}`}
                                        onClick={() => setSelectedTab('All Plans')}
                                    >
                                        All Plans
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link p-0 text-base text-black700 relative hover:!text-primary ${selectedTab === 'All Milestones' ? 'active' : ''}`}
                                        onClick={() => setSelectedTab('All Milestones')}
                                    >
                                        All Milestones
                                    </a>
                                </li>
                                {/* {paymentPlans.length > 0 && paymentPlans.map((plan, index) => (
                                    <li className="nav-item" onClick={() => {
                                        setSelectedTab('Plans')
                                        setSelectedIndex(index)
                                        setSelectedPlan(plan);
                                        handlePaymentPlanMilestone(plan, false);
                                    }} key={`${plan.uuid}_${index}`}>
                                        <a
                                            className={`nav-link p-0 text-base text-black700 relative hover:!text-primary ${selectedTab === 'Plans' && selectedIndex === index ? 'active' : ''}`}

                                        >{plan.name} </a>
                                    </li>
                                ))} */}
                            </ul>
                        </div>
                        {/* <div className='flex gap-x-4 !pb-4'>
                            {selectedTab === 'Plans' && <button className='border py-2 px-4 rounded-lg' onClick={() => {
                                setSelectedPaymentPlan(selectedPlan);
                                handlePaymentPlanMilestone(selectedPlan, true);
                                // setShow(true);
                            }}>
                                Edit
                            </button>}
                            <button
                                className='outline-btn !px-4 !gap-x-1 !inline-flex !items-center inter whitespace-nowrap'
                                onClick={() => setShowPaymentModal(true)}
                                disabled={!markAsCompleteDisable}
                            >Mark as complete</button>
                            {allowedPermissions.includes('create_payment_plan') && <button className='pr-btn inter !px-4 !gap-x-1 !inline-flex items-center inter whitespace-nowrap' onClick={() => setShow(true)}><PlushIcon />Add</button>}
                        </div> */}
                    </div >

                    <div className='!pl-6 !pt-6 h-full flex overflow-auto'>
                        <div className="h-full w-full ">
                            {selectedTab === 'All Plans' && <div className="h-full flex"
                            >
                                {/* plan tab left */}
                                <div className='w-1/5 mr-3 relative'>
                                    {paymentPlans.length > 0 ?
                                        <div>
                                            {paymentPlans.map((plan, index) => (
                                                <div
                                                    className={` ${selectedPlanIndex === index ? 'border border-primary rounded-lg font-semibold' : 'border-b border-grayLight '} px-2 my-2`}
                                                    key={`${plan.uuid}`}
                                                >
                                                    <button
                                                        className='m-0 py-2 flex justify-between items-center w-full'
                                                        onClick={() => {
                                                            setSelectedPlanIndex(index);
                                                            handlePaymentPlanMilestone(plan, false);
                                                        }}
                                                    >
                                                        {plan.name}
                                                        <span className='ml-1'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="#696974"><path fillRule="evenodd" clipRule="evenodd" d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z" fill="#696974"></path></svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            ))}
                                            <div className='absolute right-2 bottom-2'>
                                                {allowedPermissions.includes('create_payment_plan') && <button className='pr-btn inter !px-4 !gap-x-1 !inline-flex items-center inter whitespace-nowrap' onClick={() => setShow(true)}><PlushIcon />Add Plan</button>}
                                            </div>
                                        </div>
                                        :
                                        <div className='h-full w-full flex items-center justify-center'>
                                            {allowedPermissions.includes('create_payment_plan') && <button className='pr-btn inter !px-4 !gap-x-1 !inline-flex items-center inter whitespace-nowrap' onClick={() => setShow(true)}><PlushIcon />Add Plan</button>}
                                        </div>
                                    }
                                </div>
                                {/* plan tab right */}
                                <div className='w-4/5 '>
                                    <div className='border border-grayLight rounded-lg py-2 px-3'>
                                        <div className='my-3 flex justify-between'>
                                            <p className='m-0 text-lg font-semibold'>{selectedPlan.name}</p>
                                            <div className='flex items-center'>
                                                <button
                                                    className='bg-primary text-white py-1 px-3 rounded-lg mr-2'
                                                    onClick={() => {
                                                        setSelectedPaymentPlan(selectedPlan);
                                                        handlePaymentPlanMilestone(selectedPlan, true);
                                                    }}
                                                >
                                                    Add Milestone
                                                </button>
                                                {markMultipleComplete && <div className="merge-del-btn">
                                                    <div className="mr-12 d-flex flex-row">
                                                        <button className="rounded-lg border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={() => setShowPaymentModal(true)}>Mark as complete</button>
                                                        <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5"
                                                            onClick={() => {
                                                                setMarkMultiPleComplete(false);
                                                                handleSelectPlanMilestone(false);
                                                            }}
                                                        ><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                                                    </div>
                                                </div>

                                                }
                                                <div className='dropdown ml-2'>

                                                    <button className="bg-tr border-0 d-table ml-auto mr-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <ThreedotsIcon />
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        <a
                                                            className="dropdown-item text-capitalize"
                                                            onClick={() => {
                                                                setSelectedPaymentPlan(selectedPlan);
                                                                handlePaymentPlanMilestone(selectedPlan, true);
                                                            }}
                                                        >Edit Payment plan</a>
                                                        <a
                                                            className="dropdown-item text-capitalize"
                                                            onClick={() => setMarkMultiPleComplete(true)}
                                                        >Mark Multiple Complete</a>
                                                        {/* <a className="dropdown-item text-capitalize">Delete</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="h-full">
                                            <div className='w-100  h-full overflow-auto'>
                                                <div className='def-table pb-0 w-100 !h-full pr-0'>
                                                    <table className='table mb-0 !pr-5'>
                                                        <thead>
                                                            <tr>
                                                                {markMultipleComplete && <th><input type="checkbox" name="" id="" value={selectAllMilestone} onChange={() => handleSelectPlanMilestone(!selectAllMilestone)} /></th>}
                                                                <th className='!relative !w-auto !min-w-fit	!max-w-fit '>Milestone No.</th>
                                                                <th>Milestone Description</th>
                                                                <th>Part Payment </th>
                                                                <th>Collection Due Date</th>
                                                                <th>Project Status</th>
                                                                <th>Collection Status</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                plansMilestone.map((milestone, index) => (
                                                                    <tr key={`${milestone.uuid}_${index}`}>
                                                                        {markMultipleComplete && <td>
                                                                            <input type="checkbox" name="" id="" value={milestone.isSelected} checked={milestone.isSelected} onChange={() => handleSelectPaymentMilestone(!milestone.isSelected, milestone)} />
                                                                        </td>}
                                                                        <td className='text-left !relative'><span className='!ml-5'>{milestone.count || '-'}</span></td>
                                                                        <td>{milestone.description || '-'}</td>
                                                                        <td>
                                                                            {(milestone.payment_percentage || milestone.payment_amount) ?
                                                                                (milestone.payment_percentage ? `${milestone.payment_percentage}%` : `₹ ${toINRFormat(milestone.payment_amount)}`) :
                                                                                '-'
                                                                            }
                                                                        </td>
                                                                        <td>{milestone.due_date ? moment(milestone.due_date).format('Do MMM YYYY') : '-'}</td>
                                                                        <td>{milestone.project_status === 'completed' ? 'Completed' : 'Pending'}</td>
                                                                        <td>{milestone.collection_status === 'completed' ? 'Completed' : 'Pending'}</td>
                                                                        {allowedPermissions.includes('create_payment_plan') && <td className='dropdown view-more !sticky !right-0 w-[64px] min-w-[64px]'>
                                                                            <button className="bg-tr border-0 d-table ml-auto mr-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <ThreedotsIcon />
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                                {allowedPermissions.includes('create_payment_plan') && <a
                                                                                    className="dropdown-item text-capitalize"
                                                                                    onClick={() => {
                                                                                        setSelectedPlansMilestone(plansMilestone)
                                                                                        handleMilsestone(milestone)
                                                                                        // setShow(true);
                                                                                    }}
                                                                                >Edit Milestone</a>}
                                                                                <a
                                                                                    className="dropdown-item text-capitalize"
                                                                                    onClick={() => {

                                                                                    }}
                                                                                >
                                                                                    View Details
                                                                                </a>
                                                                                <a
                                                                                    className="dropdown-item text-capitalize"
                                                                                    onClick={() => {

                                                                                    }}
                                                                                >
                                                                                    View Construction Update
                                                                                </a>
                                                                                <a
                                                                                    className="dropdown-item text-capitalize"
                                                                                    onClick={() => {

                                                                                    }}
                                                                                >
                                                                                    Mark as Complete
                                                                                </a>

                                                                                {/* <a className="dropdown-item del-menu-itme text-capitalize"
                                                                >Delete</a> */}
                                                                            </div>
                                                                        </td>}
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* {paymentPlans.length > 0 ?
                                    <div className='w-100  h-full overflow-auto'>
                                        <div className='def-table pb-0 w-100 !h-full pr-0'>
                                            <table className='table mb-0 !pr-5'>
                                                <thead>
                                                    <tr>
                                                        <th >S.No</th>
                                                        <th>Payment Plan Name</th>
                                                        <th>Payment Plan Description</th>
                                                        <th>Total Milestones</th>
                                                        <th>Validity</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paymentPlans.map((plan, index) => (
                                                        <tr key={`${plan.name}_${index}`}>
                                                            <td>{index + 1}</td>
                                                            <td>{plan?.name || '-'}</td>
                                                            <td className='max-w-xs'><div className='truncate ...'>{plan?.description || '-'}</div></td>
                                                            <td>{plan.milestones?.length || '-'}</td>
                                                            <td>{plan.validity ? moment(plan.validity).format('Do MMM YYYY') : '-'}</td>
                                                            {allowedPermissions.includes('create_payment_plan') && <td className='dropdown view-more !sticky !right-0 w-[64px] min-w-[64px]'>
                                                                <button className="bg-tr border-0 d-table ml-auto mr-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <ThreedotsIcon />
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    {allowedPermissions.includes('create_payment_plan') && <a
                                                                        className="dropdown-item text-capitalize"
                                                                        onClick={() => {
                                                                            setSelectedPaymentPlan(plan);
                                                                            handlePaymentPlanMilestone(plan, true);
                                                                            // setShow(true);
                                                                        }}
                                                                    >Edit Payment Plan</a>}
                                                                    {/* <a className="dropdown-item text-capitalize">Edit Milestone</a> 
                                                                    {/* <a className="dropdown-item text-capitalize">Mark as complete</a>

                                                                </div>
                                                            </td>}
                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> :
                                    <div className='flex flex-col items-center justify-center gap-y-3 h-100'>
                                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                        <h2 className="inter text-base font-medium text-capitalize text-textblack m-0"> No Payment Found.</h2>
                                        <button className='!bg-primary text-sm font-medium inter rounded-lg !text-white hover:opacity-90 !px-4 py-2.5 inline-flex items-center gap-1' onClick={() => setShow(true)}><PlushIcon />Add Payment Plan </button>
                                    </div>
                                } */}
                            </div>}
                            {selectedTab === 'All Milestones' && <div className="h-full"
                            // id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
                            >
                                {allMilestone.length > 0 ? <div className='w-100  h-full overflow-auto'>
                                    <div className='def-table pb-0 w-100 !h-full pr-0' >
                                        <table className='table mb-0 !pr-5'>
                                            <thead>
                                                <tr>
                                                    <th><input type="checkbox" name="" id="" value={selectedAll} onChange={() => handleSelectAllMilestone(!selectedAll)} /></th>
                                                    <th>Payment Plan Name</th>
                                                    <th>Milestone No.</th>
                                                    <th>Milestone Description</th>
                                                    <th>Part Payment</th>
                                                    <th>Collection Due Date</th>
                                                    <th>Tentative Due Date</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    allMilestone.map((milestone, index) => (
                                                        <tr key={`${milestone}_${index}`}>
                                                            <td>
                                                                <input type="checkbox" name="" id="" value={selectMilestoneCheck} checked={milestone.isSelected} onChange={() => handleSelectMilestone(!selectMilestoneCheck, milestone)} />
                                                            </td>
                                                            <td>{milestone.plan_name}</td>
                                                            <td>{index + 1}</td>
                                                            <td>{milestone.description}</td>
                                                            <td>
                                                                {(milestone.payment_percentage || milestone.payment_amount) ?
                                                                    (milestone.payment_percentage ? `${milestone.payment_percentage}%` : `₹ ${toINRFormat(milestone.payment_amount)}`) :
                                                                    '-'
                                                                }
                                                            </td>
                                                            <td>{milestone.due_date ? moment(milestone.due_date).format('Do MMM YYYY') : '-'}</td>
                                                            <td>{milestone.tentative_due_date ? moment(milestone.tentative_due_date).format('Do MMM YYYY') : '-'}</td>
                                                            <td>-</td>
                                                            {allowedPermissions.includes('create_payment_plan') && <td className='dropdown view-more !sticky !right-0 w-[64px] min-w-[64px]'>
                                                                <button className="bg-tr border-0 d-table ml-auto mr-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <ThreedotsIcon />
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    {allowedPermissions.includes('create_payment_plan') && <a
                                                                        className="dropdown-item text-capitalize"
                                                                        onClick={() => {
                                                                            handleMilsestone(milestone)
                                                                            setShow(true);
                                                                        }}
                                                                    >Edit Milestone</a>}

                                                                    {/* <a className="dropdown-item del-menu-itme text-capitalize"
                                                                >Delete</a> */}
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </div> :
                                    <div className='flex flex-col items-center justify-center gap-y-3 h-100'>
                                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                        <h2 className="inter text-base font-medium text-capitalize text-textblack m-0"> No Milestone Found.</h2>
                                        <button className='!bg-primary text-sm font-medium inter rounded-lg !text-white hover:opacity-90 !px-4 py-2.5 inline-flex items-center gap-1' onClick={() => setShow(true)}><PlushIcon />Add Payment Plan </button>

                                    </div>
                                }
                            </div>}

                            {selectedTab === 'Plans' && <div
                                className="h-full"
                            >
                                <div className='w-100  h-full overflow-auto'>
                                    <div className='def-table pb-0 w-100 !h-full pr-0'>
                                        <table className='table mb-0 !pr-5'>
                                            <thead>
                                                <tr>
                                                    {/* <th><input type="checkbox" name="" id="" /></th> */}
                                                    <th className='!relative !w-auto !min-w-fit	!max-w-fit '>Milestone No.</th>
                                                    <th>Milestone Description</th>
                                                    <th>Part Payment </th>
                                                    <th>Collection Due Date</th>
                                                    <th>Tentative Due Date</th>
                                                    <th>Project Status</th>
                                                    <th>Collection Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    plansMilestone.map((milestone, index) => (
                                                        <tr key={`${milestone.uuid}_${index}`}>
                                                            {/* <td><input type="checkbox" name="" id="" /></td> */}
                                                            <td className='text-left !relative'><span className='!ml-5'>{milestone.count || '-'}</span></td>
                                                            <td>{milestone.description || '-'}</td>
                                                            <td>
                                                                {(milestone.payment_percentage || milestone.payment_amount) ?
                                                                    (milestone.payment_percentage ? `${milestone.payment_percentage}%` : `₹ ${toINRFormat(milestone.payment_amount)}`) :
                                                                    '-'
                                                                }
                                                            </td>
                                                            <td>{milestone.due_date ? moment(milestone.due_date).format('Do MMM YYYY') : '-'}</td>
                                                            <td>{milestone.tentative_due_date ? moment(milestone.tentative_due_date).format('Do MMM YYYY') : '-'}</td>
                                                            <td>{milestone.project_status === 'completed' ? 'Completed' : 'Pending'}</td>
                                                            <td>{milestone.collection_status === 'completed' ? 'Completed' : 'Pending'}</td>
                                                            {allowedPermissions.includes('create_payment_plan') && <td className='dropdown view-more !sticky !right-0 w-[64px] min-w-[64px]'>
                                                                <button className="bg-tr border-0 d-table ml-auto mr-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <ThreedotsIcon />
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    {allowedPermissions.includes('create_payment_plan') && <a
                                                                        className="dropdown-item text-capitalize"
                                                                        onClick={() => {
                                                                            setSelectedPlansMilestone(plansMilestone)
                                                                            handleMilsestone(milestone)
                                                                            // setShow(true);
                                                                        }}
                                                                    >Edit Milestone</a>}

                                                                    {/* <a className="dropdown-item del-menu-itme text-capitalize"
                                                                >Delete</a> */}
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>}
                            {/* )
                                })
                            } */}
                        </div>
                    </div>

                    {/* {paymentPlans.length > 0 ?
                        <>
                            <div className='w-100 px-24 mt-34'>
                                <div className='def-table pb-0 w-100' style={{ height: 'unset' }}>
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr>
                                                <th >S.No</th>
                                                <th>Payment Plan Name</th>
                                                <th>Payment Plan Description</th>
                                                <th>Total Milestones</th>
                                                <th>Validity</th>
                                                <th>Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                        :
                        <div className='flex flex-col items-center justify-center gap-y-3 h-100'>
                            <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                            <h2 className="inter text-base font-medium text-capitalize text-textblack m-0"> No Payment Found.</h2>
                            <button className='!bg-primary text-sm font-medium inter rounded-lg !text-white hover:opacity-90 !px-4 py-2.5 inline-flex items-center gap-1' onClick={() => setShow(true)}><PlushIcon />Add Payment Plan </button>

                        </div>
                    } */}


                </div >
                <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                    <button
                        className={`${style.cancelbtn}`}
                        onClick={() => {
                            history.push(`/projects/create-projects/additional-info/${uuid}?pt=${property}&tt=${transaction}`)
                        }}
                    ><LeftIcon />Previous </button>
                    <button onClick={handleSubmit} className={`${style.savebtn}`}>Save</button>
                </div>
            </div >

            {show && <AddPaymentPlanModal
                show={show}
                closeModal={() => {
                    setShow(false);
                    setSelectedPlansMilestone([]);
                    setSelectedPaymentPlan({});
                    setEditMilestone({});

                }}
                plansMilestone={selectedPlansMilestone}
                selectedPaymentPlan={selectedPaymentPlan}
                getPaymentPlans={getPaymentPlans}
                editMilestone={editMilestone}
                getProjectsMilstone={getProjectsMilstone}
            />}
            {/* {showPaymentModal &&
                <UpdateMilestoneModal
                    show={showPaymentModal}
                    selectedMilestone={plansMilestone.filter(item => item.isSelected)}
                    closeModal={() => {
                        setShowPaymentModal(false)
                    }}
                />} */}
            {
                showPaymentModal &&
                <MarkMilestoneCompleteModal
                    show={showPaymentModal}
                    selectedMilestone={plansMilestone.filter(item => item.isSelected)}
                    closeModal={() => {
                        setShowPaymentModal(false)
                    }}
                />
            }
        </div >
    )
}

export default PaymentPlan
